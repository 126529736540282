import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'

import FgSelect from '../../FgSelect/FgSelect'
import { transformBidding } from '../../../utils/Form'
import { bidTypes } from '../../../constants'

class Bidding extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
    this.getBiddings = memoize(this.getBiddings)
    this.onChange = this.onChange.bind(this)
  }
  getBiddings (kindType,auction) {
    let allowedBidTypes
    if (kindType === 'sheep' || kindType === 'goat' || auction.kindData.type === 'sheep-abblamb') {
      allowedBidTypes = [bidTypes.HEAD]
    } else {
     allowedBidTypes = [bidTypes.HEAD, bidTypes.KG]
      //allowedBidTypes = [bidTypes.HEAD]
    }
    return Object.values(allowedBidTypes).map(value => {
      return {
        value: value,
        label: transformBidding(value)
      }
    })
  }
  mapSelected (option) {
    return {
      value: option,
      label: transformBidding(option)
    }
  }
  onChange (option, { action }) {
    if (action !== 'select-option') {
      return
    }
    this.props.onChange('bidding', option.value)
  }
  render () {
    const { bidding, kindType,auction } = this.props
    return (
      <div className={`form-row ${this.props.disabled ? 'disabled' : ''}`}>
        <div className='row-title'>
          <p
            className='row-text'>Bidding<span className='asterisk'>*</span>:</p>
        </div>
        <div className='row-content'>
          <FgSelect
            placeholder='Select item'
            options={this.getBiddings(kindType,auction)}
            value={bidding ? this.mapSelected(bidding) : null}
            isSearchable={false}
            onChange={this.onChange}
          />
        </div>
      </div>
    )
  }
}

Bidding.propTypes = {
  bidding: PropTypes.oneOf(Object.values(bidTypes)),
  onChange: PropTypes.func.isRequired,
  kindType: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired
}

Bidding.defaultProps = {
  disabled: false
}

export default Bidding
