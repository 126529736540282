import { connect } from 'react-redux'
// import { bindActionCreators } from 'redux'

import ContactUs from './ContactUs'
// import { showSpinner } from '../../actions/index'

// const mapDispatchToProps = (dispatch) => {
//   return bindActionCreators({showSpinner}, dispatch)
// }

const ContactUsContainer = connect(
  null
  // mapDispatchToProps
)(ContactUs)

export default ContactUsContainer
