import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { showModal, showSpinner,changeStateProp } from '../../../actions'
import VersionUpgradeTerms from './VersionUpgradeTerms'

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ showModal, showSpinner,changeStateProp }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VersionUpgradeTerms)
