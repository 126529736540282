import {
  CHANGE_STATE_PROP
} from '../constants/index'
import update from 'immutability-helper'

const initialState = {
  bidSound: true,
  outBidSound: true,
  wonSound: true,
  autoBidSound: true,
  countdownSound: true
}

const REDUCER = 'SOUND'

export default (state = initialState, action) => {
  switch (action.type) {
    case REDUCER + CHANGE_STATE_PROP:
      return update(state, {
        [action.state.prop]: { $set: action.state.value }
      })
    default:
      return state
  }
}
