import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changeStateProp } from '../../actions'
import WatchButton from './WatchButton'

const mapStateToProps = (state) => {
  return {
    userData: state.user.data,
    watchButtonLoading: state.temp.watchButtonLoading
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeStateProp }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WatchButton)
