import React from 'react'
import FdInput from '../../Auth/Fd-input/index'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
    faUser
} from '@fortawesome/fontawesome-free-solid'
import FgSelect from '../../FgSelect/FgSelect'
const CustomerDetails = (props) => {
    const { form, edit, handleInputChange, mapSelected,  handleYesorNoDropdown,  handleCustomerDetailsChange , same } = props
    return (
        <div className='info-item'>
            <div className='cd-header'>
                <FontAwesomeIcon className='icons-style' icon={faUser} />
                <div className='cd-title'>Customer Details</div>
            </div>

            {
                <div className='rows cd-data'>
                    <p className='titles doyoutrade-title'>Do you trade through a company?*:</p>
                    <div className='type-wrapper'>
                        <div
                            className={`typeDropdown-wrapper  ${!same ? form.profession.value && 'selected' : form.profession.value && 'selected'}`}>

                            <FgSelect
                                placeholder={`Do you trade through a company?* `}
                                maxMenuHeight={180}
                                options={[{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }]}
                                value={form.doYouTradeThroughCompany.value
                                    ? mapSelected(form.doYouTradeThroughCompany.value)
                                    : null}
                                statedetails={true}
                                onChange={handleYesorNoDropdown.bind(null, 'doYouTradeThroughCompany')}
                                validData={form.doYouTradeThroughCompany.valid}
                                warningReason={form.doYouTradeThroughCompany.invalidMessage}
                                isDisabled={!edit}
                                className={'dropdown-input'}
                            />
                        </div>
                    </div>
                </div>

            }
            {form.doYouTradeThroughCompany.value === "No"
                ? (<div className='cd-data'>
                    <div className='columns'>

                        <div className='rows'>
                            <p className={`titles ${form.ABN.valid}`}>ABN*:</p>
                            <FdInput
                                className={`inputs ${!form.ABN.valid ? 'red' : ''}`}
                                type='text'
                                // placeholder='ABN'
                                value={form.ABN.value}
                                name='ABN'
                                onChange={handleInputChange}
                                validData={form.ABN.valid}
                                disabled={!edit}
                            />
                        </div>
                        <div className='rows'>
                            <p className={`titles margin-top-10px ${form.customerDetails.legalNameOfTradingEntity.valid}`}>Full legal name of trading entity*:</p>
                            <FdInput
                                className={`inputs ${!form.customerDetails.legalNameOfTradingEntity.valid ? 'red' : ''}`}
                                type='text'
                                // placeholder='Company/Trading Name'
                                value={form.customerDetails.legalNameOfTradingEntity.value}
                                name='legalNameOfTradingEntity'
                                onChange={ handleCustomerDetailsChange.bind(null, 'customerDetails') }
                                validData={form.customerDetails.legalNameOfTradingEntity.valid}
                                disabled={!edit}
                            />
                        </div>
                        <div className='rows'>
                            <p className={`titles margin-top-10px ${form.customerDetails.givenName.valid}`}>Given Name(s)*:</p>
                            <FdInput
                                className={`inputs ${!form.customerDetails.givenName.valid ? 'red' : ''}`}
                                type='text'
                                // placeholder='Company/Trading Name'
                                value={form.customerDetails.givenName.value}
                                name='givenName'
                                onChange={ handleCustomerDetailsChange.bind(null, 'customerDetails') }
                                validData={form.customerDetails.givenName.valid}
                                disabled={!edit}
                            />
                        </div>
                        <div className='rows'>
                            <p className={`titles margin-top-10px ${form.customerDetails.lastName.valid}`}>Last Name*:</p>
                            <FdInput
                                className={`inputs ${!form.customerDetails.lastName.valid ? 'red' : ''}`}
                                type='text'
                                // placeholder='Company/Trading Name'
                                value={form.customerDetails.lastName.value}
                                name='lastName'
                                onChange={ handleCustomerDetailsChange.bind(null, 'customerDetails') }
                                validData={form.customerDetails.lastName.valid}
                                disabled={!edit}
                            />
                        </div>

                    </div>
                </div>)
                : (<div className='cd-data'>
                    <div className='columns'>
                        <div className='rows company-tagging-name-row'>
                            <p className={`titles margin-top-10px ${form.customerDetails.companyName.valid}`}>Company Name*:</p>
                            <FdInput
                                className={`inputs ${!form.customerDetails.companyName.valid ? 'red' : ''}`}
                                type='text'
                                // placeholder='Company/Trading Name'
                                value={form.customerDetails.companyName.value}
                                name='companyName'
                                onChange={ handleCustomerDetailsChange.bind(null, 'customerDetails') }
                                validData={form.customerDetails.companyName.valid}
                                disabled={!edit}
                            />
                        </div>
                        <div className='rows company-tagging-name-row'>
                            <p className={`titles margin-top-10px ${form.customerDetails.ACN.valid}`}>ACN*:</p>
                            <FdInput
                                className={`inputs ${!form.customerDetails.ACN.valid ? 'red' : ''}`}
                                type='text'
                                // placeholder='Company/Trading Name'
                                value={form.customerDetails.ACN.value}
                                name='ACN'
                                onChange={ handleCustomerDetailsChange.bind(null, 'customerDetails') }
                                validData={form.customerDetails.ACN.valid}
                                disabled={!edit}
                            />
                        </div>
                        <div className='rows company-tagging-name-row'>
                            <p className={`titles margin-top-10px ${form.customerDetails.givenName.valid}`}>Given Name(s)*:</p>
                            <FdInput
                                className={`inputs ${!form.customerDetails.givenName.valid ? 'red' : ''}`}
                                type='text'
                                // placeholder='Company/Trading Name'
                                value={form.customerDetails.givenName.value}
                                name='givenName'
                                onChange={ handleCustomerDetailsChange.bind(null, 'customerDetails') }
                                validData={form.customerDetails.givenName.valid}
                                disabled={!edit}
                            />
                        </div>
                        <div className='rows company-tagging-name-row'>
                            <p className={`titles margin-top-10px ${form.customerDetails.lastName.valid}`}>Last Name*:</p>
                            <FdInput
                                className={`inputs ${!form.customerDetails.lastName.valid ? 'red' : ''}`}
                                type='text'
                                // placeholder='Company/Trading Name'
                                value={form.customerDetails.lastName.value}
                                name='lastName'
                                onChange={ handleCustomerDetailsChange.bind(null, 'customerDetails') }
                                validData={form.customerDetails.lastName.valid}
                                disabled={!edit}
                            />
                        </div>



                    </div>
                </div>)
            }
        </div>
    )
}



export default CustomerDetails
