import React, { Component, Fragment } from 'react'
import Loader from 'react-loader-spinner'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faPlayCircle
} from '@fortawesome/fontawesome-free-solid'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'

export default class ImageLoader extends Component {
  constructor () {
    super()
    this.state = {
      loading: true
    }
  }
  render () {
    const { src, alt, onError, id, className, type, file, lot } = this.props
    return (
      <Fragment>
        {type !== 'video'
          ? <div className='video-image-wrapper'>
            {className.includes('video-thumbnail') && <FontAwesomeIcon style={{ color: '#fff' }} icon={faPlayCircle} />}
            <img onLoad={() => {
              this.setState({
                loading: false
              })
            }} className={className} src={src} id={id} alt={alt} onError={onError} />
          </div>
          : <video
            controls={!lot}
            id={file.key}
            className={file.title ? 'carousel-video with-title' : 'carousel-video'}
            poster={file.thumbnail}
            onError={onError}
            onLoadedMetadata={() => {
              this.setState({
                loading: false
              })
            }}
          >
            <source src={file.url} type={file.type} />
          </video>
        }
        {this.state.loading &&
        <Loader
          className='image-loader-spinner'
          type='Oval'
          color='black'
          height={80}
          width={80}
        />
        }
      </Fragment>
    )
  }
}
