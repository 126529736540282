import { faBookmark, faCircle, faGavel, faTh } from '@fortawesome/fontawesome-free-solid'

const subNavItems = () => [
  {
    name: 'all-lots',
    title: 'All',
    borderColor: '#ffffff',
    icon: faTh,
    iconColor: '',
    active: true
  },
  {
    name: 'held-lots',
    title: 'Winning',
    borderColor: '#1f9512',
    icon: faCircle,
    iconColor: '#1f9512',
    active: false
  },
  {
    name: 'lost-lots',
    title: 'Losing',
    borderColor: 'red',
    icon: faCircle,
    iconColor: 'red',
    active: false
  },
  {
    name: 'watchlist',
    title: 'Watching',
    borderColor: '#007fc1',
    icon: faBookmark,
    iconColor: '#007fc1',
    active: false
  },
  {
    name: 'open-lots',
    title: 'Open Lots',
    borderColor: '#ffffff',
    icon: faGavel,
    iconColor: '',
    active: false
  }
]

const subNavItemsSequential = () => [
  {
    name: 'all-lots',
    title: 'All',
    borderColor: '#ffffff',
    icon: faTh,
    iconColor: '',
    active: true
  },
  {
    name: 'held-lots',
    title: 'Winning',
    borderColor: '#1f9512',
    icon: faCircle,
    iconColor: '#1f9512',
    active: false
  },
  {
    name: 'lost-lots',
    title: 'Losing',
    borderColor: 'red',
    icon: faCircle,
    iconColor: 'red',
    active: false
  },
  {
    name: 'watchlist',
    title: 'Watching',
    borderColor: '#007fc1',
    icon: faBookmark,
    iconColor: '#007fc1',
    active: false
  },
  {
    name: 'active-lots',
    title: 'Active Lots',
    borderColor: '#ffffff',
    icon: faGavel,
    iconColor: '',
    active: false
  },
  {
    name: 'upcoming-lots',
    title: 'Upcoming Lots',
    borderColor: '#ffffff',
    icon: faGavel,
    iconColor: '',
    active: false
  }
]

const subNavItemsPublic = () => [
  {
    name: 'all-lots',
    title: 'All',
    borderColor: '#ffffff',
    icon: faTh,
    iconColor: '',
    active: true
  },
  {
    name: 'open-lots',
    title: 'Open Lots',
    borderColor: '#ffffff',
    icon: faGavel,
    iconColor: '',
    active: false
  }
]

const subNavItemsSequentialPublic = () => [
  {
    name: 'all-lots',
    title: 'All',
    borderColor: '#ffffff',
    icon: faTh,
    iconColor: '',
    active: true
  },
  {
    name: 'active-lots',
    title: 'Active Lots',
    borderColor: '#ffffff',
    icon: faGavel,
    iconColor: '',
    active: false
  },
  {
    name: 'upcoming-lots',
    title: 'Upcoming Lots',
    borderColor: '#ffffff',
    icon: faGavel,
    iconColor: '',
    active: false
  }
]

const subNavItemsSynchronous = () => [
  {
    name: 'all-lots',
    title: 'All',
    borderColor: '#ffffff',
    icon: faTh,
    iconColor: '',
    active: true
  },
  {
    name: 'held-lots',
    title: 'Winning',
    borderColor: '#1f9512',
    icon: faCircle,
    iconColor: '#1f9512',
    active: false
  },
  {
    name: 'lost-lots',
    title: 'Losing',
    borderColor: 'red',
    icon: faCircle,
    iconColor: 'red',
    active: false
  },
  {
    name: 'watchlist',
    title: 'Watching',
    borderColor: '#007fc1',
    icon: faBookmark,
    iconColor: '#007fc1',
    active: false
  },
  {
    name: 'not-in-market',
    title: 'Lots not on Market',
    borderColor: '#ffffff',
    icon: faGavel,
    iconColor: '',
    active: false
  }
]

export const mesureConnectionSpeed = () => {
  const startTime = new Date().getTime()
  const imageUrl = `https://storage.googleapis.com/error-settings/1.jpg?date=${startTime}`
  let downloadSize = 38000
  const metric = 'MbPs'

  return new Promise((resolve, reject) => {
    window.fetch(imageUrl, {
      method: 'GET'
    })
      .then((response) => {
        const endTime = new Date().getTime()
        let duration = (endTime - startTime) / 1000
        let bitsLoaded = downloadSize * 8
        let speedMbps = (bitsLoaded / (1024 * 1024 * duration)).toFixed(2)
        let speed = +speedMbps
        resolve({ metric, speed })
      })
      .catch(reject)
  })
}

export default {
  subNavItems,
  subNavItemsPublic,
  subNavItemsSequential,
  subNavItemsSequentialPublic,
  subNavItemsSynchronous
}
