import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { showModal, showSpinner,changeStateProp } from '../../../actions'
import Terms from './Terms'
const mapStateToProps = (state) => {
  return {
    cameFromBuyerAndSellerForms: state.user.cameFromBuyerAndSellerForms
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ showModal, showSpinner,changeStateProp }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Terms)
