import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faClock,
  faGavel,
  faTimes,
  faPause
} from '@fortawesome/fontawesome-free-solid'

import ServerTime from '../../services/ServerTime'

export default class StateTimerAuction extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      timeLeft: this.getTimeLeft()
    }
    this.interval = setInterval(() => this._updateState(), 1000)
    this.getHeaderText = memoize(this.getHeaderText)
    this.getIconStyle = memoize(this.getIconStyle)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.auction.liveAt !== this.props.auction.liveAt ||
      prevProps.auction.inactiveTime !== this.props.auction.inactiveTime ||
      prevProps.auction.state !== this.props.auction.state) {
      this._updateState()
    }
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  _updateState () {
    this.setState({
      timeLeft: this.getTimeLeft()
    })
  }

  getAuctionActiveTime (auction, from) {
    // we need to take in account the time auction spent in 'paused' and 'frozen' states
    if (auction) {
      if (auction && auction.auctionType === 'synchronous' && from === 'auction') {
        return new Date(auction.finishAt || 0).valueOf()
      } else {
        return new Date(auction.liveAt).valueOf() + auction.inactiveTime
      }
    } else {
      return null
    }
  }

  getTimeLeft () {
    let { auction } = this.props
    let staleTime = auction.frozenTime || auction.pausedTime

    switch (auction.state) {
      case 'live':
        if (staleTime) {
          if (auction.auctionType === 'synchronous') {
            return ServerTime.getTimeTo(this.getAuctionActiveTime(auction, 'auction'), { mode: 'until', compareWith: staleTime })
          }
          return ServerTime.getStaleTime(staleTime - this.getAuctionActiveTime(auction))
        } else {
          if (auction.auctionType === 'synchronous') {
            return ServerTime.getTimeTo(this.getAuctionActiveTime(auction, 'auction'), { mode: 'until' })
          }
          return ServerTime.getTimeTo(this.getAuctionActiveTime(auction), { mode: 'after' })
        }
      case 'closed':
        clearInterval(this.interval)
        return ServerTime.getTimeTo(this.getAuctionActiveTime(auction), { mode: 'after', compareWith: auction.closedAt })
      case 'future':
      case 'open':
      default:
        return ServerTime.getTimeTo(this.getAuctionActiveTime(auction), { mode: 'until' })
    }
  }

  getHeaderText (auction) {
    switch (auction.state) {
      case 'future':
      case 'open':
        return 'STARTS IN:'
      case 'live':
        if (auction.frozenTime) {
          if (auction.auctionType === 'synchronous') {
            return 'AUCTION FROZEN'
          }
          return 'AUCTION FROZEN AT'
        }
        if (auction.pausedTime) {
          if (auction.auctionType === 'synchronous') {
            return 'AUCTION PAUSED'
          }
          return 'TIME PAUSED AT'
        }
        if (auction.auctionType === 'synchronous') {
          return 'CLOSE IN'
        }
        return 'LIVE FOR'
      case 'closed':
        return 'CLOSED'
      default:
        return null
    }
  }

  getIconStyle (auction) {
    switch (auction.state) {
      case 'future':
      case 'open':
        return { icon: faClock, background: '#f4c23c' }
      case 'live':
        if (auction.frozenTime) {
          return { icon: faPause, background: '#d11e2d' }
        }
        if (auction.pausedTime) {
          return { icon: faPause, background: '#ffffff' }
        }
        return { icon: faGavel, background: '#2d882f' }
      case 'closed':
        return { icon: faTimes, background: '#bfbfbf' }
      default:
        return null
    }
  }

  render () {
    const iconStyle = this.getIconStyle(this.props.auction)
    const headerText = this.getHeaderText(this.props.auction)
    return (
      <div className='auction-timer' style={{ backgroundColor: iconStyle.background }}>
        <FontAwesomeIcon className='icon' icon={iconStyle.icon} />
        <div className='info'>
          <span
            className='state'>{headerText}</span>
          {this.state.timeLeft.hours > 0
            ? <span
              className='big-time'>{this.state.timeLeft.hours > 9999 ? 'X' : this.state.timeLeft.hours}h {this.state.timeLeft.minutes}m {this.state.timeLeft.seconds}s</span>
            : <span className='small-time'>{this.state.timeLeft.minutes}m {this.state.timeLeft.seconds}s</span>
          }
        </div>
      </div>
    )
  }
}

StateTimerAuction.propTypes = {
  auction: PropTypes.object.isRequired
}
