import io from 'socket.io-client'

import { SOCKET_URL } from '../config'
import store from './store'
import logOut from '../utils/logOut'

export const BIDS_NAMESPACE = 'api/v1/bids'
export const CATALOG_NAMESPACE = 'api/v1/catalog'
export const USER_NAMESPACE = 'api/v1/user'
export const EVENTS_NAMESPACE = 'api/v1/events'
export const ANONYMOUS_USER_NAMESPACE = 'api/v1/anonymous-user'

let sockets = {}
let callbacks = {}
let validEventNames = {}
validEventNames[BIDS_NAMESPACE] = ['connect', 'reconnect', 'disconnect', 'lot-update']
validEventNames[CATALOG_NAMESPACE] = ['connect', 'reconnect', 'disconnect', 'lot', 'auction', 'kind', 'kind-delete', 'auction-delete', 'lot-delete']
validEventNames[USER_NAMESPACE] = ['connect', 'reconnect', 'disconnect', 'user-update']
validEventNames[EVENTS_NAMESPACE] = ['connect', 'reconnect', 'disconnect', 'event']
validEventNames[ANONYMOUS_USER_NAMESPACE] = ['connect', 'reconnect', 'disconnect', 'connect-anonymous-user']

export function connect (namespace, callback = null) {
  sockets[namespace] = io(SOCKET_URL + namespace, {
    forceNew: true,
    jsonp: false,
    reconnection: true,
    reconnectionDelay: 500,
    reconnectionDelayMax: 2500,
    transports: ['websocket']
  })

  sockets[namespace].on('connect', () => {
    if (namespace === BIDS_NAMESPACE) {
      socketLogin(namespace, () => {
        callback && callback()
      })
    } else if (namespace === USER_NAMESPACE) {
      socketLogin(namespace, () => {
        userConnect(USER_NAMESPACE, () => {
          callback && callback()
        })
      })
    } else if (namespace === EVENTS_NAMESPACE) {
      socketLogin(namespace, () => {
        eventsConnect(EVENTS_NAMESPACE, () => {
          callback && callback()
        })
      })
    } else {
      callback && callback()
    }
  })

  sockets[namespace].on('connect_error', (e) => {
    console.log('connect_error', namespace, e)
  })

  sockets[namespace].on('reconnect', () => {
    console.log('reconnect', namespace)
  })

  sockets[namespace].on('disconnect', () => {
    console.log('disconnect', namespace)
  })

  sockets[namespace].on('error', (e) => {
    console.log('error', namespace, e)
  })

  validEventNames[namespace].forEach((eventName) => {
    sockets[namespace].on(eventName, (data) => {
      let screens = callbacks[namespace][eventName]
      if (Object.keys(screens).length) {
        Object.getOwnPropertyNames(screens).forEach(async funcKey => {
          try {
            await screens[funcKey](data)
          } catch (err) {
            console.error('Socket error', err)
          }
        })
      }
    })
  })
}

export function reset (namespace) {
  callbacks[namespace] = []
  validEventNames[namespace].forEach((eventName) => (callbacks[namespace][eventName] = {}))
}

export function attach (namespace = BIDS_NAMESPACE, event, screen, func) {
  if (validEventNames[namespace].indexOf(event) >= 0) {
    if (!callbacks[namespace][event]) {
      callbacks[namespace][event] = {}
    }
    if (!callbacks[namespace][event][screen]) {
      callbacks[namespace][event][screen] = func
    }
  } else {
    // console.error('Tried to add event for invalid name:', event)
  }
}

export function detach (namespace, event, screen) {
  if (callbacks[namespace][event][screen]) {
    delete callbacks[namespace][event][screen]
  }
}

export function emit (namespace, ...leftArguments) {
  if (sockets[namespace]) {
    sockets[namespace].emit.apply(sockets[namespace], leftArguments)
  } else {
    sockets[namespace] = io(SOCKET_URL + namespace, {
      forceNew: true,
      jsonp: false,
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionDelayMax: 2500,
      transports: ['websocket']
    })
    sockets[namespace].emit.apply(sockets[namespace], leftArguments)
  }
  return true
}

export function socketLogin (namespace, callback = null) {
  sockets[namespace].emit('login', null, (err) => {
    if (err) {
      console.log('socket login error', err)
      logOut.init()
    } else {
      callback && callback()
    }
  })
}

export function joinRoom (namespace = BIDS_NAMESPACE, auctionId, authorized) {
  if (auctionId) {
    emit(namespace, 'join-auction', { _id: auctionId, authorized }, (err) => {
      if (err) {
        // console.log('join to socket room error', auctionId, err)
      } else {
        // console.log('joined to socket room ', namespace, auctionId)
      }
    })
  }
}

export function joinRoomAnonymous (namespace = ANONYMOUS_USER_NAMESPACE, auctionId) {
  if (auctionId) {
    emit(namespace, 'connect-anonymous-user', { _id: auctionId }, (err) => {
      if (err) {
        // console.log('join to socket room error', auctionId, err)
      } else {
        // console.log('joined to socket room ', namespace, auctionId)
      }
    })
  }
}

export function leaveAuctionAnonymous (namespace = ANONYMOUS_USER_NAMESPACE, auctionId) {
  if (auctionId) {
    emit(namespace, 'disconnect-anonymous-user', { _id: auctionId }, (err) => {
      if (err) {
        // console.log('join to socket room error', auctionId, err)
      } else {
        // console.log('joined to socket room ', namespace, auctionId)
      }
    })
  }
}

export function leaveRoom (namespace = BIDS_NAMESPACE, auctionId) {
  if (auctionId) {
    let { autobids, bids } = store.getState().user
    let hasAuctionBids = bids.some(bid => bid.auction === auctionId) ||
      autobids.some(autobid => autobid.auction === auctionId)
    if (!hasAuctionBids) {
      emit(namespace, 'leave-auction', { _id: auctionId }, (err) => {
        if (err) {
          // console.log('leave socket room error', err, auctionId)
        } else {
          // console.log('leaved socket room ', namespace, auctionId)
        }
      })
    }
  }
}

export function leaveRoomAnonymous (namespace = ANONYMOUS_USER_NAMESPACE, auctionId) {
  if (auctionId) {
    emit(namespace, 'disconnect-anonymous-user', { _id: auctionId }, (err) => {
      if (err) {
        // console.log('leave socket room error', err, auctionId)
      } else {
        // console.log('leaved socket room ', namespace, auctionId)
      }
    })
  }
}

// rejoins all auctions mentioned in user bids/autobids
// deprecated functionality
// export function rejoinRooms (namespace = BIDS_NAMESPACE) {
//   let {autobids, bids} = store.getState().user
//   // find out what auctions to rejoin (any auctions mentioned in bids or autobids)
//   let activeAuctions = new Set(
//       bids.map(bid => bid.auction)
//         .concat(autobids.map(autobid => autobid.auction))
//     )
//   activeAuctions.forEach(function (auctionId) {
//     emit(namespace, 'join-auction', {_id: auctionId}, (err) => {
//       if (err) {
//         // console.log('rejoin to socket room error', err)
//       } else {
//         // console.log('rejoined to socket room ', namespace, key)
//       }
//     })
//   })
// }

export function userConnect (namespace = USER_NAMESPACE, callback = null) {
  let userId
  if (store.getState().user.data) {
    userId = store.getState().user.data._id
  } else {
    userId = ''
  }
  if (!userId) {
    return
  }
  emit(namespace, 'connect-user', { _id: userId }, (err) => {
    if (err) {
      // console.log('rejoin to socket room error', err)
    } else {
      callback && callback()
      // console.log('rejoined to socket room ', namespace, userId)
    }
  })
}

export function eventsConnect (namespace = EVENTS_NAMESPACE, callback = null) {
  let userId
  if (store.getState().user.data) {
    userId = store.getState().user.data._id
  } else {
    userId = ''
  }
  if (!userId) {
    return
  }
  emit(namespace, 'connect-user', { _id: userId }, (err) => {
    if (err) {
      // console.log('connected to events error', err)
    } else {
      callback && callback()
      // console.log('connected to events', namespace, userId)
    }
  })
}

export function disconnectAll () {
  let namespaces = Object.keys(sockets)
  namespaces.forEach(namespace => {
    sockets[namespace].disconnect()
  })
}

reset(BIDS_NAMESPACE)
reset(CATALOG_NAMESPACE)
reset(USER_NAMESPACE)
reset(EVENTS_NAMESPACE)
reset(ANONYMOUS_USER_NAMESPACE)
