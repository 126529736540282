import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { changeStateProp, mergeStateProp } from '../../../../actions'
import RenderLot from './RenderLot'

const mapStateToProps = (state) => {
  return {
    userId: state.user.data ? state.user.data._id : '',
    biddingSwapLots: state.temp.biddingSwapLots,
    isSound: state.temp.isSound,
    auctionLiveLotsFormat: state.temp.auctionLiveLotsFormat
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeStateProp, mergeStateProp }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RenderLot)
