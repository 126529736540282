import { createSelector } from 'reselect'

const lotId = (state) => state.lotId
const lotNumericId = (state) => state.lotNumericId
const lots = (state) => state.lots
const auction = (state) => state.auction
const userId = (state) => state.userId
const showDraft = (state) => state.showDraft
const showSubmitted = (state) => state.showSubmitted
const watchList = (state) => state.watchList

export const lotById = createSelector(
  lotId, lots,
  (lotId, lots) => {
    return lots.find(l => l._id === lotId)
  }
)

export const lotByNumericId = createSelector(
  lotNumericId, lots,
  (lotNumericId, lots) => {
    return lots.find(l => l.searchNumericId === lotNumericId)
  }
)

export const auctionPublicLots = createSelector(
  auction, lots,
  (auction, lots) => {
    let legalLots = []
    if (auction) {
      legalLots = lots.filter(lot => {
        return lot.auction === auction._id && lot.approved
      })
    }
    return legalLots
  }
)

export const myLots = createSelector(
  userId, lots, showDraft, showSubmitted,
  (userId, lots, showDraft, showSubmitted) => {
    let myLots = lots.filter(lot => {
      return lot.createdBy === userId
    })
    let lotsActive = []
    if (showDraft) {
      lotsActive = myLots.filter(lot => lot.draft && lot.state !== 'closed')
    } else if (showSubmitted) {
      lotsActive = myLots.filter(lot => !lot.draft)
    }
    return lotsActive
  }
)

export const watchlistLots = createSelector(
  watchList, lots,
  (watchList, lots) => {
    return watchList ? lots.filter(lot => watchList.filter(item => item.state !== 'closed').map(item => item.lotId).includes(lot._id)) : []
  }
)

export const hasOtherDrafts = createSelector(
  lots, lotId,
  (lots, lotId) => {
    return lots.some(lot => lot.draft && (lot._id !== lotId))
  }
)
