import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { showModal } from '../../../actions'
import { MAX_HEADS, NUMBER_REGEXP, MIN_WEIGHED_HEADS_PERCENTAGE, MAX_HEADS_CATTLE } from '../../../constants'

class HeadsInput extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      value: this.props.count
    }
    this.onChange = this.onChange.bind(this)
    this.handleCount = this.handleCount.bind(this)
    this.input = React.createRef()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.count !== this.props.count) {
      this.setState({
        value: this.props.count
      })
    }
  }

  onChange () {
    let { value } = this.input.current
    if (NUMBER_REGEXP.test(value) || !value) {
      this.setState({ value })
    }
  }

  isCountValid (value) {
    if (this.props.countWeighed && this.props.countWeighed > value) {
      this.props.showModal({
        message: 'Number of Head Weighed can\'t be higher then Number of Head in Lot',
        buttons: [
          {
            text: 'OK'
          }
        ]
      })
      this.setState({ value: 1 })
      this.props.onChange(1, 'count')
      this.props.onChange(1, 'countWeighed')
      return false
    }
    if (this.props.countWeighed === 1) {
      return true
    }

    if (this.props.countWeighed && (this.props.countWeighed * 100 / value) < MIN_WEIGHED_HEADS_PERCENTAGE) {
      this.props.showModal({
        message: `Number of Head Weighed can't be lower then ${MIN_WEIGHED_HEADS_PERCENTAGE}% of Number of Head in Lot`,
        buttons: [
          {
            text: 'OK'
          }
        ]
      })
    } else if (this.props.countWeighed && this.props.countWeighed > value) {
      this.props.showModal({
        message: 'Number of Head Weighed can\'t be higher then Number of Head in Lot',
        buttons: [
          {
            text: 'OK'
          }
        ]
      })
    }
    return true
  }

  handleCount () {
    let { value } = this.state
    let newValue
    newValue = parseInt(value, 10)
    newValue = Math.min(newValue, window.location.href.indexOf('Sheep') === -1 ? MAX_HEADS_CATTLE : MAX_HEADS) || this.props.count
    if (window.location.href.indexOf('Sheep') === -1 || this.props.abblambsale) {
      this.setState({ value: newValue })
      if (newValue !== this.props.count) {
        this.props.onChange(newValue, 'count')
      }
    } else if (this.isCountValid(newValue)) {
      this.setState({ value: newValue })
      if (newValue !== this.props.count) {
        this.props.onChange(newValue, 'count')
      }
    }
  }

  render () {
    const { value } = this.state
    return (
      <div className={`form-row ${this.props.disabled ? 'disabled' : ''}`}>
        <div className='row-title'>
          <p className='row-text'>Number of Head<span className='asterisk'>*</span>:</p>
        </div>
        <div className='row-content'>
          <input
            className='input'
            placeholder='0'
            type='text'
            ref={this.input}
            value={value || ''}
            onChange={this.onChange}
            onBlur={this.handleCount}
          />
        </div>
      </div>
    )
  }
}

HeadsInput.propTypes = {
  count: PropTypes.number,
  countWeighed: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

HeadsInput.defaultProps = {
  disabled: false
}

export default connect(
  null,
  (dispatch) => {
    return bindActionCreators({ showModal }, dispatch)
  }
)(HeadsInput)
