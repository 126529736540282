import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { NUMBER_REGEXP } from '../../../constants'

class UserField extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
    this.onChange = this.onChange.bind(this)
    this.input = React.createRef()
  }
  onChange () {
    let { value } = this.input.current
    if (NUMBER_REGEXP.test(value) || !value) {
      this.props.onChange(value)
    }
  }
  render () {
    const { invalid, isRequired, noTab, title, value } = this.props
    return (
      <div className='form-row'>
        <div className='row-title'>
          <p
            className={`row-text ${invalid ? 'row-text-invalid' : ''}`}>
            {title}{isRequired ? <span className='asterisk'>*</span> : ''}:
          </p>
        </div>
        <div className='row-content'>
          <input
            ref={this.input}
            className='input'
            type='text'
            value={value}
            onChange={this.onChange}
            {...noTab ? { tabIndex: -1 } : {}}
          />
        </div>
      </div>
    )
  }
}

UserField.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  invalid: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  noTab: PropTypes.bool.isRequired
}

UserField.defaultProps = {
  invalid: false,
  noTab: false
}

export default UserField
