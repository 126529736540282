import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import ImageCarousel from "../../ImageCarousel/ImageCarousel";
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from "react-accessible-accordion";
import PropTypes from "prop-types";


class Section extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  renderSection(textArray) {
    return (
      <div className="section-container">
        <div className="title">{this.props.title}</div>
        <div className="body">
          {textArray.map((paragraph, index) => {
            return (
              <React.Fragment key={index}>
                {paragraph.title ? (
                  <p className="paragraph">{paragraph.title}</p>
                ) : (
                  index !== 0 && <br />
                )}
                {paragraph.number ? (
                  <div className="with-number">
                    <span className="text-number">{paragraph.number}</span>
                    <div
                      className="text"
                      dangerouslySetInnerHTML={{ __html: paragraph.text }}
                    />
                  </div>
                ) : (
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{ __html: paragraph.text }}
                  />
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    );
  }

  renderSectionAccordion(textArray) {
    return (
      <div className="section-container">
        <div className="title">{this.props.title}</div>
        <div className="body">
          <Accordion accordion={false}>
            {textArray.map((paragraph, index) => {
              return (
                <AccordionItem key={index}>
                  <div style={{ clear: "both" }} />
                  <AccordionItemTitle>
                    {paragraph.title ? (
                      <p className="paragraph">
                        {paragraph.title}
                        <span
                          className="accordion__arrow"
                          role="presentation"
                        />
                      </p>
                    ) : (
                      index !== 0 && <br />
                    )}
                  </AccordionItemTitle>
                  <AccordionItemBody>
                    {paragraph.number ? (
                      <div className="with-number">
                        <span className="text-number">{paragraph.number}</span>
                        <div
                          className="text"
                          dangerouslySetInnerHTML={{ __html: paragraph.text }}
                        />
                      </div>
                    ) : (
                      <div
                        className="text"
                        dangerouslySetInnerHTML={{ __html: paragraph.text }}
                      />
                    )}
                  </AccordionItemBody>
                </AccordionItem>
              );
            })}
          </Accordion>
        </div>
      </div>
    );
  }


  renderVideoSection(videos) {
    return (
      <div className="section-container video-section-container">
        <div className="title">{this.props.title}</div>
        <div className="body">
          <ImageCarousel media={videos} numberOfSlides={1} />
        </div>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.props.videos
          ? this.renderVideoSection(this.props.videos)
          : this.props.accordion
          ? this.renderSectionAccordion(this.props.text)
          : this.renderSection(this.props.text)}
      </React.Fragment>
    );
  }
}

Section.propTypes = {
  videos: PropTypes.array,
  title: PropTypes.string,
  text: PropTypes.array,
  accordion: PropTypes.bool,
  howtoassess: PropTypes.bool,
};

Section.defaultProps = {
  accordion: false,
  howtoassess: false,
};

export default withRouter(Section);
