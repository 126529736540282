import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faBalanceScale } from '@fortawesome/fontawesome-free-solid'
import SelectWithAutoScroll from '../../SelectWithAutoScroll/SelectWithAutoScroll'

class AdjustWeight extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      adjustWeight: this.props.adjustWeight,
      averageWeight: this.props.averageWeight
    }

    this.defaultAverageWeight = this.props.averageWeight
    this.input = React.createRef()
    this.options = []
    for (let i = -20; i <= 20; i += 0.25) {
      this.options.push({
        value: i,
        label: i > 0 ? `+${i}%` : `${i}%`
      })
    }
  }

  onChange = (option) => {
    this.props.onChangeDraftLot('adjustWeight', option.value)
    this.setState({
      adjustWeight: option.value
    })
  }

  countAdjustWeight = () => {
    let calculatedAvgWeight = this.defaultAverageWeight + (this.defaultAverageWeight / 100 * this.props.adjustWeight)
    this.setState({ averageWeight: calculatedAvgWeight })
  }

  render () {
    const { adjustWeight, averageWeight } = this.state

    return (
      <div className='rfn-editWeight'>
        <div className='rfn-fields-container rfn-editWeight-container'>
          <div className='form-row'>
            <div className='row-title'>
              <p className='row-text'>
                Adjust weight:
              </p>
            </div>
            <div className='row-content'>
              <SelectWithAutoScroll
                className='adjust-dropdown'
                options={this.options}
                value={adjustWeight}
                onChange={this.onChange}
              />
            </div>
          </div>
          <div className='form-row space-between'>
            <div className='row-title'>
              <p className='row-text'>
                Curfew Weight:
              </p>
            </div>
            <div className='row-content'>
              <p>
                {averageWeight.toFixed(2)} Kg
              </p>
            </div>
          </div>
        </div>
        <div
          className='adjust-weight-button'
          onClick={this.countAdjustWeight}
        >
          <span>Adjust Weight</span>
          <span className='icon-wrapper'><FontAwesomeIcon icon={faBalanceScale} /></span>
        </div>
      </div>
    )
  }
}

AdjustWeight.propTypes = {
  adjustWeight: PropTypes.number.isRequired,
  averageWeight: PropTypes.number.isRequired,
  onChangeDraftLot: PropTypes.func.isRequired
}

export default AdjustWeight
