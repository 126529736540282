import React, { useEffect, useRef, useState } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { line } from 'd3-shape'
import * as Helpers from './helpers'
import './ScrollableDiv.css'; // Import your CSS file

export const BarChartWeights = ({ individualAssessmentData }) => {
  const containerRef = useRef()
  const [containerWidth, setContainerWidth] = useState(0)
  const [data, setData] = useState([])
  const [weightData, setWeightData] = useState({ minWeight: 0, maxWeight: 0, averageWeight: 0 })
  const [averageXPoint, setAverageXPoint] = useState(0)
  const [fakeBars, setFakeBars] = useState([])
  const [mainBars, setMainBars] = useState([])
  const [mobileWidth, setMobileWidth] = useState(false)
  const containerHeight = 230
  useEffect(() => {
    setData(individualAssessmentData.counts.map((item, index) => ({
      key: index,
      title: item.startWeight && item.endWeight ? item.startWeight === item.endWeight ? `${item.startWeight}kg` : `${item.startWeight}kg-${item.endWeight}kg` : '',
      headsCount: item.count
    })))
    setWeightData({
      minWeight: individualAssessmentData.minWeight,
      maxWeight: individualAssessmentData.maxWeight,
      averageWeight: individualAssessmentData.averageWeight
    })
  }, [individualAssessmentData])

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth)
      setMobileWidth(containerRef.current.offsetWidth <= 550)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [containerRef])

  useEffect(() => {
    const findAveragePoint = (min, max, average) => {
      if (mainBars && mainBars.length > 0) {
        if (mainBars.length > 1 && individualAssessmentData) {
          let barIndex = 0
          // let averageItem = individualAssessmentData.counts[0]
          individualAssessmentData.counts.every(function (item, index) {
            if (item.startWeight >= average) {
              barIndex = index
              // averageItem = item
              return false
            }
            return true
          })
          if (mainBars[barIndex]) {
            return mainBars[barIndex].x
          }
        }
        return mainBars[0].x + mainBars[0].width / 2
      }
      return 0
    }
    setAverageXPoint(findAveragePoint(weightData.minWeight, weightData.maxWeight, weightData.averageWeight, containerWidth))
  }, [weightData, containerWidth, mainBars, individualAssessmentData])

  const handleResize = () => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth)
    }
    setMobileWidth(containerRef.current.offsetWidth <= 550)
  }

  const lineColor = 'rgba(0, 0, 0, .65)'

  const customLayers = {
    markers: ({ bars, height }) => {
      if (!mainBars || mainBars.length === 0 || JSON.stringify(bars) !== JSON.stringify(mainBars)) {
        setMainBars(bars)
      }
      let firstBar = bars[0]
      let lastBar = bars[bars.length - 1]

      const maxLine = line()([[lastBar.x + lastBar.width, height + 15], [lastBar.x + lastBar.width, 0]])
      const averageLine = line()([[averageXPoint, height + 15], [averageXPoint, 0]])
      const yForMarks = height - 15

      return (
        <>
          <g>
            <rect
              width={60}
              height={27}
              x={firstBar.x - (data.length <= 6 ? 30 : 0)}
              y={yForMarks}
              rx={4}
              ry={4}
              fill={'#FFD065'}
              stroke={'black'}
            />
            <text x={firstBar.x + (data.length <= 6 ? 0 : 30)} y={yForMarks + 19} fontSize={13} fontWeight='bold' textAnchor={'middle'}>
              {`${parseFloat(weightData.minWeight.toFixed(1))}kg`}
            </text>
            <text x={firstBar.x + (data.length <= 6 ? 0 : 30)} y={yForMarks + 43} fontSize={13} textAnchor={'middle'}>
              MIN
            </text>
          </g>

          <g>
            <path
              d={maxLine}
              fill='none'
              stroke={lineColor}
              style={{ pointerEvents: 'none' }}
            />
            <rect
              width={60}
              height={27}
              x={lastBar.x + lastBar.width - 30}
              y={yForMarks}
              rx={4}
              ry={4}
              fill={'#FFD065'}
              stroke={'black'}
            />
            <text x={lastBar.x + lastBar.width} y={yForMarks + 19} fontSize={13} fontWeight='bold' textAnchor={'middle'}>
              {`${parseFloat(weightData.maxWeight.toFixed(1))}kg`}
            </text>
            <text x={lastBar.x + lastBar.width - 15} y={yForMarks + 43} fontSize={13}>
              MAX
            </text>
          </g>
          <g>
            <path
              d={averageLine}
              fill='none'
              stroke={lineColor}
              style={{ pointerEvents: 'none' }}
            />
            <rect
              width={60}
              height={27}
              x={averageXPoint - 30}
              y={yForMarks}
              rx={4}
              ry={4}
              fill={'#ffd065'}
              stroke={'black'}
            />
            <text x={averageXPoint} y={yForMarks + 19} fontSize={13} fontWeight='bold' textAnchor={'middle'}>
              {`${parseFloat(weightData.averageWeight.toFixed(1))}kg`}
            </text>
            <text x={averageXPoint - 15} y={yForMarks + 43} fontSize={13}>
              AVG
            </text>
          </g>
        </>
      )
    },
    xAxisLine: ({ bars, height }) => {
      const rendered = []
      rendered.push(
        <path
          d={line()([[bars[0].x - 2, height], [bars[bars.length - 1].x + bars[bars.length - 1].width, height]])}
          fill='none'
          stroke={lineColor}
          style={{ pointerEvents: 'none' }}
        />
      )
      for (let i = 0, counter = bars[0].x + 10; counter < bars[bars.length - 1].x + bars[bars.length - 1].width - 10; i++, counter += 30) {
        rendered.push(
          <path
            d={line()([[counter, height], [counter, i % 2 ? height + 11 : height + 6]])}
            fill='none'
            stroke={lineColor}
            style={{ pointerEvents: 'none' }}
          />
        )
      }
      return (
        <>
          {rendered}
        </>
      )
    },
    
    fakeLayer: ({ bars }) => {
      if (!fakeBars || fakeBars.length === 0 || JSON.stringify(bars) !== JSON.stringify(fakeBars)) {
        setFakeBars(bars)
      }
      return (<></>)
    }
  }

  const renderTitles = (height) => {
    return (
      <>
        <text x={10} y={height / 2 - 25} fontSize={11} fontWeight={'bold'}>No. Head</text>
        <text x={10} y={height - 20} fontSize={11} fontWeight={'bold'}>Weight</text>
      </>
    )
  }

  const renderYAxis = (width, height) => {
    const rendered = []
    const maxHeads = Math.max(...data.map(item => item.headsCount))
    const step = Math.ceil(maxHeads / 8)
    const heightDecrement = height / Math.ceil(maxHeads / step)
    for (let i = 0, currentPoint = height; currentPoint >= 0; i++, currentPoint -= heightDecrement) {
      if (currentPoint >= height - 15) {
        continue
      }
      rendered.push(
        <path
          d={line()([[width, currentPoint + 6], [i % 2 ? width - 12 : width - 7, currentPoint + 6]])}
          fill='none'
          stroke={lineColor}
          style={{ pointerEvents: 'none' }}
        />
      )
      rendered.push(
        <path
          d={line()([[width, 6], [width, height]])}
          fill='none'
          stroke={lineColor}
          style={{ pointerEvents: 'none' }}
        />
      )
      rendered.push(
        <text x={width - 18} y={currentPoint + 8} fontSize={10} textAnchor={'end'} fontWeight={'bold'}>
          {currentPoint > 0 ? i * step : Math.max(...data.map(item => item.headsCount))}
        </text>
      )
    }
    return (
      <>
        {rendered}
      </>
    )
  }

  const renderBottomLine = (width, height) => <path
    d={line()([[0, height], [width, height]])}
    fill='none'
    stroke={lineColor}
    style={{ pointerEvents: 'none' }}
  />

  const renderTick = (tickData) => <g>
    <text x={tickData.x} y={tickData.y + (mobileWidth ? 55 : 38)} fontSize={10} textAnchor={'middle'} transform={mobileWidth ? `rotate(-40, ${tickData.x}, ${tickData.y + 38})` : null}>
      {data[tickData.tickIndex] ? data[tickData.tickIndex].title : ''}
    </text>
  </g>

  const renderFixedPart = (width, height) =>
    // Do not delete +1. It is for safari
    <div style={{ width: width + 1, position: 'absolute', left: 0, backgroundColor: '#ededed', marginLeft: 1 }}>
      <svg width={width} height={height}>
        {renderTitles(height)}
        {renderYAxis(width, Helpers.getFixedPartBottomOffset(mobileWidth, height))}
        {renderBottomLine(width, Helpers.getFixedPartBottomOffset(mobileWidth, height))}
      </svg>
    </div>

  const renderScrollablePart = () =>
    <div className='scrollable-div' style={{
      width: Helpers.getChartWidth(data.length, containerWidth),
      overflowX: data.length <= 6 ? 'hidden' : 'scroll',
      overflowY: 'hidden',
      paddingBottom: data.length <= 6 ? 0 : 25
    }}>
      <div style={{ height: containerHeight }}>
        <ResponsiveBar
          data={data}
          keys={['headsCount']}
          indexBy='key'
          width={Helpers.getChartWidth(data.length, containerWidth) > data.length * 38 ? Helpers.getChartWidth(data.length, containerWidth) : data.length * 38 }
          margin={Helpers.getBarMargins(mobileWidth)}
          padding={0.2}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={['#FFD065']}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          isInteractive={false}
          axisRight={null}
          axisLeft={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            renderTick: renderTick
          }}
          enableLabel={false}
          enableGridY={false}
          layers={[
            'axes',
            'bars',
            customLayers.xAxisLine,
            null,
            customLayers.markers
          ]}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          animate
        />
      </div>
    </div>

  return (
    (data.length <= 6 ? (<div ref={containerRef} style={{ display: 'flex' }}>
      {renderFixedPart(Helpers.getFixedPartWidth(mobileWidth, mainBars), containerHeight)}
      {renderScrollablePart()}
    </div>) : (
      (<div ref={containerRef} style={{ display: 'flex' }}>
        {renderScrollablePart()}
        {renderFixedPart(Helpers.getFixedPartWidth(mobileWidth, mainBars), containerHeight)}
      </div>)
    ))
  )
}
