import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'

import Api from '../../services/api'
import FdInput from '../Auth/Fd-input/index'
import { PASSWORD_REGEXP } from '../../constants/index'
import Header from '../Header/HeaderContainer.js'
import Footer from '../Footer/Footer'

class Change extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      form: {
        token: '',
        newPassword: '',
        confirmNewPassword: ''
      },
      submited: false,
      validSecCode: true,
      invalidSecCode: '',
      validNewPassword: true,
      invalidNewPassword: '',
      validConfirmNewPassword: true,
      invalidConfirmNewPassword: ''
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.validateData = this.validateData.bind(this)
    this.invalidDataReason = this.invalidDataReason.bind(this)
    this.allValid = this.allValid.bind(this)
    this.validateConfirmNewPassword = this.validateConfirmNewPassword.bind(this)
  }
  handleSubmit (event) {
    this.props.showSpinner(true)
    event.preventDefault()
    this.setState({
      submited: true
    })
    this.validateData(this.state.form.token, this.state.form.newPassword, this.state.form.confirmNewPassword)
    this.invalidDataReason(this.state.form.token, this.state.form.newPassword, this.state.form.confirmNewPassword)
    setTimeout(() => {
      if (this.allValid()) {
        let data = {
          password: this.state.form.newPassword
        }
        Api.resetForgotPassword(this.state.form.token, data, 'change-password')
          .then(data => {
            if (data) {
              this.props.history.push({ pathname: '/sign-in' })
              this.props.showModal({
                title: 'FarmGate',
                message: 'You have successfully changed your password. Please record it somewhere safely'
              })
            }
            this.props.showSpinner(false)
          })
      } else {
        this.props.showSpinner(false)
      }
    }, 0)
  }
  allValid () {
    if (this.state.validSecCode &&
      this.state.validNewPassword &&
      this.state.validConfirmNewPassword) {
      return true
    } else return false
  }
  validateData (secCode, newPassword, confirmNewPassword) {
    this.setState({
      validSecCode: this.validateSecCode(secCode) === '',
      validNewPassword: this.validateNewPassword(newPassword) === '',
      validConfirmNewPassword: this.validateConfirmNewPassword(confirmNewPassword) === ''
    })
  }
  invalidDataReason (secCode, newPassword, confirmNewPassword) {
    this.setState({
      invalidSecCode: this.validateSecCode(secCode),
      invalidNewPassword: this.validateNewPassword(newPassword),
      invalidConfirmNewPassword: this.validateConfirmNewPassword(confirmNewPassword)
    })
  }
  handleInputChange ({ target: { name, value } }) {
    this.setState({
      form: update(this.state.form, {
        [name]: { $set: value }
      })
    }, () => {
      if (this.state.submited) {
        switch (name) {
          case 'token':
            this.setState({
              validSecCode: this.validateSecCode(this.state.form[name]) === '',
              invalidSecCode: this.validateSecCode(this.state.form[name])
            })
            break
          case 'newPassword':
            this.setState({
              validNewPassword: this.validateNewPassword(this.state.form[name]) === '',
              invalidNewPassword: this.validateNewPassword(this.state.form[name])
            })
            break
          case 'confirmNewPassword':
            this.setState({
              validConfirmNewPassword: this.validateConfirmNewPassword(this.state.form[name]) === '',
              invalidConfirmNewPassword: this.validateConfirmNewPassword(this.state.form[name])
            })
            break
          default:
            break
        }
      }
    })
  }
  validateConfirmNewPassword (password) {
    if (password === '' || password === ' ' || password === undefined) {
      return 'Please Enter Password'
    } else if (this.state.form.newPassword !== this.state.form.confirmNewPassword) {
      return 'Passwords must match'
    } else {
      return ''
    }
  }
  validateNewPassword (password) {
    if (password === '' || password === ' ' || password === undefined) {
      return 'Please Enter Password'
    } else if (!(PASSWORD_REGEXP.test(password) && (password && password.length >= 6 && password.length <= 50))) {
      return 'Sorry, invalid password'
    } else {
      return ''
    }
  }
  validateSecCode (secCode) {
    if (secCode === '' || secCode === ' ' || secCode === undefined) {
      return 'Please Enter Security Code'
    } else {
      return ''
    }
  }
  render () {
    return (
      <div className='auth-wrapper change-forgotten'>
        <Header />
        <div className='background-wrapper'>
          <div className='content'>
            <div className='text-header'>
              <div className='main-header'>Forgotten Password</div>
            </div>
            <div className='form-wrapper'>
              <form onSubmit={this.handleSubmit}>
                <h3 className='title'>Please check your email. A security code has been sent to reset your password.</h3>
                <br />
                <FdInput className='input'
                  type='text'
                  placeholder='Security Code'
                  value={this.state.form.token}
                  name='token'
                  onChange={this.handleInputChange}
                  validData={this.state.validSecCode}
                  warningReason={this.state.invalidSecCode} />
                <FdInput className='input'
                  autoComplete='new-password'
                  type='password'
                  placeholder='New Password'
                  value={this.state.form.newPassword}
                  name='newPassword'
                  onChange={this.handleInputChange}
                  validData={this.state.validNewPassword}
                  withShowButton
                  warningReason={this.state.invalidNewPassword} />
                <FdInput className='input'
                  type='password'
                  placeholder='Confirm New Password'
                  value={this.state.form.confirmNewPassword}
                  name='confirmNewPassword'
                  onChange={this.handleInputChange}
                  validData={this.state.validConfirmNewPassword}
                  withShowButton
                  warningReason={this.state.invalidConfirmNewPassword} />
                <input className='change-pass' type='submit' value='CHANGE PASSWORD' />
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

Change.propTypes = {
  showModal: PropTypes.func.isRequired,
  showSpinner: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

Change.defaultProps = {}

export default Change
