import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import update from 'immutability-helper'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import ScrollToTop from '../../ScrollToTop/ScrollToTop'
import { faCaretLeft, faMapMarkerAlt, faUser, faBriefcase } from '@fortawesome/fontawesome-free-solid'
import { pick as _pick } from 'lodash'
import FdInput from '../Fd-input/index'
import FgSelect from '../../FgSelect/FgSelect'
import AuthCheckbox from '../AuthCheckbox/AuthCheckbox'
import Api from '../../../services/api'
import { convertData } from '../../../utils/convertData'
import Header from '../../Header/HeaderContainer'
import Footer from '../../Footer/Footer'
import {
  validateFirstName,
  validateLastName,
  validateEmail,
  validatePassword,
  validateRePassword,
  validatePic,
  validateAbn,
  validateTradingName,
  validateJobTitle,
  validateWebsite,
  validatePhone,
  validateSecondaryPhone,
  validatePostcode,
  validateTown,
  validateState,
  userOperationType,
  /*validateAccountName,
  validateAccountNumber,
  validateBsb,*/
  validateAddress,
  validateAgentName,
  validateLegalNameOfTradingEntityName,
  validateCompanyName,
  validateGivenName,
  validateAcn
} from '../../../utils/Validators'
import {
  PIC_REGEXP_SOFT,
  ABN_REGEXP_SOFT,
  ACN_REGEXP_SOFT,
  AUSTRALIAN_POSTCODE_SOFT,
  FIRST_LAST_NAME,
  NUMBER_REGEXP, ADDRESS,
  ACCOUNT_NAME_NUMBER,
  BSB
} from '../../../constants'
import ReactGA from 'react-ga'
import Section from '../../Tools/Section/Section'
import { privacy_policy } from '../../../utils'

let defaultFieldValueRequired = (value = '', valid = false) => {
  return {
    value: value,
    required: true,
    valid: valid,
    invalidMessage: ''
  }
}

let defaultFieldValue = (value = '', valid = true) => {
  return {
    value: value,
    valid: valid,
    invalidMessage: ''
  }
}

class AsBuyer extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      finishedRegistration: false,
      form: {
        PIC: defaultFieldValueRequired(),
        anotherStatePIC: defaultFieldValue(''),
        ABN: defaultFieldValueRequired(),
        agentName: defaultFieldValueRequired(),
          companyName : defaultFieldValueRequired(),
          givenName : defaultFieldValueRequired(),
          lastName : defaultFieldValueRequired(),
          ACN : defaultFieldValueRequired(),
          legalNameOfTradingEntity :  defaultFieldValueRequired(),
        stockTypes: [],
        officeAddress: {
          address1: defaultFieldValueRequired(),
          address2: defaultFieldValue(),
          town: defaultFieldValueRequired(),
          postcode: defaultFieldValueRequired(),
          state: defaultFieldValueRequired()
        },
        postalAddress: {
          address1: defaultFieldValueRequired(),
          address2: defaultFieldValue(),
          town: defaultFieldValueRequired(),
          postcode: defaultFieldValueRequired(),
          state: defaultFieldValueRequired()
        },
        /*bankDetails: {
          accountNumber: defaultFieldValueRequired(),
          accountName: defaultFieldValueRequired(),
          BSB: defaultFieldValueRequired()
        },*/
        acceptedTerms: defaultFieldValue(false),
        areYouUsingAgent: defaultFieldValueRequired('No'),
        doYouTradeThroughCompany: defaultFieldValueRequired('No'),
        userRegisteredFrom: defaultFieldValue(props.wpSiteName),
        isPICFromAnotherState: defaultFieldValueRequired('No'),
      },
      same: false,
      overlayType: '',
      terms: [],
    }
    this.saveTimeout = null
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleAddressChange = this.handleAddressChange.bind(this)
    this.handleBankDetailsChange = this.handleBankDetailsChange.bind(this)
    this.handleLocationState = this.handleLocationState.bind(this)
    this.selectFile = this.selectFile.bind(this)
    this.handleCheckbox = this.handleCheckbox.bind(this)
    this.handleFormCheckbox = this.handleFormCheckbox.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleYesorNoDropdown = this.handleYesorNoDropdown.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onAddressBlur = this.onAddressBlur.bind(this)
    this.onBankDetailsBlur = this.onBankDetailsBlur.bind(this)
    this.handleanotherStatePIC = this.handleanotherStatePIC.bind(this)
  }

  async componentDidMount() {
    this.getData()
    if (this.props.buyerAndSellerFormInfo.buyerForm) {
      this.fillFromStorage()
    }

    let userOperations = await Api.getUserOperations();
    if (userOperations) {
      this.setState({
        form: update(this.state.form, {
          userOperationsTypes: { $set: userOperations }
        })
      })
    }
  }
  getData () {
    this.props.showSpinner(true)
    Api.getTerms()
      .then(res => {
        if (res) {
          this.setState({
            terms: this.convertData(res.terms)
          })
        }
        this.props.showSpinner(false)
      })
  }

  convertData (data) {
    return data.map(item => {
      return {
        categoryName: item.categoryName,
        terms: { text: item.content }
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.form !== this.state.form) {
      clearTimeout(this.saveTimeout)
      this.saveTimeout = setTimeout(() => this.saveToStorage(), 1000)
    }
  }

  componentWillUnmount() {
    let step = ''
    switch (this.state.activeStep) {
      case 'step1':
        step = 'Stock and Station Agent Registration drop out: Step 1'
        break
      case 'step2':
        step = 'Stock and Station Agent Registration drop out: Step 2'
        break
      case 'step3':
        step = 'Stock and Station Agent Registration drop out: Step 3'
        break
      default:
        break
    }
    if (!this.state.finishedRegistration) {
      ReactGA.event({
        category: 'Stock and Station Agent Registration drop out',
        action: step,
        label: step
      }, ['WebApp'])
    }
    clearTimeout(this.saveTimeout)
  }

  fillFromStorage() {
    // pre-fill fields from global store
    let form = this.state.form
    Object.entries(this.props.buyerAndSellerFormInfo.buyerForm).forEach(([key, value]) => {
      if (!form.hasOwnProperty(key)) return
      switch (key) {
        case 'officeAddress':
        case 'postalAddress':
          for (let innerKey in value) {
            if (!form[key].hasOwnProperty(innerKey)) continue
            form = update(form,
              { [key]: { [innerKey]: { value: { $set: value[innerKey] } } } }
            )
          }
          break
        default:
          form = update(form,
            { [key]: { value: { $set: value } } }
          )
      }
    })
    this.setState({ form })
  }

  saveToStorage() {
    let saveData = convertData(this.state.form)
  //  delete saveData.password
   // delete saveData.repassword
    this.props.changeStateProp('buyerAndSellerFormInfo', update(this.props.buyerAndSellerFormInfo, {
      buyerForm: { $set: saveData }
    }), 'user')
  }

  mapOptions(options) {
    return options.map(option => {
      return {
        value: option,
        label: option
      }
    })
  }
  mapTypeOptions(options) {
    return options.map(option => {
      return {
        value: option.name,
        label: option.name
      }
    })
  }

  mapSelected(option) {
    return {
      value: option,
      label: option
    }
  }

  handleInputChange(event) {
    let { target: { name, value } } = event
    switch (name) {
      case 'firstName':
      case 'lastName':
      case 'tradingName':
      case 'jobTitle':
      case 'agentName':
      case 'companyName' :
      case 'givenName':
      case 'legalNameOfTradingEntity' :      
        if (value && !FIRST_LAST_NAME.test(value)) {
          return
        } else {
          value = value.slice(0, 1).toUpperCase() + value.slice(1)
        }
        break
      case 'phone':
      case 'secondaryPhone':
        if (value && !NUMBER_REGEXP.test(value)) {
          return
        }
        break
      case 'PIC':
        value = value.toUpperCase()
        if (value && !PIC_REGEXP_SOFT.test(value)) {
          return
        }
        break
        case 'ACN':
          if (value && !ACN_REGEXP_SOFT.test(value)) {
            return
          }
          break
      case 'ABN':
        if (value && !ABN_REGEXP_SOFT.test(value)) {
          return
        }
        break
      case 'yearsInIndustry':
      case 'SSPermitNumber':
      case 'SSPermitNumberOfYears':
        if (value && !NUMBER_REGEXP.test(value)) {
          return
        }
        break
      default:
    }
    this.setState({
      form: update(this.state.form, {
        [name]: {
          value: { $set: value }
        }
      })
    })
  }

  onBlur({ target: { name } }) {
    this.validate(name)
  }

  onAddressBlur(type, event) {
    let { target: { name } } = event
    this.validate(name, type)
  }

  onBankDetailsBlur(type, event) {
    let { target: { name } } = event
    this.validate(name, type)
  }
  selectFile({ target: { name, files } }) {
    let file = files[0]
    if (file) {
      let fileReader = new window.FileReader()
      fileReader.readAsDataURL(files[0])
      fileReader.onloadend = async () => {
        let obj = {
          data: fileReader.result,
          mimeType: file.type,
          name: file.name
        }
        if (!file.type) {
          let arrayName = file.name.split('.')
          let ext = arrayName[arrayName.length - 1]
          switch (ext) {
            case 'xls':
              obj.mimeType = 'application/vnd.ms-excel'
              break
            case 'doc':
              obj.mimeType = 'application/msword'
              break
            case 'docx':
              obj.mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
              break
            case 'xlsx':
              obj.mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              break
            default:
              obj.mimeType = ''
              break
          }
        }
        this.setState({ [name]: obj })
      }
    } else {
      this.setState({ [name]: undefined })
    }
  }

  handleCheckbox({ target: { name } }) {
    if (name === 'same') {
      this.setState(prevValue => {
        return { [name]: !prevValue[name] }
      })
    } else {
      this.setState({
        form: update(this.state.form, {
          [name]: {
            value: (value) => !value
          }
        })
      })
    }

  }
  handleYesorNoDropdown(type, option, { action }) {
    if (action !== 'select-option') {
      return
    }
    this.setState(() => {
      return {
        form: update(this.state.form, {
          [type]: {
            value: { $set: option.value }
          }
        })
      }
    }, () => this.validate(type, null), () => {
    })
  }
  handleFormCheckbox({ target: { name } }) {
    this.setState({
      form: update(this.state.form, {
        [name]: {
          value: (value) => !value
        }
      })
    })
  }

  handleAddressChange(type, event) {
    let { target: { name, value } } = event
    switch (name) {
      case 'address1':
        if (value && !ADDRESS.test(value)) {
          return
        }
        break
      case 'address2':
        if (value && !ADDRESS.test(value)) {
          return
        }
        break
      case 'postcode':
        if (value && !AUSTRALIAN_POSTCODE_SOFT.test(value)) {
          return
        }
        break
      case 'town':
        value = value.toUpperCase()
        if (value && !ADDRESS.test(value)) {
          return
        }
        break
      default:
    }
    this.setState({
      form: update(this.state.form, {
        [type]: {
          [name]: {
            value: { $set: value }
          }
        }
      })
    }, () => this.validate(name, type))
  }

  handleBankDetailsChange(type, event) {
    let { target: { name, value } } = event
    switch (name) {
      case 'accountName':
        if (value && !ACCOUNT_NAME_NUMBER.test(value)) {
          return
        }
        break
      case 'BSB':
        if (value && !BSB.test(value)) {
          return
        }
        break
      case 'accountNumber':
        if (value && !NUMBER_REGEXP.test(value)) {
          return
        }
        break
      default:
    }

    this.setState({
      form: update(this.state.form, {
        [type]: {
          [name]: {
            value: { $set: value }
          }
        }
      })
    }, () => this.validate(name, type))
  }
  handleLocationState(type, option, { action }) {
    if (action !== 'select-option') {
      return
    }
    this.setState(() => {
      if (type === 'officeAddress') {
        return {
          form: update(this.state.form, {
            [type]: {
              state: {
                value: { $set: option.value }
              }
            }
          }),
          typeOfUser: ['NSW', 'QLD'].includes(option.value) ? 'LLA' : 'ULA'
        }
      } else {
        return {
          form: update(this.state.form, {
            [type]: {
              state: {
                value: { $set: option.value }
              }
            }
          })
        }
      }
    }, () => this.validate('state', type), () => {
    })
  }
  handleanotherStatePIC(type, option, { action }) {
    if (action !== 'select-option') {
      return
    }
    this.setState(() => {
      return {
        form: update(this.state.form, {
          [type]: {
            value: { $set: option.value }
          }
        })
      }
    }, () => this.validate('anotherStatePIC', null), () => {
    })
  }

  validate(name, address, /*bankDetails*/) {
    let stateValue = (this.state.form.anotherStatePIC.value && this.state.form.isPICFromAnotherState.value === "Yes") ? this.state.form.anotherStatePIC.value : this.state.form.officeAddress.state.value;
    let validateResult
    switch (name) {
      case 'firstName':
        validateResult = validateFirstName(this.state.form[name].value)
        break
      case 'lastName':
        validateResult = validateLastName(this.state.form[name].value)
        break
     case 'companyName':
          validateResult = validateCompanyName(this.state.form[name].value)
          break
     case 'givenName':
            validateResult = validateGivenName(this.state.form[name].value)
            break
     case 'legalNameOfTradingEntity':
              validateResult = validateLegalNameOfTradingEntityName(this.state.form[name].value)
              break
      case 'email':
        validateResult = validateEmail(this.state.form[name].value)
        break
      case 'password':
        validateResult = validatePassword(this.state.form[name].value)
        break
      case 'repassword':
        validateResult = validateRePassword(this.state.form[name].value, this.state.form.password.value)
        break
      case 'PIC':
        validateResult = validatePic(this.state.form[name].value, stateValue, { required: true })
        break
      case 'anotherStatePIC':
        validateResult = validateState(this.state.form[name].value)
        break
      case 'ACN':
          validateResult = validateAcn(this.state.form[name].value,{ required: true })
          break
      case 'ABN':
        validateResult = validateAbn(this.state.form[name].value, { required: true })
        break
      case 'agentName':
        validateResult = validateAgentName(this.state.form[name].value)
        break
      case 'tradingName':
        validateResult = validateTradingName(this.state.form[name].value)
        break
      case 'jobTitle':
        validateResult = validateJobTitle(this.state.form[name].value)
        break
      case 'website':
        validateResult = validateWebsite(this.state.form[name].value)
        break
      case 'town':
        if (address) {
          validateResult = validateTown(this.state.form[address][name].value)
        }
        break
      case 'postcode':
        if (address) {
          validateResult = validatePostcode(this.state.form[address][name].value)
        }
        break
      case 'state':
        if (address) {
          validateResult = validateState(this.state.form[address][name].value)
        }
        break
      case 'address1':
        if (address) {
          validateResult = validateAddress(this.state.form[address][name].value)
        }
        break
      case 'phone':
        validateResult = validatePhone(this.state.form[name].value)
        break
      case 'userOperationType':
        validateResult = userOperationType(this.state.form[name].value)
        break
      case 'secondaryPhone':
        validateResult = validateSecondaryPhone(this.state.form[name].value)
        break
      /*case 'accountName':
        if (bankDetails) {
          validateResult = validateAccountName(this.state.form[bankDetails][name].value)
        }
        break
      case 'accountNumber':
        if (bankDetails) {
          validateResult = validateAccountNumber(this.state.form[bankDetails][name].value)
        }
        break
      case "BSB":
        if (bankDetails) {
          validateResult = validateBsb(this.state.form[bankDetails][name].value)
        }
        break*/
      default:
        break
    }
    if (validateResult === undefined) {
      return
    }
    if (address) {
      this.setState({
        form: update(this.state.form, {
          [address]: {
            [name]: {
              valid: { $set: validateResult === '' },
              invalidMessage: { $set: validateResult }
            }
          }
        })
      })
    } /*else if (bankDetails) {
      this.setState({
        form: update(this.state.form, {
          [bankDetails]: {
            [name]: {
              valid: { $set: validateResult === '' },
              invalidMessage: { $set: validateResult }
            }
          }
        })
      })
    }*/ else {
      this.setState({
        form: update(this.state.form, {
          [name]: {
            valid: { $set: validateResult === '' },
            invalidMessage: { $set: validateResult }
          }
        })
      })
    }
  }

  validateAddressAndBankDetails() {
    const { form } = this.state
    return new Promise((resolve, reject) => {
      this.setState({
        form: update(form, {
          officeAddress: {
            town: {
              valid: { $set: validateTown(form.officeAddress.town.value) === '' },
              invalidMessage: { $set: validateTown(form.officeAddress.town.value) }
            },
            postcode: {
              valid: { $set: validatePostcode(form.officeAddress.postcode.value) === '' },
              invalidMessage: { $set: validatePostcode(form.officeAddress.postcode.value) }
            },
            state: {
              valid: { $set: validateState(form.officeAddress.state.value) === '' },
              invalidMessage: { $set: validateState(form.officeAddress.state.value) }
            },
            address1: {
              valid: { $set: validateAddress(form.officeAddress.address1.value) === '' },
              invalidMessage: { $set: validateAddress(form.officeAddress.address1.value) }
            }
          },
          postalAddress: {
            town: {
              valid: { $set: validateTown(form.postalAddress.town.value) === '' },
              invalidMessage: { $set: validateTown(form.postalAddress.town.value) }
            },
            postcode: {
              valid: { $set: validatePostcode(form.postalAddress.postcode.value) === '' },
              invalidMessage: { $set: validatePostcode(form.postalAddress.postcode.value) }
            },
            state: {
              valid: { $set: validateState(form.postalAddress.state.value) === '' },
              invalidMessage: { $set: validateState(form.postalAddress.state.value) }
            },
            address1: {
              valid: { $set: validateAddress(form.postalAddress.address1.value) === '' },
              invalidMessage: { $set: validateAddress(form.postalAddress.address1.value) }
            }
          },
          /*bankDetails: {
            accountName: {
              valid: { $set: validateAccountName(form.bankDetails.accountName.value) === '' },
              invalidMessage: { $set: validateAccountName(form.bankDetails.accountName.value) }
            },
            accountNumber: {
              valid: { $set: validateAccountNumber(form.bankDetails.accountNumber.value) === '' },
              invalidMessage: { $set: validateAccountNumber(form.bankDetails.accountNumber.value) }
            },
            BSB: {
              valid: { $set: validateBsb(form.bankDetails.BSB.value) === '' },
              invalidMessage: { $set: validateBsb(form.bankDetails.BSB.value) }
            }
          }*/
        })
      }, resolve)
    })
  }

  validateAbnAndPic() {
    const { form } = this.state
    let stateValue = (this.state.form.anotherStatePIC.value && this.state.form.isPICFromAnotherState.value === "Yes") ? this.state.form.anotherStatePIC.value : this.state.form.officeAddress.state.value;
    return new Promise((resolve, reject) => {
      this.setState({
        form: update(form, {
          ABN: {
            valid: { $set: validateAbn(form.ABN.value, { required: true }) === '' },
            invalidMessage: { $set: validateAbn(form.ABN.value, { required: true }) }
          },
          PIC: {
            valid: { $set: validatePic(this.state.form.PIC.value, stateValue, { required: true }) === '' },
            invalidMessage: { $set: validatePic(this.state.form.PIC.value, stateValue, { required: true }) }
          },
          agentName: {
            valid: { $set: validateAgentName(form.agentName.value) === '' },
            invalidMessage: { $set: validateAgentName(form.agentName.value) }
          },
          anotherStatePIC: {
            valid: { $set: validateState(form.anotherStatePIC.value) === '' },
            invalidMessage: { $set: validateState(form.anotherStatePIC.value) }
          }
        })
      }, resolve)
    })
  }
  validateCustomerDetails () {
    const { form } = this.state
    return new Promise((resolve) => {
      this.setState({
        form: update(form, {
          ACN: {
            valid: { $set: validateAcn(form.ACN.value, { required: true }) === '' },
            invalidMessage: { $set: validateAcn(form.ACN.value, { required: true }) }
          },
          companyName: {
            valid: { $set: validateCompanyName(form.companyName.value,{ required: true }) === '' },
            invalidMessage: { $set: validateCompanyName(form.companyName.value,{ required: true }) }
          },
          givenName: {
            valid: { $set: validateGivenName(form.givenName.value,{ required: true }) === '' },
            invalidMessage: { $set: validateGivenName(form.givenName.value,{ required: true }) }
          },
          lastName: {
            valid: { $set: validateLastName(form.lastName.value,{ required: true }) === '' },
            invalidMessage: { $set: validateLastName(form.lastName.value,{ required: true }) }
          },
          legalNameOfTradingEntity: {
            valid: { $set: validateLegalNameOfTradingEntityName(form.legalNameOfTradingEntity.value,{ required: true }) === '' },
            invalidMessage: { $set: validateLegalNameOfTradingEntityName(form.legalNameOfTradingEntity.value,{ required: true }) }
          }
        })
      }, resolve)
    })
  }

  allValidBuyerForm() {
    const { form, same } = this.state
    let agentNameValid = form.areYouUsingAgent.value === "Yes" ? form.agentName.valid : true
    let anotherStatePICValid = form.isPICFromAnotherState.value === "Yes" ? form.anotherStatePIC.valid : true

    let customerDetailsValid
    if(form.doYouTradeThroughCompany.value === "Yes") {
      customerDetailsValid = form.ACN.valid && form.companyName.valid && form.givenName.valid && form.lastName.valid
    }

    if(form.doYouTradeThroughCompany.value === "No") {
      customerDetailsValid = form.legalNameOfTradingEntity.valid && form.ABN.valid && form.givenName.valid && form.lastName.valid
    }

    let addressesValid = same
      ? (
        form.officeAddress.address1.valid &&
        form.officeAddress.address2.valid &&
        form.officeAddress.postcode.valid &&
        form.officeAddress.town.valid &&
        form.officeAddress.state.valid
      )
      : (
        form.officeAddress.address1.valid &&
        form.officeAddress.address2.valid &&
        form.officeAddress.postcode.valid &&
        form.officeAddress.town.valid &&
        form.officeAddress.state.valid &&
        form.postalAddress.address1.valid &&
        form.postalAddress.postcode.valid &&
        form.postalAddress.town.valid &&
        form.postalAddress.state.valid
      )
    if (
      addressesValid &&
      form.acceptedTerms.value === true  && form.PIC.valid && customerDetailsValid &&
      agentNameValid && anotherStatePICValid
    ) {
      return true
    }
  }

  reDirectToHomePage = () => {
    this.props.history.push('/main/upcoming');
    window.location.reload();
  }
  sendAnalytics() {
    if (this.props.cameFromWpSite) {
      ReactGA.event({
        category: 'Click on Register on WordPress site',
        action: 'Successful Registration: Successful Livestock Agent Register',
        label: 'Successful Registration: Successful Livestock Agent Register'
      }, ['WordPress'])
      this.props.changeStateProp('cameFromWpSite', false, 'user')
      this.props.changeStateProp('wpSiteName', 'Farmgate', 'user')
    }
    ReactGA.event({
      category: 'Successful Registration',
      action: 'Successful Registration: Successful Livestock Agent Register',
      label: 'Successful Registration: Successful Livestock Agent Register'
    }, ['WebApp'])
  }

  async handleSubmit() {
    await this.validateAddressAndBankDetails()
    await this.validateAbnAndPic()
    await this.validateCustomerDetails()
    if (this.allValidBuyerForm()) {
      this.updateUser()
    } else {
      this.props.showModal({
        message: 'Please correctly fill required fields'
      })
    }
  }
  async updateUser() {
    try {
      this.props.showSpinner(true)
      let data = convertData(this.state.form)
      let putData = _pick(data, [
        'type',
        'ABN',
        'PIC',
        'officeAddress',
        'postalAddress',
        'acceptedTerms',
        'areYouUsingAgent',
        'agentName',
        'stockTypes',
        'userRegisteredFrom',
        'anotherStatePIC',
        'companyName',
        'givenName',
        'lastName',
        'ACN',
        'legalNameOfTradingEntity',
        'doYouTradeThroughCompany'
      ])
      if (!Object.keys(putData).length) {
        this.props.showModal({
          message: 'Please correctly provide at least 1 field'
        })
        this.props.showSpinner(false)
        return
      }

      if (this.state.same) {
        putData.postalAddress = { ...putData.officeAddress }
      }
      if (!putData.stockTypes) {
        putData.stockTypes = [...this.state.form.stockTypes]
      }
      if (this.state.form.areYouUsingAgent.value === "Yes") {
        putData.areYouUsingAgent = true
      } else if (this.state.form.areYouUsingAgent.value === "No") {
        putData.areYouUsingAgent = false
      }

      if (this.state.form.doYouTradeThroughCompany.value === "Yes") {
        putData.doYouTradeThroughCompany = true
      } else if (this.state.form.doYouTradeThroughCompany.value === "No") {
        putData.doYouTradeThroughCompany = false
      }
      putData.isRequestedAsBuyer = true;
      putData.status = 'registered-updated'
      putData.customerDetails = {
        companyName : putData.companyName,
        givenName : putData.givenName,
        lastName : putData.lastName,
        ACN : putData.ACN,
        legalNameOfTradingEntity : putData.legalNameOfTradingEntity
      }

      delete putData.companyName;
      delete putData.lastName;
      delete putData.ACN;
      delete putData.legalNameOfTradingEntity;
      delete putData.givenName;

      let putUser = await Api.putUser(putData)
      this.props.showSpinner(false)
      if (putUser) {
        this.props.changeStateProp('isTermsOpened',false,'user') 
        this.props.changeStateProp('isPrivacyOpened',false,'user')
        this.props.showModal({
          message: 'Thank you for registering to become a Buyer. Please allow 24 hours for this request to be processed by our team',
          buttons: [
            {
              text: 'OK',
              onPress: () => {
                this.reDirectToHomePage()
              }
            }]
        })
      }
    } catch (e) {
      console.error('error', e)
      this.props.showSpinner(false)
    }
  }

  handleTermsClick = () => {
    this.setState({overlayType: 'terms'})
    this.props.changeStateProp('isTermsOpened',true,'user') 
  };
  handlePrivacyClick = () => {
    this.setState({overlayType: 'policy'})
    this.props.changeStateProp('isPrivacyOpened',true,'user')
  };

  render() {
    const {
      form,
      same
    } = this.state

    this.mapStates = this.mapOptions(this.props.locationStates)

    return (
      <ScrollToTop xCoord={0} yCoord={0}>
        <div className='auth-wrapper registration'>
          <Header />
          <div className='access-request-form-wrapper'>
            <button
              type='button'
              className='top-back-button'
              onClick={() => {
                if(this.state.overlayType !== '') {
                  this.setState({overlayType: ''})
                  window.scrollTo(0, document.body.scrollHeight);
                } else {
                  this.props.history.push('/main/manage')
                }
              }}
            >
              <i className='btn-left'>
                <FontAwesomeIcon icon={faCaretLeft} />
              </i>
              <span className='left-padding'>{this.state.overlayType !== '' ? 'Back To Form' : 'Back'}</span>
            </button>
            <div className='content'>
              {this.state.overlayType === '' ? 
              <div className='form-wrapper'>
                <form>
                 <div className='cd-header'>
                    <FontAwesomeIcon className='icons-style' icon={faUser} />
                    <div className='cd-title'>Customer Details</div>
                  </div>
                  <h3 className='info-paragraph justify'>Please complete the following information in order for us to process your application.</h3>
                  <div className='item-rows'>
                    <p className={`titles ${form.doYouTradeThroughCompany.required}`}>Do you trade through a company?*:</p>
                    <FgSelect
                      placeholder={`Do you trade through a company?* ${form.doYouTradeThroughCompany.required ? '*' : ''}`}
                      maxMenuHeight={180}
                      options={[{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }]}
                      value={form.doYouTradeThroughCompany
                        ? this.mapSelected(form.doYouTradeThroughCompany.value)
                        : null}
                      statedetails={true}
                      onChange={this.handleYesorNoDropdown.bind(null, 'doYouTradeThroughCompany')}
                      validData={form.doYouTradeThroughCompany.valid}
                      warningReason={form.doYouTradeThroughCompany.invalidMessage}
                    />
                  </div> 

                  {form.doYouTradeThroughCompany.value === "Yes" &&

                  <React.Fragment>
                    <div className='item-rows'>
                      <p className={`titles ${form.companyName.required}`}>Company Name*:</p>
                      <FdInput className='input'
                        type='text'
                        placeholder={`Company Name${form.companyName.required ? '*' : ''}`}
                        value={form.companyName.value}
                        name='companyName'
                        onChange={this.handleInputChange}
                        onBlur={this.onBlur}
                        validData={form.companyName.valid}
                        warningReason={form.companyName.invalidMessage}
                      />
                    </div>

                 <div className='item-rows'>
                    <p className={`titles ${form.ACN.required}`}>ACN*:</p>
                    <FdInput className='input'
                      type='text'
                      placeholder={`ACN${form.ACN.required ? '*' : ''}`}
                      value={form.ACN.value}
                      name='ACN'
                      onChange={this.handleInputChange}
                      onBlur={this.onBlur}
                      validData={form.ACN.valid}
                      warningReason={form.ACN.invalidMessage} />
                 </div>
                    <div className='item-rows'>
                      <p className={`titles ${form.givenName.required}`}>Given Name(s)*:</p>
                      <FdInput className='input'
                        type='text'
                        placeholder={`Given Name${form.givenName.required ? '*' : ''}`}
                        value={form.givenName.value}
                        name='givenName'
                        onChange={this.handleInputChange}
                        onBlur={this.onBlur}
                        validData={form.givenName.valid}
                        warningReason={form.givenName.invalidMessage}
                      />
                    </div>

                    <div className='item-rows'>
                      <p className={`titles ${form.lastName.required}`}>Last Name*:</p>
                      <FdInput className='input'
                        type='text'
                        placeholder={`Last Name${form.lastName.required ? '*' : ''}`}
                        value={form.lastName.value}
                        name='lastName'
                        onChange={this.handleInputChange}
                        onBlur={this.onBlur}
                        validData={form.lastName.valid}
                        warningReason={form.lastName.invalidMessage}
                      />
                    </div>

                    
                    </React.Fragment>
                    
                  }
                  {form.doYouTradeThroughCompany.value === "No" &&

                              <React.Fragment>
                              <div className='item-rows'>
                                <p className={`titles ${form.legalNameOfTradingEntity.required}`}>Full legal name of trading entity*:</p>
                                <FdInput className='input'
                                  type='text'
                                  placeholder={`Full legal name of trading entity${form.legalNameOfTradingEntity.required ? '*' : ''}`}
                                  value={form.legalNameOfTradingEntity.value}
                                  name='legalNameOfTradingEntity'
                                  onChange={this.handleInputChange}
                                  onBlur={this.onBlur}
                                  validData={form.legalNameOfTradingEntity.valid}
                                  warningReason={form.legalNameOfTradingEntity.invalidMessage}
                                />
                              </div>

                              <div className='item-rows'>
                              <p className={`titles ${form.ABN.required}`}>ABN*:</p>
                              <FdInput className='input'
                                type='text'
                                placeholder={`ABN${form.ABN.required ? '*' : ''}`}
                                value={form.ABN.value}
                                name='ABN'
                                onChange={this.handleInputChange}
                                onBlur={this.onBlur}
                                validData={form.ABN.valid}
                                warningReason={form.ABN.invalidMessage} />
                              </div>
                              <div className='item-rows'>
                                <p className={`titles ${form.givenName.required}`}>Given Name(s)*:</p>
                                <FdInput className='input'
                                  type='text'
                                  placeholder={`Given Name${form.givenName.required ? '*' : ''}`}
                                  value={form.givenName.value}
                                  name='givenName'
                                  onChange={this.handleInputChange}
                                  onBlur={this.onBlur}
                                  validData={form.givenName.valid}
                                  warningReason={form.givenName.invalidMessage}
                                />
                              </div>

                              <div className='item-rows'>
                                <p className={`titles ${form.lastName.required}`}>Last Name*:</p>
                                <FdInput className='input'
                                  type='text'
                                  placeholder={`Last Name${form.lastName.required ? '*' : ''}`}
                                  value={form.lastName.value}
                                  name='lastName'
                                  onChange={this.handleInputChange}
                                  onBlur={this.onBlur}
                                  validData={form.lastName.valid}
                                  warningReason={form.lastName.invalidMessage}
                                />
                              </div>


                              </React.Fragment>

                              }
                  <div className='cd-header'>
                    <FontAwesomeIcon className='icons-style' icon={faMapMarkerAlt} />
                    <div className='cd-title'>Address Details</div>
                  </div>
                  <h3 className='info-paragraph justify'>Please complete the following information in order for us to assess your eligibility to access and use the site.</h3>
                  <div className='item-rows'>
                    <p className={`titles ${form.officeAddress.address1.required}`}>Office Address*:</p>
                    <FdInput className='input'
                      type='text'
                      placeholder={`Office Address (not a PO Box address)${form.officeAddress.address1.required ? '*' : ''}`}
                      value={form.officeAddress.address1.value}
                      name='address1'
                      onChange={this.handleAddressChange.bind(null, 'officeAddress')}
                      onBlur={this.onAddressBlur.bind(null, 'officeAddress')}
                      validData={form.officeAddress.address1.valid}
                      warningReason={form.officeAddress.address1.invalidMessage}
                    />
                  </div>
                  <div className='item-rows'>
                    <p className={`titles ${form.officeAddress.address2.required}`}>Office Address Line 2:</p>
                    <FdInput className='input'
                      type='text'
                      placeholder={`Office Address Line 2${form.officeAddress.address2.required ? '*' : ''}`}
                      value={form.officeAddress.address2.value}
                      name='address2'
                      onChange={this.handleAddressChange.bind(null, 'officeAddress')}
                      onBlur={this.onAddressBlur.bind(null, 'officeAddress')} />
                  </div>
                  <div className='item-rows'>
                    <p className={`titles ${form.officeAddress.town.required}`}>Town*:</p>
                    <FdInput className='input'
                      type='text'
                      placeholder={`Town${form.officeAddress.town.required ? '*' : ''}`}
                      value={form.officeAddress.town.value}
                      name='town'
                      onChange={this.handleAddressChange.bind(null, 'officeAddress')}
                      onBlur={this.onAddressBlur.bind(null, 'officeAddress')}
                      validData={form.officeAddress.town.valid}
                      warningReason={form.officeAddress.town.invalidMessage} />
                  </div>
                  {/* <div className='auth-columns'> */}
                  <div className='item-rows'>
                    <p className={`titles ${form.officeAddress.state.required}`}>State*:</p>
                    <FgSelect
                      placeholder={`State${form.officeAddress.state.required ? '*' : ''}`}
                      maxMenuHeight={180}
                      options={this.mapStates}
                      value={form.officeAddress.state.value
                        ? this.mapSelected(form.officeAddress.state.value)
                        : null}
                      onChange={this.handleLocationState.bind(null, 'officeAddress')}
                      isSearchable={false}
                      statedetails={true}
                      validData={form.officeAddress.state.valid}
                      warningReason={form.officeAddress.state.invalidMessage}
                    />
                  </div>
                  <div className='item-rows'>
                    <p className={`titles ${form.officeAddress.postcode}`}>Postcode*:</p>
                    <FdInput className='input'
                      type='text'
                      placeholder={`Postcode ${form.officeAddress.postcode ? '*' : ''}`}
                      value={form.officeAddress.postcode.value}
                      name='postcode'
                      onChange={this.handleAddressChange.bind(null, 'officeAddress')}
                      onBlur={this.onAddressBlur.bind(null, 'officeAddress')}
                      validData={form.officeAddress.postcode.valid}
                      warningReason={form.officeAddress.postcode.invalidMessage} />
                  </div>
                  {/* </div> */}
                  <AuthCheckbox
                    type='checkbox'
                    name='same'
                    label={<span><strong>Postal Address</strong> (select if same as above)</span>}
                    labelClassName='padded-left'
                    checked={same}
                    onChange={this.handleCheckbox} />
                  {
                    same
                      ? <div>
                        <div className='item-rows'>
                          <p className={`titles ${form.officeAddress.address1.required}`}>Postal Address Line 1*:</p>
                          <FdInput
                            className='input'
                            type='text'
                            placeholder={`Postal Address Line 1${form.officeAddress.address1.required ? '*' : ''}`}
                            disabled
                            value={form.officeAddress.address1.value}
                            name='address1'
                          />
                        </div>
                        <div className='item-rows'>
                          <p className={`titles`}>Postal Address Line 2:</p>
                          <FdInput
                            className='input'
                            type='text'
                            placeholder={`Postal Address Line 2`}
                            disabled
                            value={form.officeAddress.address2.value}
                            name='address2' />
                        </div>
                        <div className='item-rows'>
                          <p className={`titles ${form.officeAddress.town.required}`}>Town*:</p>
                          <FdInput
                            className='input'
                            type='text'
                            placeholder={`Town${form.officeAddress.town.required ? '*' : ''}`}
                            disabled
                            value={form.officeAddress.town.value}
                            name='town' />
                        </div>
                        {/* <div className='auth-columns'> */}
                        <div className='item-rows'>
                          <p className={`titles ${form.officeAddress.state.required}`}>State*:</p>
                          <FgSelect
                            placeholder={`State${form.officeAddress.state.required ? '*' : ''}`}
                            value={form.officeAddress.state.value
                              ? this.mapSelected(form.officeAddress.state.value)
                              : null}
                            statedetails={true}
                            isDisabled
                          />
                        </div>
                        <div className='item-rows'>
                          <p className={`titles ${form.officeAddress.postcode}`}>Postcode*:</p>
                          <FdInput
                            className='input'
                            type='text'
                            placeholder={`Postcode${form.officeAddress.postcode ? '*' : ''}`}
                            disabled
                            value={form.officeAddress.postcode.value}
                            name='postcode' />
                        </div>
                        {/* </div> */}
                      </div>
                      : <div>
                        <div className='item-rows'>
                          <p className={`titles ${form.postalAddress.address1.required}`}>Postal Address Line 1*:</p>
                          <FdInput
                            className='input'
                            type='text'
                            placeholder={`Postal Address Line 1${form.postalAddress.address1.required ? '*' : ''}`}
                            value={form.postalAddress.address1.value}
                            name='address1'
                            onChange={this.handleAddressChange.bind(null, 'postalAddress')}
                            onBlur={this.onAddressBlur.bind(null, 'postalAddress')}
                            validData={form.postalAddress.address1.valid}
                            warningReason={form.postalAddress.address1.invalidMessage}
                          />
                        </div>
                        <div className='item-rows'>
                          <p className={`titles`}>Postal Address Line 2:</p>
                          <FdInput
                            className='input'
                            type='text'
                            placeholder={`Postal Address Line 2`}
                            value={form.postalAddress.address2.value}
                            name='address2'
                            onChange={this.handleAddressChange.bind(null, 'postalAddress')}
                            onBlur={this.onAddressBlur.bind(null, 'postalAddress')} />
                        </div>
                        <div className='item-rows'>
                          <p className={`titles ${form.postalAddress.town.required}`}>Town*:</p>
                          <FdInput
                            className='input'
                            type='text'
                            placeholder={`Town${form.postalAddress.town.required ? '*' : ''}`}
                            value={form.postalAddress.town.value}
                            name='town'
                            onChange={this.handleAddressChange.bind(null, 'postalAddress')}
                            onBlur={this.onAddressBlur.bind(null, 'postalAddress')}
                            validData={form.postalAddress.town.valid}
                            warningReason={form.postalAddress.town.invalidMessage} />
                        </div>
                        {/* <div className='auth-columns'> */}
                        <div className='item-rows'>
                          <p className={`titles ${form.postalAddress.state.required}`}>State*:</p>
                          <FgSelect
                            placeholder={`State${form.postalAddress.state.required ? '*' : ''}`}
                            maxMenuHeight={180}
                            options={this.mapStates}
                            value={form.postalAddress.state.value
                              ? this.mapSelected(form.postalAddress.state.value)
                              : null}
                            onChange={this.handleLocationState.bind(null, 'postalAddress')}
                            isSearchable={false}
                            statedetails={true}
                            validData={form.postalAddress.state.valid}
                            warningReason={form.postalAddress.state.invalidMessage}
                          />
                        </div>
                        <div className='item-rows'>
                          <p className={`titles ${form.postalAddress.postcode.required}`}>Postcode*:</p>
                          <FdInput
                            className='input'
                            type='text'
                            placeholder={`Postcode${form.postalAddress.postcode.required ? '*' : ''}`}
                            value={form.postalAddress.postcode.value}
                            name='postcode'
                            onChange={this.handleAddressChange.bind(null, 'postalAddress')}
                            onBlur={this.onAddressBlur.bind(null, 'postalAddress')}
                            validData={form.postalAddress.postcode.valid}
                            warningReason={form.postalAddress.postcode.invalidMessage} />
                        </div>

                        {/* </div> */}
                      </div>
                  }
                  <div className='cd-header'>
                    <FontAwesomeIcon className='icons-style' icon={faBriefcase} />
                    <div className='cd-title'>Business Details</div>
                  </div>
                  <div className='item-rows'>
                    <p className={`titles ${form.ABN.required}`}>PIC*:</p>
                    <FdInput className='input'
                      type='text'
                      placeholder={`PIC ${form.PIC.required ? '*' : ''}`}
                      value={form.PIC.value}
                      name='PIC'
                      onChange={this.handleInputChange}
                      onBlur={this.onBlur}
                      validData={form.PIC.valid}
                      warningReason={form.PIC.invalidMessage} />
                  </div>

                  <div className='item-rows'>
                    <p className={`titles ${form.isPICFromAnotherState.required}`}>Is your PIC No. located in a State or territory different from your Office or Postal Address above *:</p>
                    <FgSelect
                      placeholder={`Is your PIC No. located in a State or territory different from your Office or Postal Address above ${form.isPICFromAnotherState.required ? '*' : ''}`}
                      maxMenuHeight={180}
                      options={[{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }]}
                      value={form.isPICFromAnotherState
                        ? this.mapSelected(form.isPICFromAnotherState.value)
                        : null}
                      statedetails={true}
                      onChange={this.handleYesorNoDropdown.bind(null, 'isPICFromAnotherState')}
                      validData={form.isPICFromAnotherState.valid}
                      warningReason={form.isPICFromAnotherState.invalidMessage}
                    />
                  </div>

                  {(form.isPICFromAnotherState.value === "Yes") &&
                    <div className='item-rows long-title'>
                      <p className={`titles ${form.anotherStatePIC.required}`}>PIC State*:</p>
                      <FgSelect
                        placeholder={`PIC State${form.anotherStatePIC.required ? '*' : ''}`}
                        maxMenuHeight={150}
                        options={this.mapStates}
                        value={form.anotherStatePIC.value
                          ? this.mapSelected(form.anotherStatePIC.value)
                          : null}
                        onChange={this.handleanotherStatePIC.bind(null, 'anotherStatePIC')}
                        isSearchable={false}
                        statedetails={true}
                        validData={form.anotherStatePIC.valid}
                        warningReason={form.anotherStatePIC.invalidMessage}
                      />
                    </div>
                  }
                  <div className='cd-header'>
                    <FontAwesomeIcon className='icons-style' icon={faUser} />
                    <div className='cd-title'>Agent Details</div>
                  </div>
                  <div className='item-rows'>
                    <p className={`titles ${form.areYouUsingAgent.required}`}>If you are a vendor looking to sell through our platform, are you planning on using an Agent?*:</p>
                    <FgSelect
                      placeholder={`If you are a vendor looking to sell through our platform, are you planning on using an Agent?* ${form.areYouUsingAgent.required ? '*' : ''}`}
                      maxMenuHeight={180}
                      options={[{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }]}
                      value={form.areYouUsingAgent
                        ? this.mapSelected(form.areYouUsingAgent.value)
                        : null}
                      statedetails={true}
                      onChange={this.handleYesorNoDropdown.bind(null, 'areYouUsingAgent')}
                      validData={form.areYouUsingAgent.valid}
                      warningReason={form.areYouUsingAgent.invalidMessage}
                    />
                  </div>
                  {form.areYouUsingAgent.value === "Yes" &&
                    <div className='item-rows long-title'>
                      <p className={`titles ${form.agentName.required}`}>Agent Name*:</p>
                      <FdInput className='input'
                        type='text'
                        placeholder={`Agent Name${form.agentName.required ? '*' : ''}`}
                        value={form.agentName.value}
                        name='agentName'
                        onChange={this.handleInputChange}
                        onBlur={this.onBlur}
                        validData={form.agentName.valid}
                        warningReason={form.agentName.invalidMessage}
                      />
                    </div>
                  }

                  {
                    
                    <div className='application'>
                      <div className='cd-header'>
                        <FontAwesomeIcon className='icons-style' icon={faUser} />
                        <div className='cd-title'>Application</div>
                      </div>
                    <div>
                      <span> I/we: </span>
                      <br /><br />
                      <ul>
                        <li>apply for an Agreement with Farm Gate Auctions Pty. Ltd. (FarmGate) on the Terms;</li>
                        <li>agree to information provided by this application being held, used and disclosed in accordance with </li>
                      </ul>
                      <br />
                      <span> FarmGate’s Privacy Policy:</span>
                      <br /><br />
                      <ul>
                        <li>have accessed the Terms and the Privacy Policy; and</li>
                        <li>confirm that all of this application is correct and that FarmGate may rely on it.</li>
                      </ul>
                      <br /><br />
                      <div style={{display : 'flex',flexDirection:"column"}}>      
                            <span className={`auth-checkbox-label terms padded-left}`}>{<span> FarmGate Auctions <span className='link-terms' rel='noopener noreferrer' style={{ color: '#4f1e86' }} onClick={this.handleTermsClick}>terms and conditions.</span></span>}</span>
                            <span className={`auth-checkbox-label terms padded-left`}>{<span> FarmGate Auctions <span  className='link-terms' rel='noopener noreferrer' style={{color: '#4f1e86'}} onClick={this.handlePrivacyClick}>privacy policy.</span></span>}</span>
                      </div>
                    </div>
                    </div>
                  }

                  <AuthCheckbox
                    name='acceptedTerms'
                     label={<span> I have read and agree to the FarmGate Auctions Terms & Conditions and Privacy Policy.</span>}
                    // label={<span>I have read and agree to FarmGate Auctions <a href='https://live.farmgateauctions.com.au/#/main/tools/terms' onClick={this.handleTermsClick} target='_blank' className='link-terms' rel='noopener noreferrer'>terms and conditions.</a></span>}
                    // label2={<span>I have read and agree to FarmGate Auctions <a href='https://live.farmgateauctions.com.au/#/main/tools/privacy-policy' onClick={this.handlePrivacyClick} target='_blank' className='link-terms' rel='noopener noreferrer'>privacy policy.</a></span>}
                    labelClassName='terms padded-left'
                    labelClassName2={'padded-top'}
                    checked={form.acceptedTerms.value}
                    onChange={this.handleFormCheckbox}
                    disabled = {!(this.props.isPrivacyOpened && this.props.isTermsOpened)}
                  />
                  {
                    <div className='mustviewterms'>
                      <p  className='note'>
                      To select the box you MUST view the Terms & Conditions and Privacy Policy
                      </p>
                    </div>
                  }
                  <div className='auth-pagination auth-columns'>
                    <button
                      type='button'
                      className='auth-button auth-button-success'
                      onClick={() => this.props.history.push('/main/manage')}
                    >
                      <i className='btn-left'>
                        <FontAwesomeIcon icon={faCaretLeft} />
                      </i>
                      <span>Back</span>
                    </button>
                    <button
                      type='button'
                      disabled={!form.acceptedTerms.value}
                    // disabled = {!(this.props.isPrivacyOpened && this.props.isTermsOpened && form.acceptedTerms.value)}
                      onClick={this.handleSubmit}
                      className='auth-button auth-button-success btn-text-centered'
                    >
                      <span>Submit</span>
                    </button>
                  </div>
                </form>
              </div>
                : <div className='conditions'>
                  <h1 className='fg-header'>{this.state.overlayType === 'terms' ? 'Terms & Conditions' : 'Privacy Policy'}</h1>
                  {this.state.overlayType === 'terms' ? <div className='terms-container'>
                    {
                      this.state.terms.map((item, index) => {
                        return <Section key={index} title={item.categoryName} text={[item.terms]} />
                      })
                    }
                  </div> :
                    <div className='policy-container'>
                      {privacy_policy.map((section, index) => {
                        return (
                          <div key={index}>
                            <Section title={section.title} text={[{ text: section.description }]} />
                          </div>
                        )
                      })}
                    </div>}
                  <div className='auth-pagination auth-columns half-width'>
                    <button
                      onClick={() => {
                        this.setState({ overlayType: '' })
                        window.scrollTo(0, document.body.scrollHeight);
                      }}
                      className='auth-button auth-button-success btn-text-centered'
                    >
                      <span>Continue Completing Form</span>
                    </button>
                  </div>
                </div>}
            </div>
          </div>
          <Footer />
        </div>
      </ScrollToTop>
    )
  }
}

AsBuyer.propTypes = {
  showModal: PropTypes.func.isRequired,
  showSpinner: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default withRouter(AsBuyer)
