import React, { Component } from 'react'
import { subTitles } from '../../../constants/strings'
import ScrollToTop from '../../ScrollToTop/ScrollToTop'
import Toggle from 'react-toggle'
import BackButton from '../../BackButton/BackButton'

interface props {
  bidSound: boolean,
  outBidSound: boolean,
  wonSound: boolean,
  autoBidSound: boolean,
  countdownSound: boolean,
  changeStateProp: Function,
}
export default class MySounds extends Component<props> {
  toggleSound (name: string, value: boolean) {
    window.localStorage.setItem(name, (!value).toString())
    this.props.changeStateProp(name, !value, 'sound')
  }
  renderSoundTypeToggle (title: string, subTitle: string, type: string, value: boolean) {
    return (
      <div className='sound-item'>
        <div className='sound-item-title'>
          <p className='sound-item-title-main'>{title}:</p>
          <p className='sound-item-title-secondary'>{subTitle}</p>
        </div>
        <div className='sound-item-toggle'>
          <span>No</span>
          <Toggle data-testid='toggle' icons={false} onChange={() => {
            this.toggleSound(type, value)
          }} checked={value} />
          <span>Yes</span>
        </div>
      </div>
    )
  }
  render () {
    const { bidSound, outBidSound, wonSound, autoBidSound, countdownSound } = this.props
    return (
      <ScrollToTop xCoord={0} yCoord={0}>
      <div>
        <h1 className='fg-header'>Sounds</h1>
        <p className='fg-paragraph'>{subTitles.SOUNDS}</p>
        <BackButton onPress={() => {
          window.history.go(-1)
        }} name='Back to My Admin' />
        <hr className='separator' />
        <div className='sound-wrapper'>
          {this.renderSoundTypeToggle('Bids', 'All auction bids', 'bidSound', bidSound)}
          {this.renderSoundTypeToggle('Outbid', 'You have been outbid', 'outBidSound', outBidSound)}
          {this.renderSoundTypeToggle('Won', 'You have won a lot', 'wonSound', wonSound)}
          {this.renderSoundTypeToggle('Auto-Bid', 'Your auto-bid has been reached', 'autoBidSound', autoBidSound)}
          {this.renderSoundTypeToggle('Lot Countdown', '30 seconds until the Lot you are bidding on closes', 'countdownSound', countdownSound)}
        </div>
      </div>
       </ScrollToTop>
    )
  }
}
