import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'

import ProfileContainer from './ProfileContainer'
import { onEnter } from '../../utils/UpdateData'

class ProfileRoute extends PureComponent {
  render () {
    return (
      <div className='app-route'>
        <div className='body-container'>
          <div className='inner-container'>
            {onEnter(
              <Switch>
                <Route exact strict path='/main/profile'
                  component={ProfileContainer} />
                <Redirect to='/main/profile' />
              </Switch>
            )}
          </div>
        </div>
      </div>
    )
  }
}

ProfileRoute.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

ProfileRoute.defaultProps = {}

export default ProfileRoute
