import React from 'react'
import { isSeller } from '../../../utils/UpdateData'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faEdit,
  faCheck,
  faTimes,
  faKey
} from '@fortawesome/fontawesome-free-solid'
import BackButton from '../../BackButton/BackButton'
import { subTitles } from '../../../constants/strings'
const ProfileHeader = (props) => {
  const {
    logo,
    editMode,
    user,
    handleSubmit,
    editHandle,
    cancelHandle,
    openModal,
    uploadLogo,
    goBack
  } = props
  return (
    <React.Fragment>
      <h1 className='fg-header'>My Profile</h1>
      <p className='fg-paragraph'>
        {
          isSeller()
            ? subTitles.MY_PROFILE
            : subTitles.MY_PROFILE_BUYERS
        }
      </p>
      <div className='buttons'>
        {renderButton('back', editMode, editHandle, cancelHandle, openModal, handleSubmit, null, goBack)}
        {renderButton('edit', editMode, editHandle, cancelHandle, openModal, handleSubmit)}
        {renderButton('logo', editMode, editHandle, cancelHandle, openModal, handleSubmit, uploadLogo)}
        {renderButton('change-password', editMode, editHandle, cancelHandle, openModal, handleSubmit)}
      </div>
      <div className='logo'>
        {logo && <img src={logo.isNew ? logo.data : logo.url} alt='Logo' />}
      </div>
      <p className='user-id'>User ID: {user.shortId}</p>
    </React.Fragment>
  )
}

const renderButton = (button, editMode, editHandle, cancelHandle, openModal, handleSubmit, uploadLogo, goBack) => {
  switch (button) {
    case 'back':
      return (
        <BackButton onPress={() => {
          goBack()
        }} name='Back to My Admin' />
      )
    case 'edit':
      return (
        <div className='btn first-btn' onClick={editMode ? handleSubmit : editHandle}>
          <span className='button-text'>{editMode ? 'Save Profile' : 'Edit'}</span>
          <span className={editMode ? 'icon-wrapper icon-success' : 'icon-wrapper icon-warning'}>
            <FontAwesomeIcon icon={editMode ? faCheck : faEdit} />
          </span>
        </div>
      )
    case 'logo':
      return (
        editMode
          ? <div className='btn' onClick={cancelHandle}>
            <span className='button-text'>Cancel</span>
            <span className='icon-wrapper icon-red'>
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </div>
          : <label className='btn file-upload'>
            <span className='button-text'>Change Logo</span>
            <span className='icon-wrapper icon-warning'>
              <FontAwesomeIcon icon={faEdit} />
            </span>
            <input
              className='red-input'
              name='logo'
              accept='.jpg,.jpeg,.png'
              type='file'
              onChange={uploadLogo}
            />
          </label>
      )
    case 'change-password':
      return (
        <div className='btn first-btn' onClick={openModal}>
          <span className='button-text'>Change Password</span>
          <span className='icon-wrapper icon-warning'>
            <FontAwesomeIcon icon={faKey} />
          </span>
        </div>
      )
    default:
      break
  }
}

export default ProfileHeader
