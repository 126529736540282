import React from 'react'

import LotForm from '../../LotForm/LotForm'
import Api from '../../../services/api'
import BackButton from '../../BackButton/BackButton'
import { isAvailableAuction } from '../../../utils/Auctions'

export default class EditReoffer extends LotForm {
  constructor (props) {
    super(props)
    this.mode = 'reoffer'
    this.mainUrl = '/main/manage/edit-reoffers'
  }

  async submitLot (lot) {
    this.props.showSpinner(true)
    let earliestDelivery =
    lot.details.values.find(detail => detail.title === 'Delivery')
      .values.find(delivery => delivery.publicId === 'earliestDelivery')
      .value
    let latestDelivery =
    lot.details.values.find(detail => detail.title === 'Delivery')
      .values.find(delivery => delivery.publicId === 'latestDelivery')
      .value
    let data = {
      adjustWeight: lot.adjustWeight,
      startPrice: lot.bidding !== 'kg' ? lot.startPrice * 100 : lot.startPrice,
      bidIncrementCents: lot.bidIncrementCents,
      reserveCents: lot.bidding !== 'kg' ? lot.reserveCents * 100 : lot.reserveCents,
      earliestDelivery,
      latestDelivery
    }
    let res
    if (!earliestDelivery) {
      this.props.showModal({
        message: 'Please enter Earliest Delivery This is a required field*'
      })
    } else {
      res = await Api.updateReoffersLot(this.state.auction._id, lot._id, data)
    }
    if (res) {
      this.props.mergeStateProp('lots', [res.lot], 'data')
      this.props.history.push('/main/manage/my-submitted')
    }
    this.props.showSpinner(false)
  }

  validateDraftLot (draftLot) {
    let res = this.auctionValidation()
    if (res.valid) {
      if (!isAvailableAuction(this.state.auction)) {
        res = {
          valid: false,
          message: 'Please Choose a Different Auction'
        }
      }
    }
    if (res.valid) {
      res = this.bidCentsValidation(draftLot)
    }
    return res
  }
  componentDidUpdate (prevProps, prevState) {
    if ((prevProps.match.params.kindTitle !== this.props.match.params.kindTitle) ||
        (prevProps.match.params.draftId !== this.props.match.params.draftId)) {
      this.reloadPage()
    }
  }
  renderBackButton () {
    return (
      <BackButton onPress={() => {
        this.props.history.goBack()
      }} name='Back to My Reoffers' />
    )
  }
  silentSubmitLot () {}
  pageBlock () {}
  renderSaveLaterBtn () {}
  getNotificationText () {}
  renderNotificationBlock () {}
}
