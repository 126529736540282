import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  showSpinner,
  showModal,
  changeStateProp,
  mergeStateProp,
  filterStateProp
} from '../../../actions'
import MyFavouritePics from './MyFavouritePics'

const mapStateToProps = (state) => {
  return {
    pics: state.user.pics,
    locationStates: state.data.locationStates,
    locationDistricts: state.data.locationDistricts
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    showSpinner,
    showModal,
    changeStateProp,
    mergeStateProp,
    filterStateProp
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyFavouritePics)
