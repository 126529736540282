import React, { PureComponent } from 'react'

import Section from '../Section/Section'
import Api from '../../../services/api'
import BackButton from '../../BackButton/BackButton'

class GettingStarted extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      tools: []
    }
  }

  componentDidMount () {
    window.scroll(0, 0)
    this.getData()
  }

  getData () {
    this.props.showSpinner(true)
    Api.getTools()
      .then(res => {
        if (res) {
          this.setState({
            tools: res.tools
          })
        }
        this.props.showSpinner(false)
      })
  }

  render () {
    const { tools } = this.state
    return (
      <div className='getting-started'>
        <h1 className='fg-header'>Getting Started</h1>
        <div className='top-btns'>
          <div className='left'>
            <BackButton onPress={() => {
              this.props.history.goBack()
            }} name='Back to Tools' />
          </div>
        </div>
        <div className='policy-container'>
          {
            tools.map((item, index) => {
              return <Section key={index} title={item.categoryName} text={[{ text: item.content }]} />
            })
          }
        </div>
      </div>
    )
  }
}

export default GettingStarted
