const getBarMargins = (mobileWidth) => (
    { bottom: mobileWidth ? 75 : 40, top: 6, left: mobileWidth ? 80 : 60, right: mobileWidth ? 20 : 0 }
  )
  
  const getChartWidth = (dataLength, containerWidth) => containerWidth
  
  const getFixedPartBottomOffset = (mobileWidth, height) => mobileWidth ? height - 75 : height - 40
  
  const getFixedPartWidth = (mobileWidth, mainBars) => mainBars && mainBars.length ? (mobileWidth ? 80 : 60) + mainBars[0].x : mobileWidth ? 80 : 60
  
  export { getBarMargins, getChartWidth, getFixedPartBottomOffset, getFixedPartWidth }
  