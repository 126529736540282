import { clone } from './index'

export function convertData (data, { validOnly = false } = {}) {
  let copyData = clone(data)
  if (copyData.repassword) {
    delete copyData.repassword
  }
  for (let el in copyData) {
    if (el === 'postalAddress' || el === 'officeAddress' /*|| el === 'bankDetails'*/ || el === 'customerDetails') {
      for (let innerEl in copyData[el]) {
        if (validOnly && !copyData[el][innerEl].valid) {
          delete copyData[el][innerEl]
        } else {
          let value = copyData[el][innerEl].value
          copyData[el][innerEl] = typeof value === 'string' ? value.trim() : value
        }
      }
    } else if (el === 'referee1' || el === 'referee2') {
      for (let innerEl in copyData[el]) {
        if (validOnly && !copyData[el][innerEl].valid) {
          delete copyData[el][innerEl]
        } else {
          let value = copyData[el][innerEl].value
          copyData[el][innerEl] = typeof value === 'string' ? value.trim() : value
        }
      }
    } else if (el === 'userOperationsTypes'){
      delete copyData[el]
    }
     else {
      if (validOnly && !copyData[el].valid) {
        delete copyData[el]
      } else {
        let value = copyData[el].value
        copyData[el] = typeof value === 'string' ? value.trim() : value
      }
    }
  }
  return copyData
}
