import React from 'react'

import LotForm from '../../LotForm/LotForm'
import Api from '../../../services/api'
import BackButton from '../../BackButton/BackButton'

export default class EditLate extends LotForm {
  constructor (props) {
    super(props)
    this.mode = 'edit-late'
    this.mainUrl = '/main/manage/edit-late'
  }

  async submitLot (lot) {
    this.props.showSpinner(true)
    let data = {
      startPrice: lot.bidding !== 'kg' ? lot.startPrice * 100 : lot.startPrice,
      reserveCents: lot.bidding !== 'kg' ? lot.reserveCents * 100 : lot.reserveCents
    }
    let res = await Api.editLateLot(lot._id, data)
    if (res) {
      this.props.mergeStateProp('lots', [res.lot], 'data')
      this.props.history.push('/main/manage/my-submitted')
    }
    this.props.showSpinner(false)
  }

  validateDraftLot (draftLot) {
    let res = this.bidCentsValidation(draftLot)
    return res
  }
  componentDidUpdate (prevProps, prevState) {
    if ((prevProps.match.params.kindTitle !== this.props.match.params.kindTitle) ||
        (prevProps.match.params.draftId !== this.props.match.params.draftId)) {
      this.reloadPage()
    }
  }
  renderBackButton () {
    return (
      <BackButton onPress={() => {
        this.props.history.goBack()
      }} name='Back to My Submitted' />
    )
  }
  silentSubmitLot () {}
  pageBlock () {}
  renderSaveLaterBtn () {}
  getNotificationText () {}
  renderNotificationBlock () {}
  renderAuctionInfo () {}
}
