import Api from './api'

class ServerTime {
  constructor () {
    this.offsetMs = 0
    this.syncRetryDelayMs = 3000

    if (document.cookie.includes('BackUpServer=true')) {
      this.sync()
    }
  }

  async sync () {
    // WARNING: this async function restarts whenever it fails
    // and only returns after successfull sync
    let res = await Api.getServerTime()
    if (res && !res.isError) {
      let clientTime = new Date()
      let serverTime = new Date(res)
      if (serverTime) {
        this.offsetMs = serverTime.valueOf() - clientTime.valueOf()
      }
    } else {
      await new Promise(resolve => setTimeout(resolve, this.syncRetryDelayMs))
      await this.sync()
    }
  }

  getTime () {
    let clientTime = new Date()
    return clientTime.valueOf() + this.offsetMs
  }

  getTimeTo (date, { mode = 'until', compareWith } = {}) {
    date = new Date(date).getTime()
    compareWith = compareWith === undefined ? this.getTime() : new Date(compareWith).getTime()
    let ms
    switch (mode) {
      case 'until':
        ms = date - compareWith
        break
      case 'after':
        ms = compareWith - date
        break
      default:
        throw new Error('Unknown mode')
    }
    if (ms < 0) {
      return { hours: 0, minutes: 0, seconds: 0, done: true }
    }
    let s = Math.floor(ms / 1000)
    let m = Math.floor(ms / 60 / 1000)
    let h = Math.floor(ms / 60 / 60 / 1000)
    let obj = { hours: h, minutes: m - 60 * h, seconds: s - 60 * m, done: false }
    return obj
  }

  getStaleTime (ms) {
    let s = Math.floor(ms / 1000)
    let m = Math.floor(ms / 60 / 1000)
    let h = Math.floor(ms / 60 / 60 / 1000)
    return { hours: h, minutes: m - 60 * h, seconds: s - 60 * m, done: false }
  }
}

export default new ServerTime()
