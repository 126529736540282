import React from 'react'
import FdInput from '../../Auth/Fd-input/index'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faUser
} from '@fortawesome/fontawesome-free-solid'
import FgSelect from '../../FgSelect/FgSelect'
import { isSeller } from '../../../utils/UpdateData'
const BusinessDetails = (props) => {
  const { form, edit, handleInputChange, mapSelected, mapTypes, handleTradingTypes, same } = props

  return (
    <div className='info-item'>
      <div className='cd-header'>
        <FontAwesomeIcon className='icons-style' icon={faUser} />
        <div className='cd-title'>Business Details</div>
      </div>
      {isSeller()
        ? businessDetailsForSeller(form, edit, handleInputChange, handleTradingTypes, mapTypes, mapSelected, same)
        : <div className='cd-data'>
          <div className='columns'>
            <div className='rows company-tagging-name-row'>
              <p className={`titles margin-top-10px ${form.tradingName.valid}`}>Trading
                Name*:</p>
              <FdInput
                className={`inputs ${!form.tradingName.valid ? 'red' : ''}`}
                type='text'
                // placeholder='Company/Trading Name'
                value={form.tradingName.value}
                name='tradingName'
                onChange={handleInputChange}
                validData={form.tradingName.valid}
                disabled={!edit}
              />
            </div>

            <div className='rows'>
              <p className='titles'>Profession*:</p>
              <div className='type-wrapper'>
                <div
                  className={`typeDropdown-wrapper ${!same ? form.profession.value && 'selected' : form.profession.value && 'selected'}`}>
                  <FgSelect
                    options={mapTypes}
                    value={form.profession.value
                      ? mapSelected(form.profession.value)
                      : null}
                    onChange={handleTradingTypes.bind(null, 'profession')}
                    isSearchable={false}
                    multiple={true}
                    validData={form.profession.valid}
                    isDisabled={!edit || same}
                    className={'dropdown-input'}
                  />
                </div>
              </div>
            </div>

            <div className={form.profession.value === 'Other (Specify)' ? 'rows' : 'otherType-input-hidden'}>
              <p className={`titles margin-top-10px ${form.otherProfession.valid}`}>Other Profession*:</p>
              <FdInput
                className={`inputs ${!form.otherProfession.valid ? 'red' : ''}`}
                type='text'
                value={form.otherProfession.value}
                name='otherProfession'
                onChange={handleInputChange}
                validData={form.otherProfession.valid}
                disabled={!edit}
              />
            </div>

          </div>
        </div>
      }
    </div>
  )
}

const businessDetailsForSeller = (form, edit, handleInputChange, handleTradingTypes, mapTypes, mapSelected, same) => {
  return (
    <div className='cd-data'>
      <div className='columns'>
        {/* <div className='rows'>
          <p className={`titles ${form.ABN.valid}`}>ABN*:</p>
          <FdInput
            className={`inputs ${!form.ABN.valid ? 'red' : ''}`}
            type='text'
            // placeholder='ABN'
            value={form.ABN.value}
            name='ABN'
            onChange={handleInputChange}
            validData={form.ABN.valid}
            disabled={!edit}
          />
        </div> */}
        <div className='rows'>
          <p className={`titles margin-top-10px ${form.tradingName.valid}`}>Trading
            Name*:</p>
          <FdInput
            className={`inputs ${!form.tradingName.valid ? 'red' : ''}`}
            type='text'
            // placeholder='Company/Trading Name'
            value={form.tradingName.value}
            name='tradingName'
            onChange={handleInputChange}
            validData={form.tradingName.valid}
            disabled={!edit}
          />
        </div>
        <div className='rows'>
          <p className='titles'>Profession*:</p>
          <div className='type-wrapper'>
            <div
              className={`typeDropdown-wrapper ${!same ? form.profession.value && 'selected' : form.profession.value && 'selected'}`}>
              <FgSelect
                options={mapTypes}
                value={form.profession.value
                  ? mapSelected(form.profession.value)
                  : null}
                onChange={handleTradingTypes.bind(null, 'profession')}
                isSearchable={false}
                multiple={true}
                validData={form.profession.valid}
                isDisabled={!edit || same}
                className={'dropdown-input'}
              />
            </div>
          </div>
        </div>

        <div className={form.profession.value === 'Other (Specify)' ? 'rows' : 'otherType-input-hidden'}>
          <p className={`titles margin-top-10px ${form.otherProfession.valid}`}>Other Profession*:</p>
          <FdInput
            className={`inputs ${!form.otherProfession.valid ? 'red' : ''}`}
            type='text'
            value={form.otherProfession.value}
            name='otherProfession'
            onChange={handleInputChange}
            validData={form.otherProfession.valid}
            disabled={!edit}
          />
        </div>
        <div className='rows'>
          <p className={`titles ${form.PIC.valid}`}>PIC number:</p>
          <FdInput
            className={`inputs ${!form.PIC.valid ? 'red' : ''}`}
            type='text'
            // placeholder='PIC number'
            value={form.PIC.value}
            name='PIC'
            onChange={handleInputChange}
            validData={form.PIC.valid}
            disabled={!edit}
          />
        </div>
      </div>
    </div>
  )
}

export default BusinessDetails
