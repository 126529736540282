// localhost
// export const BASE_URL = 'http://localhost:3000/api/v1/'
// export const SOCKET_URL = 'http://localhost:3000/'

// test
// export const BASE_URL = 'https://test.farmgateauctions.com.au/api/v1/'
// export const SOCKET_URL = 'https://test.farmgateauctions.com.au/'

// live
// export const BASE_URL = 'https://fga.farmgateauctions.com.au/api/v1/'
// export const SOCKET_URL = 'https://fga.farmgateauctions.com.au/'

// dev
// export const BASE_URL = 'https://ekreative.farmgateauctions.com.au/api/v1/'
// export const SOCKET_URL = 'https://ekreative.farmgateauctions.com.au/'

export const BASE_URL = process.env.REACT_APP_BASE_URL
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL
export const GOOGLE_API_KEY = 'AIzaSyAYogmFPs3EukCa091qNBC0DOtYN7Nr80Y'
export const MODE = 'dev'
