import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { NavLink, Redirect } from 'react-router-dom'
import axios from 'axios'
import memoize from 'memoize-one'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import DayPicker from 'react-day-picker'
import {
  faTimes,
  faArrowRight,
  faSearch,
  faUser,
  faEdit,
  faCheck
} from '@fortawesome/fontawesome-free-solid'
import Modal from 'react-modal'
import moment from 'moment'

import BackButton from '../../BackButton/BackButton'
import FilterKind from '../../FilterKind/FilterKind'
import WatchButton from '../../WatchButton/WatchButtonContainer'
import { momentTz, limitTextLength, calculateLimitTextLength } from '../../../utils'
import { sortLots } from '../../../utils/Filter'
import { isAvailableEditLate } from '../../../utils/Lots'
import ImageCarousel from '../../ImageCarousel/ImageCarousel'
import LotItemWithdrawn from '../../LotItemWithdrawn/LotItemWithdrawn'
import InfiniteScroll from 'react-infinite-scroll-component'
import { WanderingCubes } from 'better-react-spinkit'
import Api from '../../../services/api'

import cowImage from '../../../assets/img/cowauction.png'
import lambImage from '../../../assets/img/lambauction.png'
import goatsImage from '../../../assets/img/goat-image.png'
import calendarXsImage from '../../../assets/img/calendar.png'
import bookImage from '../../../assets/img/book.png'
import LotsListClosedStatus from '../../LotsLists/LotsListClosedStatus'
import { drawCalendarBorders } from '../../../utils/Date'
import { getLotNumber, } from '../../../utils/Auctions'
import { isAllowedToAddNewLot } from '../../../utils/UpdateData'
import LotBottomInfo from '../../LotBottomInfo/LotBottomInfo'
import CalendarFilter from '../../CalendarFilter/CalendarFilter'
import { subTitles } from '../../../constants/strings'
// import {
//   firstDiffrentBoxTitle,
//   firstDiffrentBoxValue,
//   secondDiffrentBoxTitle,
//   secondDiffrentBoxValue
// } from '../../../utils/lotInfoBoxes'

class ExistingLots extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      hasMore: true,
      requestFinish: true,
      page: 1,
      limit: 10,
      auctions: this.props.auctions,
      fetching: false,
      redirectAgentCommentUrl: '',
      filters: {
        showMode: 'all'
      },
      showAutoBidPopUp: false,
      lotRedirectUrlForEdit: '',
      selectedAuction: null,
      startDay: null,
      endDay: null,
      filteredByDate: false,
      showCalendar: false,
      dateFilterText: 'Date Filter',
      lots: null,
      dateFilter: false,
      filteredStatus: 'off',
      modalCompiledCatalogueIsOpen: false
    }
    this.cancelSource = axios.CancelToken.source()
    this.getDisplayData = memoize(this.getDisplayData)
    this.changeShowMode = this.changeShowMode.bind(this)
    this.changeCalendarsShow = this.changeCalendarsShow.bind(this)
    this.getData = this.getData.bind(this)
    this.changeSelectedAuction = this.changeSelectedAuction.bind(this)
    this.handleStartDayClick = this.handleStartDayClick.bind(this)
    this.handleEndDayClick = this.handleEndDayClick.bind(this)
    this.openCompiledCatalogueModal = this.openCompiledCatalogueModal.bind(this)
    this.closeCompiledCatalogueModal = this.closeCompiledCatalogueModal.bind(this)
    this.copyLot = this.copyLot.bind(this)
    this.pickerContainer = React.createRef()
    this.picker = React.createRef()
    this.secondpickerContainer = React.createRef()
    this.secondPicker = React.createRef()
    if (this.props.showSubmitted) {
      this.props.showSingleNotification('my-saved-lots')
    }
  }

  componentDidMount() {
    drawCalendarBorders(this.pickerContainer.current)
    drawCalendarBorders(this.secondpickerContainer.current)
    if (this.props.history.action !== 'POP') {
      window.scroll(0, 0)
    }
    if (window.location.hash.includes('edit-saved')) {
      this.props.showSingleNotification('my-sync-lots')
    }
    // let startDay = localStorage.getItem('startDay');
    // let endDay = localStorage.getItem('endDay');
    // this.setState({
    //   startDay: startDay ? startDay : null,
    //   endDay : endDay ? endDay : null
    // });
    this.getData()
  }

  componentDidUpdate(prevProps) {
    drawCalendarBorders(this.pickerContainer.current)
    drawCalendarBorders(this.secondpickerContainer.current)
    if (prevProps.watchButtonLoading !== this.props.watchButtonLoading && this.props.watchButtonLoading) {
      this.props.showSpinner(true)
    }
    if (prevProps.watchButtonLoading !== this.props.watchButtonLoading && !this.props.watchButtonLoading) {
      this.props.showSpinner(false)
    }
  }

  componentWillUnmount() {
    this.cancelSource.cancel()
    this.props.hideSingleNotification('my-saved-lots')
    this.props.hideSingleNotification('my-sync-lots')
  }

  changeSelectedAuction(auction) {
    this.setState({
      selectedAuction: auction.value
    })
  }

  async getData() {
    const { page, limit, requestFinish, startDay, endDay, lots, filters } = this.state
    if (requestFinish) {
      let sortType = 'liveAt'
      let sortDirection = -1
      this.setState({
        fetching: true,
        hasMore: false,
        requestFinish: false
      })
      let data
      if (startDay && endDay) {
        if (this.props.showDraft) {
          data = await Api.getLotsDrafts(page, limit, sortType, sortDirection, startDay, endDay, { cancelToken: this.cancelSource.token })
          this.setState({
            page: page + 1,
            fetching: false,
            hasMore: parseInt(data.page, 10) < data.pages,
            lots: (lots && lots.length) ? lots.concat(data.lots) : data.lots
          })
        } else {
          data = await Api.getLotsSubmissions(page, limit, sortType, sortDirection, startDay, endDay, filters.showMode, { cancelToken: this.cancelSource.token })
          this.setState({
            page: page + 1,
            fetching: false,
            hasMore: parseInt(data.page, 10) < data.pages,
            lots: (lots && lots.length) ? lots.concat(data.lots) : data.lots
          })
        }
      } else {
        if (this.props.showDraft) {
          data = await Api.getLotsDrafts(page, limit, sortType, sortDirection, null, null, { cancelToken: this.cancelSource.token })
          this.setState({
            page: page + 1,
            fetching: false,
            hasMore: parseInt(data.page, 10) < data.pages,
            lots: (lots && lots.length) ? lots.concat(data.lots) : data.lots
          })
        } else {
          data = await Api.getLotsSubmissions(page, limit, sortType, sortDirection, null, null, filters.showMode, { cancelToken: this.cancelSource.token })
          this.setState({
            page: page + 1,
            fetching: false,
            hasMore: parseInt(data.page, 10) < data.pages,
            lots: (lots && lots.length) ? lots.concat(data.lots) : data.lots
          })
        }
      }
      this.setState({ requestFinish: true })
      if (data && !data.cancel) {
        this.props.mergeStateProp('lots', data.lots, 'data')
      }
    }
  }

  getDisplayData(auctions, lots, filters) {
    /* returns an object with structure
      [{
        auctionId: string,
        auctionState: string,
        auctionData: {...},
        kindData: {...},
        lots: [lotObj, lotObj, ....]
      }] */
    lots = lots
      .filter(lot => {
        return filters.showMode === 'all'
          ? true
          : lot.kindData.type === filters.showMode
      })
    let returnObj = lots.reduce((obj, lot) => {
      const auction = auctions.find(
        el => el.searchNumber === lot.auctionData.searchNumber
      )
      const auctionId = typeof lot.auction === 'string' ? lot.auction : lot.auction._id
      let thisSection = obj.find(section => section.auctionId === auctionId)
      if (!thisSection) {
        let storeAuction = auctions.find(auction => auction._id === auctionId)
        let auctionLots = lots
          .filter(lot => (typeof lot.auction === 'string' ? lot.auction : lot.auction._id) === auctionId)
        if (auctionLots.length) {
          obj.push({
            auctionId: auctionId,
            auction: auction,
            auctionState: storeAuction ? storeAuction.state : 'closed',
            auctionData: lot.auctionData,
            kindData: lot.kindData,
            lots: this.props.showDraft ? sortLots(auctionLots, 'Updated', 'up') : sortLots(auctionLots, 'Lot Number', 'down')
          })
        }
      }
      return obj
    }, [])
    // sort auctions by date
    // returnObj.sort((firstItem, secondItem) => {
    //   let v1 = firstItem.auctionData.liveAt
    //   let v2 = secondItem.auctionData.liveAt
    //   return v1 > v2 ? -1 : v1 < v2 ? 1 : 0
    // })
    // put future (pending) lots to top
    let futureLots = returnObj.filter(item => item.auctionState === 'future')
    let otherLots = returnObj.filter(item => item.auctionState !== 'future')
    returnObj = futureLots.concat(otherLots)
    return returnObj
  }

  changeShowMode(showMode) {
    this.setState(prevState => ({
      page: 1,
      filters: {
        ...prevState.filters,
        showMode
      }
    }), () => {
      this.getData()
    })
  }

  getLotLocation(lot) {
    return [lot.publicDetails.location.town, lot.publicDetails.location.state].filter(Boolean).join(', ')
  }

  openLive(auctionNumber) {
    window.open(`#/auction-live/${auctionNumber}`)
  }

  goToAuctionLink(kindData, searchNumber, auctionState) {
    let section = auctionState === 'closed' ? 'results' : 'upcoming'
    return `/main/${section}/${kindData.title}/${searchNumber}`
  }

  goToLotLink(lot) {
    return `/main/manage/${lot.kindData.title}/${lot.auctionData.searchNumber}/${lot.searchNumericId}`
  }

  copyLot(lot) {
    console.log(lot,"lot+++");
    let auctions = this.state.auctions || []
    auctions = auctions.filter(auction => auction.state === 'future' &&
      isAllowedToAddNewLot(auction.addNewLotToSpecificAgents, auction.specificAgents)).filter(auction => auction.kindData.type === lot.kindData.type)
    if (auctions.length > 0) {
      this.props.showModal({
        title: `Choose Auction`,
        message: 'Please choose the auction you want to copy the Lot to',
        dropdown: {
          values: auctions,
          textField: 'title',
          onChange: this.changeSelectedAuction
        },
        buttons: [
          {
            text: 'Copy',
            onPress: async () => {
              if (lot._id && this.state.selectedAuction) {
                let response = await axios.get(`/lots/${lot._id}/${this.state.selectedAuction}/copy?platform=web-app`)
                if (response.lot) {
                  const routeToLot = `/main/add/new/${response.lot.kindData.title}/${response.lot.auctionData.searchNumber}/${response.lot.searchNumericId}`
                  this.props.history.push(routeToLot)
                }
              } else {
                console.log('error')
              }
            }
          },
          {
            text: 'Cancel'
          }
        ]
      })
    } else {
      this.props.showModal({
        title: `Choose Auction`,
        message: 'No auctions available',
        buttons: [
          {
            text: 'Ok'
          }
        ]
      })
    }
  }

  goToEditLateLink(lot) {
    Api.getIsAutobidsFromLot(lot['_id']).then((res) => {
      if (res.isAutoBids > 0) {
        this.setState({
          showAutoBidPopUp: true,
          lotRedirectUrlForEdit: `/main/manage/edit-late/${lot.kindData.title}/${lot.auctionData.searchNumber}/${lot.searchNumericId}`
        })
      } else {
        window.location.hash = `/main/manage/edit-late/${lot.kindData.title}/${lot.auctionData.searchNumber}/${lot.searchNumericId}`
      }
    })
  }

  goToEditLink(lot) {
    return `/main/add/new/${lot.kindData.title}/${lot.auctionData.searchNumber}/${lot.searchNumericId}`
  }

  canEditLate(lot) {
    let auction = this.state.auctions.find(auction => auction._id === (typeof lot.auction === 'string' ? lot.auction : lot.auction._id))
    return isAvailableEditLate(lot, auction)
  }

  removeDraft(lot) {
    this.props.showModal({
      title: `Delete Lot`,
      message: 'Are you sure you want to delete this Lot?',
      buttons: [
        {
          text: 'No'
        },
        {
          text: 'Yes',
          onPress: () => {
            this.props.showSpinner(true)
            Api.deleteLot(lot._id)
              .then(data => {
                this.props.showSpinner(false)
                if (data) {
                  this.props.filterStateProp('lots', _lot => _lot._id !== lot._id, 'data')
                }
              })
          }
        }
      ]
    })
  }

  openCompiledCatalogueModal() {
    this.setState({
      modalCompiledCatalogueIsOpen: true
    })
  }
  closeCompiledCatalogueModal() {
    this.setState({
      modalCompiledCatalogueIsOpen: false
    })
  }

  renderAutoBidModal() {
    return (
      <Modal
        isOpen={this.state.showAutoBidPopUp}
        ariaHideApp={false}
        onRequestClose={() => {
          this.setState({
            showAutoBidPopUp: false
          })
        }}
        overlayClassName='overlay'
        className='content'
        portalClassName='ReactModalPortal-Email'
      >
        <div className='modal-body'>
          <h2 className='modal-catalogue-title'>An auto-bid is set on this Lot. Changing Start & Reserve Price may invalidate the auto-bid.</h2>
          <div className='buttons'>
            <div className='button ok-button' onClick={() => {
              this.setState({
                showAutoBidPopUp: false
              }, () => {
                window.location.hash = this.state.lotRedirectUrlForEdit
              })
            }}>
              <span className='button-text'>Change Price</span>
            </div>
            <div className='button ok-button red-button' onClick={() => {
              this.setState({
                showAutoBidPopUp: false
              })
            }}>
              <span className='button-text'>Cancel</span>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  renderCompiledCatalogueModule() {
    return (
      <Modal
        isOpen={this.state.modalCompiledCatalogueIsOpen}
        ariaHideApp={false}
        onRequestClose={this.closeCompiledCatalogueModal}
        overlayClassName='overlay'
        className='content'
        portalClassName='ReactModalPortal-Email'
      >
        <div className='modal-body'>
          <h2 className='modal-catalogue-title'>Catalogue is being compiled. Check back later.</h2>
          <div className='buttons'>
            <div className='button ok-button' onClick={this.closeCompiledCatalogueModal}>
              <span className='button-text'>OK</span>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  renderLotMiddleContent(lot) {
    if (lot.draft) {
      return (<React.Fragment>
        <p className='lot-desc'>{limitTextLength(lot.description, 500)}</p>
        <p className='vendor-name'>{lot.publicDetails.vendorName}</p>
      </React.Fragment>)
    } else if (lot.state !== 'closed') {
      return (
        <p className='lot-comments'>{`${limitTextLength(lot.publicDetails.agentComment, calculateLimitTextLength(lot.publicDetails.agentComment, 420), false)} `}
          <span className='lot-readMore' onClick={() => {
            this.setState(
              {
                redirectAgentCommentUrl: this.goToLotLink(lot)
              }
            )
          }}>
            {lot.publicDetails.agentComment.length > calculateLimitTextLength(lot.publicDetails.agentComment, 420) || lot.publicDetails.agentComment.split(/\r\n|\r|\n/g).length >= 4 ? '...Read More' : ''}
          </span>
        </p>
      )
    } else {
      return (
        <LotsListClosedStatus lot={lot} />
      )
    }
  }

  renderLots() {
    const { filters, filteredByDate } = this.state
    let { auctions } = this.state
    const displayData = this.getDisplayData(
      auctions,
      filteredByDate ? this.state.lots : this.props.lots,
      filters
    )
    return (
      <div className='watch-info'>
        {this.state.redirectAgentCommentUrl ? <Redirect to={{
          pathname: this.state.redirectAgentCommentUrl,
          state: { scroll: 10000 }
        }} /> : ''}
        {displayData.map(({ auction, auctionId, auctionState, auctionData, kindData, lots }) => {
          return (
            <div className='watch-auction' key={auctionId}>
              <div className='watch-auction-info-top'>
                <div>
                  <div className='watch-auction-kind'>
                    {kindData.type === 'sheep' && <img src={lambImage} alt='Sheep icon' />}
                    {kindData.type === 'cattle' &&
                      <img src={cowImage} alt='Cattle icon' className='cattle-icon' />}
                    {kindData.type === 'goat' && <img src={goatsImage} alt='Goat icon' className='goat-icon' />}
                  </div>
                  <div className='watch-auction-info'>
                    <h3>{auctionData.title}</h3>
                    <p className='auction-live-date'>{auctionData && auctionData.liveAt ? momentTz(auctionData.liveAt).format('dddd D MMMM YYYY - h.mm A') : ''}</p>
                  </div>
                </div>
                <div className='info-btns'>
                  {
                    (auctionState === 'live' && !this.props.showDraft) &&
                    <div
                      className='btn watch-enter-btn'
                      onClick={() => this.openLive(auctionData.searchNumber)}
                    >
                      <span>Enter Auction</span>
                      <span className='icon-wrapper icon-warning'><FontAwesomeIcon icon={faArrowRight} /></span>
                    </div>
                  }
                  {(!this.props.showDraft && auctionState !== 'live') &&
                    auctionState === 'closed' ? (
                    <NavLink className='view-catalog' to={this.goToAuctionLink(kindData, auctionData.searchNumber, auctionState)}>
                      <div className='btn'>
                        <span>View Results</span>
                        <span className='icon-wrapper icon-warning'><img src={bookImage} alt='Book icon' /></span>
                      </div>
                    </NavLink>
                  ) : (
                    (auction && (auction.lotCount > 0 || auction.withdrawnCount > 0)) ? (
                      <NavLink className='view-catalog' to={this.goToAuctionLink(kindData, auctionData.searchNumber, auctionState)}>
                        <div className='btn'>
                          <span>View Catalogue</span>
                          <span className='icon-wrapper icon-warning'><img src={bookImage} alt='Book icon' /></span>
                        </div>
                      </NavLink>
                    ) : (
                      <div className='btn' onClick={this.openCompiledCatalogueModal}>
                        <span>View Catalogue</span>
                        <span className='icon-wrapper icon-warning'><img src={bookImage} alt='Book icon' /></span>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className='watch-lots'>
                {lots.map(lot => {
                  if (lot.withdrawn) {
                    return (
                      <LotItemWithdrawn lot={lot} key={lot._id} auctionState={auction?.state} action={this.copyLot} />
                    )
                  }
                  return (
                    <div className='lot-item existing' key={lot._id}>
                      <div className='lot-item-top'>
                        <div className={`lot-img-container ${auctionState !== 'closed' && lot.approved ? 'container-height' : ''}`}>
                          <ImageCarousel
                            lot={lot}
                            lotId={lot._id}
                            media={lot.media}
                            kindType={kindData.type}
                            preview />
                        </div>
                        <div className='lot-info'>
                          <div className='lot-info-top'>
                            <div className='left'>
                              <p className='lot-number'>
                                {typeof lot.auction === 'string' ? getLotNumber(auctions.find(auction => auction._id === lot.auction), lot) : getLotNumber(lot.auction, lot)}
                                {lot.createdBy === this.props.userId &&
                                  (
                                    <span className='my-listing'>
                                      -
                                      <span className='icon-wrapper'>
                                        <FontAwesomeIcon icon={faUser} />
                                      </span>
                                      <span className='my-listing-text'>
                                        My Listing
                                      </span>
                                    </span>
                                  )
                                }
                              </p>
                            </div>
                            <p className='lot-location'>{this.getLotLocation(lot)}</p>
                          </div>
                          <div className='lot-info-middle'>
                            <div className='left'>
                              <p className='lot-title'>{lot.publicDetails.title} {lot.publicDetails.summary.age} {lot.additionalTitle}</p>
                              {this.renderLotMiddleContent(lot)}
                            </div>
                            {lot.draft
                              ? (
                                <div className='info-btns'>
                                  <NavLink className='btn' to={{
                                    pathname: this.goToEditLink(lot),
                                    state: {
                                      isInEditSavedLots: true
                                    }
                                  }}>
                                    <span className='button-text'>Edit</span>
                                    <span className='icon-warning icon-wrapper'>
                                      <FontAwesomeIcon icon={faEdit} />
                                    </span>
                                  </NavLink>
                                  <div className='btn' onClick={() => { this.removeDraft(lot) }}>
                                    <span className='button-text'>Delete</span>
                                    <span className='icon-red icon-wrapper'>
                                      <FontAwesomeIcon icon={faTimes} />
                                    </span>
                                  </div>
                                  <button className='btn' onClick={() => { this.copyLot(lot) }} variant='primary'>Copy Lot</button>{' '}
                                </div>
                              )
                              : (
                                <div className='info-btns'>
                                  {auctionState !== 'closed' && lot.approved && <WatchButton lotId={lot._id} bigButton />}
                                  <NavLink className='btn' to={this.goToLotLink(lot, auctionState)}>
                                    <span>View Details</span>
                                    <span className='icon-wrapper icon-warning'>
                                      <FontAwesomeIcon icon={faSearch} />
                                    </span>
                                  </NavLink>
                                  <button className='btn' onClick={() => { this.copyLot(lot) }} variant='primary'>Copy Lot</button>{' '}
                                </div>
                              )
                            }
                          </div>
                          <div className='lot-info-bottom'>
                            <div className='info-wrap'>
                              <LotBottomInfo lot={lot} mode={this.state.listType} />
                            </div>
                            <div className='company-logo-wrapper'>
                              {
                                lot.creatorData.logo &&
                                <img src={lot.creatorData.logo.url} alt='Company logo' />
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='lot-item-bottom'>
                        <div className='lot-info'>
                          <div className='lot-info-bottom'>
                            <div className='info-wrap'>
                              {
                                !lot.draft &&
                                <span>Views: {lot.totalPageViews}</span>
                              }
                              {
                                !lot.draft &&
                                <span>Watching: {lot.watchCount}</span>
                              }
                              {
                                lot.publicDetails.vendorName &&
                                <span>Vendor: {lot.publicDetails.vendorName}</span>
                              }
                            </div>
                            <div>
                              {this.canEditLate(lot) &&
                                (
                                  <span className='btn edit-price' onClick={() => {
                                    this.goToEditLateLink(lot)
                                  }}>
                                    <span>Edit Price</span>
                                    <span className='icon-wrapper icon-character'>
                                      $
                                    </span>
                                  </span>
                                )}
                              {!lot.draft &&
                                (
                                  <NavLink className='btn' to={this.goToLotLink(lot, auctionState)}>
                                    <span>{lot.approved ? 'Submitted' : 'Pending'}</span>
                                    <span className={lot.approved ? 'icon-wrapper status submitted' : 'icon-wrapper status pending'}>
                                      <FontAwesomeIcon icon={faCheck} />
                                    </span>
                                  </NavLink>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>)
                })}
              </div>
            </div>
          )
        })}
        {
          displayData.length
            ? null
            : (
              <div className='no-data-placeholder'>
                <div className='message'>
                  {this.props.noDataPlaceholder}
                </div>
              </div>
            )
        }
      </div>
    )
  }

  async onClickFilterByData() {
    if (moment(this.state.startDay).isAfter(moment(this.state.endDay))) {
      this.props.showModal({
        message: 'The from date must be earlier than the to date'
      })
      return
    }
    localStorage.setItem('startDay', this.state.startDay);
    localStorage.setItem('endDay', this.state.endDay);
    this.setState({
      page: 1,
      filteredByDate: true,
      lots: [],
      showCalendar: false,
      dateFilterText: this.getDateFilterText()
    }, () => {
      this.getData()
    })
  }

  getInitialMonth() {
    const { minDate, maxDate, value } = this.props
    if (value) return this.getNewDate(value)
    else if (minDate) return this.getNewDate(minDate)
    else if (maxDate) return this.getNewDate(maxDate)
    else return this.getNewDate()
  }

  async onClickCancelFilterByData() {
    this.setState({
      page: 1,
      filteredByDate: false,
      startDay: null,
      endDay: null,
      lots: null,
      showCalendar: false,
      dateFilterText: 'Date Filter',
      filters: {
        showMode : "all"
      }
   
    }, () => {
      this.getData()
    })
  }

  changeCalendarsShow() {
    this.setState({
      dateFilter: !this.state.dateFilter,
      filteredStatus: this.state.filteredStatus === 'off' ? 'on' : 'off',
      showCalendar: !this.state.showCalendar,
    })
  }

  handleStartDayClick(day, { selected, disabled }) {
    if (disabled) return
    this.setState({
      startDay: selected ? null : this.getNewDate(day)
    })
  }

  handleEndDayClick(day, { selected, disabled }) {
    if (disabled) return
    this.setState({
      endDay: selected ? null : this.getNewDate(day)
    })
  }

  calculateDataLength(data) {
    let res = data.length
    data.forEach(item => {
      res += item.lots.length
    })
    return res
  }

  render() {
    const { filters, hasMore, showCalendar, filteredStatus } = this.state
    const { showMode } = filters
    return (
      <div className='submitted-wrapper'>
        {this.renderAutoBidModal()}
        {this.renderCompiledCatalogueModule()}
        <h1 className='fg-header'>{this.props.showDraft ? 'Edit Saved Lots' : 'My Submitted Lots'}</h1>
        <p className='fg-paragraph'>
          {
            this.props.showDraft
              ? subTitles.SAVED_LOTS
              : subTitles.SUBMITTED_LOTS
          }
        </p>
        <div className='top-btns'>
          <div className='left'>
            <BackButton onPress={() => {
              // this.props.history.goBack()
              this.props.history.push('/main/manage')
            }} name='Back to My Admin' />
          </div>
        </div>
        <div className='watchlist-controls'>
          <h3 className='filters-heading'>Filters</h3>
          <div className='watchlist-filters-wrapper'>
            <div>
              <CalendarFilter
                activeValue='on'
                value={filteredStatus}
                onChange={this.changeCalendarsShow}
                icon={<img src={calendarXsImage} alt='Date Filter' className='icon-blackened' />}
              >  {this.state.dateFilterText}
              </CalendarFilter>
              <FilterKind categoryChoice={showMode} changeCategoryChoice={this.changeShowMode} />
              <div><button className='filter-button1' onClick={() => { this.onClickCancelFilterByData() }} variant='primary'>Clear Filter</button>{' '}</div>
            </div>
          </div>
          {showCalendar && <div className='row-filters' >
            <div>
              <h3 className='filters-heading'>From: </h3>
              <div ref={this.pickerContainer}
                className='day-picker-container'
              >
                <DayPicker
                  ref={this.picker}
                  firstDayOfWeek={1}
                  onDayClick={this.handleStartDayClick}
                  selectedDays={this.state.startDay}
                  onMonthChange={() => {
                    drawCalendarBorders(this.pickerContainer.current)
                  }}
                  renderDay={day => <div className={`DayPicker-Filled ${(day.getDate() === new Date().getDate() && day.getMonth() === new Date().getMonth()) ? 'today' : ''}`}>{day.getDate()}</div>}
                />
              </div>
            </div>
            <div>
              <h3 className='filters-heading'>To: </h3>
              <div ref={this.secondpickerContainer}
                className='day-picker-container'
              >
                <DayPicker
                  ref={this.secondPicker}
                  firstDayOfWeek={1}
                  onDayClick={this.handleEndDayClick}
                  selectedDays={this.state.endDay}
                  onMonthChange={() => {
                    drawCalendarBorders(this.secondpickerContainer.current)
                  }}
                  renderDay={day => <div className={`DayPicker-Filled ${(day.getDate() === new Date().getDate() && day.getMonth() === new Date().getMonth()) ? 'today' : ''}`}>{day.getDate()}</div>}
                />
              </div>
            </div>
            <div className='filter-buttons'>
              <button className='filter-button' onClick={() => { this.onClickFilterByData() }} variant='primary'>Filter Lots</button>{' '}
            </div>
          </div>}
        </div>
        <InfiniteScroll
          next={this.getData}
          hasMore={hasMore}
          scrollThreshold={0.8}
          dataLength={this.calculateDataLength(this.getDisplayData(this.state.auctions, this.state.filteredByDate ? this.state.lots : this.props.lots, this.state.filters))}
          style={{ overflow: 'initial' }}>
          <React.Fragment>
            {this.renderLots()}
            {this.state.fetching && <div className={`scroll-loader-wrap ${this.state.fetching ? 'active' : ''}`}>
              <WanderingCubes className='scroll-loader' color={'gray'} />
            </div>}
          </React.Fragment>
        </InfiniteScroll>
      </div>
    )
  }
  getNewDate = (value) => {
    return new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), 0, 0, 0, 0))
  }

  getDateFilterText = () => {
    if (this.state.startDay && this.state.endDay) {
      if (moment(this.state.startDay).isSame(moment(this.state.endDay))) {
        return moment(this.state.startDay).utc().format('DD MMM YYYY')
      } else {
        return moment(this.state.startDay).utc().format('DD MMM YYYY') + ' to ' + moment(this.state.endDay).utc().format('DD MMM YYYY')
      }
    } else {
      return 'Date Filter'
    }
  }
}

ExistingLots.propTypes = {
  auctions: PropTypes.array.isRequired,
  lots: PropTypes.array.isRequired,
  mergeStateProp: PropTypes.func.isRequired,
  filterStateProp: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  showSpinner: PropTypes.func.isRequired,
  showSingleNotification: PropTypes.func.isRequired,
  hideSingleNotification: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  noDataPlaceholder: PropTypes.string
}

ExistingLots.defaultProps = {
  noDataPlaceholder: 'There are currently no lots in this section.'
}

export default ExistingLots
