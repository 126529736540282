import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import AuctionsList from './AuctionsList'
import { changeStateProp, mergeStateProp } from '../../../actions/index'

const mapStateToProps = (state) => {
  return {
    locationStates: state.data.locationStates,
    filters: state.user.filters
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeStateProp, mergeStateProp }, dispatch)
}

const AuctionsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuctionsList)

export default AuctionsListContainer
