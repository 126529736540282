import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { showModal, showSpinner } from '../../../actions'
import Search from './Search'

const mapStateToProps = (state) => {
  return {
    locationStates: state.data.locationStates
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ showModal, showSpinner }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Search)
