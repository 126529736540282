import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import DayPicker from 'react-day-picker'
import moment from 'moment'
import memoize from 'memoize-one'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import {
  faCaretDown,
  faTimes,
  faCheck
} from '@fortawesome/fontawesome-free-solid'

import { isElementInsideOf } from '../../../utils'
import { drawCalendarBorders } from '../../../utils/Date'

class DateField extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      initialMonth: null,
      isOpen: false
    }
    this.getLimits = memoize(this.getLimits)
    this.clearDate = this.clearDate.bind(this)
    this.onChange = this.onChange.bind(this)
    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
    this.handleDocumentClick = this.handleDocumentClick.bind(this)
    this.pickerContainer = React.createRef()
    this.picker = React.createRef()
  }
  componentDidMount () {
    drawCalendarBorders(this.pickerContainer.current)
  }
  componentDidUpdate (prevState, prevProps) {
    drawCalendarBorders(this.pickerContainer.current)

    if (prevState.isOpen !== this.state.isOpen) {
      if (this.state.isOpen) {
        document.addEventListener('click', this.handleDocumentClick)
      } else {
        document.removeEventListener('click', this.handleDocumentClick)
      }
    }
  }
  componentWillUnmount () {
    document.removeEventListener('click', this.handleDocumentClick)
  }
  clearDate () {
    this.setState({
      isOpen: false
    })
    this.props.onChange('')
  }
  open () {
    if (!this.state.isOpen) {
      this.setState({
        initialMonth: this.getInitialMonth(),
        isOpen: true
      })
    }
  }
  close () {
    this.setState({ isOpen: false })
  }
  handleDocumentClick (event) {
    if (!this.state.isOpen ||
      isElementInsideOf(event.target, this.pickerContainer.current)) {
      return
    }
    this.setState({ isOpen: false })
  }
  onChange (date, { disabled }) {
    if (disabled) return
    let dateObj = moment(date)
    this.props.onChange(dateObj.add(dateObj.utcOffset(), 'minutes').utc().format())
  }
  getInitialMonth () {
    const { minDate, maxDate, value } = this.props
    if (value) return new Date(value)
    else if (minDate) return new Date(minDate)
    else if (maxDate) return new Date(maxDate)
    else return new Date()
  }
  getLimits (minDate, maxDate) {
    let limits = {}
    if (minDate) limits.before = new Date(minDate)
    if (!this.checkIsEarliestOrLatestDelivery()) {
      limits.after = maxDate ? new Date(maxDate) : new Date()
    }
    return limits
  }
  checkCurrentData (day) {
    let today = moment(new Date())
    return today.isSame(moment(day), 'd')
  }

  checkIsEarliestOrLatestDelivery() {
    const { title} = this.props
    // return reoffered && (title === 'Earliest Delivery' || title === 'Latest Delivery')
    return  (title === 'Earliest Delivery' || title === 'Latest Delivery')
  }

  render () {
    const { invalid, isRequired, minDate, maxDate, title, value, disabled } = this.props
    const { initialMonth, isOpen } = this.state
    const isActive = Boolean(isOpen || value)
    const dateValue = value ? new Date(value) : null
    return (
      <div className={`form-row ${this.checkIsEarliestOrLatestDelivery() ? '' : disabled ? 'disabled' : ''}`}>
        <div className='row-title'>
          <p className={`row-text ${invalid ? 'row-text-invalid' : ''}`}>
            {title}{isRequired ? <span className='asterisk'>*</span> : ''}:
          </p>
        </div>
        <div className='row-content'>
          <div className={`date-btn ${isOpen ? 'date-btn-opened' : ''} ${isActive ? 'date-btn-active' : ''}`}
            onClick={this.open}>
            <span className='date-value'>{dateValue ? moment(dateValue).format('DD/MM/YYYY') : 'Select date'}</span>
            <span className='icon-wrapper'>
              <FontAwesomeIcon icon={isOpen ? faCalendarAlt : faCaretDown} size={isOpen ? '1x' : 'lg'} />
            </span>
          </div>
          {
            isOpen && (
              <div ref={this.pickerContainer}
                className='day-picker-container'
              >
                <DayPicker
                  ref={this.picker}
                  firstDayOfWeek={1}
                  onDayClick={this.onChange}
                  month={initialMonth}
                  selectedDays={dateValue}
                  disabledDays={this.getLimits(minDate, maxDate)}
                  onMonthChange={() => {
                    drawCalendarBorders(this.pickerContainer.current)
                  }}
                  renderDay={day => <div className={`DayPicker-Filled ${this.checkCurrentData(day) ? 'today' : ''}`}>{day.getDate()}</div>}
                />
                <div className='btns'>
                  <div
                    className='btn'
                    onClick={this.close}
                  >
                    <span>Apply</span>
                    <span className='icon-wrapper icon-green'><FontAwesomeIcon icon={faCheck} /></span>
                  </div>
                  <div
                    className='btn'
                    onClick={this.clearDate}
                  >
                    <span>Clear</span>
                    <span className='icon-wrapper icon-danger'><FontAwesomeIcon icon={faTimes} /></span>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    )
  }
}

DateField.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  invalid: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.string,
  maxDate: PropTypes.string
}

DateField.defaultProps = {
  invalid: false
}

export default DateField
