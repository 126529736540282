import React from 'react'
import {CheckboxContainer, HiddenCheckbox, StyledCheckbox} from './AuthMultipleCheckboxStyle'

const AuthMultipleCheckbox = (props) => {

	function handleWarning (warning) {
		if (props.warningReason) {
		  return (
			<div className='warning-text'>* {warning}</div>
		  )
		}
	  }
	
	return(
		<div>
		{props.options.map((item, index) => (
			<CheckboxContainer key={index} disabled={props.disabled} >
				<HiddenCheckbox checked={props.selectedOptions.includes(item.key)}  />
				<StyledCheckbox checked={props.selectedOptions.includes(item.key)}
					type='checkbox'
					id={item.id}
					name={item.key}
					stockAlreadyRequested={props.requestedAndApprovedStocks && props.requestedAndApprovedStocks.includes(item.key)}
					onClick={() => props.onChangeStock(item.key)}
					>
				</StyledCheckbox>
				<span className='stock-types'>{item.key}</span>
	  		</CheckboxContainer>
		))}
		{handleWarning(props.warningReason)}
		</div>
	)
}
  
  export default AuthMultipleCheckbox