import React, { PureComponent } from 'react'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faTimes
} from '@fortawesome/fontawesome-free-solid'
import Api from '../../../services/api'
import FgTable from '../../FgTable/FgTable'
import BackButton from '../../BackButton/BackButton'
import FgSelect from '../../FgSelect/FgSelect'
import * as ApiAlerts from '../../../utils/ApiAlerts'

class Search extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      AgentName: '',
      townOrPostcode: '',
      state: '',
      searchResult: [],
      showTable: false
    }
    this.onChange = this.onChange.bind(this)
    this.search = this.search.bind(this)

    this.mapStates = this.mapOptions(this.props.locationStates)
  }

  componentDidMount () {
    if (this.props.history.action !== 'POP') {
      window.scroll(0, 0)
    }
  }

  getData () {
    this.props.showSpinner(true)
    let { AgentName, townOrPostcode, state } = this.state
    Api.agentSearch(state, AgentName, townOrPostcode)
      .then(res => {
        if (res) {
          this.setState({
            searchResult: res['users'],
            showTable: true
          })
        }
        this.props.showSpinner(false)
      })
      .catch(error => {
        this.props.showSpinner(false)
        return ApiAlerts.ErrorExtractor(error)
      })
  }

  mapOptions (options) {
    return options.map(option => {
      return {
        value: option,
        label: option
      }
    })
  }

  mapSelected (option) {
    return {
      value: option,
      label: option
    }
  }

  onChange ({ target: { name, value } }) {
    this.setState({
      [name]: value
    })
  }

  onSelect (name, item, { action }) {
    if (action === 'select-option') {
      this.setState({
        [name]: item.value
      })
    }
  }

  search () {
    let { AgentName, townOrPostcode, state } = this.state
    if ((AgentName + townOrPostcode + state).trim() !== '') {
      this.getData()
    }
  }

  clear (inputName) {
    this.setState({
      AgentName: '',
      townOrPostcode: '',
      state: ''
    })
  }

  renderForm (text, inputName, options = null, className) {
    return (
      <div className={`body ${className || ''}`}>
        <span className='explanation'>{text}</span>
        <div className='buttons'>
          {!options
            ? (
              <input
                ref={this.input}
                className='input'
                type='text'
                name={inputName}
                value={this.state[inputName]}
                style={{ textTransform: inputName === 'townOrPostcode' ? 'uppercase' : '' }}
                onChange={this.onChange}
              />
            )
            : (
              <div className={'dropdown'}>
                <FgSelect
                  options={this.mapStates}
                  value={this.mapSelected(this.state[inputName])}
                  isSearchable={false}
                  onChange={this.onSelect.bind(this, inputName)}
                />
              </div>
            )
          }
        </div>
      </div>
    )
  }

  renderSearchResult () {
    return (
      <div className='table-wrapper'>
        <h1 className='fg-header'>Agent Search Result</h1>
        <FgTable
          key={this.state.key}
          data={this.state.searchResult}
          columns={[
            {
              Header: 'Search Results',
              columns: [
                {
                  Header: 'Trading Name',
                  accessor: 'tradingName'
                },
                {
                  Header: 'Name',
                  id: 'date',
                  accessor: d => `${d.firstName} ${d.lastName}`
                },
                {
                  Header: 'Phone',
                  accessor: 'phone',
                  maxWidth: 130
                },
                {
                  Header: 'Town',
                  accessor: 'officeAddress.town',
                  maxWidth: 100,
                  style: {
                    textTransform: 'uppercase'
                  }
                },
                {
                  Header: 'Pcode',
                  accessor: 'officeAddress.postcode',
                  maxWidth: 70
                },
                {
                  Header: 'State',
                  accessor: 'officeAddress.state',
                  maxWidth: 70
                }
              ]
            }
          ]}
        />
      </div>
    )
  }

  renderTable () {
    if (this.state.searchResult.length > 0 || this.state.showTable) {
      return this.renderSearchResult()
    } else {
      return ''
    }
  }

  render () {
    return (
      <div className='search'>
        <h1 className='fg-header'>Search for an Agent</h1>
        <div className='top-btns'>
          <div className='left'>
            <BackButton onPress={() => {
              this.props.history.goBack()
            }} name='Back to Tools' />
          </div>
        </div>
        <div className='search-container'>
          <div className='section'>
            <div className='title'>
              <span>Search by Name, Town or Postcode and State</span>
            </div>
            {this.renderForm('Agent or Trading Name:', 'AgentName', null, 'first')}
            {this.renderForm('Town or Postcode:', 'townOrPostcode')}
            {this.renderForm('State:', 'state', this.mapStates)}
            <div className='body button-wrapper'>
              <span className='btn' onClick={() => this.clear()}>
                <span>Clear All</span>
                <span className='icon-wrapper icon-red'><FontAwesomeIcon icon={faTimes} style={{ color: 'white' }} /></span>
              </span>
              <span className='btn search' onClick={this.search}>
                <span>Search</span>
                <span className='icon-wrapper icon-success'><FontAwesomeIcon icon={faArrowRight} style={{ color: 'white' }} /></span>
              </span>
            </div>
          </div>
        </div>
        {
          this.renderTable()
        }
      </div>
    )
  }
}

export default Search
