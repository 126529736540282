import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Header from '../Header/HeaderContainer'
import Footer from '../Footer/Footer'

class NotFound extends PureComponent {
  render () {
    return (
      <div className='not-found'>
        <Header />
        <div className='error-content'>
          <p className='error-desc'>404 not found</p>
        </div>
        <Footer />
      </div>
    )
  }
}

NotFound.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}
NotFound.defaultProps = {
}

export default NotFound
