import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { changeStateProp, mergeStateProp, filterStateProp,showModal,showSpinner } from '../../actions'
import Main from './MainApp'

function mapStateToProps (state) {
  return {
    data: state.data,
    user: state.user.data,
    hasData: state.temp.hasData
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({ changeStateProp,showModal, mergeStateProp,showSpinner, filterStateProp }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)
