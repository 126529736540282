import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import memoize from 'memoize-one'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faEnvelope, faPrint, faTimes } from '@fortawesome/fontawesome-free-solid'

import Api from '../../../services/api'
import { makePrice } from '../../../utils/Lots'
import { momentTz } from '../../../utils'
import { isSeller, sellerOnly } from '../../../utils/UpdateData'
import FgTable from '../../FgTable/FgTable'
import BackButton from '../../BackButton/BackButton'
import { BASE_URL } from '../../../config'
import { bidTypes } from '../../../constants'
import DownloadResultBtn from '../../DownloadResultBtn/DownloadResultBtn'
import EmailModal from '../../Modals/MyAuctionResult/EmailModalMyAuctionResultContainer'
import CalendarFilter from '../../CalendarFilter/CalendarFilter'
import calendarXsImage from '../../../assets/img/calendar.png'
import DayPicker from 'react-day-picker'
import { drawCalendarBorders } from '../../../utils/Date'
import { subTitles } from '../../../constants/strings'

class MyResults extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      lots: [],
      modalIsOpen: false,
      loaded: false,
      filters: this.initFilter(),
      auctionColumnsWidth: 0,
      lotNoColumnsWidth: 0,
      lotColumnsWidth: 0,
      statusColumnsWidth: 0,
      priceColumnsWidth: 0,
      contactColumnsWidth: 0,
      tradingNameColumnsWidth: 0,
      expanderColumnsWidth: 0,
      dateFilter: false,
      filteredStatus: 'off',
      startDay: null,
      selectedStartDay: null,
      endDay: null,
      selectedEndDay: null
    }
    this.statuses = {
      'noBid': 'No Bid',
      'sold': 'Sold',
      'passed': 'Passed In',
      'withdrawn': 'Withdrawn',
      'bought': 'Bought',
      'soldAfterAuction': 'Sold AA'
    }
    this.clearFilter = this.clearFilter.bind(this)
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.getFilteredLots = memoize(this.getFilteredLots)
    this.goToDetails = this.goToDetails.bind(this)
    this.changeCalendarsShow = this.changeCalendarsShow.bind(this)
    this.handleStartDayClick = this.handleStartDayClick.bind(this)
    this.handleEndDayClick = this.handleEndDayClick.bind(this)
    this.auctionColumnHeader = React.createRef()
    this.lotNoColumnHeader = React.createRef()
    this.lotDetailsColumnHeader = React.createRef()
    this.statusColumnHeader = React.createRef()
    this.priceColumnHeader = React.createRef()
    this.contactColumnHeader = React.createRef()
    this.tradingNameColumnHeader = React.createRef()
    this.expanderColumnHeader = React.createRef()
    this.pickerContainer = React.createRef()
    this.picker = React.createRef()
    this.secondpickerContainer = React.createRef()
    this.secondPicker = React.createRef()
  }

  componentDidMount () {
    drawCalendarBorders(this.pickerContainer.current)
    drawCalendarBorders(this.secondpickerContainer.current)
    if (this.props.history.action !== 'POP') {
      window.scroll(0, 0)
    }
    this.getResults()
    window.addEventListener('resize', () => {
      this.updateSubColumnsSizes()
    })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    drawCalendarBorders(this.pickerContainer.current)
    drawCalendarBorders(this.secondpickerContainer.current)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', () => {
      this.updateSubColumnsSizes()
    })
  }

  async getResults () {
    this.props.showSpinner(true)
    let res = await Api.getMyResults()
    if (res) {
      for (let i = 0; i < res.data.length; i++) {
        let lot = res.data[i]
       // let index = i
        if ((lot.status === 'sold' || lot.status === 'soldAfterAuction') && lot.winner === this.props.userId) {
          // let oldStatus = lot.status
          lot.status = 'bought'

          // if (lot.winner === lot.createdBy) {
          //   let newLot = Object.assign({}, lot)
          //   newLot.status = oldStatus
          //   res.data.splice(index, 0, newLot)
          // }
        }
      }
      this.setState({
        lots: res.data,
        loaded: true
      })
    } else {
      this.setState({
        loaded: true
      })
    }
    this.props.showSpinner(false)
  }

  initFilter () {
    return [
      {
        key: 'bought',
        name: 'Bought',
        symbol: 'B',
        active: false
      },
      {
        key: 'sold',
        name: 'Sold',
        symbol: 'S',
        active: false
      },
      {
        key: 'noBid',
        name: 'No Bid',
        symbol: 'N',
        active: false
      },
      {
        key: 'passed',
        name: 'Passed In',
        symbol: 'P',
        active: false
      },
      {
        key: 'withdrawn',
        name: 'Withdrawn',
        symbol: 'W',
        active: false
      }
    ]
  }

  statusFilterHandler (key) {
    this.setState({
      filters: this.state.filters.map(filter => {
        return update(filter, {
          active: { $set: filter.key === key }
        })
      })
    })
  }

  clearFilter () {
    this.setState({
      startDay: null,
      endDay: null,
      selectedStartDay: null,
      selectedEndDay: null,
      filters: this.state.filters.map(filter => {
        return update(filter, {
          active: { $set: false }
        })
      })
    })
  }

  getFilteredLots (lots, filters, startDate, endDate) {
    let activeFilters = filters
      .filter(filter => filter.active)
      .map(filter => filter.key)

    let filteredLots = lots.filter(item => {
      if (startDate && !endDate) {
        return momentTz(item.auctionData.closedAt).startOf('day') >= momentTz(startDate).startOf('day')
      }
      if (!startDate && endDate) {
        return momentTz(item.auctionData.closedAt).startOf('day') <= momentTz(endDate).startOf('day')
      }
      if (startDate && endDate) {
        return (momentTz(item.auctionData.closedAt).startOf('day') >= momentTz(startDate).startOf('day')) && (momentTz(item.auctionData.closedAt).startOf('day') <= momentTz(endDate).startOf('day'))
      }
      return true
    })

    if (!activeFilters.length) {
      return filteredLots
    } else if (activeFilters.includes('sold')) {
      return filteredLots.filter(lot => {
        return activeFilters.includes(lot.status) || lot.status === 'soldAfterAuction'
      })
    } else {
      return filteredLots.filter(lot => activeFilters.includes(lot.status))
    }
  }

  getBidding (bidding) {
    switch (true) {
      case bidding === bidTypes.KG:
        return 'KG'
      case bidding === bidTypes.HEAD:
      default:
        return 'Head'
    }
  }

  totalHead () {
    return this.state.lots.map(lot => lot.count).reduce((a, b) => a + b, 0)
  }

  pdfUrl () {
    return `${BASE_URL}print/lots/my-result?type=pdf&mode=print&filterType=${this.state.filters
      .filter(filter => filter.active)
      .map(filter => filter.key)[0] || ''}`
  }

  openModal () {
    this.setState({
      modalIsOpen: true
    })
  }

  closeModal () {
    this.setState({
      modalIsOpen: false
    })
  }

  goToDetails (lot) {
    this.props.history.push({
      pathname: `/main/results/${lot.kindData.title}/${lot.auctionData.searchNumber}/${lot.searchNumericId}`,
      state: { from: 'myAdmin' }
    })
  }

  updateSubColumnsSizes = () => {
    if (this.auctionColumnHeader && this.auctionColumnHeader.parentNode && this.lotNoColumnHeader && this.lotDetailsColumnHeader &&
      this.statusColumnHeader && this.priceColumnHeader &&
      this.contactColumnHeader && this.tradingNameColumnHeader &&
      this.expanderColumnHeader && this.expanderColumnHeader.parentNode
    ) {
      this.setState({
        auctionColumnsWidth: this.auctionColumnHeader.parentNode.clientWidth + 2,
        lotNoColumnsWidth: this.lotNoColumnHeader.parentNode.clientWidth + 2,
        lotColumnsWidth: this.lotDetailsColumnHeader.parentNode.clientWidth + 2,
        statusColumnsWidth: this.statusColumnHeader.parentNode.clientWidth + 2,
        priceColumnsWidth: this.priceColumnHeader.parentNode.clientWidth + 2,
        contactColumnsWidth: this.contactColumnHeader.parentNode.clientWidth + 2 + this.tradingNameColumnHeader.parentNode.clientWidth + 2 + this.expanderColumnHeader.parentNode.clientWidth + 8
      })
    }
  }

  makeStatusOfContactFromLotStatus (lotStatus) {
    switch (lotStatus) {
      case 'sold':
        return 'Buyer:'
      case 'soldAfterAuction':
        return 'Buyer:'
      case 'passed':
        return 'Highest Bidder:'
      default :
        return ''
    }
  }

  changeCalendarsShow () {
    this.setState({
      dateFilter: !this.state.dateFilter,
      filteredStatus: this.state.filteredStatus === 'off' ? 'on' : 'off'
    })
  }

  async onClickFilterByData () {
    await this.setState({
      startDay: this.state.selectedStartDay ? this.getNewDate(this.state.selectedStartDay) : null,
      endDay: this.state.selectedEndDay ? this.getNewDate(this.state.selectedEndDay) : null
    })
  }

  async onClickCancelFilterByData () {
    await this.setState({
      startDay: null,
      endDay: null,
      selectedStartDay: null,
      selectedEndDay: null
    })
  }

  handleStartDayClick (day, { selected, disabled }) {
    if (disabled) return
    this.setState({
      selectedStartDay: selected ? null : this.getNewDate(day)
    })
  }

  handleEndDayClick (day, { selected, disabled }) {
    if (disabled) return
    this.setState({
      selectedEndDay: selected ? null : this.getNewDate(day)
    })
  }

  getNewDate = (value) => (
    new Date(new Date(value).setHours(0, 0, 0, 0))
  )

  render () {
    if (!this.state.loaded) {
      return <div />
    }
    return (
      <div className='my-results-wrapper'>
        <EmailModal
          openModal={this.openModal}
          closeModal={this.closeModal}
          afterOpenModal={this.afterOpenModal}
          isOpen={this.state.modalIsOpen}
        />
        <div className='fg-header'>My Auction Results</div>
        <p className='fg-paragraph'>
          {
            isSeller()
              ? subTitles.MY_AUCTION_RESULTS
              : subTitles.MY_AUCTION_RESULTS_BUYERS
          }
        </p>
        <div className='top-btns results'>
          <BackButton onPress={() => {
            this.props.history.goBack()
          }} name='Back to My Admin' />
          {this.state.lots.length
            ? (
              <Fragment>
                <span className='btn' onClick={this.openModal}>
                  <span>Email Results</span>
                  <span className='icon-wrapper icon-success'><FontAwesomeIcon icon={faEnvelope} /></span>
                </span>
                <DownloadResultBtn pdfUrl={`${BASE_URL}print/lots/my-result?type=pdf&filterType=${this.state.filters
                  .filter(filter => filter.active)
                  .map(filter => filter.key)[0] || ''}`} csvUrl={`${BASE_URL}print/lots/my-result?filterType=${this.state.filters
                    .filter(filter => filter.active)
                    .map(filter => filter.key)[0] || ''}`} />
                <a className='btn' href={this.pdfUrl()} target='_blank' rel='noopener noreferrer'>
                  <span>Print Results</span>
                  <span className='icon-wrapper icon-success'><FontAwesomeIcon icon={faPrint} /></span>
                </a>
              </Fragment>
            )
            : null}
        </div>
        <hr className='separator' />
        {
          sellerOnly(
            <Fragment>
              <div className='lot-filters'>
                <div className='title' style={{ marginTop: 10 }}>Filters</div>
                <div className='filter-container'>
                  {
                    this.state.filters.map((filter) => {
                      let activeClass = filter.active ? 'active' : ''
                      return (
                        <div key={filter.key} onClick={() => { this.statusFilterHandler(filter.key) }} className={`btn status-btn filter-btn filter-btn-sm ${filter.key} ${activeClass}`}>
                          <span>{filter.name}</span>
                          <span className={`icon-wrapper`}>{filter.symbol}</span>
                        </div>
                      )
                    })
                  }
                  <div className='btn filter-btn filter-btn-sm clear-all-btn' onClick={this.clearFilter}>
                    <span>Clear All</span>
                    <span className='icon-wrapper icon-danger icon-md'><FontAwesomeIcon icon={faTimes} /></span>
                  </div>
                </div>
              </div>
            </Fragment>
          )
        }
        <div className='lot-button-container'>
          <CalendarFilter
            activeValue='on'
            value={this.state.filteredStatus}
            onChange={this.changeCalendarsShow}
            icon={<img src={calendarXsImage} alt='Date Filter' className='icon-blackened' />}
          >  Date Filter
          </CalendarFilter>
        </div>
        {this.state.dateFilter && <div className='row-filters' >
          <div>
            <h3 className='filters-heading'>From: </h3>
            <div ref={this.pickerContainer}
              className='day-picker-container'
            >
              <DayPicker
                ref={this.picker}
                firstDayOfWeek={1}
                onDayClick={this.handleStartDayClick}
                selectedDays={this.state.selectedStartDay}
                onMonthChange={() => {
                  drawCalendarBorders(this.pickerContainer.current)
                }}
                renderDay={day => <div className={`DayPicker-Filled ${(day.getDate() === new Date().getDate() && day.getMonth() === new Date().getMonth()) ? 'today' : ''}`}>{day.getDate()}</div>}
              />
            </div>
          </div>
          <div>
            <h3 className='filters-heading'>To: </h3>
            <div ref={this.secondpickerContainer}
              className='day-picker-container'
            >
              <DayPicker
                ref={this.secondPicker}
                firstDayOfWeek={1}
                onDayClick={this.handleEndDayClick}
                selectedDays={this.state.selectedEndDay}
                onMonthChange={() => {
                  drawCalendarBorders(this.secondpickerContainer.current)
                }}
                renderDay={day => <div className={`DayPicker-Filled ${(day.getDate() === new Date().getDate() && day.getMonth() === new Date().getMonth()) ? 'today' : ''}`}>{day.getDate()}</div>}
              />
            </div>
          </div>
          <div className='filter-buttons'>
            <button className='filter-button' onClick={() => { this.onClickFilterByData() }} variant='primary'>Filter Lots</button>{' '}
            <button className='filter-button1' onClick={() => { this.onClickCancelFilterByData() }} variant='primary'>Clear Filter</button>{' '}
          </div>
        </div>}
        <hr className='separator' />
        <div className='table-wrapper'>
          {isSeller()
            ? <FgTable
              data={this.getFilteredLots(this.state.lots, this.state.filters, this.state.startDay, this.state.endDay)}
              type='results'
              columns={[
                {
                  Header: 'My Auction Results',
                  columns: [
                    {
                      Header: 'Auction',
                      accessor: 'auctionData.closedAt',
                      width: 200,
                      Cell: ({ original, value, ...rest }) => {
                        return (
                          <div ref={ref => {
                            this.auctionColumnHeader = ref
                          }}>
                            <p className='display-linebreak'>{original.auctionData.title}</p>
                            <p>{original.auctionData.searchNumber}</p>
                            <p>{momentTz(original.auctionData.closedAt).format('DD/MM/YY')}</p>
                          </div>
                        )
                      }
                    },
                    {
                      Header: 'Lot No.',
                      accessor: 'number',
                      maxWidth: 70,
                      sortMethod: (a, b) => {
                        return a - b
                      },
                      Cell: ({ original, value }) => {
                        return (
                          <div ref={ref => {
                            this.lotNoColumnHeader = ref
                          }}>
                            <p>{original.number}</p>
                          </div>
                        )
                      }
                    },
                    {
                      Header: 'Lot Details',
                      accessor: 'searchNumericId',
                      Footer: (<span>{this.totalHead()} Head</span>),
                      sortMethod: (a, b) => {
                        let firstNumber = parseInt(a.slice(2))
                        let secondNumber = parseInt(b.slice(2))
                        if (firstNumber > secondNumber) {
                          return 1
                        } else {
                          return -1
                        }
                      },
                      width: 420,
                      Cell: ({ original, value }) => {
                        return (
                          <div ref={ref => {
                            this.lotDetailsColumnHeader = ref
                          }}>
                            <p>{original.searchNumericId}</p>
                            <p
                              onClick={() => { this.goToDetails(original) }}
                              className={`link bought`}
                              title={original.title}>
                              {original.title}
                            </p>
                          </div>
                        )
                      }
                    },
                    {
                      Header: 'Status',
                      accessor: 'status',
                      Cell: ({ original, value }) => {
                        return (
                          <div ref={(ref) => {
                            this.statusColumnHeader = ref
                          }} className='lot-status'>
                            <span className={original.status}>{this.statuses[value]}</span>
                          </div>
                        )
                      },
                      width: 100
                    },
                    {
                      Header: 'Price',
                      id: 'price',
                      accessor: 'price',
                      Cell: ({ original, value }) => {
                        let price = makePrice(value, {
                          bidding: original.bidding,
                          precision: 0
                        })
                        let priceKg = makePrice(value / original.curfewWeight, {
                          bidding: 'kg',
                          precision: 0
                        })
                        const displaySecondPrice =
                          !['Cow(s)', 'Cow(s) and Calves', 'Bull(s)'].includes(original.sex) &&
                          original.kindData.type === 'cattle' &&
                          original.bidding === bidTypes.HEAD

                        return (
                          <div
                            ref={(ref) => {
                              this.priceColumnHeader = ref
                            }}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-end'
                            }}
                          >
                            <span>{price}</span>
                            {original.status !== 'withdrawn' && <span>/{original.bidding}</span>}
                            {price && displaySecondPrice && original.status !== 'withdrawn' && <>
                              <span>{priceKg}/{'kg'}</span>
                            </>
                            }

                          </div>)
                      },
                      sortMethod: (a, b) => {
                        return b - a
                      },
                      width: 75
                    },
                    {
                      Header: 'Contact',
                      id: 'vendorOrBuyer',
                      accessor: 'vendorOrBuyer',
                      width: 250,
                      getProps: (state, rowInfo) => {
                        if (rowInfo && Object.keys(state.expanded).includes(rowInfo.viewIndex + '') && state.expanded[rowInfo.viewIndex]) {
                          return {
                            style: {
                              'marginBottom': '3px',
                              'borderBottom': '1px solid #c6c6c6'
                            }
                          }
                        }
                        return {}
                      },
                      Cell: ({ original, value }) => {
                        const needHideWinner = original.status === 'soldAfterAuction' && !original.winner
                        return (!needHideWinner
                          ? <div
                            ref={(ref) => {
                              this.contactColumnHeader = ref
                            }}>
                            {!value.includes('undefined') && <p>{original.status !== 'withdrawn' ? this.makeStatusOfContactFromLotStatus(original.status) : ''} {original.status !== 'withdrawn' ? value : ''}</p>}
                            <p>{original.status !== 'withdrawn' ? original.vendorOrBuyerNumber : ''}</p>
                          </div> : null
                        )
                      }
                    },
                    {
                      Header: 'Trading Name',
                      id: 'vendorOrBuyerTradingName',
                      accessor: 'vendorOrBuyerTradingName',
                      width: 100,
                      getProps: (state, rowInfo) => {
                        if (rowInfo && Object.keys(state.expanded).includes(rowInfo.viewIndex + '') && state.expanded[rowInfo.viewIndex]) {
                          return {
                            style: {
                              'marginBottom': '3px',
                              'borderBottom': '1px solid #c6c6c6'
                            }
                          }
                        }
                        return {}
                      },
                      Cell: ({ original, value }) => {
                        return (
                          <div
                            ref={(ref) => {
                              this.tradingNameColumnHeader = ref
                            }}>
                            <p>{value}</p>
                          </div>
                        )
                      }
                    },
                    {
                      expander: true,
                      width: 50,
                      getProps: (state, rowInfo) => {
                       // console.log(rowInfo && rowInfo.original.status,"rowInfo.original.status");
                        let params = {}
                        if (rowInfo && Object.keys(state.expanded).includes(rowInfo.viewIndex + '') && state.expanded[rowInfo.viewIndex]) {
                          params = {
                            style: {
                              'marginBottom': '3px',
                              'borderBottom': '1px solid #c6c6c6'
                            }
                          }
                        }
                        if ((rowInfo && (rowInfo.original.status !== 'sold' &&  rowInfo.original.status !== 'bought' && !(rowInfo.original.status === 'soldAfterAuction' && rowInfo.original.winner)))) {
                          console.log(rowInfo && rowInfo.original.status,"rowInfo.original.status");
                          return {
                            style: {
                              'pointerEvents': 'none'
                            }
                          }
                        }
                        return { ...params }
                      },
                      Expander: ({ original, isExpanded }) => {
                        return (
                          <div
                            className='expander-symbol'
                            ref={(ref) => {
                              this.expanderColumnHeader = ref
                            }}
                          >
                            {/* {(original.status === 'sold' || (original.status === 'soldAfterAuction' && original.winner)) && (isExpanded ? <span>&minus;</span> : <span>&#43;</span>)} */}
                            {((original.status === 'sold' || original.status === 'bought') || (original.status === 'soldAfterAuction' && original.winner)) && (isExpanded ? <span>&minus;</span> : <span>&#43;</span>)}
                          </div>
                        )
                      }
                    }
                  ]
                }
              ]}
              SubComponent={({ row: { _original } }) => {
                this.updateSubColumnsSizes()
                return (
                  <div className='rt-tr -odd'>
                    <div className='custom-rt-td -sub-auction' style={{
                      width: this.state.auctionColumnsWidth
                    }} />
                    <div className='custom-rt-td' style={{
                      width: this.state.lotNoColumnsWidth
                    }} />
                    <div className='custom-rt-td' style={{
                      width: this.state.lotColumnsWidth
                    }} />
                    <div className='custom-rt-td' style={{
                      width: this.state.statusColumnsWidth
                    }} />
                    <div className='custom-rt-td' style={{
                      width: this.state.priceColumnsWidth
                    }} />
                    <div className='custom-rt-td first-connected' style={{
                      width: this.state.contactColumnsWidth
                    }}>
                      <p>{_original.vendorOrBuyerEmail}</p>
                      <p>{_original.vendorOrBuyerPic}</p>
                      {_original.vendorOrBuyerOfficeAddress
                        ? <p>{_original.vendorOrBuyerOfficeAddress.address1}
                          {_original.vendorOrBuyerOfficeAddress.address2 && _original.vendorOrBuyerOfficeAddress.address1 && ', '}
                          {_original.vendorOrBuyerOfficeAddress.address2}
                        </p>
                        : null}
                      {_original.vendorOrBuyerOfficeAddress
                        ? <p>
                          {_original.vendorOrBuyerOfficeAddress.town}
                          {_original.vendorOrBuyerOfficeAddress.state && _original.vendorOrBuyerOfficeAddress.postcode && ', '}
                          {_original.vendorOrBuyerOfficeAddress.state}
                          {_original.vendorOrBuyerOfficeAddress.postcode && ', '}
                          {_original.vendorOrBuyerOfficeAddress.postcode}
                        </p>
                        : null}
                      <p>{_original.vendorOrBuyerAgentName}</p>
                      <p>{_original.vendorOrBuyerAgentTradingName}</p>
                      <p>{_original.vendorOrBuyerAgentPhone}</p>
                    </div>
                  </div>
                )
              }}
            />
            : <FgTable
              data={this.getFilteredLots(this.state.lots, this.state.filters, this.state.startDay, this.state.endDay)}
              columns={[
                {
                  Header: 'My Auction Results',
                  columns: [
                    {
                      Header: 'Auction',
                      accessor: 'auctionData.closedAt',
                      maxWidth: 200,
                      Cell: ({ original, value, ...rest }) => {
                        return (
                          <div ref={this.auctionColumnHeader}>
                            <p className='display-linebreak'>{original.auctionData.title}</p>
                            <p>{original.auctionData.searchNumber}</p>
                            <p>{momentTz(original.auctionData.closedAt).format('DD/MM/YY')}</p>
                          </div>
                        )
                      }
                    },
                    {
                      Header: 'Lot No.',
                      accessor: 'number',
                      maxWidth: 70,
                      sortMethod: (a, b) => {
                        return a - b
                      },
                      Cell: ({ original, value }) => {
                        return (
                          <div ref={ref => {
                            this.lotNoColumnHeader = ref
                          }}>
                            <p>{original.number}</p>
                          </div>
                        )
                      }
                    },
                    {
                      Header: 'Lot Details',
                      accessor: 'searchNumericId',
                      Footer: (<span>{this.totalHead()} Head</span>),
                      sortMethod: (a, b) => {
                        let firstNumber = parseInt(a.slice(2))
                        let secondNumber = parseInt(b.slice(2))
                        if (firstNumber > secondNumber) {
                          return 1
                        } else {
                          return -1
                        }
                      },
                      width: 420,
                      Cell: ({ original, value }) => {
                        return (
                          <div ref={ref => {
                            this.lotDetailsColumnHeader = ref
                          }}>
                            <p>{original.searchNumericId}</p>
                            <p
                              onClick={() => { this.goToDetails(original) }}
                              className={`link bought`}
                              title={original.title}>
                              {original.title}
                            </p>
                          </div>
                        )
                      }
                    },
                    {
                      Header: 'Status',
                      accessor: 'status',
                      Cell: ({ original, value }) => {
                        return (
                          <div ref={(ref) => {
                            this.statusColumnHeader = ref
                          }} className='lot-status'>
                            <span className={original.status}>{this.statuses[value]}</span>
                          </div>
                        )
                      },
                      width: 100
                    },
                    {
                      Header: 'Price',
                      id: 'price',
                      accessor: 'price',
                      Cell: ({ original, value }) => {
                        let price = makePrice(value, {
                          bidding: original.bidding,
                          precision: 0
                        })
                        let priceKg = makePrice(value / original.curfewWeight, {
                          bidding: 'kg',
                          precision: 0
                        })
                        const displaySecondPrice =
                          !['Cow(s)', 'Cow(s) and Calves', 'Bull(s)'].includes(original.sex) &&
                          original.kindData.type === 'cattle' &&
                          original.bidding === bidTypes.HEAD

                        return (
                          <div
                            ref={(ref) => {
                              this.priceColumnHeader = ref
                            }}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'flex-end'
                            }}
                          >
                            <span>{price}</span>
                            {original.status !== 'withdrawn' && <span>/{original.bidding}</span>}
                            {price && displaySecondPrice && original.status !== 'withdrawn' && <>
                              <span>{priceKg}/{'kg'}</span>
                            </>
                            }
                          </div>)
                      },
                      sortMethod: (a, b) => {
                        return b - a
                      },
                      width: 75
                    },
                    {
                      Header: 'Contact',
                      id: 'vendorOrBuyer',
                      accessor: 'vendorOrBuyer',
                      width: 250,
                      getProps: (state, rowInfo) => {
                        if (rowInfo && Object.keys(state.expanded).includes(rowInfo.viewIndex + '') && state.expanded[rowInfo.viewIndex]) {
                          return {
                            style: {
                              'marginBottom': '3px',
                              'borderBottom': '1px solid #c6c6c6'
                            }
                          }
                        }
                        return {}
                      },
                      Cell: ({ original, value }) => {
                        return (
                          <div ref={(ref) => {
                            this.contactColumnHeader = ref
                          }}>
                            <p>{this.makeStatusOfContactFromLotStatus(original.status)} {value}</p>
                            <p>{original.vendorOrBuyerNumber}</p>
                          </div>
                        )
                      }
                    },
                    {
                      Header: 'Trading Name',
                      id: 'vendorOrBuyerTradingName',
                      accessor: 'vendorOrBuyerTradingName',
                      width: 153,
                      getProps: (state, rowInfo) => {
                        if (rowInfo && Object.keys(state.expanded).includes(rowInfo.viewIndex + '') && state.expanded[rowInfo.viewIndex]) {
                          return {
                            style: {
                              'marginBottom': '3px',
                              'borderBottom': '1px solid #c6c6c6'
                            }
                          }
                        }
                        return {}
                      },
                      Cell: ({ original, value }) => {
                        return (
                          <div ref={(ref) => {
                            this.tradingNameColumnHeader = ref
                          }}>
                            <p>{value}</p>
                          </div>
                        )
                      }
                    }
                  ]
                }
              ]}
            />
          }
        </div>
      </div>
    )
  }
}

MyResults.propTypes = {
  userId: PropTypes.string.isRequired,
  showSpinner: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default MyResults
