import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import Api from '../../services/api'
import update from 'immutability-helper'
import FdInput from '../Auth/Fd-input/index'
import { EMAIL_REGEXP } from '../../constants/index'
import Header from '../Header/HeaderContainer.js'
import Footer from '../Footer/Footer'

class Forgot extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      form: {
        email: ''
      },
      submited: false,
      validEmail: true,
      invalidEmail: ''
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.validateData = this.validateData.bind(this)
    this.invalidDataReason = this.invalidDataReason.bind(this)
    this.allValid = this.allValid.bind(this)
  }
  handleSubmit (event) {
    event.preventDefault()
    let data = {}
    this.setState({
      submited: true
    })
    this.validateData(this.state.form.email)
    this.invalidDataReason(this.state.form.email)
    setTimeout(async () => {
      if (this.allValid()) {
        this.props.showSpinner(true)
        data = {
          email: this.state.form.email
        }
        let res = await Api.requestForgotPassword(data)
        if (res) {
          this.props.history.push({ pathname: '/forgot/change-password' })
        }
        this.props.showSpinner(false)
      }
    }, 0)
  }
  allValid () {
    if (this.state.validEmail) {
      return true
    } else { return false }
  }
  validateData (email) {
    this.setState({
      validEmail: this.validateEmail(email) === ''
    })
  }
  invalidDataReason (email) {
    this.setState({
      invalidEmail: this.validateEmail(email)
    })
  }
  handleInputChange ({ target: { name, value } }) {
    this.setState({
      form: update(this.state.form, {
        [name]: { $set: value }
      })
    }, () => {
      if (this.state.submited) {
        switch (name) {
          case 'email':
            this.setState({
              validEmail: this.validateEmail(this.state.form[name]) === '',
              invalidEmail: this.validateEmail(this.state.form[name])
            })
            break
          default:
            break
        }
      }
    })
  }
  validateEmail (email) {
    if (email === '' || email === ' ' || email === undefined) {
      return 'Please Enter Email'
    } else if (!(EMAIL_REGEXP.test(email) && (email && email.length >= 3 && email.length <= 60))) {
      return 'Sorry, invalid Email'
    } else {
      return ''
    }
  }
  render () {
    return (
      <div className='auth-wrapper change-forgotten'>
        <Header />
        <div className='background-wrapper'>
          <div className='content'>
            <div className='form-wrapper'>
              <h2>Forgot Password</h2>
              <form onSubmit={this.handleSubmit}>
                <p>Don’t worry! Enter your email and we will email you a security code to reset your password.</p>
                <br />
                <FdInput className='input'
                  type='text'
                  placeholder='Email'
                  value={this.state.form.email}
                  name='email'
                  onChange={this.handleInputChange}
                  validData={this.state.validEmail}
                  warningReason={this.state.invalidEmail} />
                <div className='navigation'>
                  <input className='continue' type='submit' value='Continue' />
                  <NavLink to='/sign-in' className='sign-in-link nav-link'>Login Now</NavLink>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

Forgot.propTypes = {
  showSpinner: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

Forgot.defaultProps = {}

export default Forgot
