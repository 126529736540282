import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import Upcoming from './Upcoming'

const mapStateToProps = (state) => {
  return {
    isServerLive: state.temp.isServerLive
  }
}

const UpcomingContainer = withRouter(connect(
  mapStateToProps,
  null
)(Upcoming))

export default UpcomingContainer
