import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'

import { alphabeticalSort } from '../../../utils'
import FgSelect from '../../FgSelect/FgSelect'

class MultiDropdownField extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
    this.mapOptions = memoize(this.mapOptions)
    this.mapSelected = memoize(this.mapSelected)
    this.onChange = this.onChange.bind(this)
    this.isOptionSelected = this.isOptionSelected.bind(this)
  }
  mapOptions (options) {
    if (this.props.isSortable) {
      options = alphabeticalSort(options)
    }
    return options.map(option => {
      return {
        value: option,
        label: option
      }
    })
  }
  mapSelected (option) {
    const { abbreviations } = this.props
    let multiLabel
    if (option.length === 1) {
      multiLabel = option[0]
    } else {
      multiLabel = option
        .map(opt => abbreviations[opt] || opt)
        .join(' / ')
    }
    return {
      value: option,
      label: multiLabel
    }
  }
  onChange (option, { action }) {
    let { value } = this.props
    switch (action) {
      case 'select-option':
        let newValue = value.includes(option.value)
          ? value.filter(v => v !== option.value)
          : value.concat(option.value)
        this.props.onChange(newValue)
        break
      default:
        break
    }
  }
  isOptionSelected (selected, options) {
    return options.some(option => {
      return option.value.includes(selected.value)
    })
  }
  render () {
    const {
      invalid,
      isRequired,
      options,
      title,
      value,
      disabled
    } = this.props
    return (
      <div className={`form-row ${disabled ? 'disabled' : ''}`}>
        <div className='row-title'>
          <p className={`row-text ${invalid ? 'row-text-invalid' : ''}`}>
            {title}{isRequired ? <span className='asterisk'>*</span> : ''}:
          </p>
        </div>
        <div className='row-content'>
          <FgSelect
            placeholder='Select all relevant'
            options={this.mapOptions(options)}
            value={value.length ? this.mapSelected(value) : null}
            isSearchable
            onChange={this.onChange}
            isOptionSelected={this.isOptionSelected}
          />
        </div>
      </div>
    )
  }
}

MultiDropdownField.propTypes = {
  publicId: PropTypes.string,
  title: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  invalid: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool.isRequired,
  isSortable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
}

MultiDropdownField.defaultProps = {
  invalid: false
}

export default MultiDropdownField
