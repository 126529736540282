export function drawCalendarBorders (container) {
  if (!container) return
  // Reusable CSS styles
  const borderStyle = '1px solid #464646'
  const borderRadius = '5px'

  // Select weeks containers and count their numbers
  const divWeeks = container.querySelectorAll(`.DayPicker-Week`)
  const weeksLength = divWeeks.length

  for (let i = 0; i < weeksLength; i++) { // for every week container
    let prevDaysOutside = []
    const currDaysOutside = container.querySelectorAll(`
      .DayPicker-Body
      .DayPicker-Week:nth-child(${i + 1})
      .DayPicker-Day--outside
    `) // select all outside days in current week

    if (i > 0) { // week container's index can't be equal 0, start from 1
      prevDaysOutside = container.querySelectorAll(`
        .DayPicker-Body
        .DayPicker-Week:nth-child(${i})
        .DayPicker-Day--outside
      `) // select all outside days in previous week
    }

    // count number of outside days
    const prevDaysOutsideLength = prevDaysOutside.length
    const currDaysOutsideLength = currDaysOutside.length

    // redraw bottom borders for last weeks in month
    // i > 1 because in all cases we need to redraw borders for row with index more than 1
    if (i > 1 && prevDaysOutsideLength < currDaysOutsideLength) {
      const startIndex = 7 - currDaysOutside.length // 7 is a number of days in week

      // draw bottom borders for all elements with index greater then index of last elem in last row
      for (let j = startIndex; j < startIndex + currDaysOutsideLength; j++) {
        const elem = container.querySelector(`
          .DayPicker-Body
          .DayPicker-Week:nth-child(${i})
          .DayPicker-Day:nth-of-type(${j + 1})
          .DayPicker-Filled
        `)
        elem.style.borderBottom = borderStyle

        // round out border for last elem in penultimate row
        if (j + 1 === startIndex + currDaysOutsideLength) {
          elem.style.borderBottomRightRadius = borderRadius
        }
      }

      // draw and round out border for last elem in last row
      const lastElem = container.querySelector(`
        .DayPicker-Body
        .DayPicker-Week:nth-child(${i + 1})
        .DayPicker-Day:nth-of-type(${startIndex})
        .DayPicker-Filled
      `)
      lastElem.style.borderRight = borderStyle
      lastElem.style.borderBottomRightRadius = borderRadius
    }

    // round out border for first elem in second row
    if (prevDaysOutsideLength > currDaysOutsideLength) {
      container.querySelector(`
        .DayPicker-Body
        .DayPicker-Week:nth-child(${i + 1})
        .DayPicker-Day:first-of-type
        .DayPicker-Filled
      `).style.borderTopLeftRadius = borderRadius
    }
  }
}
