
import styled from 'styled-components'

export const CheckboxContainer = styled.div`
  display: block;
  vertical-align: middle;
  margin: 10px;
  pointer-events: ${props => props.disabled ? 'none' : null}
`

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  background: ${props => (props.stockAlreadyRequested ? 'gray' : props.checked ? '#1f9512' : '#c6c6c6')}
  border-radius: 3px;
  transition: all 150ms;
  margin-right: 10px;
  vertical-align: bottom;
  border: 0.4px solid black;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }
`
