import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'

import { alphabeticalSort } from '../../../utils'
import FgSelect from '../../FgSelect/FgSelect'

class DropdownField extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      value: this.props.value
    }
    this.selectClassName = this.getSelectClassName()
    this.mapOptions = memoize(this.mapOptions)
    this.mapSelected = memoize(this.mapSelected)
    this.onChange = this.onChange.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.filterOption = this.filterOption.bind(this)
  }
  componentDidUpdate () {
    if (this.props.value !== this.state.value) {
      if (this.props.value === '' && this.props.publicId === 'exportCsvSkip') {
        return
      }
      this.setState({
        value: this.props.value
      })
    }
  }
  mapOptions (options) {
    if (this.props.isSortable) {
      options = alphabeticalSort(options)
    }
    return options.map(option => {
      return {
        value: option,
        label: option
      }
    })
  }
  mapSelected (option) {
    return {
      value: option,
      label: option
    }
  }
  getSelectClassName () {
    let classes = []
    if (this.props.isCustom) {
      classes.push('fg-dropdown-custom-input')
    }
    if (this.props.publicId === 'vendorPic') {
      classes.push('fg-dropdown-uppercase-input')
    }
    return classes.join(' ')
  }
  onChange (option, { action }) {
    switch (action) {
      case 'select-option':
        this.setState({
          value: option.value
        }, () => {
          this.props.onChange(option.value)
        })
        break
      default:
        break
    }
  }
  onInputChange (value, { action }) {
    if (this.props.isCustom) {
      switch (action) {
        case 'input-change':
          this.setState({
            value: value
          }, () => {
            this.props.onChange(value)
          })
          break
        default:
          break
      }
    }
  }
  filterOption (obj, searchStr) {
    if (this.props.isCustom) {
      return obj.value.toLowerCase().includes(this.state.value.toLowerCase())
    } else {
      return obj.value.toLowerCase().includes(searchStr.toLowerCase())
    }
  }

  renderPicField (title, invalid, isRequired) {
    let vendorTitle = title.slice(0, title.search('PIC') + 'PIC'.length)
    let vendorDesc = title.slice(title.search('PIC') + 'PIC'.length)
    return (
      <React.Fragment>
        <p className={`row-text ${invalid ? 'row-text-invalid' : ''}`}>
          {vendorTitle}{isRequired ? <span className='asterisk'>*</span> : ''}:
        </p>
        <p className={`row-text ${invalid ? 'row-text-invalid' : ''}`}>
          {vendorDesc}
        </p>
      </React.Fragment>
    )
  }

  render () {
    const {
      invalid,
      isRequired,
      isSearchable,
      options,
      title,
      disabled
    } = this.props
    let placeholder
    let warningReason = ''
    if (this.props.publicId === 'vendorName') {
      placeholder = 'Enter Name'
    } else if (this.props.publicId === 'vendorPic') {
      placeholder = 'Enter PIC No.'
    } else if (title === 'Individual Weight' || title === 'Fat Score' || title === 'Individual ID') {
      placeholder = 'Choose item'
    } else {
      placeholder = undefined
    }
    if (this.props.valid) {
      if (this.props.title === 'Individual Weight') {
        warningReason = 'Please select Individual Weight'
      } else if (this.props.title === 'Fat Score') {
        warningReason = 'Please select Fat Score'
      }
    }
    return (
      <div className={`form-row ${disabled ? 'disabled' : ''}`}>
        <div className='row-title'>
          {this.props.publicId === 'vendorPic'
            ? this.renderPicField(title, invalid, isRequired)
            : <p className={`row-text ${invalid ? 'row-text-invalid' : ''}`}>
              {title}{isRequired ? <span className='asterisk'>*</span> : ''}:
            </p>
          }
        </div>
        <div className='row-content'>
          <FgSelect
            className={`${this.selectClassName} ${this.props.valid ? 'red' : ''}`}
            placeholder={placeholder || 'Select item'}
            inputValue={this.props.isCustom ? this.state.value : undefined}
            filterOption={this.filterOption}
            options={this.mapOptions(options)}
            value={this.state.value ? this.mapSelected(this.state.value) : null}
            isSearchable={isSearchable}
            onChange={this.onChange}
            onInputChange={this.onInputChange}
            valid={this.props.valid}
            warningReason={warningReason}
            dropdownField
          />
        </div>
      </div>
    )
  }
}

DropdownField.propTypes = {
  publicId: PropTypes.string,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  invalid: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool.isRequired,
  isSearchable: PropTypes.bool.isRequired,
  isSortable: PropTypes.bool.isRequired,
  isCustom: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
}

DropdownField.defaultProps = {
  invalid: false
}

export default DropdownField
