/* global Blob, URL */
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { range as _range, clone as _clone } from 'lodash'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faArrowLeft,
  faArrowRight,
  faArrowDown,
  faTimes,
  faPlus
} from '@fortawesome/fontawesome-free-solid'
import Papa from 'papaparse'

import { showModal, updateLotAssessmentPager, changeStateProp, hideModal } from '../../../actions'
import ProgressBar from '../../FgProgressBar/FgProgressBar'
import FormField from '../FormField'
import DropdownField from './DropdownField'
import { findAllInvalidFields } from '../../../utils/FindFieldLot'
import { validateForm } from '../../../utils/Form'
import { AdjustWeight } from './index'
import { MIN_WEIGHED_HEADS_PERCENTAGE } from '../../../constants'
import { subTitles } from '../../../constants/strings'

const allowedFieldsForExport = ['weightLive', 'fatScore', 'animalIndividualId']
const acceptableValues = [null, 1, 2, 3, 4, 5]

function parseFatScore (value) {
  if (acceptableValues.find(el => el === value)) {
    return value
  } else {
    return null
  }
}

const csvDelimiter = ','

class RepeatForNumber extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      index: this.props.savedIndex || 0,
      selectedIndWeight: '',
      selectedFatScore: '',
      selectedIndId: '',
      finishButtonLocked: false,
      formElementValuesLength: this.props.formElement.values.length
    }
    this.onResize = this.onResize.bind(this)
    this.exportWeights = this.exportWeights.bind(this)
    this.importWeights = this.importWeights.bind(this)
    this.clearAllCsv = this.clearAllCsv.bind(this)
    this._moveNext = this._moveNext.bind(this)

    this.repeatRefs = []
    this.exportLink = React.createRef()
  }
  static getDerivedStateFromProps (props, state) {
    if (state.index + 1 > props.formElement.values.length) {
      return {
        index: props.formElement.values.length - 1
      }
    }
    if (state.formElementValuesLength !== props.formElement.values.length) {
      return {
        finishButtonLocked: false,
        formElementValuesLength: props.formElement.values.length
      }
    }
    return null
  }

  componentDidMount () {
    const { mode, formElement } = this.props
    if (mode === 'reoffer') {
      this.setState({
        index: formElement.values.length - 1
      })
    } else {
      if (!this.props.rememberedLotsWithIndex) {
        this.props.changeStateProp('rememberedLotsWithIndex', { lots: [] }, 'main')
      } else {
        let lotWithIndexForWeights = this.props.rememberedLotsWithIndex.lots.find(lot => lot.id === this.props.draftLot['_id'])
        if (lotWithIndexForWeights) {
          this.setState({
            index: lotWithIndexForWeights.index
          })
        }
      }
    }
    window.addEventListener('resize', this.onResize)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.index !== this.state.index) {
      // smooth transitions for repeatForNumber
      if (this.showComponent()) {
        let offsetLeft = this.repeatRefs[this.state.index].getBoundingClientRect().width
        let newOffset = offsetLeft * this.repeatForNumRange(this.state.index).indexOf(this.state.index)
        this.setState({
          rfnTransition: 'all 0.4s linear',
          rfnTransform: `translateX(${newOffset * -1}px)`
        })
      }
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.onResize)
  }

  showComponent () {
    return this.props.headsCount > 0
  }

  onResize () {
    this._move(0, { validateMove: false })
  }

  exportWeights () {
    let data = this.props.formElement.values
      .map(array => {
        return array
          .filter(field => allowedFieldsForExport.includes(field.publicId))
          .map(field => field.value)
      })
    if (data.length === 0) {
      return
    }
    data.unshift(this.props.formElement.values[0]
      .filter(field => allowedFieldsForExport.includes(field.publicId))
      .map(field => field.title)) // add titles
    const dataStr = data
      .map(row => row.join(csvDelimiter))
      .reduce((content, row) => content + '\r\n' + row)
    let downloadUrl = URL.createObjectURL(new Blob([dataStr], { type: 'text/csv' }))
    let fileName = `${this.props.draftLot.description || 'No Description'} - weights ${moment().format('dddd DD MMM YYYY')}.csv`
    this.exportLink.current.setAttribute('download', fileName)
    this.exportLink.current.setAttribute('href', downloadUrl)
    this.exportLink.current.click()
    URL.revokeObjectURL(downloadUrl)
  }

  clear = () => {
    if (this.props.formElement.values && this.props.formElement.values.length > (this.props.kindType === 'goat' ? 0 : 1)) {
      this.props.removeRFNElement(this.state.index)
    }
  }

  clearAllCsv (e) {
    // let withOutCsv = this.props.formElement.values.filter(group => {
    //   let isFromCsv = false
    //   group.forEach(field => {
    //     if (field.fromCsv) {
    //       isFromCsv = true
    //     }
    //   })
    //   return !isFromCsv
    // })
    let withOutCsv = this.props.formElement.values
    withOutCsv = []
    if (withOutCsv.length === 0) {
      withOutCsv.push(this.props.formElement.values[0].filter(field => !field.fromCsv))
      withOutCsv[0].forEach((elem) => {
        if (elem.publicId === 'weightLive') {
          elem.value = 0
        } else if (elem.publicId === 'fatScore') {
          elem.value = 0
        }
      })
    }

    this.props.onChangeDraftLot('count', withOutCsv.length)
    setTimeout(() => {
      //
      // we need this sequence, because
      // autosaving have timeout
      //
      // firstly we need to change 'count'
      // then set new array with new values
      //
      if (this.props.kindType === 'sheep' || this.props.kindType === 'goat') {
        this.props.onChangeDraftLot('countWeighed', withOutCsv.length)
        setTimeout(() => {
          this.props.handleChange(withOutCsv, `${this.props.path}`)
        }, 400)
      } else {
        this.props.handleChange(withOutCsv, `${this.props.path}`)
      }
    }, 400)
  }

  importWeights (evt) {
    let file = evt.target.files[0]
    if (file) {
      evt.persist()
      Papa.parse(file, {
        error: () => {
          this.props.showModal({
            message: 'Invalid CSV-file'
          })
        },
        complete: (results) => {
          results.data = results.data.filter((elem) => elem.length > 1)
          if (results.data.length + this.props.formElement.values.length > 2000) {
            this.props.showModal({
              message: `Maximum allowed upload of head weighted is 2000  per lot`
            })
            return
          }
          evt.target.value = ''
          if (results.errors.length > 0) {
            return this.props.showModal({
              message: 'Invalid CSV-file'
            })
          }
          this.props.showModal({
            title: 'Upload CSV',
            message: subTitles.UPLOAD_CSV,
            customClassName: 'upload-csv',
            topBar: true,
            buttons: [{
              text: 'Cancel',
              isCancel: true,
              onPress: () => {
                this.props.changeStateProp('selectedIndWeightInValid', false, 'temp')
                this.props.changeStateProp('selectedFatScoreInValid', false, 'temp')
                this.setState({
                  selectedIndWeight: '',
                  selectedFatScore: '',
                  selectedIndId: ''
                })
              }
            }, {
              text: 'Upload',
              isSuccess: true,
              isNotClose: true,
              onPress: () => {
                this.deleteCurrentLotFromRemeberedLotsWithIndex(this.props.draftLot['_id'])
                this.setState({
                  index: 0
                })
                let selectedIndWeightIndex = results.data[0].indexOf(this.state.selectedIndWeight)
                let selectedFatScoreIndex = results.data[0].indexOf(this.state.selectedFatScore)
                let selectedIndIdIndex = results.data[0].indexOf(this.state.selectedIndId)
                if (selectedIndWeightIndex !== -1 && (selectedFatScoreIndex !== -1 || this.props.auction.kindData.type === 'sheep-abblamb')) {
                  this.props.changeStateProp('selectedIndWeightInValid', false, 'temp')
                  this.props.hideModal()
                  this.props.showModal({
                    message: `Data entered manually will be deleted. Do you want to proceed? `,
                    buttons: [
                      {
                        text: 'Yes',
                        onPress: () => {
                          let importedValues = results.data.slice(1)
                          let schema = this.props.formElement.values[0].map(field => {
                            let schemaField = _clone(field)
                            if (typeof schemaField.value === 'number') {
                              schemaField.value = 0
                            } else if (typeof schemaField.value === 'string') {
                              schemaField.value = ''
                            } else if (Array.isArray(schemaField.value)) {
                              schemaField.value = []
                            }
                            return schemaField
                          })

                          schema.push({ type: 'hidden', fromCsv: true })
                          let importedArray = importedValues.map(element => {
                            return schema.map(el => {
                              try {
                                if (el.publicId === 'weightLive' && selectedIndWeightIndex !== -1) {
                                  let value = parseFloat(element[selectedIndWeightIndex], 10) || el.value || null
                                  if (value) {
                                    return {
                                      ...el,
                                      value
                                    }
                                  }
                                }
                                if (el.publicId === 'fatScore' && selectedFatScoreIndex !== -1) {
                                  let value = parseFatScore(parseInt(element[selectedFatScoreIndex], 10) || el.value)
                                  if (value) {
                                    return {
                                      ...el,
                                      value
                                    }
                                  }
                                }
                                if (el.publicId === 'animalIndividualId' && selectedIndIdIndex !== -1) {
                                  return {
                                    ...el,
                                    value: '' + (element[selectedIndIdIndex] || el.value)
                                  }
                                }
                                return el
                              } catch (err) {
                                return el
                              }
                            })
                          })
                          let newArray = []
                          newArray = newArray.concat(importedArray)
                          this.props.onChangeDraftLot('count', newArray.length)
                          setTimeout(() => {
                            //
                            // we need this sequence, because
                            // autosaving have timeout
                            //
                            // firstly we need to change 'count'
                            // then set new array with new values
                            //
                            if (this.props.kindType === 'sheep' || this.props.kindType === 'goat') {
                              this.props.onChangeDraftLot('countWeighed', newArray.length)
                              setTimeout(() => {
                                this.props.handleChange(newArray, `${this.props.path}`)
                              }, 400)
                            } else {
                              this.props.handleChange(newArray, `${this.props.path}`)
                            }
                          }, 400)
                          this.props.hideModal()
                          this.setState({
                            selectedIndWeight: '',
                            selectedFatScore: '',
                            selectedIndId: ''
                          })
                        }
                      },
                      {
                        text: 'No',
                        onPress: () => {
                          this.props.hideModal()
                          this.setState({
                            selectedIndWeight: '',
                            selectedFatScore: '',
                            selectedIndId: ''
                          })
                        }
                      }
                    ]
                  })
                } else {
                  if (selectedIndWeightIndex !== -1 && selectedFatScoreIndex === -1) {
                    this.props.changeStateProp('selectedFatScoreInValid', true, 'temp')
                  } else if (selectedFatScoreIndex !== -1 && selectedIndWeightIndex === -1) {
                    this.props.changeStateProp('selectedIndWeightInValid', true, 'temp')
                  } else {
                    this.props.changeStateProp('selectedIndWeightInValid', true, 'temp')
                    this.props.changeStateProp('selectedFatScoreInValid', true, 'temp')
                  }
                }
              }
            }],
            customBodyJSX: () => {
              const options = results.data[0]
              return (
                <div className='custom-message'>
                  <p className='message'>You are importing the weight and fat score of {results.data.length - 1} head
                    of {this.props.kindType}</p>
                  <DropdownField
                    title='Individual Weight'
                    value={this.state.selectedIndWeight}
                    valid={this.props.selectedIndWeightInValid}
                    options={options}
                    onChange={(value) => {
                      this.props.changeStateProp('selectedIndWeightInValid', false, 'temp')
                      this.setState({
                        selectedIndWeight: value,
                        validIndividual: true
                      })
                    }}
                    isCustom={false}
                    isRequired
                    isSearchable={false}
                    isSortable
                    publicId='exportCsvSkip' // very important
                  />
                  {
                    this.props.auction.kindData.type !== 'sheep-abblamb' && 
                    <DropdownField
                    title='Fat Score'
                    value={this.state.selectedFatScore}
                    valid={this.props.selectedFatScoreInValid}
                    options={options}
                    onChange={(value) => {
                      this.props.changeStateProp('selectedFatScoreInValid', false, 'temp')
                      this.setState({
                        selectedFatScore: value
                      })
                    }}
                    isCustom={false}
                    isRequired
                    isSearchable={false}
                    isSortable
                    publicId='exportCsvSkip' // very important
                  />
                  }
                  <DropdownField
                    title='Individual ID'
                    value={this.state.selectedIndId}
                    options={options}
                    onChange={(value) => {
                      this.setState({
                        selectedIndId: value
                      })
                    }}
                    isCustom={false}
                    isRequired={false}
                    isSearchable={false}
                    isSortable
                    publicId='exportCsvSkip' // very important
                  />
                </div>
              )
            }
          })
        }
      })
    }
  }

  repeatForNumRange (index) {
    let total = parseInt(this.props.headsCount, 10)
    let range = _range(total)
    let shortRange = range.slice(Math.max(index - 1, 0), index).concat(range.slice(index, index + 2))
    return shortRange
  }

  updateRememberedLotsWithIndex (newIndex) {
    if (this.props.rememberedLotsWithIndex) {
      let ids = this.props.rememberedLotsWithIndex.lots.map((lot) => lot.id)
      let lotId = ids.find((id) => id === this.props.draftLot['_id'])
      let lotIndex = ids.findIndex((id) => id === this.props.draftLot['_id'])
      let newRememberedLotsWithIndex = this.props.rememberedLotsWithIndex.lots
      if (lotId) {
        newRememberedLotsWithIndex[lotIndex].index = newIndex
      } else {
        newRememberedLotsWithIndex.push({ id: this.props.draftLot['_id'], index: newIndex })
      }
      this.props.changeStateProp('rememberedLotsWithIndex', { lots: newRememberedLotsWithIndex }, 'main')
    }
  }

  deleteCurrentLotFromRemeberedLotsWithIndex = (lotId) => {
    if (this.props.rememberedLotsWithIndex) {
      let newRememberedLotsWithIndex = this.props.rememberedLotsWithIndex.lots.filter((lot) => lot.id !== lotId)
      this.props.changeStateProp('rememberedLotsWithIndex', { lots: newRememberedLotsWithIndex }, 'main')
    }
  }

  _move (change, { validateMove = true } = {}) {
    let stateIndex = this.state.index
    let formElement = this.props.formElement
    if (change < 0) {
      stateIndex += change
      if (stateIndex <= 0) {
        stateIndex = 0
      }
    } else {
      // only allow 'Next' click if user all values are valid on current
      if (formElement.values[stateIndex]) {
        if (validateMove) {
          let customFormValidation = validateForm(formElement.values[stateIndex], false, false, { draft: false })
          let invalidElems = findAllInvalidFields(customFormValidation.values)
          if (invalidElems.length) {
            // if invalid
            this.props.showModal({
              message: `Please Enter ${invalidElems[0].title}`
            })
            this.setState({
              finishButtonLocked: false
            })
            return
          }
        }
        // if valid
        stateIndex += change
        if (stateIndex >= formElement.values.length - 1) {
          stateIndex = formElement.values.length - 1
        }
      }
    }
    if (this.showComponent()) {
      let offsetLeft = this.repeatRefs[this.state.index].getBoundingClientRect().width
      let newOffset = offsetLeft * this.repeatForNumRange(stateIndex).indexOf(this.state.index)
      this.updateRememberedLotsWithIndex(stateIndex)
      this.setState({
        index: stateIndex,
        rfnTransition: 'none',
        rfnTransform: `translateX(${newOffset * -1}px)`
      })
    }
    if (this.props.lotId) {
      this.props.updateLotAssessmentPager(this.props.lotId, stateIndex)
    }
  }

  createRepeatRef (ref, index) {
    this.repeatRefs[index] = ref
  }

  _moveNext () {
    const { headsCount } = this.props
    const { index } = this.state
    if (index === headsCount - 1) {
      this.setState({
        finishButtonLocked: true
      })
    }
    this._move(1)
  }

  render () {
    const {
      formElement,
      headsCount,
      kindType,
      path,
      mode,
      assessmentInfo
    } = this.props
    const {
      index,
      rfnTransform,
      rfnTransition
    } = this.state
    if (!this.showComponent()) {
      return <div />
    }
    if (rfnTransition !== 'none') {
      setTimeout(() => {
        this.setState({
          rfnTransition: 'none'
        })
      }, 400)
    }
    const reofferMode = mode === 'reoffer'
    const editLateMode = mode === 'edit-late'
    const disabled = reofferMode || editLateMode
    return [(
      <div className='form-row form-row-rfn form-row-repeat-for-number' key='main'>
        <div className='rfn-stats'>
          <div className='rfn-current'>
            <span className='current-num'>{index + 1}</span>
            <span className='total-num'>/{formElement.values.length}</span>
          </div>
          <ProgressBar percentage={assessmentInfo.percentageDone} />
          <div className='rfn-progress-info'>
            <div className='rfn-info rfn-info-weight'>
              <div className='rfn-info-value'>{assessmentInfo.avgWeight.toFixed(2)}</div>
              <div className='rfn-info-desc'>Ass/wt</div>
            </div>
            {
              !(this.props.auction.kindData.type === 'sheep-abblamb') && (
                <div className='rfn-info rfn-info-rating'>
                  <div className='rfn-info-value'>{assessmentInfo.avgRating.toFixed(1)}</div>
                  <div className='rfn-info-desc'>Fat Score</div>
                </div>
              )
            }
          </div>
        </div>
        <div className='rfn-fields'>
          <div className='rfn-fields-wrapper'>
            <div
              className={`rfn-fields-container ${disabled ? 'disabled' : ''}`}
              style={{ transition: rfnTransition, transform: rfnTransform }}>
              {this.repeatForNumRange(index).map(repeatIndex =>
                <div
                  key={repeatIndex}
                  className='rfn-fields-item'
                  ref={(field) => this.createRepeatRef(field, repeatIndex)}>
                  {formElement.values[repeatIndex] && formElement.values[repeatIndex].map((repeatItem, index) =>
                    <FormField
                      {...this.props}
                      isRepeat
                      key={index}
                      index={index}
                      activeSlideIndex={this.state.index}
                      slideIndex={repeatIndex}
                      path={`${path}.values.${repeatIndex}.${index}`}
                      formElement={repeatItem}
                      moveToNext={this._moveNext}
                    />
                  )}
                </div>
              )}
            </div>
            <div className='rfn-controls'>
              <div
                className={`rfn-nav rfn-nav-prev ${(this.state.index === 0 || rfnTransition !== 'none') ? 'locked' : ''}`}
                onClick={() => {
                  setTimeout(() => {
                    this._move(-1)
                  }, 150)
                }}>
                <span className='icon-wrapper icon-success'><FontAwesomeIcon size='lg' icon={faArrowLeft} /></span>
                <span>Previous</span>
              </div>
              {!reofferMode && <div
                className={`rfn-nav rfn-nav-next`}
                onClick={() => this.clear()}>
                <span>Clear</span>
                <span className='icon-wrapper icon-red'><FontAwesomeIcon size='lg' icon={faTimes} /></span>
              </div>}
              <div
                className={`rfn-nav rfn-nav-next ${((this.state.finishButtonLocked && this.state.index === headsCount - 1) || rfnTransition !== 'none') ? 'locked' : ''}`}
                onClick={() => {
                  setTimeout(() => {
                    this._moveNext()
                  }, 150)
                }}>
                <span>{this.state.index === headsCount - 1 ? 'Finish' : 'Next'}</span>
                <span className='icon-wrapper icon-success'><FontAwesomeIcon size='lg' icon={faArrowRight} /></span>
              </div>
            </div>
          </div>
        </div>

        {reofferMode &&
        <AdjustWeight
          onChangeDraftLot={this.props.onChangeDraftLot}
          adjustWeight={this.props.draftLot.adjustWeight}
          averageWeight={assessmentInfo.avgWeight}
        />}

        {
          !reofferMode && kindType === 'sheep' && !(this.props.auction.kindData.type === 'sheep-abblamb')
            ? (
              <div className='rfn-notice'>
                <div className='rfn-recommend'>
                  We recommend weighing a {MIN_WEIGHED_HEADS_PERCENTAGE}% sample size. Assessed Weight will be taken
                  across sample weighed.
                </div>
              </div>
            )
            : null
        }
      </div>
    ), (
      <div className={`form-row form-row-rfn imports-exports ${disabled ? 'disabled' : ''}`} key='imports-exports'>
        <div className='rfn-fields'>
          <a href={false} className='hidden' download='weights.csv' ref={this.exportLink}>Export weights</a>
          <div className='block'>
            <h3>Upload Weights (CSV):</h3>
            <label className='btn'>
              <span>Choose File</span>
              <input
                type='file'
                onChange={this.importWeights}
                className='hidden no-width'
                accept='.csv' />
              <span className='icon-wrapper icon-success'><FontAwesomeIcon size='lg' icon={faPlus} /></span>
            </label>
          </div>
          <div className='block'>
            <h3>Download Weights (CSV):</h3>
            <div className='btn' onClick={this.exportWeights}>
              <span>Download File</span>
              <span className='icon-wrapper icon-warning'><FontAwesomeIcon size='lg' icon={faArrowDown} /></span>
            </div>
          </div>
          <div className='block'>
            <h3>Clear All (CSV) data:</h3>
            <div className='btn' onClick={this.clearAllCsv}>
              <span>Clear All</span>
              <span className='icon-wrapper icon-red'><FontAwesomeIcon size='lg' icon={faTimes} /></span>
            </div>
          </div>
        </div>
      </div>
    )]
  }
}

RepeatForNumber.propTypes = {
  row: PropTypes.object.isRequired,
  formElement: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  headsCount: PropTypes.number.isRequired,
  kindType: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired
}

RepeatForNumber.defaultProps = {}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ showModal, hideModal, updateLotAssessmentPager, changeStateProp }, dispatch)
}

const mapStateToProps = (state, ownProps) => {
  let savedIndex
  if (ownProps.lotId && state.main.lotsInfo && state.main.lotsInfo[ownProps.lotId] && state.main.lotsInfo[ownProps.lotId].assessmentPage) {
    savedIndex = state.main.lotsInfo[ownProps.lotId].assessmentPage
  }
  return {
    savedIndex,
    selectedIndWeightInValid: state.temp.selectedIndWeightInValid,
    selectedFatScoreInValid: state.temp.selectedFatScoreInValid,
    rememberedLotsWithIndex: state.main.rememberedLotsWithIndex,
    auction : ownProps.auction
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RepeatForNumber)
