import React, { Component } from 'react'
import './SelectWithAutoScroll.scss'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faCaretDown
} from '@fortawesome/fontawesome-free-solid'

export default class SelectWithAutoScroll extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: null,
      showOptions: false
    }
    this.inputRef = React.createRef()
    this.dropdownRef = React.createRef()
    this.selectWrapperRef = React.createRef()
  }

  componentDidMount () {
    window.addEventListener('click', this.onBlurOfSelect)
  }

  componentWillUnmount () {
    window.removeEventListener('click', this.onBlurOfSelect)
  }

  onBlurOfSelect = (e) => {
    if (!this.findParentInParentsTree(e.target)) {
      this.setState({
        showOptions: false,
        value: null
      })
    }
  }

  findParentInParentsTree (target) {
    let parent = null
    let index = 0
    while (parent !== document.getElementsByTagName('body')[0]) {
      if (index === 0) {
        parent = target.parentNode
      } else {
        if (parent === null) {
          return false
        }
        if (parent && parent.classList.contains('custom-select-wrapper')) {
          return true
        }
        parent = parent.parentNode
      }
      index++
    }
  }

  findCurrentElem (i) {
    return this.findCurrentIndex() === i
  }

  findCurrentIndex () {
    return this.props.options.findIndex((elem) => {
      return elem.value === this.props.value
    })
  }

  handleChange (e) {
    const { value } = this.state
    if (value === null) {
      let valueWithoutDefault = e.target.value.slice(0, 1)
      this.setState({
        value: valueWithoutDefault
      })
    } else {
      if (e.target.value === '') {
        this.setState({
          value: null
        })
      } else {
        this.setState({
          value: e.target.value
        })
      }
    }
  }

  searchOptionsByValue () {
    const { options } = this.props
    const { value } = this.state
    return options.filter((option) => {
      return value === null || option.label.match(value)
    }).length
  }

  renderOption (option, index) {
    const { options } = this.props
    return (
      <div
        key={index}
        className={`custom-select-option ${this.findCurrentElem(index) ? 'active' : ''}`}
        onClick={() => {
          this.inputRef.current.blur()
          this.setState({
            showOptions: false
          })
          this.props.onChange(options[index])
        }}
      >
        <p>{option.label}</p>
      </div>
    )
  }

  render () {
    const { value, showOptions } = this.state
    const { options, className } = this.props
    return (
      <div
        tabIndex='0'
        className={`custom-select-wrapper ${className || ''}`}
        ref={this.selectWrapperRef}
        onBlur={(e) => {
          this.inputRef.current.blur()
        }}
      >
        <div
          className='custom-select'
          onClick={(e) => {
            this.setState({
              showOptions: !showOptions,
              value: null
            }, () => {
              if (showOptions) {
                this.inputRef.current.blur()
              } else {
                this.inputRef.current.focus()
              }
              if (this.state.showOptions) {
                this.dropdownRef.current.scrollTo(0, this.findCurrentIndex() > 4 ? (this.findCurrentIndex() - 4) * 32.5 : 0)
              }
            })
          }}
        >
          <div className='custom-select-container'>
            <input
              className='custom-select-input'
              style={{ width: `${value !== null ? value.length * 15 : 3}px` }}
              type='text'
              ref={this.inputRef}
              value={value !== null ? `${value}` : ``}
              onChange={(e) => {
                this.handleChange(e)
              }}
            />
            <div className='custom-select-value'>
              { value !== null ? '' : `${options.find((option) => option.value === this.props.value).label}`}
            </div>
          </div>
          <div className='custom-select-icon'>
            <FontAwesomeIcon icon={faCaretDown} />
          </div>
        </div>
        {showOptions &&
        <div
          className='custom-select-options'
          ref={this.dropdownRef}
          style={{
            height: `${this.searchOptionsByValue() !== 0 ? this.searchOptionsByValue() < 10 ? this.searchOptionsByValue() * 32.5 : 290 : 32.5}px`,
            overflowY: `${this.searchOptionsByValue() < 10 ? 'hidden' : 'scroll'}`
          }}
        >
          {options.map((option, index) => {
            if (value === null) {
              return this.renderOption(option, index)
            } else {
              if (option.label.match(value)) {
                return this.renderOption(option, index)
              }
              return ''
            }
          })
          }
          {!this.searchOptionsByValue() && <div className='custom-select-option empty'>No options</div>}
        </div>}
      </div>
    )
  }
}
