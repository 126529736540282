import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

import Api from '../../../services/api'
import { momentTz } from '../../../utils'
import ImageCarousel from '../../ImageCarousel/ImageCarousel'
import DetailedInfo from '../../DetailedInfo/DetailedInfoContainer'
import LotDocuments from '../../LotDocuments/LotDocuments'
import EmailModalLot from '../../Modals/EmailModalLotContainer'
import { changeOrClearMetaTags } from '../../../utils/Lots'
import { BASE_URL } from '../../../config'
import BackButton from '../../BackButton/BackButton'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faArrowDown,
  faEnvelope,
  faPrint,
  faUser
} from '@fortawesome/fontawesome-free-solid'
import SocialButtons from '../../SocialButtons/SocialButtons'
import LotDetailsClosedStatus from '../../LotDetails/LotDetailsClosedStatus'

class LotDetails extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      auction: null,
      lot: null,
      modalIsOpen: false
    }
    this.cancelSource = axios.CancelToken.source()

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.afterOpenModal = this.afterOpenModal.bind(this)
  }
  componentDidMount () {
    changeOrClearMetaTags()
    if (this.props.history.action !== 'POP') {
      window.scroll(0, 0)
    }
    this.getData()
  }
  componentDidUpdate (prevProps, prevState) {
    if ((this.props.match.params.auctionNumber !== prevProps.match.params.auctionNumber) ||
      (this.props.match.params.lotNumericId !== prevProps.match.params.lotNumericId)) {
      this.cancelSource.cancel()
      this.cancelSource = axios.CancelToken.source()
      this.getData()
    } else if (this.props.match.params.kindTitle !== prevProps.match.params.kindTitle) {
      this.checkTitle()
    }
  }
  componentWillUnmount () {
    this.cancelSource.cancel()
    changeOrClearMetaTags(true)
  }
  checkTitle () {
    let kindTitle = this.state.auction.kindData.title
    if (kindTitle.toLowerCase() !== this.props.match.params.kindTitle.toLowerCase()) {
      // if wrong kindTitle
      this.props.history.replace(`/main/results/${kindTitle}/${this.props.match.params.auctionNumber}/${this.props.match.params.lotNumericId}`)
    }
  }
  getData () {
    this.props.showSpinner(true)
    let requests = [
      Api.getAuction(this.props.match.params.auctionNumber, { cancelToken: this.cancelSource.token }),
      Api.getLot(this.props.match.params.lotNumericId, { cancelToken: this.cancelSource.token, trackView: true })
    ]
    Promise.all(requests)
      .then(data => {
        if (data.every(d => !d.cancel)) {
          let [{ auction }, { lot }] = data
          if (data.every(Boolean)) {
            // if OK
            this.setState({
              auction,
              lot
            }, () => {
              if (this.state.lot.draft) {
                // check if a lot is in draft, leave page if it is
                this.props.history.replace(`/main/results/${this.props.match.params.kindTitle}/${this.props.match.params.auctionNumber}`)
              } else {
                this.checkTitle()
              }
            })
          } else {
            if (!auction) {
              // if wrong auctionId
              this.props.history.replace(`/main/results/${this.props.match.params.kindTitle}`)
            } else if (!lot) {
              // if wrong lotId
              this.props.history.replace(`/main/results/${this.props.match.params.kindTitle}/${this.props.match.params.auctionNumber}`)
            }
          }
        }
        this.props.showSpinner(false)
      })
  }
  getAuctionTitle () {
    return `Auction No. ${this.state.auction.number} ${this.state.auction.title}` || ''
  }
  getLotLocation () {
    let addresses = []
    let possibleLocations = ['town', 'state']
    possibleLocations.forEach(loc => {
      let str = this.state.lot.publicDetails.location[loc]
      if (str) {
        addresses.push(str)
      }
    })
    return addresses.join(', ')
  }
  goBackName () {
    const { state } = this.props.location
    if (state && state.from === 'myAdmin') {
      return 'Back to My Auction Results'
    }
    return 'Back to Results'
  }
  openModal () {
    this.setState({
      modalIsOpen: true
    })
  }
  closeModal () {
    this.setState({
      modalIsOpen: false
    })
  }
  afterOpenModal () {
    // console.log('modal opened') custom animation here?
  }
  pdfUrl () {
    return `${BASE_URL}print/lots/${this.state.lot.searchNumericId}/full-details?mode=print`
  }
  render () {
    const { auction, lot } = this.state
    if (!auction || !lot) {
      return <div />
    }
    const shareMessage = `Lot - ${lot.publicDetails.title} ${
      lot.publicDetails.weight.curfew
    }kg Cur/wt ${lot.publicDetails.location.town} ${
      lot.publicDetails.location.state
    }`
    const shareUrl = `${window.location.href}`

    return (
      <div className='lot-wrapper'>
        <EmailModalLot
          openModal={this.openModal}
          closeModal={this.closeModal}
          afterOpenModal={this.afterOpenModal}
          isOpen={this.state.modalIsOpen}
          lot={lot}
        />
        <div className='lot-menu'>
          <div className='left'>
            <p className='fg-header watch-header'>{this.state.auction.title}</p>
            <p className='fg-secondary-header'>Auction No. {this.state.auction.number}</p>
            <p className='auction-live-date'>{momentTz(auction.liveAt).format('dddd D MMMM YYYY, h.mm A')}</p>
            <p className='fg-paragraph'>{this.state.auction.description}</p>
          </div>
          <div className='right'>
            <SocialButtons shareMessage={shareMessage} shareUrl={shareUrl} />
          </div>
        </div>
        <div className='top-btns results results-flex'>
          <Fragment>
            <BackButton onPress={() => {
              this.props.history.goBack()
            }} name={this.goBackName()} />
            <span className='btn btn-flex' onClick={this.openModal}>
              <span>
                <span className='btn-title'>Email</span> <span className='btn-title'>Lot Details</span>
              </span>
              <span className='icon-wrapper icon-success'><FontAwesomeIcon icon={faEnvelope} /></span>
            </span>
            <a className='btn btn-flex' href={`${BASE_URL}print/lots/${this.state.lot.searchNumericId}/full-details`}>
              <span>
                <span className='btn-title'>Download</span> <span className='btn-title'>Lot Details</span>
              </span>
              <span className='icon-wrapper icon-warning'><FontAwesomeIcon icon={faArrowDown} /></span>
            </a>
            <a className='btn btn-flex' href={this.pdfUrl()} rel='noopener noreferrer' target='_blank'>
              <span>
                <span className='btn-title'>Print</span> <span className='btn-title'>Lot Details</span>
              </span>
              <span className='icon-wrapper icon-success'><FontAwesomeIcon icon={faPrint} /></span>
            </a>
          </Fragment>
        </div>
        <hr className='separator' />
        <div className='lot-info'>
          <div className='lot-info-top'>
            <div className='title'>
              <p>
                <span>Lot </span>
                { this.state.lot.number || 'X' }
                { this.state.lot.createdBy === this.props.userId &&
                  <Fragment>
                    &nbsp;-&nbsp;
                    <span className='icon-wrapper'>
                      <FontAwesomeIcon icon={faUser} />
                    </span>
                    <span> My Listing</span>
                  </Fragment>
                }
              </p>
            </div>
            <div className='location'>
              <p className='lot-location'>{this.getLotLocation()}</p>
            </div>
          </div>
          <hr className='separator' />
          <LotDetailsClosedStatus lot={lot} />
          <div className='lot-title-weights'>
            <p className='title-weight'>{lot.weightGain ? lot.weightGain !== 0 ? `${parseFloat(lot.weightGain.toFixed(2))}kg avdwtg` : '-' : lot.publicDetails.weight.average !== 0 ? `${Math.round(lot.publicDetails.weight.average)}kg ass/wt` : '-'}</p>
            <p className='title-weight curfew'>{`${lot.publicDetails.weight.curfew !== 0 ? `${Math.round(lot.publicDetails.weight.curfew)}kg ${lot.adjustWeight === 0 && lot.optiweighAllowed ? 'live/wt' : 'cur/wt'}` : '-'}`}</p>
          </div>
          {lot.inlineDetails.assessorType &&
            <div className='lot-title-weights'>
              <p className='title-weight assessor-type'>{lot.inlineDetails.assessorType}</p>
            </div>
          }
          {lot.media.length ? <ImageCarousel showVideoIcon lot={lot} lotId={lot._id} media={lot.media} /> : null}
          {lot.media.length ? <hr className='separator' /> : null}
          <LotDocuments documents={lot.documents} />
          {lot.documents.length ? <hr className='separator' /> : null}
          <DetailedInfo lot={lot} />
        </div>
        <hr className='separator' />
      </div>
    )
  }
}

LotDetails.propTypes = {
  showSpinner: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}
LotDetails.defaultProps = {
}

export default LotDetails
