import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { showModal, showSpinner } from '../../../actions'
import Faqs from './Faqs'

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ showModal, showSpinner }, dispatch)
}

export default connect(
  null,
  mapDispatchToProps
)(Faqs)
