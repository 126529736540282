import axios from 'axios'
import ReactGA from 'react-ga'
import { bindActionCreators } from 'redux'
import { showModal, showSpinner } from '../actions/index'
import logOut from './logOut'
import store from '../services/store'
import * as Sentry from '@sentry/browser'
import { apiAlerts as strings } from '../constants/strings'

let bound = bindActionCreators({ showModal, showSpinner }, store.dispatch)

export function ErrorExtractor(error, { scene, silent = false } = {}) {
  if (error && error.response && error.response.status && error.response.status !== 401) {
    Sentry.captureException(error)
  }
  if (silent) {
    if (error.response && error.response.status && error.response.data.error &&
      error.response.data.error.errors &&
      error.response.data.error.errors.length) {
      return {
        isError: true,
        message: error.response.data.error.errors[0].message
      }
    } else {
      return false
    }
  }

  if (axios.isCancel(error)) {
    // console.log('[API Request] Cancelled', error.message || '')
    return { cancel: true }
  }

  if (!error.response) {
    if (window.location.hash.match('auction-live')) {
      bound.showModal({
        title: strings.NO_CONNECTION_TITLE,
        message: strings.CONNECTION_ERROR_SUPPORT
      })
    } else {
      bound.showModal({
        title: strings.NO_CONNECTION_TITLE,
        message: strings.CONNECTION_ERROR
      })
    }
    if (scene === 'sign in') {
      ReactGA.event({
        category: strings.FAILED_LOGIN_NETWORK_ERROR_TITLE,
        action: strings.FAILED_LOGIN_NETWORK_ERROR,
        label: strings.FAILED_LOGIN_NETWORK_ERROR
      }, ['WebApp'])
    }
    return false
  }
  switch (error.response.status) {
    case 400:
      if (error.response.data.error &&
        error.response.data.error.errors &&
        error.response.data.error.errors.length) {
        const fError = error.response.data.error.errors[0]
        if (scene === 'sign up') {
          if (fError.code === 'duplicateFieldError') {
            bound.showModal({
              title: 'FarmGate',
              message: fError.message
            })
          } else {
            bound.showModal({
              title: strings.FARMGATE_TITLE,
              message: strings.SUBMIT_ERROR
            })
          }
        } else if (scene === 'setAutobid') {
          bound.showModal({
            title: strings.FARMGATE_TITLE,
            message: fError.message
          })
        } else {
          bound.showModal({
            title: fError.title ? fError.title : strings.ERROR_TITLE,
            message: fError.message
          })
        }
      } else {
        bound.showModal({
          title: strings.ERROR_TITLE,
          message: strings.SOMETHING_WRONG_ERROR + error.message
        })
      }
      break
    case 401:
      if (scene === 'sign in') {
        ReactGA.event({
          category: strings.FAILED_LOGIN_CRED_ERROR_TITLE,
          action: strings.FAILED_LOGIN_CRED_ERROR,
          label: strings.FAILED_LOGIN_CRED_ERROR
        }, ['WebApp'])
        return 401
      } else {
        logOut.init({ force: true })
      }
      break
    case 403:
      if (scene === 'sign in') {
        return 403
      }
      break
    case 404:
      if (scene === 'changeCurrentPassword') {
        bound.showModal({
          title: strings.ERROR_TITLE,
          message: strings.WRONG_PASSWORD_ERROR
        })
      } else if (scene === 'change-password') {
        bound.showModal({
          title: strings.SECURITY_CODE_ERROR_TITLE,
          message: strings.SECURITY_CODE_ERROR
        })
      } else if (scene === 'forgotPassword') {
        bound.showModal({
          title: strings.EMAIL_ERROR_TITLE,
          message: strings.EMAIL_ERROR
        })
      } else {
        bound.showModal({
          title: strings.ERROR_TITLE,
          message: strings.NOT_FOUND_ERROR
        })
      }
      break
    case 500:
      bound.showModal({
        title: strings.ERROR_TITLE,
        message: strings.BAD_REQUEST_ERROR
      })
      break
    default:
      return false
  }
  return false
}
