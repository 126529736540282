import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Checkbox from '../../Checkbox/Checkbox'
import SignatureImage from '../elements/SignatureImage'

class SaleConditions extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      editable: false
    }
    this.toggleEdit = this.toggleEdit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.input = React.createRef()
  }
  onChange () {
    this.props.onChange('saleConditions', this.input.current.value)
  }
  toggleEdit () {
    this.setState((prevState) => {
      return { editable: !prevState.editable }
    })
  }
  render () {
    const { editable } = this.state
    const { saleConditions, disabled, agentSignature } = this.props
    return (
      <div className={`input-section ${disabled ? 'disabled' : ''}`}>
        <div className='section-heading'>
          <div className='heading-title'>Special Conditions of Sale</div>
        </div>
        <div className='section-body'>
          <div className='form-row'>
            <div className='row-title'>
              <p
                className='row-text'>Change Sale Terms</p>
            </div>
            <div className='row-content'>
              <Checkbox
                type='green'
                value={editable}
                onToggle={this.toggleEdit} />
            </div>
          </div>
          <div className='form-row form-row-long'>
            <div className='row-title'>
              <p
                className='row-text'>Sale Terms:</p>
            </div>
            <div className='row-content'>
              <textarea
                ref={this.input}
                className={`textarea ${!editable ? 'disabled' : ''}`}
                value={saleConditions}
                disabled={!editable}
                maxLength={150}
                onChange={this.onChange}
              />
            </div>
          </div>
          {agentSignature && agentSignature.url && <div className='form-row form-row-long'>
            <div className='row-title'>
              <p
                className='row-text'>Agent Signature Capture</p>
            </div>
            <div className='row-content'>
              <SignatureImage src={agentSignature.url} />
            </div>
          </div>}
        </div>
      </div>
    )
  }
}

SaleConditions.propTypes = {
  saleConditions: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

SaleConditions.defaultProps = {
  disabled: false
}

export default SaleConditions
