import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'

import FgSelect from '../../FgSelect/FgSelect'
import { momentTz } from '../../../utils'
import { sortAuctions } from '../../../utils/Filter'

class AuctionSelect extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
    this.mapOptions = memoize(this.mapOptions)
    this.mapSelected = memoize(this.mapSelected)
    this.onChange = this.onChange.bind(this)
  }
  mapOptions (options) {
    return sortAuctions(options, 'Date', 'down').map(option => {
      return {
        value: option,
        label: momentTz(option.liveAt).format('dddd D MMMM YYYY, h.mm A')
      }
    })
  }
  mapSelected (option) {
    return {
      value: option,
      label: momentTz(option.liveAt).format('dddd D MMMM YYYY, h.mm A')
    }
  }
  onChange (option, { action }) {
    if (action !== 'select-option') return
    this.props.onChange(option.value)
  }
  isOptionSelected (option, selectedArr) {
    return selectedArr.find(selected => option.value._id === selected.value._id)
  }
  render () {
    const { auction, auctions, disabled } = this.props
    return (
      <div className={`form-row ${disabled ? 'disabled' : ''}`}>
        <div className='row-title'>
          <p
            className='row-text'>Auction Date<span className='asterisk'>*</span>:</p>
        </div>
        <div className='row-content'>
          <FgSelect
            placeholder='Select item'
            options={this.mapOptions(auctions)}
            value={auction ? this.mapSelected(auction) : null}
            isSearchable={false}
            onChange={this.onChange}
            isOptionSelected={this.isOptionSelected}
          />
        </div>
      </div>
    )
  }
}

AuctionSelect.propTypes = {
  auctions: PropTypes.array.isRequired,
  auction: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

AuctionSelect.defaultProps = {
  disabled: false
}

export default AuctionSelect
