/* global FileReader, Blob, URL */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import update from 'immutability-helper'
import moment from 'moment'
import memoize from 'memoize-one'

import { showModal, showSpinner } from '../../../actions'
import Api from '../../../services/api'
import { arrayBufferToBase64 } from '../../../utils'
import { getTimestamp } from '../../../utils/Form'
import { compressImage, rotateImage } from '../../../utils/Lots'
import Carousel from '../../Carousel/Carousel'
import Checkbox from '../../Checkbox/Checkbox'
import CropImageModal from '../../Modals/CropImageModal'
import { isSafari, isIos } from '../../../utils/DeviceDetector'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/fontawesome-free-solid'

class MediaPicker extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false,
      image: {},
      imgHeight: 0,
      imgWidth: 0,
      mediaCountToCrop: 0,
      needToBeCompress: [],
      media: this.props.media
    }
    this.MAX_MEDIA_LIMIT = 20
    this.onMedia = this.onMedia.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.saveImage = this.saveImage.bind(this)
    this.openModal = this.openModal.bind(this)
    this.checkMediaLimit = this.checkMediaLimit.bind(this)
    this.getNodes = memoize(this.getNodes)

    this.addMedia = React.createRef()
  }

  getNextOrder () {
    let { media } = this.props
    let maxOrder = Math.max(...media.map(m => m.order))
    return maxOrder >= 0 ? maxOrder + 1 : 0
  }

  newMediaBase () {
    return {
      main: false,
      order: this.getNextOrder(),
      isNew: true
    }
  }

  closeModal () {
    this.setState({
      isOpen: false
    })
  }

  swap = (firstIndex, secondIndex) => {
    let { media } = this.state
    let temp = media[secondIndex]
    let tempOrder = media[firstIndex].order
    media[secondIndex] = media[firstIndex]
    media[firstIndex] = temp
    media[secondIndex].order = media[firstIndex].order
    media[firstIndex].order = tempOrder

    this.props.saveMedia(update(
      update(this.props.media, {
        $splice: [[0, media.length]]
      }),
      { $push: media }
    ))
  }

  dragSwap = (firstIndex, secondIndex) => {
    let { media } = this.state
    if (media) {
      const cutOut = media.splice(firstIndex, 1)[0]
      media.splice(secondIndex, 0, cutOut)
      media = media.map((el, index) => {
        el.order = index
        return el
      })
    }

    this.props.saveMedia(update(
      update(this.props.media, {
        $splice: [[0, media.length]]
      }),
      { $push: media }
    ))
  }

  static getDerivedStateFromProps (props, state) {
    if (props.media !== state.media) {
      return { media: props.media }
    }
  }

  saveImage (croppedImage, rotation) {
    this.props.showSpinner(true)
    let compressImage = this.state.compressedImg
    compressImage.thumbnail = croppedImage
    rotateImage(compressImage.url, rotation).then((result) => {
      compressImage.url = result
      this.props.saveMedia(update(this.props.media, {
        $push: [compressImage]
      }))
      this.props.showSpinner(false)
    })
    this.setState({
      isOpen: false
    }, () => {
      this.setState({ mediaCountToCrop: this.state.mediaCountToCrop - 1, needToBeCompress: this.state.needToBeCompress.slice(1) }, () => {
        if(this.state.mediaCountToCrop > 0) {
          this.openModal()
        }
      })
    })
  }

  openModal () {
    this.props.showSpinner(false)
    this.setState({
      isOpen: true,
      image: this.state.needToBeCompress[0].newMedia,
      compressedImg: this.state.needToBeCompress[0].compressedImg
    })
  }

  onMedia (event) {
    if (this.props.media.length >= this.MAX_MEDIA_LIMIT) {
      this.props.showModal({
        title: 'Max media amount reached',
        message: `You can add max. ${this.MAX_MEDIA_LIMIT} media to each lot`
      })
      this.addMedia.current.value = null
      return
    }
    if(event.target.files.length <= 0) { return }
    this.setState({ mediaCountToCrop: [...event.target.files].filter( s => s.type.includes("image")).length })
    for (let file of event.target.files) {
    if (!file) {
      return
    }
    if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'video/mp4' && ((isSafari() || isIos()) ? file.type !== 'video/quicktime' : true)) {
      this.props.showModal({
        title: 'Uploading error',
        message: `File format not supported, please upload either: JPG, PNG, or MP4`
      })
      this.addMedia.current.value = null
      return
    }
    let fileReader = new FileReader()
    // this.addMedia.current.value = null
    let newMedia = this.newMediaBase()
    if (/video/i.test(file.type)) {
      this.props.showSpinner(true)
      let video = document.createElement('video')
      if (/mp4|quicktime/i.test(file.type)) {
        fileReader.onload = () => {
          let blob = new Blob([fileReader.result], { type: file.type })
          let url = URL.createObjectURL(blob)
          let snapImage = function () {
            let canvas = document.createElement('canvas')
            canvas.width = video.videoWidth
            canvas.height = video.videoHeight
            canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height)
            let image = canvas.toDataURL('image/jpeg')
            newMedia.thumbnail = image
            return image.length > 1000
          }
          let timeupdate = function () {
            if (snapImage()) {
              video.removeEventListener('timeupdate', timeupdate)
              video.pause()
            }
          }
          video.addEventListener('loadeddata', () => {
            if (video.duration > 60) {
              this.props.showSpinner(false)
              this.props.showModal({
                title: 'Video exceeds 1 minute',
                message: 'Please choose another file'
              })
              return false
            } else if (fileReader.result.byteLength < 50000000) {
              this.props.saveMedia(update(this.props.media, {
                $push: [newMedia]
              }))
              this.props.showSpinner(false)
            }
            if (snapImage()) {
              video.removeEventListener('timeupdate', timeupdate)
            }
          })
          video.addEventListener('timeupdate', timeupdate)
          video.preload = 'metadata'
          video.src = url
          // Load video in Safari / IE11
          video.muted = true
          video.playsInline = true
          video.play()
        }
        fileReader.onloadend = () => {
          if (fileReader.result.byteLength > 50000000) {
            this.props.showSpinner(false)
            this.props.showModal({
              title: 'Video exceeds 50mb',
              message: 'Please resize or choose another video'
            })
            return
          }
          newMedia.file = file
          newMedia.mimeType = file.type
          newMedia.url = `data:${file.type};base64,${arrayBufferToBase64(fileReader.result)}`
          newMedia.timestamp = getTimestamp(file.lastModified)
        }
        fileReader.readAsArrayBuffer(file)
      } else {
        this.props.showSpinner(false)
        this.props.showModal({
          title: 'Unsupported format',
          message: 'Please select .mp4 or .mov'
        })
      }
    } else {
      fileReader.onloadstart = () => {
        this.props.showSpinner(true)
      }
      fileReader.onloadend = () => {
        let image = new window.Image()
        image.src = fileReader.result
        image.onload = () => {
          if (image.width < 320 || image.height < 220) {
            this.props.showSpinner(false)
            this.props.showModal({
              title: 'Image size too small',
              message: `Sorry, we could not upload this photo as it is too small. Try taking another photo or choosing one from your library.`
            })
          } else {
            this.setState({
              imgWidth: image.width,
              imgHeight: image.height
            })
            let blob = new Blob([fileReader.result], { type: file.type })
            newMedia.file = file
            newMedia.mimeType = file.type
            newMedia.url = fileReader.result
            newMedia.blob = blob
            newMedia.timestamp = getTimestamp(file.lastModified)
            let options = {
              convertSize: 200,
              quality: 0.8,
              minWidth: 320,
              minHeight: 213,
              maxWidth: 3000,
              maxHeight: 3000,
              mimeType: 'image/jpeg'
            }
            compressImage(newMedia, options)
              .then(compressedImg => {
                this.setState(prevState => ({
                  needToBeCompress: [...prevState.needToBeCompress, {newMedia, compressedImg}]
                }), ()=> {
                  if(this.state.needToBeCompress.length === this.state.mediaCountToCrop) {
                    this.props.showSpinner(false)
                    this.openModal()    
                  }
                })
              })
              .catch(err => {
                console.error(err)
              })
          }
        }
      }
      fileReader.readAsDataURL(file)
    }
  }
  }

  checkMediaLimit (e) {
    if (this.props.media.length >= this.MAX_MEDIA_LIMIT) {
      this.props.showModal({
        title: 'Max media amount reached',
        message: `You can add max. ${this.MAX_MEDIA_LIMIT} media to each lot`
      })
      e.preventDefault()
    }
  }

  removeLoadedMedia (file) {
    this.props.showModal({
      message: `Remove ${/video/.test(file.mimeType) ? 'Video' : 'Photo'}?`,
      buttons: [
        {
          text: 'No'
        },
        {
          text: 'Yes',
          onPress: () => this.removeMediaFromServer(file)
        }
      ]
    })
  }

  async removeMediaFromServer (file) {
    if (file.key && this.props.lotId) {
      this.props.showSpinner(true)
      let res = await Api.deleteLotMedia(this.props.lotId, file.key)
      if (res) {
        this.props.saveMedia(this.props.media.filter(m => m.key !== file.key))
      }
      this.props.showSpinner(false)
    }
  }

  removePreloadedMedia (file) {
    this.props.showModal({
      message: `Remove ${/video/.test(file.mimeType) ? 'Video' : 'Photo'}?`,
      buttons: [
        {
          text: 'No'
        },
        {
          text: 'Yes',
          onPress: () => this.removeMediaFromState(file)
        }
      ]
    })
  }

  removeMediaFromState (file) {
    let index = this.props.media.indexOf(file)
    this.props.saveMedia(update(this.props.media, {
      $splice: [[index, 1]]
    }))
  }

  setMainLoadedMedia (file) {
    // this.props.showModal({
    //   message: `Are you sure you want to set this ${/video/.test(file.mimeType) ? 'video' : 'image'} as the main?`,
    //   buttons: [
    //     {
    //       text: 'Cancel'
    //     },
    //     {
    //       text: 'Yes',
    //       onPress: () => this.setMainMediaOnServer(file)
    //     }
    //   ]
    // })
    this.setMainMediaOnServer(file)
  }

  async setMainMediaOnServer (file) {
    if (file.key && this.props.lotId) {
      this.props.showSpinner(true)
      let res = await Api.putMediaMain(file.key, this.props.lotId)
      if (res) {
        this.props.saveMedia(
          this.props.media.map(media => {
            return update(media, {
              main: { $set: media.key === file.key }
            })
          })
        )
      }
      this.props.showSpinner(false)
    }
  }

  setMainPreloadedMedia (file) {
    // this.props.showModal({
    //   message: `Are you sure you want to set this ${/video/.test(file.mimeType) ? 'video' : 'image'} as the main?`,
    //   buttons: [
    //     {
    //       text: 'Cancel'
    //     },
    //     {
    //       text: 'Yes',
    //       onPress: () => this.setMainMediaOnState(file)
    //     }
    //   ]
    // })
    this.setMainMediaOnState(file)
  }

  setMainMediaOnState (file) {
    this.props.saveMedia(
      this.props.media.map(media => {
        return update(media, {
          main: { $set: media === file }
        })
      })
    )
  }

  reorder = (file, { direction }) => {
    let { media } = this.state
    let firstIndex = media.findIndex(el => {
      return el.order === file.order
    })
    let secondIndex = direction === 'left' ? firstIndex - 1 : firstIndex + 1
    if (secondIndex < 0 || secondIndex >= media.length) {
      return
    }
    this.swap(firstIndex, secondIndex)
  }

  renderPickerNode (file, { index, localMain = false, provided, snapshot } = {}) {
    switch (true) {
      case /image/.test(file.mimeType):
        return (
          <Draggable key={index} draggableId={`${file.order}`} index={index}>
            {(provided, snapshot) => (
              <div className='carousel-item' key={file.isNew ? index : file.key}
                ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                <span className='remove' onClick={() => file.isNew
                  ? this.removePreloadedMedia(file)
                  : this.removeLoadedMedia(file)} />
                <div className='order'>
                  <FontAwesomeIcon
                    className='control-icon'
                    onClick={() => { this.reorder(file, { direction: 'left' }) }}
                    icon={faChevronLeft}
                  />
                  <div className='text'>Move</div>
                  <FontAwesomeIcon
                    className='control-icon'
                    onClick={() => { this.reorder(file, { direction: 'right' }) }}
                    icon={faChevronRight}
                  />
                </div>
                <div className='set-main'>
                  <div className='inner-line'>
                    <Checkbox
                      type='green'
                      value={file.isNew ? file.main : file.main && !localMain}
                      onToggle={() => {
                        if (file.isNew) {
                          if (!file.main) {
                            this.setMainPreloadedMedia(file)
                          }
                        } else {
                          if (!file.main || localMain) {
                            this.setMainLoadedMedia(file)
                          }
                        }
                      }} />
                    <div className='set-main-text'>Set as main</div>
                  </div>
                </div>
                <img className='carousel-img' src={file.thumbnail} alt='' />
                {file.timestamp && <span className='date'>{moment(file.timestamp).format('DD/MM/YYYY')}</span>}
              </div>
            )}
          </Draggable>
        )
      case /video/.test(file.mimeType):
        let ref = React.createRef()
        return (
          <Draggable disableInteractiveElementBlocking key={index} draggableId={`${file.order}`} index={index}>
            {(provided, snapshot) => (
              <div className='carousel-item' key={file.isNew ? index : file.key} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                <span className='remove' onClick={() => file.isNew
                  ? this.removePreloadedMedia(file)
                  : this.removeLoadedMedia(file)} />
                <div className='order'>
                  <FontAwesomeIcon
                    className='control-icon'
                    onClick={() => { this.reorder(file, { direction: 'left' }) }}
                    icon={faChevronLeft}
                  />
                  <div className='text'>Move</div>
                  <FontAwesomeIcon
                    className='control-icon'
                    onClick={() => { this.reorder(file, { direction: 'right' }) }}
                    icon={faChevronRight}
                  />
                </div>
                <div className='set-main'>
                  <div className='inner-line'>
                    <Checkbox
                      type='green'
                      value={file.isNew ? file.main : file.main && !localMain}
                      onToggle={() => {
                        if (file.isNew) {
                          if (!file.main) {
                            this.setMainPreloadedMedia(file)
                          }
                        } else {
                          if (!file.main || localMain) {
                            this.setMainLoadedMedia(file)
                          }
                        }
                      }} />
                    <div className='set-main-text'>Set as main</div>
                  </div>
                </div>
                <video onLoadedMetadata={() => {
                  setTimeout(() => {
                    if(ref.current) {
                      ref.current.pause() // for ipad
                    }
                  }, 100)
                }} ref={ref} className='carousel-video' poster={file.isNew ? null : file.thumbnail} autoPlay muted controls playsinline>
                  <source src={file.url} type={file.type} />
                </video>
                {file.timestamp && <span className='date'>{moment(file.timestamp).format('DD/MM/YYYY')}</span>}
              </div>
            )}
          </Draggable>
        )
      default:
        throw new Error(`Unknown mime type: ${file.mimeType}`)
    }
  }

  getNodes (media, localMain, provided, snapshot) {
    return media.map((file, index) => this.renderPickerNode(file, { index, localMain, provided, snapshot }))
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    const { destination, source } = result
    if (destination && source) {
      this.dragSwap(source.index, destination.index)
    }
  }

  render () {
    const { disabled } = this.props
    const { media } = this.state
    const localMain = media.some(file => file.isNew && file.main)
    return (
      <div className={disabled ? 'input-section disabled' : 'input-section'}>
        <div className='section-body'>
          <Carousel lotForm>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <div
                style={{ flexBasis: 0 }}
              >
                <Droppable droppableId='droppable' direction='horizontal'>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {this.getNodes(media, localMain, provided, snapshot)}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </DragDropContext>
            <div className='carousel-item' key='file-input'>
              <label className='no-image'>
                {<input
                  className='file-input'
                  type='file'
                  multiple
                  ref={this.addMedia}
                  onClick={this.checkMediaLimit}
                  onChange={this.onMedia}
                  accept={`video/mp4, ${isSafari() ? 'video/quicktime' : ''}, image/jpeg, image/jpg, image/png`} />}
                Add photo/video
              </label>
            </div>
          </Carousel>
        </div>
        <CropImageModal
          closeModal={this.closeModal}
          saveImage={this.saveImage}
          isOpen={this.state.isOpen}
          preview={false}
          image={this.state.image}
          width={this.state.imgWidth}
          height={this.state.imgHeight}
        />
      </div>
    )
  }
}

MediaPicker.propTypes = {
  media: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  lotId: PropTypes.string
}

MediaPicker.defaultProps = {
  disabled: false
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ showSpinner, showModal }, dispatch)
}

export default connect(
  null,
  mapDispatchToProps
)(MediaPicker)
