import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { NUMBER_REGEXP, PIC_REGEXP_SOFT, LOT_HIDDEN_FIELDS, FORM_ABBREVIATIONS, SIRE_DAM_ABBREAVIATIONS } from '../../constants'
import { findFieldByPublicId } from '../../utils/FindFieldLot'
import { getAssessmentInfo } from '../../utils/Form'
import { momentTz } from '../../utils'
import {
  getSexesByKinds,
  getStockCategoriesBySex,
  getDistrictsByState
} from '../../utils/Lots'
import {
  BoolField,
  CheckboxField,
  DateField,
  DropdownField,
  EmailField,
  MultiDropdownField,
  NumberField,
  PhoneField,
  PICField,
  RatingField,
  RepeatForBool,
  RepeatForNumber,
  SelectField,
  TextAreaField,
  TextField,
  TradingNameField,
  UserField,
  WeightField,
  CurfewWeight,
  WeaningField
} from './types'

const isDropdownSearchable = (formElement) => {
  const searchableIds = ['majorBreed', 'district', 'sire', 'dam', 'vendorName', 'vendorPic']
  return searchableIds.includes(formElement.publicId)
}
const isDropdownSortable = (formElement) => {
  const sortableIds = ['majorBreed', 'district', 'sire', 'dam']
  return sortableIds.includes(formElement.publicId)
}
const isDropdownCustom = (formElement) => {
  const customIds = ['vendorName', 'vendorPic']
  return customIds.includes(formElement.publicId)
}

class FormField extends PureComponent {
  onChange = (value) => {
    // HERE handle special cases
    switch (true) {
      case this.props.formElement.publicId === 'postcode':
        if (NUMBER_REGEXP.test(value) || !value) {
          this.props.handleChange(value, this.props.path, { isRepeat: this.props.isRepeat })
        }
        break
      case this.props.formElement.publicId === 'town':
        value = value.toUpperCase()
        this.props.handleChange(value, this.props.path, { isRepeat: this.props.isRepeat })
        break
      case this.props.formElement.publicId === 'vendorPic':
        value = value.toUpperCase()
        if (PIC_REGEXP_SOFT.test(value) || !value) {
          this.props.handleChange(value, this.props.path, { isRepeat: this.props.isRepeat })
        }
        break
      default:
        this.props.handleChange(value, this.props.path, { isRepeat: this.props.isRepeat })
    }
  }

  commonProps () {
    // many fields use similar props
    return {
      title: this.props.formElement.title,
      value: this.props.formElement.value,
      invalid: this.props.formElement.invalid,
      isRequired: this.props.formElement.isRequired,
      onChange: this.onChange,
      noTab: this.props.isRepeat,
      isFloat:
      this.props.formElement.publicId === 'lowAge' ||
      this.props.formElement.publicId === 'highAge' ||
      this.props.formElement.title === 'Micron Tested (Micron)',
    }
  }

  generateAbbrForMultiSelectField (formElementName) {
    if (formElementName === 'Stock Category') {
      return FORM_ABBREVIATIONS
    } else {
      return SIRE_DAM_ABBREAVIATIONS
    }
  }

  dateLimits (formElement) {
    let limits
    let auctionDate = momentTz(new Date(this.props.auction.liveAt).getTime())
    switch (formElement.publicId) {
      case 'assessmentDate':
        limits = { maxDate: auctionDate }
        break
      case 'weaningDate':
        limits = { maxDate: moment() }
        break
      case 'earliestDelivery':
        let latestDelivery = findFieldByPublicId(this.props.row, 'latestDelivery')
        if (latestDelivery && latestDelivery.value) {
          let maxDate = moment(latestDelivery.value)
          limits = { minDate: auctionDate, maxDate: maxDate }
        } else {
          limits = { minDate: auctionDate }
        }
        break
      case 'latestDelivery':
        let minDate
        let earliestDelivery = findFieldByPublicId(this.props.row, 'earliestDelivery')
        if (earliestDelivery && earliestDelivery.value) {
          minDate = moment(earliestDelivery.value)
        } else minDate = auctionDate
        limits = { minDate }
        break
      default:
        limits = {}
    }
    if (limits.minDate) {
      limits.minDate = limits.minDate.format('YYYY-MM-DD')
    }
    if (limits.maxDate) {
      limits.maxDate = limits.maxDate.format('YYYY-MM-DD')
    }
    return limits
  }

  fieldKey (formElement) {
    /* this is a way to re-render both earliest-latest delivery dates
      when either of them change
      can be used to 'link' any other fields of same row in similar way */
    let key = 'default-key'
    switch (formElement.publicId) {
      case 'earliestDelivery':
        let latest = findFieldByPublicId(this.props.row, 'latestDelivery')
        if (latest) key = `earliest-${latest.value}`
        break
      case 'latestDelivery':
        let earliest = findFieldByPublicId(this.props.row, 'earliestDelivery')
        if (earliest) key = `latest-${earliest.value}`
        break
      default:
        break
    }
    return key
  }

  dropdownOptions (formElement) {
    let options
    switch (formElement.publicId) {
      case 'sex': {
        options = getSexesByKinds(this.props.kindType,{abbLambSale : this.props.auction.kindData.type === 'sheep-abblamb'})
        break
      }
      case 'stockCategory': {
        let sex = findFieldByPublicId(this.props.draftLot.details, 'sex')
        if (sex && sex.value) {
          options = getStockCategoriesBySex(this.props.kindType, sex.value,{abbLambSale : this.props.auction.kindData.type === 'sheep-abblamb'})
        }
        break
      }
      case 'state': {
        options = this.props.locationStates
        break
      }
      case 'district': {
        let state = findFieldByPublicId(this.props.draftLot.details, 'state')
        if (state && state.value) {
          options = getDistrictsByState(state.value)
        }
        break
      }
      case 'vendorPic': {
        options = this.props.userPics
          .map(item => {
            return item.pic
          })
          .filter(Boolean)
        break
      }
      case 'vendorName': {
        options = this.props.userPics
          .map(item => {
            return item.vendorName
          })
          .filter(Boolean)
        break
      }
      default:
        options = formElement.options
        break
    }
    options = options || []
    return options
  }

  assessmentInfo () {
    if (this.props.draftLot.optiweighAllowed) {
      return {
        avgWeight: this.props.draftLot.publicDetails.weight.average
      }
    }
    let assessmentGroup = this.props.draftLot.details.values.find(group => {
      return group.publicId === 'assessmentGroup'
    })
    return getAssessmentInfo(assessmentGroup, this.props.headsCount,this.props.auction.kindData.type === 'sheep-abblamb')
  }

  render () {
    const { formElement, isRowDisabled } = this.props
    // TODO client request - hide District field for now
    if (LOT_HIDDEN_FIELDS.includes(formElement.publicId)) {
      return null
    }
    if (formElement.publicId === 'haveWeaned') {
      return <WeaningField
        {...this.props}
        onChange={this.onChange}
      />
    }
    if (formElement.publicId === 'assessmentDate') {
      return (
        <React.Fragment>
          <DateField
            disabled={isRowDisabled || this.props.draftLot.optiweighAllowed}
            key={this.fieldKey(formElement)}
            title={formElement.title}
            value={formElement.value}
            invalid={formElement.invalid}
            isRequired={formElement.isRequired}
            onChange={this.onChange}
            {...this.dateLimits(formElement)}
            reoffered={this.props.draftLot.reoffered}
          />
          <CurfewWeight
            disabled={isRowDisabled}
            assessmentDate={formElement.value}
            isOptiweigh={this.props.draftLot.optiweighAllowed}
            earliestDeliveryDate={findFieldByPublicId(this.props.row, 'earliestDelivery').value}
            adjustWeight={this.props.draftLot.adjustWeight}
            weightGain={this.props.kindType === 'cattle' ? this.props.draftLot.weightGain : this.props.draftLot.weightGain * 1000}
            hoursOffFeed={this.props.kindType === 'sheep-abblamb' && findFieldByPublicId(this.props.draftLot.details, 'hoursoffeed') ? findFieldByPublicId(this.props.draftLot.details, 'hoursoffeed').value : ''}
            assessedData={this.assessmentInfo().avgWeight}
            onChange={this.props.onChangeDraftLot}
            kindType={this.props.kindType}
            auction = {this.props.auction}
            reoffered={this.props.draftLot.reoffered}
          />
        </React.Fragment>
      )
    }
    switch (formElement.type) {
      case 'text':
        return (
          <TextField
            disabled={isRowDisabled}
            {...this.commonProps()}
          />
        )
      case 'email':
        return (
          <EmailField
            disabled={isRowDisabled}
            {...this.commonProps()}
          />
        )
      case 'textarea':
        return (
          <TextAreaField
            disabled={isRowDisabled}
            {...this.commonProps()}
          />
        )
      case 'user':
        return (
          <UserField
            {...this.commonProps()}
          />
        )
      case 'weight':
        return (
          <WeightField
            moveToNext={this.props.moveToNext}
            slideIndex={this.props.slideIndex}
            activeSlideIndex={this.props.activeSlideIndex}
            {...this.commonProps()}
          />
        )
      case 'number':
        return (
          <NumberField
            {...this.commonProps()}
          />
        )
      case 'picNumber':
        return (
          <PICField
            {...this.commonProps()}
          />
        )
      case 'tradingName':
        return (
          <TradingNameField
            {...this.commonProps()}
          />
        )
      case 'phone':
        return (
          <PhoneField
            disabled={isRowDisabled}
            {...this.commonProps()}
          />
        )
      case 'rating':
        return (
          <RatingField
            title={formElement.title}
            value={formElement.value}
            invalid={formElement.invalid}
            isRequired={formElement.isRequired}
            onChange={this.onChange}
            kindType={this.props.kindType}
            isTabledFatScore={formElement.publicId === 'fatScore'}
          />
        )
      case 'checkbox':
        return (
          <CheckboxField
            title={formElement.title}
            value={formElement.value}
            isRequired={formElement.isRequired}
            onChange={this.onChange}
          />
        )
      case 'bool':
        if (formElement.publicId === 'withholding') {
          let drenchedDate = findFieldByPublicId(this.props.row, 'drenchedDate')
          return (
            <BoolField
              title={formElement.title}
              value={formElement.value}
              invalid={formElement.invalid}
              isRequired={formElement.isRequired}
              onChange={this.onChange}
              hidden={!moment().isSame(moment(drenchedDate.value), 'd')}
            />
          )
        } else {
          return (
            <BoolField
              title={formElement.title}
              value={formElement.value}
              invalid={formElement.invalid}
              isRequired={formElement.isRequired}
              onChange={this.onChange}
            />
          )
        }
      case 'dropdown':
        return (
          <DropdownField
            disabled={isRowDisabled}
            publicId={formElement.publicId}
            title={formElement.title}
            value={formElement.value}
            options={this.dropdownOptions(formElement)}
            invalid={formElement.invalid}
            isRequired={formElement.isRequired}
            isCustom={isDropdownCustom(formElement)}
            isSearchable={isDropdownSearchable(formElement)}
            isSortable={isDropdownSortable(formElement)}
            onChange={this.onChange}
          />
        )
      case 'multiDropdown':
        return (
          <MultiDropdownField
            disabled={isRowDisabled}
            publicId={formElement.publicId}
            title={formElement.title}
            abbreviations={this.generateAbbrForMultiSelectField(formElement.title)}
            value={formElement.value}
            options={this.dropdownOptions(formElement)}
            invalid={formElement.invalid}
            isRequired={formElement.isRequired}
            isSortable={isDropdownSortable(formElement)}
            onChange={this.onChange}
          />
        )
      case 'select':
        return (
          <SelectField
            publicId={formElement.publicId}
            title={formElement.title}
            value={formElement.value}
            defaultValue={formElement.defaultValue}
            options={formElement.options}
            invalid={formElement.invalid}
            isRequired={formElement.isRequired}
            onChange={this.onChange}
          />
        )
      case 'date':
        return (
          <DateField
            disabled={isRowDisabled}
            key={this.fieldKey(formElement)}
            title={formElement.title}
            value={formElement.value}
            invalid={formElement.invalid}
            isRequired={formElement.isRequired}
            onChange={this.onChange}
            {...this.dateLimits(formElement)}
            reoffered={this.props.draftLot.reoffered}
          />
        )
      case 'repeatForBool':
        return (
          <RepeatForBool
            {...this.props}
            onChange={this.onChange}
          />
        )
      case 'repeatForNumber':
        if (formElement.publicId !== 'individualAssessment') {
          return null
        }
        return (
          <RepeatForNumber
            {...this.props}
            assessmentInfo={this.assessmentInfo()}
            onChangeDraftLot={this.props.onChangeDraftLot}
            removeRFNElement={this.props.removeRFNElement}
          />
        )
      case 'hidden':
        return null
      default:
        throw new Error(`Unknown field type: ${formElement.type}`)
    }
  }
}

FormField.propTypes = {
  formElement: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  auction: PropTypes.object.isRequired,
  headsCount: PropTypes.number.isRequired,
  isRepeat: PropTypes.bool.isRequired,
  kindType: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  draftLot: PropTypes.object.isRequired,
  locationStates: PropTypes.array.isRequired,
  userPics: PropTypes.array.isRequired
}

FormField.defaultProps = {
  isRepeat: false
}

export default FormField
