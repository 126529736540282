import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { changeStateProp, filterStateProp, showModal, showSpinner, showSingleNotification, hideSingleNotification } from '../../../actions'
import MyReoffers from './MyReoffers'

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeStateProp, filterStateProp, showModal, showSpinner, showSingleNotification, hideSingleNotification }, dispatch)
}

export default connect(
  null,
  mapDispatchToProps
)(MyReoffers)
