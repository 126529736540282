import React, { PureComponent } from 'react'

import Api from '../../../services/api'
import Section from '../Section/Section'
import BackButton from '../../BackButton/BackButton'
//import { isSeller } from '../../../utils/UpdateData'

class Faqs extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      faqs: []
    }
  }

  componentDidMount () {
    window.scroll(0, 0)
    this.getData()
  }

  getData () {
    this.props.showSpinner(true)
    Api.getFaqs()
      .then(res => {
        if (res) {
          this.setState({
            faqs: this.convertData(res.faqs)
          })
        }
        this.props.showSpinner(false)
      })
  }

  convertData (data) {
    return data.map(item => {
      return {
        categoryName: item.categoryName,
        faqs: item.questions.map(item => {
          return { title: item.question, text: item.answer }
        }),
        forSeller: item.forSeller,
        forBuyer: item.forBuyer
      }
    })
  }

  render () {
    const { faqs } = this.state
    return (
      <div className='conditions'>
        <h1 className='fg-header'>Getting Started & FAQs</h1>
        <div className='top-btns'>
          <div className='left'>
            <BackButton onPress={() => {
              this.props.history.goBack()
            }} name='Back to Tools' />
          </div>
        </div>
        <div className='policy-container faqs-container'>
          {
            faqs.map((item, index) => {
              // if (isSeller() && item.forSeller) {
              //   return (
              //     <React.Fragment key={index}>
              //       <Section title={item.categoryName} text={item.faqs} accordion />
              //     </React.Fragment>
              //   )
              // } else if (!isSeller() && item.forBuyer) {
              //   return (
              //     <React.Fragment key={index}>
              //       <Section title={item.categoryName} text={item.faqs} accordion />
              //     </React.Fragment>)
              // } else {
              //   return (
              //     <span />
              //   )
              // }

              return (
                <React.Fragment key={index}>
                  <Section title={item.categoryName} text={item.faqs} accordion />
                </React.Fragment>
              )
            })
          }
        </div>
      </div>
    )
  }
}

export default Faqs
