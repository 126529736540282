import { WEANING_DETAILS_EXCEPTIONS } from '../constants'
import { findFieldByPublicId } from './FindFieldLot'

export function isRequiredWeaningDetails (lotDetails) {
  let stockCategory = findFieldByPublicId(lotDetails, 'stockCategory')
  let haveWeaned = findFieldByPublicId(lotDetails, 'haveWeaned')
  if (!stockCategory || !haveWeaned) {
    return false
  }
  if (haveWeaned.isRequired) {
    return true
  }
  if (Array.isArray(stockCategory.value) && stockCategory.value.find(element => WEANING_DETAILS_EXCEPTIONS.includes(element))) {
    return true
  } else {
    return false
  }
}

export function haveWeanedFieldIsNotValid (lotDetails) {
  let stockCategory = findFieldByPublicId(lotDetails, 'stockCategory')
  let haveWeaned = findFieldByPublicId(lotDetails, 'haveWeaned')
  if (!stockCategory || !haveWeaned) {
    return false
  }
  if (Array.isArray(stockCategory.value) && stockCategory.value.find(element => WEANING_DETAILS_EXCEPTIONS.includes(element)) && haveWeaned.value === null) {
    return true
  } else {
    return false
  }
}
