import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import RadioButton from '../../RadioButton/RadioButton'

const tableFatScoreCattle = [
  '0-2 mm',
  '3-6 mm',
  '7-12 mm',
  '13-22 mm',
  '23-32 mm'
]

const tableFatScoreSheep = [
  '0-5 mm',
  '6-10 mm',
  '11-15 mm',
  '16-20 mm',
  '21+ mm'
]

const tableFatScoreGoat = [
  '0-3 mm',
  '4-6 mm',
  '7-9 mm',
  '10-12 mm',
  '12+ mm'
]

class RatingField extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
    this.isTabledFatScore = this.props.isTabledFatScore
    this.options = [1, 2, 3, 4, 5]
    this.onChange = this.onChange.bind(this)
  }
  onChange (value) {
    this.props.onChange(value)
  }
  render () {
    const { invalid, isRequired, title, value, kindType } = this.props
    return (
      <div className='form-row'>
        <div className='row-title'>
          <p
            className={`row-text ${invalid ? 'row-text-invalid' : ''}`}>
            {title}{isRequired ? <span className='asterisk'>*</span> : ''}:
          </p>
        </div>
        <div className='row-content'>
          <div className='rating'>
            {this.isTabledFatScore ? this.options.map((item, index) => {
              let label
              switch (kindType) {
                case 'cattle':
                  label = tableFatScoreCattle[index]
                  break
                case 'sheep':
                  label = tableFatScoreSheep[index]
                  break
                case 'goat':
                  label = tableFatScoreGoat[index]
                  break
                default:
                  label = tableFatScoreCattle[index]
                  break
              }
              return (
                <RadioButton
                  key={index}
                  value={item}
                  label={label}
                  selected={value}
                  onSelection={this.onChange} />
              )
            }
            ) : null
            }
          </div>
        </div>
      </div>
    )
  }
}

RatingField.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  invalid: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isTabledFatScore: PropTypes.bool
}

RatingField.defaultProps = {
  invalid: false
}

export default RatingField
