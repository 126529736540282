import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faCaretDown
} from '@fortawesome/fontawesome-free-solid'

const DropdownIndicator = ({ innerRef, innerProps }) => (
  <div
    ref={innerRef}
    className='fg-dropdown__arrow'
    {...innerProps}
  >
    <FontAwesomeIcon icon={faCaretDown} size='lg' />
  </div>
)

class FgSelect extends PureComponent {
  handleWarning (warning) {
    if ((!this.props.validData && this.props.warningReason) || (this.props.valid && this.props.warningReason)) {
      if (this.props.dropdownField) {
        return (
          <div className='warning-text'>{warning}</div>
        )
      } else {
        return (
          <div className='warning-text'>* {warning}</div>
        )
      }
    }
  }

  setCaretPosition (input, pos) {
    if (input.setSelectionRange) {
      input.setSelectionRange(pos, pos)
    }
  }

  render () {
    const { warningReason, picState,statedetails } = this.props
    return (
      <div className={`fg-select-container  ${picState && 'pic-state'} ${statedetails && 'statedetails'}`}>
        <Select
          components={{ DropdownIndicator }}
          {...this.props}
          className={`fg-dropdown-container ${this.props.className || ''}`}
          classNamePrefix='fg-dropdown'
          warningReason={undefined}
          validData={undefined}
          ref='select'
          onFocus={() => {
            Array.from(document.getElementsByClassName('fg-dropdown__input')).forEach((div) => {
              if (document.activeElement === div.firstChild) {
                this.setCaretPosition(div.firstChild, div.firstChild.value.length)
              }
            })
          }}
        />
        {this.handleWarning(warningReason)}
      </div>
    )
  }
}

FgSelect.propTypes = {
  className: PropTypes.string,
  warningReason: PropTypes.string,
  validData: PropTypes.bool.isRequired
}

FgSelect.defaultProps = {
  validData: true
}

export default FgSelect
