import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class FgProgressBar extends PureComponent {
  render () {
    const { className, percentage } = this.props
    return (
      <div className={`fg-progress-bar ${className || ''}`}>
        <div className='fg-progress-bar-filler' style={{ width: `${percentage}%` }} />
        <span className='fg-progress-bar-percentage'>{percentage}%</span>
      </div>
    )
  }
}

FgProgressBar.propTypes = {
  className: PropTypes.string,
  percentage: PropTypes.number.isRequired
}

export default FgProgressBar
