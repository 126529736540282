import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import update from 'immutability-helper'
import memoize from 'memoize-one'
import { get as _get } from 'lodash'

import { changeStateProp } from '../../../actions/index'

import FormField from '../../FormField/FormField'
import { findField, findFieldByPublicId } from '../../../utils/FindFieldLot'
import { isRequiredWeaningDetails } from '../../../utils/WeaningDetails'

class FormRow extends Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.isAssessment = this.isAssessmentRow()
  }

  /*
     Set value to state for some formElement

     text - data which will set to formElement value field
     path - path to formElement object (from this.props.row)
     isRepeat - comes from repeatForNumber or not
     */
  changeLocalStateDetails = (text, path, { isRepeat = false } = {}) => {
    let formChanged
    let details = { ...this.props.row }
    let formElement = _get(details, path)
    if (!formElement) {
      console.error(`No form element found at provided path: ${path}`)
      return
    }
    if (formElement.value !== text) {
      formElement.markedChanged = true
      formChanged = true
    }
    formElement.value = text
    if (formElement.type === 'rating' && !formElement.value) {
      formElement.ratingChanged = true
    }
    if (formElement.type === 'repeatForNumber') {
      if (formElement.publicId === 'individualAssessment') {
        // this case is for importing data from csv
        formElement.values = text
        formElement.markedChanged = true
        formChanged = true
      }
    }
    if (isRepeat && formElement.type === 'rating') {
      // change next 'ratings' in repeatForNumber sequence
      let pathArr = path.split('.')
      let repeatValues = _get(details, pathArr.slice(0, pathArr.length - 2))
      let ratingFieldsPos = parseInt(pathArr[pathArr.length - 2], 10)
      let ratingElemPos = parseInt(pathArr[pathArr.length - 1], 10)
      for (let remainingIndex = ratingFieldsPos + 1; remainingIndex < repeatValues.length; remainingIndex++) {
        let remainingItem = repeatValues[remainingIndex][ratingElemPos]
        let weightValue = repeatValues[remainingIndex][ratingElemPos - 1].value
        if ((remainingItem.value !== text && !remainingItem.value) || !weightValue) {
          remainingItem.value = text
          remainingItem.markedChanged = true
          formChanged = true
        }
      }
    }
    if (formElement.publicId === 'sex') {
      let stockCategory = findFieldByPublicId(this.props.row, 'stockCategory')
      if (stockCategory) {
        stockCategory.value = []
        stockCategory.markedChanged = true
        formChanged = true
      }
    }
    if (formElement.publicId === 'state') {
      let district = findFieldByPublicId(this.props.row, 'district')
      if (district) {
        district.value = ''
        district.markedChanged = true
        formChanged = true
      }
    }
    if (formElement.publicId === 'vendorName' || formElement.publicId === 'vendorPic') {
      let vendorDetails
      if (formElement.value) {
        if (formElement.publicId === 'vendorPic') {
          vendorDetails = this.props.pics.find(item => item.pic === formElement.value)
        } else if (formElement.publicId === 'vendorName') {
          vendorDetails = this.props.pics.find(item => item.vendorName === formElement.value)
        }
      }
      if (vendorDetails) {
        if (formElement.publicId === 'vendorPic') {
          let vendorName = findFieldByPublicId(this.props.row, 'vendorName')
          if (vendorName) {
            vendorName.value = vendorDetails.vendorName
            vendorName.markedChanged = true
          }
        } else if (formElement.publicId === 'vendorName') {
          let vendorPic = findFieldByPublicId(this.props.row, 'vendorPic')
          if (vendorPic) {
            vendorPic.value = vendorDetails.pic
            vendorPic.markedChanged = true
          }
        }
        let vendorPhone = findFieldByPublicId(this.props.row, 'vendorPhone')
        if (vendorPhone) {
          vendorPhone.value = vendorDetails.vendorPhone
          vendorPhone.markedChanged = true
        }
        let vendorEmail = findFieldByPublicId(this.props.row, 'vendorEmail')
        if (vendorEmail) {
          vendorEmail.value = vendorDetails.vendorEmail
          vendorEmail.markedChanged = true
        }
        let vendorAddress = findFieldByPublicId(this.props.row, 'vendorAddress')
        if (vendorAddress) {
          vendorAddress.value = vendorDetails.address
          vendorAddress.markedChanged = true
        }
        let town = findFieldByPublicId(this.props.row, 'town')
        if (town) {
          town.value = vendorDetails.town
          town.markedChanged = true
        }
        let postcode = findFieldByPublicId(this.props.row, 'postcode')
        if (postcode) {
          postcode.value = vendorDetails.postcode
          postcode.markedChanged = true
        }
        let state = findFieldByPublicId(this.props.row, 'state')
        if (state) {
          state.value = vendorDetails.state
          state.markedChanged = true
        }
        let district = findFieldByPublicId(this.props.row, 'district')
        if (district) {
          district.value = vendorDetails.district
          district.markedChanged = true
        }
        formChanged = true
      }
    }
    this.props.updateDraftLot(update(this.props.draftLot, {
      details: { values: { [this.props.index]: { $set: details } } }
    }), { formChanged })
  }

  changeGlobalStateDetails = ({ formChanged } = {}) => {
    this.props.updateDraftLot(update(this.props.draftLot, {
      details: { values: { [this.props.index]: { $set: { ...this.props.row } } } }
    }), { formChanged })
  }

  // memoize wrapper
  hasRequired () {
    return this._hasRequired(this.props.draftLot.details)
  }

  _hasRequired = memoize(() => {
    let { row } = this.props
    let isRequired = Boolean(findField(row, el => el.isRequired, { ignoreClosedRFBool: true }))
    if (row.publicId === 'weaningGroup') {
      let { draftLot } = this.props
      isRequired = isRequired || isRequiredWeaningDetails(draftLot.details)
    }
    if(row.publicId === 'fatscoreGroup') {
      isRequired = true
    }
    return isRequired
  })

  isAssessmentRow () {
    return this.props.row.publicId === 'assessmentGroup'
  }

  isTemperamentRow () {
    return this.props.row.publicId === 'temperamentGroup'
  }

  checkGoatWithoutWeight = () => {
    const {
      draftLot,
      kindType,
      rememberedLotsWithIndex
    } = this.props
    const isGoatWithoutWeight = kindType === 'goat' && draftLot.countWeighed === 0

    if (isGoatWithoutWeight) {
      let newRememberedLotsWithIndex = rememberedLotsWithIndex.lots.filter(element => element.id !== draftLot._id)
      if (newRememberedLotsWithIndex.length !== rememberedLotsWithIndex.lots.length) {
        this.props.changeStateProp('rememberedLotsWithIndex', { lots: newRememberedLotsWithIndex }, 'main')
      }
    }

    return isGoatWithoutWeight
  };

  render () {
    const {
      auction,
      draftLot,
      kindType,
      row,
      mode,
      locationStates,
      userPics
    } = this.props
    const reofferMode = mode === 'reoffer'
    const editLateMode = mode === 'edit-late'
    const headsCount = ((kindType === 'sheep' && auction.kindData.type !== 'sheep-abblamb') || kindType === 'goat') ? draftLot.countWeighed : draftLot.count
    let isRowDisabled = false
    if (reofferMode && !this.isAssessment) {
      isRowDisabled = true
    }
    if (editLateMode) {
      isRowDisabled = true
    }
    const isAssessmentGroup = row.publicId === 'assessmentGroup'
    const isOptiweigh = kindType === 'cattle' && draftLot.optiweighAllowed
    const isGoatWithoutWeight = this.checkGoatWithoutWeight()

    if (!(isAssessmentGroup && (isOptiweigh || isGoatWithoutWeight))) {
      return (
        <div className={`input-section ${(isRowDisabled && row.title !== 'Delivery') ? 'disabled' : ''}`}>
          <div className='section-heading'>
            <div className='heading-title'>
              {row.title}
              {(this.hasRequired() || (this.isTemperamentRow() && !isOptiweigh)) && <span className='asterisk'>*</span>}
            </div>
          </div>
          <div className={this.isAssessment ? 'section-body-blank' : 'section-body'}>
            {row.values.map((formElement, index) => {
              return (
                <FormField
                  isRowDisabled={isRowDisabled}
                  key={index}
                  draftLot={draftLot}
                  formElement={formElement}
                  index={index}
                  mode={mode}
                  path={`values.${index}`}
                  handleChange={this.changeLocalStateDetails}
                  onChangeDraftLot={this.props.onChangeDraftLot}
                  headsCount={headsCount || 1}
                  auction={auction}
                  row={row}
                  kindType={kindType}
                  locationStates={locationStates}
                  userPics={userPics}
                  removeRFNElement={this.props.removeRFNElement}
                />
              )
            }
            )}
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}

FormRow.propTypes = {
  index: PropTypes.number.isRequired,
  draftLot: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  auction: PropTypes.object.isRequired,
  updateDraftLot: PropTypes.func.isRequired,
  kindType: PropTypes.string.isRequired,
  mode: PropTypes.string.isRequired,
  locationStates: PropTypes.array.isRequired,
  userPics: PropTypes.array.isRequired
}

const mapStateToProps = (state) => {
  return {
    locationStates: state.data.locationStates,
    userPics: state.user.pics,
    rememberedLotsWithIndex: state.main.rememberedLotsWithIndex
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeStateProp }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormRow)
