export const subTitles = {
  UPCOMING_AUCTIONS: 'Australia wide Cattle, Sheep and Goat auctions. Filter by stock type, view the Catalogue, enter Live Auctions or easily add your Lot for sale by clicking the Add Lot button.',
  UPCOMING_AUCTIONS_BUYERS: 'Australia wide Cattle, Sheep and Goat auctions. Filter by stock type, enter Live Auctions and download, email or print the Catalogue from your iPhone, iPad and desktop.',
  WATCH_LIST: 'Click the blue tags on Lots you are interested in bidding on to add them to your Watch List and track them easily in the Catalogue and Live Auction.',
  UPCOMING_RESULTS: 'Australia wide Cattle, Sheep and Goat auction results. View sold, passed in or no bid Lots from all past auctions.',
  MY_ADMIN: 'Keep track of your submitted lots, edit saved lots, view your personal sale results, save your favourite PICs, reoffer passed in or no bid listings and more.',
  MY_ADMIN_BUYERS: 'View your personal sale results and keep your profile up to date.',
  CONTACT_US: 'We’re here to help whenever you need.',
  SUBMITTED_LOTS: 'View your pending and submitted Lots for Upcoming Auctions. Filter the date range to find past Lots submitted and copy the information into a new Lot for sale.',
  SAVED_LOTS: 'Finish editing Lots you have saved and submit them to Auction.',
  MY_AUCTION_RESULTS: 'A quick and easy way to view your personal Auction Results. Filter by sold, passed in, no bid and purchased Lots.',
  MY_AUCTION_RESULTS_BUYERS: 'A quick and easy way to view the Lots you have purchased.',
  MY_REOFFERS: 'Reoffer passed in or no bid Lots without charge.',
  MY_PROFILE: 'Keep your business details up to date. Upload your company logo to help your Lots stand out in the Catalogue.',
  MY_PROFILE_BUYERS: 'Keep your business details up to date and add additional PIC numbers.',
  MY_FAVOURITE_PICS: 'Save the details of PIC numbers you regularly use and simply select them when filling out the Assessment Form. This information will auto-populate.',
  TOOLS: 'Print Cattle & Sheep Assessment Forms to fill in on property, check out our Getting Started, FAQs and How-To Videos & Tips to best use the platform.',
  BUYER_TOOLS: 'Check out our Getting Started, FAQs and How-To Videos & Tips to best use the platform.',
  UPLOAD_CSV: `Choose the fields you would like to import into the Assessment Form. The individual weight and fat score fields are required fields. When finished click Upload.`,
  SOUNDS: 'Control your Live Auction sounds',
  INTEREST_OF_SALE: 'We are still building interest in our auctions. When an auction is listed we will notify you via email. If you have livestock you would like to sell with us, please click the button below and we will work with you to build a sale.'
}

export const apiAlerts = {
  NO_CONNECTION_TITLE: 'No Internet Connection',
  FARMGATE_TITLE: 'FarmGate',
  ERROR_TITLE: 'Error',
  CONNECTION_ERROR: 'Something is wrong. Please connect and try again.',
  CONNECTION_ERROR_SUPPORT: 'Something is wrong. Please try again or contact Support on (02) 8123542 to place bids on your behalf.',
  SUBMIT_ERROR: 'Error occurred while trying to submit your data. Please check your internet connection and try again',
  SOMETHING_WRONG_ERROR: 'Something is wrong ',
  WRONG_PASSWORD_ERROR: 'Wrong Password. Password you entered is not valid',
  SECURITY_CODE_ERROR_TITLE: 'Wrong Security Code',
  SECURITY_CODE_ERROR: 'Security Code you entered is not valid, please check it and try again',
  EMAIL_ERROR_TITLE: 'Wrong Email',
  EMAIL_ERROR: 'Email you entered does not belong to any account, please check it and try again',
  NOT_FOUND_ERROR: 'Not Found. Server error. Please try again later',
  BAD_REQUEST_ERROR: 'Bad request. Please try again later',
  FAILED_LOGIN_CRED_ERROR_TITLE: 'Failed Login',
  FAILED_LOGIN_CRED_ERROR: 'Failed Login: Incorrect credentials',
  FAILED_LOGIN_NETWORK_ERROR_TITLE: 'Failed Login',
  FAILED_LOGIN_NETWORK_ERROR: 'Failed Login: Something is wrong'
}

export const validators = {
  INCLUDE_AREA_CODE: 'If entering a landline please include the area code',
  ENTER_CONTACT_NUMBER: 'Please Enter Contact Number',
  CORRECT_MOBILE_NUMBER: 'Mobile numbers should be 10 digits and start with 04XX XXX XXX',
  RELATIONSHIP: 'Please Enter Relationship',
  INCORRECT_RELATIONSHIP: 'Please Enter valid Relationship',
  POSTCODE: 'Please Enter Postcode',
  INCORRECT_POSTCODE: 'Sorry, invalid Postcode',
  TOWN: 'Please Enter Town',
  INCORRECT_TOWN: 'Sorry, invalid Town',
  INCORRECT_WEBSITE: 'Sorry, invalid Website URL',
  TRADING_NAME: 'Please Enter Trading Name',
  INCORRECT_TRADING_NAME: 'Please Enter valid Trading Name',
  PIC: 'Please enter PIC',
  TRADING_TYPE: 'Please Enter Trading Type',
  INCORRECT_TRADING_TYPE: 'Please Enter valid Trading Type',
  INCORRECT_PIC: 'Please enter valid PIC number',
  ABN: 'Please Enter ABN',
  STOCK: 'Please select at least one stock type before submitting',
  INCORRECT_ABN: 'Sorry, invalid ABN',
  ACN: 'Please Enter ACN',
  INCORRECT_ACN: 'Sorry, invalid ACN',
  FIRST_NAME: 'Please Enter First Name',
  INCORRECT_FIRST_NAME: 'Sorry, invalid First Name',
  AGENT_NAME: 'Please Enter Agent Name',
  REGISTERED_FOR_GST: 'Please select registered for GST or not ',
  INCORRECT_AGENT_NAME: 'Sorry, invalid Agent Name',
  LAST_NAME: 'Please Enter Last Name',
  INCORRECT_LAST_NAME: 'Sorry, invalid Last Name',
  COMPANY_NAME: 'Please Enter Company Name',
  INCORRECT_COMPANY_NAME: 'Sorry, invalid Company Name',
  GIVEN_NAME: 'Please Enter Given Name',
  INCORRECT_GIVEN_NAME: 'Sorry, invalid Given Name',
  LEGAL_ENTITY_NAME: 'Please Enter Legal entity Name',
  INCORRECT_LEGAL_ENTITY_NAME: 'Sorry, invalid Legal entity Name',
  EMAIL: 'Please Enter Email',
  INCORRECT_EMAIL: 'Email is incorrect please check and enter again',
  PASSWORD: 'Please Enter Password',
  USEROPERATIONTYPE : 'Please Select Operation Type',
  STOCK_TYPES: 'Please Select Stock Types',
  INCORRECT_PASSWORD: 'Please Enter Password min. 6 characters long, can include letters and numbers',
  REPASSWORD: 'Please Confirm Password',
  INCORRECT_REPASSWORD: 'Passwords must match',
  ADDRESS: 'Please Enter Address',
  INCORRECT_ADDRESS: 'Sorry, invalid Address',
  LICENSE: 'Please Enter License/Permit',
  INCORRECT_LICENSE: 'Sorry, invalid License/Permit',
  YEARS_HELD: 'Please Enter No. years held',
  INCORRECT_YEARS_HELD: 'Sorry, invalid No. years held',
  YEARS_INDUSTRY: 'Please Enter No. years in Industry',
  INCORRECT_YEARS_INDUSTRY: 'Sorry, invalid No. years in Industry',
  STATE: 'Please Select State',
  DISTRICT: 'Please Enter District',
  JOB_TITLE: 'Please Enter Job Title',
  ACCOUNT_NAME: 'Please Enter Account Name',
  INCORRECT_ACCOUNT_NAME: 'Sorry, invalid Account Name',
  ACCOUNT_NUMBER: 'Please Enter Account Number',
  INCORRECT_ACCOUNT_NUMBER: 'Sorry, invalid Account Number',
  BSB: 'Please Enter Bank State Branch',
  INCORRECT_BSB: 'Please Enter 6 Digit BSB',
  OTHER_OPTION_TYPE: 'Please Enter Other Type',
  INCORRECT_OTHER_OPTION_TYPE: 'Sorry, invalid Other Type'
}

export const restrictAccessMessages = {
  GUEST_RESTIRCT_MESSAGE: 'Please register to buy & sell for access',
  BUYER_RESTRICT_MESSAGE: 'Please register to sell for access',
  ALREADY_HAVE_BUY_SELL_ACCESS: 'You already have access to buy and sell',
  ALREADY_HAVE_BUY_ACCESS: 'You already have access to buy',
  ALREADY_REQUESTED_ACCESS: 'You have already requested access. Please allow 24 hours for this request to be processed by our team'
}