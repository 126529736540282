import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import AuctionLive from './AuctionLive'
import { auctionByNumber } from '../../selectors/auctionsSelector'
import { auctionPublicLots } from '../../selectors/lotsSelector'
import {
  changeStateProp,
  mergeStateProp,
  filterStateProp,
  showSpinner,
  showModal,
  showSlowInternetConnection,
  hideSlowInternetConnection
} from '../../actions'

const mapStateToProps = (state, ownProps) => {
  let auction = auctionByNumber({
    auctionNumber: ownProps.match.params.auctionNumber,
    auctions: state.data.auctions
  })
  let lots = auctionPublicLots({
    auction: auction,
    lots: state.data.lots
  })
  return {
    auctions: state.data.auctions,
    user: state.user,
    auction,
    lots,
    bidSound: state.sound.bidSound,
    outBidSound: state.sound.outBidSound,
    wonSound: state.sound.wonSound,
    autoBidSound: state.sound.autoBidSound,
    countdownSound: state.sound.countdownSound,
    watchButtonLoading: state.temp.watchButtonLoading,
    isServerLive: state.temp.isServerLive
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    changeStateProp, mergeStateProp, filterStateProp, showSpinner, showModal, showSlowInternetConnection, hideSlowInternetConnection
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuctionLive)
