import React from 'react'
import PropTypes from 'prop-types'
import DownloadResultBtn from '../DownloadResultBtn/DownloadResultBtn'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/fontawesome-free-solid'

class DownloadCatalogueBtn extends DownloadResultBtn {
  handleLotsFullDetail = () => {
    const lotCount = this.props.auctionLotCount
    const stepCount = 40
    const url = this.props.fullDetailsUrl

    function openPdf (step) {
      window.open(`${url}?step=${step}`)
      if (lotCount >= stepCount * (step + 1)) {
        setTimeout(() => { openPdf(++step) }, 1000)
      }
    }

    if (lotCount > stepCount) {
      openPdf(0)
    } else {
      window.open(`${this.props.fullDetailsUrl}`)
    }
  }

  renderItems () {
    return (
      <div>
        <a className='item' href={this.props.summaryUrl}>
          <span>Summary Catalogue</span>
          <span className='icon-wrapper icon-danger icon-md'><FontAwesomeIcon icon={faFilePdf} /></span>
        </a>
        {/* eslint-disable-next-line */}
        <a href='#' className='item' onClick={this.handleLotsFullDetail}>
          <span>Lots in Full Detail</span>
          <span className='icon-wrapper icon-danger icon-md'><FontAwesomeIcon icon={faFilePdf} /></span>
        </a>
      </div>
    )
  }
}

DownloadCatalogueBtn.defaultProps = {
  name: 'Download Catalogue',
  summaryUrl: '/',
  fullDetailsUrl: '/'
}

DownloadCatalogueBtn.propTypes = {
  name: PropTypes.string,
  summaryUrl: PropTypes.string,
  fullDetailsUrl: PropTypes.string
}

export default DownloadCatalogueBtn
