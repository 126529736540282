import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import RenderLots from './RenderLots'

const mapStateToProps = (state) => {
  return {
    autobids: state.user.autobids,
    isSound: state.temp.isSound,
    isSlowInternetSpeed: state.temp.isSlowInternetSpeed
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RenderLots)
