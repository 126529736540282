import React, { PureComponent } from 'react'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faPrint,
  faArrowDown
} from '@fortawesome/fontawesome-free-solid'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'

import EmailModalFormContainer from '../../Modals/EmailModalFormContainer'
import Api from '../../../services/api'
import { BASE_URL } from '../../../config'
import BackButton from '../../BackButton/BackButton'

class Manuals extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      manuals: [],
      manualId: '',
      assessmentForms: []
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.renderManual = this.renderManual.bind(this)
  }

  componentDidMount () {
    if (this.props.history.action !== 'POP') {
      window.scroll(0, 0)
    }
    this.getManuals()
    // this.getAssessmentForms()
  }

  async getManuals () {
    this.props.showSpinner(true)
    let res = await Api.getManuals()
    if (res) {
      this.setState({
        manuals: res.data
      })
    }
    this.props.showSpinner(false)
  }

  // async getAssessmentForms () {
  //   let res = await Api.getAssessmentForms()
  //   if (res) {
  //     this.setState({
  //       assessmentForms: res.data
  //     })
  //   }
  // }

  openModal (manual) {
    this.setState({
      modalIsOpen: true,
      manualId: manual._id
    })
  }

  closeModal () {
    this.setState({
      modalIsOpen: false
    })
  }

  renderManual (manual, key) {
    return (
      <div className='section' key={key}>
        <div className='title'>
          { manual.name }
        </div>
        <div className='body'>
          <span className='explanation'>{ manual.description }</span>
          <div className='buttons'>
            <span className='btn' onClick={() => this.openModal(manual)}>
              <span>Email Form</span>
              <span className='icon-wrapper icon-success'><FontAwesomeIcon icon={faEnvelope} /></span>
            </span>
            { manual.document &&
              <a className='btn' href={`${BASE_URL}download-file/${manual.document.key}?fileName=${manual.name}.pdf`} >
                <span>Download Form</span>
                <span className='icon-wrapper icon-warning'><FontAwesomeIcon icon={faArrowDown} /></span>
              </a>
            }
            { manual.document &&
              <a className='btn' href={manual.document.url} target='_blank' rel='noopener noreferrer'>
                <span>Print Form</span>
                <span className='icon-wrapper icon-success'><FontAwesomeIcon icon={faPrint} /></span>
              </a>
            }
          </div>
        </div>
      </div>
    )
  }

  renderAssessmentForm (form) {
    return (
      <div className='section' key={form._id}>
        <div className='title'>{form.name}</div>
        <div className='body'>
          <div className='section-left'>
            <a className='download' href={`${BASE_URL}download-file/${form.document.key}?fileName=${form.name}.pdf`}>
              <span className='icon-wrapper' />
              <span>Download Form</span>
            </a>
          </div>
          <div className='section-right'>
            <a className='download' href={form.document.url} target='_blank' rel='noopener noreferrer'>
              <span className='icon-wrapper' />
              <span>Print Form</span>
            </a>
          </div>
        </div>
      </div>
    )
  }

  render () {
    return (
      <div className='manuals'>
        <h1 className='fg-header'>Forms & Manuals</h1>
        <div className='top-btns'>
          <div className='left'>
            <BackButton onPress={() => {
              this.props.history.goBack()
            }} name='Back to Tools' />
          </div>
        </div>
        <div className='manuals-container'>
          <EmailModalFormContainer
            openModal={this.openModal}
            closeModal={this.closeModal}
            afterOpenModal={this.afterOpenModal}
            isOpen={this.state.modalIsOpen}
            manualId={this.state.manualId}
          />
          {/* <div className='assessment-forms'>
            { isSeller()
              ? assessmentForms.map(form => {
                return this.renderAssessmentForm(form)
              })
              : null
            }
          </div> */}
          { this.state.manuals.map(this.renderManual) }
        </div>
      </div>
    )
  }
}

export default Manuals
