import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { FLOAT_REGEXP, NUMBER_REGEXP } from '../../../constants'

class NumberField extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      value: props.value
    }
    this.onChange = this.onChange.bind(this)
    this.input = React.createRef()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value
      })
    }
  }

  onChange () {
    let { value } = this.input.current
    if (value[value.length - 1] === '.') {
      this.setState({ value: value.replaceAll('.', '').concat('.') })
      return
    }
    if (this.props.isFloat ? (FLOAT_REGEXP.test(value) || NUMBER_REGEXP.test(value)) : NUMBER_REGEXP.test(value) || !value) {
      this.props.onChange(value)
      this.setState({ value: value })
    }
  }
  render () {
    const { invalid, isRequired, noTab, title } = this.props
    const { value } = this.state
    return (
      <div className='form-row'>
        <div className='row-title'>
          <p
            className={`row-text ${invalid ? 'row-text-invalid' : ''}`}>
            {title}{isRequired ? <span className='asterisk'>*</span> : ''}:
          </p>
        </div>
        <div className='row-content'>
          <input
            ref={this.input}
            className='input'
            type='text'
            value={value ? value.toString() : value}
            onChange={this.onChange}
            {...noTab ? { tabIndex: -1 } : {}}
          />
        </div>
      </div>
    )
  }
}

NumberField.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  invalid: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  noTab: PropTypes.bool.isRequired,
  isFloat: PropTypes.bool
}

NumberField.defaultProps = {
  invalid: false,
  noTab: false,
  isFloat: false
}

export default NumberField
