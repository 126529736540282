import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import { NavLink, Redirect } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import memoize from 'memoize-one'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faCaretDown,
  faTimes,
  faArrowRight,
  faSearch,
  faFilter,
  faUser,
  faBalanceScale
} from '@fortawesome/fontawesome-free-solid'
import { faCompass, faCalendarAlt } from '@fortawesome/free-regular-svg-icons'

import FilterKind from '../../FilterKind/FilterKind'
import FilterMultiSelect from '../../FilterMultiSelect/FilterMultiSelect'
import FilterRange from '../../FilterRange/FilterRange'
import FilterRangeAge from '../../FilterRangeAge/FilterRangeAge'

import cowImage from '../../../assets/img/cowauction.png'
import lambImage from '../../../assets/img/lambauction.png'
import goatImage from '../../../assets/img/goat-image.png'
import bookImage from '../../../assets/img/book.png'

import Api from '../../../services/api'
import ServerTime from '../../../services/ServerTime'
import ImageCarousel from '../../ImageCarousel/ImageCarousel'
import WatchButton from '../../WatchButton/WatchButtonContainer'
import LotItemWithdrawn from '../../LotItemWithdrawn/LotItemWithdrawn'
import { limitTextLength, momentTz } from '../../../utils'
import { getKindOptions } from '../../../utils/FindFieldKind'
import { filterLots, sortLots } from '../../../utils/Filter'
import { getSexesByKinds, getStockCategoriesByKinds } from '../../../utils/Lots'
import * as Socket from '../../../services/socket'
import InfiniteScroll from 'react-infinite-scroll-component'
import { WanderingCubes } from 'better-react-spinkit'
import { getLotNumber } from '../../../utils/Auctions'
import LotBottomInfo from '../../LotBottomInfo/LotBottomInfo'
import { subTitles } from '../../../constants/strings'
// import {
//   firstDiffrentBoxTitle,
//   firstDiffrentBoxValue,
//   secondDiffrentBoxTitle,
//   secondDiffrentBoxValue
// } from '../../../utils/lotInfoBoxes'

class WatchlistInfo extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      redirectAgentCommentUrl: '',
      filters: {
        screen: this.getScreen(),
        showMode: this.getSavedFilterValue('showMode', 'all'),
        breed: this.getSavedFilterValue('breed', []),
        stockCategory: this.getSavedFilterValue('stockCategory', []),
        sex: this.getSavedFilterValue('sex', []),
        age: this.getSavedFilterValue('age', []),
        weight: this.getSavedFilterValue('weight', []),
        location: this.getSavedFilterValue('location', [])
      },
      page: 1,
      limit: 50,
      hasMore: true,
      fetching: false,
      sortType: 'liveAt',
      sortDirection: -1,
      requestFinish: true
    }
    this.cancelSource = axios.CancelToken.source()
    this.getDisplayData = memoize(this.getDisplayData)
    this.selectOptions = memoize(this.selectOptions)
    this.rangeOptions = memoize(this.rangeOptions)
    this.filterChangeHandler = this.filterChangeHandler.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
    this.changeShowMode = this.changeShowMode.bind(this)
    this.getData = this.getData.bind(this)
  }

  componentDidMount () {
    if (this.props.history.action !== 'POP') {
      window.scroll(0, 0)
    }
    this.getData()
    Socket.attach(Socket.BIDS_NAMESPACE, 'lot-update', 'watchList', this.getData)
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.filters !== prevState.filters) {
      this.props.mergeStateProp('filters', [this.state.filters], 'user')
    }
  }

  componentWillUnmount () {
    this.cancelSource.cancel()
    Socket.detach(Socket.BIDS_NAMESPACE, 'lot-update', 'watchList')
  }

  getScreen () {
    return 'watchList'
  }

  getSavedFilterValue (key, defaultValue = []) {
    let screen = this.getScreen()
    let thisFilter = this.props.filters.find(filterSet => filterSet.screen === screen)
    let value
    if (thisFilter) {
      value = thisFilter[key]
    }
    return value || defaultValue
  }

  async getData () {
    const { page, limit, sortType, sortDirection, requestFinish } = this.state
    if (requestFinish) {
      this.setState({ requestFinish: false })
      this.props.showSpinner(true)
      let data = await Api.getLotsWatchlist(
        page,
        limit,
        sortType,
        sortDirection,
        { cancelToken: this.cancelSource.token }
      )

      let newLots = data.lots
      let lots = this.props.lots.length ? this.props.lots.concat(newLots) : newLots
      const { headers } = data
      let currentPage = headers ? headers['x-current-page'] : 1
      let totalPages = headers ? headers['x-total-pages'] : 1
      if (data && !data.cancel) {
        this.setState({
          page: this.state.page + 1,
          hasMore: parseInt(currentPage, 10) < totalPages,
          fetching: false,
          requestFinish: true
        })
        this.props.mergeStateProp('lots', lots, 'data')
      }
      this.props.showSpinner(false)
    }
  }

  selectOptions (kinds, showMode, locationStates) {
    let kindsArr = showMode === 'all' ? kinds : kinds.filter(kind => kind.type === showMode)
    return {
      breed: getKindOptions(kindsArr, 'majorBreed', { sort: true }),
      stockCategory: getStockCategoriesByKinds(kindsArr.map(kind => kind.type), { sort: true }),
      sex: getSexesByKinds(kindsArr.map(kind => kind.type), { sort: true }),
      location: locationStates
    }
  }

  rangeOptions (lots) {
    // find min/max values only in approved lots (draft lots can have crazy values)
    lots = lots.filter(lot => lot.approved)
    let ages = lots.map(lot => lot.publicDetails.age.max).filter(Boolean)
    let weights = lots.map(lot => lot.publicDetails.weight.average).filter(Boolean)
    let ageMax = Math.round(Math.max(...ages))
    let weightMax = Math.round(Math.max(...weights))
    return {
      age: {
        min: 0,
        max: isFinite(ageMax) ? Math.max(ageMax, 0) : 0
      },
      weight: {
        min: 0,
        max: isFinite(weightMax) ? Math.max(weightMax, 0) : 0
      }
    }
  }

  getDisplayData (auctions, lots, filters) {
    /* returns an object with structure
      [{
        auctionId: string,
        auctionState: string,
        auctionData: {...},
        kindData: {...},
        lots: [lotObj, lotObj, ....]
      }] */
    let watchedLots = lots
      .filter(lot => {
        return filters.showMode === 'all'
          ? true
          : lot.kindData.type === filters.showMode
      })
    watchedLots = filterLots(watchedLots, filters).filter((lot) => {
      return lot.state !== 'closed' && lot.status !== 'withdrawn'
    })
    let returnObj = watchedLots.reduce((obj, lot) => {
      let thisSection = obj.find(section => section.auctionId === lot.auction)
      if (!thisSection) {
        let storeAuction = auctions.find(auction => auction._id === lot.auction)
        obj.push({
          auctionId: lot.auction,
          auctionState: storeAuction ? storeAuction.state : 'closed',
          showLotNumbers: storeAuction ? storeAuction.showLotNumbers : true,
          auctionData: lot.auctionData,
          kindData: lot.kindData
        })
      }
      return obj
    }, [])
    // hide closed auctions in watchlist
    // returnObj = returnObj.filter(section => section.auctionState !== 'closed')
    // sort auctions by date
    returnObj.sort((firstItem, secondItem) => {
      let v1 = firstItem.auctionData.liveAt
      let v2 = secondItem.auctionData.liveAt
      return v1 < v2 ? -1 : v1 > v2 ? 1 : 0
    })
    // sort lots in each section by number
    returnObj.forEach(section => {
      let auctionLots = watchedLots
        .filter(lot => lot.auction === section.auctionId)
      section.lots = sortLots(auctionLots, 'Lot Number', 'down')
    })
    return returnObj
  }

  getLotLocation (lot) {
    let addresses = []
    let possibleLocations = ['town', 'state']
    possibleLocations.forEach(loc => {
      let str = lot.publicDetails.location[loc]
      if (str) {
        addresses.push(str)
      }
    })
    return addresses.join(', ')
  }

  goToAuctionLink (kindData, auctionNumber, auctionState) {
    let section = auctionState === 'closed' ? 'results' : 'upcoming'
    return `/main/${section}/${kindData.title}/${auctionNumber}?from=watchList`
  }

  goToLotLink (lot, auctionState) {
    let section = auctionState === 'closed' ? 'results' : 'upcoming'
    return `/main/${section}/${lot.kindData.title}/${lot.auctionData.searchNumber}/${lot.searchNumericId}?from=watchList`
  }

  openLive (auctionNumber) {
    window.open(`#/auction-live/${auctionNumber}`)
  }

  isAuctionInTwoWeeks (liveAt) {
    return moment(ServerTime.getTime()).add(10, 'd').isAfter(liveAt)
  }

  showButton (auctionId) {
    const auction = this.props.auctions.find(item => item._id === auctionId)
    if (auction) {
      if (auction.viewDetailsDays || auction.viewDetailsDays === 0) {
        return moment(ServerTime.getTime()).add(auction.viewDetailsDays, 'd').isAfter(auction.liveAt)
      } else {
        return moment(ServerTime.getTime()).add(10, 'd').isAfter(auction.liveAt)
      }
    } else {
      return true
    }
  }

  changeShowMode (showMode) {
    this.setState(update(this.state, {
      filters: {
        showMode: { $set: showMode }
      }
    }))
  }

  clearFilter () {
    this.setState(update(this.state, {
      filters: {
        showMode: { $set: 'all' },
        breed: { $set: [] },
        stockCategory: { $set: [] },
        sex: { $set: [] },
        age: { $set: [] },
        weight: { $set: [] },
        location: { $set: [] }
      }
    }))
  }

  filterChangeHandler (filterName, value) {
    this.setState({
      filters: update(this.state.filters, {
        [filterName]: { $set: value }
      })
    })
  }

  render () {
    const { filters, hasMore } = this.state
    const { breed, stockCategory, sex, age, weight, location, showMode } = filters
    const { auctions } = this.props
    const displayData = this.getDisplayData(
      this.props.auctions,
      this.props.lots,
      filters
    )
    const selectOptions = this.selectOptions(this.props.kinds, showMode, this.props.locationStates)
    const ranges = this.rangeOptions(this.props.lots)
    return (
      <div className='watchlist-wrapper'>
        {this.state.redirectAgentCommentUrl ? <Redirect
          to={{
            pathname: this.state.redirectAgentCommentUrl,
            state: { scroll: 10000 }
          }}
        /> : ''}
        <h1 className='fg-header'>Watch List</h1>
        <p className='fg-paragraph'>{subTitles.WATCH_LIST}</p>
        <div className='watchlist-controls'>
          <h3 className='filters-heading'>Filters</h3>
          <div className='watchlist-filters-wrapper'>
            <div>
              <FilterKind categoryChoice={showMode} changeCategoryChoice={this.changeShowMode} />
            </div>
            <div>
              <FilterMultiSelect
                title='Breed'
                name='breed'
                options={selectOptions.breed}
                selectedOptions={breed}
                onChange={this.filterChangeHandler}
                icon={<FontAwesomeIcon icon={faFilter} />}
              />
              <FilterMultiSelect
                title='Sex'
                name='sex'
                options={selectOptions.sex}
                selectedOptions={sex}
                onChange={this.filterChangeHandler}
                icon={<FontAwesomeIcon icon={faCaretDown} size='lg' />}
              />
              <FilterMultiSelect
                title='Stock Category'
                name='stockCategory'
                options={selectOptions.stockCategory}
                selectedOptions={stockCategory}
                onChange={this.filterChangeHandler}
                icon={<FontAwesomeIcon icon={faCaretDown} size='lg' />}
              />
              <FilterRangeAge
                title='Age'
                name='age'
                unitY='Years'
                unitM='Months'
                description='Please type your minimum and maximum age in Months or Years or use slide below.'
                belowDescription='Please note: the filters are set from zero to the highest age available in the Catalogue.'
                min={ranges.age.min}
                max={ranges.age.max}
                value={age}
                onChange={this.filterChangeHandler}
                icon={<FontAwesomeIcon icon={faCalendarAlt} />}
              />
              <FilterRange
                title='Weight'
                name='weight'
                unit='Kg'
                description='Please type your minimum and maximum weight or use slide below.'
                belowDescription='Please note: the filters are set from zero to the highest weight available in the Catalogue.'
                min={ranges.weight.min}
                max={ranges.weight.max}
                value={weight}
                onChange={this.filterChangeHandler}
                icon={<FontAwesomeIcon icon={faBalanceScale} />}
              />
              <FilterMultiSelect
                title='Location'
                name='location'
                options={selectOptions.location}
                selectedOptions={location}
                onChange={this.filterChangeHandler}
                icon={<FontAwesomeIcon icon={faCompass} />}
              />
              <div
                className='btn filter-btn filter-btn-sm clear-all-btn'
                onClick={this.clearFilter}
              >
                <span>Clear All</span>
                <span className='icon-wrapper icon-danger'><FontAwesomeIcon icon={faTimes} /></span>
              </div>
            </div>
          </div>
        </div>
        <div className='watch-info'>
          <InfiniteScroll
            next={this.getData}
            hasMore={hasMore}
            scrollThreshold={0.8}
            dataLength={auctions.length}
            style={{ overflow: 'initial' }}>
            <React.Fragment>
              {displayData.map(({ auctionId, auctionState, showLotNumbers, auctionData, kindData, lots }) => {
                return (
                  <div className='watch-auction' key={auctionId}>
                    <div className='watch-auction-info-top'>
                      <div>
                        <div className='watch-auction-kind'>
                          {kindData.type === 'sheep' && <img src={lambImage} alt='Sheep icon' />}
                          {kindData.type === 'cattle' && <img src={cowImage} alt='Cattle icon' className='cattle-icon' />}
                          {kindData.type === 'goat' && <img src={goatImage} alt='Goat icon' className='goat-icon inverted' />}
                        </div>
                        <div className='watch-auction-info'>
                          <h3>{auctionData.title}</h3>
                          <p className='auction-live-date'>{momentTz(auctionData.liveAt).format('dddd D MMMM YYYY - h.mm A')}</p>
                        </div>
                      </div>
                      <div className='info-btns'>
                        {
                          (auctionState === 'open' || auctionState === 'live') &&
                          <div
                            className='btn watch-enter-btn'
                            onClick={() => this.openLive(auctionData.searchNumber)}
                          >
                            <span>Enter Auction</span>
                            <span className='icon-wrapper icon-warning'><FontAwesomeIcon icon={faArrowRight} /></span>
                          </div>
                        }
                        <NavLink className='view-catalog' to={this.goToAuctionLink(kindData, auctionData.searchNumber, auctionState)}>
                          <div className='btn'>
                            <span>{auctionState === 'closed' ? 'View Results' : 'View Catalogue'}</span>
                            <span className='icon-wrapper icon-warning'><img src={bookImage} alt='Book icon' /></span>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                    <div className='watch-lots'>
                      {lots.map(lot => {
                        if (lot.withdrawn) {
                          return (
                            <LotItemWithdrawn lot={lot} key={lot._id} auctionState={auctionState} />
                          )
                        }
                        return (
                          <div className='lot-item' key={lot._id}>
                            <div className='lot-img-container'>
                              <ImageCarousel
                                lot={lot}
                                lotId={lot._id}
                                media={lot.media}
                                kindType={kindData.type}
                                preview />
                            </div>
                            <div className='lot-info'>
                              <div className='lot-info-top'>
                                <p className='lot-number'>
                                  { getLotNumber({ state: auctionState, showLotNumbers }, lot) }
                                  {lot.createdBy === this.props.userId &&
                                  (
                                    <span className='my-listing'>
                                    -
                                      <span className='icon-wrapper'>
                                        <FontAwesomeIcon icon={faUser} />
                                      </span>
                                      <span className='my-listing-text'>
                                      My Listing
                                      </span>
                                    </span>
                                  )
                                  }
                                </p>
                                <p className='lot-location'>{this.getLotLocation(lot)}</p>
                              </div>
                              <div className='lot-info-middle'>
                                <div className='left'>
                                  <p className='lot-title'>{lot.publicDetails.title} {lot.publicDetails.summary.age} {lot.additionalTitle}</p>
                                  <p className='lot-comments'>{`${limitTextLength(lot.publicDetails.agentComment, 420, false)} `}
                                    <span className='lot-readMore' onClick={() => {
                                      this.setState({
                                        redirectAgentCommentUrl: this.goToLotLink(lot)
                                      })
                                    }}>
                                      {lot.publicDetails.agentComment.length > 420 || lot.publicDetails.agentComment.split(/\r\n|\r|\n/g).length >= 4 ? '...Read More' : ''}
                                    </span>
                                  </p>
                                </div>
                                <div className='info-btns'>
                                  <WatchButton lotId={lot._id} bigButton />
                                  {
                                    this.showButton(auctionId) && (
                                      <NavLink className='btn' to={this.goToLotLink(lot, auctionState)}>
                                        <span>View Details</span>
                                        <span className='icon-wrapper icon-warning'><FontAwesomeIcon icon={faSearch} /></span>
                                      </NavLink>
                                    )
                                  }
                                </div>
                              </div>
                              <div className='lot-info-bottom'>
                                <div className='info-wrap'>
                                  <LotBottomInfo lot={lot} mode={this.state.listType} />
                                </div>
                                <div className='company-logo-wrapper'>
                                  {
                                    lot.creatorData.logo &&
                                    <img src={lot.creatorData.logo.url} alt='Company logo' />
                                  }
                                </div>
                              </div>
                            </div>
                          </div>)
                      })}
                    </div>
                  </div>
                )
              })}
              {this.state.fetching && <div className={`scroll-loader-wrap ${this.state.fetching ? 'active' : ''}`}>
                <WanderingCubes className='scroll-loader' color={'gray'} />
              </div>}
            </React.Fragment>
          </InfiniteScroll>
          {
            displayData.length
              ? null
              : (
                <div className='no-data-placeholder'>
                  <div className='message'>
                    You are not currently watching any Lots.
                  </div>
                </div>
              )
          }
        </div>
      </div>
    )
  }
}

WatchlistInfo.propTypes = {
  kinds: PropTypes.array.isRequired,
  auctions: PropTypes.array.isRequired,
  lots: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
  mergeStateProp: PropTypes.func.isRequired,
  showSpinner: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

WatchlistInfo.defaultProps = {}

export default WatchlistInfo
