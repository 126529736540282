/* global Blob, URL */
import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faPrint,
  faArrowDown,
  faUser,
  faPlusSquare
} from '@fortawesome/fontawesome-free-solid'
import moment from 'moment'

import ImageCarousel from '../../ImageCarousel/ImageCarousel'
import DetailedInfo from '../../DetailedInfo/DetailedInfoContainer'
import LotDocuments from '../../LotDocuments/LotDocuments'
import EmailModalLot from '../../Modals/EmailModalLotContainer'
import BackButton from '../../BackButton/BackButton'
import WatchButton from '../../WatchButton/WatchButtonContainer'
import Api from '../../../services/api'
import {
  momentTz,
  parseQueryString
} from '../../../utils'
import { BASE_URL } from '../../../config'
import SocialButtons from '../../SocialButtons/SocialButtons'
import { changeOrClearMetaTags } from '../../../utils/Lots'
import LotDetailsClosedStatus from '../../LotDetails/LotDetailsClosedStatus'
import { Calculator } from '../../Calculator/Calculator'
import { getLotNumber } from '../../../utils/Auctions'
import './LotDetails.scss'

const allowedFieldsForExport = ['weightLive', 'fatScore', 'animalIndividualId']
const csvDelimiter = ','
class LotDetails extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      auction: null,
      lot: null,
      modalIsOpen: false,
      lotForCsv: null
    }
    this.cancelSource = axios.CancelToken.source()

    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.exportWeights = this.exportWeights.bind(this)
    this.from = parseQueryString(this.props.location.search).from
    this.allFields = false
    this.mode = 'full-preview'
    this.exportLink = React.createRef()
    this.downloadButton = React.createRef()
    this.printLotDetailsButton = React.createRef()
  }
  componentDidMount () {
    changeOrClearMetaTags()
    this.getData()
    this.getLotForCsv()
    window.addEventListener('resize', () => {
      this.checkBackButtonWidth()
    })
  }
  componentDidUpdate (prevProps, prevState) {
    if ((this.props.match.params.auctionNumber !== prevProps.match.params.auctionNumber) ||
      (this.props.match.params.lotNumericId !== prevProps.match.params.lotNumericId)) {
      this.cancelSource.cancel()
      this.cancelSource = axios.CancelToken.source()
      this.getData()
    } else if (this.props.match.params.kindTitle !== prevProps.match.params.kindTitle) {
      this.checkTitle()
    }
    this.checkBackButtonWidth()
  }
  componentWillUnmount () {
    this.cancelSource.cancel()
    changeOrClearMetaTags(true)
    if (this.props.history.action === 'PUSH') {
      return
    } else if(this.props.history.action === 'POP') { 
     //this.props.history.replace(`/main/manage/my-submitted`)
    // this.props.history.goBack()
    }
  }
  checkTitle () {
    let kindTitle = this.state.auction.kindData.title
    if (kindTitle.toLowerCase() !== this.props.match.params.kindTitle.toLowerCase()) {
      // if wrong kindTitle
      this.props.history.replace(`/main/upcoming/${kindTitle}/${this.props.match.params.auctionNumber}/${this.props.match.params.lotNumericId}`)
    }
  }
  getLotForCsv () {
    Api.getLot(
      this.props.match.params.lotNumericId,
      {
        mode: 'full'
      }
    ).then((lot) => {
      this.setState({
        lotForCsv: lot.lot
      })
    })
  }
  getData () {
    this.props.showSpinner(true)
    Api.getLot(
      this.props.match.params.lotNumericId,
      {
        cancelToken: this.cancelSource.token,
        mode: this.mode,
        trackView: true
      }
    ).then((lot) => {
      Api.getAuction(lot.lot.auctionData.searchNumber, { cancelToken: this.cancelSource.token })
        .then((auction) => {
          this.props.showSpinner(false)
          this.setState({
            auction: auction.auction,
            lot: lot.lot
          }, () => {
            if (this.props.location.state && this.props.location.state.scroll) {
              window.scroll(0, this.props.location.state.scroll)
            } else {
              if (this.props.history.action !== 'POP') {
                window.scroll(0, 0)
              }
            }
            if (this.state.lot.draft) {
            // check if a lot is in draft, leave page if it is
              this.props.history.replace(`/main/upcoming/${this.props.match.params.kindTitle}/${this.props.match.params.auctionNumber}`)
            } else {
              this.checkTitle()
            }
          })
        })
    })
  }

  getLotLocation () {
    let addresses = []
    let possibleLocations = ['town', 'state']
    possibleLocations.forEach(loc => {
      let str = this.state.lot.publicDetails.location[loc]
      if (str) {
        addresses.push(str)
      }
    })
    return addresses.join(', ')
  }
  goBackName () {
    if (this.from) {
      if (this.from === 'watchList') {
        return 'Back to Watch List'
      }
    }
    return 'Back to Catalogue'
  }
  openModal () {
    this.setState({
      modalIsOpen: true
    })
  }
  closeModal () {
    this.setState({
      modalIsOpen: false
    })
  }
  pdfUrl () {
    return `${BASE_URL}print/lots/${this.state.lot.searchNumericId}/full-details?mode=print`
  }
  downloadReportUrl () {
    return `${BASE_URL}print/lots/${this.state.lot.searchNumericId}/full-details`
  }
  exportWeights () {
    let data = this.state.lotForCsv.details.values[1].values[0].values
      .map(array => {
        return array
          .filter(field => allowedFieldsForExport.includes(field.publicId))
          .map(field => field.value)
      })
    if (data.length === 0) {
      return
    }
    data.unshift(this.state.lotForCsv.details.values[1].values[0].values[0]
      .filter(field => allowedFieldsForExport.includes(field.publicId))
      .map(field => field.title) // add titles
    )
    const dataStr = data
      .map(row => row.join(csvDelimiter))
      .reduce((content, row) => content + '\r\n' + row)
    let downloadUrl = URL.createObjectURL(new Blob([dataStr], { type: 'text/csv' }))
    let fileName = `${this.state.lotForCsv.description || 'No Description'} - weights ${moment().format('dddd DD MMM YYYY')}.csv`
    this.exportLink.current.setAttribute('download', fileName)
    this.exportLink.current.setAttribute('href', downloadUrl)
    this.exportLink.current.click()
  }
  checkBackButtonWidth () {
    let downloadButton = this.downloadButton.current
    let backButton = document.getElementsByClassName('back-btn')[0]
    let printLotDetailsButton = this.printLotDetailsButton.current
    if (window.innerWidth > 900) {
      if (downloadButton && backButton) {
        downloadButton.style.width = `${backButton.clientWidth + 2.2}px`
      }
    } else if (window.innerWidth < 900 && window.innerWidth > 728) {
      if (downloadButton && printLotDetailsButton) {
        downloadButton.style.width = `${printLotDetailsButton.clientWidth + 2.2}px`
      }
    } else {
      if (downloadButton && backButton) {
        downloadButton.style.width = ''
      }
    }
  }

  averageWeightText (lot) {
    if (lot.optiweighAllowed) {
      return lot.weightGain !== 0 ? `${lot.weightGain.toFixed(2)}kg avdwtg` : '-'
    } else {
      return lot.publicDetails.weight.average !== 0 ? `${Math.round(lot.publicDetails.weight.average)}kg ass/wt` : '-'
    }
  }

  render () {
    const { auction, lot } = this.state
    if (!auction || !lot) {
      return <div />
    }
    const isShowShareButtons = this.props.match.url.includes('manage')
    const shareMessage = `Lot - ${lot.publicDetails.title} ${
      lot.publicDetails.weight.curfew
    }kg ${lot.adjustWeight === 0 && lot.optiweighAllowed ? 'Live/wt' : 'Cur/wt'} ${lot.publicDetails.location.town} ${
      lot.publicDetails.location.state
    }`
    let host = window.location.host.split('.')[0]
    let isTestWP = (host === 'webapp-ekreative' || host === 'test-webapp') ? 'test-wp.' : ''
    const shareUrl = `https://${isTestWP}farmgateauctions.com.au/upcoming-auctions/?${lot.kindData.title}/${
      lot.auctionData.searchNumber
    }/${lot.searchNumericId}`
    return (
      <div className='lot-wrapper'>
        <EmailModalLot
          openModal={this.openModal}
          closeModal={this.closeModal}
          isOpen={this.state.modalIsOpen}
          lot={lot}
          owner={this.allFields}
        />
        <div className='lot-menu'>
          <div className='left'>
            <p className='fg-header watch-header'>{this.state.auction.title}</p>
            <p className='fg-secondary-header'>Auction No. {this.state.auction.number}</p>
            <p className='auction-live-date'>{momentTz(this.state.auction.liveAt).format('dddd D MMMM YYYY - h.mm A')}</p>
            <p className='fg-paragraph'>{this.state.auction.description}</p>
          </div>
          <div className='right'>
            {!isShowShareButtons && <SocialButtons shareMessage={shareMessage} shareUrl={shareUrl} />}
          </div>
        </div>
        <div className='top-btns results results-flex'>
          <Fragment>
            <BackButton onPress={() => {
              if (this.props.history.action === 'PUSH') {
                this.props.history.goBack()
              } else {
                this.props.history.replace(`/main/upcoming/${this.props.match.params.kindTitle}/${this.props.match.params.auctionNumber}`)
              }
            }} name={this.goBackName()} />
            <span className='btn btn-flex' onClick={this.openModal}>
              <span>
                <span className='btn-title'>Email</span> <span className='btn-title'>Lot Details</span>
              </span>
              <span className='icon-wrapper icon-success'><FontAwesomeIcon icon={faEnvelope} /></span>
            </span>
            <a className='btn btn-flex' href={this.downloadReportUrl()}>
              <span>
                <span className='btn-title'>Download</span> <span className='btn-title'>Lot Details</span>
              </span>
              <span className='icon-wrapper icon-warning'><FontAwesomeIcon icon={faArrowDown} /></span>
            </a>
            <a className='btn btn-flex' href={this.pdfUrl()} target='_blank' ref={this.printLotDetailsButton} rel='noopener noreferrer'>
              <span>
                <span className='btn-title'>Print</span> <span className='btn-title'>Lot Details</span>
              </span>
              <span className='icon-wrapper icon-success'><FontAwesomeIcon icon={faPrint} /></span>
            </a>
            {
              lot.approved
                ? <WatchButton lotId={lot._id} bigButton lotDetails />
                : null
            }
          </Fragment>
        </div>
        {this.pageSubmitted && !lot.optiweighAllowed ? <div className='top-btns results results-flex'>
          <Fragment>
            <div ref={this.downloadButton} className='btn btn-download-file' onClick={this.exportWeights}>
              <span>Download Weight & Fat Score</span>
              <a href={false} className='hidden' download='weights.csv' ref={this.exportLink}>Export weights</a>
              <span className='icon-wrapper icon-success'><FontAwesomeIcon icon={faPlusSquare} /></span>
            </div>
          </Fragment>
        </div> : ''}
        <hr className='separator' />
        <div className='lot-info-wrapper'>
          <div className={`left-side ${auction.showCalculator ? 'showCalculator' : ''}`}>
            <div className='lot-info'>
              <div className='lot-info-top'>
                <div className='title'>
                  <p>
                    {getLotNumber(auction, lot)}
                    { lot.createdBy === this.props.userId &&
                    <Fragment>
                      &nbsp;-&nbsp;
                      <span className='icon-wrapper'>
                        <FontAwesomeIcon icon={faUser} />
                      </span>
                      <span> My Listing</span>
                    </Fragment>
                    }
                  </p>
                </div>
                <div className='location'>
                  <p className='lot-location'>{this.getLotLocation()}</p>
                </div>
              </div>
              {lot.state !== 'closed' ? <div className='lot-title-wrapper'>
                <p className='lot-title'>{`${lot.publicDetails.title} - ${lot.publicDetails.summary.age} - ${lot.additionalTitle}`}</p>
                <div className='company-logo-wrapper'>
                  {
                    lot.creatorData.logo &&
                    <img src={lot.creatorData.logo.url} alt='Company logo' />
                  }
                </div>
              </div> : <div>
                <LotDetailsClosedStatus lot={lot} />
              </div>}
              {lot.usefulLinks && lot.usefulLinks.length
                ? <div id='useful-links-details' className='lots-controls'>
                  <h3 className='filters-heading'>Useful links:</h3>
                  <div className='lots-links-wrapper'>
                    {lot.usefulLinks.map(link => {
                      if (link.url) {
                        return (
                          <a target='_blank' rel='noopener noreferrer' href={link.url} className='link'>{link.description || link.url}</a>
                        )
                      }
                      return null
                    })}
                  </div>
                </div> : null }
              <div className='lot-title-weights'>
                <p className='title-weight'>{this.averageWeightText(lot)}</p>
                <p className='title-weight curfew'>{`${lot.publicDetails.weight.curfew !== 0 ? `${Math.round(lot.publicDetails.weight.curfew)}kg ${lot.adjustWeight === 0 && lot.optiweighAllowed ? 'live/wt' : 'cur/wt'}` : '-'}`}</p>
              </div>
              {lot.inlineDetails.assessorType &&
                <div className='lot-title-weights'>
                  <p className='title-weight assessor-type'>{lot.inlineDetails.assessorType}</p>
                </div>
              }
              <ImageCarousel showVideoIcon lot={lot} lotId={lot._id} media={lot.media} />
              <LotDocuments documents={lot.documents} />
              <DetailedInfo lot={lot} allFields={this.allFields} />
            </div>
          </div>
          {auction.showCalculator && <Calculator />}
        </div>
      </div>
    )
  }
}

LotDetails.propTypes = {
  auction: PropTypes.object,
  showSpinner: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

LotDetails.defaultProps = {
}

export default LotDetails
