import { connect } from 'react-redux'
import SignIn from './SignIn'
import { bindActionCreators } from 'redux'

import { changeStateProp, showSpinner, showModal } from '../../../actions/index'

const mapStateToProps = (state) => {
  return {
    redirectUrl: state.user.redirectUrl
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    changeStateProp,
    showSpinner,
    showModal
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn)
