/* global FileReader */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import update from 'immutability-helper'
import { bindActionCreators } from 'redux'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/fontawesome-free-solid'

import { limitCharacters } from '../../../utils'
import { compressImage } from '../../../utils/Lots'
import Api from '../../../services/api'
import { showSpinner } from '../../../actions'

class DocPicker extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
    this.MAX_DOCUMENT_LIMIT = 6
    this.addDocument = this.addDocument.bind(this)
  }

  componentDidMount () {
    if (!this.props.documents.length) {
      this.generateSingleRow()
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (!this.props.documents.length) {
      this.generateSingleRow()
    }
  }

  getNextOrder () {
    let { documents } = this.props
    let maxOrder = Math.max(...documents.map(doc => doc.order))
    return maxOrder >= 0 ? maxOrder + 1 : 0
  }

  addDocument (event, document) {
    let file = event.target.files[0]
    if (!file) {
      return
    }
    let { documents } = this.props
    let newDocument = {}
    event.target.value = null

    let fileReader = new FileReader()
    fileReader.onloadend = () => {
      newDocument.file = file
      newDocument.mimeType = file.type
      newDocument.uri = fileReader.result
      newDocument.name = file.name
      if (!file.type) {
        let arrayName = file.name.split('.')
        let ext = arrayName[arrayName.length - 1]
        switch (ext) {
          case 'xls':
            newDocument.mimeType = 'application/vnd.ms-excel'
            break
          case 'doc':
            newDocument.mimeType = 'application/msword'
            break
          case 'docx':
            newDocument.mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            break
          case 'xlsx':
            newDocument.mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            break
          default:
            newDocument.mimeType = ''
            break
        }
      }
      if (/image/.test(file.type)) {
        let options = {
          convertSize: 1000,
          quality: 0.7,
          mimeType: 'image/jpeg'
        }
        compressImage(newDocument, options)
          .then(newDocument => {
            this.props.saveDocuments(update(documents, {
              [documents.indexOf(document)]: { $merge: newDocument }
            }))
          })
          .catch(err => {
            console.error(err)
          })
      } else {
        this.props.saveDocuments(update(documents, {
          [documents.indexOf(document)]: { $merge: newDocument }
        }))
      }
    }
    fileReader.readAsDataURL(file)
  }

  generateSingleRow () {
    let { documents } = this.props
    if (documents.length < this.MAX_DOCUMENT_LIMIT) {
      this.props.saveDocuments(update(documents, {
        $push: [{
          uri: '',
          name: '',
          mimeType: '',
          description: '',
          order: this.getNextOrder(),
          isNew: true
        }]
      }))
    }
  }

  removeSingleRow (document) {
    if (document.isNew) {
      let { documents } = this.props
      let index = documents.indexOf(document)
      this.props.saveDocuments(update(documents, {
        $splice: [[index, 1]]
      }))
    } else {
      this.removeDocumentFromServer(document)
    }
  }

  async removeDocumentFromServer (document) {
    if (document.key && this.props.lotId) {
      this.props.showSpinner(true)
      let res = await Api.deleteLotDocument(this.props.lotId, document.key)
      if (res) {
        this.props.saveDocuments(this.props.documents.filter(doc => doc.key !== document.key))
      }
      this.props.showSpinner(false)
    }
  }

  async changeDesc ({ target: { value } }, document) {
    const { documents, saveDocuments, showSpinner, lotId } = this.props
    if (document.isNew) {
      // local
      value = value.trim()
      saveDocuments(update(documents, {
        [documents.indexOf(document)]: { description: { $set: value } }
      }))
    } else {
      // server
      if (!lotId) {
        return
      }
      value = value.trim()
      if (document.description === value) {
        return
      }
      let data = { description: value }
      showSpinner(true)
      let res = await Api.editLotDocument(lotId, document.key, data)
      showSpinner(false)
      if (res) {
        saveDocuments(update(documents, {
          [documents.indexOf(document)]: { description: { $set: value } }
        }))
      }
    }
  }

  render () {
    const { documents, disabled } = this.props
    return (
      <div className={`input-section input-section-documents ${disabled ? 'disabled' : ''}`}>
        <div className='section-heading'>
          <div className='heading-title'>Add Documents</div>
        </div>
        <div className='section-body'>
             <div className='row-title'>
                <p
                  className='row-text'>Information: You may wish to upload documents including but not limited to vet certificates, bloodline information or a copy of EID’s in listing.
                </p>
              </div>
          {documents.map((document, index) => (
            <div className='form-row' key={index}>
              <div className='row-title'>
                <p
                  className='row-text'>Select Document:</p>
              </div>
              <div className='row-content'>
                <label className='file-label documents-text-wrapper'>
                  {
                    document.name
                      ? limitCharacters(document.name)
                      : 'Add New File'
                  }
                  {
                    document.isNew
                      ? (
                        <input
                          id='file'
                          className='file-input'
                          type='file'
                          onChange={(event) => this.addDocument(event, document)}
                          accept='.jpg,.jpeg,.pdf,.png,.doc,.docx,.xls,.xlsx'
                        />
                      )
                      : null
                  }
                </label>
                <input
                  key={`${document.name}${index}`}
                  className='input documents-description documents-text-wrapper'
                  placeholder='Description'
                  type='text'
                  defaultValue={document.description || ''}
                  onBlur={(e) => this.changeDesc(e, document)}
                />
                <span className='doc-btn doc-btn-add' onClick={() => this.generateSingleRow()}>
                  <FontAwesomeIcon icon={faPlus} />
                </span>
                <span className='doc-btn doc-btn-remove' onClick={() => this.removeSingleRow(document)}>
                  <FontAwesomeIcon icon={faMinus} />
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

DocPicker.propTypes = {
  saveDocuments: PropTypes.func.isRequired,
  documents: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  lotId: PropTypes.string
}

DocPicker.defaultProps = {
  disabled: false
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ showSpinner }, dispatch)
}

export default connect(
  null,
  mapDispatchToProps
)(DocPicker)
