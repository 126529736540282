import { createSelector } from 'reselect'

const kinds = (state) => state.kinds
const kindTitle = (state) => state.kindTitle
const kindId = (state) => state.kindId

export const kindByTitle = createSelector(
  kindTitle, kinds,
  (kindTitle, kinds) => {
    return kinds.find(kind => kind.title.toLowerCase() === kindTitle.toLowerCase())
  }
)

export const kindById = createSelector(
  kindId, kinds,
  (kindId, kinds) => {
    return kinds.find(kind => kind._id === kindId)
  }
)
