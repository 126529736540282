import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import { Circle } from 'better-react-spinkit'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faBookmark } from '@fortawesome/fontawesome-free-solid'

import Api from '../../services/api'

class WatchButton extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      active: this._isWatched()
    }
    this.toggle = this.toggle.bind(this)
  }

  componentDidMount () {
    this.updateState()
    this.checkQueryParamsFromWordPressToWatch()
  }

  componentDidUpdate () {
    if (this.state.active !== this._isWatched()) {
      this.updateState()
    }
  }

  checkQueryParamsFromWordPressToWatch () {
    if (document.location.hash.match('lot-watch-id=')) {
      let lotId = document.location.hash.split('lot-watch-id=')[1]
      let { watchList } = this.props.userData
      let index = watchList.indexOf(lotId)

      if (index !== -1) {
        return
      }
      watchList = update(watchList, {
        $push: [lotId]
      })
      this.props.changeStateProp('data', update(this.props.userData, {
        watchList: { $set: watchList }
      }), 'user')

      clearTimeout(WatchButton.globalRequestTimeout)
      WatchButton.globalRequestTimeout = setTimeout(async () => {
        let res = await Api.putUserWatchList(watchList)
        if (res && !res.isError && res.watchList) {
          this.props.changeStateProp('data', update(this.props.userData, {
            watchList: { $set: res.watchList }
          }), 'user')
        }
      }, WatchButton.globalRequestThrottleMs)
    }
  }

  updateState () {
    this.setState({
      active: this._isWatched()
    }, () => {
      this.props.onChange(this.state.active)
    })
  }

  async toggle (e) {
    if (!this.props.bigButton) {
      e.stopPropagation()
    }
    let { watchList2 } = this.props.userData
    let index = watchList2.map(item => item.lotId).indexOf(this.props.lotId)
    if (this.state.active) {
      if (index === -1) {
        return
      }
      this.props.changeStateProp('watchButtonLoading', true, 'temp')

      clearTimeout(WatchButton.globalRequestTimeout)
      WatchButton.globalRequestTimeout = setTimeout(async () => {
        let res = await Api.deleteUserWatchList(this.props.lotId)
        if (res && !res.isError && res.watchList) {
          this.props.changeStateProp('data', update(this.props.userData, {
            watchList2: { $set: res.watchList }
          }), 'user')
        }
        this.props.changeStateProp('watchButtonLoading', false, 'temp')
      }, WatchButton.globalRequestThrottleMs)
    } else {
      if (index !== -1) {
        return
      }
      this.props.changeStateProp('watchButtonLoading', true, 'temp')

      clearTimeout(WatchButton.globalRequestTimeout)
      WatchButton.globalRequestTimeout = setTimeout(async () => {
        let res = await Api.putUserWatchList(this.props.lotId)
        if (res && !res.isError && res.watchList) {
          this.props.changeStateProp('data', update(this.props.userData, {
            watchList2: { $set: res.watchList }
          }), 'user')
        }
        this.props.changeStateProp('watchButtonLoading', false, 'temp')
      }, WatchButton.globalRequestThrottleMs)
    }
  }

  _isWatched () {
    if (!this.props.userData) {
      return false
    } else if (this.props.userData && this.props.userData.watchList2) {
      let watchList = this.props.userData.watchList2.map((item) => item.lotId)
      return Boolean(
        watchList &&
        watchList.includes(this.props.lotId)
      )
    }
  }

  render () {
    const { bigButton, watchButtonLoading, lotDetails, userData, isBorder, isSmall } = this.props
    const { active } = this.state
    if (!userData || !userData.firstName) {
      return <span />
    }
    if (bigButton) {
      return (
        <div
          className={`watching-btn${active ? ' active' : ''} ${watchButtonLoading ? ' disabled' : ''}`}
          onClick={this.toggle}
        >
          {lotDetails && watchButtonLoading && (
            <div className='app-spinner-overlay area'>
              <div className='app-spinner area'>
                <Circle size={30} />
              </div>
            </div>
          )}
          <span>{active ? 'Watching' : 'Watch'}</span>
          <span className='icon-wrapper'><FontAwesomeIcon icon={faBookmark} /></span>
        </div>
      )
    } else {
      return (
        <div
          className={`${active && isBorder ? 'active' : ''} watching-small-btn ${isBorder ? 'with-borders' : ''}`}
          onClick={this.toggle}>
          <FontAwesomeIcon className={`${active ? 'active' : ''} ${isSmall ? 'small' : ''}  watch-flag`} icon={faBookmark} />
        </div>
      )
    }
  }
}

WatchButton.globalRequestThrottleMs = 600
WatchButton.globalRequestTimeout = null

WatchButton.propTypes = {
  userData: PropTypes.object.isRequired,
  lotId: PropTypes.string.isRequired,
  bigButton: PropTypes.bool,
  onChange: PropTypes.func
}

WatchButton.defaultProps = {
  bigButton: true,
  onChange: () => {}
}

export default WatchButton
