import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import BackButton from '../../BackButton/BackButton'
import FgTable from '../../FgTable/FgTable'
import Api from '../../../services/api'
import Checkbox from '../../Checkbox/Checkbox'
import { makePrice } from '../../../utils/Lots'
import { momentTz } from '../../../utils'

class MyListings extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      lots: [],
      loaded: false
    }
  }

  componentDidMount () {
    this.getLots()
  }

  async getLots () {
    this.props.showSpinner(true)
    let res = await Api.getLotsForListing()
    if (res) {
      this.setState({
        lots: res.data,
        loaded: true
      })
    } else {
      this.setState({ loaded: true })
    }
    this.props.showSpinner(false)
  }

  getListingPrice (kindType) {
    let kind = this.props.kinds.find(kind => kind.type === kindType)
    let listingFee = this.props.auctionData && this.props.auctionData.listingFee ? this.props.auctionData.listingFee : null
    if (!listingFee) {
      return kind ? kind.listingPriceCents : null
    } else {
      return listingFee
    }
  }

  getListingFee (lot, kindType) {
    let kind = this.props.kinds.find(kind => kind.type === kindType)
    let listingFee = lot.auctionData && lot.auctionData.listingFee ? lot.auctionData.listingFee : null
    if (!listingFee) {
      return kind ? kind.listingPriceCents : null
    } else {
      return listingFee
    }
  }

  totalHead () {
    return this.state.lots.map(lot => { return lot.count }).reduce((a, b) => a + b, 0)
  }

  render () {
    if (!this.state.loaded) {
      return <div />
    }
    return (
      <div className='listings-wrapper'>
        <div className='fg-header'>My Listings</div>
        <p className='fg-paragraph'>Listing fees: Cattle {makePrice(this.getListingPrice('cattle'))} per head. Sheep {makePrice(this.getListingPrice('sheep'))} per head. Goat {makePrice(this.getListingPrice('goat'))} per head. All prices are exclusive of GST.
          Volume discounts apply on application.
        </p>
        <BackButton onPress={() => {
          this.props.history.goBack()
        }} name='Back to My Admin' />
        <hr className='separator' />
        {!this.state.lots.length ? (
          <div className='no-data-placeholder'>
            <div className='message'>
              Listings for closed auctions will appear here.
            </div>
          </div>
        ) : (
          <div className='table-wrapper'>
            <FgTable
              data={this.state.lots}
              style={{ height: '820px' }}
              type='listing'
              columns={[
                {
                  Header: 'My FarmGate Auction Listings',
                  columns: [
                    {
                      Header: 'Date',
                      id: 'date',
                      accessor: 'date',
                      Cell: ({ original, value }) => {
                        if (original.status === 'withdrawn') {
                          return ''
                        } else {
                          return momentTz(value).format('DD/MM/YY')
                        }
                      },
                      sortMethod: (a, b) => {
                        return new Date(a) - new Date(b)
                      },
                      Footer: (<span>Total</span>),
                      maxWidth: 125
                    },
                    { Header: 'Lot No.',
                      accessor: 'number',
                      maxWidth: 70,
                      sortMethod: (a, b) => {
                        return a - b
                      }
                    },
                    { Header: 'Stock Details',
                      id: 'stock',
                      accessor: 'title',
                      Cell: ({ original, value }) => {
                        if (original.status === 'withdrawn') {
                          return (<span className='broken'>{value}</span>)
                        } else {
                          return (<span>{value}</span>)
                        }
                      }
                    },
                    { Header: 'Type',
                      accessor: 'kindData.title',
                      maxWidth: 115
                    },
                    { Header: 'Unit price',
                      id: 'price',
                      accessor: 'price',
                      Cell: ({ original, value }) => {
                        if (original.auctionData.listingFee) {
                          return makePrice(original.auctionData.listingFee)
                        } else {
                          return makePrice(value)
                        }
                      },
                      sortMethod: (a, b) => {
                        return b - a
                      },
                      maxWidth: 120
                    },
                    { Header: 'Total',
                      id: 'total',
                      accessor: 'total',
                      Cell: ({ original, value }) => {
                        if (original.auctionData.listingFee) {
                          return makePrice(original.auctionData.listingFee * original.count)
                        } else {
                          return makePrice(value)
                        }
                      },
                      sortMethod: (a, b) => {
                        return b - a
                      },
                      maxWidth: 125
                    },
                    {
                      Header: 'Paid',
                      accessor: 'paid',
                      sortMethod: (a, b) => {
                        if (a) {
                          return -1
                        } else if (b) {
                          return 1
                        } else {
                          return 0
                        }
                      },
                      Cell: ({ value }) => {
                        return (
                          <Checkbox
                            value={value}
                            type='green'
                            fgDisabled />
                        )
                      },
                      maxWidth: 70
                    }
                  ]
                }
              ]}
            />
          </div>
        )}

      </div>
    )
  }
}

MyListings.propTypes = {
  showSpinner: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default MyListings
