import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faCheck
} from '@fortawesome/fontawesome-free-solid'
import FilterMultiItem from './FilterMultiItem/FilterMultiItem'
import { isElementInsideOf } from '../../utils'

export default class FilterMultiSelect extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false
    }
    this.checkHandler = this.checkHandler.bind(this)
    this.uncheckHandler = this.uncheckHandler.bind(this)
    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
    this.handleDocumentClick = this.handleDocumentClick.bind(this)

    this.innerContainer = React.createRef()
  }
  componentDidUpdate (prevProps, prevState) {
    if (prevState.isOpen !== this.state.isOpen) {
      if (this.state.isOpen) {
        document.addEventListener('click', this.handleDocumentClick)
      } else {
        document.removeEventListener('click', this.handleDocumentClick)
      }
    }
  }
  componentWillUnmount () {
    document.removeEventListener('click', this.handleDocumentClick)
  }
  checkHandler (option,regionOption) {
    if (regionOption){
      let newSelected = [...this.props.selectedRegionOptions, regionOption]
      this.props.onChange("region", newSelected)
    }else {
      let newSelected = [...this.props.selectedOptions, option]
      this.props.onChange(this.props.name, newSelected)
    }

  }
  uncheckHandler (option,regionOption) {

    if (regionOption){
      let newSelected = this.props.selectedRegionOptions.filter(item => item !== regionOption)
      this.props.onChange("region", newSelected)
    }else {
      let newSelected = this.props.selectedOptions.filter(item => item !== option)
      this.props.onChange(this.props.name, newSelected)
    }

   
  }
  renderOptions (optionsArray) {
    const { options, selectedOptions,regionsData,selectedRegionOptions } = this.props
    const optionsPerColumn = Math.round(optionsArray.length)
    // const optionsPerColumn = title === 'Breed' ? Math.round(optionsArray.length / 2) : 14
    const optionsLength = optionsArray.length
    if (optionsLength > optionsPerColumn) {
      const optionsNumOfColumns = Math.ceil(optionsLength / optionsPerColumn)
      const optionsColumns = []
      for (let i = 0; i < optionsNumOfColumns; i++) {
      
        optionsColumns.push(
          <ul key={`column-${i}`}>
            {
              options
                .slice(i * optionsPerColumn, (i + 1) * optionsPerColumn)
                .map(option => {
                  const isSelected = selectedOptions.includes(option)               
                  return(
                   this.props.name === "location" ? <FilterMultiItem
                    key={option}
                    option={option}
                    isSelected={isSelected}
                    regionsData = {regionsData}
                    selectedRegionOptions = {selectedRegionOptions}
                    name = {this.props.name}
                    checkHandler={this.checkHandler}
                    uncheckHandler={this.uncheckHandler}
                  /> :
                  <FilterMultiItem
                    key={option}
                    option={option}
                    isSelected={isSelected}
                    checkHandler={this.checkHandler}
                    uncheckHandler={this.uncheckHandler}
                  />

                  ) 
                })
            }
          </ul>
        )
      }
      return optionsColumns
    }
    return (
      <ul>
        {
          options.map(option => {
            const isSelected = selectedOptions.includes(option)
            return (
              this.props.name === "location" ?  <FilterMultiItem
              key={option}
              option={option}
              isSelected={isSelected}
              regionsData = {regionsData}
              name = {this.props.name}
              selectedRegionOptions = {selectedRegionOptions}
              checkHandler={this.checkHandler}
              uncheckHandler={this.uncheckHandler}
            /> :
            <FilterMultiItem
              key={option}
              option={option}
              isSelected={isSelected}
              checkHandler={this.checkHandler}
              uncheckHandler={this.uncheckHandler}
            />
            ) 
          })
        }
      </ul>
    )
  }
  open () {
    if (!this.state.isOpen) {
      this.setState({ isOpen: true })
    }
  }
  close () {
    if (this.state.isOpen) {
      this.setState({ isOpen: false })
    }
  }
  handleDocumentClick (event) {
    if (!this.state.isOpen ||
      isElementInsideOf(event.target, this.innerContainer.current)) {
      return
    }
    this.close()
  }
  render () {
    const {
      title,
      icon,
      options,
      selectedOptions,
      buttonLg,
      name
    } = this.props
    const {
      isOpen
    } = this.state
    const isActive = Boolean(isOpen || selectedOptions.length)
    return (
      <div
        className={`filter-multi-wrapper filter-multi-wrapper-md ${buttonLg ? 'filter-multi-wrapper-lg' : ''}`}>
        <div className={`btn filter-btn filter-btn-md ${buttonLg ? 'filter-btn-lg' : ''} ${isOpen ? 'btn-opened' : ''} ${isActive ? 'filter-btn-active' : ''}`}
          onClick={this.open}
        >
          <span>{title}</span>
          <span className='icon-wrapper'>{icon}</span>
        </div>
        { isOpen &&
          <div ref={this.innerContainer} className={`filter-multi-inner ${name === 'breed' || name === 'region' || name === 'location' ? 'overflow' : ''} ${name === 'location' ? 'location-container-width' : ''}`}>
            <div className='filter-multi-options'>
              {options.length !== 0 ? this.renderOptions(options) : 'No breed Found'}
            </div>
            <div className='filter-multi-buttons'>
              <div
                className='btn filter-btn filter-btn-sm'
                onClick={this.close}
              >
                <span>OK</span>
                <span className='icon-wrapper icon-green'><FontAwesomeIcon icon={faCheck} /></span>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

FilterMultiSelect.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  buttonLg: PropTypes.bool
}
