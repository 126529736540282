import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withCookies } from 'react-cookie'
// router
import { HashRouter, Route, Redirect, Switch } from 'react-router-dom'
// redux
import { Provider } from 'react-redux'
// components
import './App.css'
import MainContainer from './MainApp/MainContainer'
import * as UpdateData from '../utils/UpdateData'
import logOut from '../utils/logOut'
import LoadingContainer from '../components/Loading/LoadingContainer'
import SignUpGuestContainer from './Auth/SignUpGuest/SignUpGuestContainer'
import AuctionLiveContainer from './AuctionLive/AuctionLiveContainer'
import SignInContainer from './Auth/SignIn/SignInContainer'
import Forgot from './ForgotPassword/ForgotContainer'
import ChangePassword from './ChangeForgottenPassword/ChangeForgottenPasswordContainer'
import ModalContainer from './Modal/ModalContainer'
import ContactUsContainer from './ContactUs/ContactUsContainer'
import VersionUpgradeTermsContainer from '../components/Tools/Terms/VersionUpgradeTermsContainer'
import NotFound from './NotFound/NotFound'
import AsBuyerContainer from './Auth/AsBuyer/AsBuyerContainer'
import AsBuyerAndSellerContainer from './Auth/AsBuyerAndSeller/AsBuyerAndSellerContainer'
import ReactGA from 'react-ga'
import Api from '../services/api'
import { checkRedirectForIos } from '../utils'
import { isIos } from '../utils/DeviceDetector'

import { MODE } from '../config'

const errorSettingsRefresh = 20000

class App extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      loaded: false,
      loggedInViaToken: false
    }
    this.checkInterval = null
  }

  static async getDerivedStateFromProps () {
    await Api.checkServer().then(async response => {
      if (response.ok) {
        let json = await response.json()
        if (json.isLive) {
          UpdateData.updateInitialData()
        }
      } else {
        throw new Error()
      }
    })
    return null
  }

  async componentDidMount() {
    if (isIos() && window.location.href.includes('mobile')) {
      window.location.href = 'https://apps.apple.com/au/app/farmgate-auctions/id1469350158'
      return
    }
    let token = window.location.hash.split('/').reverse()[0]
    if (token.length > 15) {
      setTimeout(async () => {
        if (this.props.loggedIn) {
          await Api.userLogout()
        }
        await Api.getUserMagicLink(token, { scene: 'sign in' })
          .then(async resp => {
            if (resp === 401 || resp === 403) {
              // Invalid Link
            } else if (resp) {
              this.props.changeStateProp('hasData', false, 'temp')
              this.props.changeStateProp('data', resp.user, 'user')
              this.props.changeStateProp('loggedIn', true, 'user')
              window.location.href = window.location.href.split('/mobile')[0]
              this.setState({
                loggedInViaToken: true
              })
            }
          })
      }, 0)
    }
    checkRedirectForIos()
    const { cookies } = this.props
    cookies.set('BackUpServer', true)
    this.checkServerBackUp()
    let trackingId = MODE === 'live' ? 'UA-137496646-3' : 'UA-137496646-1'
    this.checkInterval = setInterval(() => { this.checkServerBackUp() }, errorSettingsRefresh)
    ReactGA.initialize([
      {
        trackingId,
        gaOptions: {
          name: 'WebApp'
        }
      },
      {
        trackingId: 'UA-137496646-2',
        gaOptions: {
          name: 'WordPress'
        }
      }
    ], { alwaysSendToDefaultTracker: false })
    await Api.checkServer().then(async response => {
      if (response.ok) {
        let json = await response.json()
        if (json.isLive) {
          const user = this.props.store.getState().user
          if (user.loggedIn) {
            await UpdateData.updateMainData()
          } else {
            await UpdateData.updateInitialData()
          }
        }
      } else {
        throw new Error()
      }
    })
    window.addEventListener('offline', () => {
      window.localStorage.setItem('disconnect', 'true')
      window.localStorage.setItem('screen', window.location.hash.split('/').reverse()[0])
    })
    window.addEventListener('online', () => {
      if (window.localStorage.getItem('disconnect') === 'true') {
        let screen = window.localStorage.getItem('screen')
        if (screen.match(/AC|AS/gm)) {
          screen = `Catalogue ${screen}`
        } else if (screen.match(/LC|LS/gm)) {
          screen = `Lot Details ${screen}`
        }
        let text = `Internet Connection Lost on Screen: ${screen}`
        ReactGA.event({
          category: 'Connection Lost',
          action: text,
          label: text
        }, ['WebApp'])
        window.localStorage.clear()
      }
    })
    if (this.shouldLogout()) {
      logOut.init()
    }
    this.setState({
      loaded: true
    })
  }

  componentWillUnmount () {
    clearInterval(this.checkInterval)
  }

  checkServerBackUp = async () => {
    const { cookies } = this.props
    await Api.checkServer().then(async response => {
      if (response.ok) {
        let json = await response.json()
        if (json.isLive) {
          if (!cookies.cookies.BackUpServer) {
            setTimeout(async () => {
              cookies.set('BackUpServer', true)
              this.props.changeStateProp('isServerLiveMessage', json.message, 'temp')
              this.props.changeStateProp('isServerLive', json.isLive, 'temp')
            }, errorSettingsRefresh)
          } else {
            this.props.changeStateProp('isServerLiveMessage', json.message, 'temp')
            this.props.changeStateProp('isServerLive', json.isLive, 'temp')
          }
        } else {
          cookies.set('BackUpServer', false)
          this.props.changeStateProp('isServerLiveMessage', json.message, 'temp')
          this.props.changeStateProp('isServerLive', json.isLive, 'temp')
        }
      } else {
        throw new Error()
      }
    }).catch(err => {
      console.log('Err: ', err)
    })
  }

  shouldLogout () {
    if (this.props.loggedIn && !this.props.rememberMe) {
      if (!window.sessionStorage.getItem('remember-session')) {
        return true
      }
    }
    return false
  }

  checkAuctionResultRouteFromWordPress () {
    if (document.location.hash.match('AucRes=')) {
      let route = document.location.hash.slice(document.location.hash.match('AucRes=').index + 'AucRes='.length)
      this.props.changeStateProp('AuctionResultRoute', route, 'temp')
    }
  }

  checkWatchLotRouteFromWordPress () {
    if (document.location.hash.match('lot-watch-id=')) {
      let route = document.location.hash.split('upcoming/')[1]
      this.props.changeStateProp('lotWatchRoute', route, 'temp')
    }
  }

  redirectAfterSignIn () {
    if (this.props.AuctionResultRoute) {
      return <Redirect to={`/main/results/${this.props.AuctionResultRoute}`} />
    } else if (this.props.lotWatchRoute) {
      return <Redirect to={`main/upcoming/${this.props.lotWatchRoute}`} />
    } else {
      return <Redirect to='/main/upcoming' />
    }
  }

  checkQueryParams () {
    this.checkAuctionResultRouteFromWordPress()
    this.checkWatchLotRouteFromWordPress()
  }

  render () {
    if (!this.state.loaded) {
      return <div />
    }
    return (
      <Provider store={this.props.store}>
        <HashRouter>
          <div className='App'>
            <Switch>
              {this.checkQueryParams()}
              {/* <Route path='/main/manage/my-texts/'
                render={(obj) => {
                  return this.state.loggedInViaToken
                    ? (() => {
                      return <MainContainer {...obj} />
                    })()
                    : <Redirect to='/sign-in' />
                }} /> */}
              <Route exact path='/' render={() => (
                !this.props.loggedIn ? (
                  <Redirect to='/sign-in' />
                ) : (
                  <Redirect to='/main/upcoming' />
                )
              )} />
              <Route path='/main/manage/operation-access/as-buyerandseller' render={(obj) => {
                return this.props.loggedIn
                  ? <AsBuyerAndSellerContainer {...obj} />
                  : (
                    <Redirect to='/sign-in' />
                  )
              }} />
              <Route path='/main/manage/operation-access/as-buyer' render={(obj) => {
                return this.props.loggedIn
                  ? <AsBuyerContainer {...obj} />
                  : (
                    <Redirect to='/sign-in' />
                  )
              }} />
             <Route path='/main/terms-accept' render={(obj) => {
                return this.props.loggedIn
                  ? <VersionUpgradeTermsContainer {...obj} />
                  : (
                    <Redirect to='/sign-in' />
                  )
              }} />
              <Route path='/contact-us' render={(obj) => {
                return <ContactUsContainer {...obj} />
              }} />
              <Route path='/log-out' render={(obj) => {
                logOut.init()
                return <Redirect to='/sign-in' />
              }} />
              <Route path='/sign-up' render={(obj) => {
                return this.props.loggedIn
                  ? <Redirect to='/main/upcoming' />
                  : <SignUpGuestContainer {...obj} />
              }} />
              <Route path='/sign-in' render={(obj) => {
                return this.props.loggedIn && !window.location.hash.split('token=')[1]
                  ? this.redirectAfterSignIn()
                  : <SignInContainer {...obj} loggedIn={this.props.loggedIn} />
              }} />
              <Route path='/forgot/change-password' render={(obj) => {
                return this.props.loggedIn
                  ? <Redirect to='/main/upcoming' />
                  : <ChangePassword {...obj} />
              }} />
              <Route path='/forgot' render={(obj) => {
                return this.props.loggedIn
                  ? <Redirect to='/main/upcoming' />
                  : <Forgot {...obj} />
              }} />
              <Route path='/main'
                render={(obj) => {
                  return !this.props.loggedIn
                    ? (() => {
                      this.props.changeStateProp('redirectUrl', window.location.href, 'user')
                      return <Redirect to='/sign-in' />
                    })()
                    : <MainContainer {...obj} />
                }} />
              <Route path='/auction-live/:auctionNumber'
                render={(obj) => {
                  return <AuctionLiveContainer {...obj} loggedIn={this.props.loggedIn} />
                }} />
              <Route component={NotFound} />
            </Switch>
            <ModalContainer />
            <LoadingContainer />
          </div>
        </HashRouter>
      </Provider>
    )
  }
}

App.propTypes = {
  showModal: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  rememberMe: PropTypes.bool.isRequired,
  changeStateProp: PropTypes.func.isRequired,
}
App.defaultProps = {}

export default withCookies(App)
