import React from 'react'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faUser
} from '@fortawesome/fontawesome-free-solid'
import AuthMultipleCheckbox from '../../Auth/AuthMultipleCheckbox/AuthMultipleCheckbox'

const StockTypes = (props) => {
  const {  edit, handleMultipleCheckbox, stockTypeArr,user } = props
  
  return (
    <div className='info-item'>
      <div className='cd-header'>
        <FontAwesomeIcon className='icons-style' icon={faUser} />
        <div className='cd-title'>Stock Types</div>
      </div>
      <div className='cd-data'>
        <div className='columns'>
          <div className=''>
			    <AuthMultipleCheckbox
              options={stockTypeArr}
              name='stockType'
              selectedOptions={user.approvedStockTypes}
              onChangeStock={handleMultipleCheckbox}
              disabled={!edit}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default StockTypes
