import React, { PureComponent } from 'react'
// import PropTypes from 'prop-types'

class Footer extends PureComponent {
  render () {
    return (
      <footer className='app-footer'>
        <div className='footer-wrap'>
          <span className='copyright'>Copyright {new Date().getFullYear()} FarmGate Auctions All Rights Reserved</span>
        </div>
      </footer>
    )
  }
}

export default Footer
