import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'

import LiveAuctionTimeService from '../../services/LiveAuctionTimeService'
import * as LotUtils from '../../utils/Lots'

const sounds = {
  preclose: require('../../assets/audio/Countdown.mp3')
}

let audio = document.createElement('audio')
audio.src = sounds.preclose

export default class StateTimerLot extends PureComponent {
  constructor (props) {
    super(props)
    this.interval = setInterval(() => this._updateTime(), 1000)
    this.state = {
      timeLeft: this.getTimeLeft(),
      playStatus: null
    }
    this.getDisplayText = memoize(this.getDisplayText)
  }

  componentDidMount () {
    audio.addEventListener('ended', this.soundState)
    audio.addEventListener('playing', this.soundStatePlaying)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.lot !== this.props.lot) {
      this._updateTime()
    }

    if (prevProps.isSound !== this.props.isSound && this.props.isSound === false && this.state.playStatus === 'playing') {
      this.stopSound()
    }
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  soundState = (event) => {
    this.setState({ playStatus: event.type })
  }

  soundStatePlaying = (event) => {
    this.setState({ playStatus: event.type })
  }

  getTimeLeft () {
    if (this.props.auction.auctionType === 'synchronous') {
      return LiveAuctionTimeService.getAuctionTimeLeft(this.props.auction, this.props.offsetMs)
    } else {
      return LiveAuctionTimeService.getLotTimeLeft(this.props.lot, this.props.offsetMs)
    }
  }

  _updateTime () {
    this.setState({ timeLeft: this.getTimeLeft() }, () => this.props.updateTimeCallback())
    let timer
    if (this.props.auction.auctionType === 'simultaneous'|| this.props.auction.auctionType === 'synchronous') {
      timer = 30
    } else {
      timer = 10
    }
    if (
      this.props.lot.pausedTime === null &&
      this.props.lot.frozenTime === null &&
      this.state.timeLeft.hours === 0 &&
      this.state.timeLeft.minutes === 0 &&
      this.state.timeLeft.seconds === timer) {
      if (this.state.playStatus !== null && this.state.playStatus !== 'ended') {
        return
      }

      // Turing off 30 min to go sound for synchronus type auctions as temporary. to enable jus remove the condition
      if (!this.props.isSound || this.props.auction.auctionType === 'synchronous') {
        return
      }

      this.setSound()
    }
  }

  stopSound () {
    audio.pause()
    audio.currentTime = 0
    this.setState({
      playStatus: null
    })
  }

  setSound () {
    if (window.localStorage.getItem('countdownSound') !== 'true') return
    let playPromise = audio.play()

    if (playPromise !== undefined) {
      playPromise
        .then(_ => {
          // Automatic playback started!
          // Show playing UI.
        })
        .catch(error => {
          // Auto-play was prevented
          // Show paused UI.
          console.log('error', error)
        })
    }
  }

  getDisplayText (lot, timeLeft) {
    return LotUtils.getTimerState(lot, timeLeft, this.props.auction, this.props.offsetMs)
  }

  render () {
    const { lot, grid } = this.props
    const { timeLeft } = this.state
    return (
      <div className={`lot timer-text ${lot.frozenTime ? 'frozen lot-current-state' : lot.pausedTime ? `paused lot-current-state ${grid}` : ''} ${grid}`}>
        {lot.pausedTime ? `PAUSED - ${this.getDisplayText(lot, timeLeft)}` : lot.frozenTime ? `FROZEN - ${this.getDisplayText(lot, timeLeft)}` : this.getDisplayText(lot, timeLeft)}
      </div>
    )
  }
}

StateTimerLot.propTypes = {
  lot: PropTypes.object.isRequired
}
