import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import './crop-image.css'

import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faTimes
} from '@fortawesome/fontawesome-free-solid'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { faCheck } from '@fortawesome/fontawesome-free-solid/index'
import { ImgManageButton } from '../../elements/ImgManageButton'
const image = {
  rotate: require('../../assets/img/rotate.png'),
  zoomIn: require('../../assets/img/zoomIn.png'),
  zoomOut: require('../../assets/img/zoomOut.png')
}

export class CropImageModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      landscape: false
    }
    this.myCropper = React.createRef()
  }
  _crop () {
    this.setState({
      croppedImageUrl: this.refs.cropper.getCroppedCanvas().toDataURL()
    })
  }

  render () {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
        ariaHideApp={false}
        className='image-crop'
        ref={elem => { this.myCropper = elem }}
      >
        <div className='crop-modal'>
          <span className='icon-close icon-danger icon-for-prewiev' onClick={this.props.closeModal} >
            <FontAwesomeIcon icon={faTimes} />
          </span>
          <Cropper
            ref='cropper'
            src={this.props.image.url}
            className={'modal-test'}
            guides={false}
            dragMode={'move'}
            viewMode={1}
            restore={false}
            cropBoxResizable={false}
            draggable
            toggleDragModeOnDblclick={false}
            minCropBoxWidth={320}
            minCropBoxHeight={220}
            zoomable
            // responsive={false}
            ready={() => {
              this.refs.cropper.setCropBoxData({
                width: 320,
                height: 220
              })
              this._crop()
            }}
          />
          <div className='img-management-buttons'>
            <ImgManageButton onClick={() => {
              this.refs.cropper.rotate(270)
            }}>
              <p>Rotate</p>
              <div className='logo'>
                <img src={image.rotate} alt='rotate' />
              </div>
            </ImgManageButton>
            <ImgManageButton onClick={() => {
              this.refs.cropper.zoom(0.1)
            }}>
              <p>Zoom in</p>
              <div className='logo'>
                <img src={image.zoomIn} alt='zoom in' />
              </div>
            </ImgManageButton>
            <ImgManageButton onClick={() => {
              this.refs.cropper.zoom(-0.1)
            }}>
              <p>Zoom out</p>
              <div className='logo'>
                <img src={image.zoomOut} alt='zoom out' />
              </div>
            </ImgManageButton>
          </div>
          <div className='save-btn' onClick={() => {
            this.setState({
              croppedImageUrl: this.refs.cropper.getCroppedCanvas().toDataURL(this.props.image.file.type)
            }, () => {
              this.props.saveImage(this.state.croppedImageUrl, this.refs.cropper.getData().rotate)
            })
          }}>
            <span>Save</span>
            <span className='icon-wrapper icon-success'>
              <FontAwesomeIcon icon={faCheck} size='lg' />
            </span>
          </div>
        </div>
      </Modal>
    )
  }
}

CropImageModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  saveImage: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  lot: PropTypes.object,
  preview: PropTypes.bool
}

export default CropImageModal
