import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { NavLink,withRouter } from 'react-router-dom'
import { faCaretRight, faCaretLeft } from '@fortawesome/fontawesome-free-solid'
import { pick as _pick } from 'lodash'
import FdInput from '../Fd-input/index'
import FgSelect from '../../FgSelect/FgSelect'
import FgPhone from '../../FgPhone/FgPhone'
// import AuthCheckbox from '../AuthCheckbox/AuthCheckbox'
import NavigationTop from '../NavigationTop/NavigationTop'
import Api from '../../../services/api'
import { convertData } from '../../../utils/convertData'
import Header from '../../Header/HeaderContainer'
import Footer from '../../Footer/Footer'
import {
  validateFirstName,
  validateLastName,
  validateEmail,
  validatePassword,
  validateRePassword,
  validatePic,
  validateAbn,
  validateTradingName,
  validateJobTitle,
  validateWebsite,
  validatePhone,
  validateSecondaryPhone,
  validatePostcode,
  validateTown,
  validateState,
  userOperationType,
  validateSSPermitNumber,
  validateSSPermitNumberOfYears,
  validateOtherOptionType
} from '../../../utils/Validators'
import {
  PIC_REGEXP_SOFT,
  ABN_REGEXP_SOFT,
  FIRST_LAST_NAME,
  AUSTRALIAN_POSTCODE_SOFT,
  NUMBER_REGEXP
} from '../../../constants'
import ReactGA from 'react-ga'

let defaultFieldValueRequired = (value = '', valid = false) => {
  return {
    value: value,
    required: true,
    valid: valid,
    invalidMessage: ''
  }
}

let defaultFieldValue = (value = '', valid = true) => {
  return {
    value: value,
    valid: valid,
    invalidMessage: ''
  }
}

class SignUpGuest extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      finishedRegistration: false,
      userOperationsTypes : null,
      form: {
        firstName: defaultFieldValueRequired(),
        lastName: defaultFieldValueRequired(),
        email: defaultFieldValueRequired(),
        password: defaultFieldValueRequired(),
        repassword: defaultFieldValueRequired(),
        tradingName: defaultFieldValueRequired(),
        website: defaultFieldValue(),
        profession : defaultFieldValueRequired(),
        otherProfession : defaultFieldValueRequired(),
        phone: defaultFieldValueRequired(''),
        secondaryPhone: defaultFieldValue(''),
        officeAddress: {
          state: defaultFieldValueRequired(),
          postcode: defaultFieldValueRequired(),
        },
        userRegisteredFrom: defaultFieldValue(props.wpSiteName)
      },
      activeStep: 'step1' // 'step1', 'step2', 'step3'
    }
    this.saveTimeout = null
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleUserOperationTypes = this.handleUserOperationTypes.bind(this)
    this.handleLocationState = this.handleLocationState.bind(this)
    this.handleCheckbox = this.handleCheckbox.bind(this)
    this.handleFormCheckbox = this.handleFormCheckbox.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.goToStep2 = this.goToStep2.bind(this)
    this.onBlur = this.onBlur.bind(this)
    this.onAddressBlur = this.onAddressBlur.bind(this)
    this.handleAddressChange = this.handleAddressChange.bind(this)
  }

  async componentDidMount () {
    if (this.props.signUpInfo.seller) {
      this.fillFromStorage()
    }
    console.log(this.props.wpSiteName,"props.wpSiteName");
    console.log(this.props.cameFromWpSite,"props.cameFromWpSite");
    if (document.location.hash.match('wp')) {
      let name = document.location.href.split('?')[1].split('&&')[1] ? document.location.href.split('?')[1].split('&&')[1].replace(/[~`!@#$%^&*()+={}<>.,-_]/g, '') : document.location.href.split('?')[1].split('&')[1] ? document.location.href.split('?')[1].split('&')[1].replace(/[~`!@#$%^&*()+={}<>.,-_]/g, ''): 'Farmgate';
      ReactGA.event({
        category: 'Click on Register on WordPress site',
        action: 'Click on Register on WordPress site'
      }, ['WordPress'])
      this.props.changeStateProp('cameFromWpSite', true, 'user')
      this.props.changeStateProp('wpSiteName', name, 'user') 
    }
    let profession = await Api.getUserOperations();
    if(profession){
      this.setState({
        userOperationsTypes: profession
      })
      // this.setState({
      //   form: update(this.state.form, {
      //     userOperationsTypes: { $set: profession }
      //   })
      // })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.form !== this.state.form) {
      clearTimeout(this.saveTimeout)
      this.saveTimeout = setTimeout(() => this.saveToStorage(), 1000)
    }
  }

  componentWillUnmount () {
    let step = ''
    switch (this.state.activeStep) {
      case 'step1':
        step = 'Stock and Station Agent Registration drop out: Step 1'
        break
      case 'step2':
        step = 'Stock and Station Agent Registration drop out: Step 2'
        break
      case 'step3':
        step = 'Stock and Station Agent Registration drop out: Step 3'
        break
      default:
        break
    }
    if (!this.state.finishedRegistration) {
      ReactGA.event({
        category: 'Stock and Station Agent Registration drop out',
        action: step,
        label: step
      }, ['WebApp'])
    }
    clearTimeout(this.saveTimeout)
  }

  fillFromStorage () {
    // pre-fill fields from global store
    console.log(this.props.signUpInfo.seller,"this.props.signUpInfo.seller+++");
    let form = this.state.form
    Object.entries(this.props.signUpInfo.seller).forEach(([key, value]) => {
      if (!form.hasOwnProperty(key)) return
          form = update(form,
            { [key]: { value: { $set: value } } }
          )
    })
    this.setState({ form })
  }

  saveToStorage () {
    if (!(this.state.activeStep === 'step1' || this.state.activeStep === 'step2')) {
      return
    }
    let saveData = convertData(this.state.form)
    delete saveData.password
    delete saveData.repassword
    this.props.changeStateProp('signUpInfo', update(this.props.signUpInfo, {
      seller: { $set: saveData }
    }), 'main')
  }

  mapOptions (options) {
    return options.map(option => {
      return {
        value: option,
        label: option
      }
    })
  }
  mapTypeOptions (options) {
    return options.map(option => {
      return {
        value: option.name,
        label: option.name
      }
    })
  }

  mapSelected (option) {
    return {
      value: option,
      label: option
    }
  }

  handleInputChange (event) {
    let { target: { name, value } } = event
    switch (name) {
      case 'firstName':
      case 'lastName':
      case 'tradingName':
      case 'jobTitle':
      case 'otherProfession':
        if (value && !FIRST_LAST_NAME.test(value)) {
          return
        } else {
          value = value.slice(0, 1).toUpperCase() + value.slice(1)
        }
        break
      case 'phone':
      case 'secondaryPhone':
        if (value && !NUMBER_REGEXP.test(value)) {
          return
        }
        break
      case 'PIC':
        value = value.toUpperCase()
        if (value && !PIC_REGEXP_SOFT.test(value)) {
          return
        }
        break
      case 'ABN':
        if (value && !ABN_REGEXP_SOFT.test(value)) {
          return
        }
        break
      case 'yearsInIndustry':
      case 'SSPermitNumber':
      case 'SSPermitNumberOfYears':
        if (value && !NUMBER_REGEXP.test(value)) {
          return
        }
        break
      default:
    }
    this.setState({
      form: update(this.state.form, {
        [name]: {
          value: { $set: value }
        }
      })
    })
  }

  onBlur ({ target: { name } }) {
    this.validate(name)
  }

  onAddressBlur (type, event) {
    let { target: { name } } = event
    this.validate(name, type)
  }

  // changeSellerType (name) {
  //   this.setState({
  //     form: update(this.state.form, {
  //       sellerType: {
  //         value: { $set: name }
  //       }
  //     })
  //   })
  // }

  handleCheckbox ({ target: { name } }) {
    this.setState(prevValue => {
      return { [name]: !prevValue[name] }
    })
  }

  handleFormCheckbox ({ target: { name } }) {
    this.setState({
      form: update(this.state.form, {
        [name]: {
          value: (value) => !value
        }
      })
    })
  }

  handleUserOperationTypes (type, option, { action }) {
    if (action !== 'select-option') {
      return
    }
    this.setState(() => {
        return {
          form: update(this.state.form, {
            
              [type] : {
                value: { $set: option.value }
              }
            
          })
        }
    },
    () => this.validate('profession',null), () => {
    }
    )
  }

  handleAddressChange(type, event) {
    let { target: { name, value } } = event
    switch (name) {
      case 'postcode':
        if (value && !AUSTRALIAN_POSTCODE_SOFT.test(value)) {
          return
        }
        break

      default:
    }
    this.setState({
      form: update(this.state.form, {
        [type]: {
          [name]: {
            value: { $set: value }
          }
        }
      })
    })
  }


  handleLocationState(type, option, { action }) {
    if (action !== 'select-option') {
      return
    }
    this.setState(() => {
      if (type === 'officeAddress') {
        return {
          form: update(this.state.form, {
            [type]: {
              state: {
                value: { $set: option.value }
              }
            }
          }),
        //  typeOfUser: ['NSW', 'QLD'].includes(option.value) ? 'LLA' : 'ULA'
        }
      } else {
        return {
          form: update(this.state.form, {
            [type]: {
              state: {
                value: { $set: option.value }
              }
            }
          })
        }
      }
    }, () => this.validate('state', type), () => {
    })
  }

  validate (name, address) {
    let validateResult
    switch (name) {
      case 'firstName':
        validateResult = validateFirstName(this.state.form[name].value)
        break
      case 'lastName':
        validateResult = validateLastName(this.state.form[name].value)
        break
      case 'email':
        validateResult = validateEmail(this.state.form[name].value)
        break
      case 'password':
        validateResult = validatePassword(this.state.form[name].value)
        break
      case 'repassword':
        validateResult = validateRePassword(this.state.form[name].value, this.state.form.password.value)
        break
      case 'PIC':
        validateResult = validatePic(this.state.form[name].value, this.state.form.officeAddress.state.value, { required: false })
        break
      case 'ABN':
        validateResult = validateAbn(this.state.form[name].value, { required: false })
        break
      case 'tradingName':
        validateResult = validateTradingName(this.state.form[name].value)
        break
      case 'jobTitle':
        validateResult = validateJobTitle(this.state.form[name].value)
        break
      case 'website':
        validateResult = validateWebsite(this.state.form[name].value)
        break
      case 'otherProfession':
        validateResult = validateOtherOptionType(this.state.form[name].value)
        break
      case 'town':
        if (address) {
          validateResult = validateTown(this.state.form[address][name].value)
        }
        break
      case 'postcode':
        if (address) {
          validateResult = validatePostcode(this.state.form[address][name].value)
        }
        break
      case 'state':
        if (address) {
          validateResult = validateState(this.state.form[address][name].value)
        }
        break
      case 'phone':
        validateResult = validatePhone(this.state.form[name].value)
        break
      case 'profession' :
          validateResult = userOperationType(this.state.form[name].value)
        break
      case 'secondaryPhone':
        validateResult = validateSecondaryPhone(this.state.form[name].value)
        break
      case 'SSPermitNumber':
        validateResult = validateSSPermitNumber(this.state.form[name].value, { required: false })
        break
      case 'SSPermitNumberOfYears':
        validateResult = validateSSPermitNumberOfYears(this.state.form[name].value, { required: false })
        break
      default:
        break
    }
    if (validateResult === undefined) {
      return
    }
    if (address) {
      this.setState({
        form: update(this.state.form, {
          [address]: {
            [name]: {
              valid: { $set: validateResult === '' },
              invalidMessage: { $set: validateResult }
            }
          }
        })
      })
    } else {
      this.setState({
        form: update(this.state.form, {
          [name]: {
            valid: { $set: validateResult === '' },
            invalidMessage: { $set: validateResult }
          }
        })
      })
    }
  }

  validateFirstStep () {
    const { form } = this.state
    return new Promise((resolve) => {
      this.setState({
        form: update(form, {
          firstName: {
            valid: { $set: validateFirstName(form.firstName.value) === '' },
            invalidMessage: { $set: validateFirstName(form.firstName.value) }
          },
          lastName: {
            valid: { $set: validateLastName(form.lastName.value) === '' },
            invalidMessage: { $set: validateLastName(form.lastName.value) }
          },
          email: {
            valid: { $set: validateEmail(form.email.value) === '' },
            invalidMessage: { $set: validateEmail(form.email.value) }
          },
          phone: {
            valid: { $set: validatePhone(form.phone.value) === '' },
            invalidMessage: { $set: validatePhone(form.phone.value) }
          },
          secondaryPhone: {
            valid: { $set: validateSecondaryPhone(form.secondaryPhone.value) === '' },
            invalidMessage: { $set: validateSecondaryPhone(form.secondaryPhone.value) }
          }
        })
      }, resolve)
    })
  }

  validateSecondStep () {
    const { form } = this.state
    return new Promise((resolve, reject) => {
      this.setState({
        form: update(form, {
          password: {
            valid: { $set: validatePassword(form.password.value) === '' },
            invalidMessage: { $set: validatePassword(form.password.value) }
          },
          repassword: {
            valid: { $set: validateRePassword(form.repassword.value, form.password.value) === '' },
            invalidMessage: { $set: validateRePassword(form.repassword.value, form.password.value) }
          },
          tradingName: {
            valid: { $set: validateTradingName(form.tradingName.value) === '' },
            invalidMessage: { $set: validateTradingName(form.tradingName.value) }
          },
          otherProfession: {
            valid: { $set: validateOtherOptionType(form.otherProfession.value) === '' },
            invalidMessage: { $set: validateOtherOptionType(form.otherProfession.value) }
          },
          profession: {
            valid: { $set: userOperationType(form.profession.value) === '' },
            invalidMessage: { $set: userOperationType(form.profession.value) }
          },
          officeAddress: {
            state: {
              valid: { $set: validateState(form.officeAddress.state.value) === '' },
              invalidMessage: { $set: validateState(form.officeAddress.state.value) }
            },
            postcode: {
              valid: { $set: validatePostcode(form.officeAddress.postcode.value) === '' },
              invalidMessage: { $set: validatePostcode(form.officeAddress.postcode.value) }
            },  
          }
        })
      }, resolve)
    })
  }

  allValidFirstStep () {
    const { form } = this.state
    if (
      form.firstName.valid &&
      form.lastName.valid &&
      form.email.valid &&
      form.phone.valid &&
      form.secondaryPhone.valid 
    ) {
      return true
    } else {
      return false
    }
  }

  allValidSecondStep () {
    const { form } = this.state
    const otherProfession = form.profession.value === 'Other (Specify)' ? form.otherProfession.valid : true
    
    if (
      form.password.valid &&
      form.repassword.valid &&
      form.tradingName.valid &&
      form.officeAddress.state.valid &&
      form.officeAddress.postcode.valid &&
      form.profession.valid  &&
      otherProfession
    ) {
      return true
    } else {
      return false
    }

  }

  async goToStep2 () {
    await this.validateFirstStep()
    if (!this.allValidFirstStep()) {
      this.props.showModal({
        message: 'Please fill required fields'
      })
      return
    }
    this.props.showSpinner(true)
    const { email, phone } = convertData(this.state.form)
    let res = await Api.checkUniqueUser({ email, phone })
    this.props.showSpinner(false)
    if (res) {
      if (res.valid) {
        this.setState({
          activeStep: 'step2'
        })
      } else {
        this.props.showModal({
          message: Object.entries(res.errors)[0][1]
        })
      }
    }
  }

  sendAnalytics () {
    if (this.props.cameFromWpSite) {
      ReactGA.event({
        category: 'Click on Register on WordPress site',
        action: 'Successful Registration: Successful Livestock Agent Register',
        label: 'Successful Registration: Successful Livestock Agent Register'
      }, ['WordPress'])
      this.props.changeStateProp('cameFromWpSite', false, 'user')
      this.props.changeStateProp('wpSiteName', 'Farmgate', 'user')
    }
    ReactGA.event({
      category: 'Successful Registration',
      action: 'Successful Registration: Successful Livestock Agent Register',
      label: 'Successful Registration: Successful Livestock Agent Register'
    }, ['WebApp'])
  }

  async handleSubmit () {
    await this.validateSecondStep()
    if (this.allValidFirstStep() && this.allValidSecondStep()) {
      this.saveUser();
    } else {
      this.props.showModal({
        message: 'Please correctly fill required fields'
      })
    }
  }

  async saveUser () {
    try {
      this.props.showSpinner(true)
      let data = convertData(this.state.form)
      
      let postData = _pick(data, [
        'type',
        'firstName',
        'lastName',
        'email',
        'password',
        'tradingName',
        'phone',
        'secondaryPhone',
        'profession',
        'otherProfession',
        'officeAddress'
      ])
      postData.type = 'guest'
      if (postData.phone === '') {
        postData.phone = ''
      }
      if (postData.secondaryPhone === '') {
        postData.secondaryPhone = ''
      }
      let postUser = await Api.postUser(postData)
      this.props.showSpinner(false)
      if (postUser) {
        this.setState({
          finishedRegistration: true
        })
        this.sendAnalytics()
        this.props.changeStateProp('signUpInfo', update(this.props.signUpInfo, {
          seller: { $set: null }
        }), 'main')
        this.props.history.push('/sign-in')
        this.props.showModal({
          message:
            postUser.message ?? '',
        });
      }
    } catch (e) {
      console.error('error', e)
      this.props.showSpinner(false)
    }
  }

  render () {
    const {
      activeStep,
      form,
      userOperationsTypes
    } = this.state
    this.mapStates = this.mapOptions(this.props.locationStates)
   if(userOperationsTypes){
   this.mapTypes = this.mapTypeOptions(userOperationsTypes.professions)
   }
    return (
      <div className='auth-wrapper registration'>
        <Header />
        <div className='background-wrapper'>
          <div className='content'>
            <div className='text-header'>
              <div className='main-header'>Registration</div>
            </div>
            <div className='form-wrapper'>
              {activeStep === 'step1' && (
                <form autoComplete='off'>
                  <NavigationTop activeNav={activeStep} />
                  <FdInput className='input'
                    type='text'
                    placeholder={`First Name${form.firstName.required ? '*' : ''}`}
                    value={form.firstName.value}
                    name='firstName'
                    onChange={this.handleInputChange}
                    onBlur={this.onBlur}
                    validData={form.firstName.valid}
                    warningReason={form.firstName.invalidMessage} />
                  <FdInput className='input'
                    type='text'
                    placeholder={`Last Name${form.lastName.required ? '*' : ''}`}
                    value={form.lastName.value}
                    name='lastName'
                    onChange={this.handleInputChange}
                    onBlur={this.onBlur}
                    validData={form.lastName.valid}
                    warningReason={form.lastName.invalidMessage} />
                  <FdInput className='input'
                    autoComplete='email'
                    type='email'
                    placeholder={`Email${form.email.required ? '*' : ''} (this will be your UserName)`}
                    value={form.email.value}
                    name='email'
                    onChange={this.handleInputChange}
                    onBlur={this.onBlur}
                    validData={form.email.valid}
                    warningReason={form.email.invalidMessage} />
                  <FgPhone
                    className='input'
                    placeholder={`Mobile${form.phone.required ? '*' : ''}`}
                    value={form.phone.value}
                    name='phone'
                    containerClassName='input-container'
                    onChange={this.handleInputChange}
                    onBlur={this.onBlur}
                    validData={form.phone.valid}
                    warningReason={form.phone.invalidMessage} />
                  <FgPhone
                    className='input'
                    placeholder={`Secondary Number${form.secondaryPhone.required ? '*' : ''}`}
                    value={form.secondaryPhone.value}
                    name='secondaryPhone'
                    containerClassName='input-container'
                    onChange={this.handleInputChange}
                    onBlur={this.onBlur}
                    validData={form.secondaryPhone.valid}
                    warningReason={form.secondaryPhone.invalidMessage} />
                  <div className='auth-pagination auth-pagination-button-right no-margin'>
                  <div className='login-line'>
                <span className='already-registered'>
                  Already registered?
                </span>
                <NavLink to='/sign-in' className='sign-in-link nav-link'>
                  Login Now
                </NavLink>
              </div>
                    <button
                      type='button'
                      className='auth-button auth-button-success'
                      onClick={this.goToStep2}
                    >
                      <span>Next</span>
                      <i className='btn-right'>
                        <FontAwesomeIcon icon={faCaretRight} />
                      </i>
                    </button>
                  </div>
                </form>
              )}
              {activeStep === 'step2' && (
                <form>
                  <NavigationTop activeNav={activeStep} />
                  <FdInput className='input'
                    type='text'
                    placeholder={`Trading Name${form.tradingName.required ? '*' : ''}`}
                    value={form.tradingName.value}
                    name='tradingName'
                    onChange={this.handleInputChange}
                    onBlur={this.onBlur}
                    validData={form.tradingName.valid}
                    warningReason={form.tradingName.invalidMessage} />

                    <FgSelect
                      placeholder={`State${form.officeAddress.state.required ? '*' : ''}`}
                      maxMenuHeight={180}
                      options={this.mapStates}
                      value={form.officeAddress.state.value
                        ? this.mapSelected(form.officeAddress.state.value)
                        : null}
                      onChange={this.handleLocationState.bind(null, 'officeAddress')}
                      isSearchable={false}
                      validData={form.officeAddress.state.valid}
                      warningReason={form.officeAddress.state.invalidMessage}
                    />

                  <FdInput className='input'
                    type='text'
                    placeholder={`Postcode${form.officeAddress.postcode ? '*' : ''}`}
                    value={form.officeAddress.postcode.value}
                    name='postcode'
                    onChange={this.handleAddressChange.bind(null, 'officeAddress')}
                    validData={form.officeAddress.postcode.valid}
                    warningReason={form.officeAddress.postcode.invalidMessage}
                    onBlur={this.onAddressBlur.bind(null, 'officeAddress')} />
                 
                  <FdInput className='input'
                    autoComplete='new-password'
                    type='password'
                    placeholder={`Password${form.password.required ? '*' : ''}`}
                    value={form.password.value}
                    name='password'
                    withShowButton
                    onChange={this.handleInputChange}
                    onBlur={this.onBlur}
                    validData={form.password.valid}
                    warningReason={form.password.invalidMessage} />
                  <FdInput className='input'
                    type='password'
                    placeholder={`Confirm Password${form.repassword.required ? '*' : ''}`}
                    value={form.repassword.value}
                    name='repassword'
                    withShowButton
                    onChange={this.handleInputChange}
                    onBlur={this.onBlur}
                    validData={form.repassword.valid}
                    warningReason={form.repassword.invalidMessage} />
                    <FgSelect
                      placeholder={`Type ${form.profession.required ? '*' : ''}`}
                      options={this.mapTypes}
                      value={form.profession.value
                        ? this.mapSelected(form.profession.value)
                        : null}
                      onChange={this.handleUserOperationTypes.bind(null, 'profession')}
                      isSearchable={false}
                      multiple={true}
                      validData={form.profession.valid}
                      warningReason={form.profession.invalidMessage}
                    />
                  <FdInput
                    containerClassName={form.profession.value === 'Other (Specify)' ? '' : 'input-hidden'}
                    className='input'
                    type='text'
                    placeholder={`Specify${form.otherProfession.required ? '*' : ''}`}
                    value={form.otherProfession.value}
                    name='otherProfession'
                    onChange={this.handleInputChange}
                    onBlur={this.onBlur}
                    validData={form.otherProfession.valid}
                    warningReason={form.otherProfession.invalidMessage} 
                  />

                  <div className='auth-pagination auth-columns'>
                    <button
                      type='button'
                      className='auth-button auth-button-success'
                      onClick={() => this.setState({
                        activeStep: 'step1'
                      })}
                    >
                      <i className='btn-left'>
                        <FontAwesomeIcon icon={faCaretLeft} />
                      </i>
                      <span>Back</span>
                    </button>
                    <button
                      type='button'
                      //disabled={!form.acceptedTerms.value}
                      onClick={this.handleSubmit}
                      className='auth-button auth-button-success btn-text-centered'
                    >
                      <span>Register now</span>
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

SignUpGuest.propTypes = {
  showModal: PropTypes.func.isRequired,
  showSpinner: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default withRouter(SignUpGuest)
