import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import { limitTextLength } from '../../utils/index'
import Carousel from '../Carousel/Carousel'
import PrewievModalImages from '../Modals/PrewievModalImages'
import ImageLoader from '../ImageLoader/ImageLoader'

class ImageCarousel extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      selectedLot: null,
      indexImg: 0,
      media: this.props.media,
      imgErrorThumbnail: null,
      imgErrorUrl: null,
      videoError: null
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.imgErrorUrl !== this.state.imgErrorUrl || prevState.videoError !== this.state.videoError) {
      this.setState({
        media: this.state.media.filter(item => item.key !== this.state.imgErrorUrl && item.key !== this.state.videoError)
      })
    }
  }

  openModal (lot, index) {
    this.setState({
      modalIsOpen: true,
      selectedLot: lot,
      indexImg: index
    })
  }
  closeModal () {
    this.setState({
      modalIsOpen: false
    })
  }
  handleError = (e) => {
    this.setState({
      imgErrorThumbnail: e.target.id
    })
  }

  handleVideoError = (e) => {
    this.setState({
      videoError: e.target.id
    })
  }

  getNodes () {
    let nodes = this.props.media.map((file, index) => {
      switch (true) {
        case /image/.test(file.mimeType):
          if (file.thumbnail && !this.state.imgErrorThumbnail) {
            return (
              <div onClick={() => this.openModal(this.props.lot, index)} className='carousel-item' key={file.key}>
                <ImageLoader id={file.key} className='carousel-img thumbnail' src={file.thumbnail} alt='' onError={this.handleError} />
                {file.timestamp && !this.props.preview && <span className='date'>{moment(file.timestamp).format('DD/MM/YYYY')}</span>}
              </div>
            )
          } else {
            return (
              <div onClick={() => this.openModal(this.props.lot, index)} className='carousel-item' key={file.key}>
                <img id={file.key} className='carousel-img' src={file.url} alt='' onError={e => {
                  this.setState({
                    imgErrorUrl: e.target.id
                  })
                }} />
                {file.timestamp && !this.props.preview && <span className='date'>{moment(file.timestamp).format('DD/MM/YYYY')}</span>}
              </div>
            )
          }
        case /video/.test(file.mimeType):
          return (
            <div onClick={() => this.openModal(this.props.lot, index)} className={file.title ? 'carousel-item with-title' : 'carousel-item'} key={index}>
              {(this.props.preview || this.props.showVideoIcon)
                ? (
                  <ImageLoader className='carousel-img video-thumbnail' src={file.thumbnail} alt='' onError={this.handleError} />
                )
                : (
                  <ImageLoader type='video' lot={this.props.lot} file={file} className={file.title ? 'carousel-video with-title' : 'carousel-video'} onError={this.handleVideoError} />
                )}
              {file.title && (<div className='video-title'>{limitTextLength(file.title, 60)}</div>)}
              {file.timestamp && !this.props.preview && <span className='date'>{moment(file.timestamp).format('DD/MM/YYYY')}</span>}
            </div>
          )
        default:
          throw new Error(`Unknown mime type: ${file.mimeType}`)
      }
    })
    if (this.props.preview && !nodes.length) {
      nodes.push(<NoImage key='no-image' kindType={this.props.kindType} />)
    }
    return nodes
  }
  render () {
    return (
      <Carousel
        key={this.props.lotId}
        preview={this.props.preview}
        numberOfSlides={this.props.numberOfSlides}
        carouselClassName={this.props.preview ? 'preview' : null}
      >
        {this.getNodes()}
        <PrewievModalImages
          closeModal={this.closeModal}
          isOpen={this.state.modalIsOpen}
          lot={this.state.selectedLot}
          lotMedia={this.props.media}
          preview={false}
          numberOfSlides={1}
          indexImg={this.state.indexImg} />
      </Carousel>
    )
  }
}

class NoImage extends PureComponent {
  render () {
    switch (this.props.kindType) {
      case 'sheep':
        return <span key='no-image' className='no-image sheep'>No photo/video</span>
      case 'cattle':
        return <span key='no-image' className='no-image cattle'>No photo/video</span>
      case 'goat':
        return <span key='no-image' className='no-image goat'>No photo/video</span>
      default:
        return <span key='no-image' className='no-image'>No photo/video</span>
    }
  }
}

ImageCarousel.propTypes = {
  lotId: PropTypes.string,
  media: PropTypes.array.isRequired,
  preview: PropTypes.bool,
  kindType: PropTypes.string,
  numberOfSlides: PropTypes.number
}

ImageCarousel.defaultProps = {
  preview: false,
  numberOfSlides: 3
}

export default ImageCarousel
