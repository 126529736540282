import React, { useEffect, useState } from 'react'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faCaretDown, faTh } from '@fortawesome/fontawesome-free-solid'
import cattleXsImage from '../../assets/img/cattle-xs.png'
import sheepXsImage from '../../assets/img/sheep-xs.png'
import goatXsImage from '../../assets/img/goat-xs.png'

const FilterKind = ({ categoryChoice, changeCategoryChoice }) => {
  const [isOpen, setIsOpen] = useState(false)
  const categories = generateCategories()

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)
    return () => document.removeEventListener('click', handleDocumentClick)
  })

  const handleDocumentClick = () => {
    if (isOpen) {
      setIsOpen(false)
    }
  }

  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  return (
    <div className={`filter-kind-component${isOpen ? ' opened' : ''}`} >
      <div className='filter-kind-dropdown'>
        <div className={`btn filter-kind-dropdown-btn ${isOpen ? 'btn-opened filter-kind-dropdown-btn-active' : ''}`}
          onClick={() => {
            setIsOpen(!isOpen)
          }}>
          <span>{categoryChoice === 'all' ? 'Category' : capitalize(categoryChoice)}</span>
          <span className='icon-wrapper'><FontAwesomeIcon icon={faCaretDown} /></span>
        </div>
        <div className='dropdown-choices'>
          {categories.map((option, index) => (
            <div key={index} className={`dropdown-choice ${categoryChoice === option.type ? 'selected' : ''}`} onClick={() => {
              setIsOpen(false)
              changeCategoryChoice(option.type)
            }
            }>
              <span>{option.title}</span>
              {option.icon ? React.isValidElement(option.icon) ? option.icon : <img src={option.icon} alt={option.type} className='icon-blackened' /> : null}
            </div>))}
        </div>
      </div>
    </div>
  )
}

// todo: remove it when kinds will be dynamic at server side
const generateCategories = () => [
  {
    type: 'all',
    title: 'All',
    icon: <FontAwesomeIcon icon={faTh} />
  },
  {
    type: 'cattle',
    title: 'Cattle',
    icon: cattleXsImage
  },
  {
    type: 'sheep',
    title: 'Sheep',
    icon: sheepXsImage
  },
  {
    type: 'goat',
    title: 'Goat',
    icon: goatXsImage
  },
  {
    type: 'sheep-abblamb',
    title: 'Sheep-AbbLamb',
    icon: sheepXsImage
  },
]

export default FilterKind
