import EmailModalLot from './EmailModalLot'

import Api from '../../services/api'

class EmailModalForm extends EmailModalLot {
  constructor (props) {
    super(props)
    this.title = 'Email Form'
  }

  async sendRequest (data) {
    let res = await Api.emailManual({
      manualId: this.props.manualId,
      data
    })
    return res
  }
}

export default EmailModalForm
