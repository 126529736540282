import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import SignUpGuest from './SignUpGuest'
import { changeStateProp, showSpinner, showModal } from '../../../actions/index'

const mapStateToProps = (state) => {
  return {
    signUpInfo: state.main.signUpInfo,
    locationStates: state.data.locationStates,
    cameFromWpSite: state.user.cameFromWpSite,
    wpSiteName:    state.user.wpSiteName
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeStateProp, showSpinner, showModal }, dispatch)
}

const SignUpGuestContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpGuest)

export default SignUpGuestContainer
