import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faGavel,
  faBookmark,
  faListAlt,
  faUserEdit,
  faWrench,
  faPhoneSquare,
  faUser
} from '@fortawesome/fontawesome-free-solid'

import logOut from '../../utils/logOut'

class NavBar extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      showMenu: false
    }
    this.toggleMenu = this.toggleMenu.bind(this)
    this.logOut = this.logOut.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
  }
  toggleMenu () {
    this.setState({
      showMenu: !this.state.showMenu
    })
  }
  closeMenu () {
    this.setState({
      showMenu: false
    })
    window.localStorage.clear()
  }
  logOut () {
    if (document.cookie.includes('BackUpServer=true')) {
      if (this.props.onLotForm) {
        this.props.showModal({
          message: 'Please Save Changes',
          buttons: [
            {
              text: 'Cancel',
              onPress: () => {
                logOut.init()
              }
            },
            {
              text: 'Save',
              onPress: () => {
                this.props.changeStateProp('saveAndLogout', true, 'temp')
              }
            }
          ]
        })
      } else if (this.props.location.pathname.includes('/auction-live')) {
        this.props.showModal({
          message: 'Do You Wish to Leave the Live Auction?',
          buttons: [
            {
              text: 'No'
            },
            {
              text: 'Yes',
              onPress: () => {
                logOut.init()
              }
            }
          ]
        })
      } else {
        logOut.init()
      }
    }
  }
  render () {
    return (
      <div>
        <div className={this.state.showMenu ? 'app-navbar show-mobile' : 'app-navbar'}>
          <div className='navbar-wrap'>
            <div className='close'><span onClick={this.toggleMenu} /></div>
            <div className='user-tab'>
              <Link to='/main/profile' className='profile'
                onClick={this.closeMenu}>
                <FontAwesomeIcon className='user-icon' icon={faUser} />
                <span className='name'>
                  {this.props.firstName} {this.props.lastName}
                </span>
              </Link>
              {this.props.userStatus === 'partially-approved' && (<div className='notification'>1</div>)}
            </div>
            <div className='navbar'>
              <Link className={`tab upcoming ${this.props.location.pathname.match('main/upcoming') && this.props.from !== 'watchList' ? 'active' : ''}`}
                to='/main/upcoming' onClick={this.closeMenu}>
                <span className='tab-title'>
                  <FontAwesomeIcon icon={faGavel} />
                  <span className='tab-text'>
                    Upcoming Auctions
                  </span>
                </span>
              </Link>
              <Link className={`tab watchlist ${this.props.location.pathname.match('main/watchlist') || this.props.from === 'watchList' ? 'active' : ''}`}
                to='/main/watchlist' onClick={this.closeMenu}>
                <span className='tab-title'>
                  <FontAwesomeIcon icon={faBookmark} />
                  <span className='tab-text'>
                    Watch List
                  </span>
                </span>
              </Link>
              <Link className={`tab results ${this.props.location.pathname.match('main/results') ? 'active' : ''}`}
                to='/main/results' onClick={this.closeMenu}>
                <span className='tab-title'>
                  <FontAwesomeIcon icon={faListAlt} inverse='true' />
                  <span className='tab-text'>
                    Auction Results
                  </span>
                </span>
              </Link>
              <Link className={`tab manage ${this.props.location.pathname.match('main/manage') ? 'active' : ''}`}
                to='/main/manage' onClick={this.closeMenu}>
                <span className='tab-title'>
                  <FontAwesomeIcon icon={faUserEdit} />
                  <span className='tab-text'>
                    My Admin
                  </span>
                </span>
              </Link>
              <Link className={`tab tools ${this.props.location.pathname.match('main/tools') ? 'active' : ''}`}
                to='/main/tools' onClick={this.closeMenu}>
                <span className='tab-title'>
                  <FontAwesomeIcon icon={faWrench} />
                  <span className='tab-text'>
                    Tools
                  </span>
                </span>
              </Link>
              <Link className={`tab contact ${this.props.location.pathname.match('main/contact') ? 'active' : ''}`}
                to='/main/contact' onClick={this.closeMenu}>
                <span className='tab-title'>
                  <FontAwesomeIcon icon={faPhoneSquare} />
                  <span className='tab-text'>
                    Contact Us
                  </span>
                </span>
              </Link>
              <div className='tab mobile' onClick={this.logOut}>
                <span className='tab-title'>
                  Log Out
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className='burger' onClick={this.toggleMenu} />
        <div className={this.state.showMenu ? 'overlay show' : 'overlay'} onClick={this.toggleMenu} />
      </div>
    )
  }
}

NavBar.propTypes = {
  firstName: PropTypes.string,
  onLotForm: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  changeStateProp: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}
NavBar.defaultProps = {
}

export default NavBar
