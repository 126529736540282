import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  changeStateProp
} from '../../../actions'
import MySounds from './MySounds'

const mapStateToProps = (state) => {
  return {
    bidSound: state.sound.bidSound,
    outBidSound: state.sound.outBidSound,
    wonSound: state.sound.wonSound,
    autoBidSound: state.sound.autoBidSound,
    countdownSound: state.sound.countdownSound
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    changeStateProp
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MySounds)
