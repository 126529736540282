import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { kindByTitle } from '../../../selectors/kindsSelector'
import { lotReofferAuctions } from '../../../selectors/auctionsSelector'
import { hasOtherDrafts } from '../../../selectors/lotsSelector'
import {
  showSpinner,
  mergeStateProp,
  showModal,
  changeStateProp,
  filterStateProp
} from '../../../actions/index'
import EditReoffer from './EditReoffer'

const mapStateToProps = (state, ownProps) => {
  let kind = kindByTitle({
    kinds: state.data.kinds,
    kindTitle: ownProps.match.params.kindTitle
  })
  let auctions = lotReofferAuctions({
    auctions: state.data.auctions,
    kindId: kind ? kind._id : null
  })
  return {
    kind,
    auctions,
    user: state.user,
    hasOtherDrafts: hasOtherDrafts({
      lots: state.data.lots,
      lotId: ownProps.match.params.draftId
    }),
    locationStates: state.data.locationStates,
    locationDistricts: state.data.locationDistricts,
    stockCategories: state.data.stockCategories,
    saveAndLogout: state.temp.saveAndLogout
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    showSpinner,
    mergeStateProp,
    showModal,
    changeStateProp,
    filterStateProp
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditReoffer)
