import { BarChart } from '../BarChart/BarChart'
import React from 'react'
import { ScoreChart } from '../ScoreChart/ScoreChart'
import { BarChartWeights } from '../BarChartWeights/BarChartWeights';

const IndividualAssessment = ({ data, abbLambSale }) => {
  return (
    <div className="lot-documents">
      <div className="row">
        <div className="row-title-wrap">
          <p className="row-title">Individual Assessment</p>
        </div>
        <div className="individual-assessment-container">
          {abbLambSale ? (
            <div>
              <div className="optiweigh-container">
                <BarChartWeights individualAssessmentData={data} />
              </div>
            </div>
          ) : (
            <div>
              <div className="optiweigh-container">
                <ScoreChart fatScoreData={data.scores} />
              </div>
              <div className="divider" />
              <div className="optiweigh-container">
                <BarChart individualAssessmentData={data} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default IndividualAssessment
