import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Toggle from 'react-toggle'

class BoolField extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
    this.onChange = this.onChange.bind(this)
  }
  onChange () {
    this.props.onChange(!this.props.value)
  }
  render () {
    const { invalid, isRequired, title, value, hidden } = this.props
    return (
      <span>
        {!hidden &&
          <div className='form-row'>
            <div className='row-title'>
              <p className={`row-text ${invalid ? 'row-text-invalid' : ''}`}>
                {title}{isRequired ? <span className='asterisk'>*</span> : ''}{title.indexOf('?') > 0 ? '' : ':'}
              </p>
            </div>
            <div className='row-content'>
              <div className='switcher'>
                <p className='row-text'>No</p>
                <Toggle
                  icons={false}
                  checked={value}
                  onChange={this.onChange} />
                <p className='row-text'>Yes</p>
              </div>
            </div>
          </div>
        }
      </span>
    )
  }
}

BoolField.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

BoolField.defaultProps = {
  invalid: false
}

export default BoolField
