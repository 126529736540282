import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faUser
} from '@fortawesome/fontawesome-free-solid'

import logOut from '../../utils/logOut'

class Header extends PureComponent {
  constructor (props) {
    super(props)
    this.logOut = this.logOut.bind(this)
  }
  logOut () {
    if (document.cookie.includes('BackUpServer=true')) {
      if (this.props.onLotForm) {
        this.props.showModal({
          message: 'Please Save Changes',
          buttons: [
            {
              text: 'Cancel',
              onPress: () => {
                // logOut.init()
              }
            },
            {
              text: 'Save',
              onPress: () => {
                this.props.changeStateProp('saveAndLogout', true, 'temp')
              }
            }
          ]
        })
      } else if (this.props.location.pathname.includes('/auction-live')) {
        this.props.showModal({
          message: 'Do You Wish to Leave the Live Auction?',
          buttons: [
            {
              text: 'No'
            },
            {
              text: 'Yes',
              onPress: () => {
                logOut.init()
              }
            }
          ]
        })
      } else {
        logOut.init()
      }
    }
  }
  userStatusToDisplay(status) {

    switch (status) {
      case 'registered':
      case 'registered-updated':
        return 'Guest'
      case 'approved-buyer':
      case 'approved-buyer-updated':
        return 'Buyer'
      case 'approved-buyer-seller':
        return 'Buyer & Seller'
      case 'approved-seller':
        return 'Seller'
      default:
        return 'Guest'

    }

  }

  render () {
    let userStatus =  this.userStatusToDisplay(this.props.userStatus)
    return (
      <header className='app-header'>
        <div className='header-wrap'>
          {this.props.isLive
            ? <div className='logo' />
            : (
              <Link to='/main/upcoming'>
                <div className='logo' />
              </Link>
            )}
            <div className='permisson-level-box'>
            <span>{userStatus.toUpperCase()}</span>
            </div>
          <div className='right'>
            {
              !this.props.isLive &&
              <Link to='/main/profile' className='profile'>
                <FontAwesomeIcon className='user-icon' icon={faUser} />
                <span className='name'>
                  {this.props.firstName} {this.props.lastName}
                </span>
              </Link>
            }
            <span className='bar' />
            <span className='logout' onClick={this.logOut}>Log Out</span>
          </div>
          {this.props.userStatus === 'partially-approved' && (<div className='notification'>1</div>)}
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  isLive: PropTypes.bool.isRequired,
  firstName: PropTypes.string,
  onLotForm: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  changeStateProp: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

Header.defaultProps = {
  isLive: false
}

export default Header
