import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import cowImage from '../../../assets/img/cowauction.png'
import lambImage from '../../../assets/img/lambauction.png'
import goatImage from '../../../assets/img/goat-image.png'
import BackButton from '../../BackButton/BackButton'
import { makePrice } from '../../../utils/Lots'

class ListingFees extends PureComponent {
  componentDidMount () {
    if (this.props.history.action !== 'POP') {
      window.scroll(0, 0)
    }
  }

  getListingPrice (kindType) {
    let kind = this.props.kinds.find(kind => kind.type === kindType)
    return kind ? kind.listingPriceCents : null
  }

  renderReOffersSection () {
    return (
      <div className='section'>
        <div className='title'>
          Re-offers
        </div>
        <div className='body'>
          <span className='explanation'>
            Livestock offered for Auction that do not reach the nominated reserve price may be re-offered
            at a further online Auction without charge. This is providing the livestock are re-offered for
            Auction within the following time limits.
            <br /><br />
            <ul>
              <li>Cattle 21 days</li>
              <li>Lambs 7 days</li>
              <li>Sheep 14 days</li>
              <li>Goats 14 days</li>
            </ul>
            <br />
            Lots will not automatically be re-offered. Sellers must follow the re-offer process in My Admin - My Reoffers
            found in the FarmGate Auctions Mobile App or Website.
            <br /><br />
            All prices are quoted GST-exclusive
          </span>
        </div>
      </div>
    )
  }

  renderListingFeesSection () {
    return (
      <div className='big-section'>
        <div className='title'>
          <img src={cowImage} alt='Sheep' className='icon-blackened' />
            Cattle - {makePrice(this.getListingPrice('cattle'))} per head (Commercial)
        </div>
        <div className='body'>
          <span className='explanation'>
            <p>1-200 head $10.00 per head, single vendor, one auction</p>
            <p>201-400 head, 10% discount, $9 per head, single vendor, one auction</p>
            <p>401-600 head 20% discount, $8 per head, single vendor, one auction</p>
            <p>600 head + 30% discount, $7per head, single vendor, one auction</p>
            <p>$40.00 Bulls (offered in regular weekly auctions)</p>
            <p>2% standard rate if you choose to settle through FarmGate</p>
          </span>
        </div>
        <div className='title'>
          <img src={lambImage} alt='Sheep' className='icon-blackened' />
          Sheep - {makePrice(this.getListingPrice('sheep'))} per head (Commercial)
        </div>
        <div className='body'>
          <span className='explanation'>
            <p>1-1250 head $1 per head, single vendor, one auction</p>
            <p>1251-2250 head 10% discount, $0.90 per head, single vendor, one auction</p>
            <p>2251-3250 head 20% discount, $0.80 per head, single vendor, one auction</p>
            <p>3251 head + 30% discount, $0.70 per head, single vendor, one auction</p>
            <p>$20.00 Rams (offered in regular weekly auctions)</p>
            <p>2% standard rate if you choose to settle through FarmGate</p>
          </span>
        </div>
        <div className='title'>
          <img src={goatImage} alt='Sheep' className='icon-blackened' />
          Goat - {makePrice(this.getListingPrice('goat'))} per head (Commercial)
        </div>
        <div className='body'>
          <span className='explanation'>
            <p>1-1250 head $1 per head, single vendor, one auction</p>
            <p>1251-2250 head 10% discount, $0.90 per head, single vendor, one auction</p>
            <p>2251-3250 head 20% discount, $0.80 per head, single vendor, one auction</p>
            <p>3251 head + 30% discount, $0.70 per head, single vendor, one auction</p>
            <p>$20.00 Bucks (offered in regular weekly auctions)</p>
            <p>2% standard rate if you choose to settle through FarmGate</p>
          </span>
        </div>
        <div className='body-with-margins'>
          <span className='explanation'>
            <p>Note: Listing fees are chargeable as soon as the listing is live on the FarmGate Auctions platform.
            Invoices will be sent the day after auction and are charged to the seller on the number of
            head that appear in the catalogue not the number delivered. The fee covers catalogue distribution
              and sale process.</p>
          </span>
        </div>
      </div>
    )
  }

  renderStudSalesSection () {
    return (
      <div className='section'>
        <div className='title'>
        Stud Sales & Private Sales
        </div>
        <div className='body'>
          <span className='explanation'>
            One-off stand-alone, stud, and special sale set up fee (POA) + per head fee
            <p>Stud and Breeding Stock (Registered, Herd and Commercial)</p>
            <p>$13.00 per head - Heifers and Cows</p>
            <p>$13.00 per head - Bulls</p>
            <p>$7.00 per head - Ewes</p>
            <p>$7.00 per head - Rams</p>
            <p>$7.00 per head - Does</p>
            <p>$7.00 per head - Bucks</p>
          </span>
        </div>
      </div>
    )
  }
  render () {
    return (
      <div className='listing-fees'>
        <h1 className='fg-header'>Listing Fees</h1>
        <p className='fg-paragraph'>{`FarmGate Auctions Listing Fees ${moment().format('YYYY')}. Invoices will be sent the day after auction. Payment Terms are 7 days. Volume discounts apply on application.`}</p>
        <div className='top-btns'>
          <div className='left'>
            <BackButton onPress={() => {
              this.props.history.goBack()
            }} name='Back to Tools' />
          </div>
        </div>
        <div className='listing-fees-container'>
          {this.renderListingFeesSection()}
          {this.renderReOffersSection()}
          {this.renderStudSalesSection()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    kinds: state.data.kinds
  }
}

export default connect(
  mapStateToProps
)(ListingFees)
