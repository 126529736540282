import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import ScrollToTop from '../../ScrollToTop/ScrollToTop'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faTimes,
  faUser,
  faPlusSquare,
  faEdit
} from '@fortawesome/fontawesome-free-solid'

import Api from '../../../services/api'
import FgTable from '../../FgTable/FgTable'
import FdInput from '../../Auth/Fd-input/index'
import FgSelect from '../../FgSelect/FgSelect'
import FgPhone from '../../FgPhone/FgPhone'
import BackButton from '../../BackButton/BackButton'
import {
  validateEmail,
  // validatePic,
  validateTown,
  validatePostcode,
  validateFirstName,
  validateState,
  validateDistrict,
  validateVendorPicPhone
} from '../../../utils/Validators'
import { alphabeticalSort } from '../../../utils'
import { convertData } from '../../../utils/convertData'
import {
  ADDRESS,
  // PIC_REGEXP_SOFT,
  AUSTRALIAN_POSTCODE_SOFT,
  FIRST_LAST_NAME,
  NUMBER_REGEXP
} from '../../../constants'
import { subTitles } from '../../../constants/strings'

class MyFavouritePics extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      pic: {
        vendorName: this.defaultFieldValue(),
        pic: this.defaultFieldValue(),
        vendorPhone: this.defaultFieldValue(''),
        vendorEmail: this.defaultFieldValue(),
        address: this.defaultFieldValue(),
        town: this.defaultFieldValue(),
        postcode: this.defaultFieldValue(),
        state: this.defaultFieldValue(),
        district: this.defaultFieldValue()
      },
      editId: null
    }
    this.isUnmounted = false
    this.handleInputChange = this.handleInputChange.bind(this)
    this.defaultFieldValue = this.defaultFieldValue.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.cancelInputs = this.cancelInputs.bind(this)
    this.select = this.select.bind(this)
    this.handleSelect = this.handleSelect.bind(this)

    this.mapStates = this.mapOptions(this.props.locationStates)
    this.mapDistricts = []
  }

  componentDidMount () {
    this.getPics()
  }

  componentWillUnmount () {
    this.isUnmounted = true
  }

  handleInputChange (event) {
    let { target: { name, value } } = event
    switch (name) {
      case 'vendorName':
        if (value && !FIRST_LAST_NAME.test(value)) {
          return
        }
        break
      case 'vendorPhone':
        if (value && !NUMBER_REGEXP.test(value)) {
          return
        }
        break
      case 'pic':
        value = value.toUpperCase()
        // if (value && !PIC_REGEXP_SOFT.test(value)) {
        //   return
        // }
        break
      case 'postcode':
        if (value && !AUSTRALIAN_POSTCODE_SOFT.test(value)) {
          return
        }
        break
      case 'town':
        value = value.toUpperCase()
        if (value && !ADDRESS.test(value)) {
          return
        }
        break
      default:
        break
    }

    this.setState({
      pic: update(this.state.pic, {
        [name]: {
          value: { $set: value }
        }
      })
    }, () => this.validate(name))
  }

  handleSubmit () {
    let { pic, editId } = this.state
    let updatedForm
    updatedForm = update(this.state.pic, {
      vendorName: {
        valid: { $set: validateFirstName(pic.vendorName.value, { required: false }) === '' }
      },
      vendorEmail: {
        valid: { $set: validateEmail(pic.vendorEmail.value, { required: false }) === '' }
      },
      // pic: {
      //   valid: { $set: validatePic(pic.pic.value, pic.state.value, { required: false }) === '' },
      //   invalidMessage: { $set: validatePic(pic.pic.value, pic.state.value, { required: false }) }
      // },
      vendorPhone: {
        valid: { $set: validateVendorPicPhone(pic.vendorPhone.value, { required: false }) === '' }
      },
      town: {
        valid: { $set: validateTown(pic.town.value, { required: false }) === '' }
      },
      postcode: {
        valid: { $set: validatePostcode(pic.postcode.value, { required: false }) === '' }
      },
      state: {
        valid: { $set: validateState(pic.state.value, { required: false }) === '' }
      },
      district: {
        valid: { $set: validateDistrict(pic.district.value, { required: false }) === '' }
      }
    })
    this.setState({ pic: updatedForm },
      () => {
        if (this.allValid()) {
          editId ? this.putPic() : this.postPic()
        } else {
          if (this.state.pic.pic.invalidMessage) {
            this.props.showModal({
              title: 'Validation Error',
              message: this.state.pic.pic.invalidMessage
            })
          } else if (this.state.pic.vendorPhone.invalidMessage) {
            this.props.showModal({
              message: this.state.pic.vendorPhone.invalidMessage
            })
          } else if (this.state.pic.vendorEmail.invalidMessage) {
            this.props.showModal({
              message: this.state.pic.vendorEmail.invalidMessage
            })
          } else if (this.state.pic.postcode.invalidMessage) {
            this.props.showModal({
              title: 'Validation Error',
              message: this.state.pic.postcode.invalidMessage
            })
          } else {
            this.props.showModal({
              title: 'Validation Error',
              message: 'Please correctly provide at least 1 field'
            })
          }
        }
      })
  }

  validate (name) {
    let validateResult
    switch (name) {
      case 'vendorName':
        validateResult = validateFirstName(this.state.pic[name].value, { required: false })
        break
      case 'vendorEmail':
        validateResult = validateEmail(this.state.pic[name].value, { required: false })
        break
      case 'vendorPhone':
        validateResult = validateVendorPicPhone(this.state.pic[name].value, { required: false })
        break
      // case 'pic':
      //   validateResult = validatePic(this.state.pic[name].value, this.state.pic.state.value, { required: false })
      //   break
      case 'town':
        validateResult = validateTown(this.state.pic[name].value, { required: false })
        break
      case 'postcode':
        validateResult = validatePostcode(this.state.pic[name].value, { required: false })
        break
      case 'state':
        validateResult = validateState(this.state.pic[name].value, { required: false })
        break
      case 'district':
        validateResult = validateDistrict(this.state.pic[name].value, { required: false })
        break
      default:
        break
    }
    if (validateResult !== undefined) {
      this.setState({
        pic: update(this.state.pic, {
          [name]: {
            valid: { $set: validateResult === '' },
            invalidMessage: { $set: validateResult }
          }
        })
      })
    }
  }

  allValid () {
    const { pic } = this.state
    let fields = [
      pic.vendorName,
      pic.pic,
      pic.vendorEmail,
      pic.address,
      pic.town,
      pic.postcode,
      pic.state,
      pic.district,
      pic.vendorPhone
    ]
    let hasFilled = fields.some(field => {
      return field === pic.vendorPhone
        ? field.value && field.value !== ''
        : field.value
    })
    let hasAllValid = fields.every(field => field.valid)
    return (
      hasFilled &&
      hasAllValid
    )
  }

  defaultFieldValue (value = '') {
    return {
      value,
      valid: true,
      invalidMessage: ''
    }
  }

  clearFields () {
    this.setState({
      pic: update(this.state.pic, {
        vendorName: { $set: this.defaultFieldValue() },
        pic: { $set: this.defaultFieldValue() },
        vendorPhone: { $set: this.defaultFieldValue('') },
        vendorEmail: { $set: this.defaultFieldValue() },
        address: { $set: this.defaultFieldValue() },
        town: { $set: this.defaultFieldValue() },
        postcode: { $set: this.defaultFieldValue() },
        state: { $set: this.defaultFieldValue() },
        district: { $set: this.defaultFieldValue() }
      })
    })
  }

  select (name, item) {
    this.setState({
      pic: update(this.state.pic, {
        [name]: { value: { $set: item } }
      })
    })
  }

  mapOptions (options, isSortable) {
    if (isSortable) {
      options = alphabeticalSort(options)
    }
    return options.map(option => {
      return {
        value: option,
        label: option
      }
    })
  }

  mapSelected (option) {
    return {
      value: option,
      label: option
    }
  }

  handleSelect (type, item, { action }) {
    if (action === 'select-option') {
      switch (type) {
        case 'state':
          if (this.state.pic.state.value !== item.value) {
            this.mapDistricts = this.mapOptions(this.props.locationDistricts[item.value], true)
            this.setState({
              pic: update(this.state.pic, {
                [type]: {
                  value: { $set: item.value },
                  valid: { $set: validateState(item.value) === '' }
                },
                district: {
                  value: { $set: '' },
                  valid: { $set: validateDistrict(this.state.pic.district.value, { required: false }) === '' }
                }
              })
            }, () => {
              // this.setState({
              //   pic: update(this.state.pic, {
              //     pic: {
              //       valid: { $set: validatePic(this.state.pic.pic.value, item.value, { required: false }) === '' },
              //       invalidMessage: { $set: validatePic(this.state.pic.pic.value, item.value) }
              //     }
              //   })
              // })
            })
          }
          break
        case 'district':
          this.setState({
            pic: update(this.state.pic, {
              [type]: {
                value: { $set: item.value },
                valid: { $set: validateDistrict(item.value) === '' }
              }
            })
          })
          break
        default:
          break
      }
    }
  }

  startEdit (picId) {
    let { pics, editId } = this.props
    if (editId) {
      return
    }
    let pic = pics.find(pic => pic._id === picId)
    if (!pic) {
      return
    }
    this.setState({
      pic: update(this.state.pic, {
        vendorName: { $set: this.defaultFieldValue(pic.vendorName) },
        pic: { $set: this.defaultFieldValue(pic.pic) },
        vendorPhone: { $set: this.defaultFieldValue(pic.vendorPhone) },
        vendorEmail: { $set: this.defaultFieldValue(pic.vendorEmail) },
        address: { $set: this.defaultFieldValue(pic.address) },
        town: { $set: this.defaultFieldValue(pic.town) },
        postcode: { $set: this.defaultFieldValue(pic.postcode) },
        state: { $set: this.defaultFieldValue(pic.state) },
        district: { $set: this.defaultFieldValue(pic.district) }
      }),
      editId: pic._id
    })
  }

  async getPics () {
    this.props.showSpinner(true)
    let res = await Api.getPics()
    if (this.isUnmounted) {
      this.props.showSpinner(false)
      return
    }
    if (res) {
      this.props.changeStateProp('pics', res.pics, 'user')
    }
    this.props.showSpinner(false)
  }

  async deletePic (picId) {
    this.props.showSpinner(true)
    let res = await Api.deletePic(picId)
    if (res) {
      this.props.filterStateProp('pics', item => item._id !== picId, 'user')
    }
    this.props.showSpinner(false)
  }

  async postPic () {
    let { pic } = this.state
    this.props.showSpinner(true)
    // if (pic.vendorPhone.value === '610') {
    //   pic.vendorPhone.value = ''
    // }
    let res = await Api.postPic(convertData(pic))
    if (res) {
      this.props.mergeStateProp('pics', [res.pic], 'user')
    }
    this.clearFields()
    this.props.showSpinner(false)
  }

  async putPic () {
    let { pic, editId } = this.state
    if (!editId) {
      return
    }
    this.props.showSpinner(true)
    let res = await Api.putPic(editId, convertData(pic))
    if (res) {
      this.props.mergeStateProp('pics', [res.pic], 'user')
    }
    this.setState({
      editId: null
    })
    this.clearFields()
    this.props.showSpinner(false)
  }

  async cancelInputs () {
    this.setState({
      editId: null
    })
    this.clearFields()
  }

  render () {
    const { pic, editId } = this.state
    const { pics } = this.props
    return (
      <ScrollToTop xCoord={0} yCoord={0}>
      <div className='submitted-container my-favpics-container'>
        <h1 className='fg-header'>My Favourite PICs</h1>
        <p className='fg-paragraph'>{subTitles.MY_FAVOURITE_PICS}</p>
        <BackButton onPress={() => {
          this.props.history.goBack()
        }} name='Back to My Admin' />
        <div className='my-profile-container'>
          <div className='info-items'>
            <div className='info-item'>
              <div className='cd-header'>
                <FontAwesomeIcon className='icons-style' icon={faUser} />
                <div className='cd-title'>Add a Favourite PIC</div>
              </div>
              <div className='cd-data'>
                <div className='columns'>
                  <div className='rows'>
                    <p className={`titles ${pic.vendorName.valid}`}>PIC Contact Name</p>
                    <FdInput
                      className='inputs'
                      type='text'
                      placeholder='PIC Contact Name'
                      name='vendorName'
                      value={pic.vendorName.value}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className='rows'>
                    {/* <p className={`titles ${pic.pic.valid}`}>Vendor PIC</p> */}
                    <p className={`titles`}>PIC Number</p>
                    <FdInput
                      className='inputs'
                      type='text'
                      placeholder='PIC Number'
                      name='pic'
                      value={pic.pic.value}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className='rows'>
                    <p className={`titles ${pic.vendorPhone.valid}`}>Contact Phone</p>
                    <FgPhone
                      className='inputs'
                      placeholder='Contact Phone'
                      name='vendorPhone'
                      containerClassName='input-container'
                      value={pic.vendorPhone.value}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className='rows'>
                    <p className={`titles ${pic.vendorEmail.valid}`}>Contact Email</p>
                    <FdInput
                      className='inputs'
                      type='text'
                      placeholder='Contact Email'
                      name='vendorEmail'
                      value={pic.vendorEmail.value}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  {/* TODO client request - hide District field for now */}
                  {/* <div className='rows hide-on-mobile'>
                    <div className='helpers' />
                    <div className='helpers' />
                  </div> */}
                </div>
                <div className='columns'>
                  <div className='rows'>
                    <p className={`titles ${pic.address.valid}`}>Address</p>
                    <FdInput
                      className='inputs'
                      type='text'
                      placeholder='Address'
                      name='address'
                      value={pic.address.value}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className='rows'>
                    <p className={`titles ${pic.town.valid}`}>Town</p>
                    <FdInput
                      className='inputs'
                      type='text'
                      placeholder='Town'
                      name='town'
                      value={pic.town.value}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className='rows'>
                    <p className={`titles ${pic.postcode.valid}`}>Postcode</p>
                    <FdInput
                      className='inputs'
                      type='text'
                      placeholder='Postcode'
                      name='postcode'
                      value={pic.postcode.value}
                      onChange={this.handleInputChange}
                    />
                  </div>
                  <div className={`rows ${this.state.pic.state.value && 'selected'}`}>
                    <p className={`titles ${pic.state.valid}`}>State</p>
                    <FgSelect
                      placeholder='Select'
                      options={this.mapStates}
                      value={this.mapSelected(this.state.pic.state.value)}
                      isSearchable={false}
                      onChange={this.handleSelect.bind(null, 'state')}
                    />
                  </div>
                  {/* TODO client request - hide District field for now */}
                  {/* <div className={`rows ${this.state.pic.district.value && 'selected'}`}>
                    <p className={`titles ${pic.district.valid}`}>District</p>
                    <FgSelect
                      placeholder='Select'
                      options={this.mapDistricts}
                      value={this.mapSelected(this.state.pic.district.value)}
                      isSearchable={false}
                      onChange={this.handleSelect.bind(null, 'district')}
                      isDisabled={!this.state.pic.state.value}
                    />
                  </div> */}
                </div>
                {
                  editId
                    ? (
                      <div className='action-row action-row-multiple'>
                        <div className='additional-information-inputs'>
                          <div className='auth-file-upload pointer' onClick={this.cancelInputs}>
                            <span className='auth-upload-text'>Cancel</span>
                            <span className='auth-upload-button auth-upload-button-danger'><FontAwesomeIcon icon={faTimes} style={{ color: 'white' }} /></span>
                          </div>
                        </div>
                        <div className='additional-information-inputs'>
                          <div className='auth-file-upload pointer' onClick={this.handleSubmit}>
                            <span className='auth-upload-text'>Save PIC</span>
                            <span className='auth-upload-button'><FontAwesomeIcon icon={faPlusSquare} style={{ color: 'white' }} /></span>
                          </div>
                        </div>
                      </div>
                    )
                    : (
                      <div className='action-row'>
                        <div className='additional-information-inputs'>
                          <div className='auth-file-upload pointer' onClick={this.handleSubmit}>
                            <span className='auth-upload-text'>Add PIC</span>
                            <span className='auth-upload-button'><FontAwesomeIcon icon={faPlusSquare} style={{ color: 'white' }} /></span>
                          </div>
                        </div>
                      </div>
                    )
                }
              </div>
              <div className='cd-data table'>
                <FgTable
                  data={pics}
                  defaultPageSize={10}
                  columns={[
                    {
                      Header: 'My PICs',
                      columns: [
                        {
                          Header: 'Name',
                          accessor: 'vendorName'
                        },
                        {
                          Header: 'PIC',
                          accessor: 'pic'
                        },
                        {
                          Header: 'Phone',
                          accessor: 'vendorPhone'
                        },
                        {
                          Header: 'Email',
                          accessor: 'vendorEmail'
                        },
                        {
                          Header: 'Address',
                          accessor: 'address'
                        },
                        {
                          Header: 'Town',
                          accessor: 'town'
                        },
                        {
                          Header: 'Pcode',
                          accessor: 'postcode',
                          maxWidth: 70
                        },
                        {
                          Header: 'State',
                          accessor: 'state',
                          maxWidth: 70
                        },
                        // {
                        //   Header: 'District',
                        //   accessor: 'district'
                        // },
                        {
                          Header: 'Edit',
                          sortable: false,
                          maxWidth: 70,
                          className: 'action-cell',
                          Cell: ({ original }) => {
                            return (<FontAwesomeIcon icon={faEdit} className='action-btn action-btn-edit' onClick={() => this.startEdit(original._id)} />)
                          }
                        },
                        {
                          Header: 'Delete',
                          sortable: false,
                          maxWidth: 70,
                          className: 'action-cell',
                          Cell: ({ original }) => {
                            return (<FontAwesomeIcon icon={faTimes} className='action-btn action-btn-delete' onClick={() => this.deletePic(original._id)} />)
                          }
                        }
                      ]
                    }
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
       </ScrollToTop>
    )
  }
}

MyFavouritePics.propTypes = {
  pics: PropTypes.array.isRequired,
  showModal: PropTypes.func.isRequired,
  showSpinner: PropTypes.func.isRequired,
  locationStates: PropTypes.array.isRequired
}

MyFavouritePics.defaultProps = {}

export default MyFavouritePics
