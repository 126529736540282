import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'

import FormField from '../FormField'
import FgSelect from '../../FgSelect/FgSelect'
import { isRequiredWeaningDetails } from '../../../utils/WeaningDetails'

class WeaningField extends PureComponent {
  onChange = (option, { action }) => {
    switch (action) {
      case 'select-option':
        this.props.onChange(option.value)
        break
      default:
        break
    }
  }
  render () {
    return (
      <Fragment>
        <WeaningSelect
          title={this.props.formElement.title}
          value={this.props.formElement.value}
          invalid={this.props.formElement.invalid}
          isRequired={isRequiredWeaningDetails(this.props.draftLot.details)}
          onChange={this.onChange}
        />
        {
          this.props.formElement.value
            ? (
              <div className='rfb-rows'>
                {
                  this.props.formElement.values[0].map((repeatItem, index) =>
                    <FormField
                      {...this.props}
                      key={index}
                      index={index}
                      path={`${this.props.path}.values.0.${index}`}
                      formElement={repeatItem}
                    />
                  )
                }
              </div>
            )
            : null
        }
      </Fragment>
    )
  }
}

class WeaningSelect extends PureComponent {
  options = [
    {
      value: true,
      label: 'Yes'
    },
    {
      value: false,
      label: 'No'
    }
  ]
  mapSelected = memoize((value) => {
    if (typeof value === 'boolean') {
      return {
        value: value,
        label: value ? 'Yes' : 'No'
      }
    } else {
      return null
    }
  })
  render () {
    const { isRequired, value } = this.props
    return (
      <div className='form-row'>
        <div className='row-title'>
          <p
            className={`row-text ${this.props.invalid ? 'row-text-invalid' : ''}`}>
            {this.props.title}{this.props.title.indexOf('?') > 0 ? '' : ':'}{isRequired ? <span className='asterisk'>*</span> : ''}
          </p>
        </div>
        <div className='row-content'>
          <FgSelect
            placeholder='Select item'
            options={this.options}
            value={this.mapSelected(value)}
            isSearchable={false}
            onChange={this.props.onChange}
          />
        </div>
      </div>
    )
  }
}

WeaningSelect.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.bool,
  invalid: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

WeaningSelect.defaultProps = {
  invalid: false
}

WeaningField.propTypes = {
  formElement: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

WeaningField.defaultProps = {
  isRequired: false
}

export default WeaningField
