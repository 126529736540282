import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import Toggle from 'react-toggle'
import updateHelper from 'immutability-helper'
import memoize from 'memoize-one'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faThLarge,
  faThList,
  faBars
} from '@fortawesome/fontawesome-free-solid'
import { Link } from 'react-router-dom'

import * as Socket from '../../services/socket'
import * as LotUtils from '../../utils/Lots'
import Api from '../../services/api'
import ServerTime from '../../services/ServerTime'
import LiveHeaderContainer from './LiveHeader/LiveHeaderContainer'
import RenderLotsContainer from './RenderLots/RenderLotsContainer'
import BiddingContainer from './Bidding/BiddingContainer'
import LotInfo from './LotInfo/LotInfoContainer'
import LoadingContainer from '../Loading/LoadingContainer'
import WatchButton from '../WatchButton/WatchButtonContainer'
import makeSound from '../../services/sound'
import { isIpad, isSafari } from '../../utils/DeviceDetector'
import { BASE_URL } from '../../config'
import AuctionLiveService, { mesureConnectionSpeed } from './AuctionLive.service'
import { isIos } from '../../utils/DeviceDetector'

const sounds = {
  silence: require('../../assets/audio/silence.mp3')
}

let audio = document.createElement('audio')

let subNavItems = AuctionLiveService.subNavItems()
let subNavItemsSequential = AuctionLiveService.subNavItemsSequential()
let subNavItemsSynchronous = AuctionLiveService.subNavItemsSynchronous()
let subNavItemsPublic = AuctionLiveService.subNavItemsPublic()
let subNavItemsSequentialPublic = AuctionLiveService.subNavItemsSequentialPublic()

class AuctionLiveComponent extends PureComponent {
  constructor (props) {
    super(props)
    document.title = 'FARMGATE LIVE AUCTION'
    this.state = {
      auction: {},
      selectedLotId: null,
      navItems: this.props.loggedIn ? subNavItems : subNavItemsPublic,
      selectedNavItem: 'all-lots',
      showInfo: false,
      loaded: false,
      showDetailsPopup: false,
      fixedHeader: false,
      isSound: !isSafari(),
      soundEvents: [],
      outbid: false,
      autobid: false,
      windowWidth: window.innerWidth,
      gridState: window.localStorage.getItem('gridState') ? window.localStorage.getItem('gridState') : 'default',
      offsetMs: 0,
      synchronousClosed: false,
      isAuctionTabsAddedAsNavItems : false
    }
    this.syncRetryDelayMs = 3000
    this.socketsLoaded = false
    this.liveContainer = React.createRef()
    this.timeSyncTimeout = null
    this.timeSyncIntervalMs = 20000
    this.joinRoom = this.joinRoom.bind(this)
    this.updateGridState = this.updateGridState.bind(this)
    this.selectLot = this.selectLot.bind(this)
    this.onReconnect = this.onReconnect.bind(this)
    this.onResize = this.onResize.bind(this)
    this.onScroll = this.onScroll.bind(this)
    this.closeInfoMobile = this.closeInfoMobile.bind(this)
    this.showDetailsPopup = this.showDetailsPopup.bind(this)
    this.getValidLots = memoize(this.getValidLots)
    this.toggleSound = this.toggleSound.bind(this)

    document.querySelector('body').style.width = '100%'
    if (!isIpad()) {
      document.querySelector('#root').style.zoom = '80%'
    }

    if (document.cookie.includes('BackUpServer=true')) {
      this.sync()
    }
  }

  async sync () {
    // WARNING: this async function restarts whenever it fails
    // and only returns after successfull sync
    let res = await Api.getServerTime()
    if (res && !res.isError) {
      let clientTime = new Date()
      let serverTime = new Date(res)
      if (serverTime) {
        this.setState({ offsetMs: serverTime.valueOf() - clientTime.valueOf() })
      }
    } else {
      await new Promise(resolve => setTimeout(resolve, this.syncRetryDelayMs))
      await this.sync()
    }
  }

  componentDidMount () {
    this.checkMagicLink()
    this.handleIpadScroll()
    this.syncServerTime()
    this.getInitialData()
    this.initializeSoundsInLocalStorage()
    this.props.changeStateProp('isSound', this.state.isSound, 'temp')
    document.querySelector('body').style.backgroundColor = '#cccccc'

    window.addEventListener('online', this.onReconnect)
    window.addEventListener('visibilitychange', this.onReconnect)
    window.addEventListener('resize', this.onResize)
    this.liveContainer.current.addEventListener('scroll', this.onScroll)

    this._auctionUpdateCallback = (update) => {
      let auction = this.props.auctions.find(item => item._id === update.doc._id)
      if (auction) {
        this.props.mergeStateProp('auctions', [update.doc], 'data')
      }
    }

    this._lotUpdateCallback = (update) => {
      if (this.state.auction.auctionType === 'synchronous' && update.doc && update.doc.state === 'closed') {
        if (this.state.synchronousClosed === false) {
          this.setState({ synchronousClosed: true })
        }
      }
      let lot = this.props.lots.find(item => item._id === update.doc._id)
      update.doc.previousBidCents = lot.currentBidCents
      let newLot = {
        ...update.doc
      }
      if (update.changeStatus) {
        newLot.status = update.changeStatus
      }
      if (lot) {
        this.props.mergeStateProp('lots', [newLot], 'data')
      }
    }
    this._userUpdateCallback = (update) => {
      if (update.status || update.type || update.isRequestedAsBuyer || update.isRequestedAsSeller || update.stockTypes || update.approvedStockTypes) {
        let userData = updateHelper(this.props.user.data, {
          status: { $set: update.status || this.props.user.data.status },
          type: { $set: update.type || this.props.user.data.type },
          isRequestedAsBuyer: { $set: update.isRequestedAsBuyer || this.props.user.data.isRequestedAsBuyer },
          isRequestedAsSeller: { $set: update.isRequestedAsSeller || this.props.user.data.isRequestedAsSeller },
          stockTypes: { $set: update.stockTypes || this.props.user.data.stockTypes },
          approvedStockTypes: { $set: update.approvedStockTypes || this.props.user.data.approvedStockTypes },
        });
        this.props.changeStateProp("data", userData, "user");
      }
      if (update.watchList) {
        let userData = updateHelper(this.props.user.data, {
          watchList2: { $set: update.watchList2 }
        })
        this.props.changeStateProp('data', userData, 'user')
      }
      if (update.bid) {
        this.props.mergeStateProp('bids', [update.bid], 'user')
      }
      if (update['bid-delete']) {
        this.props.filterStateProp('bids', item => item.lot !== update['bid-delete'].lot, 'user')
      }
      if (update.autobid) {
        this.props.mergeStateProp('autobids', [update.autobid], 'user')
      }
      if (update['autobid-delete']) {
        this.props.filterStateProp('autobids', item => item.lot !== update['autobid-delete'].lot, 'user')
      }
      if (update.lot) {
        let lot = this.props.lots.find(item => item._id === update.lot._id)
        if (lot) {
          this.props.mergeStateProp('lots', [update.lot], 'data')
        }
      }
      if (update['lot-delete']) {
        this.props.filterStateProp('lots', item => item._id !== update['lot-delete']._id, 'data')
      }
      if (update.changeOnlineState) {
        if (document.visibilityState === 'visible') {
          if (document.cookie.includes('BackUpServer=true')) {
            Api.putOnlineState('ONLINE')
          }
        }
      }
    }

    this._auctionDeleteCallback = (auctionId) => {
      this.props.filterStateProp('auctions', item => item._id !== auctionId, 'data')
    }
    this._lotDeleteCallback = async (lotId) => {
      let { selectedLotId } = this.state
      if (selectedLotId === lotId) {
        await new Promise((resolve, reject) => {
          this.setState({
            selectedLotId: null
          }, () => resolve())
        })
      }
      this.props.filterStateProp('lots', item => item._id !== lotId, 'data')
    }
    this._eventsUpdateCallback = async (update) => {
      if (update.event && update.data.bid.auction === this.props.auction._id) {
        if (this.state.isSound) {
          makeSound(update)
          let sound = makeSound(update)
          this.setSound(sound)
        }
      }
    }
  }

  checkMagicLink() {
    if (isIos() && window.location.href.includes('mobile')) {
      window.location.href = 'https://apps.apple.com/au/app/farmgate-auctions/id1469350158'
      return
    }
    let token = window.location.hash.split('/').reverse()[0]
    if (token.length > 15) {
      setTimeout(() => {
        this.props.showSpinner(true)
        if (this.props.loggedIn) {
          Api.userLogout()
        }
        Api.getUserMagicLink(token, { scene: 'sign in' })
          .then(resp => {
            if (resp === 401) {
              // Invalid Link
              this.props.showSpinner(false)
              return
            } else if (resp === 403) {
              this.props.showSpinner(false)
              return
            } else if (resp) {
              this.props.changeStateProp('hasData', false, 'temp')
              // if (!this.state.form.rememberMe) {
              //   window.sessionStorage.setItem('remember-session', true)
              // }
              // this.props.changeStateProp('rememberMe', this.state.form.rememberMe, 'user')
              this.props.changeStateProp('data', resp.user, 'user')
              this.props.changeStateProp('loggedIn', true, 'user')
              window.location.href = window.location.href.split('/mobile')[0]
            }
            this.props.showSpinner(false)
          })
      }, 0)
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (!this.isValidUrl()) {
      window.location.reload()
      return
    }
    if (prevProps.auction && prevProps.auction.lotCount !== this.props.auction.lotCount) {
      Api.getLotsByAuction(this.props.match.params.auctionNumber)
        .then(data => {
          if (data) {
            this.props.mergeStateProp('lots', data.lots, 'data')
          }
        })
    }

    if (prevProps.watchButtonLoading !== this.props.watchButtonLoading && this.props.watchButtonLoading) {
      this.props.showSpinner(true)
    }
    if (prevProps.watchButtonLoading !== this.props.watchButtonLoading && !this.props.watchButtonLoading) {
      this.props.showSpinner(false)
    }
  }

  static getDerivedStateFromProps (props) {
    if (!props.isServerLive) {
      props.history.push('/')
    }
  }

  componentWillUnmount () {
    document.querySelector('body').style.backgroundColor = '#FFF'
    window.removeEventListener('visibilitychange', this.onReconnect)
    window.removeEventListener('online', this.onReconnect)
    window.removeEventListener('resize', this.onResize)
    this.liveContainer.current.removeEventListener('scroll', this.onScroll)

    Socket.detach(Socket.BIDS_NAMESPACE, 'lot-update', 'live')

    Socket.detach(Socket.CATALOG_NAMESPACE, 'auction', 'live')
    Socket.detach(Socket.CATALOG_NAMESPACE, 'lot', 'live')
    Socket.detach(Socket.CATALOG_NAMESPACE, 'auction-delete', 'live')
    Socket.detach(Socket.CATALOG_NAMESPACE, 'lot-delete', 'live')
    Socket.detach(Socket.EVENTS_NAMESPACE, 'event', 'live')

    Socket.detach(Socket.USER_NAMESPACE, 'user-update', 'live')
    document.querySelector('body').style.position = 'relative'
    document.querySelector('html').style.position = 'relative'
    document.querySelector('#root').style.zoom = '100%'
    if (!this.props.loggedIn || (this.props.user && !this.props.user.data)) {
      Socket.leaveRoomAnonymous(Socket.ANONYMOUS_USER_NAMESPACE, this.state.auction ? this.state.auction._id : '')
    }
  }

  attachSocketListeners () {
    return new Promise((resolve, reject) => {
      Socket.disconnectAll()
      Socket.connect(Socket.CATALOG_NAMESPACE, () => {
        Socket.attach(Socket.CATALOG_NAMESPACE, 'auction', 'live', this._auctionUpdateCallback)
        Socket.attach(Socket.CATALOG_NAMESPACE, 'lot', 'live', this._lotUpdateCallback)
        Socket.attach(Socket.CATALOG_NAMESPACE, 'auction-delete', 'live', this._auctionDeleteCallback)
        Socket.attach(Socket.CATALOG_NAMESPACE, 'lot-delete', 'live', this._lotDeleteCallback)
      })

      Socket.connect(Socket.USER_NAMESPACE, () => {
        Socket.attach(Socket.USER_NAMESPACE, 'user-update', 'live', this._userUpdateCallback)
      })

      Socket.connect(Socket.EVENTS_NAMESPACE, () => {
        Socket.attach(Socket.EVENTS_NAMESPACE, 'event', 'live', this._eventsUpdateCallback)
      })
      Socket.connect(Socket.BIDS_NAMESPACE, () => {
        this.joinRoom(this.props.user && !!this.props.user.data)
        // if sockets loaded later than UI, update all data
        if (this.state.loaded && !this.socketsLoaded) {
          this.onReconnect()
        }
        this.socketsLoaded = true
        Socket.attach(Socket.BIDS_NAMESPACE, 'lot-update', 'live', this._lotUpdateCallback)
        resolve()
      })

      setTimeout(() => {
        reject(new Error('Socket connection timeout'))
      }, 20000)
    })
  }

  handleIpadScroll () {
    if (window.innerWidth > 550 && window.innerHeight > 420) {
      document.querySelector('body').style.position = 'fixed'
      document.querySelector('html').style.position = 'fixed'
    } else {
      document.querySelector('body').style.position = 'static'
      document.querySelector('html').style.position = 'static'
    }
  }

  checkConectionSpeed = async () => {
    const networkSpeed = await mesureConnectionSpeed()
    if (networkSpeed && networkSpeed.speed < 0.2) {
      this.props.showSlowInternetConnection()
    } else {
      this.props.hideSlowInternetConnection()
    }
  };

  async syncServerTime () {
    this.timeSyncTimeout = setTimeout(async () => {
      await ServerTime.sync()
      this.syncServerTime()
      this.checkConectionSpeed()
    }, this.timeSyncIntervalMs)
  }

  isValidUrl () {
    if (!this.state.loaded) {
      return true
    }
    return Boolean(this.props.auction && this.props.match.params.auctionNumber === this.props.auction.searchNumber)
  }

  onResize () {
    this.handleIpadScroll()
    if (window.innerWidth < 900 && this.state.showDetailsPopup) {
      this.setState({
        showDetailsPopup: false
      })
    } else {
      if (window.innerWidth !== this.state.windowWidth) {
        this.liveContainer.current.scrollTop = 0
        this.liveContainer.current.classList.remove(['fixed'])
      }
      this.setState({
        windowWidth: window.innerWidth
      })
    }
  }

  onScroll () {
    if (this.liveContainer.current.scrollTop >= 200 && window.innerWidth < 900) {
      if (!this.state.fixedHeader) {
        this.setState({
          fixedHeader: true
        })
      }
    } else {
      if (this.state.fixedHeader) {
        this.setState({
          fixedHeader: false
        })
      }
    }
  }

  initializeSoundsInLocalStorage = () => {
    const { bidSound, outBidSound, wonSound, autoBidSound, countdownSound } = this.props
    window.localStorage.setItem('bidSound', bidSound)
    window.localStorage.setItem('outBidSound', outBidSound)
    window.localStorage.setItem('wonSound', wonSound)
    window.localStorage.setItem('autoBidSound', autoBidSound)
    window.localStorage.setItem('countdownSound', countdownSound)
  }

  async getInitialData () {
    this.props.showSpinner(true)
    let res
    if (this.props.loggedIn) {
      res = await Api.getUser()
    } else {
      res = {
        firstName: 'test',
        lastName: 'user'
      }
    }
    if (!res) {
      return
    }
    this.props.changeStateProp('data', res.user, 'user')
    if (this.props.loggedIn) {
      res = [
        await Api.getKinds(),
        await Api.getAuction(this.props.match.params.auctionNumber)
      ]
    } else {
      res = [
        await Api.getKindsPublic(),
        await Api.getAuctionPublic(this.props.match.params.auctionNumber)
      ]
    }
    if (!res.every(Boolean)) {
      return
    }
    let [{ kinds }, { auction }] = res
    this.setState({
      auction
    })
    if (auction && auction.state === 'closed') {
      this.props.history.push('/')
    }
    if (!this.props.loggedIn && !auction.public) {
      this.props.history.push('/')
    }
    this.props.changeStateProp('kinds', kinds, 'data')
    this.props.mergeStateProp('auctions', [auction], 'data')
    this.attachSocketListeners()
    if (this.props.loggedIn) {
      res = await Api.getLotsByAuction(this.props.match.params.auctionNumber)
    } else {
      res = await Api.getLotsByAuctionPublic(this.props.match.params.auctionNumber)
    }
    if (!res) {
      return
    }
    this.props.mergeStateProp('lots', res.lots, 'data')
    if (this.props.loggedIn) {
      res = [
        await Api.getBids(),
        await Api.getAutobids()
      ]
      if (!res.every(Boolean)) {
        return
      }
      let [{ bids }, { autobids }] = res
      this.props.changeStateProp('bids', bids, 'user')
      this.props.changeStateProp('autobids', autobids, 'user')

      this.setState({ loaded: true })
      this.props.showSpinner(false)
    } else {
      this.setState({ loaded: true })
      this.props.showSpinner(false)
    }
    let joinRoomAnonymous = () => {
      if (this.props.auction) {
        Socket.joinRoomAnonymous(Socket.ANONYMOUS_USER_NAMESPACE, this.props.auction._id)
        this.liveContainer.current.removeEventListener('click', joinRoomAnonymous, false)
      }
    }
    if (!this.props.loggedIn || (this.props.user && !this.props.user.data) || !this.props.user) {
      if (this.liveContainer && this.liveContainer.current) {
        this.liveContainer.current.addEventListener('click', joinRoomAnonymous, false)
      }
      window.addEventListener('beforeunload', async (event) => {
        event.preventDefault()
        event.returnValue = ''
        navigator.sendBeacon(`${BASE_URL}delete-anonymous-user`, JSON.stringify({ id: this.state.auction ? this.state.auction._id : '' }))
      })
    }
  }

  async onReconnect (a) {
    this.props.showSpinner(true)
    if (a?.target?.visibilityState === 'hidden') {
      this.props.showSpinner(false)
      return
    }
    // only update data if tab is active
    if (this.props.loggedIn) {
      let res = await Promise.all([
        Api.getAuction(this.props.match.params.auctionNumber),
        Api.getLotsByAuction(this.props.match.params.auctionNumber),
        Api.getBids(),
        Api.getAutobids(),
        Api.putOnlineState('ONLINE')
      ])
      if (res.every(Boolean)) {
        let [{ auction }, { lots }, { bids }, { autobids }] = res
        this.props.mergeStateProp('auctions', [auction], 'data')
        this.props.mergeStateProp('lots', lots, 'data')
        this.props.changeStateProp('bids', bids, 'data')
        this.props.changeStateProp('autobids', autobids, 'data')
      }
      this.attachSocketListeners().then(() => {
        this.props.showSpinner(false)
      }).catch(e => {
        console.log(e)
        window.location.reload()
      })
    } else {
      let res = await Promise.all([
        Api.getAuctionPublic(this.props.match.params.auctionNumber),
        Api.getLotsByAuctionPublic(this.props.match.params.auctionNumber)
      ])
      if (res.every(Boolean)) {
        let [{ auction }, { lots }] = res
        this.props.mergeStateProp('auctions', [auction], 'data')
        this.props.mergeStateProp('lots', lots, 'data')
      }
      this.props.showSpinner(false)
    }
  }

  joinRoom (authorized) {
    if (this.props.auction) {
      Socket.joinRoom(Socket.BIDS_NAMESPACE, this.props.auction._id, authorized)
    }
  }

  selectLot (lotId) {
    if (this.props.lots.some(lot => lot._id === lotId)) {
      this.setState({
        selectedLotId: lotId,
        showInfo: true
      })
    }
  }

  updateGridState (state) {
    this.setState({ gridState: state })
    window.localStorage.setItem('gridState', state)
  }

  toggleSound () {
    let audio = new window.Audio(sounds.silence)
    let playPromise = audio.play()

    if (playPromise !== undefined) {
      playPromise
        .then(_ => {
          // Automatic playback started!
          // Show playing UI.
        })
        .catch(error => {
          // Auto-play was prevented
          // Show paused UI.
          console.log('error', error)
        })
    }
    this.setState({ isSound: !this.state.isSound }, () => {
      this.props.changeStateProp('isSound', this.state.isSound, 'temp')
    })
  }

  selectNaItem (item) {
    this.setState({
      selectedLotId: null
    })
    let navItems = this.props.loggedIn ? subNavItems : subNavItemsPublic
    if ((this.state.auction.auctionType === 'sequential' || this.state.auction.auctionType === 'sequentialOffline') && this.props.loggedIn) {
      navItems = subNavItemsSequential
    } else if ((this.state.auction.auctionType === 'sequential' || this.state.auction.auctionType === 'sequentialOffline') && !this.props.loggedIn) {
      navItems = subNavItemsSequentialPublic
    } else if (this.state.auction.auctionType === 'synchronous') {
      navItems = subNavItemsSynchronous
    }
    navItems.forEach(navItem => {
      navItem.active = navItem.name === item.name
    })
    this.setState({
      selectedNavItem: item.name,
      navItems: navItems
    }, () => this.setState({ navItems: navItems }))
  }

  getValidLots (lots) {
    return lots.sort((a, b) => a.number - b.number)
  }

  findBids (bids, lotId) {
    return bids.find((bid) => {
      return bid.lot === lotId
    })
  }

  getRenderLots () {
    let renderLots = this.getValidLots(this.props.lots)
    switch (this.state.selectedNavItem) {
      case 'all-lots':
        return renderLots
      case 'watchlist':
        return renderLots.filter(
          lot => this.props.user.data.watchList2.map((item) => item.lotId).includes(lot._id)
        )
      case 'held-lots':
        return renderLots.filter(lot => {
          let status = this.getStatus(lot)
          if (lot.status === 'passed' && lot.state === 'closed') {
            return false
          }
          return (status.indexOf('bided') !== -1 && status.indexOf('overbided') === -1) || (this.props.user.data['_id'] === lot.winner)
        })
      case 'lost-lots':
        return renderLots.filter(lot => {
          let status = this.getStatus(lot)
          if (lot.status === 'passed' && lot.state === 'closed') {
            return false
          }
          return status.indexOf('overbided') !== -1 || (lot.winner && this.props.user.data['_id'] !== lot.winner && (this.findBids(this.props.user.bids, lot['_id']) || this.findBidsByParticipants(this.props.user.data['_id'], lot)))
        })
      case 'open-lots':
        return renderLots.filter(lot => lot.state !== 'closed' && !lot.withdrawn)
      case 'not-in-market':
        return renderLots.filter(lot => lot.state !== 'closed' && lot.reserveStatus !== 'onMarket')
      case 'active-lots':
        return renderLots.filter(lot => lot.state === 'live' && !lot.withdrawn)
      case 'upcoming-lots':
        return renderLots.filter(lot => lot.state === 'futureInLive' && !lot.withdrawn)
      default:
        return renderLots.filter(lot => lot.allocatedSection === this.state.selectedNavItem && lot.state !== 'closed' && !lot.withdrawn)
    }
  }
  auctiontabsAsNavItems(auction, navItems) {
    auction.auctionTabs.forEach((ele, index) => {
      navItems.push({
        name: `${ele.tabName} - ${ele.description}` ,
        title: `${ele.tabName} - ${ele.description}` ,
        borderColor: '#1f9512',
        // icon: faCircle,
        iconColor: '#1f9512',
        active: false
      })
    })
    navItems.push({
      name: `other lots (not allocated)` ,
      title: `other lots (not allocated)` ,
      borderColor: '#1f9512',
      // icon: faCircle,
      iconColor: '#1f9512',
      active: false
    })
   this.setState ({
    isAuctionTabsAddedAsNavItems : true
   })
    return navItems
  }

  findBidsByParticipants (userID, lot) {
    return lot.participants.find((participant) => {
      return userID === participant
    })
  }

  getStatus (lot) {
    return LotUtils.getRunningStatus(lot, this.props.auction, this.props.user.data ? this.props.user.data._id : '')
  }

  closeInfoMobile () {
    this.setState({
      showInfo: false
    })
  }

  getStatusForMobile (selectedLotId) {
    if (!selectedLotId) {
      return null
    }
    let lot = this.props.lots.find(lot => lot._id === selectedLotId)
    let status = this.getStatus(lot)
    if (status.indexOf('overbided') !== -1) {
      return <span className='red'>Bid is Against You</span>
    } else if (status.indexOf('bided') !== -1) {
      return <span className='green'>Bid is With You</span>
    } else {
      return null
    }
  }

  showDetailsPopup (value) {
    this.setState({
      showDetailsPopup: value
    })
  }

  renderAuctionLive (selectedLotId, showAsDetailsPopup) {
    return (
      <Fragment>
        {showAsDetailsPopup && (<div className='popup-closing-layer' onClick={() => (this.showDetailsPopup(false))} />)}
        <div className={`single-lot-container ${showAsDetailsPopup ? 'details-popup' : ''}`}>
          <div className='button-close-info'>
            <span className='button-text' onClick={this.closeInfoMobile} />
          </div>
          <div className='mobileStatus'>{this.getStatusForMobile(selectedLotId)}</div>
          <BiddingContainer offsetMs={this.state.offsetMs} history={this.props.history} lotId={selectedLotId} auctionId={this.props.auction._id} />
          {this.props.loggedIn && <div className={`watch-container ${!selectedLotId ? 'no-lot' : ''}`}>
            {
              selectedLotId
                ? (<WatchButton lotId={selectedLotId} bigButton />)
                : null
            }
          </div>}
          <LotInfo showInPopup={showAsDetailsPopup} showingInPopup={this.showDetailsPopup} lotId={selectedLotId} />
        </div>
      </Fragment>)
  }

  showAuctionClosed = (auction) => {
    return auction.state === 'closed' || this.state.synchronousClosed
  }
  renderContent () {
    const {
      loaded,
      selectedLotId,
      showDetailsPopup,
      isSound,
      gridState
    } = this.state
    const { auction, loggedIn } = this.props
    if (!this.isValidUrl()) {
      return null
    }
    if (!auction || !loaded) {
      return (
        <Fragment>
          <LiveHeaderContainer auction={auction} history={this.props.history} />
          <LoadingContainer />
        </Fragment>
      )
    }
    let renderedLots = this.getRenderLots()
    let navItems = loggedIn ? subNavItems : subNavItemsPublic
    if ((auction.auctionType === 'sequential' || auction.auctionType === 'sequentialOffline') && loggedIn) {
      navItems = subNavItemsSequential
    } else if ((auction.auctionType === 'sequential' || auction.auctionType === 'sequentialOffline') && !loggedIn) {
      navItems = subNavItemsSequentialPublic
    } else if (auction.auctionType === 'synchronous') {
      navItems = subNavItemsSynchronous
    }

    if(!this.state.isAuctionTabsAddedAsNavItems && auction.requiresTabs ){
      this.auctiontabsAsNavItems (auction,navItems)
    }
    return (
      <Fragment>
        <LiveHeaderContainer auction={auction} history={this.props.history} />
        <div className='app-navbar live-navbar'>
          <div className='navbar-wrap'>
            <div className='navbar'>
              <div className='navbar-tabs'>
                {navItems.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`tab ${item.title}-tab ${item.active ? ' active' : ''}`}
                      onClick={() => this.selectNaItem(item)}
                      style={item.active ? { borderColor: item.borderColor, color: '#ffffff' } : null}>
                      <FontAwesomeIcon icon={item.icon} style={{ color: item.iconColor }} />
                      {item.title}
                    </div>
                  )
                })}
              </div>
              <div className='toggle-pictures'>

                <div className='grid-row'>
                  <div onClick={() => this.updateGridState('default')}>
                    <FontAwesomeIcon className={`grid-icon ${gridState === 'default' ? 'active' : ''}`} icon={faThLarge} />
                  </div>
                  <div onClick={() => this.updateGridState('no-image')}>
                    <FontAwesomeIcon className={`grid-icon ${gridState === 'no-image' ? 'active' : ''}`} icon={faThList} />
                  </div>

                  <div onClick={() => this.updateGridState('list')}>
                    <FontAwesomeIcon className={`grid-icon ${gridState === 'list' ? 'active' : ''}`} icon={faBars} />
                  </div>

                </div>

                <div className='toggle-item'>
                  <p>Off</p>
                  <Toggle icons={false} onChange={this.toggleSound} checked={isSound} />
                  <p>Sound</p>
                </div>
                {/* <div className='toggle-item'>
                  <p>Hide</p>
                  <Toggle icons={false} onChange={this.toggleShowImgs} checked={showImgs} />
                  <p>Photos</p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {renderedLots.length ? (
          <div className={`live-auction ${this.state.showInfo ? 'show-mobile' : ''}`}>
            {renderedLots.length
              ? (
                <Fragment>
                  <RenderLotsContainer
                    lots={renderedLots}
                    allLots={this.props.lots}
                    selectLot={this.selectLot}
                    selectedLotId={selectedLotId}
                    hideImages={gridState === 'no-image'}
                    lotList={gridState === 'list'}
                    auction={auction}
                    offsetMs={this.state.offsetMs}
                    selectedNavItem={this.state.selectedNavItem}
                  />
                  {this.renderAuctionLive(selectedLotId, false)}
                  {showDetailsPopup ? this.renderAuctionLive(selectedLotId, showDetailsPopup) : null}
                  <div className='overlay-info' />
                </Fragment>
              )
              : null}
          </div>
        ) : (
          <div className='live-auction'>
            <p className='no-items' />
          </div>
        )}
        <LoadingContainer />
        {
          this.showAuctionClosed(auction) && (
            <div className='live-overlay'>
              <div className='text-container'>
                <h3>AUCTION NO. {auction.number} CLOSED</h3>
                <Link to={`/main/results/${auction.kindData.title}/${auction.searchNumber}`} target='_blank'>
                  See Whole Auction Results
                </Link>
                <Link to='/main/manage/my-results' target='_blank'>
                  See My Auction Results
                </Link>
              </div>
            </div>
          )
        }
      </Fragment>
    )
  }

  setSound = (sound) => {
    let userEvent = sound
    this.state.soundEvents.push(userEvent)
    this.soundsWithOutQueue(this.state.soundEvents, userEvent)
  }

  async soundsWithOutQueue (userEvents, eventSound) {
    let onlyBid = userEvents.every(elem => elem.event === 'bid')
    let bidEvent = userEvents.find(elem => elem.event === 'bid')

    if ((onlyBid && !this.state.outbid) || (userEvents.length > 1 && onlyBid) || (!this.state.outbid && this.state.autobid && onlyBid)) {
      let autoBid = await this.props.user.autobids.find(element => element.active && element.lot === bidEvent.data.bid.lot)
      if (autoBid && bidEvent.data.bid.cents <= autoBid.cents) {
        if (bidEvent.data.bid.cents === autoBid.cents) {
          return
        }

        if (this.state.soundEvents.length === 1) {
          return
        }

        if (this.state.soundEvents.length > 1) {
          await this.setState({
            soundEvents: []
          })
        }
      }

      await this.createSound(bidEvent)
      await this.setState({
        outbid: false,
        autobid: false
      })
    } else {
      await userEvents.forEach((userEvent, index, arr) => {
        if (!userEvent.ignorable && (userEvent.event === 'autobid')) {
          if (this.state.outbid) {
            setTimeout(() => {
              this.createSound(userEvent)
            }, 900)
          } else {
            this.createSound(userEvent)
          }
          this.setState({
            autobid: true,
            outbid: false
          })
        }

        if (!userEvent.ignorable && (userEvent.event === 'outbid')) {
          this.createSound(userEvent)
          this.setState({
            outbid: true,
            autobid: false
          })
        }

        if (!userEvent.ignorable && (userEvent.event === 'won')) {
          this.createSound(userEvent)
        }
      })
    }

    if (eventSound.event !== 'bid') {
      await this.setState({
        soundEvents: []
      })
    }
  }

  checkSoundState (sound) {
    if (sound.event === 'bid' && window.localStorage.getItem('bidSound') === 'false') {
      return false
    } else if (sound.event === 'outbid' && window.localStorage.getItem('outBidSound') === 'false') {
      return false
    } else if (sound.event === 'won' && window.localStorage.getItem('wonSound') === 'false') {
      return false
    } else if (sound.event === 'autobid' && window.localStorage.getItem('autoBidSound') === 'false') {
      return false
    } else if (sound.event === 'countdown' && window.localStorage.getItem('countdownSound') === 'false') {
      return false
    } else {
      return true
    }
  }

  createSound = (sound) => {
    if (this.state.outbid && sound.event === 'bid') return
    audio.src = sound.url
    if (this.checkSoundState(sound)) {
      let playPromise = audio.play()
      if (playPromise !== undefined) {
        playPromise
          .then(_ => {
            // Automatic playback started!
            // Show playing UI.
          })
          .catch(error => {
            // Auto-play was prevented
            // Show paused UI.
            console.log('error', error)
          })
      }
    }
  }

  render () {
    let { fixedHeader, showInfo } = this.state
    return (
      <div ref={this.liveContainer} className={`auction-live live ${fixedHeader ? 'fixed' : ''} ${showInfo ? 'show-mobile' : ''}`}>
        {this.renderContent()}
      </div>
    )
  }
}

AuctionLiveComponent.propTypes = {
  auctions: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  auction: PropTypes.object,
  lots: PropTypes.array.isRequired,
  changeStateProp: PropTypes.func.isRequired,
  mergeStateProp: PropTypes.func.isRequired,
  filterStateProp: PropTypes.func.isRequired,
  showSpinner: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
}

export default AuctionLiveComponent
