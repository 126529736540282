import { connect } from 'react-redux'

import { lotById } from '../../selectors/lotsSelector'
import StateTimerLot from './StateTimerLot'

const mapStateToProps = (state, ownProps) => {
  return {
    lot: lotById({ lotId: ownProps.lotId, lots: state.data.lots }),
    isSound: state.temp.isSound
  }
}

export default connect(mapStateToProps, null)(StateTimerLot)
