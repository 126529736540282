import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/fontawesome-free-solid'
// import ImageCarousel from "../ImageCarousel/ImageCarousel";

class Carousel extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      currentSlide: this.props.currentSlide ? this.props.currentSlide : 0,
      dots: []
    }
    this.totalItems = 0
    this.numberOfSlides = props.numberOfSlides
    this.updateCarousel = this.updateCarousel.bind(this)

    this.mediaWrap = React.createRef()
    this.mediaArea = React.createRef()
    this.prevArrow = React.createRef()
    this.nextArrow = React.createRef()
  }
  componentDidMount () {
    this.updateCarousel()
    this.activateDot(0)
    window.addEventListener('resize', this.updateCarousel)
  }
  componentDidUpdate (prevProps, prevState) {
    this.updateCarousel()
  }
  componentWillUnmount () {
    window.removeEventListener('resize', this.updateCarousel)
  }
  changeSlide (num) {
    const { lotForm } = this.props
    let totalItems = lotForm ? document.querySelectorAll('.carousel-item').length : this.mediaArea.current.childNodes.length
    let nextSlide = this.state.currentSlide + num
    if (nextSlide >= 0 && nextSlide < totalItems) {
      this.setState({
        currentSlide: nextSlide
      })
    } else {
      nextSlide > 0 && this.nextArrow.current.classList.remove('active')
    }
    this.activateDot(nextSlide)
  }
  goToSlide (number) {
    this.setState({
      currentSlide: number
    })
    this.activateDot(number)
  }
  updateCarousel () {
    const { lotForm } = this.props
    let margin
    let wrap = this.mediaWrap.current
    let box = this.mediaArea.current
    let prevArrow = this.prevArrow.current
    let nextArrow = this.nextArrow.current
    let wrapRect = wrap.getBoundingClientRect()
    let totalItems = lotForm ? document.querySelectorAll('.carousel-item').length : box.childNodes.length
    this.totalItems = totalItems
    let itemWidth
    const element = lotForm ? document.querySelector('.carousel-item') : box.childNodes[0]
    if (totalItems > 1) {
      itemWidth = element.getBoundingClientRect().width
      let computedStyle = element.currentStyle || window.getComputedStyle(box.childNodes[0])
      margin = parseInt(computedStyle.marginRight, 10) || 0
    } else {
      prevArrow.classList.contains('active') &&
      prevArrow.classList.remove('active')
      nextArrow.classList.contains('active') &&
      nextArrow.classList.remove('active')
      box.style.left = '0px'
      return
    }
    let totalMediaLength = (itemWidth + margin) * totalItems
    let predictLastItemRight = wrapRect.left + itemWidth * (totalItems - this.state.currentSlide) + margin * (totalItems - this.state.currentSlide - 1)

    if (this.state.currentSlide === 0) {
      prevArrow.classList.contains('active') &&
      prevArrow.classList.remove('active')
    } else {
      !prevArrow.classList.contains('active') &&
      prevArrow.classList.add('active')
    }

    if (wrapRect.right >= predictLastItemRight || this.state.currentSlide >= totalItems - 1) {
      nextArrow.classList.contains('active') &&
      nextArrow.classList.remove('active')
    } else {
      !nextArrow.classList.contains('active') &&
      nextArrow.classList.add('active')
    }
    let maxShift = -(Math.abs(totalMediaLength - (wrapRect.right - wrapRect.left)))
    let shift = -this.state.currentSlide * (itemWidth + margin)
    box.style.left = (shift < maxShift ? maxShift : shift) + 'px'
  }
  initDots () {
    let dotsCount = this.totalItems - (this.numberOfSlides - 1)
    if (this.props.preview || dotsCount <= 1) return []
    let dots = []
    for (let i = 0; i < dotsCount; i++) {
      dots.push({ active: false })
    }
    return dots
  }
  activateDot (index) {
    let dots = this.initDots()
    if (!dots.length) return
    dots[Math.min(index, dots.length - 1)].active = true
    this.setState({ dots })
  }
  render () {
    return (
      <div className='carousel-wrap'>
        <div className={`media-carousel${this.props.carouselClassName ? ` ${this.props.carouselClassName}` : ''}`}>
          <div className='nav-prev' ref={this.prevArrow} onClick={() => { this.changeSlide(-1) }}>
            <span className='icon-wrapper'>
              <FontAwesomeIcon icon={faChevronLeft} size='2x' />
            </span>
          </div>
          <div className='media-wrap' ref={this.mediaWrap}>
            <div className='media-area' ref={this.mediaArea}>
              {this.props.children}
            </div>
          </div>
          <div className='nav-next' ref={this.nextArrow} onClick={() => { this.changeSlide(1) }}>
            <span className='icon-wrapper'>
              <FontAwesomeIcon icon={faChevronRight} size='2x' />
            </span>
          </div>
        </div>
        <div className='dots-wrap'>
          {
            this.state.dots.length > 0
              ? <span className='numberSlides'>{`${this.state.currentSlide + 1}/${this.props.lotForm ? this.totalItems - 2 : this.totalItems}`}</span>
              : null
            // this.state.dots.map((dot, index) => {
            //   return (
            //     <span key={index} onClick={() => { this.goToSlide(index) }} className={'dot ' + (dot.active ? 'active' : '')} />
            //   )
            // })
          }
        </div>
      </div>
    )
  }
}

Carousel.defaultProps = {
  numberOfSlides: 3
}

Carousel.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  preview: PropTypes.bool,
  carouselClassName: PropTypes.string,
  numberOfSlides: PropTypes.number
}

export default Carousel
