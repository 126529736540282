import React, { Component } from 'react'
import memoize from 'memoize-one'
import PropTypes from 'prop-types'

function encode (value) {
  let result = ''
  if (value.slice(0, 2)) {
    result += `${value.slice(0, 2)}`
  }
  if (value.slice(2, 6)) {
    result += ` ${value.slice(2, 6)}`
  }
  if (value.slice(6, 10)) {
    result += ` ${value.slice(6, 10)}`
  }
  return result
}

function decode (value) {
  return value.replace(/\D+/g, '').slice(0, 10)
}

class FgPhone extends Component {
  constructor (props) {
    super(props)
    this.getClassNames = memoize(this.getClassNames)
    this.getContainerClassNames = memoize(this.getContainerClassNames)
    this.state = {
      value: this.props.context !== 'profile' ? encode(this.props.value) : this.props.value
    }
    this.input = React.createRef()
    this.onChange = this.onChange.bind(this)
  }
  componentDidUpdate (prevProps) {
    if (prevProps.value !== this.props.value) {
      if (this.props.context !== 'profile') {
        this.setState({ value: encode(this.props.value) })
      } else {
        this.setState({ value: this.props.value.slice(0, 10) })
      }
    }
  }
  handleWarning (warning) {
    if (!this.props.validData && this.props.warningReason) {
      return (
        <div className='warning-text'>* {warning}</div>
      )
    }
  }
  onChange (event) {
    let { value } = this.input.current
    if (this.props.context !== 'profile') {
      if (value.indexOf('(') !== -1 && value.indexOf(')') === -1) {
        value = value.slice(0, -1)
      }
      value = decode(value)
      this.setState({
        value: encode(value)
      })
      event.target.value = value
      this.props.onChange(event)
    } else {
      // value = decode(value)
      value = value.slice(0, 10)
      this.setState({
        value
      })
      event.target.value = value
      this.props.onChange(event)
    }
  }
  getContainerClassNames (customClass) {
    let classNames = []
    if (customClass) classNames.push(customClass)
    return classNames.join(' ')
  }
  getClassNames (validData, customClass) {
    let classNames = ['input']
    if (!validData) classNames.push('warning-input')
    if (customClass) classNames.push(customClass)
    return classNames.join(' ')
  }
  render () {
    let containerClass = this.getContainerClassNames(this.props.containerClassName)
    let classNames = this.getClassNames(this.props.validData, this.props.className)
    return (
      <div className={containerClass}>
        <label>{this.props.label}
          <input
            className={classNames}
            disabled={this.props.disabled}
            value={this.state.value}
            ref={this.input}
            type='text'
            name={this.props.name}
            placeholder={this.props.placeholder}
            onChange={this.onChange}
            onBlur={this.props.onBlur}
          />
        </label>
        {this.handleWarning(this.props.warningReason)}
      </div>
    )
  }
}

FgPhone.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  warningReason: PropTypes.string,
  validData: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool
}

FgPhone.defaultProps = {
  placeholder: '',
  value: '',
  name: '',
  warningReason: '',
  validData: true,
  onChange: Function.prototype,
  disabled: false
}

export default FgPhone
