import moment from 'moment'

import ServerTime from '../services/ServerTime'
import { momentTz } from './index'
import { AUCTION_ADD_LIMIT } from '../constants'
// import store from '../services/store'
import { isAllowedToAddNewLot } from '../utils/UpdateData'
const epochStart = moment(0)

export function getDateDays (dateString) {
  return moment(momentTz(dateString)._d).diff(epochStart, 'days')
}

export function getTodayDays (dateString) {
  return moment(momentTz(dateString)._d).diff(epochStart, 'days')
}

export function formatAuctionDate (date) {
  return momentTz(date).format('dddd D MMMM YYYY')
}

export function getSectionHeaderTitle (auctionDate, today) {
  let todayDays
  todayDays = today ? getDateDays(today) : getTodayDays(new Date())
  let days = getDateDays(auctionDate)
  if (days === todayDays) {
    return 'Today'
  } else if (days === todayDays + 1) {
    return 'Tomorrow'
  } else {
    return formatAuctionDate(auctionDate)
  }
}

export function isAvailableAuction (auction) {
  // let user = store.getState().user
  if (!auction) {
    return false
  }
  if (auction.cancelled) {
    return false
  }
  const timer = auction.addLotOpenTimer ? auction.addLotOpenTimer : AUCTION_ADD_LIMIT
  const property = auction.addLotOpenTimer ? 'seconds' : 'days'
  let leftDate = moment(ServerTime.getTime()).add(timer, property)
  let rightDate = new Date(auction.editLotDisabledAt)
  let liveAt = new Date(auction.liveAt).getTime()
  return auction.state === 'future' &&
    isAllowedToAddNewLot(auction.addNewLotToSpecificAgents, auction.specificAgents) &&
    (liveAt < leftDate && ServerTime.getTime() < rightDate)
    // && (user.data.approvedStockTypes && user.data.approvedStockTypes.includes(auction.kindData.title))
}

export function isAvailableReofferAuction (auction) {
  if (!auction) {
    return false
  }
  if (auction.cancelled) {
    return false
  }
  let rightDate = new Date(auction.editLotDisabledAt)
  return auction.state === 'future' && ServerTime.getTime() < rightDate
}

export const getLotNumber = (auction, lot) => {
  let lotNumber
  if (auction) {
    let isStateToShowNumber =
      auction.state === 'open' ||
      auction.state === 'live' ||
      auction.state === 'closed'

    if (((!isStateToShowNumber && auction.showLotNumbers) || isStateToShowNumber) && lot.number) {
      lotNumber = `Lot ${lot.number}`
    } else if (lot.searchNumericId) {
      lotNumber = `ID${lot.searchNumericId.substr(2, lot.searchNumericId.length)}`
    } else {
      lotNumber = 'Lot X'
    }
  } else {
    if (lot.searchNumericId) {
      lotNumber = `ID${lot.searchNumericId.substr(2, lot.searchNumericId.length)}`
    } else {
      lotNumber = 'Lot X'
    }
  }
  return lotNumber
}
