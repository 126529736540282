import React, { PureComponent } from 'react'

// import FgSelect from '../../FgSelect/FgSelect'
import SelectWithAutoScroll from '../../SelectWithAutoScroll/SelectWithAutoScroll'

class CurfewWeight extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      curfewWeight: this.calculateCurfew(this.props.adjustWeight, this.props.weightGain),
      newAssessedWeight: this.calculateNewAssessedWeight(this.props.weightGain)
    }
    this.adjustmentOptions = [];
    for (let i = -20; i <= 20; i += 0.25) {
      this.adjustmentOptions.push({
        value: i,
        label: i > 0 ? `+${i}%` : `${i}%`
      });
    }
    if (this.props.auction.kindData.type === 'sheep-abblamb') {
      const additionalValues = [-3.9, -3.8, -3.7, -3.6, -3.4, -3.3, -3.2, -3.1, -2.9, -2.8, -2.7, -2.6, -2.4, -2.3, -2.2, -2.1, -1.9, -1.8, -1.7, -1.6, -1.4, -1.3, -1.2, -1.1];
      this.adjustmentOptions = [...this.adjustmentOptions, ...additionalValues.map(value => ({ value, label: `${value}%` }))];
      this.adjustmentOptions.sort((a, b) => a.value - b.value);
    }

    this.weightGainOptions = []
    switch (this.props.kindType) {
      case 'cattle':
        for (let i = 0; i <= 5; i += 0.5) {
          this.weightGainOptions.push({
            value: i,
            label: `${i}kg`
          })
        }
        break
      case 'sheep':
      case 'sheep-abblamb':
      case 'goat':
        for (let i = 0; i <= 500; i += 50) {
          this.weightGainOptions.push({
            value: i,
            label: `${i}g`
          })
        }
        break
      default:
        break
    }
  }
  onAdjustmentChange = (option) => {
    this.setState({
      curfewWeight: this.calculateCurfew(option.value, this.props.weightGain)
    })
    this.props.onChange('adjustWeight', option.value)
  }
  onGainChange = (option) => {
    this.setState({
      newAssessedWeight: this.calculateNewAssessedWeight(option.value)
    })
    const divider = this.props.kindType === 'cattle' ? 1 : 1000
    this.props.onChange('weightGain', option.value / divider)
  }
  calculateCurfew = (adjustWeight, weightGain) => {
    if (this.calculateNewAssessedWeight(weightGain) && !this.props.isOptiweigh && this.props.auction.kindData.type !== 'sheep-abblamb' ) {
      return (((100 + adjustWeight) * this.calculateNewAssessedWeight(weightGain)) / 100).toFixed(2)
    } else {
      return (((100 + adjustWeight) * this.props.assessedData) / 100).toFixed(2)
    }
  }
  calculateNewAssessedWeight (weightGain) {
    const divider = this.props.kindType === 'cattle' ? 1 : 1000
    if (this.props.earliestDeliveryDate && this.props.assessmentDate) {
      const earliesDelivery = new Date(this.props.earliestDeliveryDate)
      const assessmentDate = new Date(this.props.assessmentDate)
      const timeDiff = Math.abs(earliesDelivery - assessmentDate)
      const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24))
      return ((weightGain / divider * daysDiff) + this.props.assessedData).toFixed(2)
    } else {
      return (0).toFixed(2)
    }
  }
  calculateShrinkPercentage (value) {
    let percentage
    if(value){
      percentage = parseFloat((-4 + (value - 1) * 0.1).toFixed(1));
    }else {
      percentage = 0
    }
    this.props.onChange('adjustWeight', percentage)
    return percentage
  }
  componentDidUpdate () {
    const { newAssessedWeight } = this.state
    if (this.state.curfewWeight !== this.calculateCurfew(this.props.adjustWeight, this.props.weightGain) ||
      newAssessedWeight !== this.calculateNewAssessedWeight(this.props.weightGain)) {
      this.setState({
        curfewWeight: this.calculateCurfew(this.props.adjustWeight, this.props.weightGain),
        newAssessedWeight: this.calculateNewAssessedWeight(this.props.weightGain)
      })
    }
  }
  render () {
    const {
      invalid,
      adjustWeight,
      hoursOffFeed,
      assessedData,
      weightGain,
      disabled,
      reoffered,
      isOptiweigh,
      auction
    } = this.props
    const {
      curfewWeight,
      newAssessedWeight
    } = this.state
    return (
      <React.Fragment>
        <div className={`form-row ${disabled ? 'disabled' : ''}`}>
          <div className='row-title'>
            <p
              className={`row-text`}>
              {isOptiweigh ? 'Full Weight:' : 'Assessment Weight:'}
            </p>
          </div>
          <div className='row-content'>
            <span className='value'>{assessedData} Kg average</span>
          </div>
        </div>

        {/* <div className={`form-row ${disabled && !reoffered ? 'disabled' : ''}`}> */}
        <div className={`form-row`}>
          <div className='row-title'>
            <p className={`row-text ${invalid ? 'row-text-invalid' : ''}`}>
             {!(auction.kindData.type === 'sheep-abblamb')  ? <p>Weight Gain Adjustment: </p> : <p>Daily Weight Gain Adjustment: </p> } 
            </p>
          </div>
          {isOptiweigh
            ? (
              <div className='row-content'>
                <span className='value'>
                  {weightGain} Kg
                </span>
              </div>
            )
            : (<div className='row-content'>
              <SelectWithAutoScroll
                options={this.weightGainOptions}
                value={weightGain}
                onChange={this.onGainChange}
              />
            </div>)
          }
        </div>
        {!isOptiweigh ? <div className='form-row'>
          <div className='row-title'>
            <p className={`row-text ${invalid ? 'row-text-invalid' : ''}`}>
              New Assessed Weight:
            </p>
          </div>
          <div className='row-content'>
            <span className='value'>
              {newAssessedWeight} Kg
            </span>
          </div>
        </div> : null}
        {this.props.auction.kindData.type === 'sheep-abblamb' ?
          (
            <div className={`form-row disabled`}>
              <div className='row-title'>
                <p className={`row-text ${invalid ? 'row-text-invalid' : ''}`}>
                  Shrinkage % :
                </p>
              </div>
              <div className='row-content'>
                <SelectWithAutoScroll
                  options={this.adjustmentOptions}
                  value={this.calculateShrinkPercentage(hoursOffFeed)}
                  onChange={this.onAdjustmentChange}
                />
              </div>
            </div>
          ) : (<div className={`form-row ${disabled && !reoffered ? 'disabled' : ''}`}>
            <div className='row-title'>
              <p className={`row-text ${invalid ? 'row-text-invalid' : ''}`}>
                Adjustment: (+ or -)
              </p>
            </div>
            <div className='row-content'>
              <SelectWithAutoScroll
                options={this.adjustmentOptions}
                value={adjustWeight}
                onChange={this.onAdjustmentChange}
              />
            </div>
          </div>)
        }

        <div className={`form-row`}>
          <div className='row-title'>
            <p className={`row-text ${invalid ? 'row-text-invalid' : ''}`}>
              Curfew Weight:
            </p>
          </div>
          <div className='row-content'>
            <span className='value'>
              {curfewWeight} Kg
            </span>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

CurfewWeight.defaultProps = {
  assessedData: 0
}

export default CurfewWeight
