import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faSearch,
  faDownload
} from '@fortawesome/fontawesome-free-solid'

import { limitCharacters } from '../../utils'
import { BASE_URL } from '../../config'

class LotDocuments extends Component {
  openDocLink (doc) {
    if (/image/.test(doc.mimeType)) {
      // use regular window for images
      return doc.url
    } else {
      // use google docs window for everything else (pdf, MS Word files...)
      return `https://docs.google.com/gview?url=${doc.url}`
    }
  }
  render () {
    if (!this.props.documents.length) {
      return (
        <div className='lot-documents'>
          <div className='row'>
            <div className='row-title-wrap'>
              <p className='row-title'>Documents</p>
            </div>
            <div className='documents-container'>
              <div className='empty-documents-wrapper'>
                <span className='text'>
                  No Documents
                </span>
                <span>
                  -
                </span>
              </div>

            </div>
          </div>
        </div>
      )
    }
    return (
      <div className='lot-documents'>
        <div className='row'>
          <div className='row-title-wrap'>
            <p className='row-title'>Documents</p>
          </div>
          <div className='documents-container'>
            {this.props.documents.map((doc, i) => {
              return (
                <div className='option' key={doc.key}>
                  <div className='file-wrap'>
                    <p className='description'>{doc.description}</p>
                    <p className='file-name'>{limitCharacters(doc.name, 30)}</p>
                    <a className='view-link' href={this.openDocLink(doc)} target='_blank' rel='noopener noreferrer'>
                      <span>View </span>
                      <FontAwesomeIcon icon={faSearch} />
                    </a>
                    <a className='download-link' download target='_blank' href={`${BASE_URL}download-file/${doc.key}?fileName=${doc.name}`} rel='noopener noreferrer'>
                      <span>Download</span>
                      <FontAwesomeIcon icon={faDownload} />
                    </a>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

LotDocuments.propTypes = {
  documents: PropTypes.array.isRequired
}
LotDocuments.defaultProps = {
  documents: []
}

export default LotDocuments
