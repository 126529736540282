import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { showSpinner } from '../../../actions'
import MySybmittedLotDetails from './LotDetails'

const mapStateToProps = (state) => {
  return {
    userId: state.user.data._id
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ showSpinner }, dispatch)
}

const LotDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MySybmittedLotDetails)

export default LotDetailsContainer
