import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faDoorOpen
} from '@fortawesome/fontawesome-free-solid'

import { momentTz } from '../../../utils'
import logOut from '../../../utils/logOut'
import StateTimerAuction from '../../StateTimerAuction/StateTimerAuction'

class LiveHeader extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      mobile: false
      // contactNumber: ''
    }
    this.logOut = this.logOut.bind(this)
    this.changeState = this.changeState.bind(this)
    this.goHome = this.goHome.bind(this)
  }

  componentDidMount () {
    this.changeState()
    // this.getContactNumber()
    window.addEventListener('resize', this.changeState)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.changeState)
  }

  changeState () {
    window.innerWidth > 900
      ? this.setState({ mobile: false })
      : this.setState({ mobile: true })
  }
  userStatusToDisplay(status) {

    switch (status) {
      case 'registered':
      case 'registered-updated':
        return 'Guest'
      case 'approved-buyer':
      case 'approved-buyer-updated':
        return 'Buyer'
      case 'approved-buyer-seller':
        return 'Buyer & Seller'
      case 'approved-seller':
        return 'Seller'
      default:
        return 'Guest'

    }

  }
  goHome () {
    this.props.history.replace('/main/upcoming')
  }

  logOut () {
    this.props.showModal({
      message: 'Do you wish to Leave the Live Auction or go to FarmGate Home area?',
      buttons: [
        {
          text: 'Cancel'
        },
        {
          text: 'Leave',
          onPress: () => {
            logOut.init({ leaveAuction: true })
          }
        },
        {
          text: 'Go Home',
          onPress: () => {
            this.goHome()
          }
        }
      ]
    })
  }

  render () {
    if (!this.props.auction) {
      return <div />
    }
    console.log(this.props.user.status,"this.props.userStatus+++");
    let userStatus =  this.userStatusToDisplay(this.props.user.status);
    return (
      <header className='live-app-header'>
        {this.state.mobile // swap the logout-wrapper and data
          ? <div className='live-header-wrap'>
            <div className='logo-wrapper' onClick={this.goHome}>
              <div className='logo' >
                <div className='logo-image' />
              </div>
              {/* <div className='logo-contact-number'>{this.state.contactNumber}</div> */}
            </div>
            <div className='logout-wrapper'>
              {this.props.user && this.props.user.firstName && <span className='logout' onClick={this.logOut}><FontAwesomeIcon className='icon-door' icon={faDoorOpen} />Leave Auction</span>}
              {(!this.props.user || (this.props.user && !this.props.user.firstName)) && <span className='logout' onClick={this.goHome}><FontAwesomeIcon className='icon-door' icon={faDoorOpen} />Login</span>}
            </div>
            <div className='data'>
              <div className='title-date'>
                <div className='title'>
                  <p className='auction-number'>
                    {this.props.auction.title}
                  </p>
                </div>
                <p className='auction-number sub'>Auction No. {this.props.auction.number}</p>
                <div className='date'>
                  {momentTz(this.props.auction.liveAt).format('dddd Do MMMM YYYY, h.mm A')}
                </div>
              </div>
              <div className='right-data'>
                {this.props.user && this.props.user.firstName && <div className='user-data'>
                  <div className='user-name'>
                    User: {this.props.user.firstName[0] ? `${this.props.user.firstName[0]}. ${this.props.user.lastName}` : ''}
                  </div>
                  <div className='user-id'>
                    ID: {this.props.user ? this.props.user.shortId : ''}
                  </div>
                </div>}
                <div className='timer'>
                  <StateTimerAuction auction={this.props.auction} />
                </div>
              </div>
            </div>
          </div>
          : <div className='live-header-wrap'>
            <div className='logo-wrapper' onClick={this.goHome}>
              <div className='logo' >
                <div className='logo-image' />
              </div>
              {/* <div className='logo-contact-number'>{this.state.contactNumber}</div> */}
            </div>
            <div className='data'>
              <div className='title-date'>
                <div className='title'>
                  <p className='auction-number'>
                    {this.props.auction.title}
                  </p>
                </div>
                <p className='auction-number sub'>Auction No. {this.props.auction.number}</p>
                <div className='date'>
                  {momentTz(this.props.auction.liveAt).format('dddd Do MMMM YYYY, h.mm A')}
                </div>
              </div>
              <div className='right-data'>
                {
                  <div className='live-page-permisson-level-box'>
                    <span>{userStatus.toUpperCase()}</span>
                  </div>
                }
                {this.props.user && this.props.user.firstName && <div className='user-data'>
                  <div className='user-name'>
                    User: {`${this.props.user.firstName[0]}. ${this.props.user.lastName}`}
                  </div>
                  <div className='user-id'>
                    ID: {this.props.user.shortId}
                  </div>
                </div>}
                <div className='timer'>
                  <StateTimerAuction auction={this.props.auction} />
                </div>
              </div>
            </div>
            <div className='logout-wrapper'>
              {this.props.user && this.props.user.firstName && <span className='logout' onClick={this.logOut}><FontAwesomeIcon className='icon-door' icon={faDoorOpen} />Leave Auction</span>}
              {(!this.props.user || (this.props.user && !this.props.user.firstName)) && <span className='logout' onClick={this.goHome}><FontAwesomeIcon className='icon-door' icon={faDoorOpen} />Login</span>}
            </div>
          </div>
        }
      </header>
    )
  }
}

LiveHeader.propTypes = {
  showModal: PropTypes.func.isRequired,
  auction: PropTypes.object
}

export default LiveHeader
