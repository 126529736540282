import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { changeStateProp, showModal } from '../../actions/index'
import Header from './Header'

const mapStateToProps = (state) => {
  return {
    userStatus: state.user.data ? state.user.data.status : '',
    firstName: state.user.data ? state.user.data.firstName : '',
    lastName: state.user.data ? state.user.data.lastName : '',
    onLotForm: state.temp.onLotForm
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeStateProp, showModal }, dispatch)
}

const HeaderContainer = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Header))

export default HeaderContainer
