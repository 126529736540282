import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { MAX_VENDORBIDS, NUMBER_REGEXP } from '../../../constants'
import Checkbox from '../../Checkbox/Checkbox'

class VendorBids extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      customVendorBids: this.props.maxVendorBids,
      changeVendorBids: this.props.maxVendorBids !== 0 && this.props.maxVendorBids !== 1
    }
    this.setZero = this.setZero.bind(this)
    this.setOne = this.setOne.bind(this)
    this.setOther = this.setOther.bind(this)
    this.handleVendorBids = this.handleVendorBids.bind(this)
    this.input = React.createRef()
  }
  static getDerivedStateFromProps (props, state) {
    if (props.maxVendorBids !== 0 && props.maxVendorBids !== 1 && !state.changeVendorBids) {
      return { changeVendorBids: true, customVendorBids: props.maxVendorBids }
    }
    if (state.changeVendorBids && state.customVendorBids !== props.maxVendorBids) {
      return { customVendorBids: props.maxVendorBids }
    }
    return null
  }
  setZero (enable) {
    if (!enable) return
    this.props.onChange('maxVendorBids', 0)
    this.setState({ changeVendorBids: false })
  }
  setOne (enable) {
    if (!enable) return
    this.props.onChange('maxVendorBids', 1)
    this.setState({ changeVendorBids: false })
  }
  setOther (enable) {
    if (!enable) return
    this.props.onChange('maxVendorBids', this.state.customVendorBids)
    this.setState({ changeVendorBids: true })
  }
  handleVendorBids (event) {
    let { value } = this.input.current
    if (NUMBER_REGEXP.test(value) || !value) {
      value = parseInt(value, 10) || 0
      value = Math.min(MAX_VENDORBIDS, value)
      this.props.onChange('maxVendorBids', value)
    }
  }
  render () {
    const { maxVendorBids, isOptiweigh } = this.props
    const { changeVendorBids, customVendorBids } = this.state
    return (
      <div className='inner-section-vendorbid'>
        <div className='row-text row-text-full'>
          A Vendor or Vendor’s Agent has the right to bid on behalf of
          the Vendor for their own Lot through our Auction System, only where such Vendor’s bid(s) is/are
          publicly disclosed on our website such as via the Lot Assessment form. Such a notification, forms
          part of the condition of sale. The Vendor or Vendor’s Agent is restricted to the number of bids noted
          on this Lot Assessment form.
        </div>
        <div className={`form-row ${this.props.disabled ? 'disabled' : ''}`}>
          <div className='row-title'>
            <p
              className='row-text'>Indicate Number of Vendor Bid/s {!isOptiweigh ? <span className='asterisk'>*</span> : null}:</p>
          </div>
          <div className='checkboxes'>
            <div className='form-row'>
              <div className='inner-line'>
                <Checkbox
                  type='green'
                  value={!changeVendorBids && maxVendorBids === 0}
                  onToggle={this.setZero} />
                <div className='row-text'>Nil</div>
              </div>
            </div>
            <div className='form-row'>
              <div className='inner-line'>
                <Checkbox
                  type='green'
                  value={!changeVendorBids && maxVendorBids === 1}
                  onToggle={this.setOne} />
                <div className='row-text'>1</div>
              </div>
            </div>
            <div className='form-row'>
              <div className='inner-line'>
                <Checkbox
                  type='green'
                  value={changeVendorBids}
                  onToggle={this.setOther} />
                <div className='row-text'>Other</div>
              </div>
              <div>
                <input
                  ref={this.input}
                  className={`input short${changeVendorBids ? '' : ' hidden'}`}
                  placeholder='0'
                  type='text'
                  value={customVendorBids}
                  onChange={this.handleVendorBids} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

VendorBids.propTypes = {
  maxVendorBids: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

VendorBids.defaultProps = {
  disabled: false
}

export default VendorBids
