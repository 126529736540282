import React, { Component } from 'react'
import PropTypes from 'prop-types'
import updateHelper from 'immutability-helper'
import axios from 'axios'
import Api from '../../services/api'
import * as Socket from '../../services/socket'
import HeaderContainer from '../Header/HeaderContainer'
import NavBarContainer from '../NavBar/NavBarContainer'
import RoutesContainer from '../../routes/RoutesContainer'
import NotificationContainer from '../Notifications/NotificationsContainer'
import Footer from '../Footer/Footer'
import { updateMainData } from '../../utils/UpdateData'
import logOut from '../../utils/logOut'

class MainApp extends Component {
  constructor (props) {
    super(props)
    this.state = {
      terms : null
    }
    this.onReconnect = this.onReconnect.bind(this);
    this.getTermsData = this.getTermsData.bind(this);
  }
  componentDidMount () {
    this.getTermsData()
    Socket.disconnectAll()
    Socket.connect(Socket.BIDS_NAMESPACE)
    Socket.connect(Socket.CATALOG_NAMESPACE)
    Socket.connect(Socket.USER_NAMESPACE)

    window.addEventListener('online', this.onReconnect)
    window.addEventListener('visibilitychange', this.onReconnect)
    Socket.attach(Socket.CATALOG_NAMESPACE, 'reconnect', 'home', this.onReconnect)

    this._kindUpdateCallback = async (update) => {
      // console.log('UPDATE KIND HOME', update)
      let kind = this.props.data.kinds.find(item => item._id === update.doc._id)
      if (kind) {
        this.props.mergeStateProp('kinds', [update.doc], 'data')
      } else {
        let res = await Api.getKind(update.doc._id, { silent: true })
        if (res && !res.isError) {
          this.props.mergeStateProp('kinds', [res.kind], 'data')
        }
      }
    }
    this._auctionUpdateCallback = async (update) => {
      // console.log('UPDATE AUCTION HOME', update)
      let auction = this.props.data.auctions.find(item => item._id === update.doc._id)
      if (auction) {
        this.props.mergeStateProp('auctions', [update.doc], 'data')
      } else {
        if (update.doc.hasOwnProperty('state')) {
          if (update.doc.state === 'closed') {
            return
          }
        }
        let res = null
        if (document.cookie.includes('BackUpServer=true')) {
          res = await Api.getAuction(update.doc._id, { silent: true })
        }
        if (res && !res.isError) {
          this.props.mergeStateProp('auctions', [res.auction], 'data')
        }
      }
    }
    let _lotUpdateData = async (lotId) => {
      let res = null
      if (document.cookie.includes('BackUpServer=true')) {
        res = await Api.getLot(lotId, { mode: 'short', silent: true })
      }
      if (res && !res.isError) {
        this.props.mergeStateProp('lots', [res.lot], 'data')
      }
    }
    this._lotUpdateCallback = (update) => {
      // console.log('UPDATE LOT HOME', update)
      let lot = this.props.data.lots.find(item => item._id === update.doc._id)
      if (lot) {
        if (update.doc.hasOwnProperty('auction') && lot.auction !== update.doc.auction) {
          _lotUpdateData(update.doc._id)
        } else if (update.doc.hasOwnProperty('approved') && !lot.approved && update.doc.approved) {
          _lotUpdateData(update.doc._id)
        }
        this.props.mergeStateProp('lots', [update.doc], 'data')
      } else {
        if (update.doc.hasOwnProperty('state')) {
          if (update.doc.state === 'closed') {
            return
          }
        }
        _lotUpdateData(update.doc._id)
      }
    }
    this._userUpdateCallback = async (update) => {
      // console.log('main app user update', update)
      if (update.status || update.type || update.isRequestedAsBuyer || update.isRequestedAsSeller || update.stockTypes || update.approvedStockTypes) {
        let userData = updateHelper(this.props.user, {
          status: { $set: update.status || this.props.user.status },
          type: { $set: update.type || this.props.user.type },
          isRequestedAsBuyer: { $set: update.isRequestedAsBuyer || this.props.user.isRequestedAsBuyer },
          isRequestedAsSeller: { $set: update.isRequestedAsSeller || this.props.user.isRequestedAsSeller },
          stockTypes: { $set: update.stockTypes || this.props.user.stockTypes },
          approvedStockTypes: { $set: update.approvedStockTypes || this.props.user.approvedStockTypes },
        });
        this.props.changeStateProp("data", userData, "user");
      }
      if (update.watchList2) {
        if (update.watchList2.length > this.props.user.watchList2.length) {
          let data = await Api.getLotsWatchlist(
            1,
            50,
            'liveAt',
            -1
          )
          this.props.mergeStateProp('lots', data.lots, 'data')
        }
        let userData = updateHelper(this.props.user, {
          watchList2: { $set: update.watchList2 }
        })
        this.props.changeStateProp('data', userData, 'user')
      }
      if (update.lot) {
        let lot = this.props.data.lots.find(item => item._id === update.lot._id)
        if (lot) {
          this.props.mergeStateProp('lots', [update.lot], 'data')
        }
        _lotUpdateData(update.lot._id)
      }
      if (update['lot-delete']) {
        this.props.filterStateProp('lots', item => item._id !== update['lot-delete']._id, 'data')
      }
      if (update.pic) {
        this.props.mergeStateProp('pics', [update.pic], 'user')
      }
      if (update['pic-delete']) {
        this.props.filterStateProp('pics', item => item._id !== update['pic-delete']._id, 'user')
      }
      if (update.changeOnlineState) {
        if (document.visibilityState === 'visible') {
          if (document.cookie.includes('BackUpServer=true')) {
            Api.putOnlineState('ACTIVE')
          }
        }
      }
    }
    // delete callback
    this._kindDeleteCallback = (kindId) => {
      // console.log('DELETE Kind', kindId)
      this.props.filterStateProp('kinds', item => item._id !== kindId, 'data')
    }
    this._auctionDeleteCallback = (auctionId) => {
      // console.log('DELETE Auction', auctionId)
      this.props.filterStateProp('auctions', item => item._id !== auctionId, 'data')
    }
    this._lotDeleteCallback = (lotId) => {
      // console.log('DELETE Lot', lotId)
      this.props.filterStateProp('lots', item => item._id !== lotId, 'data')
    }

    Socket.attach(Socket.CATALOG_NAMESPACE, 'kind', 'home', this._kindUpdateCallback)
    Socket.attach(Socket.CATALOG_NAMESPACE, 'auction', 'home', this._auctionUpdateCallback)
    Socket.attach(Socket.CATALOG_NAMESPACE, 'lot', 'home', this._lotUpdateCallback)

    Socket.attach(Socket.CATALOG_NAMESPACE, 'kind-delete', 'home', this._kindDeleteCallback)
    Socket.attach(Socket.CATALOG_NAMESPACE, 'auction-delete', 'home', this._auctionDeleteCallback)
    Socket.attach(Socket.CATALOG_NAMESPACE, 'lot-delete', 'home', this._lotDeleteCallback)

    Socket.attach(Socket.USER_NAMESPACE, 'user-update', 'home', this._userUpdateCallback)
  }
    async getTermsData () {
      let res =  await axios.get('/terms');
      if (res) {
        this.setState({
          terms : res
        })
      }
    }
  shouldComponentUpdate (nextProps, nextState) {
    return this.props.match !== nextProps.match ||
      this.props.location !== nextProps.location ||
      this.props.history !== nextProps.history ||
      nextState.terms !== this.state.terms
  }
  componentWillUnmount () {
    Socket.detach(Socket.CATALOG_NAMESPACE, 'reconnect', 'home')

    Socket.detach(Socket.CATALOG_NAMESPACE, 'kind', 'home')
    Socket.detach(Socket.CATALOG_NAMESPACE, 'auction', 'home')
    Socket.detach(Socket.CATALOG_NAMESPACE, 'lot', 'home')

    Socket.detach(Socket.CATALOG_NAMESPACE, 'kind-delete', 'home')
    Socket.detach(Socket.CATALOG_NAMESPACE, 'auction-delete', 'home')
    Socket.detach(Socket.CATALOG_NAMESPACE, 'lot-delete', 'home')

    Socket.detach(Socket.USER_NAMESPACE, 'user-update', 'home')

    window.removeEventListener('online', this.onReconnect)
    window.removeEventListener('visibilitychange', this.onReconnect)
  }
  onReconnect () {
    // only update data if tab is active
    if (document.visibilityState === 'visible' && this.props.hasData) {
      updateMainData()
    }
  }
  
  render () { 
    const { user } = this.props;
    if(!(user.acceptedNewVersionTerms) ) {
      return (<div> {
        this.props.showModal({
          title: "Terms and Conditions",
          showTAndC: true,
          message: "We've updated our terms and conditions. To proceed, please review and accept these changes",
          buttons: [
            {
              text: "Accept",
              onPress:() => {
                this.state.terms &&  user.olderVersionsAcceptedTo.push(this.state.terms.terms[0].version);
                Api.putUser({acceptedNewVersionTerms : true,status : user.status,acceptedNewVersionTermsOn : new Date(),currentVersionAcceptedTo : this.state.terms.terms[0].version, olderVersionsAcceptedTo : user.olderVersionsAcceptedTo}).then((userData) => {
                  this.props.showSpinner(false)
                  this.props.changeStateProp('data', userData.user, 'user')
                  window.location.reload()
                }).catch((error) => {
                  this.props.showSpinner(false)
                  console.error(error)
                })
              }
            },
            {
              text: "Deny",
              onPress: () => {
                //this.props.history.push(`/main/profile`);
                logOut.init()
              },
            },
          ],
        })
        
        }
  
      </div>)
      
    } else {
      return (
        <div className='main'>
          <HeaderContainer />
          <NavBarContainer />
          <NotificationContainer />
          <RoutesContainer />
          <Footer />
        </div>
      )
    }
    }

}

MainApp.propTypes = {
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  hasData: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default MainApp
