import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";
import { NavLink } from "react-router-dom";
import memoize from "memoize-one";
import FontAwesomeIcon from "@fortawesome/react-fontawesome";
import ReactHtmlParser from "react-html-parser";
import {
  faPlusSquare,
  faArrowRight,
  faCaretDown,
  faTimes,
  faFilter,
  faBalanceScale,
  faCalendarAlt,
} from "@fortawesome/fontawesome-free-solid";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

import {
  momentTz,
  limitTextLength,
  calculateLimitTextLength,
} from "../../../utils";
import FilterRangeAge from "../../FilterRangeAge/FilterRangeAge";
import FilterRange from "../../FilterRange/FilterRange";
import {
  isSeller,
  isAllowedToAddNewLot,
} from "../../../utils/UpdateData";
import {
  getSectionHeaderTitle,
  isAvailableAuction,
} from "../../../utils/Auctions";
import {
  getSexesByKinds,
  getStockCategoriesByKinds,
} from "../../../utils/Lots";
import { filterAuctions, sortAuctions } from "../../../utils/Filter";
import FilterKind from "../../FilterKind/FilterKind";
import FilterMultiSelect from "../../FilterMultiSelect/FilterMultiSelect";
import EmailModalAuction from "../../Modals/EmailModalAuctionContainer";
import Modal from "react-modal";
import { withCookies } from "react-cookie";
import Api from "../../../services/api";

import bookImage from "../../../assets/img/book.png";
import cowImage from "../../../assets/img/cowauction.png";
import lambImage from "../../../assets/img/lambauction.png";
import goatImage from "../../../assets/img/goat-image.png";
import hammerImage from "../../../assets/img/hammer.png";
import { subTitles } from "../../../constants/strings";
import { findFieldByPublicId } from "../../../utils/FindFieldKind";
import { pick as _pick } from 'lodash'

class AuctionsList extends PureComponent {
  constructor(props) {
    super(props);
    document.title = "FarmGate";
    this.state = {
      filters: {
        screen: this.getScreen(),
        breed: this.getSavedFilterValue("breed", []),
        sex: this.getSavedFilterValue("sex", []),
        age: this.getSavedFilterValue("age", []),
        weight: this.getSavedFilterValue("weight", []),
        showMode: this.getSavedFilterValue("showMode", "all"),
        state: this.getSavedFilterValue("state", []),
        client: this.getSavedFilterValue("client", []),
        stockCategory: this.getSavedFilterValue("stockCategory", []),
        selectedagents: this.getSavedFilterValue("selectedagents", []),
        agency: this.getSavedFilterValue("agency", []),
        assessorType: this.getSavedFilterValue("assessorType", []),
      },
      modalEmailIsOpen: false,
      modalTextIsOpen: false,
      modalAuction: null,
      clientTag: ["FarmBuy"],
      kinds: [],
    };
    this.getFilteredAuctions = memoize(this.getFilteredAuctions);
    this.groupAuctionsByDate = memoize(this.groupAuctionsByDate);
    this.changeShowMode = this.changeShowMode.bind(this);
    this.filterChangeHandler = this.filterChangeHandler.bind(this);
    this.clearFilter = this.clearFilter.bind(this);
    this.openEmailModal = this.openEmailModal.bind(this);
    this.closeEmailModal = this.closeEmailModal.bind(this);
    this.openTextModal = this.openTextModal.bind(this);
    this.closeTextModal = this.closeTextModal.bind(this);
  }
  componentDidMount() {
    if (this.props.history.action !== "POP") {
      window.scroll(0, 0);
    }

    this.getKinds();
  }

  getKinds() {
    Api.getKinds().then((data) => {
      this.setState({
        kinds: data.kinds,
      });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.filters !== prevState.filters) {
      this.props.mergeStateProp("filters", [this.state.filters], "user");
    }
    let scrollPosition = parseInt(window.localStorage.getItem('upcomingAuctionsListScrollPosition')) || 0
    let countOfAuctions = parseInt(window.localStorage.getItem('upcomingAuctionsListCount')) || 0
    if (countOfAuctions !== 0 && scrollPosition !== 0) {
      window.scrollTo(0, scrollPosition)
      if (this.props.auctions.length === countOfAuctions) {
        window.localStorage.clear()
      }
    }
  }
  getScreen() {
    return "upcomingAuctionsList";
  }
  getSavedFilterValue(key, defaultValue = []) {
    let screen = this.getScreen();
    let thisFilter = this.props.filters.find(
      (filterSet) => filterSet.screen === screen
    );
    let value;
    if (thisFilter) {
      value = thisFilter[key];
    }
    return value || defaultValue;
  }
  getFilteredAuctions(auctions, filters) {
    let filteredAuctions = auctions.filter((auction) => {
      return filters.showMode === "all"
        ? true
        : auction.kindData.type === filters.showMode;
    });
    return filterAuctions(filteredAuctions, filters);
  }
  openLive(auction) {
    if (this.props.isServerLive) {
      window.open(`#/auction-live/${auction.searchNumber}`);
    }
  }
  clearFilter() {
    this.setState(
      update(this.state, {
        filters: {
          showMode: { $set: "all" },
          client: { $set: [] },
          state: { $set: [] },
          age: { $set: [] },
          sex: { $set: [] },
          breed: { $set: [] },
          weight: { $set: [] },
          stockCategory: { $set: [] },
          selectedagents: { $set: [] },
          agency: { $set: [] },
          assessorType: { $set: [] },
        },
      })
    );
  }
  getAuctionStatus(auction) {
    let status;
    switch (auction.state) {
      case "closed":
        status = "Closed";
        break;
      case "open":
        status = "Open";
        break;
      case "live":
        status = "Live";
        break;
      case "future":
      default:
        status = "Upcoming";
        break;
    }
    return status;
  }
  getAuctionState(auction) {
    return auction.location.state.join("/");
  }
  getAuctionTypeShort(auction) {
    let type;
    switch (auction.auctionType) {
      case "simultaneous":
        type = "TIMED";
        break;
      case "sequential":
        type = "SEQ ONLINE";
        break;
      case "sequentialOffline":
        type = "INTERFACED";
        break;
      case "synchronous":
        type = "HELMSMAN";
        break;
      default:
        break;
    }
    return type;
  }
  goToLink(auction) {
    return `/main/upcoming/${auction.kindData.title}/${auction.searchNumber}`;
  }
  addNewLink(auction) {
    return `/main/add/new/${auction.kindData.title}/${auction.searchNumber}`;
  }
  filterChangeHandler(filterName, value) {
    this.setState({
      filters: update(this.state.filters, {
        [filterName]: { $set: value },
      }),
    });
  }
  groupAuctionsByDate(auctions) {
    let groupedAuctions = sortAuctions(auctions, "Date").reduce(
      (r, auction) => {
        let title = getSectionHeaderTitle(auction.liveAt);
        let group = r.find((gr) => gr.title === title);
        if (!group) {
          group = { title: title, auctions: [] };
          r.push(group);
        }
        group.auctions.push(auction);
        return r;
      },
      []
    );

    return groupedAuctions;
  }
  changeShowMode(showMode) {
    this.setState({
      filters: update(this.state.filters, {
        showMode: { $set: showMode },
      }),
    });
  }
  openEmailModal(auction) {
    this.setState({
      modalAuction: auction,
      modalEmailIsOpen: true,
    });
  }
  closeEmailModal(auction) {
    this.setState({
      modalAuction: null,
      modalEmailIsOpen: false,
    });
  }
  openTextModal() {
    this.setState({
      modalTextIsOpen: true,
    });
  }
  closeTextModal() {
    this.setState({
      modalTextIsOpen: false,
    });
  }

  navigateToAddLot(auction) {
    switch (this.props.userStatus) {
      case "partially-approved":
        this.props.showModal({
          message: "Please complete your profile to Add Lot",
          buttons: [
            {
              text: "Cancel",
            },
            {
              text: "Update",
              onPress: () => {
                this.props.history.push(`/main/profile`);
              },
            },
          ],
        });
        break;
      case "partially-approved:updated":
        this.props.showModal({
          message:
            "Your application is under review and should be approved in the next 24-hours. Please contact us if you need immediate access to Add a Lot to an Upcoming Auction.",
          buttons: [
            {
              text: "OK",
            },
          ],
        });
        break;
      default:
        this.props.history.push(this.addNewLink(auction));
        break;
    }
  }
  canAddLot(auction) {
    return isAvailableAuction(auction);
  }
  canOpenCatalogue = (auction) => {
    if (auction.privateSale) {
      return true;
    }
    return auction.lotCount > 0 || auction.withdrawnCount > 0;
  };
  showAdditionalDateInfo = (auction) => {
    return (
     
      auction.studSale &&
      auction.privateSale &&
      auction.additionalDateInfo &&
      auction.additionalDateInfo.title &&
      auction.additionalDateInfo.date
    );
  };
  renderItem(auction) {
    let html;
    if (auction.descriptionShort) {
      html =
        auction.descriptionShort.length >
          calculateLimitTextLength(auction.descriptionShort, 150) ||
        auction.descriptionShort.split(/\r\n|\r|\n/g).length >= 4
          ? "...Read More"
          : "";
    } else if (auction.description) {
      html =
        auction.description.length >
          calculateLimitTextLength(auction.description, 150) ||
        auction.description.split(/\r\n|\r|\n/g).length >= 4
          ? "...Read More"
          : "";
    } else {
      html = "";
    }
    return (
      <div className="auction-item" key={auction._id}>
        <div className="auction-img-container">
          {auction.state === "open" ? (
            <div className="enter-auction-wrapper enter-auction-wrapper-open">
              <img src={hammerImage} alt="Hammer icon" />
              <p
                className="btn enter-auction-btn"
                onClick={() => this.openLive(auction)}
              >
                <span>
                  {auction.allowAutoBid
                    ? "Set your Auto-bids"
                    : "Auction is open"}
                </span>
                <span className="icon-wrapper">
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </p>
            </div>
          ) : auction.state === "live" ? (
            <div className="enter-auction-wrapper">
              <img src={hammerImage} alt="Hammer icon" />
              <p
                className="btn enter-auction-btn"
                onClick={() => this.openLive(auction)}
              >
                <span>Enter Auction</span>
                <span className="icon-wrapper">
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </p>
            </div>
          ) : auction.image ? (
            <img
              className="auction-img"
              src={auction.image.url}
              alt="Auction img"
            />
          ) : (
            <span className={`no-image ${auction.kindData.type}`} alt="Lot">
              No image
            </span>
          )}
        </div>
        <div className="auction-info">
          <div className="auction-info-top">
            <div>
              <h3 className="auction-title">{auction.title}</h3>
              {auction.state === "open" && (
                <div> 
                  <p className="auction-live-info">Auction is open</p> 
                  <p className="auction-live-date">Auction Starts:</p>
                  <p className="auction-live-date">
                    {momentTz(auction.liveAt).format("ddd D MMM YYYY - h.mm A")}
                  </p>
                  </div>
              )}
              {auction.state === "live" && (
                <div>
                  <p className="auction-live-info">Auction is live</p>
                  <p className="auction-live-date">Auction Starts:</p>
                  <p className="auction-live-date">
                    {momentTz(auction.liveAt).format("ddd D MMM YYYY - h.mm A")}
                  </p>
                </div>
                
              )}
              {auction.state !== "live" && auction.state !== "open" && (
                <div>
                  <p className="auction-live-date">Auction Starts:</p>
                  <p className="auction-live-date">
                    {momentTz(auction.liveAt).format("ddd D MMM YYYY - h.mm A")}
                  </p>
                  {isSeller() &&
                    isAllowedToAddNewLot(
                      auction.addNewLotToSpecificAgents,
                      auction.specificAgents
                    ) && (
                      <React.Fragment>
                        <p className="auction-live-date">Add New Lot Closes:</p>
                        <p className="auction-live-date">
                          {momentTz(auction.editLotDisabledAt).format(
                            "ddd D MMM YYYY - h.mm A"
                          )}
                        </p>
                      </React.Fragment>
                    )}
                  {this.showAdditionalDateInfo(auction) && !isAllowedToAddNewLot(
                      auction.addNewLotToSpecificAgents,
                      auction.specificAgents
                    ) && (
                    <React.Fragment>
                      <p className="auction-live-date">
                        {auction.additionalDateInfo.title}:
                      </p>
                      <p className="auction-live-date">
                        {momentTz(auction.additionalDateInfo.date).format(
                          "ddd D MMM YYYY - h.mm A"
                        )}
                      </p>
                    </React.Fragment>
                  )}
                </div>
              )}
            </div>
            <div className="info-btns">
              {this.canOpenCatalogue(auction) ? (
                <NavLink className="btn" onClick={() => {
                  window.localStorage.setItem('upcomingAuctionsListScrollPosition', window.scrollY)
                  window.localStorage.setItem('upcomingAuctionsListCount', this.props.auctions.length)
                  window.localStorage.removeItem('upcomingLotsListScrollPosition')
                  window.localStorage.removeItem('upcomingLotsListCount')
                }} to={this.goToLink(auction)}>
                  <span>View Catalogue</span>
                  <span className="icon-wrapper icon-warning">
                    <img src={bookImage} alt="Book icon" />
                  </span>
                </NavLink>
              ) : (
                <div className="btn" onClick={this.openTextModal}>
                  <span>View Catalogue</span>
                  <span className="icon-wrapper icon-warning">
                    <img src={bookImage} alt="Book icon" />
                  </span>
                </div>
              )}
              <div
                className="btn"
                onClick={() => {
                  if (document.cookie.includes("BackUpServer=true")) {
                    if (auction.lotCount > 0 || auction.withdrawnCount > 0) {
                      this.openEmailModal(auction);
                    } else {
                      this.openTextModal();
                    }
                  }
                }}
              >
                <span>Email Catalogue</span>
                <span className="icon-wrapper icon-success">
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
              </div>
              {this.canAddLot(auction) &&
                isAllowedToAddNewLot(
                  auction.addNewLotToSpecificAgents,
                  auction.specificAgents
                ) &&
                <div
                  className="btn "
                  onClick={() => {
                    if ((this.props.user.approvedStockTypes && this.props.user.approvedStockTypes.includes(auction.kindData.type === "sheep-abblamb" ? "Sheep" : auction.kindData.title)) ) {
                      this.navigateToAddLot(auction)
                    } else {
                      this.props.showModal({
                        title: 'FarmGate',
                        message: 'Please request permission to Sell by clicking My Admin > Upgrade Account > Register to Buy & Sell.'
                      })
                    }
                  }}
                >
                  <span>Add New Lot</span>
                  <span className="icon-wrapper icon-success">
                    <FontAwesomeIcon icon={faPlusSquare} />
                  </span>
                </div>
              }
            </div>
          </div>
          <div className="auction-info-middle">
            <div className="auction-type">
              {auction.kindData.type === "cattle" && (
                <img src={cowImage} alt="Cattle" />
              )}
              {auction.kindData.type === "sheep" && (
                <img src={lambImage} alt="Sheep" className="icon-blackened" />
              )}
              {auction.kindData.type === "goat" && (
                <img src={goatImage} alt="Goat" />
              )}
            </div>
            <div className="auction-desc">
              {ReactHtmlParser(
                limitTextLength(
                  auction.descriptionShort || auction.description,
                  calculateLimitTextLength(auction.description, 150),
                  false
                )
              )}
              <span
                className="auction-readMore"
                onClick={() => {
                  window.localStorage.setItem('upcomingAuctionsListScrollPosition', window.scrollY)
                  window.localStorage.setItem('upcomingAuctionsListCount', this.props.auctions.length)
                  window.localStorage.removeItem('upcomingLotsListScrollPosition')
                  window.localStorage.removeItem('upcomingLotsListCount')
                  if (this.canOpenCatalogue(auction)) {
                    this.props.history.push(this.goToLink(auction));
                  } else {
                    this.openTextModal();
                  }
                }}
              >
                {ReactHtmlParser(html)}
              </span>
            </div>

            <div className="auction-logo">
              {auction.logo && (
                <img src={auction.logo.url} alt="Auction Logo" />
              )}
            </div>
          </div>
          <div className="auction-info-bottom">
            <span className="auction-number">Auction No. {auction.number}</span>
            <span className="status">
              Status: {this.getAuctionStatus(auction)}
            </span>
            <span className="location-state">
              {this.getAuctionState(auction)}
            </span>
            {auction.headCount > 0 && (
              <span className="heads">{auction.headCount} Head</span>
            )}
            {auction.lotCount > 0 && (
              <span className="lots">{auction.lotCount} Lots</span>
            )}
            <span className="lots">{this.getAuctionTypeShort(auction)}</span>
          </div>
        </div>
      </div>
    );
  }
  renderLockedItem(auction) {
    return (
      <div className="locked-item" key={auction._id}>
        <span className="result">cancelled</span>
        {this.renderItem(auction)}
      </div>
    );
  }

  selectOptions(kinds, auctions, showMode) {
    let kindArray = [], agentArray = [], agencyArray = [], breedArray = [], assessorTypesArray = [];
    kinds.forEach((kind) => kindArray.push(kind.type));
    if (kinds.length > 0) {
      let cattleKind = kinds.find(item => item.type === 'cattle')
      assessorTypesArray = findFieldByPublicId(cattleKind.form.forms, "assessorType").options
    }
    auctions.forEach((auction) => {
      if (auction.lotData) {
        let data = auction.lotData;
        data.forEach(
          (lot) =>{
            agentArray.push(
              `${lot.creatorData.firstName} ${lot.creatorData.lastName}`
            );
            agencyArray.push(lot.creatorData.tradingName);
            if(showMode === "all"){
              breedArray.push(
                lot.publicDetails.majorBreed,
                ...lot.inlineDetails.sire,
                ...lot.inlineDetails.dam ?? ''
              );
            }else{
              if(lot.kindData.type === showMode){
                breedArray.push(
                  lot.publicDetails.majorBreed,
                  ...lot.inlineDetails.sire,
                  ...lot.inlineDetails.dam ?? ''
                );
              }
            }
          
          }
        
        );
      } else {
        return
      }
    });
    agentArray = Array.from(new Set(agentArray));
    agencyArray = Array.from(new Set(agencyArray));
    breedArray = Array.from(new Set(breedArray));
    return {
      /* breed: clienTag
        ? clienTag === "Farmgate"
          ? getKindOptions(kinds, "majorBreed", { sort: true })
          : breedArray
        : getKindOptions(kinds, "majorBreed", { sort: true }), */
      breed: breedArray,
      stockCategory: getStockCategoriesByKinds(kindArray, { sort: true }),
      sex: getSexesByKinds(kindArray, { sort: true }),
      agentss: agentArray,
      agencyArray: agencyArray,
      assessorType: assessorTypesArray,
    };
  }

  rangeOptions(auctions) {
    let lots = [];
    auctions.forEach((auction) => {
      if (auction.lotData) {
        let data = auction.lotData
        lots.push(...data)
      } else {
        return
      }
    });
    // find min/max values only in approved lots (draft lots can have crazy values)
    lots = lots.filter((lot) => lot.approved);
    let ages = lots.map((lot) => lot.publicDetails.age.max).filter(Boolean);
    let weights = lots
      .map((lot) => lot.publicDetails.weight.average)
      .filter(Boolean);
    let ageMax = Math.round(Math.max(...ages));
    let weightMax = Math.round(Math.max(...weights));
    return {
      age: {
        min: 0,
        max: isFinite(ageMax) ? Math.max(ageMax, 0) : 0,
      },
      weight: {
        min: 0,
        max: isFinite(weightMax) ? Math.max(weightMax, 0) : 0,
      },
    };
  }

  getTags(auctiontags) {
    let tags = [];
    auctiontags.forEach((tag) => {
      tags.push(tag.title);
    });

    return tags;
  }

  async registerInterestApi() {
    try {
      this.props.showSpinner(true)
      let postData = _pick(this.props.user, [
        'numericId',
        'firstName',
        'lastName',
        'email',
        'phone',
      ])
      let registerInterest = await Api.registerInterest(postData)
      this.props.showSpinner(false)
      if (registerInterest) {
        this.props.showModal({
          message: registerInterest.success ?? ''
        })
      }
    } catch (e) {
      console.error('error', e)
      this.props.showSpinner(false)
    }
  }
  render() {
    const { auctions, locationStates } = this.props;
    const { filters, kinds } = this.state;
    const ranges = this.rangeOptions(auctions);
    const {
      showMode,
      state,
      breed,
      sex,
      stockCategory,
      age,
      weight,
      assessorType,
    } = filters;
    const selectOptions = this.selectOptions(kinds, auctions,showMode);
    let filteredAuctions = this.getFilteredAuctions(auctions, filters);
    let sydneyDate = `${momentTz(new Date()).format("D/M/YYYY")}`;
    let sydneyTime = `${momentTz(new Date()).format("h:mm A")}`;
    return (
      <div className="auctions-wrapper">
        <EmailModalAuction
          openModal={this.openEmailModal}
          closeModal={this.closeEmailModal}
          isOpen={this.state.modalEmailIsOpen}
          auction={this.state.modalAuction}
        />
        <Modal
          isOpen={this.state.modalTextIsOpen}
          ariaHideApp={false}
          onRequestClose={this.closeTextModal}
          overlayClassName="overlay"
          className="content"
          portalClassName="ReactModalPortal-Email"
        >
          <div className="modal-body">
            <h2 className="modal-catalogue-title">
              Catalogue is being compiled. Check back later.
            </h2>
            <div className="buttons">
              <div className="button ok-button" onClick={this.closeTextModal}>
                <span className="button-text">OK</span>
              </div>
            </div>
          </div>
        </Modal>
        <h1 className="fg-header">Upcoming Auctions</h1>
        <h4 className="fg-sub-header">
          All times displayed are in Sydney Time{" "}
          <span role="img" aria-label="Sydney time">
            &#x1F556;
          </span>{" "}
          {sydneyDate}&nbsp;&nbsp;{sydneyTime}
        </h4>
        <p className="fg-paragraph">
          {isSeller()
            ? subTitles.UPCOMING_AUCTIONS
            : subTitles.UPCOMING_AUCTIONS_BUYERS}
        </p>
        <div className="auctions-controls">
          <h3 className="filters-heading">Filters</h3>
          <div className="auctions-filters-wrapper">
            <FilterKind
              categoryChoice={showMode}
              changeCategoryChoice={this.changeShowMode}
            />
            <FilterMultiSelect
              title="State"
              name="state"
              options={locationStates}
              selectedOptions={state}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faCaretDown} size="lg" />}
              buttonLg
            />
            <FilterMultiSelect
              title="Breed"
              name="breed"
              options={selectOptions.breed}
              selectedOptions={breed}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faFilter} />}
            />
            {/* <FilterMultiSelect
              title="Other"
              name="client"
              options={clientTag}
              selectedOptions={client}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faCaretDown} size="lg" />}
              buttonLg
            /> */}
            <FilterMultiSelect
              title="Stock Category"
              name="stockCategory"
              options={selectOptions.stockCategory}
              selectedOptions={stockCategory}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faCaretDown} size="lg" />}
            />

            <FilterMultiSelect
              title="Sex"
              name="sex"
              options={selectOptions.sex}
              selectedOptions={sex}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faCaretDown} size="lg" />}
            />
            {/* <FilterMultiSelect
              title="Listing Agent"
              name="selectedagents"
              options={selectOptions.agentss}
              selectedOptions={selectedagents}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faCaretDown} size="lg" />}
              buttonLg
            />
            <FilterMultiSelect
              title="Listing Agency"
              name="agency"
              options={selectOptions.agencyArray}
              selectedOptions={agency}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faCaretDown} size="lg" />}
              buttonLg
            /> */}
            <FilterMultiSelect
              title="Assessor Type"
              name="assessorType"
              options={selectOptions.assessorType}
              selectedOptions={assessorType}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faCaretDown} size="lg" />}
              buttonLg
            />
            <FilterRange
              title="Weight"
              name="weight"
              unit="Kg"
              description="Please type your minimum and maximum weight or use slide below."
              belowDescription="Please note: the filters are set from zero to the highest weight available in the Catalogue."
              min={ranges.weight.min}
              max={ranges.weight.max}
              value={weight}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faBalanceScale} />}
            />
            <FilterRangeAge
              title="Age"
              name="age"
              unitY="Years"
              unitM="Months"
              description="Please type your minimum and maximum age in Months or Years or use slide below."
              belowDescription="Please note: the filters are set from zero to the highest age available in the Catalogue."
              min={ranges.age.min}
              max={ranges.age.max}
              value={age}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faCalendarAlt} />}
            />
            <div
              className="btn filter-btn filter-btn-sm clear-all-btn"
              onClick={this.clearFilter}
            >
              <span>Clear All</span>
              <span className="icon-wrapper icon-danger">
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </div>
          </div>
        </div>
        <div className="auctions-list list">
          {this.groupAuctionsByDate(filteredAuctions).map((group) => {
            return (
              <section className="auctions-group" key={group.title}>
                <p className="group-title">{group.title}</p>
                {group.auctions.map((auction) => {
                  if (auction.cancelled) {
                    return this.renderLockedItem(auction);
                  } else {
                    return this.renderItem(auction);
                  }
                })}
              </section>
            );
          })}
          {!filteredAuctions.length ? (
            <p className="auctions-empty">No auctions found.</p>
          ) : null}
          {!filteredAuctions.length && (
            <div><p className="register-interest-text">
              {subTitles.INTEREST_OF_SALE}
            </p>
              <div className="register-interest-div">
                <span className='btn' onClick={() => {
                  this.registerInterestApi()
                }}>
                  <span>REGISTER INTEREST</span>
                  <span className='icon-wrapper icon-success'><FontAwesomeIcon icon={faEnvelope} /></span>
                </span>
              </div>
            </div>)}
        </div>
      </div>
    );
  }
}

AuctionsList.propTypes = {
  auctions: PropTypes.array.isRequired,
  changeStateProp: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
};
AuctionsList.defaultProps = {};

export default withCookies(AuctionsList);
