import React, { PureComponent } from 'react'
import { bindActionCreators } from 'redux'
import { changeStateProp, showSpinner } from '../actions/index'
import Api from '../services/api'
import store from '../services/store'
import { alphabeticalSort } from '../utils'

let bound = bindActionCreators({ changeStateProp, showSpinner }, store.dispatch)
let fetching = false

class UpdateComponent extends PureComponent {
  componentDidMount() {
    if (!fetching) {
      bound.showSpinner(true)
      updateMainData(() => {
        bound.showSpinner(false)
      })
    }
  }
  render() {
    return <div />
  }
}

export function onEnter(component, isServerLive) {
  if (!isServerLive) {
    return component
  } else {
    let { hasData } = store.getState().temp
    return hasData ? component : <UpdateComponent />
  }
}

export function sellerOnly(component) {
  return isSeller() ? component : null
}

export function isSeller() {
  let user = store.getState().user
  // we do not store any user data in browser storage,
  // so we may assume user is seller if he hasn't loaded yet
  if (user.data && !user.data._id) {
    return true
  }
  return user.data && (user.data.type === 'seller' || user.data.role === 'admin')
}

export function isBuyer() {
  let user = store.getState().user
  // we do not store any user data in browser storage,
  // so we may assume user is seller if he hasn't loaded yet
  if (user.data && !user.data._id) {
    return true
  }
  return user.data && user.data.type === 'buyer'
}

export function getUserData() {
  return store.getState().user.data
}

export function isGuest() {
  let user = store.getState().user
  // we do not store any user data in browser storage,
  // so we may assume user is seller if he hasn't loaded yet
  if (user.data && !user.data._id) {
    return true
  }
  return user.data && user.data.status === 'registered'
}
export function isGuestUpdated() {
  let user = store.getState().user
  // we do not store any user data in browser storage,
  // so we may assume user is seller if he hasn't loaded yet
  if (user.data && !user.data._id) {
    return true
  }
  return user.data && user.data.status === 'registered-updated'
}
export function isApprovedToBuy() {
  let user = store.getState().user
  // we do not store any user data in browser storage,
  // so we may assume user is seller if he hasn't loaded yet
  if (user.data && !user.data._id) {
    return true
  }
  return user.data && user.data.status === 'approved-buyer'
}
export function isApprovedBuyerUpdated() {
  let user = store.getState().user
  // we do not store any user data in browser storage,
  // so we may assume user is seller if he hasn't loaded yet
  if (user.data && !user.data._id) {
    return true
  }
  return user.data && user.data.status === 'approved-buyer-updated'
}

export function isApprovedToSell() {
  let user = store.getState().user
  // we do not store any user data in browser storage,
  // so we may assume user is seller if he hasn't loaded yet
  if (user.data && !user.data._id) {
    return true
  }
  return user.data && user.data.status === 'approved-seller'
}

export function adminOnly(component) {
  return isAdmin() ? component : null
}

export function isAdmin() {
  let user = store.getState().user
  return user.data && user.data.role === 'admin'
}

export async function updateInitialData() {
  let data = [
    await Api.getStates(),
    await Api.getStockCategories()
  ]
  if (data.every(Boolean)) {
    let [{ states }, { stockCategories }] = data
    let locationStates = alphabeticalSort(Object.keys(states))
    bound.changeStateProp('locationStates', locationStates, 'data')
    bound.changeStateProp('locationDistricts', states, 'data')
    bound.changeStateProp('stockCategories', stockCategories, 'data')
  } else {
    setTimeout(() => updateInitialData(), 5000)
  }
}

export async function updateMainData(callback = null) {
  fetching = true
  let requests = [
    Api.getKinds(),
    Api.getAuctions(),
    Api.getUser()
  ]

  let data = await Promise.all(requests)
  if (data.every(Boolean)) {

    let [
      { kinds },
      { auctions },
      { user }
    ] = data

    bound.changeStateProp('auctions', auctions, 'data')
    bound.changeStateProp('kinds', kinds, 'data')
    bound.changeStateProp('data', user, 'user')
    bound.changeStateProp('hasData', true, 'temp')
    setTimeout(() => { callback && callback() }, 0)
  }
  fetching = false
}

export function isAllowedToAddNewLot(addNewLotToSpecificAgents, specificAgents = []) {
  if (addNewLotToSpecificAgents) {
    if (specificAgents && specificAgents.length > 0) {
      let user = store.getState().user
      if (user.data && !user.data._id) {
        return false
      }
      let isAllowed = user.data && specificAgents.includes(user.data._id)
      return isAllowed
    } else {
      return false
    }
  } else {
    // allowed if addNewLotToSpecificAgents is false 
    return true
  }
}