import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faCalculator } from '@fortawesome/fontawesome-free-solid'
import React from 'react'
import './Calculator.css'

export function Calculator () {
  return (<div className='calculator'>
    <div className='logo' />
    <p className='text'>
      Need finance? 100% finance for livestock*.
      *Subject to approval in accordance with StockCo’s eligibility criteria
    </p>
    <a href='https://farmgateauctions.com.au/stockco-calculator/' target='_blank' rel='noopener noreferrer' className='button-txt btn'>
      <span className='button-txt'>
        Calculator
      </span>
      <span className='icon'><FontAwesomeIcon icon={faCalculator} /></span>
    </a>
  </div>)
}
