import React from 'react'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/fontawesome-free-solid'

const AuthCheckbox = props => (
  <label className= {`input-container ${props.labelClassName2 || ''}`}>
    <div className='auth-checkbox'>

      <div style={{display : 'flex',flexDirection:"column"}}>      
      <span className={`auth-checkbox-label ${props.labelClassName || ''}`}>{props.label}</span>
      </div>

      <div className='auth-checkbox-inner'>
        <input
          type='checkbox'
          name={props.name}
          checked={props.checked}
          onChange={props.onChange}
          disabled = {props.disabled ? true : false}
        />
        <span>
          {
            props.checked &&
            <FontAwesomeIcon
              icon={faCheck}
              className='auth-checkbox-inner-icon'
            />
          }
        </span>
      </div>
    </div>
  </label>
)

export default AuthCheckbox
