import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router'

import Upcoming from '../components/Upcoming/UpcomingContainer'
import ProfileRoute from '../components/Profile/ProfileRoute'
import Watchlist from '../components/Watchlist/Watchlist'
import Results from '../components/Results/Results'
import ManageLots from '../components/ManageLots/ManageLots'
import AddLot from '../components/ManageLots/AddLot/AddLot'
import ContactUsContainer from '../components/ContactUs/ContactUsContainer'
import Tools from '../components/Tools/ToolsContainer'
import { sellerOnly } from '../utils/UpdateData'

class Routes extends PureComponent {
  render () {
    return (
      <div className='app-routes'>
        <Switch>
          <Route path='/main/upcoming' component={Upcoming} />
          <Route path='/main/watchlist' render={(obj) => {
            if (!this.props.isServerLive) {
              return <Redirect to='/main/upcoming' />
            } else {
              return <Watchlist {...obj} />
            }
          }} />
          <Route path='/main/results' render={(obj) => {
            if (!this.props.isServerLive) {
              return <Redirect to='/main/upcoming' />
            } else {
              return <Results {...obj} />
            }
          }} />

          {
            sellerOnly(<Route path='/main/add/new' render={(obj) => {
              if (!this.props.isServerLive) {
                return <Redirect to='/main/upcoming' />
              } else {
                return <AddLot {...obj} />
              }
            }} />)
          }
          <Route path='/main/manage' render={(obj) => {
            if (!this.props.isServerLive) {
              return <Redirect to='/main/upcoming' />
            } else {
              return <ManageLots {...obj} />
            }
          }} />
          <Route path='/main/profile' render={(obj) => {
            if (!this.props.isServerLive) {
              return <Redirect to='/main/upcoming' />
            } else {
              return <ProfileRoute {...obj} />
            }
          }} />
          <Route path='/main/tools' render={(obj) => {
            if (!this.props.isServerLive) {
              return <Redirect to='/main/upcoming' />
            } else {
              return <Tools {...obj} />
            }
          }} />
          <Route path='/main/contact' render={(obj) => {
            if (!this.props.isServerLive) {
              return <Redirect to='/main/upcoming' />
            } else {
              return <ContactUsContainer {...obj} />
            }
          }} />
          <Redirect to='/main/upcoming' />
        </Switch>
      </div>
    )
  }
}

Routes.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

Routes.defaultProps = {}

export default Routes
