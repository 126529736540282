import React, { PureComponent } from 'react'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faUser,
  faGavel
} from '@fortawesome/fontawesome-free-solid'
import memoize from 'memoize-one'

import * as LotUtils from '../../../../utils/Lots'
import StateTimerLotContainer from '../../../StateTimerLot/StateTimerLotContainer'
import WatchButton from '../../../WatchButton/WatchButtonContainer'
import { bidTypes } from '../../../../constants'

class RenderLot extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      currentStatus: this.getStatus(),
      isWatched: false,
      autoBidStatus: props.autoBidStatus,
      currentFormatDefault: true,
      bidAnimation: false,
      bidAnimationShort: false,
      deviceWidth: window.innerWidth <= 1024,
      middleWidth: window.innerWidth > 1024 && window.innerWidth < 1450,
      innerWidth: window.innerWidth,
      offsetMs: props.offsetMs,
      fade: false
    }

    if (props.auction.auctionType === 'synchronous') {
      this.interval = setInterval(() => this.updateStatus(), 1000)
      this.fadeInterval = setInterval(() => {
        this.setState({ fade: !this.state.fade })
      }, 3500)
    }

    this.getResultMessage = memoize(this.getResultMessage)
    this.statusClassName = memoize(this.statusClassName)
    this.isEnding = memoize(this.isEnding)
    this.disable = null
  }

  componentDidMount () {
    this.checkStatusOfPriceFormat()
    window.addEventListener('resize', this.resizeWindow)
  }

  componentDidUpdate () {
    let status = this.getStatus()
    if (this.state.currentStatus !== status) {
      this.updateStatus()
      this.checkStatusOfPriceFormat()
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.resizeWindow)
    if (this.interval) {
      clearInterval(this.interval)
    }
    if (this.fadeInterval) {
      clearInterval(this.fadeInterval)
    }
  }

  resizeWindow = (event) => {
    if (event.target.innerWidth <= 1024) {
      this.setState({
        deviceWidth: true,
        middleWidth: false,
        innerWidth: event.target.innerWidth
      })
    } else if (event.target.innerWidth > 1024 && event.target.innerWidth < 1450) {
      this.setState({
        deviceWidth: false,
        middleWidth: true,
        innerWidth: event.target.innerWidth
      })
    } else {
      this.setState({
        deviceWidth: false,
        middleWidth: false,
        innerWidth: event.target.innerWidth
      })
    }
  }

  isPriceSwapped () {
    let { biddingSwapLots, lot } = this.props
    return biddingSwapLots.includes(lot._id)
  }

  checkStatusOfPriceFormat = () => {
    let { auctionLiveLotsFormat, lot } = this.props
    let currentLot = auctionLiveLotsFormat.find((lotFormatted) => {
      return lotFormatted._id === lot._id
    })
    if (currentLot) {
      if (this.state.currentFormatDefault !== currentLot.currentFormatDefault) {
        this.setState({
          currentFormatDefault: currentLot.currentFormatDefault
        })
      }
    } else {
      let newArr = [...auctionLiveLotsFormat]
      newArr.push({ _id: lot._id, currentFormatDefault: true })
      this.props.changeStateProp('auctionLiveLotsFormat', newArr, 'temp')
    }
  }

  toggleStatusOfPriceFormat = () => {
    let { auctionLiveLotsFormat, lot } = this.props
    let newArr = [...auctionLiveLotsFormat]
    newArr.forEach((lotFormatted) => {
      if (lotFormatted._id === lot._id) {
        lotFormatted.currentFormatDefault = !lotFormatted.currentFormatDefault
      }
    })
    this.props.changeStateProp('auctionLiveLotsFormat', newArr, 'temp')
    this.checkStatusOfPriceFormat()
  }

  getCurrentBid (price) {
    let { lot } = this.props
    const { HEAD, KG } = bidTypes
    let isSwapped = this.isPriceSwapped()
    let formattedPrice, measure
    switch (true) {
      case lot.bidding === HEAD && !isSwapped:
      default:
        formattedPrice = LotUtils.makePrice(price, { bidding: bidTypes.HEAD })
        measure = 'head'
        break
      case lot.bidding === HEAD && isSwapped:
        formattedPrice = LotUtils.makePrice(LotUtils.convertDollarToCentsPrice(price, lot), { bidding: bidTypes.KG })
        measure = 'kg'
        break
      case lot.bidding === KG && !isSwapped:
        formattedPrice = LotUtils.makePrice(price, { bidding: bidTypes.KG })
        measure = 'kg'
        break
      case lot.bidding === KG && isSwapped:
        formattedPrice = LotUtils.makePrice(LotUtils.convertCentsToDollarPrice(price, lot), { bidding: bidTypes.HEAD })
        measure = 'head'
        break
    }
    return (
      <React.Fragment>
        {formattedPrice}<span>/{measure}</span>
      </React.Fragment>
    )
  }

  getStatus () {
    return LotUtils.getRunningStatus(this.props.lot, this.props.auction, this.props.userId)
  }

  getResultMessage (lot, userId, auction) {
    return LotUtils.getResultMessage(lot, userId, auction)
  }

  updateStatus = () => {
    let status = this.getStatus()
    if (this.state.currentStatus !== status) {
      this.setState({
        currentStatus: status
      })
    }
  }

  getBiddingStatus ({ lot, deviceWidth, auction }) {
    const state = auction.auctionType === 'synchronous' ? auction.state : lot.state
    let { lotList } = this.props
    let list = !deviceWidth && lotList ? 'list' : deviceWidth && lotList ? 'device' : ''
    if (state === 'closed' || lot.withdrawn || lot.soldBeforeAuction) {
      return <span className={`lot-status ${list} closed`}>{this.getResultMessage(lot, this.props.userId, auction)}</span>
    }
    if (state === 'futureInLive') {
      return (
        <div className='status-box'>
          <span className={`lot-status ${list} black`}>UPCOMING</span>
        </div>
      )
    }
    if (state !== 'live') {
      return null
    }
    if (this.isEnding(this.state.currentStatus)) {
      return (
        <div className='status-box'>
          {this.props.auction && this.props.auction.auctionType === 'sequential' && <span className={`lot-status ${list} ending`}>Lot Closing</span>}
          {lot.reserveStatus === 'awaitingBids' &&
          <span className={`lot-status ${list} ${this.props.auction && this.props.auction.auctionType !== 'sequentialOffline' ? 'awaiting' : ''} ${this.props.auction && this.props.auction.auctionType === 'sequential' && 'closing'}`}>{this.checkStatus('Awaiting Bids')}</span>
          }
          {lot.reserveStatus === 'nearReserve' &&
          <span className={`lot-status ${list} ${this.props.auction && this.props.auction.auctionType !== 'sequentialOffline' ? 'nearing' : ''} ${this.props.auction && this.props.auction.auctionType === 'sequential' && 'closing'}`}>{this.checkStatus('Nearing Reserve')}</span>
          }
          {lot.reserveStatus === 'onMarket' &&
          <span className={`lot-status ${list} ${this.props.auction && this.props.auction.auctionType !== 'sequentialOffline' ? 'on-market' : ''} ${this.props.auction && this.props.auction.auctionType === 'sequential' && 'closing'}`}>{this.checkStatus('On Market')}</span>
          }
          {lot.reserveStatus === 'notNear' &&
          <span className={`lot-status ${list} ${this.props.auction && this.props.auction.auctionType !== 'sequentialOffline' ? 'open' : ''} ${this.props.auction && this.props.auction.auctionType === 'sequential' && 'closing'}`}>{this.checkStatus('Opening Bids')}</span>
          }
        </div>
      )
    }
    switch (lot.reserveStatus) {
      case 'awaitingBids':
        return <span className={`lot-status ${list} ${this.props.auction && this.props.auction.auctionType !== 'sequentialOffline' ? 'awaiting' : ''}`}>{this.checkStatus('Awaiting Bids')}</span>
      case 'nearReserve':
        return <span className={`lot-status ${list} ${this.props.auction && this.props.auction.auctionType !== 'sequentialOffline' ? 'nearing' : ''}`}>{this.checkStatus('Nearing Reserve')}</span>
      case 'onMarket':
        return <span className={`lot-status ${list} ${this.props.auction && this.props.auction.auctionType !== 'sequentialOffline' ? 'on-market' : ''}`}>{this.checkStatus('On Market')}</span>
      case 'notNear':
      default:
        return <span className={`lot-status ${list} ${this.props.auction && this.props.auction.auctionType !== 'sequentialOffline' ? 'open' : ''}`}>{this.checkStatus('Opening Bids')}</span>
    }
  }

  checkStatus = (status) => this.props.auction.auctionType === 'sequentialOffline' ? 'Active' : status

  isNeedOfflineFlashing (lot, auction) {
    const state = auction.auctionType === 'synchronous' ? auction.state : lot.state
    return auction.auctionType === 'sequentialOffline' && state === 'live' && (lot.pausedTime || lot.frozenTime || auction.pausedTime || auction.frozenTime)
  }

  statusClassName (currentStatus) {
    /* returns 'bided', 'overbided' or ''
      can be used for css classNames */
    if (currentStatus.indexOf('overbided') !== -1) {
      return 'overbided'
    } else if (currentStatus.indexOf('bided') !== -1) {
      return 'bided'
    } else {
      return ''
    }
  }

  isEnding (currentStatus) {
    return currentStatus.indexOf('ending') !== -1
  }

  setWatchingStatus = (status) => {
    this.setState({
      isWatched: status
    })
  }

  getPrice () {
    let { lot } = this.props
    const { HEAD, KG } = bidTypes
    let isSwapped = this.isPriceSwapped()
    let formattedPrice, measure
    switch (true) {
      case lot.bidding === HEAD && !isSwapped:
      default:
        formattedPrice = LotUtils.makePrice(lot.currentBidCents, { bidding: bidTypes.HEAD })
        measure = 'head'
        break
      case lot.bidding === HEAD && isSwapped:
        formattedPrice = LotUtils.makePrice(LotUtils.convertDollarToCentsPrice(lot.currentBidCents, lot), { bidding: bidTypes.KG })
        measure = 'kg'
        break
      case lot.bidding === KG && !isSwapped:
        formattedPrice = LotUtils.makePrice(lot.currentBidCents, { bidding: bidTypes.KG })
        measure = 'kg'
        break
      case lot.bidding === KG && isSwapped:
        formattedPrice = LotUtils.makePrice(LotUtils.convertCentsToDollarPrice(lot.currentBidCents, lot), { bidding: bidTypes.HEAD })
        measure = 'head'
        break
    }
    return (
      <React.Fragment>
        <span>{formattedPrice}</span>
        <span className='price-measure'>/{measure}</span>
      </React.Fragment>
    )
  }

  renderImage () {
    const { lot, isSelected, auction, lotList } = this.props
    const state = auction.auctionType === 'synchronous' ? auction.state : lot.state
    if (lot.media && lot.media.length > 0) {
      return (
        <img
          src={lot.media[0].thumbnail ? lot.media[0].thumbnail : lot.media[0].url}
          className={`lot-image ${lotList && 'img-list'}  ${state === 'closed' ? '' : isSelected ? 'selected' : ''}`}
          alt='lot'
        />
      )
    } else {
      if (lotList) {
        return (
          <div className='image-box'>
            <span className={`lot-image no-image no-img-list ${this.props.auction.kindData.type}`}>{''}</span>
          </div>
        )
      } else {
        return <span className={`lot-image no-image ${this.props.auction.kindData.type}`}>{'No photo/video'}</span>
      }
    }
  }

  checkAnimation = (lot) => {
    lot.previousBidCents = lot.currentBidCents
    this.props.mergeStateProp('lots', [lot], 'data')
    let disableAnimation = () => {
      this.disable = setTimeout(() => {
        this.setState({
          bidAnimation: false
        })
      }, 10000)
    }
    this.setState({
      bidAnimation: false
    }, () => {
      this.setState({
        bidAnimation: true,
        bidAnimationShort: true
      }, () => {
        setTimeout(() => {
          this.setState({
            bidAnimationShort: false
          })
        }, 500)
        clearTimeout(this.disable)
        disableAnimation()
      })
    })
  }
  findBidsByParticipants (userId, lot) {
    return lot.participants.find((participant) => {
      return userId === participant
    })
  }

  renderGridContent () {
    let { lot, selectedLotId, hideImages, userId, autoBidStatus, auction } = this.props
    let { bidAnimation, currentStatus, isWatched, bidAnimationShort } = this.state
    const state = auction.auctionType === 'synchronous' ? auction.state : lot.state

    let isSelected = Boolean(selectedLotId === lot._id)
    const statusClass = this.statusClassName(currentStatus)
    const isEnding = this.isEnding(currentStatus)
    const iconsStyle = { color: '#fff', width: 25, height: 25, marginTop: 0 }
    let resultMessage = this.getResultMessage(lot, userId, auction)
    let needHideTimer = (auction.auctionType === 'synchronous' &&  state !== 'open') || (auction.auctionType === 'sequentialOffline' && state !== 'open')
    
    const displaySecondPrice =  lot.kindData.type === 'cattle' && lot.bidding === bidTypes.HEAD

    let content = (
      <React.Fragment>
        <div
          className={`result ${hideImages ? 'no-img' : ''} ${resultMessage === 'no bid' ? 'no-bid' : resultMessage}`}>
          {resultMessage}
        </div>
        <div className={isWatched ? 'lot-header watched' : 'lot-header'}>
          <div className='left'>
            Lot {lot.number}
            {lot.createdBy === userId && (<FontAwesomeIcon className='my-listing' icon={faUser} />)}
            <div className='small-watch-btn'>
              {
                hideImages
                  ? <WatchButton onChange={this.setWatchingStatus} lotId={lot._id} bigButton={false} isBorder={false} />
                  : null
              }
            </div>
          </div>
          <div className={userId ? 'location' : 'abcd'}>
          
            {lot.publicDetails.location.town}, {lot.publicDetails.location.state}
          </div>
          {
            hideImages
              ? null
              : <WatchButton onChange={this.setWatchingStatus} lotId={lot._id} bigButton={false} isBorder />
          }
        </div>
        <div className={'lot-body'}>
          {
            !hideImages
              ? this.renderImage()
              : null
          }
          <div
            className={`lot-info ${state === 'closed' ? '' : isSelected ? 'selected' : ''} 
            ${statusClass} 
            ${(isEnding && auction.auctionType !== 'sequentialOffline' && auction.auctionType !== 'synchronous') || this.isNeedOfflineFlashing(lot, auction) ? 'ending' : ''} ${hideImages ? 'no-img' : ''}`}>
            { isEnding && auction.auctionType !== 'synchronous' && !auction.auctionpaused && !this.props.auction.frozenTime &&
              <div className={`ending-help-container ${this.state.fade ? 'fade-in' : 'fade-out'}`} />
            }
            <div className='top'>
              <div className='left'>
                {!needHideTimer && 
                <StateTimerLotContainer
                  lotId={lot._id}
                  updateTimeCallback={this.updateStatus}
                  auction={auction}
                  offsetMs={this.props.offsetMs}
                />}
              </div>
              {this.getBiddingStatus({ lot, auction })}
            </div>
            {/* <div
              className={`center ${state === 'closed' ? '' : isSelected ? 'selected' : ''} ${hideImages ? 'no-img' : ''} ${lot.state === 'futureInLive' ? '' : statusClass} ${auction.state === 'open' && lot.winner && userId === lot.winner ? 'bided' : ''} ${auction.state === 'open' && lot.winner && userId !== lot.winner && (this.findBidsByParticipants (userId, lot)) ? 'overbided' : ''}`}> */}
            <div
              className={`center ${state === 'closed' ? '' : isSelected ? 'selected' : ''} ${hideImages ? 'no-img' : ''} ${statusClass}`}>
              <div className='auc-title'>
                <p className='auc-title-main'>{`${lot.publicDetails.title}`}</p>
                <p className='auc-title-main-secondary'>{`${lot.publicDetails.summary.age} ${lot.additionalTitle}`}</p>
              </div>
              <div className='auc-title-weights'>
                <p className='auc-title-weight'>{LotUtils.calculateAverageDailyWeightGain(lot) ? LotUtils.calculateAverageDailyWeightGain(lot) !== 0 ? `${LotUtils.calculateAverageDailyWeightGain(lot)}kg avdwtg` : '-' : lot.publicDetails.weight.average !== 0 ? `${Math.round(lot.publicDetails.weight.average)}kg ass/wt` : '-'}</p>
                <p className='auc-title-weight curfew'>{`${lot.publicDetails.weight.curfew !== 0 ? `${Math.round(lot.publicDetails.weight.curfew)}kg ${lot.adjustWeight === 0 && lot.optiweighAllowed ? 'live/wt' : 'cur/wt'}` : '-'}`}</p>
              </div>
            </div>
            {/* <div
              className={`bottom ${state === 'closed' ? '' : isSelected ? 'selected' : ''} ${hideImages ? 'no-img' : ''} ${lot.state === 'futureInLive' ? '' : statusClass} ${auction.state === 'open' && lot.winner && userId === lot.winner ? 'bided' : ''} ${bidAnimationShort ? 'bid' : ''} ${auction.state === 'open' && lot.winner && userId !== lot.winner && (this.findBidsByParticipants (userId, lot)) ? 'overbided' : ''}`}> */}
            <div
              className={`bottom ${state === 'closed' ? '' : isSelected ? 'selected' : ''} ${hideImages ? 'no-img' : ''} ${statusClass} ${bidAnimationShort ? 'bid' : ''}`}>
              <div className='price-left'>
                {autoBidStatus && <div className='autobid-active '>
                  <FontAwesomeIcon className='gavel' icon={faGavel} style={iconsStyle} />
                </div>}
              </div>
              <div className='price-center'>
                <div className='auc-price' onClick={() => {
                  if (displaySecondPrice) {
                    this.toggleStatusOfPriceFormat()
                  }
                }}>
                  {this.state.currentFormatDefault ? this.getCurrentBid(lot.currentBidCents) : `${LotUtils.makePrice(lot.currentBidCents / lot.publicDetails.weight.curfew, { bidding: bidTypes.KG })}/kg`}
                </div>
                {displaySecondPrice &&
                <div className='cents-price' onClick={() => {
                  if (displaySecondPrice) {
                    this.toggleStatusOfPriceFormat()
                  }
                }}>
                  {this.state.currentFormatDefault ? `${LotUtils.makePrice(lot.currentBidCents / lot.publicDetails.weight.curfew, { bidding: bidTypes.KG })}/kg` : this.getPrice()}
                </div>}
              </div>
              <div className='price-right'>
                {bidAnimation &&
                <div className={`recent-bid ${isSelected && 'active'}`}>
                  <FontAwesomeIcon icon={faGavel} style={iconsStyle} />
                </div>
                }
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )

    return (
      <React.Fragment>
        {bidAnimation && <div
          // className={`bid lot-item ${state === 'closed' || lot.withdrawn ? 'closed' : isSelected ? 'selected' : ''} ${hideImages ? 'no-img' : ''} ${lot.state === 'futureInLive' ? '' : statusClass} ${auction.state === 'open' && lot.winner && userId === lot.winner ? 'bided' : ''} ${auction.state === 'open' && lot.winner && userId !== lot.winner && (this.findBidsByParticipants (userId, lot)) ? 'overbided' : ''}`}
          className={`bid lot-item ${state === 'closed' || lot.withdrawn || lot.soldBeforeAuction ? 'closed' : isSelected ? 'selected' : ''} ${hideImages ? 'no-img' : ''} ${statusClass}`}
          onClick={() => {
            this.props.selectLot(lot._id)
          }}>
          {/* { (state === 'futureInLive' || (auction.auctionType === 'sequentialOffline' && state === 'open')) && <div className='mask' /> } */}
          {(state === 'futureInLive') && <div className='mask' />}
          {content}
        </div>}
        {!bidAnimation && <div
          // className={`lot-item ${state === 'closed' || lot.withdrawn ? 'closed' : isSelected ? 'selected' : ''} ${hideImages ? 'no-img' : ''} ${lot.state === 'futureInLive' ? '' : statusClass} ${auction.state === 'open' && lot.winner && userId === lot.winner ? 'bided' : ''} ${auction.state === 'open' && lot.winner && userId !== lot.winner && (this.findBidsByParticipants (userId, lot)) ? 'overbided' : ''}`}
          className={`lot-item ${state === 'closed' || lot.withdrawn || lot.soldBeforeAuction  ? 'closed' : isSelected ? 'selected' : ''} ${hideImages ? 'no-img' : ''} ${statusClass}`}
          onClick={() => {
            this.props.selectLot(lot._id)
          }}>

          {/* { (state === 'futureInLive' || (auction.auctionType === 'sequentialOffline' && state === 'open')) && <div className='mask' /> } */}
          {(state === 'futureInLive') && <div className='mask' />}
          {content}
        </div>}
      </React.Fragment>
    )
  }

  renderListContent () {
    let { lot, selectedLotId, hideImages, autoBidStatus, userId, auction } = this.props
    let { currentStatus, deviceWidth, middleWidth, bidAnimation, bidAnimationShort } = this.state
    const state = auction.auctionType === 'synchronous' ? auction.state : lot.state
    const displaySecondPrice = !['Cow(s)', 'Cow(s) and Calves', 'Bull(s)'].includes(lot.publicDetails.sex) && lot.kindData.type === 'cattle' && lot.bidding === bidTypes.HEAD
    const statusClass = this.statusClassName(currentStatus)
    let isSelected = Boolean(selectedLotId === lot._id)
    let isOpen = state === 'open'
    const iconsStyle = { color: '#fff', width: 30, height: 30, marginTop: 0 }
    const smallIconsStyle = { color: '#fff', width: 25, height: 25, marginTop: 0 }
    const isEnding = this.isEnding(currentStatus)
    let resultMessage = this.getResultMessage(lot, userId, auction)

    let content = (
      <React.Fragment>
        <div
          className={`result result-list ${hideImages ? 'no-img' : ''} ${resultMessage === 'no bid' ? 'no-bid' : resultMessage}`}>
          {resultMessage}
        </div>
        {!middleWidth &&
        <div className={'lot-body'}>
          <div className={`lot-info lot-list-info ${state === 'closed' ? '' : isSelected ? 'selected' : ''}`}>
            {!deviceWidth &&
            <div
              className={`center-list ${state === 'closed' ? '' : isSelected ? 'selected' : ''} ${isOpen ? 'open' : ''}`}>
              <div className='row-title'>
                <div className={`lot-number-image-list`}>
                  <div className={`lotNoBox ${statusClass}`}>
                    <div className='lotNo'>Lot {lot.number} </div>
                  </div>

                  {this.renderImage()}

                </div>

                <p className='auc-title-list'>{`${lot.publicDetails.title}`}</p>
              </div>
              <div className={`state-info-list`}>
                <p className='age-list'>{`${lot.publicDetails.summary.age} ${lot.additionalTitle}`}</p>
                <p className='state-weight'>{LotUtils.calculateAverageDailyWeightGain(lot) ? LotUtils.calculateAverageDailyWeightGain(lot) !== 0 ? `${LotUtils.calculateAverageDailyWeightGain(lot)}kg avdwtg` : '-' : lot.publicDetails.weight.average !== 0 ? `${Math.round(lot.publicDetails.weight.average)}kg ass/wt` : '-'}</p>
                <p className='state-weight'>{`${lot.publicDetails.weight.curfew !== 0 ? `${Math.round(lot.publicDetails.weight.curfew)}kg ${lot.adjustWeight === 0 && lot.optiweighAllowed ? 'live/wt' : 'cur/wt'}` : '-'}`}</p>
                <div className='state-list'>
                  <p className='info-text'>{`${lot.publicDetails.location.town}`}</p>
                  <p className='info-text'>{`${lot.publicDetails.location.state}`}</p>
                </div>
              </div>
            </div>
            }
            {deviceWidth &&
            <div
              className={`center-list ${state === 'closed' ? '' : isSelected ? 'selected' : ''} ${isOpen ? 'open' : ''}`}>
              <div className='row-title device'>
                <p className='header-lot'>Lot {lot.number}</p>
                <p
                  className='header-text'>{`${lot.publicDetails.location.town} ${lot.publicDetails.location.state}`}</p>
              </div>
              <p className='device-title'>{`${lot.publicDetails.title}`}</p>
            </div>
            }

            <div
              className={`bottom list-right ${deviceWidth ? 'device-bottom' : ''} ${state === 'closed' ? '' : isSelected ? 'selected' : ''} ${isOpen ? 'open' : ''}`}>
              <div className={`part-list-left`}>
                {autoBidStatus && <div className={'autobid-active list'}>
                  <FontAwesomeIcon className='gavel' icon={faGavel}
                    style={this.state.innerWidth >= 1450 ? smallIconsStyle : iconsStyle} />
                </div>}

                {bidAnimation &&
                <div className={`recent-bid ${isSelected && 'active'} list`}>
                  <FontAwesomeIcon icon={faGavel} style={this.state.innerWidth >= 1450 ? smallIconsStyle : iconsStyle} />
                </div>
                }
                {!bidAnimation && <div className='recent-bid-plug'>&nbsp;</div>}

                {bidAnimationShort && <div className={`bid auc-price-box`}>
                  <div className='auc-price-list bid-price' onClick={() => {
                    if (displaySecondPrice) {
                      this.toggleStatusOfPriceFormat()
                    }
                  }}>
                    {this.state.currentFormatDefault ? this.getCurrentBid(lot.currentBidCents) : `${LotUtils.makePrice(lot.currentBidCents / lot.publicDetails.weight.curfew, { bidding: bidTypes.KG })}/kg`}
                  </div>
                  {displaySecondPrice &&
                  <div className='cents-price-list bid-price' onClick={() => {
                    if (displaySecondPrice) {
                      this.toggleStatusOfPriceFormat()
                    }
                  }}>
                    {this.state.currentFormatDefault ? `${LotUtils.makePrice(lot.currentBidCents / lot.publicDetails.weight.curfew, { bidding: bidTypes.KG })}/kg` : this.getPrice()}
                  </div>}
                </div>}
                {!bidAnimationShort && <div className={`auc-price-box `}>
                  <div className='auc-price-list' onClick={() => {
                    if (displaySecondPrice) {
                      this.toggleStatusOfPriceFormat()
                    }
                  }}>
                    {this.state.currentFormatDefault ? this.getCurrentBid(lot.currentBidCents) : `${LotUtils.makePrice(lot.currentBidCents / lot.publicDetails.weight.curfew, { bidding: bidTypes.KG })}/kg`}
                  </div>
                  {displaySecondPrice &&
                  <div className='cents-price-list' onClick={() => {
                    if (displaySecondPrice) {
                      this.toggleStatusOfPriceFormat()
                    }
                  }}>
                    {this.state.currentFormatDefault ? `${LotUtils.makePrice(lot.currentBidCents / lot.publicDetails.weight.curfew, { bidding: bidTypes.KG })}/kg` : this.getPrice()}
                  </div>}
                </div>}
              </div>
              <div className={`part-list-right ${deviceWidth ? 'device-right' : ''} ${(isEnding && auction.auctionType !== 'sequentialOffline') || this.isNeedOfflineFlashing(lot, auction) ? 'ending' : ''}`}>
                <div className={`top-list ${deviceWidth ? 'device-timer' : 'list-timer'}`}>
                  {auction.auctionType !== 'sequentialOffline' &&
                  <div className='left'>
                    <StateTimerLotContainer
                      lotId={lot._id}
                      updateTimeCallback={this.updateStatus}
                      grid={deviceWidth ? 'grid' : 'list'}
                      auction={auction}
                      offsetMs={this.props.offsetMs}
                    />
                  </div> }
                  {this.getBiddingStatus({ lot, auction, deviceWidth })}
                </div>
                <div className={`watch-btn-list ${deviceWidth ? 'device-btn' : ''}`}>
                  <WatchButton onChange={this.setWatchingStatus} lotId={lot._id} bigButton={false} isBorder isSmall />
                </div>
              </div>
            </div>
          </div>
        </div>}
        {middleWidth && <div className={'lot-body list'}>
          <div className={`lot-info lot-list-info ${state === 'closed' ? '' : isSelected ? 'selected' : ''}`}>
            <div
              className={`center-list ${state === 'closed' ? '' : isSelected ? 'selected' : ''} ${isOpen ? 'open' : ''}`}>
              <div className='row-title'>
                <div className={`lotNoBox ${statusClass}`}>
                  <div className='lotNo'>Lot {lot.number} </div>
                </div>
                {this.renderImage()}
              </div>
              <div className={`middle-screen-info-list`}>
                <div className={`main-info-list`}>
                  <div className={`title-middle-list`}>
                    <p className='auc-title-list'>{`${lot.publicDetails.title}`}</p>
                  </div>
                  <div className={`state-info-list`}>
                    <p className='age-list'>{`${lot.publicDetails.summary.age} ${lot.additionalTitle}`}</p>
                    <p className='state-weight'>{`${lot.publicDetails.summary.weight}`}</p>
                    <p className='state-weight'>{`${Math.round(lot.publicDetails.weight.curfew)}kg ${lot.adjustWeight === 0 && lot.optiweighAllowed ? 'live/wt' : 'cur/wt'}`}</p>
                    <div className='state-list'>
                      <p className='info-text'>{`${lot.publicDetails.location.town}`}</p>
                      <p className='info-text'>{`${lot.publicDetails.location.state}`}</p>
                    </div>
                  </div>
                </div>
                <div className={`additional-info-list`} />
                <div
                  className={`bottom list-right ${deviceWidth ? 'device-bottom' : ''} ${state === 'closed' ? '' : isSelected ? 'selected' : ''} ${isOpen ? 'open' : ''}`}>
                  <div className={`part-list-left ${(isEnding && auction.auctionType !== 'sequentialOffline') || this.isNeedOfflineFlashing(lot, auction) ? 'ending' : ''}`}>
                    <div className={'bid-icons'}>
                      {autoBidStatus && <div className={`autobid-active list`}>
                        <FontAwesomeIcon className='gavel' icon={faGavel}
                          style={this.state.innerWidth >= 1450 ? smallIconsStyle : iconsStyle} />
                      </div>}

                      {bidAnimation &&
                      <div className={`recent-bid ${isSelected && 'active'} list`}>
                        <FontAwesomeIcon icon={faGavel} style={this.state.innerWidth >= 1450 ? smallIconsStyle : iconsStyle} />
                      </div>
                      }
                    </div>

                    {bidAnimationShort && <div className={`bid auc-price-box`}>
                      <div className='auc-price bid-price' onClick={() => {
                        if (displaySecondPrice) {
                          this.toggleStatusOfPriceFormat()
                        }
                      }}>
                        {this.state.currentFormatDefault ? this.getCurrentBid(lot.currentBidCents) : `${LotUtils.makePrice(lot.currentBidCents / lot.publicDetails.weight.curfew, { bidding: bidTypes.KG })}/kg`}
                      </div>
                      {displaySecondPrice &&
                      <div className='cents-price bid-price' onClick={() => {
                        if (displaySecondPrice) {
                          this.toggleStatusOfPriceFormat()
                        }
                      }}>
                        {this.state.currentFormatDefault ? `${LotUtils.makePrice(lot.currentBidCents / lot.publicDetails.weight.curfew, { bidding: bidTypes.KG })}/kg` : this.getPrice()}
                      </div>}
                    </div>}
                    {!bidAnimationShort && <div className={`auc-price-box `}>
                      <div className='auc-price-list' onClick={() => {
                        if (displaySecondPrice) {
                          this.toggleStatusOfPriceFormat()
                        }
                      }}>
                        {this.state.currentFormatDefault ? this.getCurrentBid(lot.currentBidCents) : `${LotUtils.makePrice(lot.currentBidCents / lot.publicDetails.weight.curfew, { bidding: bidTypes.KG })}/kg`}
                      </div>
                      {displaySecondPrice &&
                      <div className='cents-price-list' onClick={() => {
                        if (displaySecondPrice) {
                          this.toggleStatusOfPriceFormat()
                        }
                      }}>
                        {this.state.currentFormatDefault ? `${LotUtils.makePrice(lot.currentBidCents / lot.publicDetails.weight.curfew, { bidding: bidTypes.KG })}/kg` : this.getPrice()}
                      </div>}
                    </div>}
                    <div className={`top-list ${deviceWidth ? 'device-timer' : 'list-timer'}`}>
                      {auction.auctionType !== 'sequentialOffline' && <div className='left'>
                        <StateTimerLotContainer
                          lotId={lot._id}
                          updateTimeCallback={this.updateStatus}
                          grid={deviceWidth ? 'grid' : 'list'}
                          offsetMs={this.props.offsetMs}
                          auction={auction}
                        />
                      </div>}

                      {this.getBiddingStatus({ lot, auction, deviceWidth })}
                    </div>
                    <div className={`watch-btn-list ${deviceWidth ? 'device-btn' : ''}`}>
                      <WatchButton onChange={this.setWatchingStatus} lotId={lot._id} bigButton={false} isBorder isSmall />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
      </React.Fragment>
    )

    return (
      <div
        className={`lot-item  ${state === 'closed' || lot.withdrawn || lot.soldBeforeAuction  ? 'closed' : isSelected ? 'selected' : ''} ${hideImages ? 'no-img' : ''} lotList ${isOpen ? 'open' : ''}`}
        onClick={() => {
          this.props.selectLot(lot._id)
        }}>
        {/* { (state === 'futureInLive' || (auction.auctionType === 'sequentialOffline' && state === 'open')) && <div className='mask' /> } */}
        {(state === 'futureInLive') && <div className='mask' />}
        {content}
      </div>
    )
  }

  renderGridOrderPad() {
    console.log('allLots--', this.props.allLots);
    let winnerLots = this.props.allLots.filter(
      (lot) =>
        ((lot.status !== 'passed' && lot.state === 'closed') || (lot.status === 'sold' && lot.state === "live")) &&
        lot.winner === this.props.userId
    )
    let noOfHeads = winnerLots.reduce((acc, value) => acc + value.count, 0);
    let gross = winnerLots.reduce(
      (acc, value) => acc + value.currentBidCents * value.count,
      0
    );
    return (
      <React.Fragment>
        <div
          className={`order-pad`}
        >
          {<p>{`Winning: ${winnerLots.length} Lots`}</p>}
          {<p>{`Winning: ${noOfHeads} Head`}</p>}
          {<p>{`Average per/head: ${LotUtils.makePrice(gross / noOfHeads, {
            bidding: bidTypes.HEAD,
          })}`}</p>}
          <p>{`Gross: ${LotUtils.makePrice(
            gross,
            { bidding: bidTypes.HEAD }
          )}`}</p>
        </div>
      </React.Fragment>
    )
  }

  renderListOrderPad() {
    let winnerLots = this.props.allLots.filter(
      (lot) =>
        ((lot.status !== 'passed' && lot.state === 'closed') || (lot.status === 'sold' && lot.state === "live")) &&
        lot.winner === this.props.userId
    )
    let noOfHeads = winnerLots.reduce((acc, value) => acc + value.count, 0);
    let gross = winnerLots.reduce(
      (acc, value) => acc + value.currentBidCents * value.count,
      0
    );
    return (
      <div
        className={`order-pad list`}>
        {<p>{`Winning: ${winnerLots.length} Lots`}</p>}
        {<p>{`Winning: ${noOfHeads} Head`}</p>}
        {<p>{`Average per/head: ${LotUtils.makePrice(gross / noOfHeads, {
            bidding: bidTypes.HEAD,
          })}`}</p>}
        <p>{`Gross: ${LotUtils.makePrice(
          gross,
          { bidding: bidTypes.HEAD }
        )}`}</p>
      </div>
    )
  }

  render () {
    let { auction, lot, lotList, showOrderPad } = this.props
    const { bidAnimation } = this.state
    let isBid = (lot.currentBidCents > lot.previousBidCents) && auction.state !== 'open'
    if (!bidAnimation && isBid) {
      this.checkAnimation(lot)
    } else if (bidAnimation && isBid) {
      this.checkAnimation(lot, true)
    }
    return (
      <React.Fragment>
        {showOrderPad && (lotList ? this.renderListOrderPad() : this.renderGridOrderPad())}
        {lotList ? this.renderListContent() : this.renderGridContent()}
      </React.Fragment>
    )
  }
}

export default RenderLot
