import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect, NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import _ from 'lodash';
import Tooltip from 'react-simple-tooltip'
import { css } from 'styled-components'
import { TooltipContainer } from '../../components/LotBox/styled'

import { onEnter, sellerOnly, isSeller, getUserData } from '../../utils/UpdateData'
import ExistingLotsContainer from './ExistingLotsList/ExistingLotsListContainer'
import MyResultsContainer from './MyResults/MyResultsContainer'
import MyListingsContainer from './MyListings/MyListingsContainer'
import MyFavouritePicsContainer from './MyFavouritePics/MyFavouritePicsContainer'
import MyReoffersContainer from './MyReoffers/MyReoffersContainer'
import LotDetailsContainer from './LotDetails/LotDetailsContainer'
import EditReofferContainer from './EditReoffer/EditReofferContainer'
import EditLateContainer from './EditLate/EditLateContainer'
import MySounds from './MySounds/MySoundsContainer'
import MyTexts from './MyTexts/MyTextsContainer'
import AsBuyerAndSellerContainer from '../Auth/AsBuyerAndSeller/AsBuyerAndSellerContainer'
import AsBuyerContainer from '../Auth/AsBuyer/AsBuyerContainer'
import { subTitles } from '../../constants/strings'
import { showModal, changeStateProp } from '../../actions/index'
import { bindActionCreators } from 'redux'
import store from '../../services/store'
import { ALLOW_ACCESS_OBJECT } from '../../constants/index'
import { restrictAccessMessages } from '../../constants/strings'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faCaretUp, faCaretRight } from '@fortawesome/fontawesome-free-solid'
import Api from '../../services/api'
import ScrollToTop from '../ScrollToTop/ScrollToTop'
import update from 'immutability-helper';


let bound = bindActionCreators({ showModal }, store.dispatch)

class ManageLots extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      upgradeAccountExpand: false,
      howToAssess: []
    }

    this.handleNavigationClick = this.handleNavigationClick.bind(this);
    this.userStatusToDisplay = this.userStatusToDisplay.bind(this)
    this.registerToBuyOrSell = this.registerToBuyOrSell.bind(this);
  }
  componentDidMount() {
    this.getData()

    if (this.props.history.action !== 'POP') {
      window.scroll(0, 0)
    }
  }

  getData() {
    Api.getHowToAssess()
      .then(res => {
        if (res) {
          this.setState({
            howToAssess: this.convertData(res['how-to-assess-steps'])
          })
        }
      })
  }

  convertData(data) {
    return data.map(item => {
      return { title: item.categoryName, text: item.content }
    })
  }

  registerToBuyOrSell(value, e) {
    let data = getUserData()
    let message = ""

    if (value === 'registerToBuy') {
      if (
        data.status === 'approved-buyer' ||
        data.status === 'approved-buyer-updated'
      ) {
        message = 'You already have buy permissions';
      } else if (data.status === 'approved-buyer-seller') {
        message = 'You already have buy and sell permissions';
      } else if (data.status === 'registered-updated') {
        if (
          data.isRequestedAsBuyer &&
          data.isRequestedAsSeller
        ) {
          message = `You have already requested access. Please allow 24 hours for this request to be processed by our team.`;
        }
        if (data.isRequestedAsBuyer) {
          message = `You have already requested access. Please allow 24 hours for this request to be processed by our team.`;
        }
      }
      this.props.changeStateProp('buyerAndSellerFormInfo', update(this.props.buyerAndSellerFormInfo, {
        buyerForm: { $set: this.props.user }
      }), 'user')
    } else if (value === 'registerToBuyAndSell') {
      if (
        data.status === 'approved-buyer-seller' &&
        _.isEqual(
          data.approvedStockTypes.sort(),
        // this.props.kinds.map((item) => item.title).sort()
         this.props.kinds.filter(item => item.sellingCategory === true).map((item) => item.title).sort()
        )
      ) {
        message = 'You already have buy and sell permissions';
      }
      if (this.requestedAndApprovedMaxStocks()) {
        message = `You have already requested access. Please allow 24 hours for this request to be processed by our team.`;
      }
    }

    if (message === "") {
      return
    } else {
      e.preventDefault()
      bound.showModal({
        message: message
      })
    }

  }

  requestedAndApprovedStocks() {
    let data = getUserData()
    let requestedStocks = data.stockTypes || [];
    let approvedStocks = data.approvedStockTypes || [];
    return Array.from(new Set(requestedStocks.concat(approvedStocks)));
  }

  requestedAndApprovedMaxStocks() {
    return _.isEqual(
      this.requestedAndApprovedStocks().sort(),
      this.props.kinds.map((item) => item.title).sort()
    );
  }

  handleNavigationClick(value, e) {
    let data = getUserData()
    let message

    if (data.status === 'registered' || data.status === 'registered-updated') {
      message = value && (value === 'registerToBuy' || value === 'registerToBuyAndSell') ? restrictAccessMessages.ALREADY_REQUESTED_ACCESS : restrictAccessMessages.GUEST_RESTIRCT_MESSAGE
    }

    if (data.status === 'approved-buyer' || data.status === 'approved-buyer-updated') {
      if (value === 'registerToBuy') {
        message = restrictAccessMessages.ALREADY_HAVE_BUY_ACCESS
      } else if (value === 'registerToBuyAndSell') {
        message = restrictAccessMessages.ALREADY_REQUESTED_ACCESS
      } else {
        message = restrictAccessMessages.BUYER_RESTRICT_MESSAGE
      }
      // message = value &&  (value === 'registerToBuy' || value === 'registerToBuyAndSell') ? restrictAccessMessages.ALREADY_REQUESTED_ACCESS : restrictAccessMessages.BUYER_RESTRICT_MESSAGE
    }
    if (data.status === 'approved-buyer-seller') {
      if (data.approvedStockTypes.length === 3) {
        message = restrictAccessMessages.ALREADY_HAVE_BUY_SELL_ACCESS
      } else {
        ALLOW_ACCESS_OBJECT['approved-buyer-seller'].push('registerToBuyAndSell')
        message = restrictAccessMessages.ALREADY_HAVE_BUY_ACCESS
      }

    }
    if (data.status === 'registered-updated' && data.isRequestedAsBuyer && data.isRequestedAsSeller) {
      ALLOW_ACCESS_OBJECT['registered-updated'].splice(0, ALLOW_ACCESS_OBJECT['registered-updated'].length);
      ALLOW_ACCESS_OBJECT['registered-updated'].push('myProfile')
    }
    if (data.status === 'registered-updated' && data.isRequestedAsBuyer && !data.isRequestedAsSeller) {
      ALLOW_ACCESS_OBJECT['registered-updated'].splice(0, ALLOW_ACCESS_OBJECT['registered-updated'].length);
      ALLOW_ACCESS_OBJECT['registered-updated'].push('registerToBuyAndSell', 'myProfile')
    }
    if (ALLOW_ACCESS_OBJECT[data.status]) {
      if (ALLOW_ACCESS_OBJECT[data.status].includes(value)) {
        return
      } else {
        e.preventDefault()
        bound.showModal({
          message: message
        })
      }
    } else {
      return
    }

  }
  userStatusToDisplay(userData) {

    switch (userData.status) {
      case 'registered':
      case 'registered-updated':
        return 'Guest Account'
      case 'approved-buyer':
      case 'approved-buyer-updated':
        return 'Buyer Account'
      case 'approved-buyer-seller':
        return 'Buyer and Seller Account'
      case 'approved-seller':
        return 'Seller Account'
      default:
        return 'Guest Account'

    }

  }

  render() {
    let { upgradeAccountExpand, howToAssess } = this.state
    let userData = getUserData()
    let userStatus = userData && this.userStatusToDisplay(userData)
    // let userType = (userData.type === 'guest' && 'Guest Account') || (userData.type === 'buyer' && 'Buyer Account') || (userData.type === 'seller' && 'Seller Account')

    return (
      <div className='app-route'>
        <div className='body-container'>
          <div className='inner-container'>
            {onEnter(
              <Switch>
                {
                  sellerOnly(
                    <Route path='/main/manage/my-submitted' render={(obj) => {
                      return <ExistingLotsContainer showSubmitted {...obj} />
                    }} />
                  )
                }
                {
                  sellerOnly(
                    <Route path='/main/manage/edit-saved'
                      render={(obj) => {
                        return <ExistingLotsContainer showDraft {...obj} noDataPlaceholder='You currently have no Lots to edit.' />
                      }} />
                  )
                }
                {
                  sellerOnly(
                    <Route path='/main/manage/my-reoffers'
                      component={MyReoffersContainer} />
                  )
                }
                {
                  sellerOnly(
                    <Route path='/main/manage/edit-reoffers/:kindTitle/:auctionNumber/:draftNumericId?'
                      component={EditReofferContainer} />
                  )
                }
                {
                  sellerOnly(
                    <Route path='/main/manage/edit-late/:kindTitle/:auctionNumber/:draftNumericId?'
                      component={EditLateContainer} />
                  )
                }
                {
                  sellerOnly(
                    <Route path='/main/manage/my-favourite-pics' render={(obj) => {
                      return <MyFavouritePicsContainer pics {...obj} />
                    }} />
                  )
                }
                {
                  sellerOnly(
                    <Route path='/main/manage/my-listings'
                      component={MyListingsContainer} />
                  )
                }
                <Route path='/main/manage/my-results'
                  component={MyResultsContainer} />
                <Route path='/main/manage/my-sounds'
                  component={MySounds} />
                <Route path='/main/manage/my-texts'
                  component={MyTexts} />
                <Route path='/main/manage/operation-access/as-buyer'
                  component={AsBuyerContainer} />
                <Route path='/main/manage/operation-access/as-buyerandseller'
                  component={AsBuyerAndSellerContainer} />
                {
                  sellerOnly(
                    <Route path='/main/manage/:kindTitle/:auctionNumber/:lotNumericId/' component={LotDetailsContainer} />
                  )
                }
                <Route exact path='/main/manage'
                  render={(obj) => {
                    return (
                      <ScrollToTop xCoord={0} yCoord={0}>
                        <div className='manage-lots'>
                          <h1 className='fg-header'>My Admin</h1>
                          <p className='fg-paragraph'>
                            {
                              isSeller()
                                ? subTitles.MY_ADMIN
                                : subTitles.MY_ADMIN_BUYERS
                            }
                          </p>
                          <div className='upgrade-account'>
                            <p className='upgrade-account-name'>{userData.firstName} {userData.lastName}</p>

                            <div>
                              <p className='upgrade-account-type'>{`${userStatus} : click upgrade account to Buy or Sell`}</p>
                              {(userData.status === 'approved-seller' || userData.status === 'approved-buyer-seller') &&
                                <p className='display-approved-stocktypes'>Approved Selling Categories : {userData.approvedStockTypes.join(' ')}</p>
                              }
                              {(userData.status === 'approved-seller' || userData.status === 'approved-buyer-seller') &&
                                <p className='display-approved-stocktypes'>Requested Selling Categories : {userData.stockTypes.join(' ')}</p>
                              }
                            </div>
                            <button className='upgrade-account-button'
                              onClick={() => this.setState({ upgradeAccountExpand: !this.state.upgradeAccountExpand })}
                            >{upgradeAccountExpand ? 'Collapse' : 'Upgrade Account'}
                              <span className='icon-wrapper'>
                                {upgradeAccountExpand ? <FontAwesomeIcon icon={faCaretUp} /> :
                                  <FontAwesomeIcon icon={faCaretRight} />
                                }
                              </span>
                            </button>
                          </div>
                          {
                            upgradeAccountExpand &&
                            <div className='upgrade-account-details'>
                              {howToAssess.map((item, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <div className='upgrade-account-data'>
                                      <p className='left-heading'>{item.title}</p>
                                      <div
                                        className="right-paragraph"
                                        dangerouslySetInnerHTML={{ __html: item.text }}
                                      />
                                    </div>
                                  </React.Fragment>
                                )
                              })}
                              <div className='upgrade-account-details-buttons'>
                                <p className='left-heading' />
                                <div className='right-paragraph'>
                                  <TooltipContainer>
                                    <Tooltip customCss={css`
                                 white-space: nowrap;
                               `} content={'Fill out this form if you want permission to buy'} placement='top' background={'#2e2e2e'} radius={5} padding={8}>
                                      <NavLink to='/main/manage/operation-access/as-buyer'
                                        onClick={(e) => this.registerToBuyOrSell('registerToBuy', e)}
                                        className='register-buttons'>
                                        Register to Buy
                                      </NavLink>
                                    </Tooltip>
                                  </TooltipContainer>
                                  <TooltipContainer>
                                    <Tooltip customCss={css`
                                 white-space: nowrap;
                               `} content={'Fill out this form if you want permission to buy and sell'} placement='top' background={'#2e2e2e'} radius={5} padding={8}>
                                      <NavLink to='/main/manage/operation-access/as-buyerandseller'
                                        onClick={(e) => this.registerToBuyOrSell('registerToBuyAndSell', e)}
                                        className='register-buttons'>
                                        Register to Buy & Sell
                                      </NavLink>
                                    </Tooltip>
                                  </TooltipContainer>
                                </div>
                              </div>
                            </div>

                          }

                          <div className='manage-list'>
                            {/* <NavLink className='manage-item'
                            to='/main/manage/how-to-assess' onClick={(e) => this.handleNavigationClick('registerToBuyOrSell', e)}>
                            <div className='left my-texts-left'>
                              <div className='img-container' />
                            </div>
                            <div className='right'>
                              <p className='item-title'>Register to Buy/Sell</p>
                              <p className='item-desc'>Register to Become a Buyer/Buyer&Seller </p>
                            </div>
                          </NavLink> */}
                            <NavLink className='manage-item'
                              to='/main/upcoming' onClick={(e) => this.handleNavigationClick('addNewLot', e)}>
                              <div className='left add-lots-left'>
                                <div className='img-container' />
                              </div>
                              <div className='right'>
                                <p className='item-title'>Add New Lot</p>
                                <p className='item-desc'>List a Lot for sale</p>
                              </div>
                            </NavLink>
                            <NavLink className='manage-item'
                              to='/main/manage/my-submitted' onClick={(e) => this.handleNavigationClick('mySubmittedLots', e)}>
                              <div className='left my-submitted-lots-left'>
                                <div className='img-container' />
                              </div>
                              <div className='right'>
                                <p className='item-title'>My Submitted Lots</p>
                                <p className='item-desc'>View your listed Lots for sale</p>
                              </div>
                            </NavLink>

                            <NavLink className='manage-item'
                              to='/main/manage/edit-saved'
                              onClick={(e) => this.handleNavigationClick('editLots', e)}
                            >
                              <div className='left edit-saved-lots-left'>
                                <div className='img-container' />
                              </div>
                              <div className='right'>
                                <p className='item-title'>Edit Saved Lots</p>
                                <p className='item-desc'>Edit one of your saved drafts</p>
                              </div>
                            </NavLink>
                            <NavLink className='manage-item results'
                              to='/main/manage/my-results' onClick={(e) => this.handleNavigationClick('myAuctionResults', e)} >
                              <div className='left my-auction-results-left'>
                                <div className='img-container' />
                              </div>
                              <div className='right'>
                                <p className='item-title'>My Auction Results</p>
                                <p className='item-desc'>View your past sale results</p>
                              </div>
                            </NavLink>
                            <NavLink className='manage-item'
                              to='/main/manage/my-reoffers' onClick={(e) => this.handleNavigationClick('myReoffers', e)}>
                              <div className='left my-reoffers-left'>
                                <div className='img-container' />
                              </div>
                              <div className='right'>
                                <p className='item-title'>My Reoffers</p>
                                <p className='item-desc'>Reoffer your passed in Lots</p>
                              </div>
                            </NavLink>
                            {/* <NavLink className='manage-item results'
                            to='/main/manage/my-listings' onClick={(e) => this.handleNavigationClick('myListings', e)}>
                            <div className='left my-listings-left'>
                              <div className='img-container' />
                            </div>
                            <div className='right'>
                              <p className='item-title'>My Listings</p>
                              <p className='item-desc'>View account summary of your listing</p>
                            </div>
                          </NavLink> */}
                            <NavLink className='manage-item'
                              to='/main/profile' onClick={(e) => this.handleNavigationClick('myProfile', e)}>
                              <div className='left my-profile-left'>
                                <div className='img-container' />
                              </div>
                              <div className='right'>
                                <p className='item-title'>My Profile</p>
                                <p className='item-desc'>Edit your profile details</p>
                              </div>
                            </NavLink>
                            <NavLink className='manage-item'
                              to='/main/manage/my-favourite-pics' onClick={(e) => this.handleNavigationClick('myFavouritePICs', e)}>
                              <div className='left my-favourite-pics-left'>
                                <div className='img-container' />
                              </div>
                              <div className='right'>
                                <p className='item-title'>My Favourite PICs</p>
                                <p className='item-desc'>Add and edit your most used PIC numbers</p>
                              </div>
                            </NavLink>
                            <NavLink className='manage-item'
                              to='/main/manage/my-sounds'>
                              <div className='left my-sounds-left'>
                                <div className='img-container' />
                              </div>
                              <div className='right'>
                                <p className='item-title'>Sounds</p>
                                <p className='item-desc'>Control your Live Auction sounds</p>
                              </div>
                            </NavLink>
                            <NavLink className='manage-item'
                              to='/main/manage/my-texts'>
                              <div className='left my-texts-left'>
                                <div className='img-container' />
                              </div>
                              <div className='right'>
                                <p className='item-title'>Texts</p>
                                <p className='item-desc'>Control your text message reminders</p>
                              </div>
                            </NavLink>
                          </div>
                        </div>
                      </ScrollToTop>
                    )
                  }} />
                <Redirect to='/main/manage' />
              </Switch>
            )}
          </div>
        </div>
      </div>
    )
  }
}

ManageLots.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  buyerAndSellerFormInfo: PropTypes.object.isRequired,
}
ManageLots.defaultProps = {}

const mapStateToProps = (state) => {
  return {
    kinds: state.data.kinds,
    user: state.user.data,
    buyerAndSellerFormInfo: state.user.buyerAndSellerFormInfo,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeStateProp }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageLots)
