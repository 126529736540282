import React, { PureComponent } from 'react'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/fontawesome-free-solid'
import { getMyAutobid } from '../../../utils/Lots'
import RenderLotContainer from './RenderLot/RenderLotContainer'

class RenderLots extends PureComponent {
  constructor () {
    super()

    this.state = {
      showNoInternetNotification: false,
      connection: navigator.connection || navigator.mozConnection || navigator.webkitConnection
    }
  }

  componentDidMount () {
    window.addEventListener('offline', () => {
      this.handleNoInternetAlert(true)
    })
    window.addEventListener('online', () => {
      this.handleNoInternetAlert(false)
    })
  }

  reload () {
    window.location.reload()
  }

  componentWillUnmount () {
    window.removeEventListener('offline', () => {
      this.handleNoInternetAlert(true)
    })
    window.removeEventListener('online', () => {
      this.handleNoInternetAlert(false)
    })
  }

  handleNoInternetAlert = (showModal) => {
    this.setState({
      showNoInternetNotification: showModal
    })
  }

  render () {
    let { auction, autobids, selectLot, selectedLotId, hideImages, lotList, isSlowInternetSpeed, selectedNavItem } = this.props
    let { showNoInternetNotification } = this.state
    let hasNotification = Boolean(auction.notification)
    return (
      <div className='render-lots-container'>
        {
          isSlowInternetSpeed && (
            <div className='auction-notification-wrapper notification-without-internet'>
              <span className='icon-wrapper'>
                <div className='no-internet-image' />
              </span>
              <span className='auction-notification-text'>
                <p>No Network Available - <span>Please connect to join auction</span></p>
              </span>
            </div>
          )
        }
        {
          (isSlowInternetSpeed && !showNoInternetNotification) && (
            <div className='live-overlay'>
              <div className='text-container-low-connection'>
                <span className='icon-wrapper'>
                  <div className='no-internet-image' />
                </span>
                <span className='auction-notification-text'>
                  <p>
                  Slow Connection
                  </p>
                  <p>
                    <span> Refresh page <span className='hyperlink' onClick={this.reload}> here</span> to update bids</span>
                  </p>
                </span>
              </div>
            </div>
          )
        }
        {
          hasNotification
            ? (
              <div
                className={`auction-notification-wrapper ${showNoInternetNotification ? 'notification-after-no-internet' : ''}`}>
                <span className='icon-wrapper'>
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                </span>
                <span className='auction-notification-text'>
                  {auction.notification}
                </span>
              </div>
            )
            : null
        }
        <div
          className={`live-lots-list ${hasNotification || showNoInternetNotification || isSlowInternetSpeed ? 'live-lots-list-with-notification' : ''} ${(showNoInternetNotification && hasNotification) || (isSlowInternetSpeed && hasNotification) ? 'no-internet' : showNoInternetNotification ? 'live-lots-list-with-notification' : ''}`}>
          {this.props.lots.map((lot, index) => {
            let autobid = getMyAutobid(lot, autobids)
            return (
              <RenderLotContainer
                key={lot._id}
                lot={lot}
                auction={auction}
                selectedLotId={selectedLotId}
                hideImages={hideImages}
                selectLot={selectLot}
                autoBidStatus={autobid ? autobid.active : false}
                lotList={lotList}
                offsetMs={this.props.offsetMs}
                selectedNavItem={selectedNavItem}
                showOrderPad={index === 0}
                lots={this.props.lots}
                allLots={this.props.allLots}
              />
            )
          })}
        </div>
      </div>
    )
  }
}

export default RenderLots
