import { useEffect } from 'react';

const ScrollToTop = ({ children, xCoord, yCoord }) => {
const pathname = window.location.pathname

  useEffect(() => {
    window.scrollTo(xCoord, yCoord);
  }, [pathname,xCoord,yCoord]);

  return children || null;
};

export default ScrollToTop;