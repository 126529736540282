import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { showSpinner, showModal } from '../../actions'
import ChangeForgottenPassword from './ChangeForgottenPassword'

function mapDispatchToProps (dispatch) {
  return bindActionCreators({ showSpinner, showModal }, dispatch)
}

export default connect(null, mapDispatchToProps)(ChangeForgottenPassword)
