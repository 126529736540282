import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faCheck
} from '@fortawesome/fontawesome-free-solid'

class SelectField extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
    this.onChange = this.onChange.bind(this)
  }
  onChange (value) {
    if (value !== this.props.value) {
      this.props.onChange(value)
    }
  }
  render () {
    const { invalid, isRequired, title, value, options } = this.props
    return (
      <div className='form-row'>
        <div className='row-title'>
          <p className={`row-text ${invalid ? 'row-text-invalid' : ''}`}>
            {title}{isRequired ? <span className='asterisk'>*</span> : ''}:
          </p>
        </div>
        <div className='row-content selection-wrapper'>
          {
            options.map(option => {
              let isSelected = option.value === value
              return (
                <div
                  key={option.value}
                  onClick={() => this.onChange(option.value)}
                  className={`option-block ${isSelected ? 'active' : ''}`}
                >
                  {option.label}
                  {isSelected && <FontAwesomeIcon className='fa-check icon-green' icon={faCheck} />}
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }
}

SelectField.propTypes = {
  publicId: PropTypes.string,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  invalid: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired
}

SelectField.defaultProps = {
  invalid: false
}

export default SelectField
