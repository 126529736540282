import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { showModal } from '../../../actions/index'
import LiveHeader from './LiveHeader'

const mapStateToProps = (state) => {
  return {
    user: state.user.data
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ showModal }, dispatch)
}

const HeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LiveHeader)

export default HeaderContainer
