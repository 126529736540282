import React, { Component } from 'react'
import Toggle from 'react-toggle'
import BackButton from '../../BackButton/BackButton'
import ScrollToTop from '../../ScrollToTop/ScrollToTop'
import Api from '../../../services/api'
//import { isSeller } from '../../../utils/UpdateData'
export default class MyTexts extends Component {
  constructor () {
    super()
    this.state = {
      cattleAuctionOpenReminder: false,
      cattleOneDayReminder: false,
      cattleTenDaysReminder: false,
      sheepAuctionOpenReminder: false,
      sheepOneDayReminder: false,
      sheepTenDaysReminder: false,
      goatAuctionOpenReminder: false,
      goatOneDayReminder: false,
      goatTenDaysReminder: false
    }
  }

  componentDidMount () {
    const { smsRemindersPermissions } = this.props.data.settings
    this.setState({
      cattleAuctionOpenReminder: smsRemindersPermissions.cattleAuctionOpenReminder,
      cattleOneDayReminder: smsRemindersPermissions.cattleOneDayReminder,
      cattleTenDaysReminder: smsRemindersPermissions.cattleTenDaysReminder,
      sheepAuctionOpenReminder: smsRemindersPermissions.sheepAuctionOpenReminder,
      sheepOneDayReminder: smsRemindersPermissions.sheepOneDayReminder,
      sheepTenDaysReminder: smsRemindersPermissions.sheepTenDaysReminder,
      goatAuctionOpenReminder: smsRemindersPermissions.goatAuctionOpenReminder,
      goatOneDayReminder: smsRemindersPermissions.goatOneDayReminder,
      goatTenDaysReminder: smsRemindersPermissions.goatTenDaysReminder
    })
  }

  toggleSound (name) {
    this.props.showSpinner(true)
    const { ...settings } = this.props.data.settings
    let data = {
      settings
    }
    data.settings.smsRemindersPermissions[name] = !data.settings.smsRemindersPermissions[name]
    this.setState({
      [name]: !this.state[name]
    }, () => {
      Api.putUser(data).then((userData) => {
        this.props.showSpinner(false)
        this.props.changeStateProp('data', userData.user, 'user')
      }).catch((error) => {
        this.props.showSpinner(false)
        console.error(error)
      })
    })
  }

  renderTextTypeToggle (title, type, value) {
    return (
      <div className='sound-item'>
        <div className='sound-item-title'>
          <p className='sound-item-title-main'>{title}:</p>
        </div>
        <div className='sound-item-toggle'>
          <span>No</span>
          <Toggle data-testid='toggle' icons={false} onChange={() => {
            this.toggleSound(type)
          }} checked={value} />
          <span>Yes</span>
        </div>
      </div>
    )
  }
  render () {
    const {
      cattleAuctionOpenReminder,
      cattleOneDayReminder,
     // cattleTenDaysReminder,
      sheepAuctionOpenReminder,
      sheepOneDayReminder,
      //sheepTenDaysReminder,
      goatAuctionOpenReminder,
      goatOneDayReminder,
     // goatTenDaysReminder
    } = this.state
    return (
      <ScrollToTop xCoord={0} yCoord={0}>
      <div>
        <h1 className='fg-header'>Texts</h1>
        <p className='fg-paragraph'>Control your text message reminders</p>
        <BackButton onPress={() => {
          this.props.history.goBack()
        }} name='Back to My Admin' />
        <hr className='separator' />
        <div className='sound-wrapper'>
          <h1 className='toggle-header'>Cattle Text Settings</h1>
          {this.renderTextTypeToggle('Auction is Live', 'cattleAuctionOpenReminder', cattleAuctionOpenReminder)}
          {this.renderTextTypeToggle('Auto-Bids are Open', 'cattleOneDayReminder', cattleOneDayReminder)}
          {/* {isSeller() && this.renderTextTypeToggle('Listings are Open', 'cattleTenDaysReminder', cattleTenDaysReminder)} */}
          <h1 className='toggle-header'>Sheep Text Settings</h1>
          {this.renderTextTypeToggle('Auction is Live', 'sheepAuctionOpenReminder', sheepAuctionOpenReminder)}
          {this.renderTextTypeToggle('Auto-Bids are Open', 'sheepOneDayReminder', sheepOneDayReminder)}
          {/* {isSeller() && this.renderTextTypeToggle('Listings are Open', 'sheepTenDaysReminder', sheepTenDaysReminder)} */}
          <h1 className='toggle-header'>Goat Text Settings</h1>
          {this.renderTextTypeToggle('Auction is Live', 'goatAuctionOpenReminder', goatAuctionOpenReminder)}
          {this.renderTextTypeToggle('Auto-Bids are Open', 'goatOneDayReminder', goatOneDayReminder)}
          {/* {isSeller() && this.renderTextTypeToggle('Listings are Open', 'goatTenDaysReminder', goatTenDaysReminder)} */}
        </div>
      </div>
      </ScrollToTop>
    )
  }
}
