import React, { PureComponent } from 'react'
import { Switch, Route, Redirect, NavLink } from 'react-router-dom'

import { onEnter } from '../../utils/UpdateData'
import ManualsContainer from './Manuals/ManualsContainer'
import ListingFees from './ListingFees/ListingFees'
import Policy from './Policy/Policy'
import TermsContainer from './Terms/TermsContainer'
import FaqsContainer from './Faqs/FaqsContainer'
import SearchContainer from './Search/SearchContainer'
import VideosContainer from './Videos/VideosContainer'
import GettingStartedContainer from './GettingStarted/GettingStartedContainer'
import { subTitles } from '../../constants/strings'

const buttons = [
  {
    text: 'Forms and Manuals',
    url: '/main/tools/manuals',
    cls: 'forms',
    sellerOnly: true
  },
  {
    text: 'Listing Fees',
    url: '/main/tools/listing',
    cls: 'listing',
    sellerOnly: true
  },
  {
    text: 'How-To Videos & Tips',
    url: '/main/tools/videos-tips',
    cls: 'videos-tips'
  },
  {
    text: 'Getting Started & FAQs',
    url: '/main/tools/faqs',
    cls: 'faqs'
  },
  {
    text: 'Search for an Agent',
    url: '/main/tools/search',
    cls: 'search'
  },
  {
    text: 'Terms & Conditions',
    url: '/main/tools/terms',
    cls: 'terms'
  },
  {
    text: 'Privacy Policy',
    url: '/main/tools/privacy-policy',
    cls: 'privacy-policy'
  }
  // {
  //   text: 'Getting Started',
  //   url: '/main/tools/getting-started',
  //   cls: 'getting-started'
  // }
]

class Tools extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      assessmentForms: []
    }
  }

  componentDidMount () {
    if (this.props.history.action !== 'POP') {
      window.scroll(0, 0)
    }
  }

  renderButton (button, index) {
    return (
      <NavLink key={index} className='manage-item' to={button.url}>
        <div className={`left ${button.cls}`} style={{ opacity: 1 - (buttons.length - (index + 1)) * 0.06 }}>
          <div className='img-container' />
        </div>
        <div className='right'>
          <p className='item-title'>{button.text}</p>
        </div>
      </NavLink>
    )
  }

  render () {
    return (
      <div className='app-route'>
        <div className='body-container'>
          <div className='inner-container'>
            {onEnter(
              <Switch>
                <Route path='/main/tools/manuals' component={ManualsContainer} />
                <Route path='/main/tools/listing' component={ListingFees} />
                <Route path='/main/tools/privacy-policy' component={Policy} />
                <Route path='/main/tools/terms' component={TermsContainer} />
                <Route path='/main/tools/getting-started' component={GettingStartedContainer} />
                <Route path='/main/tools/faqs' component={FaqsContainer} />
                <Route path='/main/tools/search' component={SearchContainer} />
                <Route path='/main/tools/videos-tips' component={VideosContainer} />
                <Route exact path='/main/tools' render={(obj) => {
                  return (
                    <div className='tools'>
                      <h1 className='fg-header'>Tools</h1>
                      <p className='fg-paragraph'>{this.props.userType === 'seller' ? subTitles.TOOLS : subTitles.BUYER_TOOLS}</p>
                      <div className='tools-list'>
                        {
                          buttons.map((button, index) => (
                            this.renderButton(button, index)
                          )
                          )
                        }
                      </div>
                    </div>
                  )
                }}
                />
                <Redirect to='/main/tools' />
              </Switch>
            )}
          </div>
        </div>
      </div>
    )
  }
}

Tools.propTypes = {}
Tools.defaultProps = {}

export default Tools
