import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { showModal } from '../../../actions'
import { MAX_HEADS_WEIGHED, NUMBER_REGEXP, MIN_WEIGHED_HEADS_PERCENTAGE } from '../../../constants'

class WeighedHeadsInput extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      value: this.props.countWeighed
    }
    this.onChange = this.onChange.bind(this)
    this.handleWeight = this.handleWeight.bind(this)
    this.input = React.createRef()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.countWeighed !== this.props.countWeighed) {
      this.setState({
        value: this.props.countWeighed
      })
    }
  }

  onChange () {
    let { value } = this.input.current
    if (NUMBER_REGEXP.test(value) || !value) {
      this.setState({ value })
    }
  }

  isCountValid (value) {
    if (this.props.kind === 'goat' && !value) {
      this.setState({ value: 0 })
      this.props.onChange(0, 'countWeighed')
      return false
    }
    if (this.props.count < value) {
      this.props.showModal({
        message: 'Number of Head Weighed can\'t be higher then Number of Head in Lot',
        buttons: [
          {
            text: 'OK'
          }
        ]
      })
      this.setState({ value: this.props.kind === 'sheep' ? 1 : 0 })
      this.props.onChange(this.props.kind === 'sheep' ? 1 : 0, 'countWeighed')
      return false
    }
    if (this.props.kind === 'sheep' && value && (value * 100 / this.props.count) < MIN_WEIGHED_HEADS_PERCENTAGE) {
      this.props.showModal({
        message: `Please weigh ${MIN_WEIGHED_HEADS_PERCENTAGE}% sample size`,
        buttons: [
          {
            text: 'OK'
          }
        ]
      })
      this.setState({ value: 1 })
      this.props.onChange(1, 'countWeighed')
      return false
    } else if (value && value > this.props.count) {
      this.props.showModal({
        message: 'Number of Head Weighed can\'t be higher then Number of Head in Lot',
        buttons: [
          {
            text: 'OK'
          }
        ]
      })
      return false
    }
    return true
  }

  handleWeight () {
    let { value } = this.state
    let newValue
    newValue = parseInt(value, 10)
    newValue = Math.min(newValue, MAX_HEADS_WEIGHED) || null
    if (this.isCountValid(newValue)) {
      this.setState({ value: newValue })
      if (newValue !== this.props.countWeighed) {
        this.props.onChange(newValue, 'countWeighed')
      }
    }
  }

  render () {
    const { value } = this.state
    return (
      <div className={`form-row ${this.props.disabled ? 'disabled' : ''}`}>
        <div className='row-title'>
          <p className={`row-text ${this.props.kind === 'sheep' && value && (value * 100 / this.props.count) < MIN_WEIGHED_HEADS_PERCENTAGE ? 'row-text-invalid' : ''}`}>{this.props.kind === 'goat' ? 'Number of head Weighed/Weight Estimated' : 'Number of Head Weighed'}{this.props.kind !== 'goat' ? <span className='asterisk'>*</span> : null}:</p>
        </div>
        <div className='row-content'>
          <input
            className='input'
            placeholder={this.props.kind === 'sheep' ? '0' : ''}
            type='text'
            ref={this.input}
            value={value ? value.toString() : ''}
            onChange={this.onChange}
            onBlur={this.handleWeight}
          />
        </div>
      </div>
    )
  }
}

WeighedHeadsInput.propTypes = {
  count: PropTypes.number.isRequired,
  kind: PropTypes.string.isRequired,
  countWeighed: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

WeighedHeadsInput.defaultProps = {
  disabled: false
}

export default connect(
  null,
  (dispatch) => {
    return bindActionCreators({ showModal }, dispatch)
  }
)(WeighedHeadsInput)
