import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { mergeStateProp } from '../../../actions'
import { lotById } from '../../../selectors/lotsSelector'
import LotInfo from './LotInfo'

const mapStateToProps = (state, ownProps) => {
  return {
    loggedIn: state.user.loggedIn,
    lot: lotById({ lotId: ownProps.lotId, lots: state.data.lots }) || null
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ mergeStateProp }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LotInfo)
