import { parsePhoneNumberFromString } from 'libphonenumber-js'
import {
  FIRST_LAST_NAME,
  EMAIL_REGEXP,
  PASSWORD_REGEXP,
  WEBSITE_REGEXP,
  PHONE_REGEXP,
  AUSTRALIAN_POSTCODE,
  PHONE_SECOND_REGEXP,
  ABN_REGEXP,
  ACN_REGEXP,
  ADDRESS_REGEXP,
  NSW_REGEXP,
  QLD_REGEXP,
  VIC_REGEXP,
  WA_REGEXP,
  SA_REGEXP,
  NT_REGEXP,
  TAS_REGEXP,
  FAVORITE_PIC_PHONE_REGEXP, ADDRESS, ACCOUNT_NAME_NUMBER,
  BSB,
  NUMBER_REGEXP,
} from '../constants/index'
import { validators as strings } from '../constants/strings'

export function executePhoneValidator (phoneNum) {
  let phone = 61 + phoneNum
  let isValid = false
  try {
    let number = `+${phone}`
    isValid = parsePhoneNumberFromString(number).isValid() && PHONE_REGEXP.test(phoneNum)
  } catch (e) {}
  return isValid
}
export function executePicPhoneValidator (phoneNum) {
  let isValid = false
  try {
    isValid = FAVORITE_PIC_PHONE_REGEXP.test(phoneNum)
  } catch (e) {}
  return isValid
}
export function validateSecondaryPhone (secondaryPhone) {
  if (!secondaryPhone) {
    return ''
  } else if (!(PHONE_SECOND_REGEXP.test(secondaryPhone))) {
    return strings.INCLUDE_AREA_CODE
  } else {
    return ''
  }
}
export function validatePhone (phone, { required = true } = {}) {
  if (!phone) {
    if (required) {
      return strings.ENTER_CONTACT_NUMBER
    } else {
      return ''
    }
  } else if (!executePhoneValidator(phone)) {
    return strings.CORRECT_MOBILE_NUMBER
  } else {
    return ''
  }
}
export function userOperationType (userOperationType, { required = true } = {}) {
  if (!userOperationType) {
    if (required) {
      return strings.USEROPERATIONTYPE
    } else {
      return ''
    }
  } else {
    return ''
  }
}

export function validateVendorPicPhone (phone, { required = true } = {}) {
  if (!phone) {
    if (required) {
      return strings.ENTER_CONTACT_NUMBER
    } else {
      return ''
    }
  } else if (!executePicPhoneValidator(phone)) {
    return strings.CORRECT_MOBILE_NUMBER
  } else {
    return ''
  }
}
export function validateRelationship (relationship, { required = true } = {}) {
  if (!relationship) {
    if (required) {
      return strings.RELATIONSHIP
    } else {
      return ''
    }
  } else if (!relationship.trim().length) {
    return strings.INCORRECT_RELATIONSHIP
  } else {
    return ''
  }
}
export function validatePostcode (postcode, { required = true } = {}) {
  if (!postcode) {
    if (required) {
      return strings.POSTCODE
    } else {
      return ''
    }
  } else if (!(AUSTRALIAN_POSTCODE.test(postcode))) {
    return strings.INCORRECT_POSTCODE
  } else {
    return ''
  }
}
export function validateTown (town, { required = true } = {}) {
  if (!town) {
    if (required) {
      return strings.TOWN
    } else {
      return ''
    }
  } else if (!(ADDRESS.test(town))) {
    return strings.INCORRECT_TOWN
  } else {
    return ''
  }
}
export function validateWebsite (website) {
  if (!website) {
    return ''
  } else if (!(WEBSITE_REGEXP.test(website))) {
    return strings.INCORRECT_WEBSITE
  } else {
    return ''
  }
}
export function validateTradingName (tradingName, { required = true } = {}) {
  if (!tradingName) {
    if (required) {
      return strings.TRADING_NAME
    } else {
      return ''
    }
  } else if (!tradingName.trim().length) {
    return strings.INCORRECT_TRADING_NAME
  } else {
    return ''
  }
}

export function validatePic (pic, state, { required = true, validateForState = true } = {}) {
  pic = pic.trim()
  if (!pic) {
    if (required) {
      return strings.PIC
    } else {
      return ''
    }
  } else if (!state) {
    if (validateForState) {
      return strings.INCORRECT_PIC
    } else {
      return ''
    }
  } else if (pic && state) {
    return picCorrespondsToTheRules(pic, state)
  }
}
export function picCorrespondsToTheRules (pic, state) {
  switch (state) {
    case 'NSW':
      return !NSW_REGEXP.test(pic) ? 'PIC Numbers in NSW are 8 digits and start with a letter N then 1 letter in the range of A-K and 6 numbers.' : ''
    case 'QLD':
      return !QLD_REGEXP.test(pic) ? 'PIC Numbers in QLD are 8 digits and start with a letter Q then 3 letters then 4 numbers.' : ''
    case 'VIC':
      return !VIC_REGEXP.test(pic) ? 'PIC Numbers in VIC are 8 digits and start with a number 3 then 4 letters then 3 numbers.' : ''
    case 'WA':
      return !WA_REGEXP.test(pic) ? 'PIC Numbers in WA are 8 digits and start with a letter W then 3 letters then 4 numbers.' : ''
    case 'SA':
      return !SA_REGEXP.test(pic) ? 'PIC Numbers in SA are 8 digits and all start with SA or SB or SC or SK then 6 numbers.' : ''
    case 'NT':
      return !NT_REGEXP.test(pic) ? 'PIC Numbers in NT are 8 digits and start with a letter T then 3 letters then 4 numbers.' : ''
    case 'TAS':
      return !TAS_REGEXP.test(pic) ? 'PIC Numbers in TAS are 8 digits and start with a letter M then 3 letters then 4 numbers.' : ''
    default:
      return ''
  }
}
export function validateAbn (abn, { required = true } = {}) {
  if (!abn) {
    if (required) {
      return strings.ABN
    } else {
      return ''
    }
  } else if (!ABN_REGEXP.test(abn)) {
    return strings.INCORRECT_ABN
  } else {
    return ''
  }
}
export function validateStock (stock, { required = true } = {}) {
  if (stock.length === 0) {
    if (required) {
      return strings.STOCK
    } else {
      return ''
    }
  } 
   else {
    return ''
  }
}
export function validateAcn (acn, { required = true } = {}) {
  if (!acn) {
    if (required) {
      return strings.ACN
    } else {
      return ''
    }
  } else if (!ACN_REGEXP.test(acn)) {
    return strings.INCORRECT_ACN
  } else {
    return ''
  }
}
export function validateFirstName (name, { required = true } = {}) {
  if (!name) {
    if (required) {
      return strings.FIRST_NAME
    } else {
      return ''
    }
  } else if (!(FIRST_LAST_NAME.test(name) && (name && name.trim().length >= 1 && name.trim().length <= 30))) {
    return strings.INCORRECT_FIRST_NAME
  } else {
    return ''
  }
}

export function validateLastName (name, { required = true } = {}) {
  if (!name) {
    if (required) {
      return strings.LAST_NAME
    } else {
      return ''
    }
  } else if (!(FIRST_LAST_NAME.test(name) && (name && name.trim().length >= 1 && name.trim().length <= 30))) {
    return strings.INCORRECT_LAST_NAME
  } else {
    return ''
  }
}

export function validateCompanyName (name, { required = true } = {}) {
  if (!name) {
    if (required) {
      return strings.COMPANY_NAME
    } else {
      return ''
    }
  } else if (!(FIRST_LAST_NAME.test(name) && (name && name.trim().length >= 1 && name.trim().length <= 30))) {
    return strings.INCORRECT_COMPANY_NAME
  } else {
    return ''
  }
}
export function validateGivenName (name, { required = true } = {}) {
  if (!name) {
    if (required) {
      return strings.GIVEN_NAME
    } else {
      return ''
    }
  } else if (!(FIRST_LAST_NAME.test(name) && (name && name.trim().length >= 1 && name.trim().length <= 30))) {
    return strings.INCORRECT_GIVEN_NAME
  } else {
    return ''
  }
}
export function validateLegalNameOfTradingEntityName (name, { required = true } = {}) {
  if (!name) {
    if (required) {
      return strings.LEGAL_ENTITY_NAME
    } else {
      return ''
    }
  } else if (!(FIRST_LAST_NAME.test(name) && (name && name.trim().length >= 1 && name.trim().length <= 30))) {
    return strings.INCORRECT_LEGAL_ENTITY_NAME
  } else {
    return ''
  }
}
export function validateEmail (email, { required = true } = {}) {
  if (!email) {
    if (required) {
      return strings.EMAIL
    } else {
      return ''
    }
  } else if (!(EMAIL_REGEXP.test(email) && (email && email.length >= 3 && email.length <= 60))) {
    return strings.INCORRECT_EMAIL
  } else {
    return ''
  }
}
export function validatePassword (password) {
  if (!password) {
    return strings.PASSWORD
  } else if (!(PASSWORD_REGEXP.test(password) && (password && password.length >= 6 && password.length <= 50))) {
    return strings.INCORRECT_PASSWORD
  } else {
    return ''
  }
}
export function validateRePassword (repassword, password) {
  if (!repassword) {
    return strings.REPASSWORD
  } else if (!(PASSWORD_REGEXP.test(repassword) && (repassword && repassword.length >= 6 && repassword.length <= 50))) {
    return strings.REPASSWORD
  } else if (repassword !== password) {
    return strings.INCORRECT_REPASSWORD
  } else {
    return ''
  }
}
export function validateAddress (address) {
  if (!address) {
    return strings.ADDRESS
  } else if (!(ADDRESS_REGEXP.test(address) && (address && address.length >= 1 && address.length <= 82))) {
    return strings.INCORRECT_ADDRESS
  } else {
    return ''
  }
}

export function validateSSPermitNumber (name, { required = true } = {}) {
  name = name.trim()
  if (!name) {
    if (required) {
      return strings.LICENSE
    } else {
      return ''
    }
  } else if (!(FIRST_LAST_NAME.test(name) && (name.length >= 1 && name.length <= 30))) {
    return strings.INCORRECT_LICENSE
  } else {
    return ''
  }
}
export function validateSSPermitNumberOfYears (name, { required = true } = {}) {
  name = name.trim()
  if (!name) {
    if (required) {
      return strings.YEARS_HELD
    } else {
      return ''
    }
  } else if (!(FIRST_LAST_NAME.test(name) && (name.length >= 1 && name.length <= 30))) {
    return strings.INCORRECT_YEARS_HELD
  } else {
    return ''
  }
}
export function validateNumberYearsInIndustry (name, { required = true } = {}) {
  name = name.trim()
  if (!name) {
    if (required) {
      return strings.YEARS_INDUSTRY
    } else {
      return ''
    }
  } else if (!(FIRST_LAST_NAME.test(name) && (name.length >= 1 && name.length <= 30))) {
    return strings.INCORRECT_YEARS_INDUSTRY
  } else {
    return ''
  }
}
export function validateState (name, { required = true } = {}) {
  if (!name) {
    if (required) {
      return strings.STATE
    } else {
      return ''
    }
  } else {
    return ''
  }
}

export function validateDistrict (name, { required = true } = {}) {
  if (!name) {
    if (required) {
      return strings.DISTRICT
    } else {
      return ''
    }
  } else {
    return ''
  }
}

export function validateJobTitle (name) {
  if (!name || !name.trim().length) {
    return strings.JOB_TITLE
  } else {
    return ''
  }
}

export function validateAccountName (name, { required = true } = {}){
  if(!name) {
    if (required) {
      return strings.ACCOUNT_NAME
    } else {
      return ''
    }
  } else if (!(ACCOUNT_NAME_NUMBER.test(name) && (name && name.trim().length >=1 && name.trim().length <= 30) )) {
    return strings.INCORRECT_ACCOUNT_NAME
  } else {
    return ''
  }
}

export function validateAccountNumber (name, { required = true } = {}){
  if(!name){
    if(required){
      return strings.ACCOUNT_NUMBER
    } else {
      return ''
    }
  } else if (!(NUMBER_REGEXP.test(name))) {
    return strings.INCORRECT_ACCOUNT_NUMBER
  } else {
    return ''
  }
}

export function validateBsb (name, { required = true } = {}){
  if(!name){
    if(required){
      return strings.BSB
    } else {
      return ''
    }
  } else if (!(BSB.test(name) && (name && name.trim().length >=1 && name.trim().length <= 30))){
    return strings.INCORRECT_BSB
  } else {
    return ''
  }
}


export function validateOtherOptionType (name, { required = true } = {}) {
  if (!name) {
    if (required) {
      return strings.OTHER_OPTION_TYPE
    } else {
      return ''
    }
  } else if (!name.trim().length) {
    return strings.INCORRECT_OTHER_OPTION_TYPE
  } else {
    return ''
  }
}
export function validateTradingType (tradingType, { required = true } = {}) {
  if (!tradingType) {
    if (required) {
      return strings.TRADING_TYPE
    } else {
      return ''
    }
  } else {
    return ''
  }

}
export function validateAgentName (name, { required = true } = {}) {
  if (!name) {
    if (required) {
      return strings.AGENT_NAME
    } else {
      return ''
    }
  } else if (!(FIRST_LAST_NAME.test(name) && (name && name.trim().length >= 1 && name.trim().length <= 30))) {
    return strings.INCORRECT_AGENT_NAME
  } else {
    return ''
  }
}
export function validateregisteredForGST (name, { required = true } = {}) {
  if (!name) {
    if (required) {
      return strings.REGISTERED_FOR_GST
    } else {
      return ''
    }
  }  else {
    return ''
  }
}
