import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { changeStateProp, mergeStateProp, showModal } from '../../../actions'
import { lotById } from '../../../selectors/lotsSelector'
import { auctionById } from '../../../selectors/auctionsSelector'
import Bidding from './Bidding'

const mapStateToProps = (state, ownProps) => {
  return {
    lot: lotById({ lotId: ownProps.lotId, lots: state.data.lots }) || null,
    auction: auctionById({ auctionId: ownProps.auctionId, auctions: state.data.auctions }),
    user: state.user,
    biddingSwapLots: state.temp.biddingSwapLots,
    auctionLiveLotsFormat: state.temp.auctionLiveLotsFormat
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeStateProp, mergeStateProp, showModal }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Bidding)
