import styled from 'styled-components'

export const ImgManageButton = styled.div`
  font-size: 14px;
  border: 1px solid black;
  width: 130px;
  display: flex;
  justify-content: space-between;
  font-family: 'Lato';
  text-align: center;
  height: 40px;
  color: #030404;
  border-radius: 5px;
  cursor: pointer;
  -webkit-touch-callout: none;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  p {
    width: 70px;
    margin: 0;
    padding-top: 10px;
    padding-left: 10px;
    @media (max-width: 550px) {
      padding-left: 0;
    }
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center
    width: 35px;
    height: 38px;
    border-left: 1px solid black;
    border-radius: 5px;
    background-color: #1f9512;
    img {
      width: 20px;
      height: 20px;
    }
  }
  @media (max-width: 550px) {
    width: 100px;
  }
`
