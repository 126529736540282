import Immutable from 'immutable'
import moment from 'moment'
import memoize from 'memoize-one'

import { findFieldByPublicId, findAllFields } from './FindFieldLot'
// import { picCorrespondsToTheRules } from './Validators'
import { bidTypes, PIC_REGEXP } from '../constants'
import { haveWeanedFieldIsNotValid } from './WeaningDetails'

// get default value for non-nested fields
let getDefaultFieldValue = (field) => {
  let value
  switch (field.type) {
    case 'text':
    case 'textarea':
    case 'date':
    case 'dropdown':
    case 'email':
    case 'number':
    case 'phone':
    case 'user':
    case 'tradingName':
    case 'picNumber':
      // text fields - default value is empty string
      value = ''
      break
    case 'bool':
    case 'checkbox':
      // boolean fields - default value is boolean
      value = false
      break
    case 'weight':
      // numeric fields - default value is numeric
      value = 0
      break
    case 'rating':
      // rating field - default value is 0
      value = 0
      break
    case 'select':
      // select field - if it has defaultValue prop, set it as default, otherwise an empty string
      if (field.defaultValue) {
        value = field.defaultValue
      } else {
        value = ''
      }
      break
    case 'multiDropdown':
      // multidropdown field - default value is empty array
      value = []
      break
    default:
      break
  }
  return value
}

// some accordions need to pre-fill with null instead of false
let getDefaultAccordionValue = (accordion) => {
  if (accordion.publicId === 'haveWeaned') {
    return null
  } else {
    return false
  }
}

let canEmptyFieldValue = (field) => {
  let canDelete = false
  switch (field.type) {
    case 'text':
    case 'textarea':
    case 'date':
    case 'dropdown':
    case 'email':
    case 'number':
    case 'phone':
    case 'user':
    case 'tradingName':
    case 'picNumber':
    case 'select':
      // text fields
      canDelete =  (field.value === null || (field.value && field.value.trim() === ''))
      break
    case 'bool':
    case 'checkbox':
      // boolean fields
      canDelete = false
      break
    case 'weight':
      // numeric fields
      canDelete = (field.value === null || field.value === 0)
      break
    case 'rating':
      // rating field
      canDelete = (field.value === null || field.value === 0)
      break
    case 'multiDropdown':
      // multidropdown field
      canDelete = (field.value === null || ( field.value && field.value.length === 0))
      break
    default:
      break
  }
  return canDelete
}

// main field validation function
// you can add unique validations here
let isFieldInvalid = ({ field, draft, context, isOptiweigh, isValidGoatWeights }) => {
  let isInvalid = false
  switch (field.type) {
    case 'text':
    case 'textarea':
    case 'date':
    case 'dropdown':
    case 'email':
    case 'number':
    case 'phone':
    case 'user':
    case 'tradingName':
    case 'select':
      if (field.isRequired && !draft) {
        isInvalid = (field.value === null || field.value.trim() === '')
      }
      break
    case 'weight':
      if (field.isRequired && !draft) {
        isInvalid = field.value === 0
      }
      break
    case 'rating':
      if (field.isRequired && !draft) {
        isInvalid = field.value === 0
      }
      break
    case 'picNumber':
      if (field.isRequired && !draft) {
        isInvalid = !PIC_REGEXP.test(field.value)
      } else if (!draft) {
        isInvalid = !PIC_REGEXP.test(field.value) && field.value.trim() !== ''
      }
      break
    case 'multiDropdown':
      if (field.isRequired && !draft) {
        isInvalid = field.value.length === 0
      }
      break
    case 'repeatForNumber':
      if (isOptiweigh || isValidGoatWeights) {
        return false
      }
      if (!draft) {
        isInvalid = field.values.some(fieldset => {
          return validateForm(fieldset, isOptiweigh, isValidGoatWeights, { draft, change: false, context }).invalid
        })
      }
      break
    default:
      break
  }

  // validations for special cases
  if (!isInvalid) {
    switch (field.publicId) {
      case 'vendorPic':
        if (!draft) {
          // hide validation for Pic field in LotForm
          // let stateField = findFieldByPublicId(context, 'state')
          // isInvalid = Boolean(stateField && stateField.value && picCorrespondsToTheRules(field.value, stateField.value))
        }
        break
      case 'haveWeaned':
        if (!draft) {
          isInvalid = haveWeanedFieldIsNotValid(context)
        }
        break
      default:
        break
    }
  }

  return isInvalid
}

/*
  recursively pre-fill all fields in a form
  mode is either 'kind' or 'lot'
  if 'kind' - function will look at 'forms' prop for nested fields
  if 'lot' - function will look at 'values' prop for nested fields

  DO NOT pass form fields to frontend components without previously running them through this function
*/
export function prefillForm (fields, { mode }, kindType) {
  if (!Array.isArray(fields)) {
    fields = [fields]
  }
  let newFields = Immutable.List([])
  fields.length > 0 && fields[0] !== undefined &&  fields.forEach((field, index) => {
    let newField = Immutable.Map(field)
    // handle special nested types first
    switch (field.type) {
      case 'group':
        // 'group' is a top-level field type, simply prefill all nested values in it
        let nestedFields
        switch (mode) {
          case 'kind':
            nestedFields = field.forms
            break
          case 'lot':
          default:
            nestedFields = field.values
        }
        let newNestedFields = prefillForm(nestedFields, { mode }, kindType)
        newField = newField.set('values', newNestedFields)

        if (mode === 'kind') {
          newField = newField.delete('forms')
        }
        break
      case 'repeatForNumber':
        switch (mode) {
          case 'kind': {
            let newNestedFields = [
              prefillForm(field.forms, { mode }, kindType)
            ]
            newField = newField.set(
              'values',
              kindType === 'goat' ? [] : newNestedFields
            )
            break
          }
          case 'lot':
          default: {
            let newNestedFields = Immutable.List(field.values)
            field.values.forEach((nestedFields, index) => {
              newNestedFields = newNestedFields.set(index, prefillForm(nestedFields, { mode }, kindType))
            })
            newField = newField.set('values', newNestedFields.toArray())
          }
        }

        if (mode === 'kind') {
          newField = newField.delete('forms')
        }
        // remove 'value' prop from Individual Assessment, which will be generated on server
        newField = newField.delete('value')
        break
      case 'repeatForBool':
        if (typeof field.value !== 'boolean') {
          newField = newField.set('value', getDefaultAccordionValue(field))
        }
        switch (mode) {
          case 'kind': {
            let newNestedFields = [prefillForm(field.forms, { mode }, kindType)]
            newField = newField.set('values', newNestedFields)
            break
          }
          case 'lot':
          default: {
            let newNestedFields = Immutable.List(field.values)
            field.values.forEach((nestedFields, index) => {
              newNestedFields = newNestedFields.set(index, prefillForm(nestedFields, { mode }, kindType))
            })
            newField = newField.set('values', newNestedFields.toArray())
          }
        }

        if (mode === 'kind') {
          newField = newField.delete('forms')
        }
        break
      default:
        // handle all other types (not nested)
        if (field.value) {
          // if field value is already truthy
          switch (field.type) {
            case 'rating':
              // mark already pre-filled rating fields as they were edited before
              newField = newField.set('ratingChanged', true)
              break
            default:
              break
          }
        } else {
          // if field value is falsy, populate it with default value
          newField = newField.set('value', getDefaultFieldValue(field))
        }
    }

    newField = newField.set('invalid', false)
    newFields = newFields.set(index, newField.toJS())
  })
  return newFields.toArray()
}

// 'clears' all fields - resets value to default, etc.
// returns a copy of passed fields, old fields remain unchanged
// does not support nested fields
export function resetForm (fields) {
  if (!Array.isArray(fields)) {
    fields = [fields]
  }
  let newFields = Immutable.List([])
  fields.forEach((field, index) => {
    let newField = Immutable.Map(field)
    // reset value to default
    newField = newField.set('value', getDefaultFieldValue(field))
    // clear validations
    newField = newField.set('invalid', false)
    // set field as marked, so that autosave picks it up
    newField = newField.set('markedChanged', true)

    newFields = newFields.set(index, newField.toJS())
  })
  return newFields.toArray()
}

/*
  clears a field from frontend modifications
  making it ready to be sent in requests

  DO NOT send form fields in requests without previously running them through this function
*/
export function cleanField (field, { draft }) {
  let newField = Immutable.Map(field)
  // handle nested types
  switch (field.type) {
    case 'group': {
      // handle groups
      newField = newField.delete('forms')
      newField = newField.set('values', cleanFields(field.values, { draft }))
      break
    }
    case 'repeatForNumber': {
      // handle Individual Assessment
      newField = newField.delete('value')
      newField = newField.delete('forms')
      let newNestedFields = Immutable.List(field.values)
      field.values.forEach((nestedFields, index) => {
        newNestedFields = newNestedFields.set(index, cleanFields(nestedFields, { draft }))
      })
      newField = newField.set('values', newNestedFields.toArray())
      break
    }
    case 'repeatForBool': {
      // handle accordions
      newField = newField.delete('forms')
      let newNestedFields = Immutable.List(field.values)
      field.values.forEach((nestedFields, index) => {
        if (!field.value) {
          // if accordion is closed, close its nested accordions and reset values of its nested fields
          nestedFields = nestedFields.map(nestedField => {
            let newNestedField = Immutable.Map(nestedField)
            switch (nestedField.type) {
              case 'repeatForBool':
                newNestedField = newNestedField.set('value', getDefaultAccordionValue(nestedField))
                break
              default:
                newNestedField = newNestedField.set('value', getDefaultFieldValue(nestedField))
            }
            return newNestedField.toJS()
          })
        }
        newNestedFields = newNestedFields.set(index, cleanFields(nestedFields, { draft }))
      })
      newField = newField.set('values', newNestedFields.toArray())
      break
    }
    default:
      break
  }
  /*
    we want to set 'empty' values to null
  */
  if (!field.isRequired || draft) {
    if (canEmptyFieldValue(field)) {
      newField = newField.set('value', null)
    }
  }
  // delete frontend-only props
  newField = newField
    .delete('invalid')
    .delete('ratingChanged')
    .delete('markedChanged')
  return newField.toJS()
}

/*
  cleanField wrapper for arrays
*/
export function cleanFields (fields, { draft }) {
  let newFields = Immutable.List([])
  fields.forEach((field, index) => {
    let newField = cleanField(field, { draft })
    newFields = newFields.set(index, newField)
  })
  return newFields.toArray()
}

/*
  recursively validate all form fields

  returns {
    values: {...} - same fields, but with updated 'invalid' prop
    invalid: true|false - is there at least one invalid field or not
  }
*/
export function validateForm (fields, isOptiweigh, isValidGoatWeights, { draft = false, change = true, context, optiweighAllowed } = {}) {
  /* use change = false if possible, it just returns 'invalid: true' if values are invalid
    without creating Immutable objects
    which drastically increases performance */
  if (!context) {
    // this function is recursive, but we want to keep a reference to initial fields
    context = fields
  }
  let invalid = false
  let newFields = change ? Immutable.List(fields) : fields
  fields.forEach((field, index) => {
    let newField = change ? Immutable.Map(field) : field
    // reset field invalid state
    if (change) {
      newField = newField.set('invalid', false)
    }
    // handle nested types
    switch (field.type) {
      case 'group': {
        if (field.publicId === 'temperamentGroup' && !optiweighAllowed) {
          let validatedTemperament = validateTemperament(field.values)
          invalid = invalid || validatedTemperament.invalid
          if (change) {
            newField = newField.set('values', validatedTemperament.values)
          }
        } else {
          // handle fields inside groups
          let validatedNestedFields = validateForm(field.values, isOptiweigh, isValidGoatWeights, { draft, change, context })
          invalid = invalid || validatedNestedFields.invalid
          if (change) {
            newField = newField.set('values', validatedNestedFields.values)
          }
        }
        break
      }
      case 'repeatForNumber': {
        if (isOptiweigh || isValidGoatWeights) {
          break
        }
        // handle fields inside Individual Assessment
        let newNestedFields = change ? Immutable.List(field.values) : field.values
        let nestedInvalid = false
        field.values.forEach((nestedFields, index) => {
          let validatedNestedFields = validateForm(nestedFields, isOptiweigh, isValidGoatWeights, { draft, change, context })
          nestedInvalid = nestedInvalid || validatedNestedFields.invalid
          if (change) {
            newNestedFields = newNestedFields.set(index, validatedNestedFields.values)
          }
        })
        invalid = invalid || nestedInvalid
        if (change) {
          newField = newField.set('values', newNestedFields.toArray())
        }
        break
      }
      case 'repeatForBool': {
        // handle fields inside accordions
        let newNestedFields = change ? Immutable.List(field.values) : field.values
        let nestedInvalid = false
        field.values.forEach((nestedFields, index) => {
          let validatedNestedFields = validateForm(nestedFields, isOptiweigh, isValidGoatWeights, { draft, change, context })
          nestedInvalid = nestedInvalid || validatedNestedFields.invalid
          if (change) {
            newNestedFields = newNestedFields.set(index, validatedNestedFields.values)
          }
        })
        // if accordion is closed, validate it regardless of values inside
        invalid = invalid || (field.value ? nestedInvalid : false)
        if (change) {
          newField = newField.set('values', newNestedFields.toArray())
        }
        break
      }
      default:
        break
    }
    // field validation
    let isInvalid = isFieldInvalid({ field, draft, context, isOptiweigh, isValidGoatWeights })
    invalid = invalid || isInvalid
    if (change) {
      newField = newField.set('invalid', isInvalid)
      newFields = newFields.set(index, newField.toJS())
    }
  })
  return {
    values: change ? newFields.toArray() : newFields,
    invalid: invalid
  }
}

export function validateTemperament (temperamentFields) {
  let invalid = true
  temperamentFields.forEach(field => {
    if (field.type === 'number' && field.value) {
      invalid = false
    }
  })

  let newFields = Immutable.List(temperamentFields)
  newFields.forEach((field, index) => {
    if (field.type === 'number') {
      let newField = Immutable.Map(field)
      newField = newField.set('invalid', invalid)
      newFields = newFields.set(index, newField.toJS())
    }
  })

  return {
    values: newFields.toArray(),
    invalid: invalid
  }
}

export function transformBidding (option) {
  let label
  switch (option) {
    case bidTypes.KG:
      label = '¢/kg'
      break
    case bidTypes.HEAD:
    default:
      label = '$/head'
  }
  return label
}

export function transformBidIncrement (cents, bidding) {
  let label
  switch (bidding) {
    case bidTypes.KG:
      label = `${cents}c`
      break
    case bidTypes.HEAD:
    default:
      if (cents % 100) {
        label = `$${(cents / 100).toFixed(2)}`
      } else {
        label = `$${cents / 100}`
      }
  }
  return label
}

export function getTimestamp (timestamp) {
  // if timestamp is not present, fallback to current date
  let date = timestamp ? moment(timestamp) : moment()
  return isNaN(date)
    ? moment().utc().format()
    : date.utc().format()
}

export function editAllRFNumber (kind, details, newCount = 0, index) {
  // edits all 'repeatForNumber' fields according to lot.count change
  // can take a while so make sure to show a spinner when you call this
  let elements = findAllFields(details, el => el.type === 'repeatForNumber', {
    ignoreRFNumber: true
  })
  elements.forEach((formElement, elementsIndex) => {
    let oldLength = formElement.values.length
    if (newCount > oldLength) {
      for (let i = 0; i < newCount - oldLength; i++) {
        if (formElement.values && formElement.values.length) {
          let firstElement = formElement.values[0].filter(field => !field.fromCsv)
          formElement.values.push(resetForm(firstElement))
        }
        if (
          kind.type === 'goat' &&
          formElement.publicId === 'individualAssessment' &&
          (!formElement.values || !formElement.values.length)
        ) {
          if (kind.form.forms && kind.form.forms.length) {
            const prefiledForm = prefillForm(
              findFieldByPublicId(kind.form.forms, 'assessmentGroup').forms[0],
              { mode: 'kind' }
            )

            if (prefiledForm && prefiledForm.length) {
              formElement.values = prefiledForm[0].values
            }
          }
        }
      }
    } else if (oldLength > newCount) {
      if (index >= 0) {
        formElement.values.splice(index, 1)
      } else {
        formElement.values.splice(
          Math.max(newCount, kind.type === 'goat' ? 0 : 1)
        )
      }
    }
    formElement.markedChanged = true
  })
  return details
}

export const getAssessmentInfo = memoize((assessmentGroup, headsCount,abbLambSale) => {
  let percentageDone = 0
  let avgWeight = 0
  let avgRating = 0
  const formElement = findFieldByPublicId(assessmentGroup, 'individualAssessment')
  if (formElement) {
    const { values } = formElement
    let weights = []
    let ratings = []
    let progress = 0
    for (let fieldset of values) {
      let weight = findFieldByPublicId(fieldset, 'weightLive')
      let rating = findFieldByPublicId(fieldset, 'fatScore')
      if (weight && rating && weight.value && rating.value ) {
        progress++
      } else if(weight &&  weight.value && abbLambSale ) {
        progress++
      }
      if (weight && weight.value) {
        weights.push(weight.value)
      }
      if (rating && rating.value) {
        ratings.push(rating.value)
      }
    }
    let newWeights = weights.map(item => Number(item))
    let newRatings = ratings.map(item => Number(item))
    percentageDone = Math.floor(progress / headsCount * 100)
    avgWeight = newWeights.reduce((sum, w) => sum + w, 0) / weights.length || 0
    avgWeight = parseFloat(avgWeight.toFixed(2))
    avgRating = newRatings.reduce((sum, r) => sum + r, 0) / ratings.length || 0
    avgRating = parseFloat(avgRating.toFixed(2))
  }
  return {
    percentageDone,
    avgWeight,
    avgRating
  }
})
