import { connect } from 'react-redux'
import Loading from './Loading'

const mapStateToProps = (state) => {
  return {
    spinner: state.temp.spinner
  }
}

const LoadingContainer = connect(
  mapStateToProps,
  null
)(Loading)

export default LoadingContainer
