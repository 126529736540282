import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'

import { onEnter } from '../../../utils/UpdateData'
import LotFormContainer from '../../LotForm/LotFormContainer'

class AddLot extends PureComponent {
  render () {
    return (
      <div>
        <div className='body-container'>
          <div className='inner-container'>
            {onEnter(
              <Switch>
                <Route path='/main/add/new/:kindTitle/:auctionNumber/:draftNumericId?'
                  component={LotFormContainer} />
                <Redirect to='/main/upcoming' />
              </Switch>
            )}
          </div>
        </div>
      </div>
    )
  }
}

AddLot.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

AddLot.defaultProps = {
}

export default AddLot
