import React from 'react'
import FdInput from '../../Auth/Fd-input/index'
import FgSelect from '../../FgSelect/FgSelect'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faMapMarkerAlt
} from '@fortawesome/fontawesome-free-solid'
import { isGuest } from '../../../utils/UpdateData'
const AddressDetails = (props) => {
  const {
    form,
    edit,
    same,
    handleSelect,
    handleCheckbox,
    handleAddressChange,
    mapSelected,
    mapStates
  } = props
  return (
    <div className='info-item'>
      <div className='cd-header'>
        <FontAwesomeIcon className='icons-style' icon={faMapMarkerAlt} />
        <div className='cd-title'>Address Details</div>
      </div>
      <div className='cd-data'>
        {isGuest() ?
          <div className='columns'>
            <div className='rows'>
              <p className='titles bold-font'>Office Address NOT PO BOX</p>
            </div>
            <div className='rows'>
              <p className='titles'>State:</p>
              <div className='independent-wrapper'>
                <div className={`typeDropdown-wrapper ${form.officeAddress.state.value && 'selected'}`}>
                  <FgSelect
                    // placeholder='Select'
                    options={mapStates}
                    value={mapSelected(form.officeAddress.state.value)}
                    isSearchable={false}
                    isDisabled={!edit}
                    onChange={handleSelect.bind(null, 'officeAddress')}
                  />
                </div>

              </div>
            </div>
            <div className='rows'>
              <p className='titles'>Postcode:</p>

              <FdInput
                className='right-inputs'
                type='text'
                // placeholder='Postcode'
                value={form.officeAddress.postcode.value}
                name='postcode'
                onChange={handleAddressChange.bind(null, 'officeAddress')}
                validData={form.officeAddress.postcode.valid}
                disabled={!edit}
              />
            </div>

          </div>
          :
          <div>
            <div className='columns'>
              <div className='rows'>
                <p className='titles bold-font'>Office Address NOT PO BOX</p>
              </div>
              <div className='rows'>
                <p className={`titles ${form.officeAddress.address1.valid}`}>Address:</p>
                <FdInput
                  className={`inputs ${!form.officeAddress.address1.valid ? 'red' : ''}`}
                  type='text'
                  // placeholder='Office Address'
                  value={form.officeAddress.address1.value}
                  name='address1'
                  onChange={handleAddressChange.bind(null, 'officeAddress')}
                  validData={form.officeAddress.address1.valid}
                  disabled={!edit}
                />
              </div>
              <div className='rows company-tagging-name-row'>
                <p className={`titles ${form.officeAddress.address2.valid}`}>Address (Line 2):</p>
                <FdInput
                  className={`inputs ${!form.officeAddress.address2.valid ? 'red' : ''}`}
                  type='text'
                  // placeholder='Office Address (Line 2)'
                  value={form.officeAddress.address2.value}
                  name='address2'
                  onChange={handleAddressChange.bind(null, 'officeAddress')}
                  validData={form.officeAddress.address2.valid}
                  disabled={!edit}
                />
              </div>
              <div className='rows'>
                <p className={`titles ${form.officeAddress.town.valid}`}>Town:</p>
                <FdInput
                  className={`inputs ${!form.officeAddress.town.valid ? 'red' : ''}`}
                  type='text'
                  // placeholder='Town'
                  value={form.officeAddress.town.value}
                  name='town'
                  onChange={handleAddressChange.bind(null, 'officeAddress')}
                  validData={form.officeAddress.town.valid}
                  disabled={!edit}
                />
              </div>
              <div className='rows'>
                <p className='titles'>State:</p>
                <div className='independent-wrapper'>
                  <div className={`dropdown-wrapper ${form.officeAddress.state.value && 'selected'}`}>
                    <FgSelect
                      // placeholder='Select'
                      options={mapStates}
                      value={mapSelected(form.officeAddress.state.value)}
                      isSearchable={false}
                      isDisabled={!edit}
                      onChange={handleSelect.bind(null, 'officeAddress')}
                    />
                  </div>
                  <div className='checkboxes-container'>
                    <p className={`checkboxes-text ${form.officeAddress.postcode.valid}`}>
                      Postcode:
                    </p>
                    <FdInput
                      className='right-inputs'
                      type='text'
                      // placeholder='Postcode'
                      value={form.officeAddress.postcode.value}
                      name='postcode'
                      onChange={handleAddressChange.bind(null, 'officeAddress')}
                      validData={form.officeAddress.postcode.valid}
                      disabled={!edit}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='columns'>
              <div className='rows'>
                <p className='titles bold-font'>Postal Address</p>
                {
                  edit &&
                  (
                    <div className='display-flex-row'>
                      <p className='same-as-office-address titles margin-top-6px'>Same as Office Address</p>
                      <label className='checkbox-container'>
                        <input
                          className='checkbox'
                          type='checkbox'
                          name='same'
                          checked={same}
                          onChange={handleCheckbox}
                          disabled={!edit}
                        />
                        <span className={edit ? 'checkmark pointer' : 'checkmark'} />
                      </label>
                    </div>
                  )
                }
              </div>
              <div className='rows'>
                <p
                  className={same ? `titles ${form.officeAddress.address1.valid}` : `titles ${form.postalAddress.address1.valid}`}>
                  Address:</p>

                <FdInput
                  className={`inputs ${!form.officeAddress.address1.valid ? 'red' : ''}`}
                  type='text'
                  // placeholder='Postal Address'
                  value={same ? form.officeAddress.address1.value : form.postalAddress.address1.value}
                  name='address1'
                  onChange={handleAddressChange.bind(null, 'postalAddress')}
                  validData={form.postalAddress.address1.valid}
                  disabled={!edit || same}
                />
              </div>
              <div className='rows'>
                <p
                  className={same ? `titles ${form.officeAddress.address2.valid}` : `titles ${form.postalAddress.address2.valid}`}>
                  Address (Line 2):</p>
                <FdInput
                  className={`inputs ${!form.officeAddress.address2.valid ? 'red' : ''}`}
                  type='text'
                  // placeholder='Postal Address (Line 2)'
                  value={same ? form.officeAddress.address2.value : form.postalAddress.address2.value}
                  name='address2'
                  onChange={handleAddressChange.bind(null, 'postalAddress')}
                  validData={form.postalAddress.address2.valid}
                  disabled={!edit || same}
                />
              </div>
              <div className='rows'>
                <p
                  className={same ? `titles ${form.officeAddress.town.valid}` : `titles ${form.postalAddress.town.valid}`}>
                  Town:</p>
                <FdInput
                  className={`inputs ${!form.officeAddress.town.valid ? 'red' : ''}`}
                  type='text'
                  // placeholder='Town'
                  value={same ? form.officeAddress.town.value : form.postalAddress.town.value}
                  name='town'
                  onChange={handleAddressChange.bind(null, 'postalAddress')}
                  validData={form.postalAddress.town.valid}
                  disabled={!edit || same}
                />
              </div>
              <div className='rows'>
                <p className='titles'>State:</p>
                <div className='independent-wrapper'>
                  <div
                    className={`dropdown-wrapper ${!same ? form.postalAddress.state.value && 'selected' : form.officeAddress.state.value && 'selected'}`}>
                    <FgSelect
                      // placeholder='Select'
                      options={mapStates}
                      value={mapSelected(form.postalAddress.state.value)}
                      isSearchable={false}
                      isDisabled={!edit || same}
                      onChange={handleSelect.bind(null, 'postalAddress')}
                    />
                  </div>
                  <div className='checkboxes-container'>
                    <p
                      className={same ? `checkboxes-text ${form.officeAddress.postcode.valid}` : `checkboxes-text ${form.postalAddress.postcode.valid}`}>
                      Postcode:</p>
                    <FdInput
                      className='right-inputs'
                      type='text'
                      // placeholder='Postcode'
                      value={same ? form.officeAddress.postcode.value : form.postalAddress.postcode.value}
                      name='postcode'
                      onChange={handleAddressChange.bind(null, 'postalAddress')}
                      validData={form.postalAddress.postcode.valid}
                      disabled={!edit || same}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

        }
      </div>
    </div>
  )
}

export default AddressDetails
