import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'

import FgSelect from '../../FgSelect/FgSelect'
import { transformBidIncrement } from '../../../utils/Form'
import { bidTypes } from '../../../constants'

class BidIncrement extends PureComponent {
  constructor (props) {
    super(props)
    this.getOptions = memoize(this.getOptions)
    this.mapSelected = memoize(this.mapSelected)
    this.onChange = this.onChange.bind(this)

    this.incrementOptions = {
      [bidTypes.HEAD]: {
        cattle: [500, 1000, 2000],
        sheep: [100, 200, 500],
        goat: [100, 200, 500],
        abblambsheep : [100]
      },
      [bidTypes.KG]: {
        cattle: [1, 2, 5],
        sheep: [1, 2, 5],
        goat: [1, 2, 5]
      }
    }
  }
  componentDidUpdate (prevProps, prevState) {
    if (prevProps.bidding !== this.props.bidding) {
      // clear value if Bid Type changed
      if (this.props.bidIncrementCents !== null) {
        this.props.onChange('bidIncrementCents', null)
      }
    }
  }
  getOptions (kindType, bidding) {
    const {
      auction
    } = this.props
    if (!bidding) {
      return []
    }
    let options
    if (auction.kindData.type === 'sheep-abblamb') {
      options = this.incrementOptions[bidding]['abblambsheep']
    } else {
      options = this.incrementOptions[bidding][kindType] || this.incrementOptions[bidding]['cattle']
    }
   
    return options.map(value => {
      return {
        value: value,
        label: transformBidIncrement(value, bidding)
      }
    })
  }
  mapSelected (value, bidding) {
    if (!value) {
      return null
    }
    return {
      value: value,
      label: transformBidIncrement(value, bidding)
    }
  }
  onChange (option, { action }) {
    if (action !== 'select-option') {
      return
    }
    this.props.onChange('bidIncrementCents', option.value)
  }
  render () {
    const {
      bidding,
      bidIncrementCents,
      kindType
    } = this.props
    return (
      <div className={`form-row ${this.props.disabled ? 'disabled' : ''}`}>
        <div className='row-title'>
          <p
            className='row-text'>Bid Increment {this.props && this.props.bidding !== 'kg' ? '$' : '¢'}<span className='asterisk'>*</span>:</p>
        </div>
        <div className='row-content'>
          <FgSelect
            placeholder='Select increment'
            options={this.getOptions(kindType, bidding)}
            value={this.mapSelected(bidIncrementCents, bidding)}
            isSearchable={false}
            onChange={this.onChange}
          />
        </div>
      </div>
    )
  }
}

BidIncrement.propTypes = {
  bidding: PropTypes.oneOf(Object.values(bidTypes)),
  kindType: PropTypes.string.isRequired,
  bidIncrementCents: PropTypes.number,
  onChange: PropTypes.func.isRequired
}

export default BidIncrement
