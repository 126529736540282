import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Checkbox extends Component {
  constructor (props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }
  handleClick () {
    if (this.props.fgDisabled) return
    this.props.onToggle(!this.props.value)
  }
  render () {
    return (
      <span
        className={`checkbox-with-value-container ${this.props.type} ${this.props.fgDisabled ? 'disabled-checkbox' : ''} ${this.props.value ? 'active' : ''}`}
        onClick={this.handleClick}>
        <span className='handle'>
          <span className='checkbox-text'>{this.props.label}</span>
          <span className='checked' />
        </span>
      </span>
    )
  }
}

Checkbox.defaultProps = {
  value: false,
  name: '',
  type: 'green'
}

Checkbox.propTypes = {
  value: PropTypes.bool,
  fgDisabled: PropTypes.bool,
  name: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.oneOf([
    'green',
    'gray'
  ])
}

export default Checkbox
