export const isIos = () => {
  if (/iPad|iPhone|iPod/.test(navigator.platform)) {
    return true
  } else {
    return navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform)
  }
}

export const isIpad = () => {
  return /iPad/.test(navigator.userAgent) && !window.MSStream
}

export const isSafari = () => {
  return /constructor/i.test(window.HTMLElement) ||
  (function (p) { return p.toString() === '[object SafariRemoteNotification]' })(!window['safari'] ||
  (typeof safari !== 'undefined' && safari.pushNotification)) //eslint-disable-line
}
