import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Toggle from 'react-toggle'

class EmailToVendor extends PureComponent {
  toggle = () => {
    this.props.onChange('emailToVendor', !this.props.emailToVendor)
  }

  render () {
    const { emailToVendor, disabled } = this.props
    return (
      <div className={`input-section ${disabled ? 'disabled' : ''}`}>
        <div className='section-heading'>
          <div className='heading-title'>Send Email to Vendor</div>
        </div>
        <div className='section-body'>
          <div className='form-row'>
            <div className='row-title row-title-long'>
              <p className='row-text'>Email Assessment to Vendor?</p>
            </div>
            <div className='row-content'>
              <div className='switcher'>
                <p className='row-text'>No</p>
                <Toggle
                  icons={false}
                  checked={emailToVendor}
                  onChange={this.toggle} />
                <p className='row-text'>Yes</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

EmailToVendor.propTypes = {
  emailToVendor: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

EmailToVendor.defaultProps = {
  disabled: false
}

export default EmailToVendor
