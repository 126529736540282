import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { NUMBER_REGEXP } from '../../../constants'

import FgPhone from '../../FgPhone/FgPhone'

class PhoneField extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      value: this.props.value || ''
    }
    this.onChange = this.onChange.bind(this)
  }
  onChange ({ target: { value } }) {
    this.setState({ value }, () => {
      if (NUMBER_REGEXP.test(value) || !value) {
        this.props.onChange(value || '')
      } else if (value === '') {
        this.props.onChange('')
      }
    })
  }
  componentDidUpdate () {
    if (this.props.value !== this.state.value) {
      this.setState({
        value: this.props.value
      })
    }
  }
  render () {
    const { invalid, isRequired, noTab, title, disabled } = this.props
    return (
      <div className={`form-row ${disabled ? 'disabled' : ''}`}>
        <div className='row-title'>
          <p
            className={`row-text ${invalid ? 'row-text-invalid' : ''}`}>
            {title}{isRequired ? <span className='asterisk'>*</span> : ''}:
          </p>
        </div>
        <div className='row-content'>
          <FgPhone
            className='input'
            value={this.state.value}
            onChange={this.onChange}
            {...noTab ? { tabIndex: -1 } : {}}
          />
        </div>
      </div>
    )
  }
}

PhoneField.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  invalid: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  noTab: PropTypes.bool.isRequired
}

PhoneField.defaultProps = {
  invalid: false,
  noTab: false
}

export default PhoneField
