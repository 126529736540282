import React from 'react'
import PropTypes from 'prop-types'

const FilterMultiItem = props => {
  const { option, checkHandler, uncheckHandler, isSelected,regionsData,selectedRegionOptions } = props

  let  renderOptions = (optionsArray) => {
    var filteredArray = optionsArray.filter(opt => {
      var bracketValue = opt.match(/\(([^)]+)\)/);
      return bracketValue && bracketValue[1].includes(option);
  });
    return (
      <ul> 
      {
        filteredArray.map(optionVal => {
          const isRegionSelected = selectedRegionOptions.includes(optionVal)
          return (
            <label key={optionVal}>
            <span>{optionVal}</span>
            <span className={`filter-checkbox ${isRegionSelected && 'active'}`} />
            <input type='radio' name='filter-day' 
            onClick={() => {
              if (isRegionSelected) {
                uncheckHandler(null,optionVal)
              } else {
                checkHandler(null,optionVal)
              }
            }} 
            />
          </label>
          )
        })
      }
    </ul>

    )
  }
  
  return (
    <>
    <label key={option}>
      <span>{option}</span>
      <span className={`filter-checkbox ${isSelected && 'active'}`} />
      <input type='radio' name='filter-day' onClick={() => {
        if (isSelected) {
          uncheckHandler(option)
        } else {
          checkHandler(option)
        }
      }} />
    </label>
    {isSelected && props.name &&  props.name ==='location' &&
                <div className='filter-multi-options regionoptions'>
                {regionsData.length !== 0 ? renderOptions(regionsData) : 'No breed Found'}
              </div>
    
    }
    </>

  )
}

FilterMultiItem.propTypes = {
  option: PropTypes.string.isRequired,
  checkHandler: PropTypes.func.isRequired,
  uncheckHandler: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired
}

export default FilterMultiItem
