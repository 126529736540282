import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class StockDescription extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
    this.onChange = this.onChange.bind(this)
    this.input = React.createRef()
  }
  onChange () {
    this.props.onChange('description', this.input.current.value)
  }
  render () {
    const { value, isOptiweigh } = this.props
    return (
      <div className={`form-row ${this.props.disabled ? 'disabled' : ''}`}>
        <div className='row-title'>
          <p
            className='row-text'>My Stock Description (not public) {!isOptiweigh ? <span className='asterisk'>*</span> : null}:</p>
        </div>
        <div className='row-content'>
          <input
            className='input'
            placeholder='Description'
            type='text'
            ref={this.input}
            onChange={this.onChange}
            value={value}
          />
        </div>
      </div>
    )
  }
}

StockDescription.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

StockDescription.defaultProps = {
  disabled: false
}

export default StockDescription
