import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import memoize from 'memoize-one'

class ValidatedInput extends PureComponent {
  constructor (props) {
    super(props)
    this.getClassNames = memoize(this.getClassNames)
    this.getContainerClassNames = memoize(this.getContainerClassNames)
    this.state = {
      showPassword: false
    }
    this.togglePassword = this.togglePassword.bind(this)
  }
  handleWarning (warning) {
    if (!this.props.validData && this.props.warningReason) {
      return (
        <div className='warning-text'>* {warning}</div>
      )
    }
  }
  getContainerClassNames (customClass) {
    let classNames = ['input-container']
    if (customClass) classNames.push(customClass)
    return classNames.join(' ')
  }
  getClassNames (validData, customClass) {
    let classNames = ['input']
    if (!validData) classNames.push('warning-input')
    if (customClass) classNames.push(customClass)
    return classNames.join(' ')
  }
  togglePassword () {
    this.setState({
      showPassword: !this.state.showPassword
    })
  }
  render () {
    let containerClass = this.getContainerClassNames(this.props.containerClassName)
    let classNames = this.getClassNames(this.props.validData, this.props.className)
    let { showPassword } = this.state
    return (
      <div className={containerClass}>
        <label>{this.props.label}
          <input
            autoComplete={this.props.autoComplete}
            className={classNames}
            disabled={this.props.disabled ? this.props.disabled : false}
            value={this.props.value}
            type={this.props.type === 'password' ? !showPassword ? 'password' : 'text' : this.props.type}
            min={this.props.min}
            name={this.props.name}
            placeholder={this.props.placeholder}
            onChange={this.props.onChange}
            onBlur={this.props.onBlur}
          />
          {this.props.withShowButton &&
            <span className='show-password-button' onClick={this.togglePassword}>
              {this.state.showPassword ? 'Hide' : 'Show'}
            </span>
          }
        </label>
        {this.handleWarning(this.props.warningReason)}
      </div>
    )
  }
}

ValidatedInput.propTypes = {
  type: PropTypes.string,
  min: PropTypes.number,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  warningReason: PropTypes.string,
  validData: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  containerClassName: PropTypes.string
}

ValidatedInput.defaultProps = {
  type: 'text',
  min: 0,
  placeholder: '',
  value: '',
  name: '',
  warningReason: '',
  validData: true,
  onChange: Function.prototype
}

export default ValidatedInput
