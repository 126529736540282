import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { upcomingAuctions } from '../../../selectors/auctionsSelector'
import AuctionsList from './AuctionsList'
import { changeStateProp, showModal, mergeStateProp,showSpinner } from '../../../actions/index'

const mapStateToProps = (state) => {
  return {
    auctions: upcomingAuctions({
      auctions: state.data.auctions
    }),
    locationStates: state.data.locationStates,
    userStatus: state.user.data.status,
    user : state.user.data,
    filters: state.user.filters,
    isServerLive: state.temp.isServerLive,
    auctiontags: state.data.auctiontags
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeStateProp, showModal,showSpinner, mergeStateProp }, dispatch)
}

const AuctionsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuctionsList)

export default AuctionsListContainer
