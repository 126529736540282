import React from 'react'
import ReactDOM from 'react-dom'
import { persistStore } from 'redux-persist'

import store from './services/store'
import App from './components/AppContainer'
import * as Sentry from '@sentry/browser'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: 'https://2e3611e77ffa414a9f14559e9c50a59a@sentry.io/1496002' })
  const script = document.createElement('script')
  script.innerHTML = '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\':\n' +
    'new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0],\n' +
    'j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=\n' +
    '\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);\n' +
    '})(window,document,\'script\',\'dataLayer\',\'GTM-KMHW7LM\');'
  document.body.appendChild(script)
}
persistStore(store, null, () => {
  ReactDOM.render(
    (
      <App store={store} />
    ),
    document.getElementById('root')
  )
})
