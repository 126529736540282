/* global FileReader */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import update from 'immutability-helper'
import { bindActionCreators } from 'redux'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/fontawesome-free-solid'

import { limitCharacters } from '../../../../utils'
import { compressImage } from '../../../../utils/Lots'
import Api from '../../../../services/api'
import { showSpinner } from '../../../../actions'
import './UsefulLinks.scss'
import uuid from 'uuid'

class UsefulLinks extends PureComponent {
  constructor (props) {
    super(props)
    this.state = { urlError: '' }
    this.MAX_DOCUMENT_LIMIT = 6
  }

  componentDidMount () {
    if (!this.props.usefulLinks.length) {
      this._initialGenerateSingleRow()
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (!this.props.usefulLinks.length) {
      this._initialGenerateSingleRow()
    }
  }

  getNextOrder () {
    let { usefulLinks } = this.props
    let maxOrder = Math.max(...usefulLinks.map(doc => doc.order))
    return maxOrder >= 0 ? maxOrder + 1 : 0
  }

  isValidHttpUrl = (string) => {
    const urlExpression = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
    const urlRegex = new RegExp(urlExpression)
    if (string.match(urlRegex)) {
      let url = string
      if (!/^https?:\/\//i.test(url)) {
        url = 'https://' + url
      }
      return url
    }
    return string.match(urlRegex)
  };

  addDocument = (event, document) => {
    let file = event.target.files[0]
    if (!file) {
      return
    }
    let { usefulLinks } = this.props
    let newDocument = {}
    event.target.value = null

    let fileReader = new FileReader()
    fileReader.onloadend = () => {
      newDocument.file = file
      newDocument.mimeType = file.type
      newDocument.uri = fileReader.result
      newDocument.name = file.name
      if (!file.type) {
        let arrayName = file.name.split('.')
        let ext = arrayName[arrayName.length - 1]
        switch (ext) {
          case 'xls':
            newDocument.mimeType = 'application/vnd.ms-excel'
            break
          case 'doc':
            newDocument.mimeType = 'application/msword'
            break
          case 'docx':
            newDocument.mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            break
          case 'xlsx':
            newDocument.mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            break
          default:
            newDocument.mimeType = ''
            break
        }
      }
      if (/image/.test(file.type)) {
        let options = {
          convertSize: 1000,
          quality: 0.7,
          mimeType: 'image/jpeg'
        }
        compressImage(newDocument, options)
          .then(newDocument => {
            this.props.saveUsefulLinks(update(usefulLinks, {
              [usefulLinks.indexOf(document)]: { $merge: newDocument }
            }))
          })
          .catch(err => {
            console.error(err)
          })
      } else {
        this.props.saveUsefulLinks(update(usefulLinks, {
          [usefulLinks.indexOf(document)]: { $merge: newDocument }
        }))
      }
    }
    fileReader.readAsDataURL(file)
  }

  generateSingleRow (isPdf) {
    let { usefulLinks } = this.props
    this.props.saveUsefulLinks(
      update(usefulLinks || [], {
        $push: [
          {
            url: '',
            description: '',
            order: this.getNextOrder([]),
            isNew: true,
            isPdf,
            key: uuid()
          }
        ]
      })
    )
  }

  _initialGenerateSingleRow = () => {
    let { usefulLinks } = this.props
    if (!usefulLinks || usefulLinks.length < this.MAX_DOCUMENT_LIMIT) {
      this.props.saveUsefulLinks(
        update(usefulLinks || [], {
          $push: [
            {
              url: '',
              description: '',
              order: this.getNextOrder([]),
              isNew: true,
              isPdf: true,
              key: uuid()
            },
            {
              url: '',
              description: '',
              order: this.getNextOrder([]),
              isNew: true,
              isPdf: false,
              key: uuid()
            }
          ]
        })
      )
    }
  };

  removeSingleRow = (link, localList) => {
    let { usefulLinks } = this.props
    if (localList.length > 1) {
      const index = usefulLinks.findIndex((l) => l.key === link.key)
      this.props.saveUsefulLinks(
        update(usefulLinks, {
          $splice: [[index, 1]]
        })
      )
    } else {
      const index = usefulLinks.findIndex((l) => l.key === link.key)
      const copyUsefulLinks = Object.assign([], usefulLinks)

      copyUsefulLinks[index] = {
        url: '',
        description: '',
        order: this.getNextOrder(usefulLinks),
        isNew: true,
        isPdf: link.isPdf,
        key: uuid()
      }

      this.props.saveUsefulLinks(
        update(usefulLinks, {
          $set: copyUsefulLinks
        })
      )
    }
  }

  async removeDocumentFromServer (document) {
    if (document.key && this.props.lotId) {
      this.props.showSpinner(true)
      let res = await Api.deleteLotDocument(this.props.lotId, document.key)
      if (res) {
        this.props.saveDocuments(this.props.documents.filter(doc => doc.key !== document.key))
      }
      this.props.showSpinner(false)
    }
  }

  async changeDesc ({ target: { value } }, document) {
    const { usefulLinks, saveUsefulLinks } = this.props
    if (document.isNew) {
      // local
      value = value.trim()
      saveUsefulLinks(update(usefulLinks, {
        [usefulLinks.findIndex((l) => l.key === document.key)]: { description: { $set: value } }
      }))
    }
  }

  async changeURl ({ target: { value } }, link) {
    const { usefulLinks, saveUsefulLinks } = this.props
    // local
    value = value.trim()
    const url = this.isValidHttpUrl(value)
    if (value && !url) {
      this.setState({ urlError: 'URL should be valid' })
    } else {
      this.setState({ urlError: '' })
    }
    saveUsefulLinks(update(usefulLinks, {
      [usefulLinks.indexOf(link)]: { url: { $set: url } }
    }))
  }

  render () {
    const { usefulLinks, disabled } = this.props
    const pdfList = usefulLinks.filter((l) => l.isPdf)
    const linkList = usefulLinks.filter((l) => !l.isPdf)

    return (
      <div className={`input-section input-section-documents useful-links-section ${disabled ? 'disabled' : ''}`}>
        <div className='section-heading'>
          <div className='heading-title'>Add Useful Links</div>
        </div>
        <div className='section-body'>
          {pdfList && pdfList.length && pdfList.map((link, index) => {
            return (
              <div className='form-row' key={link.key}>
                <div className='row-title'>
                  <p
                    className='row-text'>Select PDF File:</p>
                </div>
                <div className='row-content'>
                <label className={`file-label type-add-pdf documents-text-wrapper`}>
                    {
                      link.name
                        ? limitCharacters(link.name)
                        : 'Add New PDF File'
                    }
                    {
                      link.isNew
                        ? (
                          <input
                            disabled={link.url}
                            id='file'
                            className='file-input'
                            type='file'
                            onChange={(event) => this.addDocument(event, link)}
                            accept='.pdf'
                          />
                        )
                        : null
                    }
                  </label>
                  <input
                    key={`add-pfd-description-${link.key}`}
                    className='input documents-description documents-text-wrapper'
                    placeholder='Description'
                    type='text'
                    defaultValue={link.description || ''}
                    onBlur={(e) => this.changeDesc(e, link)}
                  />
                <span className='doc-btn doc-btn-add' onClick={() => this.generateSingleRow(true)}>
                <FontAwesomeIcon icon={faPlus} />
                </span>
                  <span className='doc-btn doc-btn-remove' onClick={() => this.removeSingleRow(link, pdfList)}>
                    <FontAwesomeIcon icon={faMinus} />
                  </span>
                </div>
              </div>
            )
          })}
          {/* <div className='form-row'>
            <div className='row-title' />
            <span className='doc-btn doc-btn-add' onClick={() => this.generateSingleRow(true)}>
              <FontAwesomeIcon icon={faPlus} />
            </span>
          </div> */}
          {linkList.map((link, index) => {
            return (
              <div className='form-row' key={link.key}>
                <div className='row-title'>
                  <p
                    className='row-text'>Add URL:</p>
                </div>
                <div className='row-content'>
                 <input
                    key={`url-field-${link.key}`}
                    className={`input type-url documents-text-wrapper `}
                    placeholder='Add URL'
                    type='text'
                    defaultValue={link.url || ''}
                    onChange={() => { this.setState({ urlError: '' }) }}
                    onBlur={(e) => this.changeURl(e, link)}
                  />
                  <input
                    key={`url-description-${link.key}`}
                    className='input documents-description documents-text-wrapper'
                    placeholder='Description'
                    type='text'
                    defaultValue={link.description || ''}
                    onBlur={(e) => this.changeDesc(e, link)}
                  />
                  <span className='doc-btn doc-btn-add' onClick={() => this.generateSingleRow()}>
                   <FontAwesomeIcon icon={faPlus} />
                  </span>
                  <span className='doc-btn doc-btn-remove' onClick={() => this.removeSingleRow(link, linkList)}>
                    <FontAwesomeIcon icon={faMinus} />
                  </span>
                </div>
              </div>
            )
          })}
          {/* <div className='form-row'>
            <div className='row-title' />
            <span className='doc-btn doc-btn-add' onClick={() => this.generateSingleRow()}>
              <FontAwesomeIcon icon={faPlus} />
            </span>
          </div> */}
          {this.state.urlError ? <p className={'url-error'}>{this.state.urlError}</p> : null}
        </div>
      </div>
    )
  }
}

UsefulLinks.propTypes = {
  saveUsefulLinks: PropTypes.func.isRequired,
  usefulLinks: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  lotId: PropTypes.string
}

UsefulLinks.defaultProps = {
  disabled: false
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ showSpinner }, dispatch)
}

export default connect(
  null,
  mapDispatchToProps
)(UsefulLinks)
