/* global Blob, FileReader, URL */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import moment from 'moment'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faCheck,
  faPlusSquare
} from '@fortawesome/fontawesome-free-solid'
import {
  faSave
} from '@fortawesome/free-regular-svg-icons'
import { pick as _pick, has as _has } from 'lodash'

import { momentTz } from '../../utils'
import { adminOnly } from '../../utils/UpdateData'
import * as FormUtils from '../../utils/Form'
import { findFieldByPublicId, findAllInvalidFields, getPathByKeyAndValue } from '../../utils/FindFieldLot'
import Api from '../../services/api'
import logOut from '../../utils/logOut'
import { isAvailableAuction } from '../../utils/Auctions'
import {
  AuctionSelect,
  Bidding,
  // BiddingSwap,
  BidIncrement,
  DocPicker,
  EmailToVendor,
  FormRow,
  HeadsInput,
  WeighedHeadsInput,
  MediaPicker,
  MoneyInput,
  SaleConditions,
  SignedForm,
  StockDescription,
  TermsConditions
 // VendorBids
} from './parts'
import { bidTypes, MIN_WEIGHED_HEADS_PERCENTAGE } from '../../constants'
import { NumberField } from '../FormField/types'
import UsefulLinks from './parts/UsefulLinks/UsefulLinks'

class LotForm extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      auction: null,
      media: [],
      documents: [],
      usefulLinks: [],
      dirty: true,
      loaded: false,
      draftLot: props.kind.type === 'goat' ? { ...LotForm.DRAFT_LOT, countWeighed: 0 } : LotForm.DRAFT_LOT,
      showSaveNotification: false,
      forceNextAutosave: false,
      fillingStarted: false
    }
    this.silentSaveTimeout = null
    this.formChanged = false
    this.hasSubmitted = false
    this.isSilentSaving = false
    this.isUnmounted = false
    this.isEdit = Boolean(this.props.match.params.draftNumericId)
    this.saveMedia = this.saveMedia.bind(this)
    this.setSaveTimeout = this.setSaveTimeout.bind(this)
    this.changeAuction = this.changeAuction.bind(this)
    this.changeCount = this.changeCount.bind(this)
    this.changeMobEntryWeight = this.changeMobEntryWeight.bind(this)
    this.updateDraftLot = this.updateDraftLot.bind(this)
    this.updateDraftLotDetails = this.updateDraftLotDetails.bind(this)
    this.changeData = this.changeData.bind(this)
    this.markFormChanged = this.markFormChanged.bind(this)
    this.submit = this.submit.bind(this)
    this.saveDraftLot = this.saveDraftLot.bind(this)
    this.showModal = this.showModal.bind(this)
    this.adminExportForm = this.adminExportForm.bind(this)
    this.adminImportForm = this.adminImportForm.bind(this)

    this.mode = 'default'
    this.mainUrl = '/main/add/new'
    this.adminExportLink = React.createRef()
  }

  async componentDidMount () {
    if (this.props.history.action !== 'POP') {
      window.scroll(0, 0)
    }
    this.props.changeStateProp('onLotForm', true, 'temp')
    this.getLot()
    window.onbeforeunload = function () {
      return false
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.saveAndLogout !== this.props.saveAndLogout && this.props.saveAndLogout === true) {
      let validationResult = this.validateDraftLot(this.state.draftLot, { draft: true, silent: true })
      if (validationResult.valid) {
        this.submitLot(this.state.draftLot, { draft: true }).then(() => logOut.init())
      } else {
        logOut.init()
      }
      return
    }
    if (prevState.loaded) {
      Object.keys(this.state.draftLot).forEach(key => {
        if (key === 'details' || key === 'draft') {
          return
        }
        if (prevState.draftLot[key] !== this.state.draftLot[key]) {
          this.markFormChanged({ formChanged: true })
        }
      })
      if (prevState.auction._id !== this.state.auction._id) {
        this.markFormChanged({ formChanged: true })
      }
    }

    if (prevState.dirty &&
      ((prevProps.match.params.kindTitle !== this.props.match.params.kindTitle) ||
        (prevProps.match.params.auctionNumber !== this.props.match.params.auctionNumber) ||
        (prevProps.match.params.draftNumericId !== this.props.match.params.draftNumericId))) {
      this.reloadPage()
    }
  }

  componentWillUnmount () {
    this.isUnmounted = true
    this.props.changeStateProp('onLotForm', false, 'temp')
    clearTimeout(this.silentSaveTimeout)
    this.pageUnblock()
    window.onbeforeunload = null
  }

  async getPics () {
    let res = await Api.getPics()
    if (this.isUnmounted) {
      return false
    }
    if (res) {
      this.props.changeStateProp('pics', res.pics, 'user')
    }
    return true
  }

  async getAuction () {
    let res = await Api.getAuction(this.props.match.params.auctionNumber)
    if (this.isUnmounted) {
      return false
    }
    if (res) {
      await new Promise((resolve) => {
        this.setState({
          auction: res.auction
        }, resolve)
      })
      return true
    } else {
      return false
    }
  }

  async getLot () {
    this.props.showSpinner(true)
    // load pics before lot
    // load auction before lot
    let res = await Promise.all([
      await this.getPics(),
      await this.getAuction()
    ])
    this.props.showSpinner(false)
    if (res.some(status => status === false)) {
      // do not continue if any of the above requests returned `false`
      this.props.history.replace('/main/manage')
      return
    }
    if (this.props.match.params.draftNumericId) {
      this.props.showSpinner(true)
      let data = await Api.getLot(this.props.match.params.draftNumericId, { mode: 'full' })
      if (data) {
        if (this.isUnmounted) {
          this.props.showSpinner(false)
          return
        }
        this.markFormChanged({ formChanged: false })
        this.populateLot(data.lot)
        this.setState({
          media: data.lot.media,
          documents: data.lot.documents,
          usefulLinks: data.lot.usefulLinks,
          loaded: true
        }, () => {
          this.props.showSpinner(false)
          this.pageBlock()
        })
      } else {
        this.props.showSpinner(false)
        this.props.history.replace('/main/manage')
      }
    } else {
      this.markFormChanged({ formChanged: false })
      this.generateLot()
      this.setState({
        media: [],
        documents: [],
        usefulLinks: [],
        loaded: true
      }, () => this.pageBlock())
    }
  }

  pageBlock () {
    this.unblock = this.props.history.block((nextLocation) => {
      if (this.state.dirty) {
        this.setState({
          nextLocation: nextLocation
        }, () => {
          this.showModal()
        })
      }
      return !this.state.dirty
    })
  }

  reloadPage () {
    window.onbeforeunload = null
    window.location.reload()
  }

  deleteCurrentLotFromRemeberedLotsWithIndex (lotId) {
    if (this.props.rememberedLotsWithIndex) {
      let newRememberedLotsWithIndex = this.props.rememberedLotsWithIndex.lots.filter((lot) => lot.id !== lotId)
      this.props.changeStateProp('rememberedLotsWithIndex', { lots: newRememberedLotsWithIndex }, 'main')
    }
  }

  showModal () {
    this.props.showModal({
      message: 'Do you want to Save the Changes made to your Lot Assessment?',
      buttons: [
        {
          text: 'Save',
          onPress: () => {
            this.saveDraftLot({ customLeave: true })
          }
        },
        {
          text: 'Cancel'
        },
        {
          text: 'Delete',
          onPress: () => {
            if (!this.state.fillingStarted) {
              this.removeDraft()
            } else {
              this.props.showModal({
                message: 'Please confirm you wish to delete this form and all its information',
                buttons: [
                  {
                    text: 'Delete',
                    onPress: () => {
                      this.removeDraft()
                    }
                  },
                  {
                    text: 'Cancel'
                  }
                ]
              })
            }
          }
        }
      ]
    })
  }

  pageUnblock () {
    this.unblock && this.unblock()
  }

  updateDraftLot (draftLot, { formChanged, cb } = {}, field) {
    this.setState({ draftLot }, () => {
      if (formChanged !== undefined) {
        this.markFormChanged({ formChanged }, field)
      }
      if (cb) {
        this.markFormChanged({ formChanged }, field)
        cb()
      }
    })
  }

   // While importing old lots , Modifying the assesment forms based on public id
  updateDraftLotDetails(draftLot) {
    let assessorDetailsField = () => (
      {
        'title': 'Assessor Details',
        'type': 'group',
        'publicId': 'agentGroup',
        'values': [
          {
            'type': 'text',
            'title': 'Assessor Name',
            'isRequired': true,
            'isPublic': true,
            'isMarked': true,
            'publicId': "agentDetailsName",
            'value': null
          },
          {
            'options': [
              "Agent Assessed",
              "Vendor Assessed"
            ],
            'isPublic': true,
            'isRequired': true,
            'title': 'Assessor Type',
            'type': 'dropdown',
            'publicId': 'assessorType',
            'value': null
          },
          {
            'type': 'textarea',
            "title": 'Assessor Comments',
            'isRequired': false,
            'isPublic': true,
            'isMarked': true,
            'publicId': 'agentComment',
            'value': null
          }
        ]
      }
    )
    let getNewFields = () => (

      {
        'options': [
          'Agent Assessed',
          'Vendor Assessed',
        ],
        'isPublic': true,
        'isRequired': true,
        'title': 'Assessor Type',
        'type': 'dropdown',
        'publicId': 'assessorType',
        'value': null
      }
    )

    const deliveryGroupIndex = draftLot.details.values.findIndex(item => item.title === 'Delivery')
    if (deliveryGroupIndex !== -1) {
      const earliestDeliveryIndex = draftLot.details.values[deliveryGroupIndex].values.findIndex(x => x.publicId === 'earliestDelivery')
      const latestDeliveryIndex = draftLot.details.values[deliveryGroupIndex].values.findIndex(x => x.publicId === 'latestDelivery')
      const agentNameIndex = draftLot.details.values[deliveryGroupIndex].values.findIndex(x => x.publicId === 'agentName')
      const agentPhoneIndex = draftLot.details.values[deliveryGroupIndex].values.findIndex(x => x.publicId === 'agentPhone')
      const agentEmailIndex = draftLot.details.values[deliveryGroupIndex].values.findIndex(x => x.publicId === 'agentEmail')
      if (earliestDeliveryIndex !== -1) {
        draftLot.details.values[deliveryGroupIndex].values[earliestDeliveryIndex].value = null
      }
      if (latestDeliveryIndex !== -1) {
        draftLot.details.values[deliveryGroupIndex].values[latestDeliveryIndex].value = null
      }
      if (agentNameIndex !== -1) {
        draftLot.details.values[deliveryGroupIndex].values[agentNameIndex].title = "Assessor Name"
      }
      if (agentPhoneIndex !== -1) {
        draftLot.details.values[deliveryGroupIndex].values[agentPhoneIndex].title = "Assessor Phone"
      }
      if (agentEmailIndex !== -1) {
        draftLot.details.values[deliveryGroupIndex].values[agentEmailIndex].title = "Assessor Email"
      }
    }


    const agentGroupIndex = draftLot.details.values.findIndex(item => item.publicId === 'agentGroup')
    const assessorGroupIndex = draftLot.details.values.findIndex(item => item.title === 'Assessor Details')
    if (agentGroupIndex !== -1) {
      draftLot.details.values[agentGroupIndex].title = 'Assessor Details'
      const agentDetailsNameIndex = draftLot.details.values[agentGroupIndex].values.findIndex(x => x.publicId === 'agentDetailsName')
      const agentCommentIndex = draftLot.details.values[agentGroupIndex].values.findIndex(x => x.publicId === 'agentComment')
      const agencyNameIndex = draftLot.details.values[agentGroupIndex].values.findIndex(x => (x.publicId === 'agencyName' || x.publicId === 'agencyname'))
      if (agentDetailsNameIndex !== -1) {
        draftLot.details.values[agentGroupIndex].values[agentDetailsNameIndex].title = 'Assessor Name'
        draftLot.details.values[agentGroupIndex].values[agentDetailsNameIndex].value = null
      }
      if (agentCommentIndex !== -1) {
        draftLot.details.values[agentGroupIndex].values[agentCommentIndex].title = 'Assessor Comments'
        draftLot.details.values[agentGroupIndex].values[agentCommentIndex].value = null
      }
      if (agencyNameIndex !== -1) {
        draftLot.details.values[agentGroupIndex].values[agencyNameIndex] = getNewFields();
      } else {
        const assessorTypeIndex =  draftLot.details.values[agentGroupIndex].values.findIndex(x => x.publicId === 'assessorType')
        assessorTypeIndex === -1 &&  draftLot.details.values[agentGroupIndex].values.splice(1, 0, getNewFields())
      }
    } else if (draftLot.kindData.type === "cattle" && assessorGroupIndex === -1) {
      const deliveryIndex = draftLot.details.values.findIndex(item => item.title === 'Delivery');
      draftLot.details.values.splice(deliveryIndex, 0, assessorDetailsField());
    }
    const VendorDetailsGroup = draftLot.details.values.findIndex(item => item.title === 'Vendor Assessor Details')
    if (VendorDetailsGroup !== -1) {
      draftLot.details.values[VendorDetailsGroup].title = 'Vendor Details'
      const vendorNameIndex = draftLot.details.values[VendorDetailsGroup].values.findIndex(x => x.publicId === 'vendorName')
      if (vendorNameIndex !== -1) {
        draftLot.details.values[VendorDetailsGroup].values[vendorNameIndex].title = 'Vendor Name'

      }
    }


    const conditionFrameIndex = draftLot.details.values.findIndex(item => item.title === 'Condition and Frame')
    const conditionFrameIndexGoat = draftLot.details.values.findIndex(item => item.title === 'Condition')
    if (conditionFrameIndex !== -1) {
      draftLot.details.values[conditionFrameIndex].title = 'Frame'
      const conditionIndex = draftLot.details.values[conditionFrameIndex].values.findIndex(x => x.title === 'Condition')
      if (conditionIndex !== -1) {
        draftLot.details.values[conditionFrameIndex].values.splice(conditionIndex, 1)
      }
    }
    if (conditionFrameIndexGoat !== -1) {
      draftLot.details.values[conditionFrameIndexGoat].title = 'Frame'
      const conditionIndex = draftLot.details.values[conditionFrameIndexGoat].values.findIndex(x => x.title === 'Condition')
      if (conditionIndex !== -1) {
        draftLot.details.values[conditionFrameIndexGoat].values.splice(conditionIndex, 1)
      }
    }

    const joiningDetailsIndex = draftLot.details.values.findIndex(item => item.title === 'Joining Details')
    if (joiningDetailsIndex !== -1) {
      const stationMatedIndex = draftLot.details.values[joiningDetailsIndex].values.findIndex(x => x.title === 'Station Mated?')
      if (stationMatedIndex !== -1) {
        draftLot.details.values[joiningDetailsIndex].values.splice(stationMatedIndex, 1)
      }
      const continuouslyMatedIndex = draftLot.details.values[joiningDetailsIndex].values.findIndex(x => x.title === 'Continuously Joined?')
      if (continuouslyMatedIndex !== -1) {
        draftLot.details.values[joiningDetailsIndex].values[continuouslyMatedIndex].title = 'Continuously Mated'
      }
      const setDateIndex = draftLot.details.values[joiningDetailsIndex].values.findIndex(x => x.title === 'Joined at Set Dates?')
      if (setDateIndex !== -1) {
        draftLot.details.values[joiningDetailsIndex].values[setDateIndex].title = 'Joined?'
      }

      draftLot.details.values[joiningDetailsIndex].values.sort((a, b) => {
        const titles = ["Joined?", "A.I", "Continuously Mated", "Access to Bulls/Stags?", "Lot been Preg Tested?"];
        return titles.indexOf(a.title) - titles.indexOf(b.title);
      });
    }

    const breedingQualityIndex = draftLot.details.values.findIndex(item => item.title === 'Breeding Quality')
    if (breedingQualityIndex !== -1) {
      draftLot.details.values.splice(breedingQualityIndex, 1)
    }

    // Sorting Joining Details fields 
    if(joiningDetailsIndex !== -1){
      if (draftLot.kindData.type === "cattle") {
        draftLot.details.values[joiningDetailsIndex].values.sort((a, b) => {
          const titles = ["Joined?", "A.I", "Continuously Mated", "Access to Bulls/Stags?", "Lot been Preg Tested?"];
          return titles.indexOf(a.title) - titles.indexOf(b.title);
        });
      }
      if (draftLot.kindData.type === "sheep") {
        draftLot.details.values[joiningDetailsIndex].values.sort((a, b) => {
          const titles = ["Joined?", "A.I?", "Continuously Mated", "Access to Rams/Bucks/Stags?", "Scanned?", "Additional Joining Details"];
          return titles.indexOf(a.title) - titles.indexOf(b.title);
        });
      }
  
      if (draftLot.kindData.type === "goat") {
        draftLot.details.values[joiningDetailsIndex].values.sort((a, b) => {
          const titles = ["Joined?", "A.I?", "Continuously Mated", "Scanned?", "Additional Joining Details"];
          return titles.indexOf(a.title) - titles.indexOf(b.title);
        });
      }
    }


  }
  
  setFillingStarted () {
    if (!this.state.fillingStarted) {
      this.setState({
        fillingStarted: true
      })
    }
  }

  changeData (prop, value) {
    this.updateDraftLot(update(this.state.draftLot, {
      [prop]: {
        $set: value
      }
    }))
  }

  markFormChanged ({ formChanged = true } = {}, field) {
    this.formChanged = formChanged
    if (formChanged) {
      this.setSaveTimeout(field)
      this.setFillingStarted()
    }
  }

  setSaveTimeout (field, delay = 2000) {
    if (this.silentSaveTimeout) {
      clearTimeout(this.silentSaveTimeout)
    }
    if (this.isUnmounted || this.isSilentSaving || this.hasSubmitted || !this.formChanged) {
      return
    }
    this.silentSaveTimeout = setTimeout(() => {
      if (this.isUnmounted || this.isSilentSaving || this.hasSubmitted || !this.formChanged) {
        return
      }
      this.isSilentSaving = true
      if (this.getCurrentLotId()) {
        let validationResult = this.validateDraftLot(this.state.draftLot, { draft: true, silent: true })
        if (validationResult.valid) {
          this.silentSubmitLot(this.state.draftLot, field)
        } else {
          this.isSilentSaving = false
        }
      } else {
        let validationResult = this.validateDraftLot(this.state.draftLot, { draft: true, silent: true })
        if (validationResult.valid) {
          this.silentSubmitLot(this.state.draftLot, field)
        } else {
          this.isSilentSaving = false
        }
      }
    }, delay)
  }

  // returns all changes made in lot.details, then resets them in state
  extractDetailsChanges (field, { extractEverything = false } = {}) {
    let detailsChanges = []
    let changesInsideAccordion = 0
    let search = (path, obj, { insideAccordion = false } = {}) => {
      if (!(obj.type === 'group') && (obj.markedChanged || extractEverything)) {
        if (obj.type === 'repeatForNumber') {
          detailsChanges.push({
            action: 'repeatForNumber-update',
            path: path,
            currentLength: obj.values.length
          })
        } else if (!insideAccordion) {
          if (field !== 'count' && field !== 'countWeighed') {
            detailsChanges.push({
              action: 'field-update',
              path: path,
              refObject: FormUtils.cleanField(obj, { draft: true })
            })
          }
        } else {
          changesInsideAccordion++
        }
      }
      if (obj.values) {
        if (field === 'count' && window.location.href.indexOf('Sheep') !== -1) {
          return detailsChanges
        }
        obj.values.forEach((val, i) => {
          if (obj.type === 'repeatForNumber') {
            val.forEach((subval, j) => {
              search(path.concat('values', i, j), subval)
            })
          } else if (obj.type === 'repeatForBool') {
            // when fields change inside Accordion (repeatForBool)
            // send entire Accordion instead of only changed field
            let oldChangesInsideAccordion = changesInsideAccordion
            val.forEach((subval, j) => {
              search(path.concat('values', i, j), subval, { insideAccordion: true })
            })
            if (!obj.markedChanged && oldChangesInsideAccordion !== changesInsideAccordion) {
              if (field !== 'count' && field !== 'countWeighed') {
                detailsChanges.push({
                  action: 'field-update',
                  path: path,
                  refObject: FormUtils.cleanField(obj, { draft: true })
                })
              }
            }
          } else {
            search(path.concat('values', i), val)
          }
        })
      }
      delete obj.markedChanged
    }
    search([], this.state.draftLot.details)
    return detailsChanges
  }

  generateLot () {
    let draftLot = { ...this.state.draftLot }
    draftLot.details.values = this.generateDetailData()
    draftLot = this.prepopulateFields(draftLot)
    draftLot.saleConditions = this.props.user.data.saleConditions
    this.updateDraftLot(draftLot)
  }

  prepopulateFields (lot) {
    let agentId = findFieldByPublicId(lot.details, 'agentId')
    let agentName = findFieldByPublicId(lot.details, 'agentName')
    let agentPhone = findFieldByPublicId(lot.details, 'agentPhone')
    let agentEmail = findFieldByPublicId(lot.details, 'agentEmail')
    let agentDetailsName = findFieldByPublicId(lot.details, 'agentDetailsName')
    let agencyName = findFieldByPublicId(lot.details, 'agencyName')
    let haveWeaned = findFieldByPublicId(lot.details, 'haveWeaned')
    if (haveWeaned) {
      haveWeaned.value = false
    }
    if (agentId) {
      agentId.value = this.props.user.data.shortId
    }
    if (agentName) {
      agentName.value = `${this.props.user.data.firstName} ${this.props.user.data.lastName}`
    }
    if (agentDetailsName) {
      agentDetailsName.value = `${this.props.user.data.firstName} ${this.props.user.data.lastName}`
    }
    if (agencyName) {
      agencyName.value = this.props.user.data.agencyName
    }
    if (agentPhone) {
      agentPhone.value = this.props.user.data.phone
    }
    if (agentEmail) {
      agentEmail.value = this.props.user.data.email
    }
    return lot
  }

  populateLot (lot) {
    const reofferMode = this.mode === 'reoffer'
    this.updateDraftLot(update(this.state.draftLot, {
      _id: { $set: lot._id },
      agentSignature: { $set: lot.agentSignature },
      vendorSignature: { $set: lot.vendorSignature },
      description: { $set: lot.description },
      startPrice: { $set: lot.bidding !== 'kg' ? lot.startPrice / 100 : lot.startPrice },
      reserveCents: { $set: lot.bidding !== 'kg' ? lot.reserveCents / 100 : lot.reserveCents },
      bidIncrementCents: { $set: lot.bidIncrementCents },
      details: {
        values: {
          $set: FormUtils.prefillForm(lot.details.values, { mode: 'lot' }, this.props.kind.type)
        }
      },
      bidding: { $set: lot.bidding },
      biddingSwap: { $set: lot.biddingSwap },
      draft: { $set: lot.draft },
      count: { $set: lot.count },
      countWeighed: { $set: lot.countWeighed },
      acceptenceOnTerms: { $set: lot.acceptenceOnTerms },
      saleConditions: { $set: lot.saleConditions },
      maxVendorBids: { $set: lot.maxVendorBids },
      vendorSignedForm: { $set: lot.vendorSignedForm },
      adjustWeight: { $set: reofferMode ? 0 : lot.adjustWeight },
      weightGain: { $set: lot.weightGain },
      optiweighAllowed: { $set: lot.optiweighAllowed },
      mobEntryWeight: { $set: lot.mobEntryWeight },
      publicDetails: { $set: lot.publicDetails },
      reoffered: { $set: lot.reoffered },
      usefulLinks: { $set: lot.usefulLinks }
    }))
  }

  generateDetailData () { 
    let forms = []
    let kind = this.props.kind
if (kind && kind.form && kind.form.forms) {
      forms = FormUtils.prefillForm(kind.form.forms, { mode: 'kind' }, this.props.kind.type)
    } else {
      this.props.showModal({
        title: 'Form',
        message: 'Wrong format of form'
      })
    }
    return forms
  }

  getCurrentLotId () {
    return this.props.match.params.draftNumericId
  }

  isAuctionValid () {
    return isAvailableAuction(this.state.auction)
  }

  changeAuction (newAuction) {
    let earliestDelivery = findFieldByPublicId(this.state.draftLot.details, 'earliestDelivery')
    let latestDelivery = findFieldByPublicId(this.state.draftLot.details, 'latestDelivery')
    if (new Date(newAuction.liveAt) > new Date(earliestDelivery.value) && (new Date(newAuction.liveAt) <= new Date(latestDelivery.value) || !latestDelivery.value)) {
      earliestDelivery.value = ''
      earliestDelivery.markedChanged = true
      this.silentSubmitLot(this.state.draftLot, '')
      this.props.showModal({
        message: 'Earliest Delivery date can not be earlier then Auction Date.'
      })
    } else if (new Date(newAuction.liveAt) > new Date(latestDelivery.value) && (new Date(newAuction.liveAt) <= new Date(earliestDelivery.value) || !earliestDelivery.value)) {
      latestDelivery.value = ''
      latestDelivery.markedChanged = true
      this.silentSubmitLot(this.state.draftLot, '')
      this.props.showModal({
        message: 'Latest Delivery date can not be earlier then Auction Date.'
      })
    } else if (new Date(newAuction.liveAt) > new Date(latestDelivery.value) && new Date(newAuction.liveAt) > new Date(earliestDelivery.value)) {
      earliestDelivery.value = ''
      earliestDelivery.markedChanged = true
      latestDelivery.value = ''
      latestDelivery.markedChanged = true
      this.silentSubmitLot(this.state.draftLot, '')
      this.props.showModal({
        message: 'Earliest Delivery and Latest Delivery dates can not be earlier than Auction Date.'
      })
    }
    if (newAuction._id !== this.state.auction._id) {
      this.setState({
        auction: newAuction,
        dirty: false
      }, () => {
        let lotId = this.getCurrentLotId()
        if (lotId) {
          this.props.history.replace(`${this.mainUrl}/${this.props.kind.title}/${newAuction.searchNumber}/${lotId}`)
        } else {
          this.props.history.replace(`${this.mainUrl}/${this.props.kind.title}/${newAuction.searchNumber}`)
        }
        this.setState({
          dirty: true
        })
      })
    }
  }

  saveMedia (media) {
    this.setState({ media })
  }

  saveDocuments = (documents) => {
    this.setState({ documents })
  }

  saveUsefulLinks = (usefulLinks) => {
    this.setState({ usefulLinks })
  }

  changeCount (newCount, field) {
    if (field !== 'count' && field !== 'countWeighed') {
      throw new Error('Invalid field. Can be "count" or "countWeighed"')
    }
    this.props.showSpinner(true)
    let trueCount = newCount
    if (field === 'count' && this.props.kind.type !== 'cattle' && this.state.auction.kindData.type !== 'sheep-abblamb') {
      if (this.state.draftLot.countWeighed !== null) {
        trueCount = this.state.draftLot.countWeighed
      }
    } else if (field === 'countWeighed') {
      if (newCount === null) {
        trueCount = 1
      }
    }
    // setTimeout here is a workaround for spinner to show up instantly
    setTimeout(() => {
      let newDetails = FormUtils.editAllRFNumber(this.props.kind, this.state.draftLot.details, trueCount)
      this.updateDraftLot(update(this.state.draftLot, {
        [field]: { $set: newCount },
        details: { $set: newDetails }
      }), {
        formChanged: true,
        cb: () => {
          this.props.showSpinner(false)
        }
      }, field)
    }, 1000)
  }

  changeMobEntryWeight (newValue) {
    this.updateDraftLot(update(this.state.draftLot, {
      mobEntryWeight: { $set: parseFloat(newValue) && !isNaN(parseFloat(newValue)) ? parseFloat(newValue) : 0 }
    }))
  }

  removeRFNElement = index => {
    this.props.showSpinner(true)
    setTimeout(async () => {
      let newDetails = FormUtils.editAllRFNumber(this.props.kind, this.state.draftLot.details, 0, index)
      this.setState(state => {
        let newState = {
          draftLot: update(state.draftLot, {
            count: { $set: state.draftLot.count - 1 },
            details: { $set: newDetails }
          })
        }
        if (this.state.draftLot.hasOwnProperty('countWeighed')) {
          newState.draftLot.countWeighed = state.draftLot.countWeighed - 1
        }
        return newState
      }, () => { this.props.showSpinner(false) })
    }, 500)
  }

  renderRow (rowData, index) {
    return (
      <FormRow
        key={index}
        index={index}
        draftLot={this.state.draftLot}
        mode={this.mode}
        row={rowData}
        auction={this.state.auction}
        onChangeDraftLot={this.changeData}
        updateDraftLot={this.updateDraftLot}
        kindType={this.props.kind.type}
        districts={this.props.locationDistricts}
        pics={this.props.user.pics}
        removeRFNElement={this.removeRFNElement}
      />
    )
  }

  submit () {
    let validationResult = this.validateDraftLot(this.state.draftLot, { draft: false })
    if (validationResult.valid) {
      this.deleteCurrentLotFromRemeberedLotsWithIndex(this.state.draftLot['_id'])
      this.submitLot(this.state.draftLot, { draft: false, customLeave: false })
    } else {
      this.props.showModal({
        message: validationResult.message
      })
    }
  }

  saveDraftLot ({ customLeave = false } = {}) {
    let validationResult = this.validateDraftLot(this.state.draftLot, { draft: true })
    if (validationResult.valid) {
      this.submitLot(this.state.draftLot, { draft: true, customLeave })
    } else {
      this.props.showModal({
        message: validationResult.message
      })
    }
  }

  auctionValidation () {
    let { auction } = this.state
    if (!this.isAuctionValid()) {
      let msg
      if (auction.cancelled) {
        msg = 'This Auction is Cancelled'
      } else if (auction.state === 'closed') {
        msg = 'This Auction is Closed'
      } else if (auction.state === 'live') {
        msg = 'This Auction is Live'
      } else if (auction.state === 'open') {
        msg = 'This Auction is Open'
      } else if (auction.state === 'future') {
        msg = 'You can no longer add lots to this Auction'
      } else {
        msg = 'This Auction is Closed or unavailable'
      }
      return {
        valid: false,
        message: msg
      }
    }
    return {
      valid: true,
      message: ''
    }
  }

  bidCentsValidation (draftLot) {
    if (draftLot.startPrice && draftLot.reserveCents &&
      draftLot.reserveCents < draftLot.startPrice) {
      return {
        valid: false,
        message: 'Your Reserve Price can\'t be lower than your Start Price'
      }
    }
    if (!draftLot.bidding) {
      return {
        valid: false,
        message: 'Please select bidding'
      }
    }
    if (!draftLot.bidIncrementCents) {
      return {
        valid: false,
        message: 'Please select bid increment'
      }
    }
    return {
      valid: true,
      message: ''
    }
  }
  caluculateFatscoreValues(draftLot) {
    let fatScoreGroup = findFieldByPublicId(draftLot.details, "fatscoreGroup")
    const sumOfValues = fatScoreGroup.values.reduce((total, item) => {
      const value = parseFloat(item.value);
      if (!isNaN(value)) {
        return total + value;
      } else {
        return total; // Ignore non-numeric values
      }
    }, 0);

    return sumOfValues;
  }
  validateGrazingConditions(draftLot) {
    let grazingConditionsField = findFieldByPublicId(draftLot.details, "grazingConditions")
    if (grazingConditionsField) {
      const hasAtLeastOneTrue = grazingConditionsField && grazingConditionsField.values && grazingConditionsField.values.length > 0 && grazingConditionsField.values[0].some(item => item.value === true);
      if (!hasAtLeastOneTrue) {
        return false
      }
    }
    return true

  }
  validateDraftLot (draftLot, { draft, silent = false } = {}) {
    if (!draft) {
      if (!draftLot.optiweighAllowed && !draftLot.description.trim()) {
        return {
          valid: false,
          message: 'Please Enter Stock Description'
        }
      }
      let bidCents = this.bidCentsValidation(draftLot)
      if (!bidCents.valid) {
        return bidCents
      }
      if (!draftLot.count) {
        return {
          valid: false,
          message: 'Please Enter Number of Head'
        }
      }
      if(this.state.auction.kindData.type === 'sheep-abblamb'){
        let fatScoreCombinedValue = this.caluculateFatscoreValues(draftLot)
        if(fatScoreCombinedValue !== 100) {
          return {
            valid: false,
            message: 'Total Fat Score Percentage must be equal to 100% when combined'
          }
        }
      }
      if(this.state.auction.kindData.type === 'sheep-abblamb' && !this.validateGrazingConditions(draftLot)){
          return {
            valid: false,
            message: 'Minimum of 1 Grazing Condition must be Selected'
          }
      }

      if (!draftLot.countWeighed && this.props.kind && this.props.kind.type === 'sheep') {
        return {
          valid: false,
          message: 'Please enter Number of Head Weighed'
        }
      }
      if (this.props.kind.type === 'sheep' && draftLot.countWeighed && (draftLot.countWeighed * 100 / draftLot.count) < MIN_WEIGHED_HEADS_PERCENTAGE) {
        return {
          valid: false,
          message: 'Amount of weighed heads should be at least 10% of total amount of heads'
        }
      }
      let customFormValidation = FormUtils.validateForm(
        draftLot.details.values,
        draftLot.optiweighAllowed,
        this.props.kind.type === 'goat' && draftLot.countWeighed === 0,
        { draft, optiweighAllowed: draftLot.optiweighAllowed }
      )
      this.updateDraftLot(update(draftLot, {
        details: {
          values: {
            $set: customFormValidation.values
          }
        }
      }))
      if (customFormValidation.invalid) {
        let invalidElems = findAllInvalidFields(customFormValidation.values)
        let msg
        if (invalidElems[0].publicId === 'slightlyReactive' ||
          invalidElems[0].publicId === 'evenTempered' ||
          invalidElems[0].publicId === 'reactive'
        ) {
          msg = 'Please enter Temperament. This is a required field*'
        } else if (invalidElems.length > 1) {
          msg = 'Please Fill Required Fields in Red Above'
        } else if (invalidElems.length > 0) {
          switch (true) {
            case invalidElems[0].publicId === 'vendorPic':
              let vendorDetails = this.state.draftLot.details.values.find((elem) => {
                return elem.title === 'Vendor Details'
              })
              let state = vendorDetails.values.find((elem) => {
                return elem.publicId === 'state'
              })
              switch (state.value) {
                case 'NSW':
                  msg = 'Your State is NSW. PIC No. starts with N then 1 letter and 6 numbers.'
                  break
                case 'QLD':
                  msg = 'Your State is QLD. PIC No. starts with Q then 3 letters and 4 numbers.'
                  break
                case 'VIC':
                  msg = 'Your State is VIC. PIC No. starts with 3 then 4 letters and 3 numbers.'
                  break
                case 'WA':
                  msg = 'Your State is WA. PIC no. starts with W then 3 letters and 4 numbers.'
                  break
                case 'SA':
                  msg = 'Your State is SA. PIC no. starts with S then 1 letter and 6 numbers.'
                  break
                case 'NT':
                  msg = 'Your State is NT. PIC no. starts with T then 3 letters and 4 numbers.'
                  break
                case 'TAS':
                  msg = 'Your state is TAS. PIC no. starts with M then 3 letters and 4 numbers.'
                  break
                default:
                  msg = `Please Enter correct "${invalidElems[0].title}". based on the chosen state. This is a required field*`
                  break
              }
              break
            default:
              msg = `Please enter ${invalidElems[0].title} This is a required field*`
          }
        }
        return {
          valid: false,
          message: msg
        }
      }
      if (!draftLot.acceptenceOnTerms && this.state.auction.kindData.type !== 'sheep-abblamb') {
        return {
          valid: false,
          message: 'Please Accept Terms & Conditions'
        }
      }
      if (!draftLot.vendorSignedForm && this.state.auction.kindData.type !== 'sheep-abblamb') {
        return {
          valid: false,
          message: 'Please Sign Assessment Form'
        }
      }
    }
    return {
      valid: true,
      message: ''
    }
  }

  tryToPutLot ({ auctionId, lotId, data, detailsChanges, silent = false, emailToVendor = false }) {
    if (!silent && this.props.user.data.status === 'partially-approved') {
      this.props.showModal({
        message: 'Please complete your profile to Add Lot',
        buttons: [
          {
            text: 'Cancel'
          },
          {
            text: 'Update',
            onPress: () => {
              this.props.history.push(`/main/profile`)
            }
          }
        ]
      })
      return
    }
    if (!silent && this.props.user.data.status === 'partially-approved:updated') {
      this.props.showModal({
        message: 'Your application is under review and should be approved in the next 24-hours. Please contact us if you need immediate access to Add a Lot to an Upcoming Auction.',
        buttons: [{
          text: 'OK'
        }]
      })
      return
    }
    if (data.bidding !== 'kg') {
      data.startPrice *= 100
    }
    if(this.state.auction.kindData.type === 'sheep-abblamb'){
      delete data.countWeighed;
    }
    if (data.bidding !== 'kg') {
      data.reserveCents *= 100
    }
    return Api.putLot({
      auctionId,
      lotId,
      data,
      detailsChanges,
      silent,
      emailToVendor
    }).then((data) => {
      if (data && !data.isError) {
        return data
      } else {
        this.props.showModal({
          message: data && data.message ? data.message : 'Your internet connection has dropped out. FarmGate Auctions will try to automatically reconnect when it detects an internet connection',
          buttons: [
            {
              text: 'OK',
              onPress: () => this.reloadPage()
            }
          ]
        })
      }
    })
  }

  async submitLot (submitData, { draft = true, customLeave = false } = {}) {
    let emailToVendor = submitData.emailToVendor
    submitData = _pick(submitData, LotForm.SUBMIT_FIELDS)
    if (this.props.kind.type !== 'cattle') {
      delete submitData.optiweighAllowed
      delete submitData.mobEntryWeight
    }
    if (this.props.kind.type === 'sheep-abblamb') {
      delete submitData.countWeighed
    }

    this.props.showSpinner(true)
    this.hasSubmitted = true
    // wait for auto-save to end before real submit
    if (this.isSilentSaving) {
      await new Promise((resolve, reject) => {
        let checkInterval = setInterval(() => {
          if (!this.isSilentSaving) {
            clearInterval(checkInterval)
            resolve()
          }
        }, 1000)
      })
    }

    let draftId = this.getCurrentLotId()
    if (draftId) {
      let success
      success = await this.uploadDocuments(draftId)
      if (!success) {
        this.hasSubmitted = false
        this.props.showSpinner(false)
        return
      }
      success = await this.uploadMedia(draftId)
      submitData.newMedia = this.state.media
      if (!success) {
        this.hasSubmitted = false
        this.props.showSpinner(false)
        return
      }
      success = await this.uploadUsefulLinks(draftId)
      submitData.usefulLinks = this.state.usefulLinks
      if (!success) {
        this.hasSubmitted = false
        this.props.showSpinner(false)
        return
      }

      // send details separately, in detailsChanges
      let detailsChanges = this.extractDetailsChanges({ extractEverything: true })
      delete submitData.details
      let displayContactAlert = false
      if (draft === false) {
        if (this.isAuctionValid()) {
          // only allow users to submit pending lots if auction passes validation
          submitData.draft = false
        } else {
          submitData.draft = true
          displayContactAlert = true
        }
      } else {
        submitData.draft = true
      }

      let data
      data = await this.tryToPutLot({
        auctionId: this.state.auction._id,
        lotId: draftId,
        data: submitData,
        detailsChanges,
        emailToVendor: emailToVendor
      })
      if (data) {
        this.props.mergeStateProp('lots', [data.lot], 'data')
        if (!this.props.saveAndLogout) {
          this.leaveAlert({ submitted: !data.lot.draft, data: data, displayContactAlert, lotHasBeenSavedAlert: !_has(this.props.location, 'state.isInEditSavedLots') })
          if (customLeave) {
            this.forceLeave()
          } else {
            this.setState({ dirty: false }, () => {
              this.leaveAfterSubmit({ submitted: !data.lot.draft })
            })
          }
        }
        /* if upload was successful
          we still want to keep hasSubmitted as true
          to prevent auto-save triggering AFTER user actually submitted a lot */
        this.hasSubmitted = true
      } else {
        this.hasSubmitted = false
      }
      this.props.showSpinner(false)
    } else {
      submitData = update(submitData, {
        details: {
          values: {
            $set: FormUtils.cleanFields(submitData.details.values, { draft: draft })
          }
        }
      })
      // first POST will save all detailsChanges anyway
      // so might as well clear them here
      this.extractDetailsChanges()
      let data
      data = await Api.postLot(this.state.auction._id, submitData)
      if (!data) {
        this.hasSubmitted = false
        this.props.showSpinner(false)
        return
      }
      this.updateDraftLot(update(this.state.draftLot, {
        _id: {
          $set: data.lot._id
        }
      }))
      this.props.mergeStateProp('lots', [data.lot], 'data')
      let success
      success = await this.uploadDocuments(data.lot._id)
      if (!success) {
        this.hasSubmitted = false
        this.props.showSpinner(false)
        return
      }
      success = await this.uploadMedia(data.lot._id)
      if (!success) {
        this.hasSubmitted = false
        this.props.showSpinner(false)
        return
      }

      success = await this.uploadUsefulLinks(data.lot._id)
      if (!success) {
        this.hasSubmitted = false
        this.props.showSpinner(false)
        return
      }

      delete submitData.details
      let displayContactAlert = false
      if (draft === false) {
        if (this.isAuctionValid()) {
          // only allow users to submit pending lots if auction passes validation
          submitData.draft = false
        } else {
          submitData.draft = true
          displayContactAlert = true
        }
      } else {
        submitData.draft = true
      }

      data = await this.tryToPutLot({
        auctionId: this.state.auction._id,
        lotId: data.lot._id,
        data: submitData,
        emailToVendor: emailToVendor
      })
      if (data) {
        this.props.mergeStateProp('lots', [data.lot], 'data')
        if (!this.props.saveAndLogout) {
          this.leaveAlert({ submitted: !data.lot.draft, data: data, displayContactAlert })
          if (customLeave) {
            this.forceLeave()
          } else {
            this.setState({ dirty: false }, () => {
              this.leaveAfterSubmit({ submitted: !data.lot.draft })
            })
          }
        }
        /* if upload was successful
          we still want to keep hasSubmitted as true
          to prevent auto-save triggering AFTER user actually submitted a lot */
        this.hasSubmitted = true
      } else {
        this.hasSubmitted = false
      }
      this.props.showSpinner(false)
    }
  }

  async silentSubmitLot (submitData, field, { draft = true } = {}) {
    submitData = _pick(submitData, LotForm.SUBMIT_FIELDS)
    if (this.props.kind.type !== 'cattle') {
      delete submitData.optiweighAllowed
      delete submitData.mobEntryWeight
    }
    if (this.props.kind.type === 'sheep-abblamb') {
      delete submitData.countWeighed
    }

    this.isSilentSaving = true
    this.markFormChanged({ formChanged: false })
    this.setState({
      showSaveNotification: true
    })

    let draftId = this.getCurrentLotId()
    if (draftId) {
      if (this.isUnmounted) {
        return
      }
      let detailsChanges
      let forcedDetailsChanges
      let individualAssessment = findFieldByPublicId(submitData.details, 'individualAssessment')

      if (individualAssessment.markedChanged) {
        let path = getPathByKeyAndValue(submitData.details, 'publicId', 'individualAssessment')
        if (path) {
          path = path.split('.').slice(0, -1)
        }
        if (this.props.kind.type === 'cattle' || this.props.kind.type === 'goat') {
          forcedDetailsChanges = {
            action: 'group-update',
            path,
            refObject: {
              isMarked: individualAssessment.isMarked,
              publicId: individualAssessment.publicId,
              title: individualAssessment.title,
              type: individualAssessment.type,
              values: individualAssessment.values
            }
          }
        } else if (field !== 'count') {
          forcedDetailsChanges = {
            action: 'group-update',
            path,
            refObject: {
              isMarked: individualAssessment.isMarked,
              publicId: individualAssessment.publicId,
              title: individualAssessment.title,
              type: individualAssessment.type,
              values: individualAssessment.values
            }
          }
        }
      }
      if (this.state.forceNextAutosave) {
        detailsChanges = this.extractDetailsChanges({ extractEverything: true })
      } else {
        detailsChanges = this.extractDetailsChanges(field)
      }
      if (forcedDetailsChanges) {
        detailsChanges.push(forcedDetailsChanges)
      }
      delete submitData.details
      if (field === 'count' && window.location.href.indexOf('Sheep') !== -1) {
        detailsChanges = []
      }
      let data = await this.tryToPutLot({
        auctionId: this.state.auction._id,
        lotId: draftId,
        data: submitData,
        detailsChanges,
        silent: true
      })
      this.isSilentSaving = false
      if (data) {
        this.props.mergeStateProp('lots', [data.lot], 'data')
      }
    } else {
      if (this.isUnmounted) {
        return
      }
      submitData = update(submitData, {
        details: {
          values: {
            $set: FormUtils.cleanFields(submitData.details.values, { draft: draft })
          }
        }
      })
      // first POST will save all detailsChanges anyway
      // so might as well clear them here
      this.extractDetailsChanges()
      let data = await Api.postLot(this.state.auction._id, submitData, { silent: true })
      this.isSilentSaving = false
      if (data && !data.isError) {
        this.setState({
          dirty: false
        }, () => {
          this.props.history.replace(`${this.mainUrl}/${this.props.kind.title}/${this.state.auction.searchNumber}/${data.lot.searchNumericId}`)
          this.setState({
            dirty: true
          })
        })
        this.updateDraftLot(update(this.state.draftLot, {
          _id: {
            $set: data.lot._id
          }
        }))
        this.props.mergeStateProp('lots', [data.lot], 'data')
      } else {
        this.props.showModal({
          message: data && data.message ? data.message : 'Your internet connection has dropped out. FarmGate Auctions will try to automatically reconnect when it detects an internet connection',
          buttons: [
            {
              text: 'OK',
              onPress: () => this.reloadPage()
            }
          ]
        })
      }
    }
    setTimeout(() => {
      if (this.isUnmounted) {
        return
      }
      if (this.formChanged) {
        this.setSaveTimeout(0) // do autosave again if form still changed
      } else {
        this.setState({
          showSaveNotification: false,
          forceNextAutosave: false
        })
      }
    }, 500)
  }

  leaveAlert ({ submitted, data, displayContactAlert = false, lotHasBeenSavedAlert = true }) {
    if (displayContactAlert) {
      this.props.showModal({
        message: `Your Lot has been saved. Please contact support on +61 2 8252 6840 to Add your Lot as \
online listings have closed for this Auction.`
      })
    } else if (submitted) {
      this.props.showModal({
        title: 'Lot Submitted Successfully',
        message: `Allow 24 hrs until live 
in Catalogue \
${data.lot.publicDetails.title} \
${data.lot.publicDetails.summary.age.toLocaleLowerCase()}. \
Email Lot to yourself?`,
        buttons: [
          {
            text: 'Yes',
            onPress: async () => {
              let data = await Api.emailLotOwnerInfo({
                lotId: this.getCurrentLotId(),
                data: {
                  recipientName: this.props.user.data.firstName,
                  recipientEmail: this.props.user.data.email
                }
              })
              if (data && data.success) {
                this.props.showModal({
                  title: 'FarmGate',
                  message: data.success
                })
              }
            }
          },
          { text: 'No', onPress: () => {} }
        ]
      }
      )
    } else if (lotHasBeenSavedAlert) {
      this.props.showModal({
        message: 'Your Lot Has Been Saved. Go to My Admin to Edit Saved Lots'
      })
    }
  }

  leaveAfterSubmit ({ submitted }) {
    if (submitted) {
      this.props.history.push('/main/manage/my-submitted')
    } else {
      this.props.history.push('/main/manage/edit-saved')
    }
  }

  forceLeave () {
    this.setState({ dirty: false }, () => {
      if (this.isEdit) {
        this.props.history.push('/main/manage/edit-saved')
      } else {
        if (this.state.nextLocation) {
          if (this.state.nextLocation.pathname.match('/add')) {
            this.props.history.replace(this.state.nextLocation.pathname)
          } else {
            this.props.history.push(this.state.nextLocation.pathname)
          }
        } else {
          this.props.history.push('/main/upcoming')
        }
      }
    })
  }

  async uploadDocuments (lotId) {
    let { documents } = this.state
    // clear empty entries
    documents = documents.filter(d => !d.isNew || d.uri)
    this.setState({ documents })
    let newDocuments = documents.filter(d => d.isNew)
    if (newDocuments.length === 0) {
      return true
    }

    let uploadDoc = async (doc) => {
      let data = await Api.postLotDocuments(lotId, doc)
      if (data) {
        documents = update(documents, {
          [documents.indexOf(doc)]: { $set: data.document }
        })
        return true
      } else {
        return false
      }
    }

    let res = await Promise.all(newDocuments.map(doc => uploadDoc(doc)))
    this.setState({ documents })
    if (res.every(d => d)) {
      this.props.mergeStateProp('lots', [{ _id: lotId, documents: documents }], 'data')
      return true
    } else {
      this.props.showModal({
        title: 'Uploading error',
        message: `Can not upload selected document(s)`
      })
      return false
    }
  }

  uploadUsefulLinks = async (lotId) => {
    let { usefulLinks } = this.state
    const newDocuments = usefulLinks.filter(link => link.uri)

    let uploadDoc = async (doc) => {
      let data = await Api.postLotUsefulPdfs(lotId, doc)
      if (data) {
        usefulLinks = update(usefulLinks, {
          [usefulLinks.indexOf(doc)]: { $set: data.newPdfFile }
        })
        return true
      } else {
        return false
      }
    }

    let res = await Promise.all(newDocuments.map(doc => uploadDoc(doc)))
    this.setState({ usefulLinks })
    if (res.every(d => d)) {
      this.props.mergeStateProp('lots', [{ _id: lotId, usefulLinks: usefulLinks }], 'data')
      return true
    } else {
      this.props.showModal({
        title: 'Uploading error',
        message: `Can not upload selected document(s)`
      })
      return false
    }
  }

  async uploadMedia (lotId) {
    let { media } = this.state
    let newMedia = media.filter(m => m.isNew)
    if (newMedia.length === 0) {
      return true
    }

    let uploadPicture = async (file) => {
      let data = await Api.postLotMedia(lotId, file)
      if (data) {
        let res = await Api.postMediaThumbnail(data.media.key, lotId, file.thumbnail)
        if (res) {
          media = update(media, {
            [media.indexOf(file)]: { $set: data.media }
          })
          this.setState({ media })
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }
    let uploadVideo = async (file) => {
      let data = await Api.postLotMedia(lotId, file)
      if (data) {
        let res = await Api.postMediaThumbnail(data.media.key, lotId, file.thumbnail)
        if (res) {
          media = update(media, {
            [media.indexOf(file)]: { $set: res.media }
          })
          this.setState({ media })
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }

    let res = await Promise.all(newMedia.map(doc => {
      return /video/.test(doc.mimeType) ? uploadVideo(doc) : uploadPicture(doc)
    }))
    this.setState({ media })
    if (res.every(d => d)) {
      this.props.mergeStateProp('lots', [{ _id: lotId, media: media }], 'data')
      return true
    } else {
      this.props.showModal({
        title: 'Uploading error',
        message: `Can not upload selected media`
      })
      return false
    }
  }

  getNotificationText () {
    return 'Saving...'
  }

  showNotification () {
    return this.state.showSaveNotification
  }

  async removeDraft () {
    this.deleteCurrentLotFromRemeberedLotsWithIndex(this.state.draftLot['_id'])
    let lotId = this.getCurrentLotId()
    if (lotId) {
      this.props.showSpinner(true)
      let res = await Api.deleteLot(lotId)
      if (res) {
        this.props.filterStateProp('lots', lot => lot._id !== lotId, 'data')
      }
    }
    this.forceLeave()
    this.props.showSpinner(false)
  }

  adminExportForm () {
    let formStr = JSON.stringify(this.state.draftLot)
    let downloadUrl = URL.createObjectURL(new Blob([formStr], { type: 'application/json' }))
    let fileName = `${this.state.draftLot.description || 'No Description'} - ${this.props.kind.title} Form ${moment().format('dddd DD MMM YYYY')}.json`
    this.adminExportLink.current.setAttribute('download', fileName)
    this.adminExportLink.current.setAttribute('href', downloadUrl)
    this.adminExportLink.current.click()
    URL.revokeObjectURL(downloadUrl)
  }

  adminImportForm ({ target: { files } }) {
    let file = files[0]
    if (file) {
      var reader = new FileReader()
      reader.readAsText(file, 'utf-8')
      reader.onload = (e) => {
        try {
          let draftLot = JSON.parse(e.target.result)
          var importValues = draftLot.details.values
          if (draftLot.details && draftLot.details.values && this.state.draftLot && this.state.draftLot.details && this.state.draftLot.details.values && this.state.draftLot.details.values.length !== draftLot.details.values.length) {
            console.log('modify draftLot');
            draftLot.details.values = this.state.draftLot.details.values
            for (let i = 0; i < draftLot.details.values.length - 1; i++) {
              const objectMatchWithTile = importValues.find((object) => object.title === draftLot.details.values[i].title);
              if (objectMatchWithTile) {
                draftLot.details.values[i] = objectMatchWithTile
              }
            }
          }
           this.updateDraftLotDetails(draftLot)
          if (!draftLot.weightGain) {
            draftLot.weightGain = this.props.kind.type === 'cattle' ? 0.5 : 0.05
          }
          if (!draftLot.publicDetails) {
            draftLot.publicDetails = {
              weight: {
                average: 0
              }
            }
          }
          if (!draftLot.mobEntryWeight) {
            draftLot.mobEntryWeight = 0
          }
          if (!draftLot.optiweighAllowed) {
            draftLot.optiweighAllowed = false
          }
          this.setState({
            forceNextAutosave: true
          }, () => {
            this.updateDraftLot(draftLot, { formChanged: true })
          })
        } catch (err) {
          console.error(err)
          this.props.showModal({
            title: 'Error',
            message: 'Could not parse form data from the file'
          })
        }
      }
      reader.onerror = (err) => {
        console.error(err)
        this.props.showModal({
          title: 'Error',
          message: 'Could not import form data from the file'
        })
      }
    }
  }

  renderAdminControls () {
    if (this.mode !== 'default') {
      return null
    }
    return (
      adminOnly(
        <div className='admin-controls'>
          <div className='btn' onClick={this.adminExportForm}>
            <span>Export Form</span>
            <span className='icon-wrapper icon-success'>
              <FontAwesomeIcon icon={faPlusSquare} size='lg' />
            </span>
          </div>
          <a download='form.json' href={false} ref={this.adminExportLink}>Export form</a>
          <label>
            <div className='btn'>
              <input
                type='file'
                accept='.json'
                onChange={this.adminImportForm}
              />
              <span>Import Form</span>
              <span className='icon-wrapper icon-success'>
                <FontAwesomeIcon icon={faPlusSquare} size='lg' />
              </span>
            </div>
          </label>
        </div>
      )
    )
  }

  renderLotControls () {
    return (
      <div className='lot-controls'>
        {this.renderBackButton()}
        {this.renderSaveLaterBtn()}
        {this.renderSubmitBtn()}
      </div>
    )
  }

  renderSubmitBtn () {
    return (
      <div className='btn' onClick={this.submit}>
        <span>Submit</span>
        <span className='icon-wrapper icon-success'>
          <FontAwesomeIcon icon={faCheck} size='lg' />
        </span>
      </div>
    )
  }

  renderSaveLaterBtn () {
    return (
      <div className='btn' onClick={this.showModal}>
        <span>Save For Later</span>
        <span className='icon-wrapper icon-success'>
          <FontAwesomeIcon icon={faSave} size='lg' />
        </span>
      </div>
    )
  }

  renderBackButton () {} // For reoffer lot form, realized there, dont remove

  renderNotificationBlock () {
    return (
      <div className={`save-notification${this.showNotification() ? ' show' : ''}`}>
        <div className='save-notice'>
          {this.getNotificationText()}
        </div>
      </div>
    )
  }

  renderAuctionInfo (auction) {
    return (
      <div className='top-header'>
        <div className='left'>
          <h1 className='auction-title'>Auction No. {auction.number} {auction.title}</h1>
          <p className='auction-live-date'>
            {momentTz(auction.liveAt).format('dddd D MMMM YYYY - h.mm A')}
          </p>
          {auction.description && (<p className='auction-desc'>{auction.description}</p>)}
        </div>
        <div className='right'>
          <div className='auction-logo'>
            {auction.logo && <img src={auction.logo.url} alt='Auction Logo' />}
          </div>
        </div>
      </div>
    )
  }

  renderMainForm () {
    const { auctions, kind } = this.props
    const { auction, draftLot, documents, usefulLinks, media } = this.state
    const reofferMode = this.mode === 'reoffer' || draftLot.reoffered
    const editLateMode = this.mode === 'edit-late'
    return (
      <div className='lot-form'>
        <p className='notice'>* is a required field</p>
        <div className='input-section input-section-first'>
          <div className='section-heading'>
            <p className='heading-title'>Auction Details<span className='asterisk'>*</span></p>
          </div>
          <div className='section-body'>
            <AuctionSelect
              disabled={editLateMode}
              auctions={auctions}
              auction={auction}
              onChange={this.changeAuction}
            />
            <StockDescription
              disabled={reofferMode || editLateMode}
              value={draftLot.description}
              isOptiweigh={draftLot.optiweighAllowed}
              onChange={this.changeData}
            />
            {draftLot && (kind.type !== 'cattle' || !draftLot.optiweighAllowed) &&
            <HeadsInput
              disabled={reofferMode || editLateMode}
              count={draftLot.count}
              countWeighed={draftLot.countWeighed}
              onChange={this.changeCount}
              abblambsale = {auction.kindData.type === 'sheep-abblamb'}
            />
            }
            {draftLot && draftLot.optiweighAllowed &&
              <NumberField
                isFloat
                onChange={this.changeMobEntryWeight}
                value={draftLot.mobEntryWeight && draftLot.mobEntryWeight !== 0 ? draftLot.mobEntryWeight : ''}
                isRequired={false} title={'Mob Entry Weight'}
              />
            }
            {(kind.type !== 'cattle' && kind.type !== 'sheep-abblamb') && (
              <WeighedHeadsInput
                disabled={reofferMode || editLateMode}
                count={draftLot.count}
                kind={kind.type}
                countWeighed={draftLot.countWeighed}
                onChange={this.changeCount}
              />
            )}
            <Bidding
              disabled={reofferMode || editLateMode}
              kindType={kind.type}
              auction= {auction}
              bidding={draftLot.bidding}
              onChange={this.changeData}
            />
            <BidIncrement
              disabled={editLateMode}
              bidding={draftLot.bidding}
              kindType={kind.type}
              auction= {auction}
              bidIncrementCents={draftLot.bidIncrementCents}
              onChange={this.changeData}
            />
            <MoneyInput
              title={draftLot.bidding === bidTypes.KG ? 'Start Price (¢/kg)' : 'Start Price ($/head)'}
              attribute='startPrice'
              startPrice={draftLot.startPrice}
              reserveCents={draftLot.reserveCents}
              required={draftLot.optiweighAllowed}
              onChange={this.changeData}
            />
            <MoneyInput
              title={draftLot.bidding === bidTypes.KG ? 'Reserve Price (¢/kg)' : 'Reserve Price ($/head)'}
              attribute='reserveCents'
              startPrice={draftLot.startPrice}
              reserveCents={draftLot.reserveCents}
              required={draftLot.optiweighAllowed}
              onChange={this.changeData}
            />
            {/* <VendorBids
              disabled={reofferMode || editLateMode}
              onChange={this.changeData}
              isOptiweigh={draftLot.optiweighAllowed}
              maxVendorBids={draftLot.maxVendorBids}
            /> */}
          </div>
        </div>
        <MediaPicker
          disabled={reofferMode || editLateMode}
          saveMedia={this.saveMedia}
          media={media}
          showSpinner={this.props.showSpinner}
          lot={this.state.draftLot}
          lotId={this.getCurrentLotId()}
        />
        <UsefulLinks usefulLinks={usefulLinks} saveUsefulLinks={this.saveUsefulLinks} />
        <DocPicker
          disabled={reofferMode || editLateMode}
          saveDocuments={this.saveDocuments}
          documents={documents}
          lotId={this.getCurrentLotId()}
        />
        {draftLot.details.values.map((rowItem, index) => this.renderRow(rowItem, index))}
        <SaleConditions
          disabled={reofferMode || editLateMode}
          saleConditions={draftLot.saleConditions}
          agentSignature={draftLot.agentSignature}
          onChange={this.changeData}
        />
        {auction.kindData.type !== 'sheep-abblamb' &&
          <TermsConditions
            disabled={reofferMode || editLateMode}
            acceptenceOnTerms={draftLot.acceptenceOnTerms}
            vendorSignature={draftLot.vendorSignature}
            onChange={this.changeData}
          />
        }

        {auction.kindData.type !== 'sheep-abblamb' && (
          <SignedForm
            disabled={reofferMode || editLateMode}
            vendorSignedForm={draftLot.vendorSignedForm}
            isLicencedAgent={draftLot.isLicencedAgent}
            onChange={this.changeData}
          />
        )}

        <EmailToVendor
          emailToVendor={draftLot.emailToVendor}
          onChange={this.changeData}
          disabled={reofferMode || editLateMode}
        />
      </div>
    )
  }

  render () {
    const {
      kind
    } = this.props
    const {
      auction,
      loaded
    } = this.state
    if (!loaded || !kind || !auction) {
      return <div />
    }
    return (
      <div className='add-lot-wrapper'>
        {this.renderNotificationBlock()}
        {this.renderAuctionInfo(auction)}
        {this.renderLotControls()}
        {this.renderAdminControls()}
        <hr className='separator' />
        {this.renderMainForm()}
        {this.renderLotControls()}
      </div>
    )
  }
}

LotForm.DRAFT_LOT = {
  description: '',
  agentSignature: {},
  vendorSignature: {},
  currentBidCents: 0,
  bidIncrementCents: null,
  reserveCents: 0,
  startPrice: 0,
  details: {
    type: 'group',
    title: 'Lot Details',
    values: []
  },
  bidding: null,
  biddingSwap: false,
  draft: true,
  count: 1,
  countWeighed: 1,
  acceptenceOnTerms: false,
  saleConditions: '',
  maxVendorBids: 0,
  vendorSignedForm: false,
  isLicencedAgent: false,
  adjustWeight: 0,
  emailToVendor: false,
  weightGain: 0,
  optiweighAllowed: false,
  mobEntryWeight: 0,
  usefulLinks: [],
  publicDetails: {
    weight: {
      average: 0
    }
  },
  reoffered: false
}

LotForm.SUBMIT_FIELDS = [
  'description',
  'currentBidCents',
  'bidIncrementCents',
  'reserveCents',
  'details',
  'bidding',
  'biddingSwap',
  'draft',
  'count',
  'countWeighed',
  'acceptenceOnTerms',
  'saleConditions',
  'maxVendorBids',
  'vendorSignedForm',
  'adjustWeight',
  'startPrice',
  'weightGain',
  'newMedia',
  'mobEntryWeight'
]

LotForm.propTypes = {
  kind: PropTypes.object,
  auctions: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  hasOtherDrafts: PropTypes.bool.isRequired,
  saveAndLogout: PropTypes.bool.isRequired,
  showSpinner: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  changeStateProp: PropTypes.func.isRequired,
  mergeStateProp: PropTypes.func.isRequired,
  filterStateProp: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  locationDistricts: PropTypes.object.isRequired,
  locationStates: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired
}

LotForm.defaultProps = {}

export default LotForm
