import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { PASSWORD_REGEXP } from '../../../src/constants/index'
import Api from '../../services/api'

import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faTimes,
  faCheck
} from '@fortawesome/fontawesome-free-solid'

class ChangePassModal extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      validPassword : false,
      invalidPassword: ''
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleOnBlur = this.handleOnBlur.bind(this)
    this.changePassword = this.changePassword.bind(this)
  }

  handleInputChange ({ target: { name, value } }) {
    this.setState({
      [name]: value
    }
    )
  }
  handleOnBlur ({ target: { name } }) {
   let validateresult =  this.validatePassword(this.state[name]);
   this.setState({
    validPassword: validateresult === '',
    invalidPassword: validateresult
  })
  }

  async changePassword() {
    if (!this.state.validPassword) {
      this.props.closeModal()
      this.props.showModal({
        message: this.state.invalidPassword
      })
    } else {
      delete this.state.validPassword
      delete this.state.invalidPassword
      this.props.showSpinner(true)
      let res = await Api.changePassword(this.state, { scene: 'changeCurrentPassword' })
      if (res) {
        this.props.closeModal()
        this.props.showModal({
          message: 'Your password has been changed successfully'
        })
      }
      this.props.showSpinner(false)
    }

  }
  validatePassword (password) {
    if (password === '' || password === ' ' || password === undefined) {
      return 'Please Enter Password'
    } 
    else if (!(PASSWORD_REGEXP.test(password) && (password && password.length >= 6 && password.length <= 50))) {
      return 'Please Enter New Password min. 6 characters long, can include letters,numbers and special characters like &@%+/!#$^?:,(){}~_&-'
    } 
    else {
      return ''
    }
  }

  render () {
   let  {validPassword,invalidPassword} = this.state
    return (
      <Modal
        isOpen={this.props.isOpen}
        onAfterOpen={this.props.afterOpenModal}
        onRequestClose={this.props.closeModal}
        ariaHideApp={false}
        // shouldCloseOnOverlayClick={false}
        overlayClassName='overlay'
        className='content'
        portalClassName='ReactModalPortal-Email'
      >
        <h2
          className='modal-header'>Change Password</h2>
        <span className='icon-close icon-danger' onClick={this.props.closeModal}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
        <div className='modal-body'>
          <form>
            <input
              type='password'
              name='oldPassword'
              placeholder='Old Password'
              className='modal-input'
              onChange={this.handleInputChange}
              defaultValue={this.state.recipientName} />
            <input
              type='password'
              name='newPassword'
              placeholder='New Password'
              className='modal-input'
              onChange={this.handleInputChange}
              onBlur={this.handleOnBlur}
              defaultValue={this.state.recipientEmail} />
            <input
              type='password'
              name='confirmPassword'
              placeholder='Confirm New Password'
              className='modal-input'
              onChange={this.handleInputChange}
              defaultValue={this.state.recipientEmail} />
          </form>
          {!validPassword && <div style={{color : 'red'}}>{invalidPassword}</div> }
          <div className='buttons'>
            <div className='button red-button' onClick={this.props.closeModal}>
              <span className='button-text'>Cancel</span>
              <span className='icon-wrapper'><FontAwesomeIcon icon={faTimes} /></span>
            </div>
            <div className='button' name='fullDetail' onClick={this.changePassword}>
              <span className='button-text'>Confirm</span>
              <span className='icon-wrapper'><FontAwesomeIcon icon={faCheck} /></span>
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

ChangePassModal.propTypes = {
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  afterOpenModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  auction: PropTypes.object
}

ChangePassModal.defaultProps = {
  afterOpenModal: () => {}
}

export default ChangePassModal
