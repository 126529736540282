import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import update from 'immutability-helper'

import FdInput from '../Fd-input/index'
import Api from '../../../services/api'
import { EMAIL_REGEXP, PASSWORD_REGEXP } from '../../../constants/index'
import Header from '../../Header/HeaderContainer'
import Footer from '../../Footer/Footer'
import Checkbox from '../../Checkbox/Checkbox'
import ReactGA from 'react-ga'
import { isIos } from '../../../utils/DeviceDetector'

class SignIn extends PureComponent {
  constructor (props) {
    super(props)
    document.title = 'FarmGate'
    this.state = {
      form: {
        email: '',
        password: '',
        rememberMe: true
      },
      submitted: false,
      validEmail: true,
      invalidEmail: '',
      validPassword: true,
      invalidPassword: ''
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleRememberMe = this.handleRememberMe.bind(this)
    this.validateData = this.validateData.bind(this)
    this.invalidDataReason = this.invalidDataReason.bind(this)
    this.allValid = this.allValid.bind(this)
  }
  componentDidMount () {
    if (isIos() && window.location.href.includes('mobile')) {
      window.location.href = 'https://apps.apple.com/au/app/farmgate-auctions/id1469350158'
      return
    }
    let token = window.location.hash.split('/').reverse()[0]
    if (token.length > 15) {
      setTimeout(() => {
        this.props.showSpinner(true)
        if (this.props.loggedIn) {
          Api.userLogout()
        }
        Api.getUserMagicLink(token, { scene: 'sign in' })
          .then(resp => {
            if (resp === 401) {
              this.setState({
                validPassword: false,
                invalidPassword: 'Invalid Link'
              })
            } else if (resp === 403) {
              this.props.showSpinner(false)
              return
            } else if (resp) {
              this.props.changeStateProp('hasData', false, 'temp')
              if (!this.state.form.rememberMe) {
                window.sessionStorage.setItem('remember-session', true)
              }
              this.props.changeStateProp('rememberMe', this.state.form.rememberMe, 'user')
              this.props.changeStateProp('data', resp.user, 'user')
              this.props.changeStateProp('loggedIn', true, 'user')
              window.location.href = window.location.href.split('?token=')[0]
            }
            this.props.showSpinner(false)
          })
      }, 0)
    }
  }
  handleInputChange ({ target: { name, value } }) {
    this.setState({
      form: update(this.state.form, {
        [name]: { $set: value }
      })
    }, () => {
      if (this.state.submitted) {
        switch (name) {
          case 'email':
            this.setState({
              validEmail: this.validateEmail(this.state.form[name]) === '',
              invalidEmail: this.validateEmail(this.state.form[name])
            })
            break
          case 'password':
            this.setState({
              validPassword: this.validatePassword(this.state.form[name]) === '',
              invalidPassword: this.validatePassword(this.state.form[name])
            })
            break
          default:
            break
        }
      }
    })
  }
  handleRememberMe (value) {
    this.setState({
      form: update(this.state.form, {
        rememberMe: { $set: value }
      })
    })
  }
  handleSubmit (event) {
    const { redirectUrl } = this.props
    event.preventDefault()
    let data = {}
    this.setState({
      submitted: true
    })
    this.validateData(this.state.form.email, this.state.form.password)
    this.invalidDataReason(this.state.form.email, this.state.form.password)
    setTimeout(() => {
      if (this.allValid()) {
        data = {
          email: this.state.form.email.toLowerCase(),
          password: this.state.form.password
        }
        this.props.showSpinner(true)
        if (window.location.href.split('?token=')[0]) {
          window.location.href = window.location.href.split('?token=')[0]
        }
        Api.userLogout()
          .then(() => {
            Api.getUser(data, { scene: 'sign in' })
              .then(resp => {
                if (resp === 401) {
                  this.setState({
                    validPassword: false,
                    invalidPassword: 'Sorry incorrect password. Try again or click Forgot Password? below.'
                  })
                } else if (resp === 403) {
                  this.props.showModal({
                    message: 'Please allow 24-hours for your account to be verified and approved',
                    buttons: [{
                      text: 'OK'
                    }]
                  })
                } else if (resp) {
                  this.props.changeStateProp('hasData', false, 'temp')
                  if (!this.state.form.rememberMe) {
                    window.sessionStorage.setItem('remember-session', true)
                  }
                  this.props.changeStateProp('rememberMe', this.state.form.rememberMe, 'user')
                  this.props.changeStateProp('data', resp.user, 'user')
                  this.props.changeStateProp('loggedIn', true, 'user')
                  if (redirectUrl) {
                    window.location.href = redirectUrl
                    this.props.changeStateProp('redirectUrl', '', 'user')
                  }
                }
                this.props.showSpinner(false)
              })
          }
          )
      }
    }, 0)
  }
  allValid () {
    if (this.state.validEmail &&
      this.state.validPassword) {
      return true
    } else {
      return false
    }
  }
  validateData (email, password) {
    this.setState({
      validEmail: this.validateEmail(email) === '',
      validPassword: this.validatePassword(password) === ''
    })
  }
  invalidDataReason (email, password) {
    this.setState({
      invalidEmail: this.validateEmail(email),
      invalidPassword: this.validatePassword(password)
    })
  }
  validateEmail (email) {
    if (email === '' || email === ' ' || email === undefined) {
      return 'Please Enter Email'
    } else if (!(EMAIL_REGEXP.test(email) && (email && email.length >= 3 && email.length <= 60))) {
      return 'Sorry, invalid Email'
    } else {
      return ''
    }
  }
  validatePassword (password) {
    if (password === '' || password === ' ' || password === undefined) {
      return 'Please Enter Password'
    } else if (!(PASSWORD_REGEXP.test(password) && (password && password.length >= 6 && password.length <= 50))) {
      return 'Sorry, invalid password'
    } else {
      return ''
    }
  }

  sendAnalytic () {
    ReactGA.event({
      category: 'Forgot Password',
      action: 'Forgot Password'
    }, ['WebApp'])
  }

  render () {
    return (
      <div className='auth-wrapper sign-in'>
        <Header />
        <div className='background-wrapper'>
          <div className='content'>
            <div className='text-header'>
              <div className='sub-header'>Welcome to FarmGate Auctions</div>
              <div className='main-header'>Sign In</div>
            </div>
            <div className='form-wrapper'>
              <div className='top-line'>
                <span className='new'>New to FarmGate?</span>
                <NavLink to='/sign-up' className='sign-up-link nav-link'>Click here to Register</NavLink>
                {/* <NavLink to='/sign-up-guest' className='sign-up-link nav-link'>Click here to Register</NavLink> */}
              </div>
              <form onSubmit={this.handleSubmit}>
                <FdInput
                  containerClassName='email'
                  type='text'
                  placeholder='Email'
                  value={this.state.form.email}
                  name='email'
                  onChange={this.handleInputChange}
                  validData={this.state.validEmail}
                  warningReason={this.state.invalidEmail} />
                <FdInput
                  containerClassName='password'
                  type='password'
                  placeholder='Password'
                  value={this.state.form.password}
                  name='password'
                  onChange={this.handleInputChange}
                  validData={this.state.validPassword}
                  warningReason={this.state.invalidPassword}
                  withShowButton
                />
                <Checkbox
                  type='gray'
                  value={this.state.form.rememberMe}
                  onToggle={this.handleRememberMe} />
                <span className='remember-label'>Remember me</span>
                <input className='login-btn' type='submit' value='login now' />
              </form>
              <div className='forgot-link-wrapper'>
                <NavLink to='/forgot' className='nav-link forgot-link' onClick={this.sendAnalytic}>Forgot Password? Click Here</NavLink>
              </div>
              <div className='forgot-link-wrapper'>
                <NavLink to='/contact-us' className='nav-link forgot-link'>Help Centre, Click Here</NavLink>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

SignIn.propTypes = {
  changeStateProp: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  showSpinner: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}
SignIn.defaultProps = {
}

export default SignIn
