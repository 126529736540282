import React from 'react'
import FdInput from '../../Auth/Fd-input/index'
import FgPhone from '../../FgPhone/FgPhone'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faUser
} from '@fortawesome/fontawesome-free-solid'

const ContactDetails = (props) => {
  const { form, edit, handleInputChange } = props
  return (
    <div className='info-item'>
      <div className='cd-header'>
        <FontAwesomeIcon className='icons-style' icon={faUser} />
        <div className='cd-title'>Contact Details</div>
      </div>
      <div className='cd-data'>
        <div className='columns'>
          <div className='rows'>
            <p className={`titles ${form.firstName.valid}`}>First Name*:</p>
            <FdInput
              className={`inputs ${!form.firstName.valid ? 'red' : ''}`}
              type='text'
              // placeholder='First Name'
              value={form.firstName.value}
              name='firstName'
              onChange={handleInputChange}
              validData={form.firstName.valid}
              disabled={!edit}
            />
          </div>
          <div className='rows'>
            <p className={`titles ${form.lastName.valid}`}>Last Name*:</p>
            <FdInput
              className={`inputs ${!form.lastName.valid ? 'red' : ''}`}
              type='text'
              // placeholder='Last Name'
              value={form.lastName.value}
              name='lastName'
              onChange={handleInputChange}
              validData={form.lastName.valid}
              disabled={!edit}
            />
          </div>
          <div className='helpers hide-on-mobile' />
        </div>
        <div className='columns'>
          <div className='rows'>
            <p className={`titles ${form.email.valid}`}>Email*:
            </p>
            <FdInput
              className={`inputs ${!form.email.valid ? 'red' : ''}`}
              type='text'
              value={form.email.value}
              name='email'
              onChange={handleInputChange}
              validData={form.email.valid}
              disabled={!edit}
            />
          </div>
          <div className='rows'>
            <p className={`titles ${form.phone.valid}`}>Mobile*:</p>
            <FgPhone
              className={`inputs ${!form.phone.valid ? 'red' : ''}`}
              type='text'
              // placeholder='Phone'
              value={form.phone.value}
              name='phone'
              containerClassName='input-container'
              onChange={handleInputChange}
              validData={form.phone.valid}
              disabled={!edit}
              context='profile'
            />
          </div>
          <div className='rows'>
            <p className={`titles margin-top-6px ${form.secondaryPhone.valid}`}>Secondary Number:</p>
            <FgPhone
              className={`inputs ${!form.secondaryPhone.valid ? 'red' : ''}`}
              type='text'
              // placeholder='Secondary Number'
              value={form.secondaryPhone.value}
              name='secondaryPhone'
              containerClassName='input-container'
              onChange={handleInputChange}
              validData={form.secondaryPhone.valid}
              disabled={!edit}
              context='profile'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactDetails
