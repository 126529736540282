import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Api from '../../services/api'
import MapContainer from '../MapContainer/Map'
import { subTitles } from '../../constants/strings'

class ContactUs extends PureComponent {
  render() {
    return (
      <div className='app-route'>
        <div className='body-container contact-us'>
          <div className='inner-container'>
            <ContactUsContent {...this.props} />
          </div>
        </div>
      </div>
    )
  }
}

class ContactUsContent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      callPhone: '',
      textPhone: '',
      email: '',
      secondaryEmail: '',
      mainOfficeMapStyle: {},
      regionalOfficeMapStyle: {},
      regionalOfficeMapStyle2: {}
    }
    this.isUnmounted = false
    this.container = React.createRef()
    this.updateMapSize = this.updateMapSize.bind(this)
  }

  componentDidMount() {
    this.getContacts()
    window.addEventListener('resize', this.updateMapSize)
    this.updateMapSize()
  }

  componentWillUnmount() {
    this.isUnmounted = true
    window.removeEventListener('resize', this.updateMapSize)
  }

  async getContacts() {
    let res = await Api.getContacts()
    if (this.isUnmounted) {
      return
    }
    if (res) {
      this.setState({
        callPhone: res.contact.callPhone,
        textPhone: res.contact.textPhone,
        email: res.contact.email,
        secondaryEmail: res.contact.secondaryEmail
      })
    }
  }

  makePhoneLink(phone) {
    if (phone.startsWith('+')) {
      return `tel:${phone}`
    } else {
      return `tel:+${phone}`
    }
  }

  updateMapSize() {
    let container = this.container.current
    if (container) {
      let width = this.container.current.clientWidth
      if (window.innerWidth < 950) {
        this.setState({
          mainOfficeMapStyle: { width: width, height: 230, zIndex: '2', left: 0, top: 130 },
          regionalOfficeMapStyle: { width: width, height: 230, zIndex: '2', left: 0, top: 520 },
          regionalOfficeMapStyle2: { width: width, height: 230, zIndex: '2', left: 0, top: 910 }
        })
      } else {
        this.setState({
          mainOfficeMapStyle: { width: (width - 12) / 3, height: 393, zIndex: '2', left: 0, top: 0 },
          regionalOfficeMapStyle: { width: (width - 12) / 3, height: 393, zIndex: '2', left: (((width - 12) / 3) + 6) * 1, top: 0 },
          regionalOfficeMapStyle2: { width: (width - 12) / 3, height: 393, zIndex: '2', left: (((width - 12) / 3) + 6) * 2, top: 0 },
        })
      }
    }
  }

  render() {
    return (
      <div ref={this.container}>
        <h1 className='fg-header'>Contact Us</h1>
        <p className='fg-paragraph'>{subTitles.CONTACT_US}</p>
        <div className='locations-wrapper'>
          <div className='locations-title'>Office Locations</div>
          <div className='maps'>
            <MapContainer
              style={this.state.mainOfficeMapStyle}
              initialCenter={{
                lat: -33.8634155,
                lng: 151.2081836
              }}
              markerPosition={{
                lat: -33.8634155,
                lng: 151.2081836
              }}
              adress='Level 5, 10 Bridge Street Sydney, Australia, 2000'
            />
            <MapContainer
              style={this.state.regionalOfficeMapStyle}
              initialCenter={{
                lat: -30.704253,
                lng: 150.043626
              }}
              markerPosition={{
                lat: -30.704253,
                lng: 150.043626
              }}
              adress='134 Merton Street Boggabri, Australia 2382'
            />
            <MapContainer
              style={this.state.regionalOfficeMapStyle2}
              adress='Goondiwindi, Queensland, Australia 4390'
            />
          </div>
          <div className='office-info'>
            <div className='main-office-info'>
              <div className='main-office-title'>Main Office</div>
              <div className='office-info-table'>
                <div className='office-info-table-titles'>
                  <div className='office-address'>Address:</div>
                  <div>Phone:</div>
                  <div>Email:</div>
                </div>
                <div className='office-info-table-data'>
                  <div>Level 5, 10 Bridge Street<br />
                    Sydney, Australia 2000
                  </div>
                  <div><a className='phone-and-email' href={this.makePhoneLink(this.state.callPhone)}>{this.state.callPhone}</a></div>
                  <div><a className='phone-and-email' href={`mailto:${this.state.email}`}>{this.state.email}</a></div>
                  <div><a className='phone-and-email' href={`mailto:${this.state.secondaryEmail}`}>{this.state.secondaryEmail}</a></div>
                </div>
              </div>
            </div>
            <div className='regional-office-info'>
              <div className='main-office-info'>
                <div className='main-office-title'>Regional Office</div>
                <div className='office-info-table'>
                  <div className='office-info-table-titles'>
                    <div className='office-address'>Address:</div>
                    <div>Phone:</div>
                    <div>Email:</div>
                  </div>
                  <div className='office-info-table-data'>
                    <div>134 Merton Street<br />Boggabri, Australia 2382</div>
                    <div><a className='phone-and-email' href={this.makePhoneLink(this.state.callPhone)}>{this.state.callPhone}</a></div>
                    <div><a className='phone-and-email' href={`mailto:${this.state.email}`}>{this.state.email}</a></div>
                    <div><a className='phone-and-email' href={`mailto:${this.state.secondaryEmail}`}>{this.state.secondaryEmail}</a></div>
                  </div>
                </div>
              </div>
            </div>
            <div className='regional-office-info'>
              <div className='main-office-info'>
                <div className='main-office-title'>Regional Office</div>
                <div className='office-info-table'>
                  <div className='office-info-table-titles'>
                    <div className='office-address'>Address:</div>
                    <div>Phone:</div>
                    <div>Email:</div>
                  </div>
                  <div className='office-info-table-data'>
                    <div>Goondiwindi,<br />Queensland, Australia 4390</div>
                    <div><a className='phone-and-email' href={this.makePhoneLink(this.state.callPhone)}>{this.state.callPhone}</a></div>
                    <div><a className='phone-and-email' href={`mailto:${this.state.email}`}>{this.state.email}</a></div>
                    <div><a className='phone-and-email' href={`mailto:${this.state.secondaryEmail}`}>{this.state.secondaryEmail}</a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='social-wrapper'>
          <div className='social-title'>
            Get Social
          </div>
          <div className='social-icons'>
            <a href={`https://www.facebook.com/FarmGateAuctions`} rel='noopener noreferrer' target='_blank'>
              <div className='social-facebook' />
            </a>
            <a href={`https://www.linkedin.com/company/farmgate-auctions`} rel='noopener noreferrer' target='_blank'>
              <div className='social-linkedin' />
            </a>
          </div>
        </div>
      </div>
    )
  }
}

ContactUs.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default ContactUs
