import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Section from '../Section/Section'
import BackButton from '../../BackButton/BackButton'
import { showModal, showSpinner,changeStateProp } from '../../../actions'
import { privacy_policy } from '../../../utils'

class Policy extends PureComponent {
  componentDidMount () {
    window.scroll(0, 0)
  }

  render () {
    return (
      <div className='policy'>
        <h1 className='fg-header'>Privacy Policy</h1>
        <div className='top-btns'>
          <div className='left'>
          <BackButton onPress={() => {
              this.props.history.goBack()
              this.props.changeStateProp('cameFromBuyerAndSellerForms',false,'user')
            }} name={`${this.props.cameFromBuyerAndSellerForms ? 'Back to Form' : "Back to Tools"}`} />
          </div>
        </div>
        <div className='policy-container'>
          { privacy_policy.map((section, index) => {
            return (
              <div key={index}>
                <Section title={section.title} text={[{ text: section.description }]} />
              </div>
            )
          }) }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    cameFromBuyerAndSellerForms: state.user.cameFromBuyerAndSellerForms
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ showModal, showSpinner,changeStateProp }, dispatch)
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Policy)

