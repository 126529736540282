import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { myLots } from '../../../selectors/lotsSelector'
import {
  mergeStateProp,
  filterStateProp,
  showModal,
  showSpinner,
  showSingleNotification,
  hideSingleNotification
} from '../../../actions'
import ExistingLotsList from './ExistingLotsList'

const mapStateToProps = (state, ownProps) => {
  return {
    auctions: state.data.auctions,
    userId: state.user.data._id,
    watchButtonLoading: state.temp.watchButtonLoading,
    lots: myLots({
      userId: state.user.data._id,
      lots: state.data.lots,
      showDraft: ownProps.showDraft,
      showSubmitted: ownProps.showSubmitted
    })
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    mergeStateProp,
    filterStateProp,
    showModal,
    showSpinner,
    showSingleNotification,
    hideSingleNotification
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExistingLotsList)
