import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import NavBar from './NavBar'
import { parseQueryString } from '../../utils'
import { changeStateProp, showModal } from '../../actions'

const mapStateToProps = (state, ownProps) => {
  return {
    userStatus: state.user.data.status,
    firstName: state.user.data.firstName,
    lastName: state.user.data.lastName,
    onLotForm: state.temp.onLotForm,
    from: parseQueryString(ownProps.location.search).from
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeStateProp, showModal }, dispatch)
}

const NavBarContainer = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(NavBar))

export default NavBarContainer
