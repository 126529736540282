import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { showSpinner } from '../../../actions'
import MyListings from './MyListings'

const mapStateToProps = (state) => {
  return {
    kinds: state.data.kinds
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ showSpinner }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyListings)
