import update from 'immutability-helper'

import {
  CHANGE_STATE_PROP,
  MERGE_STATE_PROP,
  FILTER_STATE_PROP
} from '../constants/index'
import { mergeByProperty } from '../utils/index'

const REDUCER = 'DATA'

const initialState = {
  kinds: [],
  auctions: [],
  lots: [],
  locationStates: [],
  client: [],
  locationDistricts: [],
  stockCategories: {}
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case REDUCER + CHANGE_STATE_PROP:
      return update(state, {
        [action.state.prop]: { $set: action.state.value }
      })
    case REDUCER + MERGE_STATE_PROP:
      return update(state, {
        [action.state.prop]: (prop) => mergeByProperty(prop, action.state.value, '_id')
      })
    case REDUCER + FILTER_STATE_PROP:
      return update(state, {
        [action.state.prop]: (prop) => prop.filter(action.state.func)
      })
    default:
      return state
  }
}
