import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { showSpinner, showModal } from '../../../actions'
import EmailModalMyAuctionResult from './EmailModalMyAuctionResult'

function mapStateToProps (state) {
  return {
    userData: state.user.data
  }
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({ showSpinner, showModal }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailModalMyAuctionResult)
