import * as LotUtils from './Lots'
import {
  bidTypes
} from '../constants'

export const getBidIncrement = (coefficient, lot) => {
  let price = lot.bidIncrementCents * coefficient
  let formattedPrice = LotUtils.makePrice(price, { bidding: lot.bidding, precision: 0 })
  return `+${formattedPrice}`
}

export const getBidResult = (coefficient, lot) => {
  let price = lot.currentBidCents + lot.bidIncrementCents * coefficient
  return LotUtils.makePrice(price, { bidding: lot.bidding })
}

export const statusClassName = (currentStatus) => {
  /* returns 'bided', 'overbided' or ''
      can be used for css classNames */
  if (currentStatus.indexOf('overbided') !== -1) {
    return 'overbided'
  } else if (currentStatus.indexOf('bided') !== -1) {
    return 'bided'
  } else {
    return ''
  }
}

export const getInputPlaceholder = (lot) => {
  const { HEAD, KG } = bidTypes
  let str
  switch (true) {
    case lot.bidding === KG:
      str = '¢ Amount'
      break
    case lot.bidding === HEAD:
    default:
      str = '$ Amount'
  }
  return str
}

export const calculateBid = (x, lot) => {
  return lot.currentBidCents + lot.bidIncrementCents * x
}
