import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Notifications from './Notifications'
import { closeSingleNotification, closeUserNotification, hideSingleNotification, showSingleNotification } from '../../actions'

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    singleNotifications: state.main.singleNotifications,
    userNotifications: state.user.userNotifications,
    auctions: state.data.auctions,
    isServerLive: state.temp.isServerLive,
    isServerLiveMessage: state.temp.isServerLiveMessage
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ closeSingleNotification, closeUserNotification, hideSingleNotification, showSingleNotification }, dispatch)
}

const NotificationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications)

export default NotificationContainer
