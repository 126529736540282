import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Toggle from 'react-toggle'
import { Link } from 'react-router-dom'
import SignatureImage from '../elements/SignatureImage'

class TermsConditions extends PureComponent {
  constructor (props) {
    super(props)
    this.toggle = this.toggle.bind(this)
  }
  toggle () {
    this.props.onChange('acceptenceOnTerms', !this.props.acceptenceOnTerms)
  }
  render () {
    const { acceptenceOnTerms, disabled, vendorSignature } = this.props
    return (
      <div className={`input-section ${disabled ? 'disabled' : ''}`}>
        <div className='section-heading'>
          <div className='heading-title'>Terms & Conditions<span className='asterisk'>*</span></div>
        </div>
        <div className='section-body'>
          <div className='form-row'>
            <div className='row-title row-title-long'>
              <p className='row-text'>
                Vendor accepts{' '}
                <Link to='/main/tools/terms' target='_blank' rel='noopener noreferrer'>
                  Terms & Conditions
                </Link>
                {' '}of sale
                <span className='asterisk'>*</span>:
              </p>
            </div>
            <div className='row-content'>
              <div className='switcher'>
                <p className='row-text'>No</p>
                <Toggle
                  icons={false}
                  checked={acceptenceOnTerms}
                  onChange={this.toggle} />
                <p className='row-text'>Yes</p>
              </div>
            </div>
          </div>
          {vendorSignature && vendorSignature.url && <div className='form-row form-row-long'>
            <div className='row-title'>
              <p
                className='row-text'>Vendor Signature Capture</p>
            </div>
            <div className='row-content'>
              <SignatureImage src={vendorSignature.url} />
            </div>
          </div>}
        </div>
      </div>
    )
  }
}

TermsConditions.propTypes = {
  acceptenceOnTerms: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

TermsConditions.defaultProps = {
  disabled: false
}

export default TermsConditions
