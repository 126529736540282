import LotDetails from '../../Upcoming/LotDetails/LotDetails'
import { BASE_URL } from '../../../config'

class MySybmittedLotDetails extends LotDetails {
  constructor (props) {
    super(props)
    this.allFields = true
    this.pageSubmitted = true
  }

  goBackName () {
    return 'Back to My Submitted Lots'
  }

  goBackLink () {
    return '/main/manage/my-submitted'
  }

  pdfUrl () {
    return `${BASE_URL}print/lots/${this.state.lot.searchNumericId}/owner-full-details?mode=print`
  }

  downloadReportUrl () {
    return `${BASE_URL}print/lots/${this.state.lot.searchNumericId}/owner-full-details`
  }
}

export default MySybmittedLotDetails
