import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
  changeStateProp,
  showSpinner
} from '../../../actions'
import MyTexts from './MyTexts'

const mapStateToProps = (state) => {
  return {
    data: state.user.data
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    changeStateProp,
    showSpinner
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyTexts)
