import React, { Component } from 'react'
import { WanderingCubes } from 'better-react-spinkit'
import Api from '../../../services/api'
import ImageCarousel from '../../ImageCarousel/ImageCarousel'
import DetailedInfo from '../../DetailedInfo/DetailedInfoContainer'

import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faTimes
} from '@fortawesome/fontawesome-free-solid'

class LotInfoComponent extends Component {
  constructor (props) {
    super(props)
    this.requestPending = false
  }

  componentDidMount () {
    if (this.props.lot) {
      if (!this.props.lot.details) {
        this.getLot(this.props.lot._id)
      }
    } else {
      if (this.props.lotId) {
        this.getLot(this.props.lotId)
      }
    }
  }

  getLot (lotId) {
    if (this.requestPending) return
    this.requestPending = true
    Api.getLot(lotId)
      .then(data => {
        if (data) {
          this.props.mergeStateProp('lots', [data.lot], 'data')
        }
        this.requestPending = false
      })
  }

  getLotPublic (lotId) {
    if (this.requestPending) return
    this.requestPending = true
    Api.getLotPublic(lotId)
      .then(data => {
        if (data) {
          this.props.mergeStateProp('lots', [data.lot], 'data')
        }
        this.requestPending = false
      })
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.lot && prevProps.lot && this.props.lot._id !== prevProps.lot._id) {
      this.requestPending = false
    }
    if (this.props.loggedIn && this.props.lot && !this.props.lot.details) {
      this.getLot(this.props.lot._id)
    }
    if (!this.props.loggedIn && this.props.lot && !this.props.lot.details) {
      this.getLotPublic(this.props.lot._id)
    }
  }

  render () {
    const { lot, showInPopup, lotId } = this.props
    return (
      <div className={`info-container ${!lot ? 'no-lot' : ''}`}>
        {
          lot
            ? (
              <div className='content-container'>
                <div className='heading'>
                  <p className='heading-text'>Lot Details</p>
                  {
                    showInPopup
                      ? (<FontAwesomeIcon className='fa-times' icon={faTimes} onClick={() => (this.props.showingInPopup(false))} />)
                      : (<div className='popup-btn' onClick={() => (this.props.showingInPopup(true))}>
                        View in Pop Up
                      </div>)
                  }
                </div>
                <div className='carousel'>
                  <ImageCarousel showVideoIcon lot={lot} lotId={lotId} media={lot.media ? lot.media : []} numberOfSlides={1} />
                </div>
                {
                  lot.details
                    ? (
                      <DetailedInfo lot={lot} showDocuments />
                    )
                    : <div className='details-loader-wrap'>
                      <WanderingCubes className='details-loader' color={'gray'} />
                    </div>
                }
              </div>
            )
            : null
        }
      </div>
    )
  }
}

export default LotInfoComponent
