import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Big from 'big.js'

import { MAX_WEIGHT, FLOAT_REGEXP } from '../../../constants'

class WeightField extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      value: this.props.value,
      focused: false
    }
    this.onBlur = this.onBlur.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onChange = this.onChange.bind(this)
    this.keyDown = this.keyDown.bind(this)
    this.input = React.createRef()
  }
  componentDidMount () {
    document.addEventListener('keydown', this.keyDown)
  }
  componentWillUnmount () {
    document.removeEventListener('keydown', this.keyDown)
  }
  componentDidUpdate (prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value
      })
    }
    if (
      prevProps.activeSlideIndex !== this.props.activeSlideIndex &&
      this.props.slideIndex === this.props.activeSlideIndex
    ) {
      setTimeout(() => {
        if (this.input.current) this.input.current.focus()
      }, 700)
    }
  }
  keyDown (event) {
    if (this.state.focused && ((event.keyCode === 13 || event.key === 'Enter') || event.keyCode === 9)) {
      event.preventDefault()
      this.onBlur()
      this.props.moveToNext()
      this.input.current.blur()
    }
  }
  onChange () {
    let { value } = this.input.current
    if (FLOAT_REGEXP.test(value) || !value) {
      this.setState({ value })
    }
  }
  onFocus () {
    this.setState({ focused: true })
  }
  onBlur () {
    this.setState({ focused: false })
    let { value } = this.state
    try {
      value = parseFloat(Big(value).toFixed(2))
    } catch (e) { value = 0 }
    value = Math.min(MAX_WEIGHT, value)
    this.setState({ value })
    this.props.onChange(value)
  }
  render () {
    const { value } = this.state
    const { invalid, isRequired, noTab, title } = this.props
    return (
      <div className='form-row'>
        <div className='row-title'>
          <p
            className={`row-text ${invalid ? 'row-text-invalid' : ''}`}>
            {title}{isRequired ? <span className='asterisk'>*</span> : ''}:
          </p>
        </div>
        <div className='row-content'>
          <input
            ref={this.input}
            className='input'
            id='weight-input'
            type='text'
            value={value || ''}
            onChange={this.onChange}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            {...noTab ? { tabIndex: -1 } : {}}
          />
        </div>
      </div>
    )
  }
}

WeightField.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  invalid: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  noTab: PropTypes.bool.isRequired,
  moveToNext: PropTypes.func.isRequired,
  activeSlideIndex: PropTypes.number.isRequired,
  slideIndex: PropTypes.number.isRequired
}

WeightField.defaultProps = {
  invalid: false,
  noTab: false
}

export default WeightField
