import React, { PureComponent } from 'react'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import {
  faCheck,
  faTimes
} from '@fortawesome/fontawesome-free-solid'

import { limitTextLength } from '../../utils/index'
import Select from 'react-select'

class Modal extends PureComponent {
  /*
    this.props.data = {
      title: 'title',
      message: 'message',
      buttons: [
        {
          text: 'btn1',
          onPress: () => {}
        },
        {
          text: 'btn2',
          onPress: () => {}
        }
      ]
    }
  */
  componentDidUpdate (prevProps, prevState) {
    if (this.props.active) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }
  getButtons () {
    const { data: { buttons }, hideModal } = this.props
    return (buttons && buttons.length)
      ? (
        buttons.map((obj, i) => {
          return <div className='modal-button custom' key={i}
            onClick={() => {
              if (!obj.isNotClose) {
                hideModal()
              }
              obj.onPress && obj.onPress()
            }}>
            {obj.text}
            {
              obj.isSuccess &&
              <span className='icon-wrapper icon-success'>
                <FontAwesomeIcon icon={faCheck} />
              </span>
            }
            {
              obj.isCancel &&
              <span className='icon-wrapper icon-red'>
                <FontAwesomeIcon icon={faTimes} />
              </span>
            }
          </div>
        })
      )
      : (
        <div className='modal-button default' onClick={() => { hideModal() }}>OK</div>
      )
  }
  getDropdown () {
    const { data: { dropdown } } = this.props
    let values
    if (dropdown) {
      values = dropdown.values.map(item => {
        return {
          value: item._id,
          label: item.title
        }
      })
    }
    return (values)
      ? (
        <Select className='select-dropdown' options={values} onChange={dropdown.onChange} maxMenuHeight={100} />
      )
      : null
  }
  closeWindow = (e) =>{
   // e.preventDefault()
    this.props.hideModal()
  }
  render () {
    const { data: { title, message, dropdown, customBodyJSX, customClassName = '', topBar = false,showTAndC = false }, active } = this.props
    if (!active) {
      return <div />
    }
    if (dropdown) {
      return (
        <div className={`app-modal-overlay ${customClassName}`}>
          <div className='app-modal-1'>
            {title && <p className='title'>{limitTextLength(title, 250)}</p>}
            { topBar &&
            <div>
              <div className='dropdown'>
                {this.getDropdown()}
              </div>
              <div className='buttons'>
                {this.getButtons()}
              </div>
            </div>
            }
            <p className='message'>{message || 'Message'}</p>
            {customBodyJSX && customBodyJSX()}
            { !topBar &&
            <div>
              <div className='dropdown'>
                {this.getDropdown()}
              </div>
              <div className='buttons'>
                {this.getButtons()}
              </div>
            </div>
            }
          </div>
        </div>
      )
    } else {
      return (
        <div className={`app-modal-overlay ${customClassName}`}>
          <div className='app-modal'>
            {title && <p className='title'>{limitTextLength(title, 250)}</p>}
            { topBar &&
            <div>
              <div className='buttons'>
                {this.getButtons()}
              </div>
            </div>
            }
            <p className='message'>{message || 'Message'}</p>
            {showTAndC && 
                  <div className='row-title row-title-long'>
                  <p className='row-text'>
                    <Link  to='/main/terms-accept' target='_self' rel='noopener noreferrer'  onClick = {(e) => this.closeWindow(e)}>
                      Terms & Conditions
                    </Link>
                    {/* <span className='asterisk'>*</span>: */}
                  </p>
                </div>
            }

            {customBodyJSX && customBodyJSX()}
            { !topBar &&
            <div>
              <div className='dropdown'>
                {this.getDropdown()}
              </div>
              <div className='buttons'>
                {this.getButtons()}
              </div>
            </div>
            }
          </div>
        </div>
      )
    }
  }
}

export default Modal
