import React, { Component } from 'react'
import PropTypes from 'prop-types'

// import Checkbox from '../Checkbox/Checkbox'
import CheckboxWithValue from '../CheckboxWithValue/CheckboxWithValue'

export default class RadioButton extends Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.handleClick = this.handleClick.bind(this)
  }
  shouldComponentUpdate (nextProps, nextState) {
    return this.isSelected(this.props) !== this.isSelected(nextProps)
  }
  isSelected (props) {
    return props.selected === props.value
  }
  handleClick () {
    this.props.onSelection(this.props.value)
  }
  render () {
    return (
      <div className='rating-item'>
        <CheckboxWithValue
          type='green'
          value={this.isSelected(this.props)}
          label={this.props.value}
          onToggle={this.handleClick} />
        {this.props.label ? <span className='rating-value'>{this.props.label}</span> : ''}
      </div>
    )
  }
}

RadioButton.propTypes = {
  value: PropTypes.any.isRequired,
  label: PropTypes.string,
  selected: PropTypes.any.isRequired,
  onSelection: PropTypes.func.isRequired
}
