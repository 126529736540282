import { connect } from 'react-redux'
// import { bindActionCreators } from 'redux'

// import { showSpinner } from '../../actions'
import Tools from './Tools'

const mapStateToProps = (state) => {
  return {
    userType: state.user.data.type
  }
}

// const mapDispatchToProps = (dispatch) => {
//   return bindActionCreators({ showSpinner }, dispatch)
// }

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(Tools)
