import React, { PureComponent } from 'react'

import Api from '../../../services/api'
import Section from '../Section/Section'
import logOut from '../../../utils/logOut'

class VersionUpgradeTerms extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      terms: [],
      term_version: null
    }
  }

  componentDidMount () {
    window.scroll(0, 0)
    this.getData()
  }

  getData () {
    this.props.showSpinner(true)
    Api.getTerms()
      .then(res => {
        if (res) {
          this.setState({
            terms: this.convertData(res.terms),
            term_version: res.terms[0].version
          })
        }
        this.props.showSpinner(false)
      })
  }

  convertData (data) {
    return data.map(item => {
      return {
        categoryName: item.categoryName,
        terms: { text: item.content }
      }
    })
  }
  reDirectToHomePage = () => {
    this.props.history.push('/main/upcoming');
    window.location.reload();
  }
  acceptTerms = () =>{
    let {user} = this.props;
    //user.acceptedNewVersionTerms = true ;
    user.olderVersionsAcceptedTo.push(this.state.term_version);
    Api.putUser({acceptedNewVersionTerms : true,status : this.props.user.status,acceptedNewVersionTermsOn : new Date(),currentVersionAcceptedTo : this.state.term_version, olderVersionsAcceptedTo : user.olderVersionsAcceptedTo}).then((userData) => {
      this.props.showSpinner(false)
      this.props.changeStateProp('data', userData.user, 'user')
      this.reDirectToHomePage()
    }).catch((error) => {
      this.props.showSpinner(false)
      console.error(error)
    })
  }
  denyTerms = () =>{
    logOut.init()
  }
  render () {
    const { terms } = this.state
    return (
      <div className='conditions'>
        <h1 className='fg-header'>Terms & Conditions</h1>
        <div className='terms-container'>
          {
            terms.map((item, index) => {
              return <Section key={index} title={item.categoryName} text={[item.terms]} />
            })
          }
        </div>
        <div className='terms-button-container' >
          <div className='terms-accept-btn'onClick={ this.acceptTerms} >
            Accept
          </div>
          <div className='terms-accept-btn'onClick={ this.denyTerms} >
            Deny
          </div>
        </div>
    </div>
    )
  }
}

export default VersionUpgradeTerms
