import React, { Component } from 'react'
import memoize from 'memoize-one'
import Big from 'big.js'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faLock
} from '@fortawesome/fontawesome-free-solid'

import {
  bidTypes,
  NUMBER_REGEXP,
  FLOAT_REGEXP
} from '../../../constants'
import * as LotUtils from '../../../utils/Lots'
import {
  getBidIncrement,
  getBidResult,
  statusClassName,
  getInputPlaceholder,
  calculateBid
} from '../../../utils/Bidding'
import * as Socket from '../../../services/socket'
import Api from '../../../services/api'

import StateTimerLotContainer from '../../StateTimerLot/StateTimerLotContainer'
import { Circle } from 'better-react-spinkit'

const MAX_STEP_LENGTH = 9
const MAX_SUM_LENGTH = 9

class BiddingComponent extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      lock: true,
      bidValue: '',
      currentStatus: this.getStatus(),
      currentFormatDefault: true
    }
    this.bidding = false
    this.biddingTimeout = null
    this.biddingThrottleMs = 800
    this.maxBiddingThrottleMs = 5000
    this.onChangeAmount = this.onChangeAmount.bind(this)
    this.resetAmount = this.resetAmount.bind(this)
    this.bidInput = React.createRef()
    this.isEnding = memoize(this.isEnding)
    this.updateStatus = this.updateStatus.bind(this)
    this.changeBiddingType = this.changeBiddingType.bind(this)
    this.setAutobid = this.setAutobid.bind(this)
    this.stopAutobid = this.stopAutobid.bind(this)
    this.handleLockState = this.handleLockState.bind(this)
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.lot) {
      this.updateStatus()
      this.checkStatusOfPriceFormat()
    }
    if (this.props.lot && prevProps.lot) {
      if (prevProps.lot._id !== this.props.lot._id) {
        this.resetAmount()
        this.lockBidding()
        this.checkStatusOfPriceFormat()
      }
    }
  }

  componentWillUnmount () {
    clearTimeout(this.biddingTimeout)
  }

  checkStatusOfPriceFormat = () => {
    let { auctionLiveLotsFormat, lot } = this.props
    let currentLot = auctionLiveLotsFormat.find((lotFormatted) => {
      return lotFormatted._id === lot._id
    })
    if (currentLot) {
      if (currentLot.currentFormatDefault !== this.state.currentFormatDefault) {
        this.setState({
          currentFormatDefault: currentLot.currentFormatDefault
        })
      }
    } else {
      let newArr = [...auctionLiveLotsFormat]
      newArr.push({ _id: lot._id, currentFormatDefault: true })
      this.props.changeStateProp('auctionLiveLotsFormat', newArr, 'temp')
    }
  }

  toggleStatusOfPriceFormat = () => {
    let { auctionLiveLotsFormat, lot } = this.props
    let newArr = [...auctionLiveLotsFormat]
    newArr.forEach((lotFormatted) => {
      if (lotFormatted._id === lot._id) {
        lotFormatted.currentFormatDefault = !lotFormatted.currentFormatDefault
      }
    })
    this.props.changeStateProp('auctionLiveLotsFormat', newArr, 'temp')
    this.checkStatusOfPriceFormat()
  }

  isPriceSwapped () {
    let { biddingSwapLots, lot } = this.props
    return biddingSwapLots.includes(lot._id)
  }

  isUserBidding () {
    return this.state.loading || this.bidding
  }

  startUserBidding () {
    this.bidding = true
    this.setState({ loading: true }, () => {
      setTimeout(() => { this.bidding = false }, this.biddingThrottleMs)
      this.biddingTimeout = setTimeout(() => {
        if (this.isUserBidding()) {
          this.stopUserBidding()
        }
      }, this.maxBiddingThrottleMs)
    })
  }

  stopUserBidding () {
    clearTimeout(this.biddingTimeout)
    this.setState({ loading: false })
  }

  getMyBid () {
    return LotUtils.getMyBid(this.props.lot, this.props.user.bids)
  }

  getMyAutobid () {
    if (this.props.lot && this.props.user) {
      return LotUtils.getMyAutobid(this.props.lot, this.props.user.autobids)
    }
    return null
  }

  getPrice () {
    let { lot } = this.props
    const { HEAD, KG } = bidTypes
    let isSwapped = this.isPriceSwapped()
    let formattedPrice, measure
    switch (true) {
      case lot.bidding === HEAD && !isSwapped:
      default:
        formattedPrice = LotUtils.makePrice(lot.currentBidCents, { bidding: bidTypes.HEAD })
        measure = 'head'
        break
      case lot.bidding === HEAD && isSwapped:
        formattedPrice = LotUtils.makePrice(LotUtils.convertDollarToCentsPrice(lot.currentBidCents, lot), { bidding: bidTypes.KG })
        measure = 'kg'
        break
      case lot.bidding === KG && !isSwapped:
        formattedPrice = LotUtils.makePrice(lot.currentBidCents, { bidding: bidTypes.KG })
        measure = 'kg'
        break
      case lot.bidding === KG && isSwapped:
        formattedPrice = LotUtils.makePrice(LotUtils.convertCentsToDollarPrice(lot.currentBidCents, lot), { bidding: bidTypes.HEAD })
        measure = 'head'
        break
    }
    return (
      <React.Fragment>
        <span>{formattedPrice}</span>
        <span className='price-measure'>/{measure}</span>
      </React.Fragment>
    )
  }

  bid (cents) {
    const { lot, auction } = this.props
    if (this.isUserBidding()) {
      return
    }
    const frozen = auction.auctionType === 'synchronous' ? auction.frozenTime : lot.frozenTime
    if (frozen) {
      if (auction.auctionType !== 'sequentialOffline') {
        this.props.showModal({
          title: 'FarmGate',
          message: 'This lot is frozen'
        })
      } else {
        this.props.showModal({
          title: 'FarmGate',
          message: 'Waiting for bidding to start'
        })
      }
      return
    }
    if (lot.state === 'future' || lot.state === 'open') {
      this.props.showModal({
        title: 'FarmGate',
        message: 'Auction is open for Auto-Bids. You can only use the bid buttons during a Live Auction.'
      })
      return
    }
    if(lot.reserveStatus === "onMarket" && lot.winner === this.props.user.data._id) {
      this.props.showModal({
        message: 'You are bidding against yourself, do you still want to register the bid ?',
        buttons: [
          {
            text: 'Cancel',
            onPress: () => {
              return
            }
          },
          {
            text: 'Ok',
            onPress: () => {
            this.startUserBidding()

            Socket.emit(Socket.BIDS_NAMESPACE, 'bid', {
              bid: {
                lot: lot._id,
                cents: cents
              }
            }, (e) => {
              this.stopUserBidding()
              if (e.hasOwnProperty('message')) {
                this.props.showModal({
                  title: 'Bid error',
                  message: e.hasOwnProperty('message') ? e.message.toString() : e.toString()
                })
                // get actual lot
                this.updateLot()
              }
            })
            }
          }
        ]
      })
    } else {
      this.startUserBidding()

      Socket.emit(Socket.BIDS_NAMESPACE, 'bid', {
        bid: {
          lot: lot._id,
          cents: cents
        }
      }, (e) => {
        this.stopUserBidding()
        if (e.hasOwnProperty('message')) {
          this.props.showModal({
            title: 'Bid error',
            message: e.hasOwnProperty('message') ? e.message.toString() : e.toString()
          })
          // get actual lot
          this.updateLot()
        }
      })
    }
  }

  async setAutobid () {
    if (this.isUserBidding()) {
      return
    }
    const { lot } = this.props
    const { HEAD, KG } = bidTypes
    if (lot.state === 'open' || lot.state === 'live' || lot.state === 'futureInLive') {
      let bidCents
      try {
        switch (true) {
          case lot.bidding === KG:
            bidCents = parseInt(this.state.bidValue, 10)
            break
          case lot.bidding === HEAD:
            bidCents = parseInt(Big(this.state.bidValue).times(100), 10)
            break
          default:
            break
        }
        if (!bidCents) {
          throw new Error()
        }
        if (bidCents <= lot.currentBidCents) {
          let message = this.props.auction.state === 'open' ? 'Please enter an Auto-Bid higher than the start price.' : 'Please enter an Auto-Bid higher than the current bid.'
          this.props.showModal({
            title: 'FarmGate',
            message
          })
          return
        }
        if ((bidCents - lot.currentBidCents) % lot.bidIncrementCents) {
          this.props.showModal({
            title: 'FarmGate',
            message: `Please Enter a number that is a multiple of ${LotUtils.makePrice(lot.bidIncrementCents, { bidding: lot.bidding })}`
          })
          return
        }
      } catch (e) {
        this.props.showModal({
          title: 'FarmGate',
          message: 'Invalid price'
        })
        return
      }

      this.props.showModal({
        title: 'Auto Bid',
        message: 'Are you sure you want to set Auto Bid?',
        buttons: [
          {
            text: 'OK',
            onPress: async () => {
              this.startUserBidding()
              let res = await Api.setAutobid({
                cents: bidCents,
                lot: lot._id
              })
              if (res) {
                this.props.showModal({
                  title: 'FarmGate',
                  message: `Your Auto Bid was set to ${LotUtils.makePrice(res.autobid.cents, { bidding: lot.bidding })}`
                })
                this.updateMyAutobid(res.autobid)
              }
              this.stopUserBidding()
              this.resetAmount()
            }
          },
          {
            text: 'Cancel'
          }
        ]
      })
    } else if (lot.state === 'closed') {
      this.props.showModal({
        title: 'FarmGate',
        message: 'No further bids. Lot is sold'
      })
      this.resetAmount()
    } else {
      this.props.showModal({
        title: 'FarmGate',
        message: 'You can\'t place a bid. Auction is not open yet'
      })
      this.resetAmount()
    }
  }

  handleLockState () {
    let { lot, auction } = this.props
    if (!this.props.user || !this.props.user.data || !this.props.user.data.status) {
      this.props.showModal({
        message: 'Please login or register to Bid in a Live Auction',
        buttons: [
          {
            text: 'Login',
            onPress: () => {
              this.props.history.push('/sign-in')
            }
          },
          {
            text: 'Register',
            onPress: () => {
              this.props.history.push('/sign-up')
            }
          },
          {
            text: 'Cancel'
          }
        ]
      })
      return
    }

    if (this.props.user.data.status === 'approved-seller' || this.props.user.data.status ===  'registered' || this.props.user.data.status === 'registered-updated') {
      this.props.showModal({
        message: 'You do not have permission to buy in this auction. For access, please contact support +61 2 8252 6840 or support@farmgateauctions.com.au',
        buttons: [
          {
            text: 'Ok'
          }
        ]
      })
      return
    }
    if (lot.state === 'futureInLive' && !auction.allowUpcomingBid) {
      this.props.showModal({
        title: 'FarmGate',
        message: 'Bidding for Lot not open yet'
      })
      return
    }
    if (lot.state === 'open' && !auction.allowAutoBid) {
      this.props.showModal({
        title: 'FarmGate',
        message: 'Bidding for Lot not open yet'
      })
      return
    }
    if (lot.frozenTime) {
      if (auction.auctionType !== 'sequentialOffline') {
        this.props.showModal({
          title: 'FarmGate',
          message: 'This lot is frozen'
        })
      } else {
        this.props.showModal({
          title: 'FarmGate',
          message: 'Waiting for bidding to start'
        })
      }
      return
    }
    if (this.props.auction.frozenTime) {
      if (auction.auctionType !== 'sequentialOffline') {
        this.props.showModal({
          title: 'FarmGate',
          message: 'Auction is frozen'
        })
      } else {
        this.props.showModal({
          title: 'FarmGate',
          message: 'Waiting for bidding to start'
        })
      }
      return
    }
    if (lot.state === 'closed') {
      this.props.showModal({
        title: 'FarmGate',
        message: 'No further bids. Lot is sold'
      })
      return
    }
    this.changeState('lock', false)
  }

  onChangeAmount () {
    let { value } = this.bidInput.current
    let regex
    const { lot } = this.props
    const { HEAD, KG } = bidTypes
    switch (true) {
      case lot.bidding === KG:
        regex = NUMBER_REGEXP
        break
      case lot.bidding === HEAD:
        regex = FLOAT_REGEXP
        break
      default:
        break
    }
    if (regex && (regex.test(value) || !value)) {
      this.setState({ bidValue: value })
    }
  }

  resetAmount () {
    this.setState({ bidValue: '' })
  }

  lockBidding () {
    this.setState({ lock: true })
  }

  updateMyBid (bid) {
    this.props.mergeStateProp('bids', [bid], 'user')
  }

  updateMyAutobid (autobid) {
    this.props.mergeStateProp('autobids', [autobid], 'user')
  }

  async updateLot () {
    if (this.props.lot) {
      let res = await Api.getLot(this.props.lot._id)
      if (res) {
        this.props.mergeStateProp('lots', [res.lot], 'data')
      }
    }
  }

  getBiddingStatus ({ lot, auction }) {
    if (lot.state === 'closed' || lot.withdrawn) {
      return <span className='lot-status closed'>{LotUtils.getResultMessage(lot, this.props.user.data ? this.props.user.data._id : '', auction)}</span>
    }
    if (lot.state === 'futureInLive') {
      return (
        <div className='status-box'>
          <span className={`lot-status`}>UPCOMING</span>
        </div>
      )
    }
    if (lot.state !== 'live') {
      return null
    }
    if (this.isEnding(this.state.currentStatus) && this.props.auction.auctionType !== 'sequentialOffline' && !this.props.auction.auctionpaused && !this.props.auction.frozenTime) {
      return <React.Fragment>
        <span className='lot-status ending'>Lot Closing</span>
        {lot.reserveStatus === 'awaitingBids' &&
        <span className={`lot-status awaiting closing info`}>{this.checkStatus('Awaiting Bids')}</span>
        }
        {lot.reserveStatus === 'nearReserve' &&
        <span className={`lot-status nearing closing info`}>{this.checkStatus('Nearing Reserve')}</span>
        }
        {lot.reserveStatus === 'onMarket' &&
        <span className={`lot-status on-market closing info`}>{this.checkStatus('On Market')}</span>
        }
        {lot.reserveStatus === 'notNear' &&
        <span className={`lot-status open closing info`}>{this.checkStatus('Opening Bids')}</span>
        }
      </React.Fragment>
    }
    switch (lot.reserveStatus) {
      case 'awaitingBids':
        return <span className={`lot-status ${this.props.auction && this.props.auction.auctionType !== 'sequentialOffline' ? 'awaiting' : ''}`}>{this.checkStatus('Awaiting Bids')}</span>
      case 'nearReserve':
        return <span className={`lot-status ${this.props.auction && this.props.auction.auctionType !== 'sequentialOffline' ? 'nearing' : ''} info`}>{this.checkStatus('Nearing Reserve')}</span>
      case 'onMarket':
        return <span className={`lot-status ${this.props.auction && this.props.auction.auctionType !== 'sequentialOffline' ? 'on-market' : ''}`}>{this.checkStatus('On Market')}</span>
      case 'notNear':
      default:
        return <span className={`lot-status ${this.props.auction && this.props.auction.auctionType !== 'sequentialOffline' ? 'open' : ''}`}>{this.checkStatus('Opening Bids')}</span>
    }
  }

  checkStatus = (status) => this.props.auction.auctionType === 'sequentialOffline' ? 'LIVE' : status

  isEnding (currentStatus) {
    return currentStatus.indexOf('ending') !== -1
  }

  getStatus () {
    if (!this.props.lot || !this.props.auction) {
      return ''
    }
    return LotUtils.getRunningStatus(this.props.lot, this.props.auction, this.props.user.data ? this.props.user.data._id : '')
  }

  updateStatus () {
    let status = this.getStatus()
    if (this.state.currentStatus !== status) {
      this.setState({
        currentStatus: status
      })
    }
  }

  getOtherIncrement () {
    let { lot } = this.props
    const { HEAD, KG } = bidTypes
    let isSwapped = this.isPriceSwapped()
    switch (true) {
      case lot.bidding === HEAD && !isSwapped:
      case lot.bidding === KG && isSwapped:
        return '¢/kg'
      case lot.bidding === HEAD && isSwapped:
      case lot.bidding === KG && !isSwapped:
      default:
        return '$/head'
    }
  }

  changeState (field, value) {
    this.setState({
      [field]: value
    })
  }

  async stopAutobid () {
    let res = await Api.stopAutobid({
      lot: this.props.lot._id
    })
    if (res && !res.isError) {
      this.updateMyAutobid(res.autobid)
    } else {
      this.props.showModal({
        title: 'FarmGate',
        message: 'Could not stop Auto Bid'
      })
    }
  }

  changeBiddingType () {
    let { biddingSwapLots, lot } = this.props
    let newSwapLots
    if (this.isPriceSwapped()) {
      newSwapLots = biddingSwapLots.filter(id => id !== lot._id)
    } else {
      newSwapLots = biddingSwapLots.concat(lot._id)
    }
    this.props.changeStateProp('biddingSwapLots', newSwapLots, 'temp')
  }

  renderAutobid () {
    let { lot } = this.props
    let myAutobid = this.getMyAutobid()
    if (myAutobid && myAutobid.active) {
      return (
        <div className='row auto-bid active'>
          <span className='left'>AUTO-BID ACTIVE<br />SET AT {LotUtils.makePrice(myAutobid.cents, { bidding: lot.bidding })}</span>
          <div className='bid-buttons'>
            <div className='bid-button' onClick={this.stopAutobid}>
              STOP
            </div>
          </div>
        </div>
      )
    }
    return (
      <div className='row auto-bid'>
        <span className='left'>AUTO-BID</span>
        <div className='bid-buttons'>
          <input
            type='text' className='bid-input'
            onFocus={(element) => this.resetAmount(element)}
            value={this.state.bidValue}
            onChange={this.onChangeAmount}
            ref={this.bidInput}
            placeholder={getInputPlaceholder(lot)}
          />
          <div className='bid-button' onClick={this.setAutobid}>
            Set
          </div>
        </div>
      </div>
    )
  }

  getClass (type, coefficient) {
    const { lot } = this.props
    let className = type
    switch (type) {
      case 'step':
        if (getBidIncrement(coefficient, lot).length > MAX_STEP_LENGTH) {
          className = 'big-step'
        }
        break
      case 'sum':
        if (getBidResult(coefficient, lot).length > MAX_SUM_LENGTH) {
          className = 'big-sum'
        }
        break
      default:
        break
    }
    return className
  }

  getBidButton (x) {
    const { lot } = this.props
    return (
      <div className='bid-button' onClick={() => this.bid(calculateBid(x, lot))}>
        <span className={this.getClass('step', x)}>{getBidIncrement(x, lot)}</span>
        <span className={this.getClass('sum', x)}>{getBidResult(x, lot)}</span>
      </div>
    )
  }

  renderPrice (lot) {
    if (!['Cow(s)', 'Cow(s) and Calves', 'Bull(s)'].includes(lot.publicDetails.sex) &&
      lot.kindData.type === 'cattle' &&
      lot.bidding === bidTypes.HEAD) {
      if (this.state.currentFormatDefault) {
        return this.getPrice()
      } else {
        return `${LotUtils.makePrice(lot.currentBidCents / lot.publicDetails.weight.curfew, { bidding: bidTypes.KG })}/kg`
      }
    } else {
      return this.getPrice()
    }
  }

  render () {
    let { lot, auction } = this.props
    const { currentStatus, lock } = this.state
    const lotState = lot ? lot.withdrawn ? 'closed' : lot.state : null
    const isEnding = this.isEnding(currentStatus)
    let statusClass
    let resultMessage
    if (lot) {
      statusClass = statusClassName(currentStatus)
      resultMessage = LotUtils.getResultMessage(lot, this.props.user.data ? this.props.user.data._id : '', auction)
    }
    const myAutobid = this.getMyAutobid()
    const lockCondition = lock && (!myAutobid || (myAutobid && !myAutobid.active))
    const userDataExist = this.props.user && this.props.user.data && this.props.user.data.firstName
    const hideTimer = auction.auctionType === 'sequentialOffline'

    return (
      <div className={`bidding-container`}>
        <div
          className={`${lotState === 'closed' ? 'result' : ''} ${resultMessage === 'won' ? 'won' : resultMessage === 'lost' ? 'lost' : ''}`}>
          {resultMessage}
        </div>
        {
          lot ? (
            // <div className={`content-container ${lot.state === 'futureInLive' ? '' : statusClass} ${lotState === 'closed' ? 'bidding-closed' : ''}`}>
            <div className={`content-container ${statusClass} ${lotState === 'closed' ? 'bidding-closed' : ''}`}>
              <div className='row header'>
                <span className='header-lot-number'>Lot {lot.number}</span>
                <span className='location'>{lot.publicDetails.location.town}, {lot.publicDetails.location.state}</span>
              </div>
              <div className='row info'>
                <span className='lot-title'>{lot.publicDetails.title}</span>
                <span className='lot-title'>{`${lot.publicDetails.summary.age} ${lot.additionalTitle}`}</span>
                <div className='lot-title-weight'>
                  <span className='lot-title'>{lot.optiweighAllowed && LotUtils.calculateAverageDailyWeightGain(lot) ? `${parseFloat(LotUtils.calculateAverageDailyWeightGain(lot).toFixed(2))}kg Avdwtg` : lot.publicDetails.summary.weight}</span>
                  <span className='lot-title'>{`${Math.round(lot.publicDetails.weight.curfew)}kg ${lot.adjustWeight === 0 && lot.optiweighAllowed ? 'Live/wt' : 'Cur/wt'}`}</span>
                </div>
                <div className='price'>
                  {this.renderPrice(lot)}
                </div>
                {!['Cow(s)', 'Cow(s) and Calves', 'Bull(s)'].includes(lot.publicDetails.sex) && lot.kindData.type === 'cattle' && lot.bidding === bidTypes.HEAD &&
                <div className='cents-price' onClick={() => {
                  this.toggleStatusOfPriceFormat()
                }}>
                  {this.state.currentFormatDefault ? `${LotUtils.makePrice(lot.currentBidCents / lot.publicDetails.weight.curfew, { bidding: bidTypes.KG })}/kg` : this.getPrice()}
                </div>}
              </div>
              <div className='overlay-container'>
                { this.state.loading && (
                  <div className='app-spinner-overlay area'>
                    <div className='app-spinner area'>
                      <Circle size={60} />
                    </div>
                  </div>
                )}
                <div className={`row status ${isEnding && this.props.auction.auctionType !== 'sequentialOffline' && this.props.auction.auctionType !== 'synchronous' && !this.props.auction.auctionpaused && !this.props.auction.frozenTime ? 'ending' : ''}`}>
                  <span>
                    {!hideTimer &&
                    <StateTimerLotContainer
                      lotId={lot._id}
                      updateTimeCallback={this.updateStatus}
                      auction={auction}
                      offsetMs={this.props.offsetMs}
                    />}
                  </span>
                  {this.getBiddingStatus({ lot, auction })}
                </div>
                {
                  (lockCondition || !userDataExist)
                    ? (
                      <div className='lock-bids' onClick={this.handleLockState}>
                        <FontAwesomeIcon icon={faLock} />
                        <p>CLICK TO BID</p>
                      </div>
                    )
                    : userDataExist && (
                      <React.Fragment>
                        <div className='row bid'>
                          <span className='left'>BID</span>
                          <div className='bid-buttons'>
                            {this.getBidButton(1)}
                            {this.getBidButton(2)}
                            {this.getBidButton(5)}
                            {this.getBidButton(10)}
                          </div>
                        </div>
                        {auction.allowAutoBid && this.renderAutobid()}
                      </React.Fragment>
                    )
                }
              </div>
            </div>
          ) : (
            <div className={`content-container no-lot`}>
              <div className='row no-lot-header'>
                <span>Please Select a Lot</span>
              </div>
            </div>
          )
        }
      </div>
    )
  }
}

export default BiddingComponent
