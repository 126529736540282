import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import { pick as _pick } from 'lodash'
import Api from '../../services/api'
import ChangePassModalContainer from '../Modals/ChangePassModalContainer'
import { isBuyer, isGuest, isSeller } from '../../utils/UpdateData'
import {
  validateEmail,
  validatePic,
  validateAbn,
  validateTradingName,
  validatePhone,
  validateSecondaryPhone,
  validateTown,
  validatePostcode,
  validateLastName,
  validateFirstName,
  validateState,
  validateTradingType,
  validateAccountName,
  validateAccountNumber,
  validateBsb,
  validateOtherOptionType,
  validateLegalNameOfTradingEntityName,
  validateCompanyName,
  validateGivenName,
  validateAcn
} from '../../utils/Validators'
import {
  PIC_REGEXP_SOFT,
  ABN_REGEXP_SOFT,
  ACN_REGEXP_SOFT,
  AUSTRALIAN_POSTCODE_SOFT,
  FIRST_LAST_NAME,
  NUMBER_REGEXP, ADDRESS,
  ACCOUNT_NAME_NUMBER,
  BSB
} from '../../constants'
import { convertData } from '../../utils/convertData'
import ContactDetails from './Parts/ContactDetails'
import BusinessDetails from './Parts/BusinessDetails'
import AddressDetails from './Parts/AddressDetails'
import ProfileHeader from './Parts/ProfileHeader'
// import BankDetails from './Parts/BankDetails'
import StockTypes from './Parts/StockTypes'
import CustomerDetails from './Parts/CustomerDetails'

class Profile extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      ...this.restoredValues(),
      anotherState: !!this.props.user.anotherStatePIC,
      same: false,
      edit: false,
      isModalOpen: false,
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleAddressChange = this.handleAddressChange.bind(this)
    this.cancel = this.cancel.bind(this)
    this.handlePasswordChange = this.handlePasswordChange.bind(this)
    this.clearPassword = this.clearPassword.bind(this)
    this.edit = this.edit.bind(this)
    this.handleBankDetailsChange = this.handleBankDetailsChange.bind(this)
    this.handleCustomerDetailsChange = this.handleCustomerDetailsChange.bind(this)
    this.handleTradingTypes = this.handleTradingTypes.bind(this)
    this.handleYesorNoDropdown = this.handleYesorNoDropdown.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.handleCheckbox = this.handleCheckbox.bind(this)
    this.uploadLogo = this.uploadLogo.bind(this)
    this.selectFile = this.selectFile.bind(this)
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.handlePICState = this.handlePICState.bind(this)
    this.handleMultipleCheckbox = this.handleMultipleCheckbox.bind(this)

    this.mapStates = this.mapOptions(this.props.locationStates)
  }

  async componentDidMount () {
    this.checkValidation()

    let profession = await Api.getUserOperations();
    if(profession){
      this.setState({
        form: update(this.state.form, {
          userOperationsTypes: { $set: profession }
        })
      })
    }
  }

  restoredValues () {
    let updateObj
    if (isSeller()) {
      updateObj = {
        form: {
          firstName: this.defaultFieldValue('firstName'),
          lastName: this.defaultFieldValue('lastName'),
          email: this.defaultFieldValue('email'),
          phone: this.defaultFieldValue('phone'),
          secondaryPhone: this.defaultFieldValue('secondaryPhone'),
          PIC: this.defaultFieldValue('PIC'),
          anotherStatePIC: this.defaultFieldValue('anotherStatePIC'),
          ABN: this.defaultFieldValue('ABN'),
          tradingName: this.defaultFieldValue('tradingName'),
          customerDetails : {
            companyName : this.defaultFieldValue('companyName','customerDetails'),
            ACN : this.defaultFieldValue('ACN','customerDetails'),
            givenName : this.defaultFieldValue('givenName','customerDetails'),
            lastName : this.defaultFieldValue('lastName','customerDetails'),
            legalNameOfTradingEntity : this.defaultFieldValue('legalNameOfTradingEntity','customerDetails'),
          },
          profession: this.defaultFieldValue('profession'),
          jobTitle: this.defaultFieldValue('jobTitle'),
          SSPermitNumberOfYears: this.defaultFieldValue('SSPermitNumberOfYears'),
          saleConditions: this.defaultFieldValue('saleConditions'),
          SSPermitNumber: this.defaultFieldValue('SSPermitNumber'),
          sellerType: this.defaultFieldValue('sellerType'),
          agencyName: this.defaultFieldValue('agencyName'),
          doYouTradeThroughCompany : this.defaultFieldValue('doYouTradeThroughCompany'),
          yearsInIndustry: this.defaultFieldValue('yearsInIndustry'),
          userOperationsTypes: null,
          /*bankDetails: {
            accountName: this.defaultFieldValue('accountName', 'bankDetails'),
            accountNumber: this.defaultFieldValue('accountNumber', 'bankDetails'),
            BSB: this.defaultFieldValue('BSB', 'bankDetails')
          },*/
          stockTypes: this.defaultFieldValue('stockTypes'),
          otherProfession: this.defaultFieldValue('otherProfession'),
          officeAddress: {
            address1: this.defaultFieldValue('address1', 'officeAddress'),
            address2: this.defaultFieldValue('address2', 'officeAddress'),
            town: this.defaultFieldValue('town', 'officeAddress'),
            state: this.defaultFieldValue('state', 'officeAddress'),
            postcode: this.defaultFieldValue('postcode', 'officeAddress')
          },
          postalAddress: {
            address1: this.defaultFieldValue('address1', 'postalAddress'),
            address2: this.defaultFieldValue('address2', 'postalAddress'),
            town: this.defaultFieldValue('town', 'postalAddress'),
            state: this.defaultFieldValue('state', 'postalAddress'),
            postcode: this.defaultFieldValue('postcode', 'postalAddress')
          }
        },
        logo: this.defaultFieldValue('logo')
      }
    } else if (isBuyer()){
      updateObj = {
        form: {
          firstName: this.defaultFieldValue('firstName'),
          lastName: this.defaultFieldValue('lastName'),
          email: this.defaultFieldValue('email'),
          phone: this.defaultFieldValue('phone'),
          secondaryPhone: this.defaultFieldValue('secondaryPhone'),
          PIC: this.defaultFieldValue('PIC'),
          anotherStatePIC: this.defaultFieldValue('anotherStatePIC'),
          ABN: this.defaultFieldValue('ABN'),
          tradingName: this.defaultFieldValue('tradingName'),
          customerDetails: {
            companyName : this.defaultFieldValue('companyName','customerDetails'),
            ACN : this.defaultFieldValue('ACN','customerDetails'),
            givenName : this.defaultFieldValue('givenName','customerDetails'),
            lastName : this.defaultFieldValue('lastName','customerDetails'),
            legalNameOfTradingEntity : this.defaultFieldValue('legalNameOfTradingEntity','customerDetails'),
          },
          profession: this.defaultFieldValue('profession'),
          sellerType: this.defaultFieldValue('sellerType'),
          agencyName: this.defaultFieldValue('agencyName'),
          doYouTradeThroughCompany : this.defaultFieldValue('doYouTradeThroughCompany'),
          userOperationsTypes: null,
          /*bankDetails: {
            accountName: this.defaultFieldValue('accountName', 'bankDetails'),
            accountNumber: this.defaultFieldValue('accountNumber', 'bankDetails'),
            BSB: this.defaultFieldValue('BSB', 'bankDetails')
          },*/
          otherProfession: this.defaultFieldValue('otherProfession'),
          officeAddress: {
            address1: this.defaultFieldValue('address1', 'officeAddress'),
            address2: this.defaultFieldValue('address2', 'officeAddress'),
            town: this.defaultFieldValue('town', 'officeAddress'),
            state: this.defaultFieldValue('state', 'officeAddress'),
            postcode: this.defaultFieldValue('postcode', 'officeAddress')
          },
          postalAddress: {
            address1: this.defaultFieldValue('address1', 'postalAddress'),
            address2: this.defaultFieldValue('address2', 'postalAddress'),
            town: this.defaultFieldValue('town', 'postalAddress'),
            state: this.defaultFieldValue('state', 'postalAddress'),
            postcode: this.defaultFieldValue('postcode', 'postalAddress')
          }
        },
        logo: this.defaultFieldValue('logo')
      }
    }else {
      updateObj = {
        form: {
          firstName: this.defaultFieldValue('firstName'),
          lastName: this.defaultFieldValue('lastName'),
          email: this.defaultFieldValue('email'),
          phone: this.defaultFieldValue('phone'),
          secondaryPhone: this.defaultFieldValue('secondaryPhone'),
          PIC: this.defaultFieldValue('PIC'),
          anotherStatePIC: this.defaultFieldValue('anotherStatePIC'),
          ABN: this.defaultFieldValue('ABN'),
          tradingName: this.defaultFieldValue('tradingName'),
          profession: this.defaultFieldValue('profession'),
          agentName: this.defaultFieldValue('agentName'),
          otherProfession: this.defaultFieldValue('otherProfession'),
          userOperationsTypes: null,
          officeAddress: {
            address1: this.defaultFieldValue('address1', 'officeAddress'),
            address2: this.defaultFieldValue('address2', 'officeAddress'),
            town: this.defaultFieldValue('town', 'officeAddress'),
            state: this.defaultFieldValue('state', 'officeAddress'),
            postcode: this.defaultFieldValue('postcode', 'officeAddress')
          },
          postalAddress: {
            address1: this.defaultFieldValue('address1', 'postalAddress'),
            address2: this.defaultFieldValue('address2', 'postalAddress'),
            town: this.defaultFieldValue('town', 'postalAddress'),
            state: this.defaultFieldValue('state', 'postalAddress'),
            postcode: this.defaultFieldValue('postcode', 'postalAddress')
          }
        },
        logo: this.defaultFieldValue('logo')
      }
    }
    return updateObj
  }

 

  defaultFieldValue (type, addressType) {
    switch (type) {
      case 'licenseFile':
        return this.props.user.licenseFile
      case 'policyFile':
        return this.props.user.policyFile
      case 'logo':
        return this.props.user.logo
      case 'phone':
      case 'secondaryPhone':
      case 'agentPhone':
        return {
          value: addressType ? this.props.user[addressType][type] : (this.props.user[type] || ''),
          valid: true
        }

      case 'doYouTradeThroughCompany':
          return {
            value: this.props.user[type] ? 'Yes' : 'No',
            valid: true
          }
        // case 'profession':
        //   return {
        //     value: this.props.user[type] || 'Livestock Agent',
        //     valid: true
        //   }
      default:
        if (addressType) {
          return {
            value: this.props.user[addressType][type] || '',
            valid: true
          }
        } else {
          return {
            value: this.props.user[type] || '',
            valid: true
          }
        }
    }
  }


  handleTradingTypes (type, option, { action }) {
    if (action !== 'select-option') {
      return
    }
    
    this.setState({
      form: update(this.state.form, {
        [type]: {
          value: { $set: option.value }
        }
      })
    }, () => this.validate(option))
  }

  handleYesorNoDropdown(type, option, { action }) {
    if (action !== 'select-option') {
      return
    }
    this.setState({
      form: update(this.state.form, {
        [type]: {
          value: { $set: option.value }
        }
      })
    }, () => this.validate(option))
  }

  handleMultipleCheckbox(name) {
    let arr = [...this.state.form.stockTypes.value]
    let index = arr.indexOf(name)
    if(this.state.form.stockTypes.value.includes(name)){
      this.setState({stockTypes: this.state.form.stockTypes.value.splice(index, 1)})
    } else{
      this.setState({stockTypes: this.state.form.stockTypes.value.push(name)})
    }
}

  handleInputChange (event) {
    let { target: { name, value } } = event
    switch (name) {
      case 'firstName':
      case 'lastName':
      case 'agentName':
      case 'tradingName':
      case 'jobTitle':
      case 'agentTradingName':
      case 'otherProfession':  
        if (value && !FIRST_LAST_NAME.test(value)) {
          return
        } else {
          value = value.slice(0, 1).toUpperCase() + value.slice(1)
        }
        break
      case 'phone':
      case 'secondaryPhone':
      case 'agentPhone':
        if (value && !NUMBER_REGEXP.test(value)) {
          return
        }
        break
      case 'PIC':
        value = value.toUpperCase()
        if (value && !PIC_REGEXP_SOFT.test(value)) {
          return
        }
        break
      case 'ABN':
        if (value && !ABN_REGEXP_SOFT.test(value)) {
          return
        }
        break
      case 'yearsInIndustry':
      case 'SSPermitNumber':
      case 'SSPermitNumberOfYears':
        if (value && !NUMBER_REGEXP.test(value)) {
          return
        }
        break
      default:
        break
    }

      this.setState({
        form: update(this.state.form, {
          [name]: {
            value: { $set: value }
          }
        })
      }, () => this.validate(name))

  }

  handleCheckbox ({ target: { name } }) {
    if (name === 'anotherState') {
      if (!this.state.anotherState) {
        this.handlePICState.bind(null, 'anotherStatePIC', { value: '', label: '' }, { action: 'select-option' })()
      }
    }
    this.setState(prevState => {
      return {
        [name]: !prevState[name]
      }
    }, () => {
      if (name === 'anotherState') {
        this.validate('anotherStatePIC')
        this.validate('PIC')
        this.checkValidation()
      }
    })
  }

  handlePICState (type, option, { action }) {
    if (action !== 'select-option') {
      return
    }
    this.setState({ form: update(this.state.form, {
      [type]: {
        value: { $set: option.value }
      } })
    }, () => {
      this.validate('anotherStatePIC')
      this.validate('PIC')
      this.checkValidation()
    })
  }

  handleBankDetailsChange (type, event) {
    let { target: { name, value } } = event
    switch(name){
      case 'accountName':
        if(value && !ACCOUNT_NAME_NUMBER.test(value)){
          return
        }
        break
      case 'BSB':
        if(value && !BSB.test(value)){
          return
        }
        break
      case 'accountNumber': 
        if(value && !NUMBER_REGEXP.test(value)){
          return
        }
        break
      default:
        break
    }

    this.setState({
      form: update(this.state.form, {
        [type]: {
          [name]: {
            value: { $set: value }
          }
        }
      })
    }, () => this.validate(name, type))
    
  }
  handleCustomerDetailsChange (type, event) {
    let { target: { name, value } } = event
    switch(name){
      case 'companyName':
      case 'givenName':
      case 'lastName':
      case 'legalNameOfTradingEntity': 
        if (value && !FIRST_LAST_NAME.test(value)) {
          return
        } else {
          value = value.slice(0, 1).toUpperCase() + value.slice(1)
        }
        break
        case 'ACN':
          if (value && !ACN_REGEXP_SOFT.test(value)) {
            return
          }
          break
      default:
        break
    }

    this.setState({
      form: update(this.state.form, {
        [type]: {
          [name]: {
            value: { $set: value }
          }
        }
      })
    }, () => this.validate(name, type))
    
  }
  handleAddressChange (type, event) {
    let { target: { name, value } } = event
    switch (name) {
      case 'address1':
        if (value && !ADDRESS.test(value)) {
          return
        }
        break
      case 'address2':
        if (value && !ADDRESS.test(value)) {
          return
        }
        break
      case 'postcode':
        if (value && !AUSTRALIAN_POSTCODE_SOFT.test(value)) {
          return
        }
        break
      case 'town':
        value = value.toUpperCase()
        if (value && !ADDRESS.test(value)) {
          return
        }
        break
      default:
        break
    }
    this.setState({
      form: update(this.state.form, {
        [type]: {
          [name]: { value: { $set: value } }
        }
      })
    }, () => this.validate(name, type))
  }

  handlePasswordChange ({ target: { name, value } }) {
    this.setState({
      passwordForm: update(this.state.passwordForm, {
        [name]: {
          value: { $set: value }
        }
      })
    }, () => this.validate(name))
  }

  cancel () {
    this.setState({
      ...this.restoredValues(),
      edit: false
    }, () => {
      this.checkValidation()
    })
  }

  async handleSubmit () {
    await this.checkValidation()
    if((isSeller() || isBuyer() )) {
      await this.validateCustomerDetails()
    }
    if (this.allValid()) {
      if (this.state.form.PIC.value && this.state.form.PIC.invalidMessage) {
        this.props.showModal({
          message: this.state.form.PIC.invalidMessage
        })
        return
      }
      this.putData()
    } else {
      if (this.state.form.PIC.invalidMessage) {
        this.props.showModal({
          message: this.state.form.PIC.invalidMessage
        })
      } else {
        this.props.showModal({
          message: 'Please fill required fields'
        })
      }
    }
  }

  checkValidation () {
    const { form } = this.state
    return new Promise((resolve, reject) => {
      const state = this.state.anotherState ? form.anotherStatePIC.value : form.officeAddress.state.value
      if (isSeller()) {
        this.setState({ form: update(form, {
          firstName: {
            valid: { $set: validateFirstName(form.firstName.value) === '' }
          },
          lastName: {
            valid: { $set: validateLastName(form.lastName.value) === '' }
          },
          email: {
            valid: { $set: validateEmail(form.email.value) === '' }
          },
          PIC: {
            valid: { $set: validatePic(form.PIC.value, state, { required: false }) === '' },
            invalidMessage: { $set: form.PIC.value === '' ? '' : validatePic(form.PIC.value, state, { required: false }) }
          },
          anotherStatePIC: {
            valid: { $set: validateState(form.anotherStatePIC.value) === '' },
            invalidMessage: { $set: validateState(form.anotherStatePIC.value) }
          },
          ABN: {
            valid: { $set: validateAbn(form.ABN.value) === '' }
          },
          tradingName: {
            valid: { $set: validateTradingName(form.tradingName.value) === '' }
          },
          phone: {
            valid: { $set: validatePhone(form.phone.value) === '' }
          },
          secondaryPhone: {
            valid: { $set: validateSecondaryPhone(form.secondaryPhone.value) === '' }
          },
          officeAddress: {
            town: {
              valid: { $set: validateTown(form.officeAddress.town.value) === '' }
            },
            postcode: {
              valid: { $set: validatePostcode(form.officeAddress.postcode.value) === '' }
            }
          },
          postalAddress: {
            town: {
              valid: { $set: validateTown(form.postalAddress.town.value) === '' }
            },
            postcode: {
              valid: { $set: validatePostcode(form.postalAddress.postcode.value) === '' }
            }
          },
          /*bankDetails: {
            accountName: {
              valid: { $set: validateAccountName(form.bankDetails.accountName.value) === '' }
            },
            BSB: {
              valid: { $set: validateBsb(form.bankDetails.BSB.value) === '' }
            },
            accountNumber: {
              valid: { $set: validateAccountNumber(form.bankDetails.accountNumber.value) === '' }
            }
          },*/
          profession: {
            valid: { $set: validateTradingType(form.profession.value) === '' }
          },
          otherProfession: {
            valid: { $set: validateOtherOptionType(form.otherProfession.value) === '' }
          }
        }) }, resolve)
      } else {
        this.setState({
          form: update(form, {
            firstName: {
              valid: { $set: validateFirstName(form.firstName.value) === '' }
            },
            lastName: {
              valid: { $set: validateLastName(form.lastName.value) === '' }
            },
            email: {
              valid: { $set: validateEmail(form.email.value) === '' }
            },
            PIC: {
              valid: { $set: validatePic(form.PIC.value, state) === '' },
              invalidMessage: { $set: form.PIC.value === '' ? '' : validatePic(form.PIC.value, state) }
            },
            anotherStatePIC: {
              valid: { $set: validateState(form.anotherStatePIC.value) === '' },
              invalidMessage: { $set: validateState(form.anotherStatePIC.value) }
            },
            ABN: {
              valid: { $set: validateAbn(form.ABN.value) === '' }
            },
            tradingName: {
              valid: { $set: validateTradingName(form.tradingName.value) === '' }
            },
            phone: {
              valid: { $set: validatePhone(form.phone.value) === '' }
            },
            secondaryPhone: {
              valid: { $set: validateSecondaryPhone(form.secondaryPhone.value) === '' }
            },
            officeAddress: {
              town: {
                valid: { $set: validateTown(form.officeAddress.town.value) === '' }
              },
              postcode: {
                valid: { $set: validatePostcode(form.officeAddress.postcode.value) === '' }
              }
            },
            postalAddress: {
              town: {
                valid: { $set: validateTown(form.postalAddress.town.value) === '' }
              },
              postcode: {
                valid: { $set: validatePostcode(form.postalAddress.postcode.value) === '' }
              }
            },
            profession: {
              valid: { $set: validateTradingType(form.profession.value) === '' }
            },
            otherProfession: {
              valid: { $set: validateOtherOptionType(form.otherProfession.value) === '' }
            }
          })
        }, resolve)
      }
    })
  }

  validateCustomerDetails () {
    const { form } = this.state
    return new Promise((resolve) => {
      this.setState({
        form: update(form, {
          customerDetails:{
          ACN: {
            valid: { $set: validateAcn(form.customerDetails.ACN.value, { required: true }) === '' },
            invalidMessage: { $set: validateAcn(form.customerDetails.ACN.value, { required: true }) }
          },
          companyName: {
            valid: { $set: validateCompanyName(form.customerDetails.companyName.value,{ required: true }) === '' },
            invalidMessage: { $set: validateCompanyName(form.customerDetails.companyName.value,{ required: true }) }
          },
          givenName: {
            valid: { $set: validateGivenName(form.customerDetails.givenName.value,{ required: true }) === '' },
            invalidMessage: { $set: validateGivenName(form.customerDetails.givenName.value,{ required: true }) }
          },
          lastName: {
            valid: { $set: validateLastName(form.customerDetails.lastName.value,{ required: true }) === '' },
            invalidMessage: { $set: validateLastName(form.customerDetails.lastName.value,{ required: true }) }
          },
          legalNameOfTradingEntity: {
            valid: { $set: validateLegalNameOfTradingEntityName(form.customerDetails.legalNameOfTradingEntity.value,{ required: true }) === '' },
            invalidMessage: { $set: validateLegalNameOfTradingEntityName(form.customerDetails.legalNameOfTradingEntity.value,{ required: true }) }
          }
        }})
      }, resolve)
    })
  }

  async putData () {
    try {
      this.props.showSpinner(true)
      let data = convertData(this.state.form, { validOnly: true })
      let postData
      if (isSeller()) {
          postData = _pick(data, [
            'firstName',
            'lastName',
            'email',
            'phone',
            'secondaryPhone',
            'ABN',
            'PIC',
            'anotherStatePIC',
            'tradingName',
            'profession',
            'officeAddress',
            'postalAddress',
            /*'bankDetails.accountName',
            'bankDetails.BSB',
            'bankDetails.accountNumber',*/
            'stockTypes',
            'otherProfession',
            'doYouTradeThroughCompany',
            'customerDetails.companyName',
            'customerDetails.ACN',
            'customerDetails.givenName',
            'customerDetails.lastName',
            'customerDetails.legalNameOfTradingEntity',
            
          ])
        // if (postData.sellerType === 'agency') {
        //   postData.agencyName = data.agencyName
        // }
      } else if (isBuyer()) {
        postData = _pick(data, [
          'firstName',
          'lastName',
          'email',
          'phone',
          'agentName',
          'secondaryPhone',
          'tradingName',
          'PIC',
          'anotherStatePIC',
          'ABN',
          'officeAddress',
          'postalAddress',
          'profession',
          /*'bankDetails.accountName',
          'bankDetails.BSB',
          'bankDetails.accountNumber',*/
          'otherProfession',
          'doYouTradeThroughCompany',
          'customerDetails.companyName',
          'customerDetails.ACN',
          'customerDetails.givenName',
          'customerDetails.lastName',
          'customerDetails.legalNameOfTradingEntity',
        ])
      } else {
        postData = _pick(data, [
          'firstName',
          'lastName',
          'email',
          'phone',
          'secondaryPhone',
          'tradingName',
          'profession',
          'otherProfession',
          'officeAddress',
        ])
      }
      if (this.state.same) {
        postData.postalAddress = { ...postData.officeAddress }
      }
      if (!this.state.anotherState) {
        postData.anotherStatePIC = ''
      }
      if (!isGuest() && this.state.form.doYouTradeThroughCompany.value === "Yes") {
        postData.doYouTradeThroughCompany = true
      } else if (!isGuest() && this.state.form.doYouTradeThroughCompany.value === "No") {
        postData.doYouTradeThroughCompany = false
      }

      if (isGuest()) {
        postData.officeAddress.town = ''
      }
      
      let putUser = await Api.putUser(postData)
      if (putUser) {
        this.props.changeStateProp('data', putUser.user, 'user')
        this.cancel()
      }
      this.props.showSpinner(false)
    } catch (e) {
      console.error('error', e)
      this.props.showSpinner(false)
    }
  }

  clearPassword () {
    if (this.state.passwordForm.oldPassword.value === '' &&
      this.state.passwordForm.newPassword.value === '' &&
      this.state.passwordForm.confirmNewPassword.value === '') {
      this.setState({
        passwordForm: update(this.state.passwordForm, {
          oldPassword: {
            $set: {
              value: '',
              valid: true
            }
          },
          newPassword: {
            $set: {
              value: '',
              valid: true
            }
          },
          confirmNewPassword: {
            $set: {
              value: '',
              valid: true
            }
          }
        })
      })
    }
  }

  validate (name, address) {
    let validateResult
    switch (name) {
      case 'firstName':
        validateResult = validateFirstName(this.state.form[name].value)
        break
        case 'lastName' && address :
          validateResult = validateLastName(this.state.form[address][name].value)
          break
      case 'lastName':
        validateResult = validateLastName(this.state.form[name].value)
        break
        case 'lastName' && address === "customerDetails":
          validateResult = validateLastName(this.state.form[address][name].value)
          break
      case 'email':
        validateResult = validateEmail(this.state.form[name].value)
        break
      case 'phone':
        validateResult = validatePhone(this.state.form[name].value)
        break
      case 'secondaryPhone':
        validateResult = validateSecondaryPhone(this.state.form[name].value)
        break
      case 'ABN':
        validateResult = validateAbn(this.state.form[name].value)
        break
        case 'ACN':
          validateResult = validateAcn(this.state.form[address][name].value,{ required: true })
          break
      case 'PIC':
        if (this.state.anotherState) {
          validateResult = isSeller() ? validatePic(this.state.form[name].value, this.state.form.anotherStatePIC.value, { required: false }) : validatePic(this.state.form[name].value, this.state.form.anotherStatePIC.value)
        } else {
          validateResult = isSeller() ? validatePic(this.state.form[name].value, this.state.form.officeAddress.state.value, { required: false }) : validatePic(this.state.form[name].value, this.state.form.officeAddress.state.value)
        }
        break
      case 'anotherStatePIC':
        validateResult = validateState(this.state.form.anotherStatePIC.value)
        break
      case 'tradingName':
        validateResult = validateTradingName(this.state.form[name].value)
        break
        case 'companyName':
          validateResult = validateCompanyName(this.state.form[address][name].value)
          break
     case 'givenName':
            validateResult = validateGivenName(this.state.form[address][name].value)
            break
     case 'legalNameOfTradingEntity':
              validateResult = validateLegalNameOfTradingEntityName(this.state.form[address][name].value)
              break
      case 'town':
        if (address) {
          validateResult = validateTown(this.state.form[address][name].value)
        }
        break
      case 'postcode':
        if (address) {
          validateResult = validatePostcode(this.state.form[address][name].value)
        }
        break
      case 'accountName':
        if(address) {
            validateResult = validateAccountName(this.state.form[address][name].value)
        }
        break
      case 'accountNumber':
        if(address){
            validateResult = validateAccountNumber(this.state.form[address][name].value)
        }
        break
      case "BSB": 
        if(address){
            validateResult = validateBsb(this.state.form[address][name].value)
        }
        break
      case 'agentName':
        validateResult = validateFirstName(this.state.form[name].value, { required: false })
        break
      case 'profession': 
        validateResult = validateTradingType(this.state.form[name].value)
        break
      case 'otherProfession':
        validateResult = validateOtherOptionType(this.state.form[name].value)
        break
      default:
        break
    }
    if (validateResult !== undefined) {
      if (address) {
        this.setState({
          form: update(this.state.form, {
            [address]: {
              [name]: {
                valid: { $set: validateResult === '' },
                invalidMessage: { $set: validateResult }
              }
            }
          })
        })
      } else {
        this.setState({
          form: update(this.state.form, {
            [name]: {
              valid: { $set: validateResult === '' },
              invalidMessage: { $set: validateResult }
            }
          })
        })
      }
    }
  }

  allValid () {
    // only checks fields that always have to be filled
    const { form, same, anotherState } = this.state
    let picStateValid = anotherState ? form.anotherStatePIC.valid : true
    let fieldsValid
    let customerDetailsValid
    if (!isGuest() && form.doYouTradeThroughCompany.value === "Yes") {
      customerDetailsValid = form.customerDetails.ACN.valid && form.customerDetails.companyName.valid && form.customerDetails.givenName.valid && form.customerDetails.lastName.valid
    }

    if (!isGuest() && form.doYouTradeThroughCompany.value === "No") {
      customerDetailsValid = form.customerDetails.legalNameOfTradingEntity.valid && form.ABN.valid && form.customerDetails.givenName.valid && form.customerDetails.lastName.valid
    }
    let addressesValid = same
      ? (
        form.officeAddress.address1.valid &&
        form.officeAddress.address2.valid &&
        form.officeAddress.postcode.valid &&
        form.officeAddress.town.valid
      )
      : (
        form.officeAddress.address1.valid &&
        form.officeAddress.address2.valid &&
        form.officeAddress.postcode.valid &&
        form.officeAddress.town.valid &&
        form.postalAddress.address1.valid &&
        form.postalAddress.address2.valid &&
        form.postalAddress.postcode.valid &&
        form.postalAddress.town.valid
      )
    if (isSeller()) {
        fieldsValid = (
          form.firstName.valid &&
          form.lastName.valid &&
          form.email.valid &&
          form.phone.valid &&
          form.tradingName.valid &&
          form.sellerType.valid &&
          // form.jobTitle.valid &&
         // form.ABN.valid &&
          customerDetailsValid &&
          // form.yearsInIndustry.valid &&
          // policyFile &&
          addressesValid &&
          form.PIC.valid &&
          picStateValid &&
          /*form.bankDetails.accountName &&
          form.bankDetails.accountNumber &&
          form.bankDetails.BSB &&*/
          form.profession.valid 
          // form.otherProfession.valid
        )
      } else if(isBuyer()) {
        fieldsValid = (
          form.firstName.valid &&
          form.lastName.valid &&
          form.email.valid &&
          form.phone.valid &&
          form.tradingName.valid &&
          form.sellerType.valid &&
         // form.ABN.valid &&
          customerDetailsValid &&
          form.PIC.valid &&
          picStateValid &&
          addressesValid &&
          /*form.bankDetails.accountName.valid &&
          form.bankDetails.accountNumber.valid &&
          form.bankDetails.BSB.valid &&*/
          form.profession.valid 
          // form.otherProfession.valid
        )
      }
    else {
      fieldsValid = (
        form.firstName.valid &&
        form.lastName.valid &&
        form.email.valid &&
        form.phone.valid &&
        form.tradingName.valid &&
        form.profession.valid &&
        form.officeAddress.state.valid &&
        form.officeAddress.postcode.valid
        // form.otherProfession.valid
      )
    }
    return fieldsValid
  }

  edit () {
    this.setState(prevState => ({
      edit: !prevState.edit
    }))
  }

  handleSelect (type, item, { action }) {
    if (action === 'select-option') {
      this.setState({
        form: update(this.state.form, {
          [type]: {
            state: { value: { $set: item.value } }
          }
        })
      }, () => {
        console.log('STATE--', this.state.form);
        if (type === 'officeAddress') {
          const state = this.state.anotherState ? this.state.form.anotherStatePIC.value : item.value
          this.setState({
            form: update(this.state.form, {
              PIC: {
                valid: { $set: isSeller() ? validatePic(this.state.form.PIC.value, state, { required: false }) === '' : validatePic(this.state.form.PIC.value, state) === '' },
                invalidMessage: { $set: isSeller() ? validatePic(this.state.form.PIC.value, state, { required: false }) === '' : validatePic(this.state.form.PIC.value, state) === '' }
              }
            })
          })
        }
      })
    }
  }

  selectFile ({ target: { name, files } }) {
    return new Promise((resolve, reject) => {
      let file = files[0]
      if (file) {
        let fileReader = new window.FileReader()
        fileReader.readAsDataURL(files[0])
        fileReader.onloadend = () => {
          let obj = {
            data: fileReader.result,
            mimeType: file.type,
            name: file.name,
            isNew: true
          }
          if (!file.type) {
            let arrayName = file.name.split('.')
            let ext = arrayName[arrayName.length - 1]
            switch (ext) {
              case 'xls':
                obj.mimeType = 'application/vnd.ms-excel'
                break
              case 'doc':
                obj.mimeType = 'application/msword'
                break
              case 'docx':
                obj.mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                break
              case 'xlsx':
                obj.mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                break
              default:
                obj.mimeType = ''
                break
            }
          }
          this.setState({ [name]: obj }, resolve)
        }
      } else {
        this.setState({ [name]: undefined }, resolve)
      }
    })
  }

  async uploadFile ({ file, fileType }) {
    let uploadReq
    switch (fileType) {
      case 'licenseFile':
        uploadReq = Api.postLicenseFile
        break
      case 'policyFile':
        uploadReq = Api.postPolicyFile
        break
      case 'logo':
        uploadReq = Api.postUserLogo
        break
      default:
        throw new Error('Unknown fileType')
    }
    let res = await uploadReq({
      data: file.data,
      mimeType: file.mimeType,
      name: file.name
    })
    if (!res) {
      this.props.showModal({
        message: 'File upload error'
      })
    }
  }

  mapOptions (options) {
    return options.map(option => {
      return {
        value: option,
        label: option
      }
    })
  }

  mapSelected = (option) => {
    if (this.state.same) {
      return {
        value: this.state.form.officeAddress.state.value,
        label: this.state.form.officeAddress.state.value
      }
    } else {
      return {
        value: option,
        label: option
      }
    }
  }

  async uploadLogo (event) {
    this.props.showSpinner(true)
    await this.selectFile(event)
    if (this.state.logo) {
      await this.uploadFile({
        file: this.state.logo,
        fileType: 'logo'
      })
      let res = await Api.getUser()
      if (res) {
        this.props.changeStateProp('data', res.user, 'user')
        this.setState({
          logo: this.defaultFieldValue('logo')
        })
      }
      this.props.showSpinner(false)
    }
  }

  openModal () {
    this.setState({
      isModalOpen: true
    })
  }

  closeModal () {
    this.setState({
      isModalOpen: false
    })
  }

  checkDocumentationIsFilled = () => {
    const { policyFile, licenseFile } = this.state
    if (this.isUserULA()) {
      return policyFile
    } else {
      return licenseFile && policyFile
    }
  }

  mapTypeOptions (options) {
    return options.map(option => {
      return {
        value: option.name,
        label: option.name
      }
    })
  }


  render () {
    const {
      edit,
      same,
      logo,
      form,
      isModalOpen
    } = this.state
    const { user } = this.props
    const stockTypeArr = [{id: 1, key: "Sheep"}, {id: 2, key: "Cattle"}, {id: 3, key: "Goat"}]
    if(form.userOperationsTypes){
      this.mapTypes = this.mapTypeOptions(form.userOperationsTypes.professions)
    }
    return (
      <div className='my-profile-container'>
        <ChangePassModalContainer
          openModal={this.openModal}
          closeModal={this.closeModal}
          isOpen={isModalOpen}
        />
        <ProfileHeader
          logo={logo}
          editMode={edit}
          editHandle={this.edit}
          cancelHandle={this.cancel}
          openModal={this.openModal}
          user={user}
          goBack={() => {
            this.props.history.goBack()
          }}
          handleSubmit={this.handleSubmit}
          uploadLogo={this.uploadLogo}
        />
        <div className='info-items'>
          <ContactDetails
            form={form}
            edit={edit}
            handleInputChange={this.handleInputChange}
          />
          <BusinessDetails
            form={form}
            edit={edit}
            same={same}
            handleInputChange={this.handleInputChange}
            mapSelected={this.mapSelected}
            mapTypes={this.mapTypes}
            handleTradingTypes={this.handleTradingTypes}
          />

          {
            (isGuest() && (
              <React.Fragment>
                <AddressDetails
                  form={form}
                  same={same}
                  edit={edit}
                  handleSelect={this.handleSelect}
                  handleAddressChange={this.handleAddressChange}
                  handleCheckbox={this.handleCheckbox}
                  mapSelected={this.mapSelected}
                  mapStates={this.mapStates}
                />
              </React.Fragment>
            ))
          }
         
          {
            ((isSeller() || isBuyer()) && (
              <React.Fragment>          
              <CustomerDetails
              form={form}
              edit={edit}
              same={same}
              handleInputChange={this.handleInputChange}
              mapSelected={this.mapSelected}
              mapTypes={this.mapTypes}
              handleCustomerDetailsChange={this.handleCustomerDetailsChange}
              handleYesorNoDropdown = {this.handleYesorNoDropdown}
               />
            <AddressDetails
                form={form}
                same={same}
                edit={edit}
                handleSelect={this.handleSelect}
                handleAddressChange={this.handleAddressChange}
                handleCheckbox={this.handleCheckbox}
                mapSelected={this.mapSelected}
                mapStates={this.mapStates}
              />
            </React.Fragment>
              
              
            ))
          }
          {/* {
            (isSeller()) && (
              <BankDetails
                form={form}
                edit={edit}
                handleBankDetailsChange={this.handleBankDetailsChange}
                same={same}
            />
            )
          } */}
          {
            isSeller() && (
              <StockTypes
                form={form}
                edit={false}
                user={user}
                handleMultipleCheckbox={this.handleMultipleCheckbox}
                stockTypeArr={stockTypeArr}
              />
            )
          }
        </div>
      </div>
    )
  }
}

Profile.propTypes = {
  user: PropTypes.object.isRequired,
  changeStateProp: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  showSpinner: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  locationStates: PropTypes.array.isRequired
}

Profile.defaultProps = {}

export default Profile
