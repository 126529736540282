import React from 'react'

const CalendarFilter = props => (
  <div
    className={`btn filter-btn long-but ${props.value === props.activeValue ? 'filter-btn-active' : ''}`}
    onClick={() => props.onChange(props.activeValue)}
  >
    <span>{props.children}</span>
    <span className={`icon-wrapper`}>{props.icon}</span>
  </div>
)

export default CalendarFilter
