import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { showModal } from '../../../actions'
import { INTEGER_REGEXP } from '../../../constants'

class MoneyInput extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      editing: false,
      value: this.props[this.props.attribute]
    }
    this.handleMoneyInput = this.handleMoneyInput.bind(this)
    this.onChange = this.onChange.bind(this)
    this.edit = this.edit.bind(this)
    this.input = React.createRef()
  }
  componentDidUpdate (prevProps, prevState) {
    if (prevProps[prevProps.attribute] !== this.props[this.props.attribute]) {
      this.setState({
        value: this.props[this.props.attribute]
      })
    }
  }
  edit () {
    this.setState({ editing: true })
  }
  onChange () {
    let { value } = this.input.current
    if (INTEGER_REGEXP.test(value) || !value) {
      this.setState({ value })
    }
  }
  handleMoneyInput () {
    const { attribute } = this.props
    const { value } = this.state
    let cents = parseInt(value, 10) || 0
    // invalid input handling
    if (attribute === 'startPrice') {
      const { reserveCents } = this.props
      if (attribute === 'startPrice' && reserveCents > 0 && reserveCents < cents) {
        this.props.showModal({
          message: 'Your Start Price can\'t be higher than your Reserve Price'
        })
        this.setState({ editing: false, value: this.props[this.props.attribute] })
        return
      }
    }
    if (attribute === 'reserveCents') {
      const { currentBidCents } = this.props
      if (cents > 0 && currentBidCents > cents) {
        this.props.showModal({
          message: 'Your Reserve Price can\'t be lower than the Start Price'
        })
        this.setState({ editing: false, value: this.props[this.props.attribute] })
        return
      }
    }
    // valid input handling
    this.setState({ editing: false, value: cents })
    this.props.onChange(attribute, cents)
  }
  render () {
    const { required, title, disabled } = this.props
    return (
      <div className={`form-row ${disabled ? 'disabled' : ''}`}>
        <div className='row-title'>
          <p
            className='row-text'>{title}
            {required ? <span className='asterisk'>*</span> : null}:</p>
        </div>
        <div className='row-content'>
          <input
            className='input'
            placeholder='0'
            type='text'
            ref={this.input}
            onFocus={this.edit}
            onChange={this.onChange}
            onBlur={this.handleMoneyInput}
            value={this.state.value || ''} />
        </div>
      </div>
    )
  }
}

MoneyInput.propTypes = {
  title: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  attribute: PropTypes.string.isRequired,
  startPrice: PropTypes.number,
  reserveCents: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

MoneyInput.defaultProps = {
  required: false,
  disabled: false
}

export default connect(
  null,
  (dispatch) => {
    return bindActionCreators({ showModal }, dispatch)
  }
)(MoneyInput)
