import EmailModalLot from '../EmailModalLot'

import Api from '../../../services/api'

class EmailModalMyAuctionResult extends EmailModalLot {
  constructor (props) {
    super(props)
    this.title = 'My Auction Results Email Form'
  }

  async sendRequest (data) {
    let res = await Api.emailMyAuctionResult({
      data
    })
    return res
  }
}

export default EmailModalMyAuctionResult
