import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Toggle from 'react-toggle'

class SignedForm extends PureComponent {
  constructor (props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.isLicencedAgentToggle = this.isLicencedAgentToggle.bind(this)
  }
  toggle () {
    this.props.onChange('vendorSignedForm', !this.props.vendorSignedForm)
  }
  isLicencedAgentToggle () {
    this.props.onChange('isLicencedAgent', !this.props.isLicencedAgent)
  }
  render () {
    const { vendorSignedForm, disabled,isLicencedAgent } = this.props
    return (
      <div className={`input-section ${disabled ? 'disabled' : ''}`}>
        <div className='section-heading'>
          <div className='heading-title'>Agent Declaration<span className='asterisk'>*</span></div>
        </div>
        <div className='section-body'>
        <div className='form-row'>
            <div className='row-title row-title-long'>
              <p className='row-text'>
              Are you a licenced stock and station agent <span className='asterisk'>*</span>:
              </p>
            </div>
            <div className='row-content'>
              <div className='switcher'>
                <p className='row-text'>No</p>
                <Toggle
                  icons={false}
                  checked={isLicencedAgent}
                  onChange={this.isLicencedAgentToggle} />
                <p className='row-text'>Yes</p>
              </div>
            </div>
          </div>
          { isLicencedAgent && <p className='row-text row-text-full'>
            I the Vendor's Agent declare I am a licensed Stock & Station Agent and confirm that I have with the Vendor
            completed the Lot Assessment form. I promise that the information in the Lot Assessment form is to the best
            of my information, knowledge and belief, accurate, complete, truthful, is not misleading or deceptive, does
            not omit material information and is not false or unlawful. I also certify that I hold an original Lot
            assessment form and that the Vendor’s Declaration and the Vendor's Agents Declaration, set out within each
            Lot Assessment form, have been fully and correctly signed.
          </p>}
          
          <div className='form-row'>
            <div className='row-title row-title-long'>
              <p className='row-text'>
                Vendor has signed assessment form<span className='asterisk'>*</span>:
              </p>
            </div>
            <div className='row-content'>
              <div className='switcher'>
                <p className='row-text'>No</p>
                <Toggle
                  icons={false}
                  checked={vendorSignedForm}
                  onChange={this.toggle} />
                <p className='row-text'>Yes</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

SignedForm.propTypes = {
  vendorSignedForm: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

SignedForm.defaultProps = {
  vendorSignedForm: false,
  disabled: false
}

export default SignedForm
