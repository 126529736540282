import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Checkbox from '../../Checkbox/Checkbox'
import { bidTypes } from '../../../constants'

class BiddingSwap extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
    this.onChange = this.onChange.bind(this)
  }
  onChange (value) {
    this.props.onChange('biddingSwap', value)
  }
  getOtherIncrement () {
    switch (this.props.bidding) {
      case bidTypes.HEAD:
        return '¢/kg'
      case bidTypes.KG:
      default:
        return '$/head'
    }
  }
  render () {
    const {
      biddingSwap
    } = this.props
    return (
      <div className={`form-row ${this.props.disabled ? 'disabled' : ''}`}>
        <div className='row-title'>
          <p
            className='row-text'>Also display Lot in {this.getOtherIncrement()}:</p>
        </div>
        <div className='row-content'>
          <Checkbox
            type='green'
            value={biddingSwap}
            onToggle={this.onChange} />
        </div>
      </div>
    )
  }
}

BiddingSwap.propTypes = {
  bidding: PropTypes.oneOf(Object.values(bidTypes)),
  biddingSwap: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

BiddingSwap.defaultProps = {
  disabled: false
}

export default BiddingSwap
