import React, { Component, Fragment } from 'react'
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react'

import { GOOGLE_API_KEY } from '../../config'

export class MapContainer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      infoWindowText: 'test',
      showingInfoWindow: false,
      activeMarker: {}
    }
    this.onMarkerClick = this.onMarkerClick.bind(this)
    this.onInfoWindowClose = this.onInfoWindowClose.bind(this)
  }

  onMarkerClick (props, marker, e) {
    this.setState({
      showingInfoWindow: true,
      activeMarker: marker
    })
  }

  onInfoWindowClose () {
    this.setState({
      showingInfoWindow: false,
      activeMarker: null
    })
  }

  render () {
    const style = this.props.style || {}
    let adress = this.props.adress || ''
    let initialCenter = this.props.initialCenter
    let markerPosition = this.props.markerPosition
    return (
      <Fragment>
        {markerPosition ?
          <Map
            style={style}
            initialCenter={initialCenter}
            google={this.props.google}
            zoom={14}>

            <Marker
              position={markerPosition}
              onClick={this.onMarkerClick}
              name={'Current location'} />

            <InfoWindow
              marker={this.state.activeMarker}
              onClose={this.onInfoWindowClose}
              visible={this.state.showingInfoWindow}>
              <div>
                <h4>FarmGate</h4>
                <p>{adress}</p>
              </div>
            </InfoWindow>
          </Map>
          :
          <div style={{
            width: style.width, height: style.height, zIndex: style.zIndex, left: style.left, top: style.top, backgroundColor: 'rgba(255, 255, 255, 0.6)', display: 'flex', justifyContent: 'center', float: 'right'
          }}>
            <b style={{ alignSelf: 'center', color: '#fff' }}>Location not available</b>
          </div>
          }
      </Fragment>
      
    )
  }
}

// MapContainer.propTypes = {
//   style: PropTypes.object.isRequired
// }

export default GoogleApiWrapper({
  apiKey: (GOOGLE_API_KEY)
})(MapContainer)
