import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import { NavLink, Redirect } from 'react-router-dom'
import Tooltip from 'react-simple-tooltip'
import { css } from 'styled-components'
import axios from 'axios'
import moment from 'moment'
import memoize from 'memoize-one'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faArrowRight,
  faPlusSquare,
  faUser,
  faSearch,
  faFilter,
  faCaretDown,
  faTimes,
  faBalanceScale
} from '@fortawesome/fontawesome-free-solid'
import { faCompass, faCalendarAlt } from '@fortawesome/free-regular-svg-icons'

import WatchButton from '../../WatchButton/WatchButtonContainer'
import ImageCarousel from '../../ImageCarousel/ImageCarousel'
import EmailModalAuction from '../../Modals/EmailModalAuctionContainer'
import SortDropdown from '../../SortDropdown/SortDropdown'
import DownloadCatalogueBtn from '../../DownloadCatalogueBtn/DownloadCatalogueBtn'
import PrintCatalogueBtn from '../../PrintCatalogueBtn/PrintCatalogueBtn'
import LotItemWithdrawn from '../../LotItemWithdrawn/LotItemWithdrawn'
import BackButton from '../../BackButton/BackButton'
import FilterMultiSelect from '../../FilterMultiSelect/FilterMultiSelect'
import FilterRange from '../../FilterRange/FilterRange'
import FilterRangeAge from '../../FilterRangeAge/FilterRangeAge'
import Api from '../../../services/api'
import ServerTime from '../../../services/ServerTime'
import { getKindOptions } from '../../../utils/FindFieldKind'
import { limitTextLength, momentTz, parseQueryString, calculateLimitTextLength } from '../../../utils'
import { filterLots, sortLots } from '../../../utils/Filter'
import { getLotNumber, isAvailableAuction } from '../../../utils/Auctions'
import { getSexesByKinds, getStockCategoriesByKinds,getAvaillableStatesFromCatelogue,getAvaillableRegionsFromCatelogue } from '../../../utils/Lots'
import LotsListClosedStatus from '../../LotsLists/LotsListClosedStatus'
import { sellerOnly } from '../../../utils/UpdateData'
import { BASE_URL } from '../../../config'
import InfiniteScroll from 'react-infinite-scroll-component'
import { WanderingCubes } from 'better-react-spinkit'
import { Calculator } from '../../Calculator/Calculator'
import LotBottomInfo from '../../LotBottomInfo/LotBottomInfo'
import './LotsList.scss'
import { LotBoxContainer, LotBoxValue,TooltipContainer } from '../../LotBox/styled'
import { findFieldByPublicId } from "../../../utils/FindFieldKind";

class LotsList extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      modalIsOpen: false,
      listType: 'list',
      redirectAgentCommentUrl: '',
      filters: {
        screen: this.getScreen(),
        breed: this.getSavedFilterValue('breed', []),
        stockCategory: this.getSavedFilterValue('stockCategory', []),
        sex: this.getSavedFilterValue('sex', []),
        age: this.getSavedFilterValue('age', []),
        weight: this.getSavedFilterValue('weight', []),
        location: this.getSavedFilterValue('location', []),
        assessorType: this.getSavedFilterValue("assessorType", []),
        region: this.getSavedFilterValue("region", [])
      },
      page: 1,
      limit: 10,
      hasMore: true,
      fetching: false,
      sortField: 'number',
      sortDirection: 1,
      requestFinish: true,
      totalTable: [],
      rawPostCodeData : [],
      postCodeNumbers : []
    }
    this.cancelSource = axios.CancelToken.source()
    this.getDisplayData = memoize(this.getDisplayData)
    this.selectOptions = memoize(this.selectOptions)
    this.getPostcodesBasedOnregionSelected = memoize (this.getPostcodesBasedOnregionSelected)
    this.rangeOptions = memoize(this.rangeOptions)
    this.changeListType = this.changeListType.bind(this)
    this.changeSortChoice = this.changeSortChoice.bind(this)
    this.changeSortDirection = this.changeSortDirection.bind(this)
    this.openLive = this.openLive.bind(this)
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.afterOpenModal = this.afterOpenModal.bind(this)
    this.filterChangeHandler = this.filterChangeHandler.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
    this.getData = this.getData.bind(this)
    this.from = parseQueryString(this.props.location.search).from
    this.filterOptions = [
      'Lot Number',
      'Average Age',
      'Assessed Live Weight',
      'Sex',
      'Breed',
      'Stock Category'
    ]
  }
  componentDidMount () {
    if (this.props.history.action !== 'POP') {
      window.scrollTo(0, 0)
    }
    this.checkProps()
    this.getData()
    this.getPostcodeData()
  }
  componentDidUpdate (prevProps, prevState) {
    if (prevProps.auction !== this.props.auction ||
      prevProps.match.params.kindTitle !== this.props.match.params.kindTitle) {
      this.checkProps()
    }
    if (this.state.filters !== prevState.filters) {
      this.props.mergeStateProp('filters', [this.state.filters], 'user')
    }
    if (prevProps.watchButtonLoading !== this.props.watchButtonLoading && this.props.watchButtonLoading) {
      this.props.showSpinner(true)
    }
    if (prevProps.watchButtonLoading !== this.props.watchButtonLoading && !this.props.watchButtonLoading) {
      this.props.showSpinner(false)
    }

    let scrollPosition = parseInt(window.localStorage.getItem('upcomingLotsListScrollPosition')) || 0
    let countOfLots = parseInt(window.localStorage.getItem('upcomingLotsListCount')) || 0
    if (countOfLots !== 0 && scrollPosition !== 0) {
      window.scrollTo(0, scrollPosition)
      if (this.props.lots.length === countOfLots) {
        window.localStorage.removeItem('upcomingLotsListScrollPosition')
        window.localStorage.removeItem('upcomingLotsListCount')
      }
    }
  }
  componentWillUnmount () {
    this.cancelSource.cancel()
  }
  getScreen () {
    return `upcomingLotsList-${this.props.match.params.auctionNumber}`
  }
  getSavedFilterValue (key, defaultValue = []) {
    let screen = this.getScreen()
    let thisFilter = this.props.filters.find(filterSet => filterSet.screen === screen)
    let value
    if (thisFilter) {
      value = thisFilter[key]
    }
    return value || defaultValue
  }
  goBackName () {
    if (this.from) {
      if (this.from === 'watchList') {
        return 'Back to Watch List'
      }
    }
    return 'Back to Auctions'
  }
  checkProps () {
    if (!this.props.auction) {
      // if wrong auction
      this.props.history.replace('/main/upcoming')
    } else {
      let kindTitle = this.props.auction.kindData.title
      if (kindTitle.toLowerCase() !== this.props.match.params.kindTitle.toLowerCase()) {
        // if wrong kindTitle
        this.props.history.replace(`/main/upcoming/${kindTitle}/${this.props.auction.searchNumber}`)
      }
    }
  }
  async getData () {
    const { page, limit, sortField, sortDirection, requestFinish } = this.state
    if (requestFinish) {
      this.setState({ requestFinish: false })
      let data = await Api.getLotsByAuction(
        this.props.match.params.auctionNumber,
        page,
        limit,
        sortField,
        sortDirection,
        { state: ['future', 'open', 'live', 'futureInLive', 'closed'], cancelToken: this.cancelSource.token }
      )
      if (data && data.totalTable) {
        this.setState({
          totalTable: data.totalTable
        })
      }
      this.setState({ requestFinish: true })
      const { headers } = data
      let currentPage = headers ? headers['x-current-page'] : 1
      let totalPages = headers ? headers['x-total-pages'] : 1
      let newLots = data.lots
      let lots = this.props.lots.length ? this.props.lots.concat(newLots) : newLots
      if (data && !data.cancel) {
        this.setState({
          page: page + 1,
          hasMore: parseInt(currentPage, 10) < totalPages,
          fetching: false
        })
        this.props.mergeStateProp('lots', lots, 'data')
      }
    }
  }
   getPostcodeData = async () => {
    let url = '/postcodes'
    const res = await axios.get(url);
    let data = [];
    if(res && res.postcodesData) {
      res.postcodesData.forEach(element => {
       // data.push(element.region)
       data.push(`${element.region} (${element.state})`)
    });
    }
    data = Array.from(new Set(data));
    this.setState({
     // regionsData : data,
      rawPostCodeData : res.postcodesData
    })
  }
  getDisplayData (lots, filters, sortChoice, sortDirection,postCodeNumbers) {
    let filteredLots = filterLots(lots, filters,postCodeNumbers)
    return sortLots(filteredLots, sortChoice, sortDirection)
  }
  groupLotsByAllocatedsection (lots){
    return lots.reduce((acc, lot) => {
      const { allocatedSection } = lot;
    
      // if (!allocatedSection) {
      //   // If allocatedSection is empty or undefined, add it to a special group
      //   if (!acc['Other Lots (Not Allocated)']) {
      //     acc['Other Lots (Not Allocated)'] = [];
      //   }
      //   acc['Other Lots (Not Allocated)'].push({...lot});
      // } else {

        if (!acc[allocatedSection]) {
          acc[allocatedSection] = [];
        }
        acc[allocatedSection].push({ ...lot});
    //  }
    
      return acc;
    }, {});
    
  }
   selectOptions (kinds, auction, locationStates,lots) {
    let kindObj = kinds.find(kind => kind._id === auction.kind)
    let assessorTypesArray = [] ;
    if (kinds.length > 0) {
      let cattleKind = kinds.find(item => item.type === 'cattle')
      assessorTypesArray = findFieldByPublicId(cattleKind.form.forms, "assessorType").options
    }
    return {
      breed: getKindOptions(kindObj, 'majorBreed', { sort: true }),
      stockCategory: getStockCategoriesByKinds(kindObj.type, { sort: true }),
      sex: getSexesByKinds(kindObj.type, { sort: true }),
      location: getAvaillableStatesFromCatelogue(lots,locationStates),
      assessorType : assessorTypesArray,
      regionsData : getAvaillableRegionsFromCatelogue(lots,this.state.rawPostCodeData)
    }
  }
  async getPostcodesBasedOnregionSelected(selectedRegions, rawPostCodeData) {
    selectedRegions = selectedRegions.map(reg => {
      let value = reg.split(/\s*[()]\s*/)[0];
        return value.trim()
    } )  
   rawPostCodeData = await Promise.all(rawPostCodeData.filter(ele => selectedRegions.includes(ele.region.trim())));
    let regionCodes = rawPostCodeData.map(ele => ele.postcode)
    return regionCodes.filter(code => code !== null)
  }
  rangeOptions (lots) {
    let ages = lots.map(lot => lot.publicDetails.age.max).filter(Boolean)
    let weights = lots.map(lot => lot.publicDetails.weight.average).filter(Boolean)
    let ageMax = Math.round(Math.max(...ages))
    let weightMax = Math.round(Math.max(...weights))
    return {
      age: {
        min: 0,
        max: isFinite(ageMax) ? Math.max(ageMax, 0) : 0
      },
      weight: {
        min: 0,
        max: isFinite(weightMax) ? Math.max(weightMax, 0) : 0
      }
    }
  }
  getLotLocation (lot) {
    let addresses = []
    let possibleLocations = ['town', 'state']
    possibleLocations.forEach(loc => {
      let str = lot.publicDetails.location[loc]
      if (str) {
        addresses.push(str)
      }
    })
    return addresses.join(', ')
  }
  changeListType (type) {
    this.setState({
      listType: type
    })
  }
  changeSortChoice (sortChoice) {
    this.props.changeStateProp('lotSort', { choice: sortChoice,
      direction: this.props.sortDirection }, 'main')
  }
  changeSortDirection () {
    this.props.changeStateProp('lotSort', { choice: this.props.sortChoice,
      direction: this.props.sortDirection === 'up' ? 'down' : 'up' }, 'main')
  }
  goToLotLink (lot) {
    return `/main/upcoming/${this.props.auction.kindData.title}/${this.props.auction.searchNumber}/${lot.searchNumericId}`
  }
  addNewLink () {
    return `/main/add/new/${this.props.auction.kindData.title}/${this.props.auction.searchNumber}`
  }
  isAuctionInTwoWeeks () {
    return moment(ServerTime.getTime()).add(10, 'd').isAfter(this.props.auction.liveAt)
  }
  showButton () {
    if (this.props.auction.viewDetailsDays || this.props.auction.viewDetailsDays === 0) {
      return moment(ServerTime.getTime()).add(this.props.auction.viewDetailsDays, 'd').isAfter(this.props.auction.liveAt)
    } else {
      return moment(ServerTime.getTime()).add(10, 'd').isAfter(this.props.auction.liveAt)
    }
  }
  canAddLot (auction) {
    return isAvailableAuction(auction)
  }

  navigateToAddLot (auction) {
    switch (this.props.userStatus) {
      case 'partially-approved':
        this.props.showModal({
          message: 'Please complete your profile to Add Lot',
          buttons: [
            {
              text: 'Cancel'
            },
            {
              text: 'Update',
              onPress: () => {
                this.props.history.push(`/main/profile`)
              }
            }
          ]
        })
        break
      case 'partially-approved:updated':
        this.props.showModal({
          message: 'Your application is under review and should be approved in the next 24-hours. Please contact us if you need immediate access to Add a Lot to an Upcoming Auction.',
          buttons: [
            {
              text: 'OK'
            }
          ]
        })
        break
      default:
        this.props.history.push(this.addNewLink(auction))
        break
    }
  }
  openModal () {
    this.setState({
      modalIsOpen: true
    })
  }
  closeModal () {
    this.setState({
      modalIsOpen: false
    })
  }
  afterOpenModal () {
    // console.log('modal opened') custom animation here?
  }
  openLive () {
    window.open(`#/auction-live/${this.props.auction.searchNumber}`)
  }
  filterChangeHandler (filterName, value) {
    this.setState({
      filters: update(this.state.filters, {
        [filterName]: { $set: value }
      })
    })
  }
  clearFilter () {
    this.setState(update(this.state, {
      filters: {
        showMode: { $set: 'all' },
        breed: { $set: [] },
        stockCategory: { $set: [] },
        sex: { $set: [] },
        age: { $set: [] },
        weight: { $set: [] },
        location: { $set: [] },
        assessorType : { $set: []},
        region : { $set: []}
      }
    }))
  }
  renderInfoTop (lot, auction) {
    return (
      <div className='lot-info-top'>
        <p className='lot-number'>
          { getLotNumber(auction, lot) }
          {lot.createdBy === this.props.userId &&
            (
              this.state.listType === 'list'
                ? (
                  <span className='my-listing'>
                  -
                    <span className='icon-wrapper'>
                      <FontAwesomeIcon icon={faUser} />
                    </span>
                    <span className='my-listing-text'>
                    My Listing
                    </span>
                  </span>
                )
                : (
                  <span className='my-listing'>
                    <span className='icon-wrapper'>
                      <FontAwesomeIcon icon={faUser} />
                    </span>
                  </span>
                )
            )
          }
        </p>
        <p className='lot-location'>{this.getLotLocation(lot)}</p>
      </div>
    )
  }
   render () {
    const { kinds, auction, lots, locationStates, sortChoice, sortDirection } = this.props
    const { hasMore,rawPostCodeData,postCodeNumbers } = this.state
    if (!auction) {
      return <div />
    }
    const { filters } = this.state
    const { breed, stockCategory, sex, age, weight, location,assessorType,region } = filters
    this.getPostcodesBasedOnregionSelected(region, rawPostCodeData).then((res)=>{
      this.setState({
        postCodeNumbers : res
      })
    });
    let displayLots = this.getDisplayData(lots, filters, sortChoice, sortDirection,postCodeNumbers)
    const selectOptions = this.selectOptions(kinds, auction, locationStates,lots)
    const ranges = this.rangeOptions(lots)
    displayLots = displayLots.map(lot => {
      let comments = lot.publicDetails.agentComment.split(/\r\n|\r|\n/g)
      if (comments.length > 4) {
        lot.publicDetails.agentComment = comments.slice(0, 4).join('\n')
      }
      return lot
    })
    let isFiltersApplied = displayLots.length !== lots.length;
    let a =  this.groupLotsByAllocatedsection(displayLots)
    return (
      <div className='lots-wrapper'>
        {this.state.redirectAgentCommentUrl ? <Redirect
          to={{
            pathname: this.state.redirectAgentCommentUrl,
            state: { scroll: 10000 }
          }}
        /> : ''}
        <EmailModalAuction
          openModal={this.openModal}
          closeModal={this.closeModal}
          afterOpenModal={this.afterOpenModal}
          isOpen={this.state.modalIsOpen}
          auction={auction}
        />
        <div className='top-header'>
          <div className='left'>
            <h1 className='auction-title'>{auction.title}</h1>
            <h2 className='auction-subtitle'>Auction No. {auction.number}</h2>
            <p className='auction-live-date'>
              {`${momentTz(auction.liveAt).tz('Australia/Sydney').format('ddd, DD MMMM, h:mm A')}  (SYD)`}
              {auction.state === 'open' &&
                <span className='status status-open'> AUCTION IS OPEN</span>
              }
              {auction.state === 'live' &&
                <span className='status status-live'> AUCTION IS LIVE</span>
              }
              <p className='auction-live-date' style={{ fontSize: '18px', marginTop: 10 }}>{`${momentTz(auction.liveAt).tz('Australia/Perth').format('h:mm A')} WA -- ${momentTz(auction.liveAt).tz('Australia/Darwin').format('h:mm A')} NT -- ${momentTz(auction.liveAt).tz('Australia/Queensland').format('h:mm A')} QLD -- ${momentTz(auction.liveAt).tz('Australia/Adelaide').format('h:mm A')} SA -- ${momentTz(auction.liveAt).tz('Australia/Sydney').format('h:mm A')} NSW -- ${momentTz(auction.liveAt).tz('Australia/Victoria').format('h:mm A')} VIC -- ${momentTz(auction.liveAt).tz('Australia/Tasmania').format('h:mm A')} TAS`}</p>
            </p>
            <p className='auction-desc'>{auction.description}</p>
          </div>
          <div className='right'>
            <div className='auction-logo'>
              {auction.logo && <img src={auction.logo.url} alt='Auction Logo' />}
            </div>
          </div>
        </div>

        <div className='top-btns upcoming'>
          <div className='left'>
            <BackButton onPress={() => {
              this.props.history.goBack()
            }} name={this.goBackName()} />
            <div className='btn'
              onClick={this.openModal}>
              <span>Email Catalogue</span>
              <span className='icon-wrapper icon-success'><FontAwesomeIcon icon={faEnvelope} /></span>
            </div>
            {!auction.studSale &&
              <DownloadCatalogueBtn
                summaryUrl={`${BASE_URL}print/auctions/${auction.searchNumber}/auction-summary?type=pdf`}
                fullDetailsUrl={`${BASE_URL}print/auctions/${auction.searchNumber}/full-details`}
                auctionLotCount={auction.lotCount}
              />
            }
            {!auction.studSale &&
              <PrintCatalogueBtn
                summaryUrl={`${BASE_URL}print/auctions/${auction.searchNumber}/auction-summary?type=pdf&mode=print`}
                fullDetailsUrl={`${BASE_URL}print/auctions/${auction.searchNumber}/full-details?type=pdf&mode=print`}
              />
            }

          </div>
          <div className='right'>
            {
              auction.state === 'future' && this.canAddLot(auction) && (
                sellerOnly(
                  <div className='btn ' onClick={() => (this.navigateToAddLot(auction))}>
                    <span>Add New Lot</span>
                    <span className='icon-wrapper icon-success'><FontAwesomeIcon icon={faPlusSquare} /></span>
                  </div>
                )
              )
            }
            {
              (auction.state === 'open' || auction.state === 'live') && (
                <div className='btn enter-auction-btn' onClick={this.openLive}>
                  <span>Enter Auction</span>
                  <span className='icon-wrapper'><FontAwesomeIcon icon={faArrowRight} /></span>
                </div>
              )
            }
          </div>
        </div>
        {auction.usefulLinks && auction.usefulLinks.length
          ? <div id='useful-links' className='lots-controls'>
            <h3 className='filters-heading'>Useful links:</h3>
            <div className='lots-links-wrapper'>
              {auction.usefulLinks.map(link => {
                if (link.url) {
                  return (
                    <a target='_blank' rel='noopener noreferrer' href={link.url} className='link'>{link.description || link.url}</a>
                  )
                }
                return null
              })}
            </div>
          </div> : null
        }
        <div className='lots-controls'>
          <h3 className='filters-heading'>Filters</h3>
          <div className='lots-filters-wrapper'>
            <FilterMultiSelect
              title='Breed'
              name='breed'
              options={selectOptions.breed}
              selectedOptions={breed}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faFilter} />}
            />
            <FilterMultiSelect
              title='Sex'
              name='sex'
              options={selectOptions.sex}
              selectedOptions={sex}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faCaretDown} size='lg' />}
            />
            <FilterMultiSelect
              title='Stock Category'
              name='stockCategory'
              options={selectOptions.stockCategory}
              selectedOptions={stockCategory}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faCaretDown} size='lg' />}
            />
            <FilterRangeAge
              title='Age'
              name='age'
              unitY='Years'
              unitM='Months'
              description='Please type your minimum and maximum age in Months or Years or use slide below.'
              belowDescription='Please note: the filters are set from zero to the highest age available in the Catalogue.'
              min={ranges.age.min}
              max={ranges.age.max}
              value={age}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faCalendarAlt} />}
            />
            <FilterRange
              title='Weight'
              name='weight'
              unit='Kg'
              description='Please type your minimum and maximum weight or use slide below.'
              belowDescription='Please note: the filters are set from zero to the highest weight available in the Catalogue.'
              min={ranges.weight.min}
              max={ranges.weight.max}
              value={weight}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faBalanceScale} />}
            />
            <FilterMultiSelect
              title='Location'
              name='location'
              options={selectOptions.location}
              selectedOptions={location}
              selectedRegionOptions = {region}
              regionsData = {selectOptions.regionsData}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faCompass} />}
            />
            <FilterMultiSelect
              title="Assessor Type"
              name="assessorType"
              options={selectOptions.assessorType}
              selectedOptions={assessorType}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faCaretDown} size="lg" />}
              buttonLg
            />
             {/* <FilterMultiSelect
              title="Regions"
              name="region"
              options={regionsData}
              selectedOptions={region}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faCaretDown} size="lg" />}
            /> */}
            <div
              className='btn filter-btn filter-btn-sm clear-all-btn'
              onClick={this.clearFilter}
            >
              <span>Clear All</span>
              <span className='icon-wrapper icon-danger'><FontAwesomeIcon icon={faTimes} /></span>
            </div>
          </div>
        </div>
        <div className='catalog-settings'>
          <SortDropdown
            sortOptions={this.filterOptions}
            sortChoice={this.props.sortChoice}
            changeSortChoice={this.changeSortChoice}
            sortDirection={this.props.sortDirection}
            changeSortDirection={this.changeSortDirection}
          />
          <h2 className='catalog-header'>Catalogue</h2>
          <div className='view-modes'>
            <div className={`view-mode-button view-mode-list${this.state.listType === 'list' ? ' active' : ''}`}
              onClick={() => this.changeListType('list')} />
            <div className={`view-mode-button view-mode-grid${this.state.listType === 'grid' ? ' active' : ''}`}
              onClick={() => this.changeListType('grid')} />
          </div>
        </div>
        <>
          {this.state.totalTable.length > 0
            ? <div className='table-wrapper table-auction-results'>
              <div className='table-header'>
                <div>Sex</div>
                <div>Head</div>
                <div>Lots</div>
              </div>
              {this.state.totalTable.map((el, index) => {
                return (
                  <span className={`table-row ${this.state.totalTable.length === 1 && 'single-row'}`} key={index} >
                    <div>{el.sex}</div>
                    <div>{el.counts && el.counts.heads}</div>
                    <div>{el.counts && el.counts.total}</div>
                  </span>
                )
              })}
            </div>
            : null}
          </>
        <div className='lots'>
          <div className='left-side'>
            <InfiniteScroll
              next={this.getData}
              hasMore={hasMore}
              scrollThreshold={0.8}
              dataLength={lots.length}
              className={this.state.listType === 'grid' ? 'lots-list grid' : 'lots-list list'}
              style={{ overflow: 'initial' }}>
              <React.Fragment>

                {
                  auction.requiresTabs ?
                    Object.keys(a).map((section) => {
                      const lots = a[section];
                      return (
                        <div className='lot-group' key={section}>
                          <h3 className='lot-group-header'>{section.split(' - ')[1] || section}</h3>
                          {
                            lots.map(lot => {
                              if (lot.withdrawn) {
                                return (
                                  <LotItemWithdrawn lot={lot} key={lot._id} auction={auction} />
                                )
                              }
                              return (
                                <div className='lot-item' key={lot._id}>
                                  {
                                    this.state.listType === 'grid' && this.renderInfoTop(lot, auction)
                                  }
                                  <div className='lot-img-container'>
                                    <ImageCarousel
                                      lot={lot}
                                      key={this.state.listType}
                                      lotId={lot._id}
                                      media={lot.media}
                                      kindType={auction.kindData.type}
                                      preview />
                                  </div>
                                  <div className='lot-info'>
                                    {
                                      this.state.listType === 'list' && this.renderInfoTop(lot, auction)
                                    }
                                    <div className='lot-info-middle'>
                                      <div className='left'>
                                        <p className='lot-title'>{lot.publicDetails.title} {lot.publicDetails.summary.age} {lot.additionalTitle}</p>
                                        <p className='lot-comments'>{`${limitTextLength(lot.publicDetails.agentComment, calculateLimitTextLength(lot.publicDetails.agentComment, 420), false)} `}
                                          <span className='lot-readMore' onClick={() => {
                                            this.setState({
                                              redirectAgentCommentUrl: this.goToLotLink(lot)
                                            })
                                          }}>
                                            {(lot.publicDetails.agentComment.length > calculateLimitTextLength(lot.publicDetails.agentComment, 420) || lot.publicDetails.agentComment.split(/\r\n|\r|\n/g).length >= 4) ? '...Read More' : ''}
                                          </span>
                                        </p>
                                      </div>
                                      <div className='info-btns'>
                                        <WatchButton lotId={lot._id} bigButton />
                                        {
                                          this.showButton()
                                            ? (
                                              <NavLink className='btn' onClick={() => {
                                                window.localStorage.setItem('upcomingLotsListScrollPosition', window.scrollY)
                                                window.localStorage.setItem('upcomingLotsListCount', lots.length)
                                              }} to={this.goToLotLink(lot)}>
                                                <span>View Details</span>
                                                <span className='icon-wrapper icon-warning'><FontAwesomeIcon icon={faSearch} /></span>
                                              </NavLink>
                                            )
                                            : null
                                        }
                                      </div>
                                    </div>
                                    <div className='lot-info-bottom'>
                                      <div className='info-wrap'>
                                        <LotBottomInfo lot={lot} mode={this.state.listType} />
                                      </div>
                                      <div className='assessor-type-wrapper' style={{ alignSelf: this.state.listType === 'grid' ? 'center' : 'flex-start' }}>
                                        {lot.inlineDetails.assessorType &&
                                          <TooltipContainer>
                                            <Tooltip customCss={css`
                                 white-space: nowrap;
                                 padding-right: 11px;
                               `} content={lot.inlineDetails.assessorType === 'Vendor Assessed' ? 'This lot has been assessed by an accredited vendor' : 'This lot has been assessed by an accredited agent'} placement='left' background={'#2e2e2e'} radius={5} padding={8} >
                                              <LotBoxContainer type={'assessorType'}>
                                                <LotBoxValue>{lot.inlineDetails.assessorType}</LotBoxValue>
                                              </LotBoxContainer>
                                            </Tooltip>
                                          </TooltipContainer>
                                        }
                                        <div className='company-logo-wrapper'>
                                          {
                                            lot.creatorData.logo &&
                                            <img src={lot.creatorData.logo.url} alt='Company logo' />
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>)
                            })}
                        </div>
                      );
                    }) :
                    displayLots.map(lot => {
                      if (lot.withdrawn) {
                        return (
                          <LotItemWithdrawn lot={lot} key={lot._id} auction={auction} />
                        )
                      }
                      return (
                        <div className='lot-item' key={lot._id}>
                          {
                            this.state.listType === 'grid' && this.renderInfoTop(lot, auction)
                          }
                          <div className='lot-img-container'>
                            <ImageCarousel
                              lot={lot}
                              key={this.state.listType}
                              lotId={lot._id}
                              media={lot.media}
                              kindType={auction.kindData.type}
                              preview />
                          </div>
                          <div className='lot-info'>
                            {
                              this.state.listType === 'list' && this.renderInfoTop(lot, auction)
                            }
                            <div className='lot-info-middle'>
                              <div className='left'>
                                <p className='lot-title'>{lot.publicDetails.title} {lot.publicDetails.summary.age} {lot.additionalTitle}</p>
                                {lot.soldBeforeAuction && <LotsListClosedStatus lot={lot} />}
                                <p className='lot-comments'>{`${limitTextLength(lot.publicDetails.agentComment, calculateLimitTextLength(lot.publicDetails.agentComment, 420), false)} `}
                                  <span className='lot-readMore' onClick={() => {
                                    this.setState({
                                      redirectAgentCommentUrl: this.goToLotLink(lot)
                                    })
                                  }}>
                                    {(lot.publicDetails.agentComment.length > calculateLimitTextLength(lot.publicDetails.agentComment, 420) || lot.publicDetails.agentComment.split(/\r\n|\r|\n/g).length >= 4) ? '...Read More' : ''}
                                  </span>
                                </p>
                              </div>
                              <div className='info-btns'>
                                <WatchButton lotId={lot._id} bigButton />
                                {
                                  this.showButton()
                                    ? (
                                      <NavLink className='btn' onClick={() => {
                                        window.localStorage.setItem('upcomingLotsListScrollPosition', window.scrollY)
                                        window.localStorage.setItem('upcomingLotsListCount', lots.length)
                                      }} to={this.goToLotLink(lot)}>
                                        <span>View Details</span>
                                        <span className='icon-wrapper icon-warning'><FontAwesomeIcon icon={faSearch} /></span>
                                      </NavLink>
                                    )
                                    : null
                                }
                              </div>
                            </div>
                            <div className='lot-info-bottom'>
                              <div className='info-wrap'>
                                <LotBottomInfo lot={lot} mode={this.state.listType} />
                              </div>
                              <div className='assessor-type-wrapper' style={{ alignSelf: this.state.listType === 'grid' ? 'center' : 'flex-start' }}>
                                {lot.inlineDetails.assessorType &&
                                  <TooltipContainer>
                                    <Tooltip customCss={css`
                                   white-space: nowrap;
                                   padding-right: 11px;
                                 `} content={lot.inlineDetails.assessorType === 'Vendor Assessed' ? 'This lot has been assessed by an accredited vendor' : 'This lot has been assessed by an accredited agent'} placement='left' background={'#2e2e2e'} radius={5} padding={8} >
                                      <LotBoxContainer type={'assessorType'}>
                                        <LotBoxValue>{lot.inlineDetails.assessorType}</LotBoxValue>
                                      </LotBoxContainer>
                                    </Tooltip>
                                  </TooltipContainer>
                                }
                                <div className='company-logo-wrapper'>
                                  {
                                    lot.creatorData.logo &&
                                    <img src={lot.creatorData.logo.url} alt='Company logo' />
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>)
                    })}
                {displayLots.length % 3 === 2 ? (
                  <div className='lot-item dump' key='dump' />
                ) : (false)}
                {this.state.fetching && <div className={`scroll-loader-wrap ${this.state.fetching ? 'active' : ''}`}>
                  <WanderingCubes className='scroll-loader' color={'gray'} />
                </div>}
              </React.Fragment>
            </InfiniteScroll>
          </div>
          {auction.showCalculator && <Calculator />}
        </div>

        {!displayLots.length && (
          <div className='no-data-placeholder'>
            <div className='message'>
             {isFiltersApplied ? "There are no results for your search." : "Catalogue is being compiled. Check back later."} 
            </div>
          </div>
        )}
      </div>
    )
  }
}

LotsList.propTypes = {
  kinds: PropTypes.array.isRequired,
  auction: PropTypes.object,
  lots: PropTypes.array.isRequired,
  sortChoice: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  changeStateProp: PropTypes.func.isRequired,
  mergeStateProp: PropTypes.func.isRequired,
  showSpinner: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired
}

LotsList.defaultProps = {
}

export default LotsList
