import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import Toggle from 'react-toggle'

import FormField from '../FormField'

class RepeatForBool extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
    this.onChange = this.onChange.bind(this)
  }
  onChange () {
    this.props.onChange(!this.props.formElement.value)
  }
  
  render () {
    return (
      <Fragment>
        <TopSwitch
          title={this.props.formElement.title}
          value={this.props.formElement.value}
          invalid={this.props.formElement.invalid}
          abbLambSale = {this.props.auction.kindData.type === 'sheep-abblamb'}
          publicId = { this.props.formElement.publicId }
          isRequired={this.props.formElement.isRequired}
          onChange={this.onChange}
        />
        {
          this.props.formElement.value
            ? (
              <div className='rfb-rows'>
                {
                  this.props.formElement.values[0].map((repeatItem, index) =>
                    <FormField
                      {...this.props}
                      key={index}
                      index={index}
                      path={`${this.props.path}.values.0.${index}`}
                      formElement={repeatItem}
                    />
                  )
                }
              </div>
            )
            : null
        }
      </Fragment>
    )
  }
}

class TopSwitch extends PureComponent {
  render () {
    let isTogglerender = this.props.publicId === 'grazingConditions' && this.props.abbLambSale ? false : true
    return (
      <div className='form-row'>
        <div className='row-title'>
          <p
            className={`row-text ${this.props.invalid ? 'row-text-invalid' : ''}`}>
            {this.props.title}{this.props.isRequired ? <span className='asterisk'>*</span> : ''}{this.props.title.indexOf('?') > 0 ? '' : ':'}
          </p>
        </div>
        {isTogglerender &&  
          <div className='row-content'>
            <div className='switcher'>
              <p
                className='row-text'>No</p>
              <Toggle
                icons={false}
                checked={this.props.value}
                onChange={this.props.onChange} />
              <p
                className='row-text'>Yes</p>
            </div>
          </div>
        }

      </div>
    )
  }
}

TopSwitch.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.bool,
  invalid: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool
}

TopSwitch.defaultProps = {
  invalid: false,
  isRequired: false
}

RepeatForBool.propTypes = {
  formElement: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

RepeatForBool.defaultProps = {}

export default RepeatForBool
