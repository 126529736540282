export const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
//export const PASSWORD_REGEXP = /^[0-9a-zA-Z@%+/’!#$^?:,(){}[\]~_-]+$/
export const PASSWORD_REGEXP = /^[0-9a-zA-Z@%+/’!#$^?:,(){}[\]~_&-]+$/
export const FIRST_LAST_NAME = /^[&0-9a-zA-Z \-'"’”]+$/
export const ADDRESS = /^[a-zA-Z0-9\s,.'-/\\]+$/
export const NUMBER_REGEXP = /^\d+$/
export const FAVORITE_PIC_PHONE_REGEXP = /^[0-9]{10}/
export const PHONE_REGEXP = /^04[0-9]{8}/
export const PHONE_SECOND_REGEXP = /\(?([0-9]{5})\)?([ .-]?)([0-9]{5})/
export const WEBSITE_REGEXP = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
export const AUSTRALIAN_POSTCODE = /^\d{4}$/
export const AUSTRALIAN_POSTCODE_SOFT = /^\d{0,4}$/
export const PIC_REGEXP = /^[0-9A-Z]{8}$/
export const ADJUST_WEIGHT_RANGE = { max: 20, min: -20 }
export const NUMBER_REGEXP_WITH_MINUS = /^[-+]?\d*$/

export const ACCOUNT_NAME_NUMBER = /^[&0-9a-zA-Z \-'"’”]+$/
//export const BSB = /^\d{6}$/
export const BSB = /^\d+$/

export const NSW_REGEXP = /^N[A-K]{1}[0-9]{6}$/
export const QLD_REGEXP = /^Q[A-Z]{3}[0-9]{4}$/
export const VIC_REGEXP = /^3[A-Z]{4}[0-9]{3}$/
export const WA_REGEXP = /^W[A-Z]{3}[0-9]{4}$/
export const SA_REGEXP = /^S[ABCK]{1}[0-9]{6}$/
export const NT_REGEXP = /^T[A-Z]{3}[0-9]{4}$/
export const TAS_REGEXP = /^M[A-Z]{3}[0-9]{4}$/

export const PIC_REGEXP_SOFT = /^[0-9A-Z]{0,8}$/
export const ABN_REGEXP = /^\d{11}$/
export const ACN_REGEXP = /^\d{9}$/
export const ABN_REGEXP_SOFT = /^\d{0,11}$/
export const ACN_REGEXP_SOFT = /^\d{0,9}$/
export const ADDRESS_REGEXP = /^[0-9a-zA-Z \-'"’”]+$/
export const FLOAT_REGEXP = /^[0-9]*\.?[0-9]*$/
export const INTEGER_REGEXP = /^[0-9]*$/
// Redux
export const CHANGE_STATE_PROP = '_CHANGE_STATE_PROP'
export const MERGE_STATE_PROP = '_MERGE_STATE_PROP'
export const FILTER_STATE_PROP = '_FILTER_STATE_PROP'
export const TEMP_RESTORE_DRAFT_LOT = 'TEMP_RESTORE_DRAFT_LOT'
export const USER_LOGOUT = 'USER_LOGOUT'
export const SHOW_SPINNER = 'SHOW_SPINNER'
export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
export const CLOSE_USER_NOTIFICATION = 'CLOSE_USER_NOTIFICATION'
export const SHOW_SINGLE_NOTIFICATION = 'SHOW_SINGLE_NOTIFICATION'
export const HIDE_SINGLE_NOTIFICATION = 'HIDE_SINGLE_NOTIFICATION'
export const CLOSE_SINGLE_NOTIFICATION = 'CLOSE_SINGLE_NOTIFICATION'
export const UPDATE_LOT_INFO = 'UPDATE_LOT_INFO'
export const SHOW_SLOW_INTERNET_CONNECTION = 'SHOW_SLOW_INTERNET_CONNECTION'
export const HIDE_SLOW_INTERNET_CONNECTION = 'HIDE_SLOW_INTERNET_CONNECTION'
export const MAX_HEADS = 20000
export const MAX_HEADS_CATTLE = 2000
export const MAX_HEADS_WEIGHED = 2000
export const MAX_WEIGHT = 10000000
export const MAX_VENDORBIDS = 1000000
export const MIN_WEIGHED_HEADS_PERCENTAGE = 20
export const bidTypes = {
  HEAD: 'head',
  KG: 'kg'
}
export const apiHeaders = {
  LOT_PAGE_VIEW: 'farmgate-lot-page-view'
}
export const AUCTION_ADD_LIMIT = 10 // days
export const AUCTION_REOFFER_LIMIT = 7 // days
export const LOT_TIMER_ENDING_THRESHOLD = 1000 * 60 // ms
export const LOT_TIMER_ENDING_THRESHOLD_SEQUENTIAL = 1000 * 10 // ms
export const FORM_ABBREVIATIONS = {
  '4+ Teeth': '4+T',
  'Calves': 'Cal',
  'Commercial': 'Com',
  'Hoggets': 'Hog',
  'Lambs': 'Lamb',
  'NSM': 'NSM',
  'PTE': 'PTE',
  'PTIC': 'PTIC',
  'Scanned in Lamb': 'SIL',
  'Scanned not in Lamb': 'SNIL',
  'Stud': 'Stud',
  'Vealers': 'Veal',
  'Weaners': 'Wean',
  'Yearlings': 'Year',
  'Station Mated': 'STM',
  'Doeling': 'Doe',
  'Scanned in Kid': 'SIK',
  'Scanned Not in Kid': 'SNIK',
  'Buckling': 'Buck',
  'Kids': 'Kid',
  'Scanned Kidding': 'sc/kid',
  'Scanned Empty': 'sc/empt'
}
export const SIRE_DAM_ABBREAVIATIONS = {
  Grown: 'Grown',
  'Angus': 'Ang',
  'Australian Lowline': 'Aust Low',
  'Ayrshire': 'Ayrshire',
  'Belmont': 'Belm',
  'Belted Galloway': 'Belt Gal',
  'Black Baldy': 'Blk Bld',
  'Blonde d’Aquitaine': 'Blonde d Aq',
  'Bonsmara': 'Bonsma',
  'Braford': 'Braf',
  'Brahman': 'Brah',
  'Brangus': 'Brang',
  'British White': 'Brit White',
  'Charbray': 'Charb',
  'Charolais': 'Charo',
  'Chianina': 'Chianina',
  'Devon': 'Dev',
  'Dexter': 'Dext',
  'Droughtmaster': 'Drought',
  'Fleckvieh': 'Fleck',
  'Friesian': 'Fries',
  'Galloway': 'Gallo',
  'Gelbvieh': 'Gelbv',
  'Guernsey': 'Guern',
  'Hereford': 'Heref',
  'Highland': 'HighL',
  'Holstein': 'Holst',
  'Horned Hereford': 'H Here',
  'Illawarra': 'Illawa',
  'Jersey': 'Jers',
  'Limousin': 'Limo',
  'Maine-Anjou': 'Man-An',
  'Murray Grey': 'MG',
  'Pinzgauer': 'Pinz',
  'Poll Hereford': 'P Here',
  'Red Angus': 'R Angus',
  'Red Brangus': 'R Bran',
  'Red Composite': 'R Comp',
  'Red Composite ': 'R Comp',
  'Romagnola': 'Roma',
  'Salers': 'Salers',
  'Santa Gertrudis': 'Sta Gert',
  'Senepol': 'Senpo',
  'Shorthorn': 'Short H',
  'Simbrah': 'Simb',
  'Simmental': 'Sim',
  'South Devon': 'Sou Dev',
  'Speckle Park': 'Speck Pk',
  'Square Meater': 'SMeat',
  'Texas Longhorn': 'Texas',
  'Wagyu': 'Wagyu',
  'Australian White': 'Aust White',
  'Awassi': 'Awassi',
  'Border Leicester': 'B.L.',
  'Cheviot': 'Chev',
  'Composite': 'Comp',
  'Coolalee': 'Coola',
  'Corriedale': 'Corrie',
  'Cross': 'Cross',
  'Cross Bred': 'X Bred',
  'Damara': 'Damara',
  'Dohne': 'Dohne',
  'Dorper': 'Dorp',
  'Dorset': 'Dorst',
  'Dorset Horn': 'Dorst H',
  'East Friesian': 'East Fries',
  'Finnsheep': 'Finn',
  'First Cross': '1st X',
  'Hampshire Down': 'Hamps Down',
  'Lincoln': 'Linc',
  'Merino': 'Mer',
  'Poll Dorset': 'P Dorst',
  'Poll Merino': 'P Mer',
  'Primeline': 'Pri Line',
  'Romney': 'Romm',
  'Ryeland': 'Rylnd',
  'Second Cross': '2nd X',
  'South African Meat Merino': 'Sam',
  'South Suffolk': 'South Suff',
  'Southdown': 'S down',
  'Suffolk': 'Suff',
  'Texel': 'Tex',
  'Tradie': 'Tradie',
  'Van Rooy': 'V Rooy',
  'White Dorper': 'White Dor',
  'White Suffolk': 'White Suff',
  'Wiltipoll': 'Wiltipoll',
  'Wiltshire Horn': 'Wilts Hrn',
  'Doeling': 'Doe',
  'Scanned in Kid': 'SIK',
  'Scanned Not in Kid': 'SNIK',
  'Buckling': 'Buck',
  'Kids': 'Kid'
}
export const WEANING_DETAILS_EXCEPTIONS = ['Calves', 'Vealers', 'Weaners', 'Yearlings', 'Lambs']
export const LOT_HIDDEN_FIELDS = ['district', 'animalIndividualId', 'animalTeeth', 'animalHorn', 'animalBlemishes']

export const ALLOW_ACCESS_OBJECT = {
  'registered': [ 'myProfile','registerToBuy','registerToBuyAndSell'],
  'registered-updated': [],
  'approved-buyer': [ 'myProfile', 'myAuctionResults','registerToBuyAndSell'],
  'approved-buyer-updated': [ 'myProfile', 'myAuctionResults'],
  'approved-seller': ['myProfile', 'myAuctionResults', 'mySubmittedLots', 'myReoffers', 'myListings', 'myFavouritePICs', 'addNewLot', 'editLots','registerToBuy','registerToBuyAndSell'],
  'approved-buyer-seller' : ['myProfile', 'myAuctionResults', 'mySubmittedLots', 'myReoffers', 'myListings', 'myFavouritePICs', 'addNewLot', 'editLots']
}