import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { WanderingCubes } from 'better-react-spinkit'
import update from 'immutability-helper'
import axios from 'axios'
import memoize from 'memoize-one'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faCaretDown,
  faTimes
} from '@fortawesome/fontawesome-free-solid'

import Api from '../../../services/api'
import InfiniteScroll from 'react-infinite-scroll-component'
import FilterKind from '../../FilterKind/FilterKind'
import FilterMultiSelect from '../../FilterMultiSelect/FilterMultiSelect'
import { momentTz, limitTextLength, calculateLimitTextLength } from '../../../utils'
import { getSectionHeaderTitle } from '../../../utils/Auctions'
import { filterAuctions } from '../../../utils/Filter'

import bookImage from '../../../assets/img/book.png'
import cowImage from '../../../assets/img/cowauction.png'
import lambImage from '../../../assets/img/lambauction.png'
import goatImage from '../../../assets/img/goat-image.png'
import { subTitles } from '../../../constants/strings'
import { findFieldByPublicId } from "../../../utils/FindFieldKind"

class AuctionsList extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      page: 1,
      limit: 10,
      hasMore: true,
      fetching: false,
      auctions: [],
      requestFinish: true,
      filters: {
        screen: this.getScreen(),
        showMode: this.getSavedFilterValue('showMode', 'all'),
        state: this.getSavedFilterValue('state', []),
        assessorType: this.getSavedFilterValue("assessorType", [])
      },
      kinds: [],
    }
    this.cancelSource = axios.CancelToken.source()
    this.getAuctions = this.getAuctions.bind(this)
    this.renderItem = this.renderItem.bind(this)
    this.getFilteredAuctions = memoize(this.getFilteredAuctions)
    this.changeShowMode = this.changeShowMode.bind(this)
    this.filterChangeHandler = this.filterChangeHandler.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
  }
  componentDidMount () {
    if (this.props.history.action !== 'POP') {
      window.scroll(0, 0)
    }
    this.getAuctions();
    this.getKinds();
  }
  getKinds() {
    Api.getKinds().then((data) => {
      this.setState({
        kinds: data.kinds,
      });
    });
  }
  componentDidUpdate (prevProps, prevState) {
    let scrollPosition = parseInt(window.localStorage.getItem('savedResultsAuctionsScrollPosition')) || 0
    let countOfAuctions = parseInt(window.localStorage.getItem('savedResultCountOfAuctions')) || 0
    if (countOfAuctions !== 0 && scrollPosition !== 0) {
      window.scrollTo(0, scrollPosition)
      if (this.state.auctions.length === countOfAuctions) {
        window.localStorage.clear()
      }
    }
    if (this.state.filters !== prevState.filters) {
      this.props.mergeStateProp('filters', [this.state.filters], 'user')
    }
  }
  componentWillUnmount () {
    this.cancelSource.cancel()
  }
  getScreen () {
    return 'resultsAuctionsList'
  }
  getSavedFilterValue (key, defaultValue = []) {
    let screen = this.getScreen()
    let thisFilter = this.props.filters.find(filterSet => filterSet.screen === screen)
    let value
    if (thisFilter) {
      value = thisFilter[key]
    }
    return value || defaultValue
  }
  getAuctions () {
    const { page, limit, requestFinish, auctions } = this.state
    if (requestFinish) {
      let sortType = 'liveAt'
      let sortDirection = -1
      this.setState({
        fetching: true,
        hasMore: false,
        requestFinish: false
      })
      Api.getResults(page, limit, sortType, sortDirection, { cancelToken: this.cancelSource.token })
        .then(data => {
          if (data && !data.cancel) {
            let newAuctions = data.auctions.docs
            this.setState({
              auctions: auctions.length ? auctions.concat(newAuctions) : newAuctions,
              page: page + 1,
              hasMore: parseInt(data.auctions.page, 10) < data.auctions.pages,
              fetching: false,
              requestFinish: true
            })
          }
        })
    }
  }
  resetState () {
    this.setState({
      page: 1,
      auctions: []
    }, () => { this.getAuctions() })
  }
  getAuctionStatus (auction) {
    let status
    switch (auction.state) {
      case 'closed':
        status = 'Closed'
        break
      case 'open':
        status = 'Open'
        break
      case 'live':
        status = 'Live'
        break
      case 'future':
      default:
        status = 'Upcoming'
        break
    }
    return status
  }
  getAuctionState (auction) {
    return auction.location.state.join('/')
  }
  getAuctionType (auction) {
    switch (auction.auctionType) {
      case 'simultaneous':
        return 'SIM'
      case 'sequential':
        return 'SEQ'
      default:
        return ''
    }
  }
  getFilteredAuctions (auctions, filters) {
    let filteredAuctions = auctions.filter(auction => {
      return filters.showMode === 'all'
        ? true
        : auction.kindData.type === filters.showMode
    })

    return filterAuctions(filteredAuctions, filters)
  }
  clearFilter () {
    this.setState(update(this.state, {
      filters: {
        showMode: { $set: 'all' },
        state: { $set: [] },
        assessorType: { $set: [] }
      }
    }))
  }
  filterChangeHandler (filterName, value) {
    this.setState({
      filters: update(this.state.filters, {
        [filterName]: { $set: value }
      })
    })
  }
  changeShowMode (showMode) {
    this.setState({
      filters: update(this.state.filters, {
        showMode: { $set: showMode }
      })
    })
  }
  goToLink (auction) {
    return `/main/results/${auction.kindData.title}/${auction.searchNumber}`
  }
  groupAuctionsByDate (auctions) {
    // assume auctions already sorted by date
    let groupedAuctions = auctions.reduce((r, auction) => {
      let title = getSectionHeaderTitle(auction.liveAt)
      let group = r.find(gr => gr.title === title)
      if (!group) {
        group = { title: title, auctions: [] }
        r.push(group)
      }
      group.auctions.push(auction)
      return r
    }, [])

    return groupedAuctions
  }
  renderItem (auction) {
    return (
      <div className='auction-item' key={auction._id}>
        <div className='auction-img-container'>
          {
            auction.image ? (
              <img className='auction-img' src={auction.image.url} alt='Auction img' />
            ) : (
              <span className={`no-image ${auction.kindData.type}`} alt='Lot'>No image</span>
            )
          }
        </div>
        <div className='auction-info'>
          <div className='auction-info-top'>
            <div>
              <h3 className='auction-title'>{auction.title}</h3>
              { auction.state === 'open' && <p className='auction-live-info'>Auction is open</p> }
              { auction.state === 'live' && <p className='auction-live-info'>Auction is live</p> }
              { auction.state !== 'live' && auction.state !== 'open' && (
                <div>
                  <p className='auction-live-date'>{momentTz(auction.liveAt).format('dddd D MMMM YYYY - h.mm A')}</p>
                </div>
              )}
            </div>
            <div className='info-btns'>
              <NavLink className='btn' to={this.goToLink(auction)} onClick={() => {
                window.localStorage.setItem('savedResultsAuctionsScrollPosition', window.scrollY)
                window.localStorage.setItem('savedResultCountOfAuctions', this.state.auctions.length)
                window.localStorage.removeItem('savedResultsScrollPosition')
                window.localStorage.removeItem('savedResultCountOfLots')
              }}>
                <span>View Results</span>
                <span className='icon-wrapper icon-warning'><img src={bookImage} alt='Book icon' /></span>
              </NavLink>
            </div>
          </div>
          <div className='auction-info-middle'>
            <div className='auction-type'>
              { auction.kindData.type === 'cattle' && <img src={cowImage} alt='Cattle' /> }
              { auction.kindData.type === 'sheep' && <img src={lambImage} alt='Sheep' className='icon-blackened' /> }
              { auction.kindData.type === 'goat' && <img src={goatImage} alt='Goat' /> }
            </div>
            <div className='auction-desc'>
              { limitTextLength(auction.descriptionShort, calculateLimitTextLength(auction.descriptionShort, 150), false) }
              <span className='auction-readMore' onClick={() => {
                this.props.history.push(this.goToLink(auction))
                window.localStorage.setItem('savedResultsAuctionsScrollPosition', window.scrollY)
                window.localStorage.setItem('savedResultCountOfAuctions', this.state.auctions.length)
                window.localStorage.removeItem('savedResultsScrollPosition')
                window.localStorage.removeItem('savedResultCountOfLots')
              }}>
                {(auction.descriptionShort && (auction.descriptionShort.length > calculateLimitTextLength(auction.descriptionShort, 150) || auction.descriptionShort.split(/\r\n|\r|\n/g).length >= 4)) ? '...Read More' : ''}
              </span>
            </div>
            <div className='auction-logo'>
              {auction.logo && <img src={auction.logo.url} alt='Auction Logo' />}
            </div>
          </div>
          <div className='auction-info-bottom'>
            <span className='auction-number'>Auction No. {auction.number}</span>
            <span className='status'>Status: {this.getAuctionStatus(auction)}</span>
            <span className='location-state'>{this.getAuctionState(auction)}</span>
            <span className='heads'>{auction.headCount} Head</span>
            <span className='lots'>{auction.lotCount} Lots</span>
          </div>
        </div>
      </div>
    )
  }
  renderLockedItem (auction) {
    return (
      <div className='locked-item' key={auction._id}>
        <span className='result'>cancelled</span>
        {this.renderItem(auction)}
      </div>
    )
  }
  selectOptions(kinds) {
   let assessorTypesArray = [];
    if (kinds.length > 0) {
      let cattleKind = kinds.find(item => item.type === 'cattle')
      assessorTypesArray = findFieldByPublicId(cattleKind.form.forms, "assessorType").options
    }
    return {
      assessorType: assessorTypesArray
    }
  }
  render () {
    const { locationStates } = this.props
    const { filters, auctions, hasMore,kinds } = this.state
    const { showMode, state,assessorType } = filters
    const selectOptions = this.selectOptions(kinds, auctions,showMode);
    let filteredAuctions = this.getFilteredAuctions(auctions, filters)
    return (
      <div className='auctions-wrapper'>
        <h1 className='fg-header'>Auction Results</h1>
        <p className='fg-paragraph'>{subTitles.UPCOMING_RESULTS}</p>
        <div className='auctions-controls'>
          <h3 className='filters-heading'>Filters</h3>
          <div className='auctions-filters-wrapper'>
            <FilterKind changeCategoryChoice={this.changeShowMode} categoryChoice={showMode} />
            <FilterMultiSelect
              title='State'
              name='state'
              options={locationStates}
              selectedOptions={state}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faCaretDown} size='lg' />}
              buttonLg
            />
            <FilterMultiSelect
              title="Assessor Type"
              name="assessorType"
              options={selectOptions.assessorType}
              selectedOptions={assessorType}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faCaretDown} size="lg" />}
              buttonLg
            />
            <div
              className='btn filter-btn filter-btn-sm clear-all-btn'
              onClick={this.clearFilter}
            >
              <span>Clear All</span>
              <span className='icon-wrapper icon-danger'><FontAwesomeIcon icon={faTimes} /></span>
            </div>
          </div>
        </div>
        <InfiniteScroll
          next={this.getAuctions}
          hasMore={hasMore}
          scrollThreshold={0.8}
          dataLength={auctions.length}
          style={{ overflow: 'initial' }}>
          <div className='auctions-list list'>
            {this.groupAuctionsByDate(filteredAuctions).map(group => {
              return (
                <section className='auctions-group' key={group.title}>
                  <p className='group-title'>{ group.title }</p>
                  {group.auctions.map(auction => {
                    if (auction.cancelled) {
                      return this.renderLockedItem(auction)
                    } else {
                      return this.renderItem(auction)
                    }
                  })}
                </section>
              )
            })}
            {
              (!this.state.fetching && !this.state.auctions.length) || !filteredAuctions.length
                ? (
                  <p className='auctions-empty'>
                    Results for closed auctions will appear here.
                  </p>
                )
                : null
            }

          </div>
          <div className={`scroll-loader-wrap ${this.state.fetching ? 'active' : ''}`}>
            <WanderingCubes className='scroll-loader' color={'gray'} />
          </div>
        </InfiniteScroll>
      </div>
    )
  }
}

AuctionsList.propTypes = {
  changeStateProp: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}
AuctionsList.defaultProps = {
}

export default AuctionsList
