import React, { Component } from 'react'
import { Circle } from 'better-react-spinkit'

class Loading extends Component {
  render () {
    if (this.props.spinner) {
      return (
        <div className='app-spinner-overlay'>
          <div className='app-spinner'>
            <Circle size={window.innerWidth > 500 ? 150 : 70} />
          </div>
        </div>
      )
    } else {
      return (
        <div />
      )
    }
  }
}

export default Loading
