import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Profile from './Profile'
import { changeStateProp, showModal, showSpinner } from '../../actions'

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    locationStates: state.data.locationStates
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeStateProp, showModal, showSpinner }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile)
