import React, { PureComponent } from 'react'

import Api from '../../../services/api'
import Section from '../Section/Section'
import BackButton from '../../BackButton/BackButton'

class Conditions extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      terms: []
    }
  }

  componentDidMount () {
    window.scroll(0, 0)
    this.getData()
  }

  getData () {
    this.props.showSpinner(true)
    Api.getTerms()
      .then(res => {
        if (res) {
          this.setState({
            terms: this.convertData(res.terms)
          })
        }
        this.props.showSpinner(false)
      })
  }

  convertData (data) {
    return data.map(item => {
      return {
        categoryName: item.categoryName,
        terms: { text: item.content }
      }
    })
  }
  render () {
    const { terms } = this.state
    return (
      <div className='conditions'>
        <h1 className='fg-header'>Terms & Conditions</h1>
        <div className='top-btns'>
          <div className='left'>
            <BackButton onPress={() => {
              this.props.history.goBack()
              this.props.changeStateProp('cameFromBuyerAndSellerForms',false,'user')
            }} name={`${this.props.cameFromBuyerAndSellerForms ? 'Back to Form' : "Back to Tools"}`} />
          </div>
        </div>
        <div className='terms-container'>
          {
            terms.map((item, index) => {
              return <Section key={index} title={item.categoryName} text={[item.terms]} />
            })
          }
        </div>
    </div>
    )
  }
}

export default Conditions
