import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { kindByTitle } from '../../selectors/kindsSelector'
import { lotEditAuctions } from '../../selectors/auctionsSelector'
import { hasOtherDrafts } from '../../selectors/lotsSelector'
import {
  showSpinner,
  mergeStateProp,
  showModal,
  changeStateProp,
  filterStateProp
} from '../../actions/index'
import LotForm from './LotForm'

// any new fields added to mapStateToProps return value
// should also be mirrored in EditReoffer and EditLate
// because they extend LotForm
const mapStateToProps = (state, ownProps) => {
  let kind = kindByTitle({
    kinds: state.data.kinds,
    kindTitle: ownProps.match.params.kindTitle
  })
  let auctions = lotEditAuctions({
    auctions: state.data.auctions,
    kindId: kind ? kind._id : null
  })
  return {
    kind,
    auctions,
    user: state.user,
    hasOtherDrafts: hasOtherDrafts({
      lots: state.data.lots,
      lotId: ownProps.match.params.draftId
    }),
    locationStates: state.data.locationStates,
    locationDistricts: state.data.locationDistricts,
    stockCategories: state.data.stockCategories,
    saveAndLogout: state.temp.saveAndLogout,
    rememberedLotsWithIndex: state.main.rememberedLotsWithIndex
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    showSpinner,
    mergeStateProp,
    showModal,
    changeStateProp,
    filterStateProp
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LotForm)
