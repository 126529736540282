import React from 'react'
import NavigationSingle from './NavigationSingle/NavigationSingle'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faUser,
  faIdCard
} from '@fortawesome/fontawesome-free-solid'

const NavigationTop = props => {
  const hrLine = (
    <div className='auth-navigation-hr'>
      <hr
        size='1'
        width='100%'
        color='#000'
      />
    </div>
  )
  return (
    <div className='auth-navigation-wrapper'>
      <NavigationSingle isActive={props.activeNav === 'step1'}>
        <FontAwesomeIcon
          className='navigation-single-icon'
          icon={faUser}
        />
      </NavigationSingle>
      {hrLine}
      {/* {!props.twoSteps &&

        <NavigationSingle isActive={props.activeNav === 'step2'}>
          <FontAwesomeIcon
            className='navigation-single-icon'
            icon={faMapMarkerAlt}
          />
        </NavigationSingle>
      } */}
      {!props.twoSteps && hrLine}
      <NavigationSingle isActive={props.activeNav === 'step2'}>
        <FontAwesomeIcon
          className='navigation-single-icon'
          icon={faIdCard}
        />
      </NavigationSingle>
    </div>
  )
}

export default NavigationTop
