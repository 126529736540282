import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { watchlistLots } from '../../../selectors/lotsSelector'
import { mergeStateProp, showSpinner } from '../../../actions'
import WatchlistInfo from './WatchlistInfo'

const mapStateToProps = (state) => {
  return {
    kinds: state.data.kinds,
    auctions: state.data.auctions,
    lots: watchlistLots({
      watchList: state.user.data.watchList2,
      lots: state.data.lots
    }),
    locationStates: state.data.locationStates,
    userId: state.user.data._id,
    filters: state.user.filters
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ mergeStateProp, showSpinner }, dispatch)
}

const WatchlistInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WatchlistInfo)

export default WatchlistInfoContainer
