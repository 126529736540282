import React, { PureComponent } from 'react'
import Section from '../Section/Section'
import Api from '../../../services/api'
import BackButton from '../../BackButton/BackButton'

class Videos extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      videos: []
    }
  }

  componentDidMount () {
    window.scroll(0, 0)
    this.getData()
  }

  getData () {
    this.props.showSpinner(true)
    Api.getVideos()
      .then(res => {
        if (res) {
          this.setState({
            videos: res['how-to-videos']
          })
        }
        this.props.showSpinner(false)
      })
  }

  render () {
    return (
      <div className='tips'>
        <h1 className='fg-header'>How-To Videos & Tips</h1>
        <div className='top-btns'>
          <div className='left'>
            <BackButton onPress={() => {
              this.props.history.goBack()
            }} name='Back to Tools' />
          </div>
        </div>
        <div className='policy-container tips-container'>
          { this.state.videos.map((section, index) => {
            return (
              <div key={index}>
                <Section videos={section.videos} titles title={section.name} />
                <Section title={section.title} text={[{ title: section.description, text: section.subDescription }]} />
              </div>
            )
          }) }
        </div>
      </div>
    )
  }
}

export default Videos