import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { NavLink, Redirect } from 'react-router-dom'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faUser,
  faEdit
} from '@fortawesome/fontawesome-free-solid'

import BackButton from '../../BackButton/BackButton'
import FilterKind from '../../FilterKind/FilterKind'
import { bidTypes } from '../../../constants'
import ImageCarousel from '../../ImageCarousel/ImageCarousel'

import cowImage from '../../../assets/img/cowauction.png'
import lambImage from '../../../assets/img/lambauction.png'
import goatImage from '../../../assets/img/goat-image.png'
import { makePrice } from '../../../utils/Lots'
import Api from '../../../services/api'
import { limitTextLength, momentTz } from '../../../utils'
import InfiniteScroll from 'react-infinite-scroll-component'
import ScrollToTop from '../../ScrollToTop/ScrollToTop'
import { WanderingCubes } from 'better-react-spinkit'
import LotBottomInfo from '../../LotBottomInfo/LotBottomInfo'
import { subTitles } from '../../../constants/strings'

class MyReoffers extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      data: [],
      loaded: false,
      page: 1,
      limit: 10,
      hasMore: true,
      fetching: false,
      sortType: 'liveAt',
      sortDirection: -1,
      redirectAgentCommentUrl: '',
      requestFinish: true,
      lots: [],
      filters: {
        showMode: 'all'
      }
    }

    this.changeShowMode = this.changeShowMode.bind(this)
    this.getResults = this.getResults.bind(this)

    this.props.showSingleNotification('my-reoffers')
  }

  componentDidMount () {
    this.getResults()
  }

  componentWillUnmount () {
    this.props.hideSingleNotification('my-reoffers')
  }

  async getResults () {
    const { page, limit, sortType, sortDirection, requestFinish } = this.state
    if (requestFinish) {
      this.setState({
        fetching: true,
        hasMore: false,
        requestFinish: false
      })
      let res = await Api.getMyReoffers(
        page,
        limit,
        sortType,
        sortDirection
      )
      if (res) {
        let { headers } = res
        let currentPage = headers['x-current-page']
        let totalPages = headers['x-total-pages']
        let newData
        let oldData = this.state.data
        if (this.state.data.length && res.data.length && oldData[oldData.length - 1].auction.number === res.data[0].auction.number) {
          oldData[oldData.length - 1].lots = oldData[oldData.length - 1].lots.concat(res.data[0].lots)
          newData = oldData.concat(res.data.splice(1))
        } else {
          newData = this.state.data.concat(res.data)
        }
        this.setState({
          data: newData,
          loaded: true
        }, () => {
          let lots = this.state.lots
          res.data.forEach((auction) => {
            lots = lots.concat(auction.lots)
          })
          this.setState({
            lots,
            page: this.state.page + 1,
            hasMore: parseInt(currentPage, 10) < totalPages,
            fetching: false
          })
        })
      } else {
        this.setState({
          loaded: true
        })
      }
      this.setState({
        requestFinish: true
      })
      this.props.showSpinner(false)
    }
  }
  changeShowMode (showMode) {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        showMode
      }
    }))
  }

  getLotLocation (lot) {
    let addresses = []
    let possibleLocations = ['town', 'state']
    possibleLocations.forEach(loc => {
      let str = lot.publicDetails.location[loc]
      if (str) {
        addresses.push(str)
      }
    })
    return addresses.join(', ')
  }

  editLink (lot) {
    return `/main/manage/edit-reoffers/${lot.kindData.title}/${lot.auctionData.searchNumber}/${lot.searchNumericId}`
  }

  getBidding (bidding) {
    switch (true) {
      case bidding === bidTypes.KG:
        return 'Kg'
      case bidding === bidTypes.HEAD:
      default:
        return 'Head'
    }
  }

  getLotClosedState (lot) {
    let statuses = {
      noBid: 'No Bids',
      passed: 'Passed In'
    }
    let state = statuses[lot.status] || ''
    if (state) {
      let closedStr = `${makePrice(lot.closedPrice, { bidding: lot.bidding })}/${this.getBidding(lot.bidding)}`
      return (
        <p className={`lot-closed-state ${lot.status === 'passed' && 'passed'} ${lot.status === 'sold' && 'lot-closed-success'}`}>
          <strong>{state}</strong> {closedStr}
        </p>
      )
    }
  }

  calculateDiffSevenDays (date) {
    let diffrence = new Date().getTime() - new Date(date).getTime()
    let days = diffrence / 1000 / 60 / 60 / 24
    let res = parseInt(days, 10) <= 7
    return res
  }

  goToLotLink (lot) {
    return `/main/manage/${lot.kindData.title}/${lot.auctionData.searchNumber}/${lot.searchNumericId}`
  }

  render () {
    const { filters, hasMore, lots } = this.state
    const { showMode } = filters
    return (
      <ScrollToTop xCoord={0} yCoord={0}>
      <div className='submitted-wrapper'>
        {this.state.redirectAgentCommentUrl ? <Redirect
          to={{
            pathname: this.state.redirectAgentCommentUrl,
            state: { scroll: 10000 }
          }}
        /> : ''}
        <h1 className='fg-header'>My Reoffers</h1>
        <p className='fg-paragraph'>{subTitles.MY_REOFFERS}</p>
        <div className='top-btns'>
          <div className='left'>
            <BackButton onPress={() => {
              this.props.history.goBack()
            }} name='Back to My Admin' />
          </div>
        </div>
        <div className='watchlist-controls'>
          <h3 className='filters-heading'>Filters</h3>
          <div className='watchlist-filters-wrapper'>
            <div>
              <FilterKind categoryChoice={showMode} changeCategoryChoice={this.changeShowMode} />
            </div>
          </div>
        </div>
        <div className='watch-info'>
          <InfiniteScroll
            next={this.getResults}
            hasMore={hasMore}
            scrollThreshold={0.8}
            dataLength={lots.length}
            style={{ overflow: 'initial' }}>
            <React.Fragment>
              {this.state.data.map((res, i) => {
                let { lots } = res
                let { auction, auctionData, kindData } = lots[0]
                if ((showMode === 'all' || kindData.type === showMode) && this.calculateDiffSevenDays(auctionData.closedAt)) {
                  return (
                    <div className='watch-auction' key={`${auction}${i}`}>
                      <div className='watch-auction-info-top'>
                        <div>
                          <div className='watch-auction-kind'>
                            {kindData.type === 'sheep' && <img src={lambImage} alt='Sheep icon' />}
                            {kindData.type === 'cattle' &&
                            <img src={cowImage} alt='Cattle icon' className='cattle-icon' />}
                            {kindData.type === 'goat' && <img src={goatImage} alt='Goat icon' className='goat-icon' />}
                          </div>
                          <div className='watch-auction-info'>
                            <h3>{auctionData.title}</h3>
                            <p className='auction-live-date'>{momentTz(auctionData.liveAt).format('dddd D MMMM YYYY - h.mm A')}</p>
                          </div>
                        </div>
                      </div>
                      <div className='watch-lots'>
                        {lots.map((lot, i) => {
                          return (
                            <div className='lot-item' key={`${lot._id}${i}`}>
                              <div className='lot-img-container'>
                                <ImageCarousel
                                  lot={lot}
                                  lotId={lot._id}
                                  media={lot.media}
                                  kindType={kindData.type}
                                  preview />
                              </div>
                              <div className='lot-info'>
                                <div className='lot-info-top'>
                                  <div className='left'>
                                    <p className='lot-number'>
                                  Lot X
                                      <span className='my-listing'>
                                    -
                                        <span className='icon-wrapper'>
                                          <FontAwesomeIcon icon={faUser} />
                                        </span>
                                        <span className='my-listing-text'>
                                      My Listing
                                        </span>
                                      </span>
                                    </p>
                                  </div>
                                  <p className='lot-location'>{this.getLotLocation(lot)}</p>
                                </div>
                                <div className='lot-info-middle'>
                                  <div className='left'>
                                    <p className='lot-title'>{lot.publicDetails.title} {lot.publicDetails.summary.age} {lot.additionalTitle}</p>
                                    {this.getLotClosedState(lot)}
                                    <React.Fragment>
                                      <p className='vendor-name'>{lot.publicDetails.vendorName}</p>
                                    </React.Fragment>
                                    <p className='lot-comments'>{`${limitTextLength(lot.publicDetails.agentComment, 420, false)} `}
                                      <span className='lot-readMore' onClick={() => {
                                        this.setState({
                                          redirectAgentCommentUrl: this.goToLotLink(lot)
                                        })
                                      }}>
                                        {lot.publicDetails.agentComment.length > 420 || lot.publicDetails.agentComment.split(/\r\n|\r|\n/g).length >= 4 ? '...Read More' : ''}
                                      </span>
                                    </p>
                                  </div>
                                  <div className='info-btns'>
                                    <NavLink className='btn edit-btn' to={this.editLink(lot)}>
                                      <span className='button-text'>Edit & Reoffer</span>
                                      <span className='icon-warning icon-wrapper'>
                                        <FontAwesomeIcon icon={faEdit} />
                                      </span>
                                    </NavLink>
                                  </div>
                                </div>
                                <div className='lot-info-bottom'>
                                  <div className='info-wrap'>
                                    <LotBottomInfo lot={lot} mode={this.state.listType} />
                                  </div>
                                  <div className='company-logo-wrapper'>
                                    {
                                      lot.creatorData.logo &&
                                      <img src={lot.creatorData.logo.url} alt='Company logo' />
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>)
                        })}
                      </div>
                    </div>
                  )
                }
                return null
              })}
              {this.state.fetching && <div className={`scroll-loader-wrap ${this.state.fetching ? 'active' : ''}`}>
                <WanderingCubes className='scroll-loader' color={'gray'} />
              </div>}
            </React.Fragment>
          </InfiniteScroll>
          {
            this.state.data.length
              ? null
              : (
                <div className='no-data-placeholder'>
                  <div className='message'>
                    You currently have no Lots to reoffer.
                  </div>
                </div>
              )
          }
        </div>

      </div>
      </ScrollToTop>

    )
  }
}

MyReoffers.propTypes = {
  showSpinner: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

export default MyReoffers
