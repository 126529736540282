import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { showSpinner } from '../../actions'
import ForgotPassword from './Forgot'

// function mapStateToProps (state) {
//   return state
// }

function mapDispatchToProps (dispatch) {
  return bindActionCreators({ showSpinner }, dispatch)
}

export default connect(null, mapDispatchToProps)(ForgotPassword)
