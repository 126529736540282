// import React from 'react'

const sounds = {
  bid: require('../assets/audio/Bid.mp3'),
  outbid: require('../assets/audio/Outbid.mp3'),
  autobid: require('../assets/audio/Auto-bid.wav'),
  won: require('../assets/audio/Won.wav'),
  preclose: require('../assets/audio/Countdown.mp3')
}

const makeSound = (sound) => {
  switch (sound.event) {
    case 'bid': {
      sound.name = 'bid'
      sound.channel = 1
      sound.priority = 1
      sound.ignorable = true
      sound.url = sounds.bid
      break
    }
    case 'won': {
      sound.name = 'won'
      sound.channel = 1
      sound.priority = 2
      sound.ignorable = false
      sound.url = sounds.won
      break
    }
    case 'autobid': {
      sound.name = 'autobid'
      sound.channel = 1
      sound.priority = 3
      sound.ignorable = false
      sound.url = sounds.autobid
      break
    }
    case 'outbid': {
      sound.name = 'outbid'
      sound.channel = 1
      sound.priority = 4
      sound.ignorable = false
      sound.url = sounds.outbid
      break
    }
    case 'preclose': {
      sound.name = 'preclose'
      sound.channel = 2
      sound.priority = 1
      sound.ignorable = false
      sound.url = sounds.preclose
      break
    }
    default: {
      break
    }
  }

  return sound
}

export default makeSound
