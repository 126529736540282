import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'

import AuctionsListContainer from './AuctionsList/AuctionsListContainer'
import LotsListContainer from './LotsList/LotsListContainer'
import LotDetailsContainer from './LotDetails/LotDetailsContainer'
import { onEnter } from '../../utils/UpdateData'

class Upcoming extends PureComponent {
  render () {
    return (
      <div className='app-route'>
        <div className='body-container'>
          <div className='inner-container'>
            {onEnter(
              <Switch>
                <Route path='/main/upcoming/:kindTitle/:auctionNumber/:lotNumericId/'
                  render={(obj) => {
                    if (!this.props.isServerLive) {
                      return <Redirect to='/main/upcoming' />
                    } else {
                      return <LotDetailsContainer {...obj} />
                    }
                  }} />
                <Route path='/main/upcoming/:kindTitle/:auctionNumber'
                  render={(obj) => {
                    if (!this.props.isServerLive) {
                      return <Redirect to='/main/upcoming' />
                    } else {
                      return <LotsListContainer {...obj} />
                    }
                  }} />
                <Route exact strict path='/main/upcoming'
                  component={AuctionsListContainer} />
                <Redirect to='/main/upcoming' />
              </Switch>
              , this.props.isServerLive)}
          </div>
        </div>
      </div>
    )
  }
}

Upcoming.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

Upcoming.defaultProps = {}

export default Upcoming
