import React, { PureComponent } from 'react'
import { NavLink } from 'react-router-dom'
import memoize from 'memoize-one'
import PropTypes from 'prop-types'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
 // faGavel,
  faArrowRight,
  faFileAlt,
  faTimes,
  faFile
} from '@fortawesome/fontawesome-free-solid'
//import { sortAuctions } from '../../utils/Filter'

class Notifications extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      assessNotification: true
    }
    this.openAuction = this.openAuction.bind(this)
    this.openMyAdmin = this.openMyAdmin.bind(this)
    this.renderAuctionOpen = memoize(this.renderAuctionOpen)
    this.renderAuctionLive = memoize(this.renderAuctionLive)
  }

  openAuction (item) {
    if (this.props.isServerLive) {
      window.open(`#/auction-live/${item.searchNumber}`)
    }
  }

  openMyAdmin () {
    window.open(`#/main/manage`, '_self')
  }

  // renderAuctionOpen (auctions, closedNotifs) {
  //   let nodes = []
  //   auctions = auctions.filter(auction => {
  //     return auction.state === 'open' && !closedNotifs.find(key => (key === auction._id))
  //   })
  //   auctions = sortAuctions(auctions, 'Date', 'up')
  //   auctions.forEach(item => {
  //     nodes.push(
  //       <div className='notification open' key={item._id}>
  //         <div className='icon'><FontAwesomeIcon icon={faGavel} /></div>
  //         <div className='text'>
  //           <span className='hint'>
  //             {`Auction is Open ${!item.allowAutoBid ? '-' : ''}`}<br />
  //             {item.allowAutoBid && `Set your Auto-bids`}
  //           </span>
  //           <span className='notification-title'>
  //             {item.title}
  //           </span>
  //         </div>
  //         <div className='btn enter-auction-btn' onClick={() => this.openAuction(item)}>
  //           <span>Enter Auction</span>
  //           <span className='icon-wrapper'><FontAwesomeIcon icon={faArrowRight} /></span>
  //         </div>
  //         <div className='close' onClick={() => this.props.closeUserNotification(item._id, 'auctionOpen')}>
  //           <FontAwesomeIcon icon={faTimes} />
  //         </div>
  //       </div>
  //     )
  //   })
  //   return nodes
  // }

  // renderAuctionLive (auctions, closedNotifs) {
  //   let nodes = []
  //   auctions = auctions.filter(auction => {
  //     return auction.state === 'live' && !closedNotifs.find(key => (key === auction._id))
  //   })
  //   auctions = sortAuctions(auctions, 'Date', 'up')
  //   auctions.forEach(item => {
  //     nodes.push(
  //       <div className='notification live' key={item._id}>
  //         <div className='icon'><FontAwesomeIcon icon={faGavel} /></div>
  //         <div className='text'>
  //           <span className='hint'>
  //             Auction is Live -
  //           </span>
  //           <span className='notification-title'>
  //             {item.title}
  //           </span>
  //         </div>
  //         <div className='btn enter-auction-btn' onClick={() => this.openAuction(item)}>
  //           <span>Enter Auction</span>
  //           <span className='icon-wrapper'><FontAwesomeIcon icon={faArrowRight} /></span>
  //         </div>
  //         <div className='close' onClick={() => this.props.closeUserNotification(item._id, 'auctionLive')}>
  //           <FontAwesomeIcon icon={faTimes} />
  //         </div>
  //       </div>
  //     )
  //   })
  //   return nodes
  // }


  renderAssessNotification (){
    const { user } = this.props
    let nodes = []
    if(user.status === 'registered') {
      nodes.push(
        <div className='notification assessnotification' >
          <div className='icon'><FontAwesomeIcon icon={faFile} /></div>
          <div className='text'>
            <span className='assessNotification-hint'>
              Guest Account - 
            </span>
            <span>
              <span className='assessNotification-title'>
              {`You currently do NOT have permission to BUY or SELL. Please Upgrade your account by clicking My Admin > Upgrade Account.`}
              </span>
            </span>
          </div>
          <div className='btn enter-myAdmin-btn' onClick={() => this.openMyAdmin()}>
            <span>My Admin</span>
            <span className='icon-wrapper'><FontAwesomeIcon icon={faArrowRight} /></span>
          </div>
          {/* <div className='close'>
            <FontAwesomeIcon icon={faTimes} />
          </div> */}
        </div>
     )
    }
    return this.state.assessNotification ? nodes : ''
  }

  renderSingleNotifications (activeNotifs) {
    const { user } = this.props
    let nodes = []
    if (user.type === 'seller' && window.location.href.includes('edit-saved') && activeNotifs.find((key) => key === 'my-sync-lots')) {
      nodes.push(
        <div className='notification my-sync-lots' key={'my-sync-lots'}>
          <div className='sync-image' />
          <div className='text-wrapper'>
            <h3>Can't see your Lots here?</h3>
            <span className='text'>Open the FarmGate Auction app on your iPhone or iPad and tap Edit & Sync, select Lots to Sync.</span>
          </div>
          <div className='close' onClick={() => this.props.closeSingleNotification('my-sync-lots')}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        </div>
      )
    }
    activeNotifs.forEach((key) => {
      let nodeText
      switch (key) {
        case 'my-saved-lots':
          nodeText = 'Your Submitted Lots will be available for 30 days after Auction'
          break
        case 'my-reoffers':
          nodeText = 'Your Lots to reoffer will be available for 7 days after Auction'
          break
        default:
          nodeText = ''
      }
      if (key !== 'my-sync-lots') {
        nodes.push(
          <div className='notification live' key={key}>
            <span className='text'>{nodeText}</span>
            <div className='close' onClick={() => this.props.closeSingleNotification(key)}>
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </div>
        )
      }
    })
    return nodes
  }

  hideUnusableNotifications () {
    if (window.location.href.includes('my-submitted')) {
      this.props.hideSingleNotification('my-saved-lots')
    }
    // if (!window.location.href.includes('my-reoffers')) {
    //   this.props.hideSingleNotification('my-reoffers')
    // }
    if (window.location.href.includes('my-reoffers')) {
      this.props.hideSingleNotification('my-reoffers')
    }
  }

  render () {
    const {
      auctions,
     // userNotifications,
      singleNotifications,
      user,
      isServerLive,
      isServerLiveMessage
    } = this.props
    this.hideUnusableNotifications()
    let nodes = []
    if (!isServerLive) {
      nodes.push(
        <div className='notification live-server-warning' key={'liveServerStopped'}>
          <span className='icon-wrapper'>
            <div className='no-internet-image' />
          </span>
          <div className='text-wrapper server-error'>
            <p className='text server-error-text'>
              {isServerLiveMessage}
            </p>
          </div>
        </div>
      )
    }
    if (user.status === 'partially-approved') {
      nodes.push(
        <div className='notification profile' key={'notFilled'}>
          <div className='icon'><FontAwesomeIcon icon={faFileAlt} /></div>
          <span className='text'>Please complete profile to Add a Lot or Bid in Auction</span>
          <NavLink to='/main/profile'>
            <div className='btn enter-auction-btn'>
              <span>Go to my profile</span>
              <span className='icon-wrapper'><FontAwesomeIcon icon={faArrowRight} /></span>
            </div>
          </NavLink>
        </div>
      )
    }
    if (user.status === 'partially-approved:updated') {
      nodes.push(
        <div className='notification review' key={'notApproved'}>
          <div className='icon'><FontAwesomeIcon icon={faFileAlt} /></div>
          <span className='text'>Application is under review</span>
          <NavLink to='/main/contact'>
            <div className='btn enter-auction-btn'>
              <span>Contact Us</span>
              <span className='icon-wrapper'><FontAwesomeIcon icon={faArrowRight} /></span>
            </div>
          </NavLink>
        </div>
      )
    }
    nodes = nodes.concat(
      this.renderSingleNotifications(singleNotifications.active)
        .concat(this.renderAssessNotification(auctions))
        //.concat(this.renderAuctionLive(auctions, userNotifications.auctionLive.closed))
       // .concat(this.renderAuctionOpen(auctions, userNotifications.auctionOpen.closed))
    )
    nodes.length = 5
    return (
      nodes.length > 0 && (
        <div className='notifications'>
          {nodes}
        </div>
      )
    )
  }
}

Notifications.propTypes = {
  user: PropTypes.object.isRequired,
  auctions: PropTypes.array.isRequired,
  singleNotifications: PropTypes.object.isRequired,
  userNotifications: PropTypes.object.isRequired,
  closeSingleNotification: PropTypes.func.isRequired,
  closeUserNotification: PropTypes.func.isRequired
}

Notifications.defaultProps = {}

export default Notifications
