import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Checkbox from '../../Checkbox/Checkbox'

class CheckboxField extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
    this.onChange = this.onChange.bind(this)
  }
  onChange (value) {
    this.props.onChange(value)
  }
  render () {
    const { isRequired, title, value } = this.props
    return (
      <div className='form-row'>
        <div className='row-title'>
          <p className='row-text'>
            {title}{isRequired ? <span className='asterisk'>*</span> : ''}:
          </p>
        </div>
        <div className='row-content'>
          <Checkbox
            type='green'
            value={value}
            onToggle={this.onChange} />
        </div>
      </div>
    )
  }
}

CheckboxField.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  isRequired: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

CheckboxField.defaultProps = {}

export default CheckboxField
