// TODO component is useless. Remove if it possible
import React, { Component } from 'react'
import ReactTable from 'react-table'

export default class FgTable extends Component {
  constructor (props) {
    super(props)
    this.state = {
      columns: props.columns,
      data: props.data,
      style: props.style,
      defaultPageSize: props.defaultPageSize
    }
  }

  componentDidUpdate () {
    if (this.state.data !== this.props.data) {
      this.setState({ data: this.props.data })
    }
  }

  render () {
    return (
      <div className='fg-table-container'>
        <ReactTable
          data={this.state.data}
          columns={this.state.columns}
          style={this.state.style}
          onExpandedChange={this.props.onExpandedChange}
          defaultPageSize={this.state.defaultPageSize}
          SubComponent={this.props.SubComponent}
          getTrProps={this.props.getTrProps}
          resizable={this.props.resizable !== 'false'}
          defaultSortMethod={(a, b, desc) => {
            if (!isNaN(parseInt(a, 10)) && !isNaN(parseInt(b, 10)) && a.split('-').length !== 3) {
              a = parseInt(a, 10)
              b = parseInt(b, 10)
            } else if (a === null) {
              return -1
            } else if (a && a.split('-').length === 3) {
              if (new Date(a) > new Date(b)) {
                return 1
              } else {
                return -1
              }
            }
            if (a > b) {
              return 1
            }
            if (a < b) {
              return -1
            }
            return 0
          }}
          defaultSorted={[
            {
              id: `${this.props.type === 'listing' ? 'date' : 'auctionData.closedAt'}`,
              desc: true
            }
          ]}
        />
      </div>
    )
  }
}
