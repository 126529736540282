import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import AsBuyerAndSeller from './AsBuyerAndSeller'
import { changeStateProp, showSpinner, showModal } from '../../../actions/index'

const mapStateToProps = (state) => {
  return {
    signUpInfo: state.main.signUpInfo,
    locationStates: state.data.locationStates,
    cameFromWpSite: state.user.cameFromWpSite,
    wpSiteName: state.user.wpSiteName,
    user: state.user.data,
    buyerAndSellerFormInfo :state.user.buyerAndSellerFormInfo,
    isTermsOpened : state.user.isTermsOpened,
    isPrivacyOpened : state.user.isPrivacyOpened
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeStateProp, showSpinner, showModal }, dispatch)
}

const AsBuyerAndSellerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AsBuyerAndSeller)

export default AsBuyerAndSellerContainer
