import {
  CHANGE_STATE_PROP,
  MERGE_STATE_PROP,
  FILTER_STATE_PROP,
  SHOW_SPINNER,
  SHOW_MODAL,
  HIDE_MODAL,
  TEMP_RESTORE_DRAFT_LOT,
  USER_LOGOUT,
  CLOSE_USER_NOTIFICATION,
  SHOW_SINGLE_NOTIFICATION,
  HIDE_SINGLE_NOTIFICATION,
  CLOSE_SINGLE_NOTIFICATION,
  UPDATE_LOT_INFO, HIDE_SLOW_INTERNET_CONNECTION, SHOW_SLOW_INTERNET_CONNECTION
} from '../constants'

export function changeStateProp (prop, value, reducer) {
  return dispatch => {
    dispatch({
      type: reducer.toUpperCase() + CHANGE_STATE_PROP,
      state: {
        prop: prop,
        value: value
      }
    })
  }
}

export function mergeStateProp (prop, value, reducer) {
  return dispatch => {
    dispatch({
      type: reducer.toUpperCase() + MERGE_STATE_PROP,
      state: {
        prop: prop,
        value: value
      }
    })
  }
}

export function filterStateProp (prop, func, reducer) {
  return dispatch => {
    dispatch({
      type: reducer.toUpperCase() + FILTER_STATE_PROP,
      state: {
        prop: prop,
        func: func
      }
    })
  }
}

export function showSpinner (bool) {
  return dispatch => {
    dispatch({
      type: SHOW_SPINNER,
      bool
    })
  }
}

export function showModal (data) {
  return dispatch => {
    dispatch({
      type: SHOW_MODAL,
      data
    })
  }
}

export function hideModal () {
  return dispatch => {
    dispatch({
      type: HIDE_MODAL
    })
  }
}

export function hideSlowInternetConnection () {
  return dispatch => {
    dispatch({
      type: HIDE_SLOW_INTERNET_CONNECTION
    })
  }
}

export function showSlowInternetConnection () {
  return dispatch => {
    dispatch({
      type: SHOW_SLOW_INTERNET_CONNECTION
    })
  }
}

export function restoreDraftLot () {
  return dispatch => {
    dispatch({
      type: TEMP_RESTORE_DRAFT_LOT
    })
  }
}

export function clearUserData () {
  return dispatch => {
    dispatch({
      type: USER_LOGOUT
    })
  }
}

export function closeUserNotification (key, notificationType) {
  return dispatch => {
    dispatch({
      type: CLOSE_USER_NOTIFICATION,
      key,
      notificationType
    })
  }
}

export function showSingleNotification (key) {
  return dispatch => {
    dispatch({
      type: SHOW_SINGLE_NOTIFICATION,
      key
    })
  }
}

export function hideSingleNotification (key) {
  return dispatch => {
    dispatch({
      type: HIDE_SINGLE_NOTIFICATION,
      key
    })
  }
}

export function closeSingleNotification (key) {
  return dispatch => {
    dispatch({
      type: CLOSE_SINGLE_NOTIFICATION,
      key
    })
  }
}

export function updateLotAssessmentPager (lotId, assessmentPage) {
  return dispatch => {
    dispatch({
      type: UPDATE_LOT_INFO,
      lotId,
      data: { assessmentPage }
    })
  }
}
